import React, {Fragment, useState, useEffect} from 'react';
import {Icon, Modal, Transition} from 'semantic-ui-react';
import {Row, Col, Card, CardBody, CardHeader} from 'reactstrap';
import {Form, DatePicker, Select, message} from 'antd';

import Button from '~/shared/Button';
import Notify from '~/shared/Notify';


import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';

import moment from 'moment';
import 'moment/locale/pt-br';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
moment.locale('pt-br');

const {Option} = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function ModalEscalas({
  modo,
  open,
  close,
  dataPlantao,
  selectedDate,
  setSelectedDate,
  getEscalas,
  dataEscalas,
  setOpenModalConfirm,
  excluir,
  isYearMonth,
}) {
  const [loading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleButton, setVisibleButton] = useState(false);
  const [activePlantao, setActivePlantao] = useState('');
  const [mockData, setMockData] = useState([]);
  const [form] = Form.useForm();

  const {
    data: dataCorretores,
    onLoad: onLoadCorretores,
    isLoading: isLoadingCorretores,
  } = useCorretorDropdown(true);

  useEffect(() => {
    onLoadCorretores({withUserType: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      form.resetFields();
      if (isYearMonth) {
        form.setFieldsValue({
          diaMesIni: moment(selectedDate).startOf('month'),
          diaMesFim: moment(selectedDate).endOf('month'),
        });
      } else {
        form.setFieldsValue({
          diaMesIni: moment(selectedDate),
          diaMesFim: moment(selectedDate),
        });
      }
      if (modo === MODO.CADASTRAR) {
        setVisibleButton(false);
        setVisible(false);
        setMockData([]);
        setActivePlantao('');
      } else {
        const newMockData = dataEscalas.filter((item) =>
          moment(item.diaMes).isSame(selectedDate, 'day'),
        );
        setMockData(newMockData);

        const newDataCorretores = newMockData.filter(
          (item) => item.plantaoId === dataPlantao[0]?.id,
        );

        let dataCorretores = [];
        if (newDataCorretores[0]?.escalaCorretores) {
          dataCorretores = newDataCorretores[0]?.escalaCorretores.map(
            (item) => item.corretorId,
          );
        } else {
          dataCorretores = newDataCorretores[0]?.corretoresId
            ? newDataCorretores[0]?.corretoresId
            : [];
        }

        if (dataCorretores) {
          form.setFieldsValue({corretoresId: dataCorretores});
        }

        setActivePlantao(dataPlantao.length > 0 ? dataPlantao[0].id : '');
        setVisible(true);

        if (dataCorretores && dataCorretores?.length > 0)
          setVisibleButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  async function onSaveEscala() {
    setIsLoading(true);
    if (modo === MODO.CADASTRAR) {
      await api
        .post(url.ESCALAS.CADASTRAR(), {escala: mockData})
        .then((res) => {
          setIsLoading(false);
          getEscalas();
          closeModal();
          Notify('success', pt.comum.atencao, 'Escala cadastrada com sucesso.');
        })
        .catch((err) => {
          setIsLoading(false);
          const {error} = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    } else if (modo === MODO.EDITAR) {
      await api
        .put(url.ESCALAS.EDITAR(), {escala: mockData})
        .then((res) => {
          setIsLoading(false);
          getEscalas();
          closeModal();
          Notify('success', pt.comum.atencao, 'Escala alterada com sucesso.');
        })
        .catch((err) => {
          setIsLoading(false);
          const {error} = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }

  function handleEscalas(value) {
    setVisibleButton(false);
    if (activePlantao === '')
      Notify('error', pt.comum.atencao, 'Selecione um plantão');
    else {
      form
        .validateFields()
        .then((dados) => {
          const index = mockData.findIndex(
            (item) => item.plantaoId === activePlantao,
          );
          if (index >= 0) mockData.splice(index, 1);

          dados.plantaoId = activePlantao;
          if (isYearMonth) {
            dados.diaMesIni = moment(dados.diaMesIni)
              .startOf('month')
              .format('YYYY-MM-DD');
            dados.diaMesFim = moment(dados.diaMesFim)
              .endOf('month')
              .format('YYYY-MM-DD');
          } else {
            dados.diaMesIni = moment(dados.diaMesIni).format('YYYY-MM-DD');
            dados.diaMesFim = moment(dados.diaMesFim).format('YYYY-MM-DD');
          }
          dados.diaSemana = moment(dados.diaMes).format('ddd');

          const newMockData = mockData.concat(dados);
          setMockData(newMockData);

          if (newMockData && newMockData?.length > 0) setVisibleButton(true);

          message.success('Dados salvos com sucesso.');
        })
        .catch((errorInfo) => {
          window.scrollTo(1, 1);
        });
    }
  }

  function onClickPlantao(id) {
    if (id !== '') {
      const filteredMockData = mockData.filter((item) => {
        return (
          moment(item.diaMes).isSame(selectedDate, 'day') &&
          item.plantaoId === id
        );
      });

      let dataCorretores = [];
      if (filteredMockData[0]?.escalaCorretores) {
        dataCorretores = filteredMockData[0]?.escalaCorretores.map(
          (item) => item.corretorId,
        );
      } else {
        dataCorretores = filteredMockData[0]?.corretoresId
          ? filteredMockData[0]?.corretoresId
          : [];
      }

      if (dataCorretores) form.setFieldsValue({corretoresId: dataCorretores});

      setActivePlantao(id);
      setVisible(true);
    }
  }

  function onSearchCorretor(value) {
    if (value.length > 3) {
      onLoadCorretores({ withUserType:true, nome: value });
    }
  }

  function formatHora(hora) {
    const splitHora = hora.split(':');
    return `${splitHora[0]}:${splitHora[1]}`;
  }

  function closeModal() {
    setSelectedDate('');
    setVisibleButton(false);
    setVisible(false);
    close();
    onLoadCorretores({withUserType: true});
  }

  return (
    <Fragment>
      <Modal open={open} onClose={closeModal}>
        <Modal.Content scrolling>
          <h5 className="title">Cadastro de escala</h5>
          <div className="px-2 py-2">
            <Form form={form} scrollToFirstError>
              <Row>
                <Col xs="12" md="3">
                  <p className="heading-small text-muted mb-2">
                    <small>Data Início</small>
                  </p>
                  <Form.Item className="my-2" name="diaMesIni">
                    <DatePicker
                      style={{width: '100%'}}
                      disabled={isYearMonth}
                      format={'DD/MM/YYYY'}
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <p className="heading-small text-muted mb-2">
                    <small>Data Fim</small>
                  </p>
                  <Form.Item className="my-2" name="diaMesFim">
                    <DatePicker
                      style={{width: '100%'}}
                      disabled={isYearMonth}
                      format={'DD/MM/YYYY'}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="12">
                  <p className="heading-small text-muted mb-2 mt-2">
                    <small>Plantão</small>
                  </p>
                </Col>
              </Row>
              <Row>
                {dataPlantao.map((item) => {
                  const className =
                    activePlantao === item.id
                      ? 'divClick atendimentoActive'
                      : 'divClick';

                  return (
                    <Col key={item.id}>
                      <Card
                        className={className}
                        onClick={() => onClickPlantao(item.id)}>
                        <CardHeader className="pt-0">
                          <span className="category title font-size-lg">
                            {item.descricao}
                          </span>
                        </CardHeader>
                        <CardBody className="pt-1 mx-2">
                          <Row>
                            <Col xs="12">
                              <span>
                                <b>Início: </b>
                                {formatHora(item.horaInicio)}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <span>
                                <b>Final: </b>
                                {formatHora(item.horaFim)}
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              <Row>
                {visible && (
                  <Transition
                    visible={visible}
                    animation="scale"
                    duration={500}>
                    {activePlantao && (
                      <Col xs="12" md="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Usuário(s)</small>
                        </p>
                        <Form.Item
                          className="my-2"
                          name="corretoresId"
                          rules={[
                            {
                              required: true,
                              message: 'Favor selecione um corretor!',
                            },
                          ]}>
                          <Select
                            placeholder=" Usuário(s)"
                            mode="multiple"
                            allowClear
                            showSearch
                            showArrow
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                ) >= 0
                            }
                            disabled={loading}
                            onSearch={onSearchCorretor}
                            onChange={handleEscalas}>
                            {!isLoadingCorretores &&
                              dataCorretores.map((item) => (
                                <Option
                                  key={item.id}
                                  disabled={item.isSeparator}>
                                  {item?.nome?.toString()}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Transition>
                )}
              </Row>
            </Form>
            <Row>
              {visibleButton && (
                <Transition
                  visible={visibleButton}
                  animation="scale"
                  duration={500}>
                  <Col xs="12" md="4">
                    <Button
                      className="mt-3"
                      onClick={() => onSaveEscala()}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      color="violet"
                      isloading={loading.toString()}
                      loadtext={'Cadastrando...'}>
                      <Icon name="check" />
                      Cadastrar
                    </Button>
                  </Col>
                </Transition>
              )}
              <Col xs="12" md="4">
                <Button
                  className="mt-3"
                  onClick={() => closeModal()}
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid>
                  <Icon name="undo" />
                  Voltar
                </Button>
              </Col>
              {excluir && modo === MODO.EDITAR && (
                <Col xs="12" md="4">
                  <Button
                    className="mt-3"
                    onClick={() => setOpenModalConfirm(true)}
                    color="orange"
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid>
                    <Icon name="trash" />
                    Excluir Escalas
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalEscalas;
