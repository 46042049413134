import CryptoJS, {AES} from 'crypto-js';

export function encrypt(data) {
  return AES.encrypt(data, 'icaseEncryptedMenus').toString();
}

export function encryptAll(data, name = 'latEncrypt') {
  const encrypt = AES.encrypt(JSON.stringify(data), name).toString();
  return encrypt;
}

export function decrypt(data) {
  if (data) {
    const decryptedMenus = AES.decrypt(data, 'icaseEncryptedMenus');
    return decryptedMenus.toString(CryptoJS.enc.Utf8);
  }
}

export function decryptAll(encrypt, name = 'latEncrypt') {
  var bytes = CryptoJS.AES.decrypt(encrypt, name);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
