
const benfeitoriasImovel = [
  { label: 'Via Pavimentada', value: 'VP' },
  { label: 'Varanda', value: 'VA' },
  { label: 'Escritório', value: 'ES' },
  { label: 'Rede de Esgoto', value: 'RE' },
  { label: 'Piscina', value: 'PI' },
  { label: 'Lavabo', value: 'LA' },
  { label: 'Quintal', value: 'QU' },
  { label: 'Banheiro Serv.', value: 'BS' },
  { label: 'Closet', value: 'CL' },
  { label: 'Quadra Esportes', value: 'QE' },
  { label: 'Copa', value: 'CO' },
  { label: 'Armários', value: 'AR' },
  { label: 'Salão de Festas', value: 'SF' },
  { label: 'Sala de Jantar', value: 'SJ' },
  { label: 'Mobiliado', value: 'MO' },
  { label: 'Sala Ginastica', value: 'SG' },
  { label: 'DCE', value: 'DC' },
  { label: 'Armário Coz.', value: 'AC' },
  { label: 'Churrasqueira', value: 'CH' },
  { label: 'Despensa', value: 'DE' },
  { label: 'Minha Casa Minha Vida', value: 'MV' },
  { label: 'Portão Eletrônico', value: 'PE' },
];

export default benfeitoriasImovel;