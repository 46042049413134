import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, Row} from 'reactstrap';

import history from '~/services/history';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import formatCurrency from '~/shared/formatCurrency';

import useDashResumoNegocios from '~/actions/Dashboard/useDashResumoNegocios';
import * as configConst from '~/infra/config/configSistemaConst';
import useConfigSistema from '~/actions/Empresa/useConfigSistema';

import 'moment/locale/pt-br';
import moment from 'moment';
import useDashVendas from '~/actions/Dashboard/useDashVendas';
moment.locale('pt-BR');

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(id) {
  history.push({
    pathname: `/app/atendimentos/manutencao/${MODO.EDITAR}`,
    state: {id},
  });
}

export function counterRangeDate(fechamento, dateMonth) {
  if (dateMonth?.length <= 0) {
    dateMonth = ['01/2020', '12/2021'];
  }

  let resultVenda = 0;
  let resultLocacao = 0;

  if (dateMonth) {
    const dataInicial = moment(dateMonth[0], 'MM/YYYY');
    const dataFinal = moment(dateMonth[1], 'MM/YYYY');

    fechamento.forEach((date) => {
      let baseDate = '';

      if (String(date.mes).length === 1) {
        baseDate = moment(String('0' + date.mes + '/' + date.ano), 'MM/YYYY');
      } else {
        baseDate = moment(String(date.mes + '/' + date.ano), 'MM/YYYY');
      }

      if (dataInicial <= baseDate && baseDate <= dataFinal) {
        if (date.tipo === 'L') {
          resultLocacao += date.soma;
        }

        if (date.tipo === 'V') {
          resultVenda += date.soma;
        }
      }
    });
  }

  return {resultVenda, resultLocacao};
}

function DadosChave({
  fechamento,
  dateMonth,
  atendente,
  qtdAnaliseFinanceira,
  qtdImpostoRenda,
  setAnaliseFinanceiraOpen,
  setImpostoRendaOpen,
}) {
  const [reloadCards, setReloadCards] = useState(false);

  const {checkConfig} = useConfigSistema();
  const {data: {resultVenda, resultLocacao, resultComissao}, isLoading: isLoadingTotalPorTipo} =
    useDashVendas(atendente, dateMonth)
  const {data: resumoNegocios, isLoading: loadResumo} =
    useDashResumoNegocios(atendente);

  useEffect(() => {
    if (atendente) {
      setReloadCards(true);
      setTimeout(() => {
        setReloadCards(false);
      }, 1200);
    }

  }, [atendente, dateMonth, fechamento]);

  return (
    <Fragment>
      <Row className="px-2">
        <div className="d-flex my-2 w-100 flex-wrap">
          <div className="flex-grow-1 card-dash">
            {isLoadingTotalPorTipo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container">
                    <p className="dash-content-title">
                      Vendas:{' '}
                      <span className="dash-content-title-number"> </span>
                    </p>
                    <i className="fas fa-dollar-sign fa-3x"></i>
                    <p className="dash-content-text">Mês</p>
                    <p className="dash-content-text">
                      R${' '}
                      <span className="dash-content-text-value">
                        {resultVenda ? formatCurrency(resultVenda, 2, false) : 0}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
          <div className="flex-grow-1 card-dash">
            {isLoadingTotalPorTipo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container">
                    <p className="dash-content-title">
                      Locações:
                      <span className="dash-content-title-number"></span>
                    </p>
                    <i className="fas fa-comment-dollar fa-3x"></i>
                    <p className="dash-content-text">Mês</p>
                    <p className="dash-content-text">
                      R${' '}
                      <span className="dash-content-text-value">
                        { resultLocacao ? formatCurrency(resultLocacao, 2, false) : 0}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
          <div className="flex-grow-1 card-dash">
            {isLoadingTotalPorTipo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container">
                    <p className="dash-content-title">
                      Comissão:
                      <span className="dash-content-title-number"></span>
                    </p>
                    <i className="fas fa-money-bill fa-3x"></i>
                    <p className="dash-content-text">Mês</p>
                    <p className="dash-content-text">
                      R${' '}
                      <span className="dash-content-text-value">
                      { resultComissao ? formatCurrency(resultComissao, 2, false) : 0}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
          {checkConfig(configConst.UTILIZA_ANALISE_FINANCEIRA) && (
            <div className="flex-grow-1 card-dash">
              {reloadCards ? (
                <div className="dash-container my-2">
                  <SkeletonTableBasic cardShadowless />
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{cursor: 'pointer'}}
                      className="dash-container"
                      onClick={() => setAnaliseFinanceiraOpen(true)}>
                      <p className="dash-content-title">Análise financeira</p>
                      <i className="fas fa-comments-dollar fa-3x"></i>
                      <p className="dash-content-large-number ">
                        {qtdAnaliseFinanceira || 0}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          )}
          {checkConfig(configConst.CONTROLA_IR) && (
            <div className="flex-grow-1 card-dash">
              {reloadCards ? (
                <div className="dash-container my-2">
                  <SkeletonTableBasic cardShadowless />
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <div
                      style={{cursor: 'pointer'}}
                      className="dash-container"
                      onClick={() => setImpostoRendaOpen(true)}>
                      <p className="dash-content-title">Imposto de renda</p>
                      <i className="fas fa-file-invoice-dollar fa-3x"></i>
                      <p className="dash-content-large-number ">
                        {qtdImpostoRenda || 0}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          )}
        </div>
      </Row>

      <Row className="px-2">
        <div className="d-flex my-2 w-100 flex-wrap">
          <div className="flex-grow-1 card-dash">
            {loadResumo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container-leads">
                    <p className="dash-content-title">Leads na base: </p>
                    <p className="dash-content-title-number">
                      {resumoNegocios ? resumoNegocios.totalLeadsNaBase : 0}
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
          <div className="flex-grow-1 card-dash">
            {loadResumo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container-leads">
                    <p className="dash-content-title">Atendimentos: </p>
                    <p className="dash-content-title-number">
                      {resumoNegocios ? resumoNegocios.totalAtendimentos : 0}
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
          <div className="flex-grow-1 card-dash">
            {loadResumo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container-leads">
                    <p className="dash-content-title">
                      Em visita:{' '}
                      <span className="dash-content-title-number">
                        {resumoNegocios ? resumoNegocios.totalVisitas : 0}{' '}
                      </span>
                    </p>
                    <p className="dash-content-text">
                      R${' '}
                      <span className="dash-content-text-value">
                        {resumoNegocios
                          ? formatCurrency(
                              resumoNegocios.totalVisitaValor,
                              2,
                              true,
                            )
                          : 0}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
          <div className="flex-grow-1 card-dash">
            {loadResumo || reloadCards ? (
              <div className="dash-container my-2">
                <SkeletonTableBasic cardShadowless />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <div className="dash-container-leads">
                    <p className="dash-content-title">
                      Com proposta:{' '}
                      <span className="dash-content-title-number">
                        {resumoNegocios?.totalProposta || 0}
                      </span>
                    </p>
                    <p className="dash-content-text">
                      R${' '}
                      <span className="dash-content-text-value">
                        {resumoNegocios.totalPropostaValor
                          ? formatCurrency(
                              resumoNegocios.totalPropostaValor,
                              2,
                              true,
                            )
                          : 0}
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </Row>
    </Fragment>
  );
}

export default DadosChave;
