import {useCallback, useEffect, useState} from 'react';
import api from '~/services/api';

export default function useImovelComCorretor({imovelId, userId, empresaId}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({imovel: {}, pessoa: {}, empresa: {}});

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await api
      .get(`/imovel/${imovelId}/user/${userId}/empresa/${empresaId}`)
      .then((response) => {
        setData((prevState) => ({
          ...prevState,
          imovel: response.data.imovel,
          pessoa: response.data.pessoa,
          empresa: response.data.empresa,
        }));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [imovelId, userId, empresaId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {isLoading, data};
}
