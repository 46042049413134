import React, { Component } from 'react';

import './Maintenance.css';

class Maintenance extends Component {
  render() {
    return (
      <body class="maintenance__container">
        <div class="maintenance__image"></div>
        <div class="maintenance__title">Ops... Estamos em manutenção!</div>
        <div class="maintenance__message">Não se preocupe, logo, logo estaremos de volta!</div>
        <div class="maintenance__footer">©Copyright Latitude 2023</div>
      </body>
    );
  }
}

export default Maintenance;
