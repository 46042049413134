import React, { Fragment } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';

import Button from '~/shared/Button';
import './modalConfirm.css';

function ModalConfirm({
  modalType = 'delete',
  icon,
  isDelete = false,
  buttonText = 'Excluir',
  buttonColor = 'red',
  buttonIcon,
  loading,
  open,
  confirm,
  close,
  message,
  messageAfterIcon = undefined,
  loadingText = 'Excluindo...',
  showButtonCancel = true,
}) {
  return (
    <Fragment>
      <Modal size="tiny" open={open}>
        <Modal.Content className="text-center">
          <div className="p-1">
            <h4>{message}</h4>
          </div>
          <Icon
            name={icon}
            size="huge"
            className={`${isDelete && modalType === 'delete' && 'shake'} mt-3`}
            color={isDelete ? 'yellow' : 'green'}
          />
          {messageAfterIcon && (
            <div className='pt-3'>{messageAfterIcon}</div>
          )}
        </Modal.Content>
        <Modal.Content>
          <Row className={!showButtonCancel ? 'justify-content-center' : ''}>
            {(isDelete || modalType === 'update') && (
              <Col xs="12" md="6">
                <Button
                  isloading={loading.toString()}
                  loadtext={loadingText}
                  icon
                  fluid
                  labelPosition="left"
                  color={buttonColor}
                  onClick={confirm}
                >
                  {buttonIcon && <Icon name={buttonIcon} />}
                  {loading ? loadingText : buttonText}
                </Button>
              </Col>
            )}
            {showButtonCancel && (
              <Col xs="12" md={isDelete || modalType === 'update' ? '6' : '12'}>
                <Button
                  onClick={close}
                  fluid
                  color={isDelete || modalType === 'update' ? 'orange' : ''}
                >
                  {isDelete || modalType === 'update' ? 'Cancelar' : 'OK'}
                </Button>
              </Col>
            )}
          </Row>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalConfirm;
