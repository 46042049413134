import React, { Fragment } from 'react';
import { Document, Font } from '@react-pdf/renderer';

import FrontPage from './components/FrontPage';
import GeneralInformations from './components/GeneralInformations';
import Pictures from './components/Pictures';
import Compare from './components/Compare';

import MontserratRegular from '~/assets/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from '~/assets/fonts/Montserrat-SemiBold.ttf';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratRegular
    },
    {
      src: MontserratSemiBold,
      fontWeight: 'semibold'
    },
  ],
});

const ApresentacaoImoveis = ({ cliente, corretor, imoveis, highlightCompare, message }) => {
  if (!imoveis) return <Document />;

  const empresa = JSON.parse(localStorage.getItem('latitudeCompanyData') || '{}');
  const celular = cliente?.telefones.find(telefone => telefone.tipo === 2);
  const telefone = cliente?.telefones.find(telefone => telefone.tipo === 1);

  return (
    <Document title='Apresentação de Imóveis'>
      <FrontPage
        cliente={cliente}
        celular={celular}
        telefone={telefone}
        corretor={corretor}
        empresa={empresa}
        message={message}
      />

      {imoveis.map((imovel, index) => (
        <Fragment key={`GI-P-${imovel.id}`}>
          <GeneralInformations
            key={`GI-${imovel.id}`}
            index={index}
            corretor={corretor}
            empresa={empresa}
            imovel={imovel} />

          {imovel?.imagens.length > 0 && (
            <Pictures
              key={`PI-${imovel.id}`}
              index={index}
              corretor={corretor}
              empresa={empresa}
              imovel={imovel} />
          )}
        </Fragment>
      ))}

      {highlightCompare.map((imoveis, index) => (
        <Compare
          key={`HC-${index}`}
          corretor={corretor}
          empresa={empresa}
          imoveis={imoveis} />
      ))}
    </Document>
  );
};

export default ApresentacaoImoveis;
