/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { red } from '@material-ui/core/colors';
import Resizer from 'react-image-file-resizer';
import { Icon } from 'semantic-ui-react';
import Logo from '~/assets/img/upload.svg';

function UploadImage({
  setAlterLogo,
  setFileLogo,
  fileLogo,
  maxImageSize = 5,
  minWidth = 500,
  minHeight = 500,
  multiple = false,
  showUploadList = false,
  showClear = false,
  limparImagens
}) {
  const [errorLogo, setErrorLogo] = useState([]);
  const [loading] = useState(false);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : null}
      {showClear && fileLogo?.length > 0 ? (<Button
        icon
        onClick={limparImagens}
        labelPosition="left"
        size="medium"
        fluid
        className="mt-1"
        loadtext="Excluindo imagens..."
        color="orange">
        <Icon name="trash" />
        Excluir Todas Imagens
      </Button>) :
        (
          <div style={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img alt="" style={{ marginBottom: 2, width: 70 }} src={Logo} />
          </div>)}
    </div>
  );

  const setImage = (base64, image) => {
    image.file.url = base64;
    setErrorLogo([]);
    setFileLogo(image.file);
    setAlterLogo(true);
  }

  const handleChangeImg = async (image) => {
    if (!image.file.url) {
      var base64 = await getBase64(image.file);
      let im = new Image();
      im.src = base64;
      im.onload = () => {
        setImage(base64, image);
      };
    }
  };

  // const handleChangeImg = async (image) => {
  //   if (!image.file.url) {
  //     const fileSize = image.file.size / 1024 / 1024;
  //     const fileType = image.file.type.split('/')[1];

  //     if (fileSize > maxImageSize) {
  //       setErrorLogo([`A imagem selecionada ultrapassou o tamanho máximo de ${maxImageSize}Mb.`]);
  //       setFileLogo(null)
  //     } else {
  //       var base64 = await getBase64(image.file);
  //       let im = new Image();
  //       im.src = base64;
  //       im.onload = () => {
  //         if (im?.width !== minWidth || im?.height !== minHeight) {
  //           try {
  //             Resizer.imageFileResizer(
  //               image.file,
  //               minWidth,
  //               minHeight,
  //               fileType.toUpperCase(),
  //               100,
  //               0,
  //               (uri) => {
  //                 setImage(uri, image);
  //               },
  //               "base64",
  //             );
  //           } catch (err) {
  //             setErrorLogo(['Ocorreu um erro ao redimensionar a imagem, tente novamente!']);
  //           }
  //         } else {
  //           setImage(base64, image);
  //         }
  //       };
  //     }
  //   }
  // };

  return (
    <>
      <Upload
        accept={'image/png,image/jpeg'}
        listType="picture-card"
        className="avatar-uploader"
        multiple={multiple}
        showUploadList={showUploadList}
        onPreview={fileLogo}
        disabled={showClear && fileLogo?.length > 1}
        onChange={handleChangeImg}
        onRemove={e => fileLogo.splice(fileLogo.findIndex(x => x.uid === e.uid), 1)}
        fileList={multiple ? fileLogo : null}
        beforeUpload={(file) => {
          return false;
        }}>
        {fileLogo?.url ? (
          <img
            src={fileLogo.url}
            alt="avatar"
            style={{ width: '100%', maxHeight: 450 }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {errorLogo.map((e, index) => (
        <p
          key={index}
          style={{ color: red[400] }}>{`${e}`}</p>
      ))}
    </>
  )
}

export default UploadImage;
