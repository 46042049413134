import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select, Table, Divider } from 'antd';
import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import EmptyBox from '~/components/EmptyBox';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import UploadImage from '~/components/UploadImage';
import useUserByRole from '~/actions/DropDown/useUserByRole';
import useGrupoCorretoresByRole from '~/actions/GrupoCorretores/useGrupoCorretoresByRole';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const { Option } = Select;

function GrupoCorretores(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [identificador, setIdentificador] = useState();
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [grupoCorretoresId, setGrupoCorretoresId] = useState(null);
  const [fileLogo, setFileLogo] = useState(null);
  const [isAlterLogo, setAlterLogo] = useState(false);

  const [listCorretores, setListCorretores] = useState([]);
  const [listPessoaId, setListPessoaId] = useState([]);

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [icon, setIcon] = useState();
  const [message, setMessage] = useState("Deseja realmente excluir esse registro?");
  const [corretorRow] = useState();
  const [rowIndex] = useState();

  const { data: dataGerentes } = useUserByRole();
  const { data: dataCorretores } = useGrupoCorretoresByRole();

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const { row: editData, identificador } = props.location.state;

        setInitialValues(editData);
        setIdentificador(identificador);

        setFileLogo({ url: editData?.imagemUrl && editData?.imagemUrl !== 'null' ? editData?.imagemUrl : '' });
        setListCorretores(
          editData.corretorGrupoCorretor.map((item) => {
            return item.pessoa;
          }),
        );
        setListPessoaId(
          editData.corretorGrupoCorretor.map((item) => {
            return { pessoaId: item.pessoa.id };
          }),
        );
        setInitialValues(editData);
        setModo(MODO.EDITAR);
        setGrupoCorretoresId(props.location.state.row.id);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        const { identificador } = props.location.state;

        setIdentificador(identificador);
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  useEffect(() => {
    const index = dataGerentes.findIndex(item => item.gerenteId === initialValues.gerenteId);
    if (index < 0 && modo !== MODO.CADASTRAR) {
      dataGerentes.push({ id: initialValues.gerenteId, nome: initialValues?.gerente?.nome });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGerentes]);

  function voltar() {
    if (identificador) {
      history.push(`/app/grupocorretores/${identificador}`);
    } else {
      history.push('/app/grupocorretores');
    }
  }

  function onSave() {
    setIsLoading(true);

    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            grupo: dados.grupo,
            descricao: dados.descricao,
            imagem: isAlterLogo ? fileLogo.url : null,
            gerenteId: dados.gerenteId,
            corretores: listCorretoresDraft.map(item => ({ pessoaId: item.id })),
          }
          
          await api
            .post(url.GRUPO_CORRETORES.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
              setIsDelete(false);
              setIcon('check circle');
              setMessage("Cadastrado com sucesso");
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          let corretoresList = listCorretoresDraft;

          let payload = {
            Id: grupoCorretoresId,
            Grupo: dados.grupo,
            Descricao: dados.descricao,
            GerenteId: dados.gerenteId,
            Corretores: corretoresList.length > 0 ? corretoresList.map(item => item?.id) : [],
          }

          if (isAlterLogo) {
            payload.Imagem = fileLogo?.url;
          } else {
            payload.UrlImagem = fileLogo?.url;
          }

          await api
            .put(url.GRUPO_CORRETORES.EDITAR(), payload)
            .then((res) => {
              setOpenModalConfirm(true);
              setIsDelete(false);
              setIcon('check circle');
              setMessage("Alterações salvas");
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function spliceListCorretores() {
    if (rowIndex >= 0) {
      const newListCorretores = [...listCorretores];
      const newListPessoaId = [...listPessoaId];
      newListCorretores.splice(rowIndex, 1);
      newListPessoaId.splice(rowIndex, 1);
      setListCorretores(newListCorretores);
      setListPessoaId(newListPessoaId);
    }
  }

  async function handleConfirmDelete() {
    if (grupoCorretoresId) {
      setLoadingModal(true);
      const payload = {
        grupoCorretoresId,
        pessoaId: corretorRow.id,
      };

      await api
        .delete(url.GRUPO_CORRETORES_CORRETOR.DELETAR(), { data: payload })
        .then((res) => {
          spliceListCorretores();
          setLoadingModal(false);
          setOpenModalConfirm(false);
          setIsDelete(false);
          Notify('success', '', 'Corretor excluído com sucesso!');
        })
        .catch((err) => {
          setLoadingModal(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    } else {
      setLoadingModal(true);
      spliceListCorretores();
      setOpenModalConfirm(false);
      setLoadingModal(false);
      Notify('success', '', 'Corretor excluído com sucesso!');
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const columns = [
    {
      title: 'Corretor',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.id}>
          <i
            onClick={() => onRemoveCorretor(row)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
  ];

  const [listCorretoresDraft, setListCorretoresDraft] = useState(dataCorretores);
  const isEmpty = !listCorretoresDraft.length;

  const [idsToAdd, setIdsToAdd] = useState([]);
  const [idsToRemove, setIdsToRemove] = useState([]);

  function onAddCorretor() {
    const corretor = dataCorretores.find((item) => item.id === form.getFieldValue('corretorId'));

    if (!corretor) return;

    const alreadyExistsOnDatabase = listPessoaId.find((item) => item.pessoaId === corretor.id);

    setIdsToRemove(current => current.filter(id => id !== corretor.id));

    if (!alreadyExistsOnDatabase) {
      setIdsToAdd(current => [...current, corretor.id]);
    };

    setListCorretoresDraft(current => {
      return [
        ...current,
        corretor
      ]
    })

    form.setFieldsValue({ corretorId: null });
  };

  function onRemoveCorretor(corretor) {
    const alreadyExistsOnDatabase = listPessoaId.find((item) => item.pessoaId === corretor.id);

    if (alreadyExistsOnDatabase) {
      setIdsToRemove(current => [...current, corretor.id]);
    } else {
      setIdsToAdd(current => current.filter(id => id !== corretor.id));
    }

    setListCorretoresDraft(current => current.filter(item => item.id !== corretor.id));
  };

  useEffect(() => {
    setListCorretoresDraft(listCorretores);
  }, [listCorretores]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = "Você tem certeza que deseja sair? As alterações não salvas serão perdidas.";
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    if (idsToAdd.length || idsToRemove.length) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [idsToAdd, idsToRemove]);

  const renderTable = useMemo(() => {
    return <Table
      rowKey={(row) => row.id}
      columns={columns}
      dataSource={[...listCorretoresDraft]}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCorretoresDraft])

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? 'Cadastrar equipe de corretores'
                    : 'Atualizar equipe de corretores'}
                </h5>
                <p className="category">Manutenção de equipe de corretores</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome da equipe</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="grupo"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o nome!',
                                },
                              ]}>
                              <Input placeholder=" Nome da equipe" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2 ">
                              <small>Gerente responsável</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="gerenteId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o gerente responsável!',
                                },
                              ]}>
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder=" Gerente responsável">
                                {dataGerentes.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {`${item?.nome?.toString()} ${item?.excluido ? '(Inativo)' : ''}`}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="12">
                            <p className="heading-small text-muted mb-2">
                              <small>Descrição da equipe</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="descricao">
                              <Input.TextArea
                                placeholder=" Descrição da equipe"
                                showCount
                                maxLength={1000}
                                autoSize={{ minRows: 5, maxRows: 5 }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>Logo da Equipe</small>
                            </p>
                            <UploadImage
                              setAlterLogo={setAlterLogo}
                              setFileLogo={setFileLogo}
                              fileLogo={fileLogo}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Divider dashed />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <p className="my-4">
                              <span className='title'
                                style={{ fontSize: '16px', fontWeight: '600' }}>
                                Selecione abaixo os corretores pertencentes a
                                esta equipe
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="heading-small text-muted mb-2">
                              <small>Corretor</small>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <Form.Item name="corretorId">
                              <Select
                                showSearch
                                onSelect={onAddCorretor}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder=" Selecione um corretor">
                                {dataCorretores
                                .filter((item) => !listCorretoresDraft.find(corretor => corretor.id === item.id))
                                .map((item) => (
                                    <Option key={item.id} disabled={item.isSeparator}>
                                      {item?.nome?.toString()}
                                    </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>

                      {!isEmpty && (
                        <Fragment>
                          <div className="mt-4">
                            {renderTable}
                          </div>
                        </Fragment>
                      )}
                      {isEmpty && (
                        <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                      )}

                      <ModalConfirm
                        isDelete={isDelete}
                        icon={icon}
                        loading={loadingModal}
                        open={openModalConfirm}
                        confirm={handleConfirmDelete}
                        close={isDelete ? handleCloseModal : voltar}
                        message={message}
                      />

                      <Row className="mt-4">
                        <Col xs="12" md="3">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR
                                ? 'Cadastrando...'
                                : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR
                              ? 'Cadastrar'
                              : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="undo" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default GrupoCorretores;
