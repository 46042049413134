import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useNacionalidades() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    try {
      const data = await api.get(url.DROPDOWN.NACIONALIDADES());
      setData(data.data || []);
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
