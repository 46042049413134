import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';

export default function usePerfilAcesso(
  current,
  pageSize,
  nomePerfilAcesso,
  status,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const tamanhoPagina = pageSize ? pageSize : 10;
    const nomePagina = nomePerfilAcesso ? nomePerfilAcesso : '';
    const statusPagina = status ? status : '';

    try {
      await api
        .get(
          `perfilacesso?pagina=${current}&tamanhoPagina=${tamanhoPagina}&nome=${nomePagina}&status=${statusPagina}`,
        )
        .then((res) => {
          setData(res.data || []);
          setQuantidade(res.data.length);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize, nomePerfilAcesso, status, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}