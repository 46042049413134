import React, { Fragment, useState, useEffect } from 'react';
import { Form, Input, Select, Table, Tooltip } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import { Row, Col } from 'reactstrap';
import { Icon } from 'semantic-ui-react';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Button from '~/shared/Button';
import ModalConfirm from '~/components/ModalConfirm';
import EmptyBox from '~/components/EmptyBox';
import Notify from '~/shared/Notify';
import ModalGenerico from '~/components/ModalGenerico';
import Pessoa from '~/views/private/Cadastros/Pessoa/Manutencao';

import usePessoaDropdown from '~/actions/DropDown/usePessoa';

import url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import formatTelefone from '~/shared/formatTelefone';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  GERAL: 1,
  PORTAIS: 2,
  PROPRIETARIOS: 3,
  IMAGENS: 4,
};

function Proprietarios({
  imovelId,
  modo,
  dataImovelProprietarios,
  onLoadImovelProprietarios,
  identificador,
  keepFilter,
  setActiveTab,
  showTabs
}) {
  const [loading, setLoading] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [pessoaId, setPessoaId] = useState();
  const [updatePercentual, setUpdatePercentual] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [imovelPessoaId, setImovelPessoaId] = useState();

  const { data: dataProprietarios, onLoad: onLoadProprietarios, cancelRequest } = usePessoaDropdown();
  const [openModalPessoa, setOpenModalPessoa] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ porcentagem: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSaveProprietarios() {
    setLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          let retorno = dataImovelProprietarios.reduce(
            (totalPorcentagem, item) => {
              return (totalPorcentagem +=
                updatePercentual && item.id !== imovelPessoaId
                  ? item.porcentagem
                  : 0);
            },
            0,
          );

          retorno += Number(dados.porcentagem);

          if (retorno > 100) {
            setLoading(false);
            Notify(
              'error',
              pt.comum.atencao,
              'O percentual total dos proprietários não pode ultrapassar os 100%',
            );
          } else {
            if (updatePercentual) {
              const payload = {
                pessoaId: dados?.pessoaId,
                imovelPessoaId,
                porcentagem: Number(dados.porcentagem),
              };

              await api
                .put(url.IMOVEL_PROPRIETARIOS.EDITAR(), payload)
                .then((res) => {
                  setLoading(false);
                  onLoadImovelProprietarios(imovelId);
                  setUpdatePercentual(false);
                  form.resetFields();

                  Notify('success', '', 'Salvo com sucesso!');
                  if (showTabs.showTabImages) setActiveTab(TABS.IMAGENS);
                })
                .catch((err) => {
                  setLoading(false);
                  const { error } = err.response.data;
                  if (error) {
                    for (const item in error) {
                      Notify('error', pt.comum.atencao, error[item]);
                    }
                  }
                });
            } else {
              const payload = {
                imovelId,
                pessoa: [
                  {
                    pessoasId: dados.pessoaId,
                    porcentagem: Number(dados.porcentagem),
                  },
                ],
              };

              await api
                .post(url.IMOVEL_PROPRIETARIOS.CADASTRAR(), payload)
                .then((res) => {
                  setLoading(false);

                  Notify('success', '', 'Salvo com sucesso!');
                  if (showTabs.showTabImages) setActiveTab(TABS.IMAGENS);

                  onLoadImovelProprietarios(imovelId);

                  form.resetFields();
                })
                .catch((err) => {
                  setLoading(false);
                  const { error } = err.response.data;
                  if (error) {
                    for (const item in error) {
                      Notify('error', pt.comum.atencao, error[item]);
                    }
                  }
                });
            }
          }
        } else if (modo === MODO.EDITAR) {
        }
      })
      .catch((errorInfo) => {
        setLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function voltar() {
    keepFilter();
    window.close();
  }

  async function handleEditarProprietario(row) {
    setLoadingUpdate(true);

    const params = { current: 1, pageSize: 999999, tipo: row.pessoa.tipo, pessoaId: row.pessoaId };
    await onLoadProprietarios(params);
    setImovelPessoaId(row.id);
    setPessoaId(row.pessoaId);
    setUpdatePercentual(true);
    setLoadingUpdate(false);

    form.setFieldsValue({
      tipo: String(row.pessoa.tipo),
      pessoaId: row.pessoaId,
      porcentagem: row.porcentagem,
    });
  }

  function handleModalConfirm(pessoaId) {
    setOpenModalConfirmDelete(true);
    setPessoaId(pessoaId);
  }

  async function handleConfirmDelete() {
    if (pessoaId) {
      setLoading(true);

      const payload = {
        imovelId,
        pessoaId,
      };

      await api
        .delete(url.IMOVEL_PROPRIETARIOS.DELETAR(), { data: payload })
        .then((response) => {
          setLoading(false);
          setOpenModalConfirmDelete(false);
          Notify('success', '', 'Registro excluído com sucesso!');
          onLoadImovelProprietarios(imovelId);
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirmDelete(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  async function closeModalPessoa() {
    const pessoaId = localStorage.getItem('icasePessoaId');
    setOpenModalPessoa(false);
    cancelRequest();
    await onLoadProprietarios({ pessoaId: pessoaId });
    localStorage.removeItem('icasePessoaId');
    form.setFieldsValue({
      pessoaId: pessoaId,
    });
    onLoadImovelProprietarios(imovelId);
  }

  function handleCloseModal() {
    setOpenModalConfirmDelete(false);
  }

  async function onSearchProprietario(value) {
    setPessoaId(null);
    await onLoadProprietarios({ nome: value });
  }

  const columns = [
    {
      title: 'Proprietário',
      dataIndex: '',
      key: '',
      render: (row) => <span key={row.id}>{row.pessoa.nome}</span>,
    },
    {
      title: 'Celular',
      dataIndex: '',
      key: '',
      render: (row) => (
        <span key={row.id}>{formatTelefone(row.pessoa?.telefones[0]?.numero || '')}</span>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: '',
      key: '',
      render: (row) => (
        <span key={row.id}>
          {row.pessoa.tipo === 1 ? 'Física' : 'Jurídica'}
        </span>
      ),
    },
    {
      title: 'Percentual',
      dataIndex: '',
      key: 'porcentagem',
      render: (row) => (
        <span key={row.id}>{`${row.porcentagem ? row.porcentagem : 0} %`}</span>
      ),
    },
    {
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleEditarProprietario(row)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
    {
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.pessoaId)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
  ];

  const isEmpty = !dataImovelProprietarios.length;

  return (
    <div className="px-4 pb-4">
      <Row>
        <Col>
          {loadingUpdate && <SkeletonTableBasic cardShadowless />}
          {!loadingUpdate && (
            <>
              <Form form={form}>
                <Row className="mt-2 justify-content-md-left">
                  <Col xs="12" md="7" lg="7" className="mr-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Proprietário</small>
                    </p>
                    <Form.Item
                      name="pessoaId"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o proprietário',
                        },
                      ]}>
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if (option.children && option.children.length > 0) {
                            return (
                              option.children
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                ) >= 0
                            );
                          }
                        }}
                        placeholder=" Proprietário"
                        onChange={(value) => setPessoaId(value)}
                        onSearch={onSearchProprietario}>
                        {dataProprietarios.map((item) => (
                          <Option key={item.id}>{item.nome}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs="3" md="1" className="mr-2">
                    <p className="heading-small text-muted mb-1 text-white">
                      <small>&nbsp;</small>
                    </p>
                    <Tooltip
                      placement="topRight"
                      title={`${pessoaId ? 'Editar' : 'Cadastrar'} pessoa`}>
                      <Button
                        style={{ width: '100%' }}
                        // eslint-disable-next-line
                        onClick={() =>
                          setOpenModalPessoa(true)
                        }
                        icon
                        color="violet"
                        disabled={loading}>
                        <Icon name={pessoaId ? 'pencil' : 'plus'} />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col xs="12" md="2" lg="2">
                    <p className="heading-small text-muted mb-2">
                      <small>Percentual</small>
                    </p>
                    <Form.Item
                      className="my-2"
                      name="porcentagem"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o percentual',
                        },
                      ]}>
                      <Input
                        placeholder=" Percentual"
                        type="number"
                        min={0}
                        max={100}
                        suffix={<PercentageOutlined />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row className="my-3">
                <Col xs="12" md="3">
                  <Button
                    onClick={onSaveProprietarios}
                    icon
                    labelPosition="left"
                    fluid
                    color="violet"
                    isloading={loading.toString()}
                    loadtext={
                      modo === MODO.CADASTRAR && !updatePercentual
                        ? 'Cadastrando...'
                        : 'Atualizando...'
                    }>
                    <Icon name="check" />
                    {modo === MODO.CADASTRAR && !updatePercentual
                      ? 'Cadastrar'
                      : 'Atualizar'}
                  </Button>
                </Col>
                <Col xs="12" md="3">
                  <Button
                    icon
                    labelPosition="left"
                    fluid
                    onClick={() => voltar()}>
                    <Icon name="undo" />
                    Voltar
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {!isEmpty && (
            <Fragment>
              <div className="mt-4">
                <Table
                  rowKey={(row) => row.id}
                  columns={columns}
                  dataSource={dataImovelProprietarios}
                />
              </div>
            </Fragment>
          )}
          {isEmpty && (
            <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
          )}

          <ModalConfirm
            isDelete
            icon="warning sign"
            loading={loading}
            open={openModalConfirmDelete}
            confirm={handleConfirmDelete}
            close={handleCloseModal}
            title="Atenção"
            message="Deseja realmente excluir esse registro?"
          />
        </Col>
      </Row>

      <ModalGenerico
        open={openModalPessoa}
        close={() => closeModalPessoa()}
        title={'Pessoa'}>
        <Pessoa
          match={{ params: { modo: pessoaId ? 'editar' : 'cadastrar', ignoreDuplicate: true } }}
          location={{ state: { row: { id: pessoaId } } }}
          close={() => closeModalPessoa()}
          isAtendimento={false}
        />
      </ModalGenerico>
    </div>
  );
}

export default Proprietarios;

