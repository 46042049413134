import {Row, Progress, Col} from 'antd';
import React, {useEffect, useState} from 'react';
import * as signalR from '@microsoft/signalr';
import api from '~/services/api';

const PROGRESS_STORAGE_NAME = 'progress-inativar-usuario';
const LAST_STEP = 'imoveis';
function ProgressBarInativarUsuario({usuarioId}) {
  const [currentStep, setCurrentStep] = useState('atendimentos');
  const [currentProgress, setCurrentProgress] = useState(0);

  JSON.parse(localStorage.getItem(PROGRESS_STORAGE_NAME));

  useEffect(() => {
    const fetchProgress = async () => {
      const response = await api.get(`usuarios/progress`);
      setCurrentStep(response.data.step);
      setCurrentProgress(response.data.progress);
      if (response.data.step === LAST_STEP && response.data.progress === 100) {
        localStorage.setItem(
          PROGRESS_STORAGE_NAME,
          JSON.stringify({step: 'atendimentos', progress: 0}),
        );
      }

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BASE_URL.replace(
            '/api',
            '',
          )}/inativar-usuario-hub`,
          {withCredentials: false},
        )
        .build();

      connection.on('ReceiveProgress', (userReceive, progress) => {
        if (userReceive === usuarioId) {
          setCurrentStep(progress.step);
          setCurrentProgress(progress.progress);
          localStorage.setItem(PROGRESS_STORAGE_NAME, JSON.stringify(progress));
        }
      });

      connection
        .start()
        .catch((err) => console.error('SignalR connection error: ', err));

      return () => {
        connection.stop();
      };
    };

    fetchProgress();
  }, [usuarioId]);

  return (
    <Row className="w-100">
      <Col className="w-100">
        <div className="p-2 mb-4 w-100">
          <p>
            <strong>
              {(() => {
                switch (currentStep) {
                  case 'usuario':
                    return 'Buscando usuário...';
                  case 'atendimentos':
                    return 'Atualizando atendimentos...';
                  case 'imoveis':
                    return 'Atualizando imoveis...';
                  default:
                    return 'Carregando...';
                }
              })()}
            </strong>
          </p>
          <Progress
            percent={currentProgress}
            status="normal"
            size="default"
            strokeColor={{from: '#108ee9', to: '#87d068'}}
          />
        </div>
      </Col>
    </Row>
  );
}

export default ProgressBarInativarUsuario;
