import styled from 'styled-components';
import {Image as AntdImage} from 'antd';

export const ImageContainer = styled.div`
  position: relative;

  width: 150px;
  height: 150px;

  .ant-image {
    width: 150px !important;
    height: 150px !important;
  }

  .ant-image-mask {
    width: 150px !important;
    height: 150px !important;
  }

  .remove-button {
    display: flex;
  }

  transition: all 0.2s;

  &:hover {
    /* Increase scale smoothly */
    transform: scale(1.05);
  }
`;

export const Image = styled(AntdImage)`
  width: 150px !important;
  height: 150px !important;

  object-fit: cover;
`;

export const RemoveImageIcon = styled.button`
  display: flex;

  position: absolute;
  z-index: 999;

  width: 20px;
  height: 20px;

  right: -0.5rem;
  bottom: -0.5rem;
  outline: none;
  border-radius: 9999px;
  padding: 8px;

  align-items: center;
  justify-content: center;

  background-color: #ffff;
  border-width: 0px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: transparent;
  }
`;

export const HighlightContainer = styled.div`
  position: absolute;
  z-index: 998;
  width: 100%;
  bottom: 0px;

  text-align: center;
  padding-block: 4px;

  background-color: rgba(255, 255, 255, 0.7);

  > p {
    font-weight: 400;
    text-transform: uppercase;
    color: #333;
  }
`;

