const SituacaoImovel = [
  {nome: 'Disponível', id: 1},
  {nome: 'Alugado', id: 2},
  {nome: 'Retirado de Oferta', id: 3},
  {nome: 'Reservado', id: 4},
  {nome: 'Cadastro', id: 5},
  {nome: 'Em Avaliação', id: 6},
  {nome: 'Vendido', id: 7},
  {nome: 'Com Proposta', id: 8},
  {nome: 'Lançamento', id: 9},
];

export default SituacaoImovel;
