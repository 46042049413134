/*eslint-disable*/
import {BehaviorSubject} from 'rxjs';

import history from '../services/history';
import removeSessions from './removeSessions';

const currentUserSubject = new BehaviorSubject(
  localStorage.getItem('icaseUser'),
);

export const authenticationService = {
  logout,
  icaseUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function logout() {
  removeSessions();
  currentUserSubject.next(null);
  history.push('/');
}
