import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Table, Tooltip, message } from 'antd';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Page from '~/components/Page';
import getTiposMidia from '~/infra/resources/strings/enums/getTiposMidia';

import useListagemUrl from '~/actions/ListagemUrl/useListagemUrl';

message.config({ duration: 1.5 });

function ListagemUrl() {
  const { isLoading, data } = useListagemUrl();

  async function copyURL(text) {
    try {
      await navigator.clipboard.writeText(text);
      message.success('URL copiada com sucesso!');
    } catch(err) {
      message.error('Ocorreu um erro ao copiar a URL!');
    }
  }

  const columns = [
    {
      title: 'Empresa',
      dataIndex: '',
      key: '',
      render: (row) => (
        <span>{row?.company?.name || ''}</span>
      ),
    },
    {
      title: 'Portal',
      dataIndex: '',
      key: '',
      render: (row) => (
        <span>{getTiposMidia(row?.media_type || 0)}</span>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'xml_url',
      key: 'xml_url',
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          <Tooltip placement="topRight" title='Copiar URL'>
            <i
              onClick={() => copyURL(row.xml_url)}
              className="ri-clipboard-line linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    isLoading ? <SkeletonTableBasic cardShadowless /> :
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Listagem de URLs</h5>
                <p className="category">Listagem de URLs</p>
              </CardHeader>
              <CardBody>
                <div className="p-4">
                  <Table
                    rowKey={(row) => row.id}
                    columns={columns}
                    dataSource={data}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ListagemUrl;