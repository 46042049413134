import {useCallback, useEffect, useState} from 'react';
import api from '~/services/api';

export default function useBirthdayOfTheDay() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(async () => {
    try {
      await api
        .get('Dashboard/gerente/aniversarios/dia')
        .then((res) => {
          setData(res?.data?.data);
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    onLoad,
    isLoading,
  };
}

