import React, { Fragment } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Divider } from 'antd';
import { Row, Col } from 'reactstrap';

function ModalMaisInfoAnalise({ open, close, row }) {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={true}
        size="mini"
        dimmer="blurring"
        open={open}
        onClose={() => close()}>
        <Modal.Content>
          <Row>
            <Col>
              <div className="p-2">
                <Row>
                  <Col xs="12">
                    <Divider>Corretor Responsável</Divider>
                    <p className="heading-small text-muted mb-2">
                      <small>Nome</small>
                    </p>
                    <label>{row?.corretor?.nome || '--'}</label>
                    <Divider>Cliente</Divider>
                    <p className="heading-small text-muted mb-2">
                      <small>Nome</small>
                    </p>
                    <label>{row?.cliente?.nome || '--'}</label>
                    <p className="heading-small text-muted mb-2">
                      <small>Celular</small>
                    </p>
                    <label>{row?.cliente?.celular || '--'}</label>
                    <p className="heading-small text-muted mb-2">
                      <small>E-mail</small>
                    </p>
                    <label>{row?.cliente?.email || '--'}</label>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Button icon labelPosition="left"
            onClick={() => close()}>
            <Icon name="angle left" />
            Voltar
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}



export default ModalMaisInfoAnalise;
