import styled from 'styled-components';

import { Col, Row } from 'reactstrap';
import { device } from '~/styles/dimensions';

export const CardGrid = styled(Col).attrs(() => ({
  xs: '12',
}))``;

export const ContentRow = styled(Row)`
  flex-direction: column-reverse;

  ${device.xLarge} {
    flex-direction: row;
  }

  ${device.xxLarge} {
    flex-direction: row;
  }
`;
