import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Pagination} from 'antd';

import Page from '~/components/Page';
import usePrioridadeDoDia from '~/actions/Atendimento/usePrioridadeDoDia';
import EmptyBox from '~/components/EmptyBox';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import RenderCardAtendimentos from '~/views/private/Cadastros/Atendimentos/Consulta/RenderCardAtendimentos';

function PrioridadeDoDia() {
  const [current, setCurrent] = useState(1);
  const [loadCards, setLoadCards] = useState(false);
  const {isLoading, data, quantidade, onLoad} = usePrioridadeDoDia(
    current,
    12,
    null,
    null,
    null,
    null,
    null,
    true,
    null,
    null,
    null,
    null,
    false,
    null
  );
  
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      onLoad();
      setIsFirstRender(false);
    }
  }, [onLoad, isFirstRender]);

  async function changePage(page) {
    setLoadCards(true);
    setCurrent(page);

    setTimeout(() => {
      setLoadCards(false);
    }, 1100);
  }

  const isEmpty = !isLoading && !data.length;

  const renderPrioridades = data?.map((cliente, index) => {
    if (cliente) {
      return (<RenderCardAtendimentos
        key={index}
        row={cliente}
        index={index} 
        empresaId={cliente?.empresaId}
        parmIdentificador={cliente?.identificador}
      />);
    }

    return <></>;
  })

  return (
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Prioridade do dia</h5>
                <p className="category">Controle de atendimentos</p>
              </CardHeader>
              <CardBody>
                {loadCards || isLoading ? (
                  <Row className="mt-4">
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                  </Row>
                ) : null}
                {!isEmpty && !isLoading && !loadCards && (
                  <Fragment>
                    <Row className="mt-4">
                      {renderPrioridades}
                    </Row>
                    <Row className="mt-4 p-2">
                      <Pagination
                        current={current}
                        defaultPageSize={12}
                        defaultCurrent={1}
                        total={quantidade}
                        onChange={changePage}
                        showSizeChanger={false}
                      />
                    </Row>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Não existem atendimentos com data de próximo contato para hoje." />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default PrioridadeDoDia;
