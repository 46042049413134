import React, {Fragment, useState, useEffect} from 'react';
import {Form, Input, Select} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Icon} from 'semantic-ui-react';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import useEmpresa from '~/actions/Empresa/useEmpresa';
import Page from '~/components/Page';
import EmptyBox from '~/components/EmptyBox';
import useMaster from '~/actions/Master/useMaster';
import HeaderFiltros from '~/components/HeaderFiltro';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CardUSer from '~/components/CardUser';

import enumPessoaTipo from '~/infra/resources/strings/enums/tipoPessoa';

import url from '~/infra/urls';
import api from '~/services/api';

const {Option} = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(row) {
  history.push({
    pathname: `/app/master/manutencao/${MODO.EDITAR}`,
    state: {row},
  });
}

export function navToNovo() {
  history.push(`/app/master/manutencao/${MODO.CADASTRAR}`);
}

function ConsultaMaster() {
  const [loadCards, setLoadCards] = useState(true);
  const [nome, setNome] = useState('');
  const [type, setType] = useState(undefined);
  const [cpf, setCPF] = useState('');
  const [cnpj, setCNPJ] = useState('');
  const [empresaId, setEmpresaId] = useState('');
  const [selectedType, setSelectedType] = useState();

  const {data: Empresas} = useEmpresa(1, 9999, null, null);
  const {isLoading, data} = useMaster(nome, type, cpf, cnpj, empresaId);

  const [form] = Form.useForm();

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));

  const administrador = roles.find((role) => role === 'SystemAdmin');

  useEffect(() => {
    if (data) {
      setLoadCards(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function onFiltros() {
    setLoadCards(true);

    const {nome, type, cpf, cnpj, empresaId} = form.getFieldsValue();

    setEmpresaId(empresaId);
    setNome(nome);
    setType(type ? Number(type) : undefined);
    setCPF(Number(type) === 1 ? cpf : '');
    setCNPJ(Number(type) === 2 ? cnpj : '');
  }

  function onChangeTipo(value) {
    setSelectedType(Number(value));
  }

  async function resetPassword(email) {
    setLoadCards(true);

    const payload = {
      email,
    };

    await api
      .put(url.LOGIN.RECUPERAR_SENHA(), payload)
      .then((res) => {
        setLoadCards(false);
        Notify('success', '', 'Senha reiniciada com sucesso!');
      })
      .catch((err) => {
        setLoadCards(false);
        const {errors} = err.response.data;

        if (errors) {
          for (const item in errors) {
            if (item.length > 0) {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            } else {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            }
          }
        }
      });
  }

  const isEmpty = !isLoading && !loadCards && data.length === 0;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de master</h5>
                <p className="category">Manutenção master</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form form={form} scrollToFirstError>
                        {administrador && (
                          <Row className="mb-4">
                            <Col xs="12" md="6">
                              <p className="heading-small text-muted mb-2">
                                <small>Empresa</small>
                              </p>
                              <Form.Item
                                className="my-2"
                                name="empresaId"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Favor informar o Empresa',
                                  },
                                ]}>
                                <Select placeholder=" Empresa">
                                  {Empresas.map((item) => (
                                    <Option key={item.id}>{item.nome}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item className="my-2" name="nome">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>Tipo</small>
                            </p>
                            <Form.Item className="my-2" name="type">
                              <Select
                                placeholder=" Tipo"
                                onChange={onChangeTipo}
                                defaultValue={selectedType}>
                                {enumPessoaTipo.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.nome}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {(!selectedType || selectedType === 1) && (
                            <Col xs="12" md="4">
                              <p className="heading-small text-muted mb-2">
                                <small>CPF</small>
                              </p>
                              <Form.Item className="my-2" name="cpf">
                                <Input placeholder=" CPF" />
                              </Form.Item>
                            </Col>
                          )}
                          {selectedType === 2 && (
                            <Col xs="12" md="4">
                              <p className="heading-small text-muted mb-2">
                                <small>CNPJ</small>
                              </p>
                              <Form.Item className="my-2" name="cnpj">
                                <Input placeholder=" CNPJ" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              color="green"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${data.length})`}</h5>
                <HeaderFiltros
                  navToNovo={() => navToNovo()}
                  description="Novo Master"
                />
              </CardHeader>
              <CardBody>
                {loadCards || isLoading ? (
                  <Row className="mt-4">
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                  </Row>
                ) : null}
                {!isLoading && !loadCards && !isEmpty && (
                  <Fragment>
                    <Row className="px-2">
                      {data.map((master, index) => (
                        <Col xs="12" md="6" className="p-1">
                          <CardUSer
                            key={index}
                            user={master}
                            type="master"
                            showEdit
                            url={`/app/master/manutencao/${MODO.EDITAR}`}
                            showResetPassword
                            functionResetPassword={resetPassword}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaMaster;
