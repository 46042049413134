import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Page from '~/components/Page';

import Button from '~/shared/Button';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import SortableTable from '../sortableTable';
import HeaderFiltro from '~/components/HeaderFiltro';
import ModalGenerico from '~/components/ModalGenerico';
import useDistrbuicao from '~/actions/Empresa/useDistrbuicao';
import ModalConfirm from '~/components/ModalConfirm';
import ManutDistribuicaoLeads from './manutDistribuicao';
import LogRecepcaoLeads from './components/logRecepcaoLeads';


function Distribuicao() {
  const [openModalDistrbuicao, setOpenModalDistrbuicao] = useState({ open: false, id: null, modo: 'cadastrar' });
  const [openModalLog, setOpenModalLog] = useState({ open: false, id: null});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [distribuicaoId, setDistribuicaoId] = useState(0);

  const { data, isLoading, onLoad, atualizaOrdem, excluir } = useDistrbuicao();


  const closeModalDistrbuicao = () => {
    onLoad();
    setOpenModalDistrbuicao({ open: false, id: null, modo: 'cadastrar' });
    // onLoadDistrbuicaos({ cidadeId });
  }


  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setDistribuicaoId(id);
  }


  return (
    <Page loadPage={true}>
      <Row>
        <Col>
          <Card className="p-2">
            <CardHeader>
              <h5>Configurações para Distribuição de Leads</h5>
              <p className="category">Controle de parâmetros</p>
              <h5 className="title mt-1">{`Qtd. Regras: (${data?.length || 0})`}</h5>
              <HeaderFiltro
                navToNovo={() => setOpenModalDistrbuicao({ open: true, id: null, modo: 'cadastrar', requireConfirmation: true})}
                description="Nova Regra">
                <Col xs="12" md="3">
                  <div className="pt-2">
                    <Button
                      className="my-1"
                      size="small"
                      icon
                      labelPosition="left"
                      fluid
                      onClick={onLoad}>
                      Buscar
                    </Button>
                  </div>
                </Col>
              </HeaderFiltro>
            </CardHeader>
            <CardBody>
              <div className="p-2">
                {isLoading
                  ? <SkeletonTableBasic />
                  : !data || data.length === 0 ? <span>Sem dados...</span>
                    : <SortableTable
                      data={data}
                      atualizaOrdem={atualizaOrdem}
                      handleEdit={(id) => setOpenModalDistrbuicao({ open: true, id: id, modo: 'editar', requireConfirmation: true})}
                      handleView={(id) => setOpenModalDistrbuicao({ open: true, id: id, modo: 'resumo', requireConfirmation: false })}
                      handleDrop={handleModalConfirm}
                      handleLog={(id) => setOpenModalLog({ open: true, id: id})}
                      handleOrder={(order, id) => atualizaOrdem(id, order)}
                    />}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>


      <ModalGenerico
        requireConfirmation={openModalDistrbuicao.requireConfirmation}
        open={openModalDistrbuicao.open}
        close={closeModalDistrbuicao}
        title={'Regra de Distribuição de Leads'}>
        <ManutDistribuicaoLeads
          open={openModalDistrbuicao?.open}
          modo={openModalDistrbuicao?.modo}
          id={openModalDistrbuicao?.id}
          onVoltar={closeModalDistrbuicao}
        />
      </ModalGenerico>

      <ModalGenerico
        open={openModalLog.open}
        close={() => setOpenModalLog({ open: false, id: null})}
        title={'Histórico/Log de Recepção de Leads'}>
        <LogRecepcaoLeads id={openModalLog.id} open={openModalLog.open} />
      </ModalGenerico>

      <ModalConfirm
        isDelete
        icon="warning sign"
        loading={isLoading}
        open={openModalConfirm}
        confirm={() => {
          setOpenModalConfirm(false);
          excluir(distribuicaoId);
        }}
        close={() => setOpenModalConfirm(false)}
        title="Atenção"
        message="Deseja realmente excluir esse registro?"
      />

    </Page>
  );
}

export default Distribuicao;

