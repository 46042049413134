import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

export default function useImpostoRenda(
    current,
    pageSize,
    corretorId,
    statusImpostoRenda,
    pesquisa
    ) {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [quantidade, setQuantidade] = useState(null);

    const onLoad = useCallback(async () => {
        setIsLoading(true);

        const payload = {
            numeroPagina: current,
            tamanhoPagina: pageSize,
            corretorId: (corretorId && corretorId !== 'TODOS') ? corretorId : null,
            statusImpostoRenda: statusImpostoRenda || 0,
            pesquisa
        };

        try {
            await api.post(url.IMPOSTO_RENDA.FILTER(), payload).then((res) => {
                setData(res?.data?.data || []);
                setQuantidade(res.data.total);
            });
        } catch (e) {
            setData([]);
        } finally {
            setIsLoading(false);
        }
    }, [current, pageSize, corretorId, statusImpostoRenda, pesquisa]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    return {
        data,
        quantidade,
        isLoading,
        onLoad,
    };
}
