/* eslint-disable */
import React, {Fragment, useState, useEffect} from 'react';
import {BehaviorSubject} from 'rxjs';

import security from '~/assets/img/key.svg';
import Page from '~/components/Page';
import history from '~/services/history';
import Notify from '~/shared/Notify';
import Url from '~/infra/urls';
import api from '~/services/api';
import jwtDecode from 'jwt-decode';

function AccessAtendimentos() {
  const [loadPage, setloadPage] = useState(true);

  const currentUserSubject = new BehaviorSubject(
    localStorage.getItem('icaseUser'),
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const Token = query.get('token');
    const UsuarioId = query.get('usuarioid');
    const Tipo = query.get('tipousuario');
    const empresaWEBId = query.get('empresaid');

    setTimeout(async () => {
      if (Token && UsuarioId && Tipo && EmpresaId) {
        const payload = {
          Token,
          UsuarioId: parseInt(UsuarioId),
          Tipo: parseInt(Tipo),
          EmpresaWebId: parseInt(empresaWEBId),
        };

        api
          .post(Url.LOGIN.DESKTOP(), payload)
          .then((user) => {
            const {token, refreshToken} = user.data.token;
            const usuarioId = user.data.data.usuarioId;
            const pessoaId = user.data.data.pessoaId;
            const tipo = user.data.data.tipo;
            const empresaId = user.data.data.empresaId;

            const {unique_name: userCpf, exp} = jwtDecode(token);

            localStorage.setItem('icaseUserCpf', userCpf);
            localStorage.setItem('icaseEmpresaWEBId', empresaWEBId);
            localStorage.setItem('icaseEmpresaId', empresaId);
            localStorage.setItem('icaseToken', token);
            localStorage.setItem('icasetokenExpiration', exp);
            localStorage.setItem('icaseRefreshToken', JSON.stringify(refreshToken));
            localStorage.setItem('icaseOperadorId', usuarioId);
            localStorage.setItem('icaseOperadorTipo', tipo);
            localStorage.setItem('icasePessoaId', pessoaId);
            localStorage.setItem('icaseUser', JSON.stringify(user.data.data));

            const roles = user.data.data.perfis;
            let perfis = [];
            roles.map((item) => {
              perfis.push(item.nomePerfil);
            });

            //localStorage.setItem('icaseRoles', JSON.stringify(perfis));
            const master = perfis.find((perfil) => perfil === 'MASTER LEADS');
            if (master) {
              localStorage.setItem('icaseRoles', JSON.stringify(['MASTER']));
            } else {
              localStorage.setItem('icaseRoles', JSON.stringify(['CORRETOR']));
            }

            currentUserSubject.next(user.data.data);
            setloadPage(false);
            history.push('/app');
          })
          .catch((err) => {
            Notify(
              'error',
              'Atenção',
              'Dados de acesso não conferem, favor realizar o login !',
            );
            history.push('/');
          });
      } else {
        Notify(
          'error',
          'Atenção',
          'Dados de acesso não conferem, favor realizar o login !',
        );
        history.push('/');
      }
    }, 500);
  }, []);

  return (
    <Fragment>
      <Page
        loadPage={loadPage}
        text="Validando acesso..."
        icon={security}></Page>
    </Fragment>
  );
}

export default AccessAtendimentos;
