import React, {Fragment} from 'react';
import {Card, CardBody, Row, Col} from 'reactstrap';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

export function renderLineNumber(fake, index) {
  let retorno;

  retorno = (
    <Row key={index}>
      <Col md="10" xs="12">
        <div>
          <Box>
            <Skeleton height="30px" />
          </Box>
        </div>
      </Col>
      <Col md="2" xs="12">
        <div>
          <Box>
            <Skeleton height="30px" />
          </Box>
        </div>
      </Col>
    </Row>
  );

  return retorno;
}

function SkeletonTable({cardShadowless, lineNumber}) {
  let arrayNumber = [];
  if (lineNumber) {
    let i;
    for (i = 0; i <= lineNumber; i++) {
      arrayNumber.push(i);
    }
  }

  return (
    <Card className={cardShadowless ? 'shadow-none p-1 mb-0' : 'card-custom'}>
      <CardBody>
        {!lineNumber ? (
          <Fragment>
            <Row>
              <Col md="10" xs="12">
                <div>
                  <Box>
                    <Skeleton height="30px" />
                  </Box>
                </div>
              </Col>
              <Col md="2" xs="12">
                <div>
                  <Box>
                    <Skeleton height="30px" />
                  </Box>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <div className="my-1">
                  <Box>
                    <Skeleton height="30px" />
                  </Box>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="10" xs="12">
                <div className="my-1">
                  <Box>
                    <Skeleton height="30px" />
                  </Box>
                </div>
              </Col>
              <Col md="2" xs="12">
                <div className="my-1">
                  <Box>
                    <Skeleton height="30px" />
                  </Box>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <div className="my-1">
                  <Box>
                    <Skeleton height="30px" />
                  </Box>
                </div>
              </Col>
            </Row>
          </Fragment>
        ) : arrayNumber ? (
          arrayNumber.map((fake, index) => renderLineNumber('', index))
        ) : null}
      </CardBody>
    </Card>
  );
}

export default SkeletonTable;
