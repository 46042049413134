import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select, Tooltip } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';

import useTipoLogradouro from '~/actions/TipoLogradouro/useTipoLogradouro';
import useCidadesDropdown from '~/actions/DropDown/useCidadesDropdown';
import useBairroDropdown from '~/actions/DropDown/useBairroDropdown';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import url from '~/infra/urls';
import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';

import ModalGenerico from '~/components/ModalGenerico';
import ManutencaoBairro from '~/views/private/Cadastros/Bairros/Manutencao';


const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Logradouro(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [identificador, setIdentificador] = useState();
  const [logradouroId, setLogradouroId] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalBairro, setOpenModalBairro] = useState(false);

  const [bairroDisabled, setBairroDisabled] = useState(true);
  const [loadingBairro, setLoadingBairro] = useState(false);

  const [cidadeId, setCidadeId] = useState();
  const [cidade, setCidade] = useState();
  const [nomeBairro, setNomeBairro] = useState();

  const { data: dataTipoLogradouros } = useTipoLogradouro();
  const { data: dataCidades } = useCidadesDropdown(undefined, cidade);
  const { data: dataBairros, onLoad: onLoadBairros } = useBairroDropdown(cidadeId, nomeBairro);

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetch() {
      if (props.match.params) {
        if (props.match.params.modo === MODO.EDITAR) {
          const { row: editData, identificador } = props.location.state;

          editData.cidadeId = String(editData.bairro.cidadeId);
          editData.cep = editData.cep ? mask(editData.cep, [pattern.cep]) : '';

          setIdentificador(identificador);
          setInitialValues(editData);
          setModo(MODO.EDITAR);
          setLogradouroId(props.location.state.row.id);
          setCidadeId(String(editData?.bairro?.cidadeId));
          setCidade(editData?.bairro?.cidade?.nome)
          setNomeBairro(editData?.bairro?.nome);
          setBairroDisabled(false);

          setTimeout(() => {
            setloadPage(false);
          }, 2000);
        } else {
          const { identificador } = props.location.state;

          setIdentificador(identificador);
          setModo(MODO.CADASTRAR);
          setloadPage(false);
        }
      }
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  async function onChangeCidade(cidadeId) {
    if (cidadeId) {
      form.setFieldsValue({ bairroId: [] });
      setCidadeId(cidadeId);
      setNomeBairro(undefined);
      setBairroDisabled(false);
      setLoadingBairro(false);
    } else {
      setCidade(undefined);
      setNomeBairro(undefined);
      setBairroDisabled(true);
      form.setFieldsValue({ bairroId: [] });
    }
  }

  async function onSearchCidade(value) {
    setCidade(value);
  }

  async function onSearchBairro(value) {
    if (value) setNomeBairro(value);
  }

  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/logradouros/${identificador}`);
    } else {
      history.push('/app/logradouros');
    }
  }

  const closeModalBairro = () => {
    setOpenModalBairro(false);
    onLoadBairros({ cidadeId });
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            bairroId: dados.bairroId,
            tipoLogradouroId: dados.tipoLogradouroId,
            nome: dados.nome,
            cep: dados.cep.replace(/\D/g, ''),
            numeroDe: 1,
            numeroAte: 1,
          };

          await api
            .post(url.LOGRADOURO.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);

              if (props?.onVoltar) {
                localStorage.setItem('icaseLogradouroId', res.data?.data?.id);
                localStorage.setItem('icaseLogradouroDsc', res.data?.data?.nome);
              }

              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { errors } = err.response.data;
              if (typeof String(errors)) {
                Notify('error', pt.comum.atencao, errors);
              } else {
                for (const item in errors) {
                  Notify('error', pt.comum.atencao, errors[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            logradouroId,
            bairroId: dados.bairroId,
            tipoLogradouroId: dados.tipoLogradouroId,
            nome: dados.nome,
            cep: dados.cep.replace(/\D/g, ''),
            numeroDe: 1,
            numeroAte: 1,
          };

          await api
            .put(url.LOGRADOURO.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (typeof String(error)) {
                Notify('error', pt.comum.atencao, error);
              } else {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function handleMask(e) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [pattern.cep]);

    form.setFieldsValue({ cep: value });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? 'Cadastrar logradouro'
                    : 'Atualizar logradouro'}
                </h5>
                <p className="category">Manutenção de logradouro</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="nome"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o nome!',
                                },
                              ]}>
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Tipo do Logradouro</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="tipoLogradouroId"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Favor informar o Tipo do Logradouro!',
                                },
                              ]}>
                              <Select
                                getPopupContainer={(node) => node.parentNode}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder=" Tipo do Logradouro"
                              >
                                {dataTipoLogradouros.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.descricao}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Cidade</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="cidadeId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a cidade!',
                                },
                              ]}>
                              <Select
                                getPopupContainer={(node) => node.parentNode}
                                showSearch
                                filterOption={false}
                                placeholder="Cidade"
                                placement='bottomLeft'
                                allowClear
                                onSearch={onSearchCidade}
                                onClear={onChangeCidade}
                                onChange={onChangeCidade}>
                                {dataCidades.map((item) => (
                                  <Option key={item.id}>{item.nome}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="5">
                            <p className="heading-small text-muted mb-2">
                              <small>Bairro</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="bairroId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o Bairro!',
                                },
                              ]}>
                              <Select
                                getPopupContainer={(node) => node.parentNode}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder={
                                  loadingBairro ? ' Carregando...' : ' Bairro'
                                }
                                allowClear
                                onSearch={onSearchBairro}
                                onClear={onSearchBairro}
                                disabled={bairroDisabled}>
                                {dataBairros.map((item) => (
                                  <Option key={item.id}>{item.nome}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="1">
                            <p className="heading-small text-muted mb-1 text-white">
                              <small>.</small>
                            </p>
                            <Tooltip
                              placement="topRight"
                              title={'Cadastrar Bairro'}>
                              <Button
                                style={{ width: '100%' }}
                                onClick={() => setOpenModalBairro(true)}
                                icon
                                color="violet">
                                <Icon name="plus" />
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>CEP</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="cep"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o CEP!',
                                },
                              ]}>
                              <Input
                                placeholder=" CEP"
                                onKeyUp={(e) => handleMask(e)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="my-4 py-4">
                        <Col xs="12" md="3">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR
                                ? 'Cadastrando...'
                                : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR
                              ? 'Cadastrar'
                              : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="undo" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={voltar}
              message={modo === MODO.CADASTRAR ? 'Cadastrado com sucesso' : 'Alterações salvas'}
            />
          </Col>
        </Row>

        <ModalGenerico
          open={openModalBairro}
          close={closeModalBairro}
          title={'Bairro'}>
          <ManutencaoBairro
            match={{ params: { modo: 'cadastrar' } }}
            location={{ state: { row: { id: 0 } } }}
            onVoltar={closeModalBairro}
          />
        </ModalGenerico>
      </Page>
    </Fragment>
  );
}

export default Logradouro;
