import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';


import 'moment/locale/pt-br';
import moment from 'moment';
import { FilterCache } from '~/shared/filterCache';
moment.locale('pt-BR');

export default function usePrioridadeDoDia(
  current,
  pageSize,
  situacao,
  pesquisa,
  status,
  validade,
  corretorId,
  somentePrioridadesDoDia = false,
  tipoData,
  dateRange,
  plantaoId,
  tipoMidia,
  semCorretor = false,
  statusFunil,
  statusOcorrencia,
  tipoAtendimentoId
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);


    let dataIni = undefined;
    let dataFim = undefined;
    if (dateRange && (dateRange[0] || dateRange[1]) && tipoData) {
      dataIni = dateRange[0]?.format('YYYY/MM/DD');
      dataFim = dateRange[1]?.format('YYYY/MM/DD');
    }

    const payload = {
      numeroPagina: current,
      tamanhoPagina: pageSize,
      situacao,
      pesquisa,
      status,
      validade,
      corretorId: (corretorId && corretorId !== 'TODOS') ? corretorId : null,
      somentePrioridadesDoDia,
      tipoData,
      dataIni,
      dataFim,
      plantaoId,
      tipoMidia,
      semCorretor,
      statusFunil,
      statusOcorrencia,
      tipoAtendimentoId
    };

    FilterCache.setFiltrosAtendimentos(payload);

    try {
      await api.post(url.ATENDIMENTO.FILTER(), payload).then((res) => {
        setData(res?.data?.data || []);
        setQuantidade(res.data.total);
      });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [current, pageSize, situacao, pesquisa, status, validade, corretorId, somentePrioridadesDoDia, dateRange, tipoData, plantaoId, tipoMidia, semCorretor, statusFunil, statusOcorrencia, tipoAtendimentoId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
