/* eslint-disable no-unused-vars */
import {useMemo, useState} from 'react';
import useCardWithBadge from '../components/charts/CardWithBadge/use-card-with-badge';

import {
  mainPeriodOptions,
  compareWithOptions,
  generateFakeFunnel,
  generateDaysWithoutSelling,
  generateRankingCaptors,
  generateBirthdayOfTheDay,
} from './assets-dashboard-gerente';

export default function useDashboardGerente() {

  const [salesFunnel, setSalesFunnel] = useState(generateFakeFunnel(6));
  const [daysWithoutSelling, setDaysWithoutSelling] = useState(
    generateDaysWithoutSelling(8),
  );

  const [rankingCaptors, setRankingCaptors] = useState(
    generateRankingCaptors(4),
  );

  const [birthdayOfTheDay, setBirthdayOfTheDay] = useState(
    generateBirthdayOfTheDay(3),
  );

  const [filters, setFilters] = useState({
    mainPeriod: '30d',
    compareWith: 'previous',
    broker: null,
    brokerGroup: null,
    initialDate: null,
    finalDate: null,
  });

  const showRangePicker = useMemo(() => {
    return filters.mainPeriod === 'data-personalizada';
  }, [filters.mainPeriod]);

  const handleChangeFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const {data: totalByTypeData} = useCardWithBadge(
    filters.mainPeriod,
    filters.compareWith,
    filters.broker,
    filters.brokerGroup,
    filters.initialDate,
    filters.finalDate,
  );

  return {
    filters,
    setFilters,
    mainPeriodOptions,
    compareWithOptions,
    handleChangeFilter,
    totalByTypeData,
    salesFunnel,
    daysWithoutSelling,
    rankingCaptors,
    birthdayOfTheDay,
    showRangePicker,
  };
}
