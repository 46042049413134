/* eslint-disable react-hooks/exhaustive-deps */
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownloadOutlined,
  LinkOutlined,
  LoadingOutlined,
  SendOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {pdf} from '@react-pdf/renderer';
import {
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Pagination,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {Icon, Transition} from 'semantic-ui-react';

import CardImovel from '~/components/CardImovel';
import HeaderFiltros from '~/components/HeaderFiltro';
import Page from '~/components/Page';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';

import useCaptador from '~/actions/Corretor/useCaptador';
import useBairroImovel from '~/actions/DropDown/useBairroImovel';
import useCidadesDropdownGrupos from '~/actions/DropDown/useCidadesDropdownGrupos';
import useEmpresasDropdown from '~/actions/DropDown/useEmpresasDropdown';
import useImovelCidadesDropdown from '~/actions/DropDown/useImovelCidadesDropdown';
import useProprietariosDropdown from '~/actions/DropDown/useProprietariosDropdown';
import useTipoImovel from '~/actions/DropDown/useTipoImovel';
import useConfigSistema from '~/actions/Empresa/useConfigSistema';
import useFinalidades from '~/actions/Finalidades/useFinalidades';
import useImovel from '~/actions/Imovel/useImovel';

import enumBenfeitoriasImovel from '~/infra/resources/strings/enums/benfeitoriasImovel';
import enumMidiaDivulgacao from '~/infra/resources/strings/enums/midiaDivulgacao';
import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import enumSituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';

import * as configConst from '~/infra/config/configSistemaConst';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import {FilterCache} from '~/shared/filterCache';
import formatedReal from '~/shared/formatedReal';

import SortByDropdown from '~/components/SortByDropdown';
import ModalRelatorioImoveis from './ModalRelatorioImoveis';
import ImovelDocumentDefault from './ModalRelatorioImoveis/ImovelDocumentDefault';
import ImovelDocumentModelo2 from './ModalRelatorioImoveis/ImovelDocumentModelo2';
import ModalSendApresentation from './ModalSendApresentation';

import {ContentRow} from './styles';

import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

import {useMediaQuery} from 'react-responsive';

import useCondominioDropdown from '~/actions/DropDown/useCondomionio';
import ModalApresentacaoImoveis from './ModalApresentacaoImoveis';

moment.locale('pt-BR');

const dateFormat = 'DD/MM/YYYY';

const {Option, OptGroup} = Select;
const {RangePicker} = DatePicker;
const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const options = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5 ou +', value: 5},
];

const optionsSimple = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4 ou +', value: 4},
];

const typesOfApresentation = {
  EMAIL: 1,
  WHATSAPP: 2,
  DOWNLOAD: 3,
};

const antIcon = (
  <Fragment>
    <LoadingOutlined spin style={{marginRight: 5, fontSize: 14}} />
    <span>Carregando...</span>
  </Fragment>
);

const estadosIds = [];
const cidadesIds = [];
const bairrosIds = [];
const logradourosIds = [];
const condominiosIds = [];
let filterRequired = false;
let timeoutId;

export function rowEdit(row, identificador) {
  // history.push({
  //   pathname: `/app/imoveis/manutencao/${MODO.EDITAR}`,
  //   state: { row, identificador },
  // });

  window.open(
    `/app/imoveis/manutencao/${MODO.EDITAR}/${identificador}/${row.id}`,
    '_blank',
  );
}

export function rowPreview(row, identificador) {
  window.open(
    `/app/imoveis/preview/visualizar/${identificador}/${row.id}`,
    '_blank',
  );
}

export function navToNovo(identificador) {
  // history.push({
  //   pathname: `/app/imoveis/manutencao/${MODO.CADASTRAR}`,
  //   state: { identificador },
  // });

  window.open(
    `/app/imoveis/manutencao/${MODO.CADASTRAR}/${identificador}`,
    '_blank',
  );
}

function ConsultaImoveis(props) {
  const cacheFiltros = {};
  const corretorId = localStorage.getItem('icaseUsuarioId');

  const [sortBy, setSortBy] = useState('mais_recentes');
  const [simpleFilters, setSimpleFilters] = useState(true);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(null);
  const [loadCards, setLoadCards] = useState(false);
  const [loadingBairro, setLoadingBairro] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [loadButtonRelatorio, setLoadButtonRelatorio] = useState(false);
  const [bairroDisabled, setBairroDisabled] = useState(true);
  const [tipoImoveis, setTipoImoveis] = useState([]);
  const [tipoImovelDisabled, setTipoImovelDisabled] = useState(true);
  const [clearFiltroQuartos, setClearFiltroQuartos] = useState(0);
  const [clearFiltroVagas, setClearFiltroVagas] = useState(0);
  const [clearFiltroSuites, setClearFiltroSuites] = useState(0);
  const [clearFiltroBanheiros, setClearFiltroBanheiros] = useState(0);
  const [modalRelatorioOpen, setModalRelatorioOpen] = useState(false);
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);
  const [showModalApresentacaoOpen, setShowModalApresentacaoOpen] =
    useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [actions, setActions] = useState([]);
  const [hasSearching, setHasSearching] = useState(false);
  const [maisFiltros, setMaisFiltros] = useState(true);
  const [modeloRelatorio, setModeloRelatorio] = useState(1);
  const [showClearSelection, setShowClearSelection] = useState(false);
  const [realStateForPresentation, setRealStateForPresentation] = useState([]);
  const [dataLancamento, setDataLancamento] = useState([]);
  const [typeOfApresentation, setTypeOfApresentation] = useState(
    typesOfApresentation.EMAIL,
  );
  const [tipoData, setTipoData] = useState();
  // DADOS DOS FILTROS
  const [filterParams, setFilterParams] = useState({});
  const [cidadeId, setCidadeId] = useState(null);

  // CHAMADAS DOS ACTIONS
  const {data: dataCondominios, onLoad: onLoadCondominio} =
    useCondominioDropdown(null, null);
  const {data: dataFinalidades} = useFinalidades();
  const {
    data: dataCidades,
    onLoad: onLoadCidadesDropdown,
    isLoading: isLoadingCidade,
  } = useImovelCidadesDropdown(null, null, []);
  const {data: dataBairros, onLoad: onLoadBairrosDropdown} = useBairroImovel();
  const {data: dataProprietarios} = useProprietariosDropdown();
  const {data: corretores} = useCaptador('', '', null);
  const {data: dataTipoImoveis} = useTipoImovel();
  const {
    isLoading: isLoadingCidadesDropdown,
    data: dataCidadesGrupos,
    onLoad: onLoadCidadesGrupos,
    cancelRequest: cancelRequestCidadeGrupos,
  } = useCidadesDropdownGrupos();
  const {data: dataEmpresas} = useEmpresasDropdown();
  const {isLoading, data, quantidade, onLoad, dadosRelatorio, cancelRequest} =
    useImovel();

  // TERMINO DAS CHAMADAS DOS ACTIONS

  const {checkConfig} = useConfigSistema();
  const isFilterRedeLatitude = checkConfig(
    configConst.CONSULTAR_IMOVEIS_REDE_LATITUDE,
  );

  const {identificador} = useParams();

  const [form] = Form.useForm();
  const [formSimpleFilters] = Form.useForm();

  const empresaId = localStorage.getItem('icaseEmpresaId');
  const keepFilter = false || localStorage.getItem('latitudeKeepFilterImovel');

  useEffect(() => {
    if (simpleFilters) onFiltros();
  }, [simpleFilters]);

  useEffect(() => {
    if (isLoading === false && data.length > 0) {
      setVisible(true);
      setLoadButton(false);
      setLoadCards(false);
    } else if (isLoading === false) {
      if (hasSearching) {
        setLoadButton(false);
        setLoadCards(false);
        Notify(
          'warning',
          '',
          'Não foram encontrados imóveis para os filtros informados!',
        );
      }

      window.scrollTo(1, 1);
    }
  }, [isLoading]);

  useEffect(() => {
    const atualizarEmpresa = async () => {
      await api.get(`empresas/${empresaId}`).then((res) => {
        setNomeEmpresa(res?.data?.nome);
      });
    };
    if (empresaId) atualizarEmpresa();
  }, [empresaId]);

  useEffect(() => {
    const {actions} = getActionsMenus(identificador);
    setActions(actions || []);
    window.scrollTo(1, 1);
  }, [identificador, isLoadingCidade]);

  useEffect(() => {
    if (!isLoadingCidade) {
      form.setFieldsValue({});
    }
    // eslint-disable-next-line
  }, [isLoadingCidade]);

  useEffect(() => {
    const gerarPdf = async () => {
      let blob = null;
      switch (modeloRelatorio) {
        case 2:
          blob = await pdf(
            <ImovelDocumentModelo2
              dados={dadosRelatorio}
              nomeEmpresa={nomeEmpresa}
            />,
          ).toBlob();
          break;
        default:
          blob = await pdf(
            <ImovelDocumentDefault
              dados={dadosRelatorio}
              nomeEmpresa={nomeEmpresa}
            />,
          ).toBlob();
          break;
      }
      const pdfURL = URL.createObjectURL(blob);
      window.open(pdfURL, '_blank');
    };

    if (dadosRelatorio && dadosRelatorio.length > 0) gerarPdf();
  }, [dadosRelatorio]);

  useEffect(() => {
    if (keepFilter) {
      setHasSearching(true);
      setCurrent(1);
      setLoadButton(true);
      setVisible(true);
      setLoadCards(true);

      const params = {
        consultaRedeLatitude: cacheFiltros?.consultaRedeLatitude,
        cidades: cacheFiltros?.cidadeId,
        bairros: cacheFiltros?.bairroId,
        condominios: cacheFiltros?.condominioId,
        operacaoImobiliaria: cacheFiltros?.operacaoImobiliaria,
        situacao: cacheFiltros?.situacao,
        tipoImoveis: cacheFiltros?.tipoImoveis,
        logradouro: cacheFiltros?.logradouro,
        valorVendaDe: cacheFiltros?.valorVendaDe
          ? Number(
              cacheFiltros?.valorVendaDe.replaceAll('.', '').replace(',', '.'),
            ).toFixed(2)
          : undefined,
        valorVendaAte: cacheFiltros?.valorVendaAte
          ? Number(
              cacheFiltros?.valorVendaAte.replaceAll('.', '').replace(',', '.'),
            ).toFixed(2)
          : undefined,
        valorLocacaoDe: cacheFiltros?.valorLocacaoDe
          ? Number(
              cacheFiltros?.valorLocacaoDe
                .replaceAll('.', '')
                .replace(',', '.'),
            ).toFixed(2)
          : undefined,
        valorLocacaoAte: cacheFiltros?.valorLocacaoAte
          ? Number(
              cacheFiltros?.valorLocacaoAte
                .replaceAll('.', '')
                .replace(',', '.'),
            ).toFixed(2)
          : undefined,
        areaDe: cacheFiltros?.areaDe,
        areaAte: cacheFiltros?.areaAte,
        dormitorios: cacheFiltros?.dormitorios,
        vagas: cacheFiltros?.vagas,
        suites: cacheFiltros?.suites,
        codImovel: cacheFiltros?.codImovel
          ? Number(cacheFiltros?.codImovel)
          : undefined,
        porDoSol: cacheFiltros?.porDoSol,
        aceitaPermuta: cacheFiltros?.aceitaPermuta,
        ocupadoPor: cacheFiltros?.ocupadoPor,
        andarTerreo: cacheFiltros?.andarTerreo,
        captadorId: cacheFiltros?.captadorId,
        benfeitorias: cacheFiltros?.benfeitorias
          ? [...cacheFiltros?.benfeitorias]
          : undefined,
        portais: cacheFiltros?.portais ? [...cacheFiltros?.portais] : undefined,
        proprietarioId: cacheFiltros?.proprietarioId,
        finalidadeId: cacheFiltros?.finalidadeId,
        dataLancamentoInicial:
          dataLancamento && dataLancamento.length === 2
            ? dataLancamento[0]?.format('YYYY/MM/DD')
            : null,
        dataLancamentoFinal:
          dataLancamento && dataLancamento.length === 2
            ? dataLancamento[1]?.format('YYYY/MM/DD')
            : null,
      };

      onLoad({
        relatorio: false,
        params,
      });

      setFilterParams(params);

      localStorage.removeItem('latitudeKeepFilterImovel');
    }
  }, [keepFilter]);

  function onChangeData(date, dateString) {
    setDataLancamento(date);
  }

  async function changePage(page) {
    setLoadCards(true);
    setHasSearching(true);
    setCurrent(page);

    const newFilterParams = filterParams;

    const valorVendaDeFormat = filterParams?.valorVendaDe
      ? filterParams?.valorVendaDe.includes(',')
        ? Number(
            filterParams?.valorVendaDe.replaceAll('.', '').replace(',', '.'),
          ).toFixed(2)
        : Number(filterParams?.valorVendaDe).toFixed(2)
      : undefined;

    const valorVendaAteFormat = filterParams?.valorVendaAte
      ? filterParams?.valorVendaAte.includes(',')
        ? Number(
            filterParams?.valorVendaAte.replaceAll('.', '').replace(',', '.'),
          ).toFixed(2)
        : Number(filterParams?.valorVendaAte).toFixed(2)
      : undefined;

    const valorLocacaoDeFormat = filterParams?.valorLocacaoDe
      ? filterParams?.valorLocacaoDe.includes(',')
        ? Number(
            filterParams?.valorLocacaoDe.replaceAll('.', '').replace(',', '.'),
          ).toFixed(2)
        : Number(filterParams?.valorLocacaoDe).toFixed(2)
      : undefined;

    const valorLocacaoAteFormat = filterParams?.valorLocacaoAte
      ? filterParams?.valorLocacaoAte.includes(',')
        ? Number(
            filterParams?.valorLocacaoAte.replaceAll('.', '').replace(',', '.'),
          ).toFixed(2)
        : Number(filterParams?.valorLocacaoAte).toFixed(2)
      : undefined;

    const valorCondominioDeFormat = filterParams?.valorCondominioDe
      ? filterParams?.valorCondominioDe.includes(',')
        ? Number(
            filterParams?.valorCondominioDe
              .replaceAll('.', '')
              .replace(',', '.'),
          ).toFixed(2)
        : Number(filterParams?.valorCondominioDe).toFixed(2)
      : undefined;

    const valorCondominioAteFormat = filterParams?.valorCondominioAte
      ? filterParams?.valorCondominioAte.includes(',')
        ? Number(
            filterParams?.valorCondominioAte
              .replaceAll('.', '')
              .replace(',', '.'),
          ).toFixed(2)
        : Number(filterParams?.valorCondominioAte).toFixed(2)
      : undefined;

    newFilterParams.valorVendaDe = valorVendaDeFormat;
    newFilterParams.valorVendaAte = valorVendaAteFormat;
    newFilterParams.valorLocacaoDe = valorLocacaoDeFormat;
    newFilterParams.valorLocacaoAte = valorLocacaoAteFormat;
    newFilterParams.valorCondominioDe = valorCondominioDeFormat;
    newFilterParams.valorCondominioAte = valorCondominioAteFormat;
    newFilterParams.numeroPagina = page;
    setFilterParams(newFilterParams);

    cancelRequest();
    onLoad({
      relatorio: false,
      params: newFilterParams,
    });
  }

  async function onChangeCidade(cidadeId, cacheFilters) {
    if (cidadeId && cidadeId.length > 0) {
      setLoadingBairro(true);
      setBairroDisabled(true);

      await onLoadBairrosDropdown({cidadeId});

      form.setFieldsValue({
        bairroId: cacheFilters ? cacheFiltros?.bairros || [] : [],
        portais: [],
      });

      setCidadeId(cidadeId);
      setBairroDisabled(false);
      setLoadingBairro(false);
    } else {
      form.setFieldsValue({bairroId: []});
      setCidadeId(undefined);
      setBairroDisabled(true);
    }
  }

  async function onSearchCidade(value) {
    await onLoadCidadesDropdown({cidade: value});
  }

  async function onSearchBairro(value) {
    await onLoadBairrosDropdown({cidadeId, nome: value});
  }

  async function onSearchCondominio(value) {
    await onLoadCondominio({nome: value});
  }

  async function onChangeFinalidade(finalidadeId) {
    if (finalidadeId) {
      setTipoImovelDisabled(false);

      await api
        .get(
          `${url.TIPO_IMOVEL.CONSULTA()}?numeroPagina=1&tamanhoPagina=99999999&finalidadeId=${finalidadeId}`,
        )
        .then((res) => {
          if (res?.data?.data) setTipoImoveis(res.data.data);
          else setTipoImoveis([]);
          form.setFieldsValue({
            tipoImoveis: cacheFiltros?.tipoImoveis
              ? cacheFiltros?.tipoImoveis
              : [],
          });
        })
        .catch((err) => {
          const {error} = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    } else {
      setTipoImovelDisabled(true);
      setTipoImoveis([]);
      form.setFieldsValue({tipoImoveis: []});
    }
  }

  const gerarRelatorio = async (modelo) => {
    setHasSearching(true);
    setLoadButtonRelatorio(true);
    setVisible(false);
    setModeloRelatorio(modelo);

    await onLoad({relatorio: true, params: filterParams}).finally(() => {
      setLoadButtonRelatorio(false);
      setVisible(true);
    });
  };

  const onFiltros = () => {
    setHasSearching(true);
    setCurrent(1);
    setLoadButton(true);
    setVisible(true);
    setLoadCards(true);

    let params = {};

    const localizacao = formSimpleFilters?.getFieldValue('localizacao');

    if (localizacao && localizacao.length > 0) {
      const freeSearchItems = localizacao.filter((item) => {
        return !item.includes('|');
      });

      const concatenatedSearch =
        freeSearchItems?.length > 0 ? freeSearchItems.join(' ') : null;

      if (concatenatedSearch) {
        params = {
          ...params,
          Filtro: concatenatedSearch,
        };
      }
    }

    if (!simpleFilters) {
      form
        .validateFields()
        .then((dados) => {
          console.log('form dados', dados);
          const filterCodImovel = dados?.codImovel;

          params = {
            ...params,
            ordenarPor: sortBy,
            consultaRedeLatitude: filterRequired ? filterRequired : undefined,
            cidades: dados?.cidadeId,
            bairros: dados?.bairroId,
            condominios: dados?.condominioId,
            captadorId: dados?.captadorId,
            operacaoImobiliaria: dados?.operacaoImobiliaria,
            situacao: dados?.situacao
              ? dados?.situacao
              : filterCodImovel
              ? null
              : 1,
            tipoImoveis: dados?.tipoImoveis,
            logradouro: dados?.logradouro,
            valorVendaDe: dados?.valorVendaDe
              ? Number(
                  dados?.valorVendaDe.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
              : undefined,
            valorVendaAte: dados?.valorVendaAte
              ? Number(
                  dados?.valorVendaAte.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
              : undefined,
            valorLocacaoDe: dados?.valorLocacaoDe
              ? Number(
                  dados?.valorLocacaoDe.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
              : undefined,
            valorLocacaoAte: dados?.valorLocacaoAte
              ? Number(
                  dados?.valorLocacaoAte.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
              : undefined,
            areaDe: dados?.areaDe,
            areaAte: dados?.areaAte,
            dormitorios: dados?.dormitorios,
            vagas: dados?.vagas,
            suites: dados?.suites,
            codImovel: filterCodImovel ? Number(filterCodImovel) : undefined,
            porDoSol: dados?.porDoSol,
            aceitaPermuta: dados?.aceitaPermuta,
            ocupadoPor: dados?.ocupadoPor,
            andarTerreo: dados?.andarTerreo,
            benfeitorias: dados?.benfeitorias
              ? [...dados?.benfeitorias]
              : undefined,
            portais: dados?.portais ? [...dados?.portais] : undefined,
            proprietarioId: dados?.proprietarioId,
            finalidadeId: dados?.finalidadeId,
            dataLancamentoInicial:
              dataLancamento && dataLancamento.length === 2
                ? dataLancamento[0]?.format('YYYY/MM/DD')
                : null,
            dataLancamentoFinal:
              dataLancamento && dataLancamento.length === 2
                ? dataLancamento[1]?.format('YYYY/MM/DD')
                : null,
            tipoData: tipoData,
          };

          cancelRequest();
          onLoad({
            simpleFilters,
            relatorio: false,
            params,
          });

          setFilterParams(params);
        })
        .catch(() => {
          setLoadButton(false);
          window.scrollTo(1, 1);
        });
    } else {
      formSimpleFilters
        .validateFields()
        .then((dados) => {
          const filterCodImovel = dados?.codImovel;

          params = {
            ...params,
            ordenarPor: sortBy,
          };

          if (dados?.Filtro) {
            params = {
              ...params,
              Filtro: dados?.Filtro,
            };
          }

          const isFilterTipoImovel = !dados?.tipoImoveis?.findIndex(
            (item) => item === 'TODOS',
          );

          if (filterCodImovel) {
            params = {
              ...params,
              codImovel: Number(filterCodImovel),
              situacao: null,
              consultaRedeLatitude: filterRequired ? filterRequired : undefined,
            };
          } else {
            params = {
              ...params,
              operacaoImobiliaria: dados?.operacaoImobiliaria
                ? dados?.operacaoImobiliaria
                : 2,
              // codImovel: dados?.codImovel ? Number(dados?.codImovel) : undefined,
              tipoImoveis: isFilterTipoImovel ? undefined : dados?.tipoImoveis,
              situacao: dados?.situacao ? dados?.situacao : 1,
              valorVendaDe: dados?.valorVendaDe
                ? Number(
                    dados?.valorVendaDe.replaceAll('.', '').replace(',', '.'),
                  ).toFixed(2)
                : undefined,
              valorVendaAte: dados?.valorVendaAte
                ? Number(
                    dados?.valorVendaAte.replaceAll('.', '').replace(',', '.'),
                  ).toFixed(2)
                : undefined,
              valorCondominioDe: dados?.valorCondominioDe
                ? Number(
                    dados?.valorCondominioDe
                      .replaceAll('.', '')
                      .replace(',', '.'),
                  ).toFixed(2)
                : undefined,
              valorCondominioAte: dados?.valorCondominioAte
                ? Number(
                    dados?.valorCondominioAte
                      .replaceAll('.', '')
                      .replace(',', '.'),
                  ).toFixed(2)
                : undefined,
              dormitorios: dados?.dormitorios,
              suites: dados?.suites,
              vagas: dados?.vagas,
              quantidadeBanheiros: dados?.banheiros,
              areaDe: dados?.areaDe,
              areaAte: dados?.areaAte,
              cidadesIds: cidadesIds.length > 0 ? cidadesIds : undefined,
              bairrosIds: bairrosIds.length > 0 ? bairrosIds : undefined,
              logradourosIds:
                logradourosIds.length > 0 ? logradourosIds : undefined,
              condominiosIds:
                condominiosIds.length > 0 ? condominiosIds : undefined,
              captadorId: dados?.captadorId,
              estadosIds: estadosIds.length > 0 ? estadosIds : undefined,
              consultaRedeLatitude: filterRequired ? filterRequired : undefined,
              anunciadoPor:
                dados?.anunciadoPor?.length > 0
                  ? dados?.anunciadoPor
                  : undefined,
            };
          }

          cancelRequest();
          onLoad({
            simpleFilters,
            relatorio: false,
            params,
          });

          setFilterParams(params);

          if (filterCodImovel) {
            formSimpleFilters.resetFields();
            formSimpleFilters.setFieldsValue({
              operacaoImobiliaria: 2,
              codImovel: filterCodImovel,
            });
          }
        })
        .catch(() => {
          window.scrollTo(1, 1);
        });
    }
  };

  function cleanFilter() {
    cleanSelectedRealState();
    FilterCache.removeFiltrosImoveis();

    if (!simpleFilters) {
      form.resetFields();
      form.setFieldsValue({situacao: 1});
      setTipoData();
      setLoadButton(false);
      setVisible(false);
    } else {
      formSimpleFilters.resetFields();
      formSimpleFilters.setFieldsValue({situacao: 1});

      handleOnFiltros();
    }
  }

  function cleanSelectedRealState() {
    setRealStateForPresentation([]);
    setShowClearSelection(false);
  }

  function handleMaskCurrency(e, field) {
    const maskedValue = formatedReal(e.target.value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.'),
    ).toFixed(2);

    if (simpleFilters) {
      formSimpleFilters.setFieldsValue({
        [field]: numberValue > 0 ? maskedValue : undefined,
      });
    } else {
      form.setFieldsValue({
        [field]: numberValue > 0 ? maskedValue : undefined,
      });
    }
  }

  function handleTipoImoveis(values) {
    const value = values.slice(-1); // COPIANDO ÚLTIMA POSIÇÃO

    if (value[0] === 'TODOS') {
      // LIMPAR TODOS OS VALORES, POIS IRÁ FILTRAR POR TODOS
      formSimpleFilters.setFieldsValue({tipoImoveis: ['TODOS']});
    } else if (values[0] === 'TODOS') {
      // VAI RETIRAR A OPÇÃO DE TODOS SE EXISTIR
      formSimpleFilters.setFieldsValue({tipoImoveis: [values[1]]});
    }

    onFiltros();
  }

  const handleCidadesGrupos = async function (value) {
    cancelRequestCidadeGrupos();
    await onLoadCidadesGrupos(value);
  };

  function handleChangeCidadesGrupos(values) {
    estadosIds.splice(0, estadosIds.length);
    cidadesIds.splice(0, cidadesIds.length);
    bairrosIds.splice(0, bairrosIds.length);
    logradourosIds.splice(0, logradourosIds.length);
    condominiosIds.splice(0, condominiosIds.length);

    const sortedArray = values.sort();
    sortedArray.forEach((item) => {
      const splitArray = item.split('|');

      if (splitArray[0] === 'E') estadosIds.push(splitArray[1]);
      if (splitArray[0] === 'C') cidadesIds.push(splitArray[1]);
      if (splitArray[0] === 'B') bairrosIds.push(splitArray[1]);
      if (splitArray[0] === 'L') logradourosIds.push(splitArray[1]);
      if (splitArray[0] === 'CC') condominiosIds.push(splitArray[1]);
    });

    onFiltros();
  }

  function onClickRadioQuartos(e) {
    if (e.target.value === clearFiltroQuartos) {
      if (simpleFilters) {
        formSimpleFilters.setFieldsValue({dormitorios: null});
        onFiltros();
      } else form.setFieldsValue({dormitorios: null});
    } else setClearFiltroQuartos(e.target.value);
  }

  function onClickRadioVagas(e) {
    if (e.target.value === clearFiltroVagas) {
      if (simpleFilters) {
        formSimpleFilters.setFieldsValue({vagas: null});
        onFiltros();
      } else form.setFieldsValue({vagas: null});
    } else setClearFiltroVagas(e.target.value);
  }

  function onClickRadioSuites(e) {
    if (e.target.value === clearFiltroSuites) {
      if (simpleFilters) {
        formSimpleFilters.setFieldsValue({suites: null});
        onFiltros();
      } else form.setFieldsValue({suites: null});
    } else setClearFiltroSuites(e.target.value);
  }

  function onClickRadioBanheiros(e) {
    if (e.target.value === clearFiltroBanheiros) {
      if (simpleFilters) {
        formSimpleFilters.setFieldsValue({banheiros: null});
        onFiltros();
      } else form.setFieldsValue({banheiros: null});
    } else setClearFiltroBanheiros(e.target.value);
  }

  function callOnFiltros() {
    timeoutId = setTimeout(() => onFiltros(), 1500);
  }

  function cancelOnFiltros() {
    if (timeoutId) clearTimeout(timeoutId);
  }

  function handleOnFiltros() {
    cancelOnFiltros();
    callOnFiltros();
  }

  const [clienteId, setClienteId] = useState(null);

  function generatePresentationLink() {
    const imoveis =
      realStateForPresentation?.map((item) => item.codImovel) || [];
    const companyUrl = realStateForPresentation[0]?.empresa?.urlSite || '';
    const parameters = new URLSearchParams();

    parameters.set('imoveis', imoveis?.join(','));
    parameters.set('empresaId', realStateForPresentation[0]?.empresaId);
    parameters.set('corretorId', corretorId);
    parameters.set('clienteId', clienteId);

    return `${companyUrl}/apresentacao/?${parameters.toString()}`;
  }

  const incluir = !!actions.find(
    (create) => create.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );

  const emitirRelatorios = !!actions.find(
    (create) => create.id === 'e17b0d5c-6453-44ef-b782-92ff6b5b191b',
  );

  const menuItems = [
    {
      label: 'Enviar por e-mail',
      key: 1,
      icon: <SendOutlined />,
      onClick: () => {
        setOpenModalSendEmail(true);
        setTypeOfApresentation(typesOfApresentation.EMAIL);
      },
    },
    {
      label: 'Enviar por Whatsapp',
      key: 2,
      icon: <WhatsAppOutlined />,
      // onClick: () => sendWhatsAppMessage(),
      onClick: () => {
        setOpenModalSendEmail(true);
        setTypeOfApresentation(typesOfApresentation.WHATSAPP);
      },
    },
    {
      label: 'Download',
      key: 3,
      icon: <DownloadOutlined />,
      // onClick: () => gerarApresentacaoImoveis(),
      onClick: () => {
        setOpenModalSendEmail(true);
        setTypeOfApresentation(typesOfApresentation.DOWNLOAD);
      },
    },
    {
      label: 'Gerar link',
      key: 4,
      icon: <LinkOutlined />,
      onClick: () => {
        setShowModalApresentacaoOpen(true);
      },
    },
  ];

  const [isCollapsedSimpleFilter, setIsCollapsedSimpleFilter] = useState(false);

  const isRowMode = useMediaQuery({
    query: '(max-width: 1500px)',
  });

  useEffect(() => {
    onFiltros();
  }, [sortBy]);

  const renderCardGrid = (
    <Col className="container container-fluid">
      <Transition visible={visible} animation="scale" duration={500}>
        <Card className="p-2">
          <CardHeader>
            {!loadCards && (
              <Row>
                <Space className="justify-content-between w-100 flex-wrap align-items-center">
                  <Col>
                    <h5 className="title mt-1">{`Resultados: (${
                      quantidade || 0
                    })`}</h5>
                  </Col>

                  <Col>
                    <SortByDropdown
                      defaultValue={sortBy}
                      onChange={(value) => {
                        setSortBy(value);
                      }}
                    />
                  </Col>
                </Space>
                {emitirRelatorios && data.length > 0 && (
                  <Col xs="12" md="3" className="my-2">
                    <Button
                      className="my-2"
                      size="tiny"
                      icon
                      labelPosition="left"
                      fluid
                      loadtext="Gerando Relatório..."
                      onClick={() => setModalRelatorioOpen(true)}>
                      <Icon name="file pdf outline" />
                      Relatório
                    </Button>
                  </Col>
                )}
                {showClearSelection && (
                  <Fragment>
                    <Col xs="12" md="3" className="my-2">
                      <Dropdown overlay={<Menu items={menuItems} />}>
                        <Button
                          className="my-2"
                          size="tiny"
                          icon
                          labelPosition="left"
                          fluid>
                          <Icon name="file pdf outline" />
                          Apresentação
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col xs="12" md="3" className="my-2">
                      <Button
                        className="my-2"
                        color="red"
                        size="tiny"
                        icon
                        labelPosition="left"
                        fluid
                        onClick={() => cleanSelectedRealState()}>
                        <Icon name="trash" />
                        Limpar seleção
                      </Button>
                    </Col>
                  </Fragment>
                )}
              </Row>
            )}
          </CardHeader>
          <CardBody className="pt-2">
            <Fragment>
              {!loadCards ? (
                <CardImovel
                  data={data}
                  identificador={identificador}
                  clickEnabled={true}
                  onPreview={rowPreview}
                  onEdit={rowEdit}
                  actions={actions}
                  setShowClearSelection={setShowClearSelection}
                  realStateForPresentation={realStateForPresentation}
                  setRealStateForPresentation={setRealStateForPresentation}
                />
              ) : (
                <Row className="mt-2">
                  <Col xs="12">
                    <Card className="w-100">
                      <CardBody>
                        <div className="p-4">
                          <SkeletonTableBasic cardShadowless lineNumber={3} />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <div className="p-4">
                          <SkeletonTableBasic cardShadowless lineNumber={3} />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <div className="p-4">
                          <SkeletonTableBasic cardShadowless lineNumber={3} />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <div className="p-4">
                          <SkeletonTableBasic cardShadowless lineNumber={3} />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              {!loadCards && data.length >= 1 && (
                <Row className="mt-2 p-2 justify-content-end">
                  <Pagination
                    current={current}
                    defaultPageSize={12}
                    defaultCurrent={1}
                    total={quantidade}
                    showSizeChanger={false}
                    onChange={changePage}
                  />
                </Row>
              )}
            </Fragment>
          </CardBody>
        </Card>
      </Transition>
    </Col>
  );

  const renderSimpleFilters = simpleFilters && (
    <Transition
      className="mt-1"
      visible={maisFiltros}
      animation="scale"
      duration={350}>
      <Col className="filter" xs="0" md={isRowMode ? '12' : '2'}>
        <Card
          style={{
            marginTop: '-15px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: 'none',
          }}>
          <CardBody className="mt-1">
            <Form form={formSimpleFilters} scrollToFirstError>
              <Row>
                <Col xs="12">
                  <p className="heading-small text-muted mb-2">
                    <small>Busca aberta</small>
                  </p>
                  <Form.Item className="my-2" name="localizacao">
                    <Select
                      showArrow
                      allowClear
                      mode="tags"
                      maxTagCount={10}
                      placeholder="Bairro, condomínio, cidade ou logradouro"
                      filterOption={false}
                      notFoundContent={
                        isLoadingCidadesDropdown ? (
                          <Spin indicator={antIcon} />
                        ) : null
                      }
                      onChange={handleChangeCidadesGrupos}
                      onSearch={handleCidadesGrupos}>
                      {dataCidadesGrupos.map(
                        (item) =>
                          item.options.length > 0 && (
                            <OptGroup key={item.label} label={item.label}>
                              {item.options.map((option) => (
                                <Option
                                  key={option.id}
                                  value={
                                    item.label === 'Condomínios'
                                      ? `CC|${option.id}`
                                      : `${item.label.substring(0, 1)}|${
                                          option.id
                                        }`
                                  }>
                                  {option.label}
                                </Option>
                              ))}
                            </OptGroup>
                          ),
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <p className="heading-small text-muted mb-2">
                    <small>Operação</small>
                  </p>
                  <Form.Item className="my-2" name="operacaoImobiliaria">
                    <Radio.Group
                      className="simpleFiltersRadio"
                      defaultValue={2}
                      buttonStyle="solid"
                      onChange={() => onFiltros()}>
                      {enumOperacaoImobiliaria.map(
                        (item) =>
                          item.id !== 3 && (
                            <Radio.Button
                              className="simpleFiltersRadioButton"
                              key={item.id}
                              value={item.id}>
                              {item.nome}
                            </Radio.Button>
                          ),
                      )}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {(isCollapsedSimpleFilter || !isRowMode) && (
                <>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Cód. do Imóvel</small>
                      </p>
                      <Form.Item className="my-2" name="codImovel">
                        <Input
                          placeholder=" Cód. do Imóvel"
                          type="number"
                          onChange={() => handleOnFiltros()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Captador</small>
                      </p>
                      <Form.Item className="my-2" name="captadorId">
                        <Select
                          allowClear={true}
                          onChange={() => onFiltros()}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, ''),
                              ) >= 0
                          }
                          placeholder=" Corretor Captador"
                          style={{width: '100%'}}>
                          {corretores.map((item) => (
                            <Option key={item.id} disabled={item.isSeparator}>
                              {item?.nome?.toString()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Tipo de Imóvel</small>
                      </p>
                      <Form.Item className="my-2" name="tipoImoveis">
                        <Select
                          showArrow
                          allowClear
                          mode="multiple"
                          maxTagCount={1}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (option?.children) {
                              if (option?.value === 'TODOS')
                                return option?.children;

                              return (
                                option?.children
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .indexOf(
                                    input
                                      .toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, ''),
                                  ) >= 0
                              );
                            }
                          }}
                          placeholder=" Tipo de imóvel"
                          onChange={handleTipoImoveis}>
                          <Option key={0} value="TODOS">
                            Todos os imóveis
                          </Option>
                          {dataTipoImoveis.map(
                            (item) =>
                              item.options.length > 0 && (
                                <OptGroup key={item.id} label={item.label}>
                                  {item.options.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                      {option.label}
                                    </Option>
                                  ))}
                                </OptGroup>
                              ),
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-0">
                        <small>
                          Valor em <b>R$</b>
                        </small>
                      </p>
                      <div
                        className="mb-2 mt-0 simpleFilterGroupInput"
                        style={{
                          flexWrap: 'wrap',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '100%',
                          }}>
                          <p className="heading-small text-muted mb-0">
                            <small style={{fontSize: '70%'}}>Mínimo</small>
                          </p>
                          <Form.Item className="mb-0" name="valorVendaDe">
                            <Input
                              allowClear
                              placeholder=" Mínimo"
                              onChange={(e) => {
                                handleMaskCurrency(e, 'valorVendaDe');
                                handleOnFiltros();
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            width: '100%',
                          }}>
                          <p className="heading-small text-muted mb-0 ml-1">
                            <small style={{fontSize: '70%'}}>Máximo</small>
                          </p>
                          <Form.Item className="ml-1 mb-0" name="valorVendaAte">
                            <Input
                              allowClear
                              placeholder=" Máximo"
                              onChange={(e) => {
                                handleMaskCurrency(e, 'valorVendaAte');
                                handleOnFiltros();
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-0">
                        <small>
                          Valor do condomínio em <b>R$</b>
                        </small>
                      </p>
                      <div
                        className="mb-2 mt-0 simpleFilterGroupInput"
                        style={{
                          flexWrap: 'wrap',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '100%',
                          }}>
                          <p className="heading-small text-muted mb-0">
                            <small style={{fontSize: '70%'}}>Mínimo</small>
                          </p>
                          <Form.Item
                            className="mr-1 mb-0"
                            name="valorCondominioDe">
                            <Input
                              allowClear
                              placeholder=" Mínimo"
                              onChange={(e) => {
                                handleMaskCurrency(e, 'valorCondominioDe');
                                handleOnFiltros();
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            width: '100%',
                          }}>
                          <p className="heading-small text-muted mb-0 ml-1">
                            <small style={{fontSize: '70%'}}>Máximo</small>
                          </p>
                          <Form.Item
                            className="ml-1 mb-0"
                            name="valorCondominioAte">
                            <Input
                              allowClear
                              placeholder=" Máximo"
                              onChange={(e) => {
                                handleMaskCurrency(e, 'valorCondominioAte');
                                handleOnFiltros();
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Quartos</small>
                      </p>
                      <Form.Item className="my-2" name="dormitorios">
                        <Radio.Group
                          className="simpleFiltersRadio"
                          allowClear
                          optionType="button"
                          buttonStyle="solid"
                          onChange={() => onFiltros()}>
                          {optionsSimple.map((item) => (
                            <Radio.Button
                              className="simpleFilterRadioButtonMulti"
                              key={item.value}
                              value={item.value}
                              onClick={onClickRadioQuartos}>
                              {item.label}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Suítes</small>
                      </p>
                      <Form.Item className="my-2" name="suites">
                        <Radio.Group
                          className="simpleFiltersRadio"
                          allowClear
                          optionType="button"
                          buttonStyle="solid"
                          onChange={() => onFiltros()}>
                          {optionsSimple.map((item) => (
                            <Radio.Button
                              className="simpleFilterRadioButtonMulti"
                              key={item.value}
                              value={item.value}
                              onClick={onClickRadioSuites}>
                              {item.label}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Banheiros</small>
                      </p>
                      <Form.Item className="my-2" name="banheiros">
                        <Radio.Group
                          className="simpleFiltersRadio"
                          allowClear
                          optionType="button"
                          buttonStyle="solid"
                          onChange={() => onFiltros()}>
                          {optionsSimple.map((item) => (
                            <Radio.Button
                              className="simpleFilterRadioButtonMulti"
                              key={item.value}
                              value={item.value}
                              onClick={onClickRadioBanheiros}>
                              {item.label}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Vagas de garagem</small>
                      </p>
                      <Form.Item className="my-2" name="vagas">
                        <Radio.Group
                          className="simpleFiltersRadio"
                          allowClear
                          optionType="button"
                          buttonStyle="solid"
                          onChange={() => onFiltros()}>
                          {optionsSimple.map((item) => (
                            <Radio.Button
                              className="simpleFilterRadioButtonMulti"
                              key={item.value}
                              value={item.value}
                              onClick={onClickRadioVagas}>
                              {item.label}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-0">
                        <small>
                          Área em <b>M²</b>
                        </small>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/* <div className="mb-2 mt-0 simpleFilterGroupInput"> */}
                      <div>
                        <p className="heading-small text-muted mb-0">
                          <small style={{fontSize: '70%'}}>Mínimo</small>
                        </p>
                        <Form.Item className="mb-0" name="areaDe">
                          <Input
                            placeholder=" Mínimo"
                            type="number"
                            onChange={() => handleOnFiltros()}
                          />
                        </Form.Item>
                      </div>
                      {/* </div> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <p className="heading-small text-muted mb-0">
                          <small style={{fontSize: '70%'}}>Máximo</small>
                        </p>
                        <Form.Item className="mb-0" name="areaAte">
                          <Input
                            placeholder=" Máximo"
                            type="number"
                            onChange={() => handleOnFiltros()}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Divider plain>Rede latitude</Divider>
                  <Row>
                    <Col xs="12">
                      <Form.Item className="mb-2" name="consultaRedeLatitude">
                        <Switch
                          onChange={(value) => {
                            filterRequired = value;
                            handleOnFiltros();
                          }}
                          defaultChecked={false}
                        />
                        <small
                          className="heading-small text-muted"
                          style={{marginLeft: 5}}>
                          Buscar na rede latitude
                        </small>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <p className="heading-small text-muted mb-2">
                        <small>Anunciado por</small>
                      </p>
                      <Form.Item className="my-2" name="anunciadoPor">
                        <Select
                          showSearch
                          showArrow
                          allowClear
                          mode="multiple"
                          placeholder=" Anunciado por"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (option?.children) {
                              return (
                                option?.children
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .indexOf(
                                    input
                                      .toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, ''),
                                  ) >= 0
                              );
                            }
                          }}
                          onChange={() => {
                            if (filterRequired) handleOnFiltros();
                          }}>
                          {dataEmpresas.map(
                            (empresa) =>
                              empresa.filiais.length > 0 && (
                                <OptGroup key={empresa.id} label={empresa.nome}>
                                  {empresa.filiais.map((filial) => (
                                    <Option key={filial.id} value={filial.id}>
                                      {filial.nome}
                                    </Option>
                                  ))}
                                </OptGroup>
                              ),
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col xs="12">
                  <Button
                    className="my-2"
                    color="yellow"
                    size="tiny"
                    icon
                    labelPosition="left"
                    fluid
                    onClick={() =>
                      setIsCollapsedSimpleFilter((current) => !current)
                    }>
                    <Icon
                      name={
                        isCollapsedSimpleFilter ? 'chevron up' : 'chevron down'
                      }
                    />
                    Mostrar {isCollapsedSimpleFilter ? 'menos' : 'mais'} filtros
                  </Button>
                </Col>
              </Row>
              <Divider plain />
              <Row>
                <Col xs="12">
                  <Button
                    className="my-2"
                    color="yellow"
                    size="tiny"
                    icon
                    labelPosition="left"
                    fluid
                    onClick={() => cleanFilter()}>
                    <Icon name="filter" />
                    Limpar Filtros
                  </Button>
                </Col>
                <Col xs="12">
                  <Button
                    className="my-2"
                    color="olive"
                    size="tiny"
                    icon
                    labelPosition="left"
                    fluid
                    onClick={() => {
                      setSimpleFilters(false);
                      // window.scrollTo(1, 64);
                      window.scrollTo({
                        top: 64,
                        left: 0,
                        behavior: 'smooth',
                      });
                    }}>
                    <Icon name="filter" />
                    Busca avançada
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Transition>
  );

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <Row>
                  <Col xs="12" md="3">
                    <h5 className="title">Controle de imóveis</h5>
                    <p className="category">Consulta de imóveis</p>
                  </Col>
                  {incluir && (
                    <Col xs="12" md="8">
                      <HeaderFiltros
                        navToNovo={() => navToNovo(identificador)}
                        description="Novo imóvel"
                      />
                    </Col>
                  )}
                  <Col
                    xs="12"
                    md="1"
                    className="d-flex justify-content-center align-items-center">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        color: '#1d1f8c',
                      }}>
                      {!maisFiltros && (
                        <div
                          onClick={() => setMaisFiltros(true)}
                          style={{cursor: 'pointer'}}>
                          <CaretDownOutlined
                            style={{fontSize: '20px', verticalAlign: 'top'}}
                          />
                          <span>Ver mais</span>
                        </div>
                      )}
                      {maisFiltros && (
                        <div
                          onClick={() => setMaisFiltros(false)}
                          style={{cursor: 'pointer'}}>
                          <CaretUpOutlined
                            style={{
                              fontSize: '20px',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                          <span>Ver menos</span>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              {!simpleFilters && (
                <CardBody>
                  <Row>
                    <Col>
                      <Transition
                        visible={maisFiltros}
                        animation="scale"
                        duration={500}>
                        <div className="my-2 p-2">
                          <Form form={form} scrollToFirstError>
                            <Row>
                              <Col xs="12" md="4" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Cód. do Imóvel</small>
                                </p>
                                <Form.Item className="my-2" name="codImovel">
                                  <Input
                                    placeholder=" Digite o código do imóvel"
                                    type="number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="4" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Captador</small>
                                </p>
                                <Form.Item className="my-2" name="captadorId">
                                  <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .indexOf(
                                          input
                                            .toLowerCase()
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, ''),
                                        ) >= 0
                                    }
                                    placeholder=" Corretor Captador"
                                    style={{width: '100%'}}>
                                    {corretores.map((item) => (
                                      <Option
                                        key={item.id}
                                        disabled={item.isSeparator}>
                                        {item?.nome?.toString()}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="4" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Proprietário</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="proprietarioId">
                                  <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        ?.toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        ?.indexOf(
                                          input
                                            .toLowerCase()
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, ''),
                                        ) >= 0
                                    }
                                    placeholder=" Proprietário"
                                    style={{width: '100%'}}>
                                    {dataProprietarios.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.nome?.toString()}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              {isFilterRedeLatitude && (
                                <Col xs="12" md="2" className="my-2">
                                  <p className="heading-small text-muted mb-2">
                                    <small>Consultar Rede Latitude?</small>
                                  </p>
                                  <Form.Item
                                    className="my-2"
                                    name="consultaRedeLatitude">
                                    <Switch
                                      onChange={(value) =>
                                        (filterRequired = value)
                                      }
                                      defaultChecked={false}
                                      checkedChildren="Sim"
                                      unCheckedChildren="Não"
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col
                                xs="12"
                                md={isFilterRedeLatitude ? '3' : '4'}
                                className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Cidade</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="cidadeId"
                                  rules={[
                                    {
                                      required: filterRequired,
                                      message: 'Informar ao menos uma cidade!',
                                    },
                                  ]}>
                                  <Select
                                    mode="multiple"
                                    showSearch
                                    filterOption={false}
                                    placeholder=" Cidade"
                                    allowClear
                                    onSearch={onSearchCidade}
                                    onClear={(value) =>
                                      onChangeCidade(value, false)
                                    }
                                    onChange={(value) =>
                                      onChangeCidade(value, false)
                                    }>
                                    {dataCidades.map((item) => (
                                      <Option key={item.id}>{item.nome}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col
                                xs="12"
                                md={isFilterRedeLatitude ? '3' : '4'}
                                className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Bairro</small>
                                </p>
                                <Form.Item className="my-2" name="bairroId">
                                  <Select
                                    mode="multiple"
                                    showSearch
                                    showArrow
                                    filterOption={false}
                                    placeholder={
                                      loadingBairro
                                        ? ' Carregando...'
                                        : ' Bairro'
                                    }
                                    allowClear
                                    onSearch={onSearchBairro}
                                    disabled={bairroDisabled}>
                                    {dataBairros.map((item) => (
                                      <Option key={item.id}>{item.nome}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="4" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Condomínio/Empreendimento</small>
                                </p>
                                <Form.Item className="my-2" name="condominioId">
                                  <Select
                                    mode="multiple"
                                    placeholder=" Empreendimento"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .indexOf(
                                          input
                                            .toLowerCase()
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, ''),
                                        ) >= 0
                                    }
                                    allowClear
                                    onSearch={onSearchCondominio}>
                                    {dataCondominios.map((item) => (
                                      <Option key={item.id}>
                                        {`${item.nome} - ${item?.logradouro?.bairro?.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="6" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Logradouro</small>
                                </p>
                                <Form.Item className="my-2" name="logradouro">
                                  <Input
                                    placeholder=" Logradouro"
                                    maxLength={100}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Área</small>
                                </p>
                                <Form.Item className="my-2" name="areaDe">
                                  <Input
                                    prefix="M²"
                                    placeholder=" De"
                                    type="number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2 text-white">
                                  <small>&nbsp;</small>
                                </p>
                                <Form.Item className="my-2" name="areaAte">
                                  <Input
                                    prefix="M²"
                                    placeholder=" Até"
                                    type="number"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Valor de Venda</small>
                                </p>
                                <Form.Item className="my-2" name="valorVendaDe">
                                  <Input
                                    prefix="R$"
                                    placeholder=" De"
                                    onChange={(e) =>
                                      handleMaskCurrency(e, 'valorVendaDe')
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2 text-white">
                                  <small>&nbsp;</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="valorVendaAte">
                                  <Input
                                    prefix="R$"
                                    placeholder=" Até"
                                    onChange={(e) =>
                                      handleMaskCurrency(e, 'valorVendaAte')
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Valor de Locação</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="valorLocacaoDe">
                                  <Input
                                    prefix="R$"
                                    placeholder=" De"
                                    onChange={(e) =>
                                      handleMaskCurrency(e, 'valorLocacaoDe')
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2 text-white">
                                  <small>&nbsp;</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="valorLocacaoAte">
                                  <Input
                                    prefix="R$"
                                    placeholder=" Até"
                                    onChange={(e) =>
                                      handleMaskCurrency(e, 'valorLocacaoAte')
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Operação</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="operacaoImobiliaria">
                                  <Select allowClear placeholder=" Operação">
                                    {enumOperacaoImobiliaria.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.nome}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Situação</small>
                                </p>
                                <Form.Item className="my-2" name="situacao">
                                  <Select
                                    allowClear
                                    placeholder=" Situação"
                                    defaultValue={1}>
                                    {enumSituacaoImovel.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.nome}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Finalidade</small>
                                </p>
                                <Form.Item className="my-2" name="finalidadeId">
                                  <Select
                                    placeholder=" Finalidade"
                                    allowClear
                                    onClear={onChangeFinalidade}
                                    onChange={onChangeFinalidade}>
                                    {dataFinalidades.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.nome}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Tipo de Imóvel</small>
                                </p>
                                <Form.Item className="my-2" name="tipoImoveis">
                                  <Select
                                    mode="multiple"
                                    placeholder=" Tipo de Imóvel"
                                    allowClear
                                    disabled={tipoImovelDisabled}>
                                    {tipoImoveis.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.descricao}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Posição do Sol</small>
                                </p>
                                <Form.Item className="my-2" name="porDoSol">
                                  <Select
                                    allowClear
                                    placeholder=" Posição do Sol">
                                    <Option value="M">Manhã</Option>
                                    <Option value="T">Tarde</Option>
                                    <Option value="I">Intermediário</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Imóvel ocupado por</small>
                                </p>
                                <Form.Item className="my-2" name="ocupadoPor">
                                  <Select
                                    allowClear
                                    placeholder=" Imóvel ocupado por">
                                    <Option value="P">Proprietário</Option>
                                    <Option value="I">Inquilino</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Andar Térreo</small>
                                </p>
                                <Form.Item className="my-2" name="andarTerreo">
                                  <Select
                                    allowClear
                                    placeholder=" Andar Térreo">
                                    <Option value="S">Sim</Option>
                                    <Option value="N">Não</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Aceita Permuta</small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="aceitaPermuta">
                                  <Select
                                    allowClear
                                    placeholder=" Aceita Permuta">
                                    <Option value="S">Sim</Option>
                                    <Option value="N">Não</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Benfeitoria(s)</small>
                                </p>
                                <Form.Item className="my-2" name="benfeitorias">
                                  <Select
                                    mode="multiple"
                                    showSearch
                                    showArrow
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .indexOf(
                                          input
                                            .toLowerCase()
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, ''),
                                        ) >= 0
                                    }
                                    placeholder=" Benfeitoria(s)"
                                    allowClear>
                                    {enumBenfeitoriasImovel.map((item) => (
                                      <Option
                                        key={item.value}
                                        value={item.value}>
                                        {item.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Portais</small>
                                </p>
                                <Form.Item className="my-2" name="portais">
                                  <Select
                                    mode="multiple"
                                    showSearch
                                    showArrow
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .indexOf(
                                          input
                                            .toLowerCase()
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, ''),
                                        ) >= 0
                                    }
                                    placeholder=" Portais"
                                    allowClear>
                                    {enumMidiaDivulgacao.map((item) => (
                                      <Option key={item.id}>
                                        {item.descricao}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small style={{fontSize: '70%'}}>
                                    Filtrar por data de
                                  </small>
                                </p>
                                <Form.Item className="my-2" name="tipoData">
                                  <Select
                                    style={{width: '100%'}}
                                    onChange={(value) => setTipoData(value)}
                                    allowClear
                                    placeholder="Criação, Atualização ou Lançamento"
                                    options={[
                                      {value: 'criacao', label: 'Criação'},
                                      {
                                        value: 'alteracao',
                                        label: 'Atualização',
                                      },
                                      {
                                        value: 'lancamento',
                                        label: 'Lançamento',
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small style={{fontSize: '70%'}}>
                                    {(() => {
                                      switch (tipoData) {
                                        case 'criacao':
                                          return 'Criado entre os dias';
                                        case 'atualizacao':
                                          return 'Atualizado entre os dias';
                                        case 'lancamento':
                                          return 'Lançado entre os dias';
                                        default:
                                          return 'Entre os dias';
                                      }
                                    })()}
                                  </small>
                                </p>
                                <Form.Item
                                  className="my-2"
                                  name="dataLancamento">
                                  <RangePicker
                                    onChange={(date, dateString) =>
                                      onChangeData(date, dateString)
                                    }
                                    locale={locale}
                                    format={dateFormat}
                                    picker="date"
                                    disabled={!tipoData}
                                    style={{width: '100%'}}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Quartos</small>
                                </p>
                                <Form.Item className="my-2" name="dormitorios">
                                  <Radio.Group
                                    allowClear
                                    style={{width: '100%'}}
                                    optionType="button"
                                    buttonStyle="solid">
                                    {options.map((item) => (
                                      <Radio.Button
                                        key={item.value}
                                        value={item.value}
                                        onClick={onClickRadioQuartos}>
                                        {item.label}
                                      </Radio.Button>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Vagas</small>
                                </p>
                                <Form.Item className="my-2" name="vagas">
                                  <Radio.Group
                                    allowClear
                                    style={{width: '100%'}}
                                    optionType="button"
                                    buttonStyle="solid">
                                    {options.map((item) => (
                                      <Radio.Button
                                        key={item.value}
                                        value={item.value}
                                        onClick={onClickRadioVagas}>
                                        {item.label}
                                      </Radio.Button>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-2">
                                  <small>Suítes</small>
                                </p>
                                <Form.Item className="my-2" name="suites">
                                  <Radio.Group
                                    allowClear
                                    style={{width: '100%'}}
                                    optionType="button"
                                    buttonStyle="solid">
                                    {options.map((item) => (
                                      <Radio.Button
                                        key={item.value}
                                        value={item.value}
                                        onClick={onClickRadioSuites}>
                                        {item.label}
                                      </Radio.Button>
                                    ))}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="3" className="my-2">
                                <Button
                                  className="my-2"
                                  color="green"
                                  size="tiny"
                                  icon
                                  labelPosition="left"
                                  fluid
                                  isloading={loadButton.toString()}
                                  loadtext="Filtrando..."
                                  onClick={() => onFiltros()}>
                                  <Icon name="filter" />
                                  Filtrar
                                </Button>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <Button
                                  className="my-2"
                                  color="olive"
                                  size="tiny"
                                  icon
                                  labelPosition="left"
                                  fluid
                                  onClick={() => setSimpleFilters(true)}>
                                  <Icon name="filter" />
                                  Busca rápida
                                </Button>
                              </Col>
                              <Col xs="12" md="3" className="my-2">
                                <Button
                                  className="my-2"
                                  color="yellow"
                                  size="tiny"
                                  icon
                                  labelPosition="left"
                                  fluid
                                  onClick={() => cleanFilter()}>
                                  <Icon name="filter" />
                                  Limpar filtros
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Transition>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>

            <ContentRow className="px-2">
              {renderCardGrid}
              {renderSimpleFilters}
            </ContentRow>
          </Col>
        </Row>

        <ModalRelatorioImoveis
          open={modalRelatorioOpen}
          close={() => setModalRelatorioOpen(false)}
          onExecute={gerarRelatorio}
          loading={loadButtonRelatorio}
        />

        <ModalSendApresentation
          open={openModalSendEmail}
          close={() => setOpenModalSendEmail(false)}
          cleanSelectedRealState={cleanSelectedRealState}
          realStateForPresentation={realStateForPresentation}
          typeOfApresentation={typeOfApresentation}
        />
        <ModalApresentacaoImoveis
          open={showModalApresentacaoOpen}
          onChangeClientId={(value) => setClienteId(value)}
          generatePresentationLink={generatePresentationLink()}
          close={() => setShowModalApresentacaoOpen(false)}
        />
      </Page>
    </Fragment>
  );
}

export default ConsultaImoveis;
