import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { config } from '../commom/config'

const styles = StyleSheet.create({
  Footer: {
    position: 'absolute',
    left: 20,
    bottom: 20,
    borderTop: '1px solid #E6E6E6',
    width: '100%',
    color: config.textColor,
    fontFamily: config.fontFamily,
  },
  ViewFlex: {
    display: 'flex',
  },
  FlexRow: {
    flexDirection: 'row'
  },
  TextNomeCorretor: {
    fontWeight: config.fontWeight,
    fontSize: 16,
    color: config.titleColor,
    margin: '12px 0 24px 0',
  },
  TextFont: {
    fontSize: config.secondaryFontSize,
  },
  TextBold: {
    fontWeight: config.fontWeight,
  },
  TextURL: {
    textDecoration: 'underline',
    color: 'blue',
  }
});

const Footer = ({ corretor, empresa }) => (
  <View fixed style={styles.Footer}>
    <Text style={styles.TextNomeCorretor}>{corretor?.nomeCompleto}</Text>
    <View style={styles.ViewFlex}>
      <View style={[styles.ViewFlex, styles.FlexRow, { marginBottom: 12 }]}>
        <Text style={[styles.TextFont, styles.TextBold]}>Telefone: </Text>
        <Text style={styles.TextFont}>{corretor?.telefone || ''}</Text>
      </View>
      <View style={[styles.ViewFlex, styles.FlexRow, { marginBottom: 12 }]}>
        <Text style={[styles.TextFont, styles.TextBold]}>Email: </Text>
        <Text style={styles.TextFont}>{corretor?.email || ''}</Text>
      </View>
      <View style={[styles.ViewFlex, styles.FlexRow, { marginBottom: 12 }]}>
        <Text style={[styles.TextFont, styles.TextBold]}>Site: </Text>
        <Text style={[styles.TextFont, styles.TextURL]}>{empresa?.urlSite || ''}</Text>
      </View>
      <View style={[styles.ViewFlex, styles.FlexRow]}>
        <Text style={[styles.TextFont, styles.TextBold]}>Endereço: </Text>
        <Text style={styles.TextFont}>{empresa?.endereco || ''}</Text>
      </View>
    </View>
  </View>
);

export default Footer;
