import React, {useState, Fragment, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {Form, Upload, Image} from 'antd';
import {Icon} from 'semantic-ui-react';
import * as JSZip from 'jszip';
import {saveAs} from 'file-saver';

import './styles.css';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import ModalConfirm from '~/components/ModalConfirm';

import Notify from '~/shared/Notify';
import Button from '~/shared/Button';

import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls';
import SortableList, {SortableItem} from 'react-easy-sort';
import SimpleImageCard from './components/SimpleImageCard';
import {InboxOutlined} from '@ant-design/icons';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
  VISUALIZAR: 'visualizar',
};

const END_POINT = {
  IMOVEL: 'imovel',
  CONDOMINIO: 'condominio',
};

function CardUpload({
  modo,
  multiFile,
  antButton,
  dataImages,
  apiURL,
  id,
  endPoint,
  nameIdInFormDataPost,
  nameIdInFormDataPut,
  voltar,
  showDestaque,
  descriptionDestaque,
  generateInitialValueImages,
  setInitialValuesImages,
  onClose,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(dataImages || []);
  const [visibleForm, setVisibleForm] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [imageId, setImagemId] = useState();
  const [loadingExcluir, setLoadingExcluir] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmAll, setOpenModalConfirmAll] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const [arrayFiles, setArrayFiles] = useState([]);
  const [imageToExclude, setImageToExclude] = useState(null);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function sortByName(items) {
    return items?.sort((a, b) => {
      const aName = a?.file?.name;
      const bName = b?.file?.name;
      return aName?.localeCompare(bName);
    });
  }

  function isImage(file) {
    return file && file['type'].split('/')[0] === 'image';
  }

  const props = {
    beforeUpload: (_) => {
      return false;
    },
    fileList,
    multiple: multiFile || true,
    showUploadList: false,
    onChange: async (image) => {
      if (!image.file.url) {
        image.file.url = await getBase64(image.file);
      }

      if (!isImage(image.file)) return;

      setArrayFiles((current) => {
        let ordem = fileList?.length + 2;

        if (current.length > 0)
          ordem = Number(current[current?.length - 1]?.ordem) + 1;

        const newImage = {
          ordem: ordem,
          file: image?.file,
        };

        return sortByName([...current, newImage]);
      });
    },
  };

  useEffect(() => {
    async function fetch() {
      const object = await generateInitialValueImages(fileList);
      setInitialValuesImages(object);
      form.setFieldsValue(object);
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  function isFormDataEmpty(formData) {
    const iterator = formData.entries();
    return iterator.next().done;
  }

  function onSaveImages({changePage}) {
    setVisibleForm(false);
    setLoading(true);

    form
      .validateFields()
      .then(async (dados) => {
        let guid;
        let descricao;
        let publicavel;
        let destaque;
        let superDestaque;
        let ordem;

        const keysDados = Object.keys(dados);

        let resultArray = [...fileList];

        if (arrayFiles.length > 0) {
          resultArray = resultArray.concat(arrayFiles);
        }

        const payload = resultArray.map((item, index) => {
          guid = null;
          descricao = '';
          publicavel = true;
          destaque = index === 0;
          superDestaque = false;
          ordem = index + 1;

          keysDados.forEach((keyDados) => {
            if (keyDados.includes(index)) {
              switch (keyDados) {
                case `${index}_id`:
                  guid = dados[keyDados] ? dados[keyDados] : null;
                  break;
                case `${index}_descricao`:
                  descricao = dados[keyDados] ? dados[keyDados] : '';
                  break;
                case `${index}_superDestaque`:
                  superDestaque = dados[keyDados] ? dados[keyDados] : false;
                  break;
                default:
                  break;
              }
            }
          });

          let imagePayload = {
            guid,
            descricao,
            publicavel,
            destaque,
            superDestaque,
            ordem,
          };

          imagePayload.imagem = guid ? null : item;

          if ('file' in item) {
            imagePayload.imagem = item?.file;
          }

          return imagePayload;
        });

        let retorno = payload.reduce((totalImagesCapa, item) => {
          return (totalImagesCapa += item.destaque ? 1 : 0);
        }, 0);

        if (retorno !== 1 && showDestaque) {
          setVisibleForm(true);
          setLoading(false);
          if (retorno === 0)
            Notify(
              'error',
              pt.comum.atencao,
              `Favor selecionar uma ${descriptionDestaque}.`,
            );
          else
            Notify(
              'error',
              pt.comum.atencao,
              `Permitida somente uma ${descriptionDestaque}.`,
            );
        } else {
          await new Promise((resolve, reject) => {
            const promises = payload.map(async (item, index) => {
              const formData = new FormData();

              formData.delete(nameIdInFormDataPost);
              if (item.guid) formData.delete(nameIdInFormDataPut);

              formData.delete('imagem');
              formData.delete('descricao');
              formData.delete('destaque');
              formData.delete('superDestaque');
              formData.delete('publicavel');
              formData.delete('ordem');

              formData.append(nameIdInFormDataPost, id);
              formData.append(
                nameIdInFormDataPut,
                item.guid ?? item?.imagem?.id,
              );

              formData.append('imagem', item.imagem);
              formData.append('descricao', item.descricao);
              formData.append('destaque', item.destaque);
              formData.append('superDestaque', item.superDestaque);
              formData.append('publicavel', item.publicavel);
              formData.append('ordem', item.ordem);

              let isValidFormData = !isFormDataEmpty(formData);

              if (item.guid || item?.imagem?.id) {
                return api.put(apiURL, formData).catch((err) => {
                  const {error} = err.response?.data;
                  if (error) {
                    for (const item in error) {
                      Notify('error', item, error[item]);
                    }
                  }
                });
              } else {
                if (!isValidFormData) return;
                return api.post(apiURL, formData).catch((err) => {
                  const {error} = err.response?.data;
                  if (error) {
                    for (const item in error) {
                      Notify('error', item, error[item]);
                    }
                  }
                });
              }
            });

            Promise.all(promises).then(async () => {
              if (END_POINT.IMOVEL === endPoint) {
                try {
                  const res = await api.get(
                    `${url.IMOVEL.IMAGES()}?imovelId=${id}`,
                  );
                  Notify('success', pt.imagem.upload_com_sucesso, '');
                  setArrayFiles([]);
                  if (changePage === true && voltar) voltar();

                  setFileList(res?.data || []);
                  setVisibleForm(true);
                  setLoading(false);
                } catch (err) {
                  Notify(
                    'error',
                    pt.comum.atencao,
                    'Erro ao re-carregar as imagens do imóvel!',
                  );
                  setFileList([]);
                  setVisibleForm(true);
                  setLoading(false);
                }
              } else if (END_POINT.CONDOMINIO === endPoint) {
                setOpenModalSuccess(true);
                setVisibleForm(true);
                setLoading(false);
                onClose && onClose();
              } else {
                setVisibleForm(true);
                setLoading(false);
              }
            });
          });
        }
      })
      .catch((error) => {
        setVisibleForm(true);
        setLoading(false);
      });
  }

  async function successDelete(index) {
    Notify('success', '', 'Arquivo excluído com sucesso!');

    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);

    if (newFileList.length === 0) setFileList([]);
  }

  async function onDeleteImages(index, id) {
    if (index >= 0) {
      if (id) {
        let urlDelete;
        if (END_POINT.IMOVEL === endPoint)
          urlDelete = url.IMOVEL_IMAGEM.DELETAR(id);
        if (END_POINT.CONDOMINIO === endPoint)
          urlDelete = url.CONDOMINIO_IMAGEM.DELETAR(id);

        await api
          .delete(urlDelete)
          .then((res) => {
            successDelete(index);
          })
          .catch((err) => {
            Notify('error', '', 'Oops erro ao excluir a imagem!');
            setLoadingExcluir(false);
            setOpenModalConfirm(false);
          });
      } else {
        successDelete(index);
      }
    }
  }

  function baixarImagens() {
    const zip = new JSZip();
    var count = 0;
    var erro = false;
    fileList.forEach(async (file) => {
      if (erro) return;

      const urlImagem = file.url ? file.url : file.arquivo;
      await api
        .get(url.SERVICES.GERA_BASE64_IMAGEM(urlImagem))
        .then(async (res) => {
          const imageBase64 = res.data.base64.split('base64,')[1];
          zip.file(count + '.jpg', imageBase64, {base64: true});
          ++count;
          if (count === fileList.length) {
            zip
              .generateAsync({
                type: 'blob',
              })
              .then(function (content) {
                saveAs(content, `imovel_${id}.zip`);
              });
          }
        })
        .catch((error) => {
          if (!erro)
            Notify(
              'error',
              pt.comum.atencao,
              'Não foi possível baixar as imagens',
            );
          erro = true;
        });
    });
  }

  function sortByOrder(a, b) {
    if ((!a?.ordem || a?.ordem === 0) && (!b?.ordem || b?.ordem === 0))
      return 0;
    if (!a?.ordem || a?.ordem === 0) return 1;
    if (!b?.ordem || b?.ordem === 0) return -1;
    return a.ordem - b.ordem;
  }

  async function DeleteEvent(type) {
    setLoadingExcluir(true);

    if (imageToExclude && arrayFiles.length > 0) {
      setArrayFiles((current) => {
        return current.filter((item) => item?.ordem !== imageToExclude?.ordem);
      });

      setImageToExclude(null);
      setOpenModalConfirm(false);
      setOpenModalConfirmAll(false);
      setLoadingExcluir(false);
      return;
    }

    if (type === 'T') {
      for (let index = fileList.length - 1; index > -1; index--) {
        const element = fileList[index];
        await onDeleteImages(index, element.id);
      }
      setFileList([]);
    } else await onDeleteImages(selectedIndex, imageId);

    setOpenModalConfirm(false);
    setOpenModalConfirmAll(false);
    setLoadingExcluir(false);
  }

  function handleExclusaoUnitaria(index, imageId, image) {
    if (!imageId) setImageToExclude(image);
    setSelectedIndex(index);
    setImagemId(imageId);
    setOpenModalConfirm(true);
  }

  function handleExlcusaoTodas() {
    setOpenModalConfirmAll(true);
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function handleCloseModalAll() {
    setOpenModalConfirmAll(false);
  }

  const isEmpty = !fileList.length;

  const moveImage = (dragIndex, hoverIndex) => {
    setFileList((prevCards) => {
      let clonedCards = [...prevCards];
      const shouldMergeArrays =
        !clonedCards[dragIndex] || !clonedCards[hoverIndex];

      if (shouldMergeArrays) {
        clonedCards = [...prevCards, ...arrayFiles];
        setArrayFiles([]);
      }

      const oldDragOrder = clonedCards[dragIndex]?.ordem;
      const oldHoverOrder = clonedCards[hoverIndex]?.ordem;

      clonedCards[dragIndex] = {
        ...clonedCards[dragIndex],
        ordem: oldHoverOrder,
      };

      clonedCards[hoverIndex] = {
        ...clonedCards[hoverIndex],
        ordem: oldDragOrder,
      };

      const removedItem = clonedCards.splice(dragIndex, 1)[0];

      clonedCards.splice(hoverIndex, 0, removedItem);

      return clonedCards;
    });
  };

  return (
    <Fragment>
      {!visibleForm && <SkeletonTableBasic cardShadowless />}
      {visibleForm && (
        <Fragment>
          {!isEmpty && (
            <Row className="px-2">
              <Col xs="6" md="3">
                <Button
                  onClick={baixarImagens}
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  className="mt-1"
                  color="violet"
                  isloading={'false'}
                  loadtext="Baixando...">
                  <Icon name="download" />
                  Baixar Imagens
                </Button>
              </Col>
              <Col xs="6" md="3">
                <Button
                  onClick={handleExlcusaoTodas}
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  className="mt-1"
                  loadtext="Excluindo imagens..."
                  isloading={loadingExcluir}
                  color="orange">
                  <Icon name="trash" />
                  Excluir Todas Imagens
                </Button>
              </Col>
            </Row>
          )}
          <br></br>
          {modo !== MODO.VISUALIZAR && (
            <>
              <Row>
                <Col xs="12">
                  <div className="p-2 mb-3">
                    <p>
                      {`Selecione e envie as imagens do
                        ${
                          endPoint === END_POINT.IMOVEL
                            ? 'imóvel'
                            : 'condomínio'
                        }
                        (Extensões permitidas: PNG, JPG ou JPEG)`}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="p-2 text-center">
                    <Upload.Dragger
                      {...props}
                      listType="picture"
                      className="upload-card">
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined size={30} style={{color: '#1d1f8c'}} />
                      </p>
                      <p className="ant-upload-text">
                        Clique ou arraste para carregar as imagens.
                      </p>
                      <p className="ant-upload-hint">
                        Suporte para múltiplos arquivos no formato PNG ou
                        JPEG/JPG.
                      </p>
                    </Upload.Dragger>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <SortableList
            onSortEnd={moveImage}
            className="list"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 16,
              userSelect: 'none',
            }}
            draggedItemClassName="dragged">
            <Image.PreviewGroup>
              {[...fileList?.sort(sortByOrder), ...arrayFiles]?.map(
                (image, index) => {
                  return (
                    <SortableItem key={image?.id}>
                      <div>
                        <SimpleImageCard
                          src={image?.arquivo || image?.url || image?.file?.url}
                          title={''}
                          isHighlight={index === 0}
                          id={image?.id}
                          onDeleteImage={() =>
                            handleExclusaoUnitaria(index, image?.id, image)
                          }
                        />
                      </div>
                    </SortableItem>
                  );
                },
              )}
            </Image.PreviewGroup>
          </SortableList>
        </Fragment>
      )}
      <Row>
        {modo !== MODO.VISUALIZAR && (
          <Col xs="12" md="3">
            <Button
              onClick={() => onSaveImages({changePage: false})}
              icon
              labelPosition="left"
              size="medium"
              fluid
              className="mt-3"
              color="violet"
              isloading={loading.toString()}
              loadtext={
                modo === MODO.CADASTRAR ? 'Cadastrando...' : 'Salvando...'
              }>
              <Icon name="check" />
              {modo === MODO.CADASTRAR ? 'Cadastrar' : 'Salvar e continuar'}
            </Button>
          </Col>
        )}
        {modo !== MODO.VISUALIZAR && (
          <Col xs="12" md="3">
            <Button
              onClick={() => onSaveImages({changePage: true})}
              icon
              labelPosition="left"
              size="medium"
              type="dashed"
              fluid
              className="mt-3"
              isloading={loading.toString()}
              loadtext={
                modo === MODO.CADASTRAR ? 'Cadastrando...' : 'Salvando...'
              }>
              <Icon name="check" />
              {modo === MODO.CADASTRAR ? 'Cadastrar' : 'Salvar e sair'}
            </Button>
          </Col>
        )}
        <Col xs="12" md="3">
          <Button
            icon
            labelPosition="left"
            size="medium"
            fluid
            className="mt-3"
            color="orange"
            isloading={loading.toString()}
            onClick={() => voltar()}>
            <Icon name="reply" />
            Voltar
          </Button>
        </Col>
      </Row>

      <ModalConfirm
        isDelete
        icon="warning sign"
        loading={loadingExcluir}
        open={openModalConfirm}
        confirm={DeleteEvent}
        close={handleCloseModal}
        message={'Deseja realmente excluir esse registro?'}
      />

      <ModalConfirm
        isDelete
        icon="warning sign"
        loading={loadingExcluir}
        open={openModalConfirmAll}
        confirm={() => DeleteEvent('T')}
        close={handleCloseModalAll}
        message={'ATENÇÃO: Deseja realmente excluir TODAS imagens?'}
      />

      <ModalConfirm
        isDelete={false}
        icon="check circle"
        open={openModalSuccess}
        close={voltar}
        message={
          modo === MODO.CADASTRAR
            ? 'Cadastrado com sucesso'
            : 'Alterações salvas'
        }
      />
    </Fragment>
  );
}

export default CardUpload;
