import React, {Fragment, useState} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import {Row, Col} from 'reactstrap';
import api from '~/services/api';
import url from '~/infra/urls';
import Button from '~/shared/Button';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

function ModalRelatorioCorretor({
  open,
  close,
  empresaId,
  isCorretor,
  corretorId,
  usuarioId,
  filterParams,
}) {
  const [loading, setIsLoading] = useState(false);

  const obterUrl = () => {
    return url.RELATORIOS.CORRETORES();
  };
  const downloadDoArquivo = (response) => {
    const url = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `corretores_${new Date().toLocaleString()}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();
  };

  async function gerarRelatorio(tipo) {
    setIsLoading(true);

    try {
      const payload = {
        corretorId: isCorretor
          ? usuarioId
          : corretorId === 'TODOS'
          ? null
          : corretorId,
        empresaId,
        situacao: filterParams?.situacao,
        pesquisa: filterParams?.pesquisa,
        status: filterParams?.status,
        grupoCorretoresId: filterParams?.grupoCorretoresId,
        perfil: filterParams?.perfil,
        tipoDeUsuario: filterParams?.tipoDeUsuario,
        tipoMidia: filterParams?.tipoMidia,
        tipoData: filterParams?.tipoData,
        dataIni: filterParams?.dataIni,
        dataFim: filterParams?.dataFim,
        semCorretor: filterParams?.semCorretor,
        statusFunil: filterParams?.statusFunil,
        tipoAtendimentoId: filterParams?.tipoAtendimentoId,
      };

      await api
        .post(obterUrl(tipo), payload, {
          responseType: tipo === 'excel' ? 'blob' : 'json',
        })
        .then(async (res) => {
          if (res?.data) {
            if (tipo === 'excel') {
              downloadDoArquivo(res.data);
            }
          }
        });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Modal size="mini" open={open} onClose={() => close()}>
        <Modal.Header>
          <Row>
            <Col xs="12">
              <div>
                <h4 className="titleLogin">Relatório de Corretores</h4>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row className="p-2">
            <Col>
              <div>
                <Row>
                  <Col xs="12">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      color="green"
                      className="mt-1"
                      isloading={loading.toString()}
                      onClick={() => gerarRelatorio('excel')}
                      loadtext="Gerando Excel...">
                      <Icon name="file excel outline" />
                      Gerar Excel
                    </Button>
                  </Col>
                  <Col xs="12">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-1"
                      onClick={close}>
                      <Icon name="reply" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalRelatorioCorretor;
