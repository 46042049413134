import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useLogradouro(
  current,
  pageSize,
  nomeLogradouro,
  bairroId,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async () => {
      setIsLoading(true);
      const filterCurrent = current ? current : null;
      const tamanhoPagina = pageSize ? pageSize : null;
      const filterNomeLogradouro = nomeLogradouro ? nomeLogradouro : null;
      const filtroBairroId = bairroId ? bairroId : null;

      let filter = null;

      if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

      if (tamanhoPagina) {
        if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
        else filter = `?tamanhoPagina=${tamanhoPagina}`;
      }

      if (filterNomeLogradouro) {
        if (filter.length > 0) filter += `&nome=${filterNomeLogradouro}`;
        else filter = `?nome=${filterNomeLogradouro}`;
      }

      if (filtroBairroId) {
        if (filter.length > 0) filter += `&bairroId=${filtroBairroId}`;
        else filter = `?bairroId=${filtroBairroId}`;
      }

      if (filter) {
        try {
          await api
            .get(`${url.LOGRADOURO.CONSULTA()}${filter ? filter : ''}`)
            .then((res) => {
              setData(res?.data?.data || []);
              setQuantidade(res?.data?.total);
            });
        } catch (e) {
          setData([]);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [pageSize, nomeLogradouro, bairroId, current],
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
