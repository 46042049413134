export default function useRoles() {
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));

  const isCorretor = roles.some((x) => x.toUpperCase() === 'CORRETOR') || false;
  const isGerente = roles.some((x) => x.toUpperCase() === 'GERENTE') || false;
  const isAdmMatriz= roles.some((x) => x.toUpperCase() === 'ADMMATRIZ') || false;
  const isDiretor =
    roles.some(
      (x) => x.toUpperCase() === 'MASTER' || x.toUpperCase() === 'DIRETOR' || x.toUpperCase() === 'ADMMATRIZ',
    ) || false;

  return {
    isCorretor,
    isGerente,
    isDiretor,
    isAdmMatriz
  };
}
