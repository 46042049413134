import React from 'react';

export default function CardTitle({children}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <h1
        style={{
          fontSize: 18,
          margin: 0,
          padding: 0,
        }}>
        {children}
      </h1>
    </div>
  );
}

