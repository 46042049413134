import React, {Fragment, useEffect, useState} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import {Input} from 'antd';
import Button from '~/shared/Button';
import useEditarOfertaAtiva from '~/actions/OfertaAtiva/useEditarOfertaAtiva';
import SelectCorretoresOrGrupoCorretoresInput from '~/components/SelectCorretoresOrGrupoCorretoresInput';

function ModalEditOfertaAtiva({open, close, textTitle, row, onUpdate}) {
  const [id, setId] = useState();
  const [nome, setNome] = useState();
  const [membros, setMembros] = useState([]);
  const [allowConfirm, setAllowConfirm] = useState(true);
  const {editarOfertaAtiva, isLoading} = useEditarOfertaAtiva();

  useEffect(() => {
    setId(row?.id);
    setNome(row?.nome);
    setMembros(row?.membros ?? []);
  }, [row]);

  useEffect(() => {
    setAllowConfirm(!!membros);
  }, [membros, allowConfirm]);

  const onConfirmSubmit = () => {
    editarOfertaAtiva(
      id,
      nome,
      membros.filter((m) => m.tipo === 'corretor').map((i) => i.id),
      membros.filter((m) => m.tipo === 'grupo-corretores').map((i) => i.id),
    ).then(() => {
      onUpdate(row, nome, membros);
      setMembros([]);
      setNome();
      close();
    });
  };

  const handleCloseModal = () => {
    if (!isLoading) {
      close();
    }
  };

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => handleCloseModal()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>{textTitle}</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <p className="heading-small text-muted mb-2 ">
              <small>Nome</small>
            </p>
            <Input
              type="text"
              name="nome"
              value={nome}
              disabled={isLoading}
              placeholder="Nome"
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <SelectCorretoresOrGrupoCorretoresInput
              label="Membros (Corretores ou Grupo de Corretores)"
              enableHelperTooltip
              enableTagRender
              disabled={isLoading}
              defaultValue={membros}
              placeholder=" Corretores ou Grupo de Corretores"
              onChange={(value) =>
                setMembros(
                  value.map((membro) => ({
                    id: membro.key,
                    nome: membro.children,
                    tipo: membro.options.type,
                  })),
                )
              }
            />
          </div>

          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              disabled={!allowConfirm}
              onClick={() => onConfirmSubmit(true)}
              isloading={`${isLoading}`}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              disabled={isLoading}
              onClick={() => handleCloseModal()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}
export default ModalEditOfertaAtiva;
