
const EstadoCivil = [
  { nome: 'CASADO(A)', id: 1 },
  { nome: 'DIVORCIADO(A)', id: 2 },
  { nome: 'SEPARADO(A) JUDICIALMENTE', id: 3 },
  { nome: 'SOLTEIRO(A)', id: 4 },
  { nome: 'VIUVO(A)', id: 5 },
];

export default EstadoCivil;
