
const defaultPagination = (current, total, onChange) => {
  return {
    current,
    defaultCurrent: 1,
    total,
    responsive: true,
    onChange,
  };
}

export default defaultPagination;
