import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Input, Select, DatePicker, Divider, Tooltip, Collapse } from 'antd';
import { Icon } from 'semantic-ui-react';
import { CaretDownOutlined } from '@ant-design/icons';
import { mask, unMask } from 'remask';

import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';

import './index.css';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Button from '~/shared/Button';
import { checkDocumento } from '~/shared/checkDocumento';
import pattern from '~/infra/resources/pattern/mask';
import ModalLogradouro from '~/components/ModalLogradouro';
import ModalComidaFavorita from '~/components/ModalComidaFavorita';
import ModalGenericoProfissao from '~/components/ModalGenerico';

import CompProfissoes from '~/views/private/Cadastros/Profissoes/Manutencao';

import usePessoaDropdown from '~/actions/DropDown/usePessoa';
import useNacionalidades from '~/actions/DropDown/useNacionalidades';
import useCondominio from '~/actions/Condominio/useCondominio';
import useLogradouro from '~/actions/DropDown/useLogradouroDropdown';
import useTimeFavorito from '~/actions/DropDown/useTimeFavorito';
import useComidaFavorita from '~/actions/DropDown/useComidaFavorita';

import enumEstadoCivil from '~/infra/resources/strings/enums/estadoCivil';
import coutriesDDI from '~/infra/resources/strings/enums/countries';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import Pessoa from '~/views/private/Cadastros/Pessoa/Manutencao';
import ModalGenerico from '~/components/ModalGenerico';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
moment.locale('pt-BR');

const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { TextArea } = Input;

function FormFisica({
  form,
  profissoes,
  onLoadProfissoes,
  estados,
  formacoes,
  dataCidadesDropdown,
  onLoadCidadesDropdown,
  setNovoConjugeId,
  viewClient,
  showAdvanceData,
  tipoPessoa
}) {
  const [isCasado, setIsCasado] = useState(false);
  const [loadingEndereco, setLoadingEndereco] = useState(false);
  const [modalPessoa, setOpenModalPessoa] = useState(false);
  const [currentCep, setCurrentCep] = useState(0);
  const [modalLogradouroOpen, setModalLogradouroOpen] = useState(false);
  const [timeFavorito, setTimeFavorito] = useState();
  const [comidaFavorita, setComidaFavorita] = useState();
  const [openModalComidaFavorita, setOpenModalComidaFavorita] = useState(false);
  const [openModalProfissoes, setOpenModalProfissoes] = useState(false);

  const { data: dataPessoas, onLoad: onLoadPessoas } = usePessoaDropdown();
  const { data: dataNacionalidades } = useNacionalidades();
  const { data: dataCondominios } = useCondominio();
  const { data: dataLogradouros, onLoad: onLoadLogradouros } = useLogradouro();
  const { data: dataCorretores, onLoad: onLoadCorretores } = useCorretorDropdown(true);
  const { data: dataTimeFavorito } = useTimeFavorito(timeFavorito);
  const { data: dataComidaFavorita } = useComidaFavorita(comidaFavorita);

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isMaster = !!roles.find((x) => x.toUpperCase() === 'MASTER');

  useEffect(() => {
    async function loadLogradouro() {
      const logradouro = form.getFieldValue('enderecoLogradouro');
      await onLoadLogradouros(logradouro);
    }

    async function loadCorretor() {
      const captadorId = form.getFieldValue('captadorId');
      await onLoadCorretores({ id: captadorId });
    }

    async function loadConjuge() {
      const conjugeId = form.getFieldValue('conjugeId');

      if (!!conjugeId) {
        setIsCasado(true);
        await onLoadPessoas({ pessoaId: conjugeId });
      }
    }


    loadLogradouro();
    loadCorretor();
    loadConjuge();

    // eslint-disable-next-line
  }, []);

  async function onSearchCaptador(value) {
    await onLoadCorretores({ nome: value });
  }

  // BLOCO PARA VALIDAÇÕES DO ENDEREÇO
  async function onSearchLogradouro(value) {
    await onLoadLogradouros(value);
  }

  async function onSearchConjuge(value) {
    await onLoadPessoas({ nome: value, tipo: null });
  }

  async function onChangeLogradouro(logradouroId) {
    if (logradouroId) {
      const dataEndereco = dataLogradouros.find(
        (item) => item.id === logradouroId,
      );

      form.setFieldsValue({
        cep: dataEndereco.cep
          ? mask(dataEndereco.cep, [pattern.cep])
          : dataEndereco.cep,
        tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
        bairro: dataEndereco.bairro.nome,
        cidade: dataEndereco.bairro.cidade.nome,
        estado: dataEndereco.bairro.cidade.estadoId,
        pais: 'BRASIL',
      });
    } else {
      await onLoadLogradouros();
    }
  }

  async function changeEstadoCivil(value) {
    if (value === 1) {
      setIsCasado(true);
      await onLoadPessoas({ current: 1, pageSize: 10, tipo: 1 });
    } else {
      setIsCasado(false);
    }
  }

  async function searchCidadesDropdown(value) {
    await onLoadCidadesDropdown({ estadoId: null, cidade: value });
  }

  async function onSearchProfissao(value) {
    const profissaoId = localStorage.getItem('icaseProfissaoId');
    const profissaoDesc = localStorage.getItem('icaseProfissaoDesc');
    if (profissaoId) {
      await onLoadProfissoes({ nomeProfissao: profissaoDesc });
      form.setFieldsValue({ profissaoId });

      localStorage.removeItem('icaseProfissaoId');
      localStorage.removeItem('icaseProfissaoDesc');
    } else {
      await onLoadProfissoes({ nomeProfissao: value });
    }
  }

  function searchTimeFavorito(value) {
    setTimeFavorito(value);
  }

  function searchComidaFavorita(value) {
    const comidaFavoritaId = localStorage.getItem('icaseComidaFavoritaId');
    const comidaFavoritaDesc = localStorage.getItem('icaseComidaFavoritaDesc');
    if (comidaFavoritaId) {
      setComidaFavorita(comidaFavoritaDesc);
      form.setFieldsValue({ comidaFavoritaId });

      localStorage.removeItem('icaseComidaFavoritaId');
      localStorage.removeItem('icaseComidaFavoritaDesc');
    } else {
      setComidaFavorita(value);
    }
  }

  function setConjuge(conjugeId) {
    setNovoConjugeId(conjugeId);
  }

  function novaPessoa() {
    setOpenModalPessoa(true);
  }

  function closeNovaPessoa() {
    const pessoaId = localStorage.getItem('icasePessoaId');
    setOpenModalPessoa(false);

    if (pessoaId) {
      setNovoConjugeId(pessoaId);
      onLoadPessoas({ pessoaId });
      form.setFieldsValue({ conjugeId: pessoaId });

      localStorage.removeItem('icasePessoaId');
    } else {
      onLoadPessoas({ current: 1, pageSize: 10, tipo: 1 });
    }
  }

  function handleMask(e, type, field) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (field === 'celular' || field === 'telefone') {
      const fieldValue = form.getFieldValue('contato');

      if (
        (fieldValue?.celularDDI.includes("BR") && field === 'celular')
        ||
        (fieldValue?.telefoneDDI.includes("BR") && field === 'telefone')
      )
        form.setFieldsValue({ contato: { [field]: value } });
      else
        form.setFieldsValue({ contato: { [field]: unMask(e.currentTarget.value.replace(/\D/g, '')) } });
    }
    else
      form.setFieldsValue({ [field]: value });
  }

  async function handleCep(value, type) {
    let cep = value;
    cep = unMask(cep);
    cep = mask(cep, [type]);

    if (type === pattern.cep) form.setFieldsValue({ cep });

    if (cep.length === 10) {
      const unmaskedCEP = unMask(cep);
      setLoadingEndereco(true);
      await api
        .get(url.SERVICES.CONSULTA_CEP(unmaskedCEP))
        .then(async (res) => {
          const { data: dataEndereco, status } = res;

          if (status === 204) {
            Notify(
              'warning',
              pt.comum.atencao,
              'Nenhum logradouro encontrado com o CEP informado.',
            );
          } else if (dataEndereco) {
            await onLoadLogradouros(dataEndereco.nome);
            form.setFieldsValue({
              enderecoLogradouroId: dataEndereco.id,
              cep: dataEndereco.cep
                ? mask(dataEndereco.cep, [pattern.cep])
                : '',
              tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
              bairro: dataEndereco.bairro.nome,
              cidade: dataEndereco.bairro.cidade.nome.toUpperCase(),
              estado: dataEndereco.bairro.cidade.estadoId,
              pais: 'BRASIL',
            });
          }

          setLoadingEndereco(false);
        })
        .catch((err) => {
          setLoadingEndereco(false);
        });
    }
  }

  return (
    <Fragment>
      <Collapse
        className='fixPadding'
        defaultActiveKey={['CP-1', !!showAdvanceData ? 'CP-2' : null]}
        bordered={false}
        ghost
        expandIconPosition='right'
        expandIcon={
          ({ isActive }) =>
            <div className='container-expand'>
              <span className='content-expand'>
                {isActive ? 'Ver menos' : 'Ver mais'}
              </span>
              <CaretDownOutlined style={{ fontSize: 20 }} rotate={isActive ? 180 : 0} />
            </div>
        }>
        <Collapse.Panel key="CP-1" header="Dados básicos">
          <Row>
            <Col xs="12" md="8">
              <p className="heading-small text-muted mb-2">
                <small>Nome</small>
              </p>
              <Form.Item
                name="nome"
                rules={[
                  {
                    required: true,
                    message: 'Favor informar o Nome',
                  },
                ]}>
                <Input placeholder=" Nome" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="4">
              <p className="heading-small text-muted mb-2">
                <small>Celular</small>
              </p>
              <Form.Item className="my-2">
                <Input.Group compact>
                  <Form.Item noStyle name={['contato', 'celularDDI']}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      disabled={viewClient}
                      placeholder=" DDI"
                      style={{ width: '30%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.props.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                      }
                      options={coutriesDDI.map(item => ({
                        label: <span>{`${item.name}(${item.code})`}</span>,
                        value: `${item.iso}${item.code}`
                      }))} />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name={['contato', 'celular']}
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o celular',
                      },
                      // eslint-disable-next-line no-empty-pattern
                      ({ }) => ({
                        validator(rule, value) {
                          if (value) {
                            const fieldValue = form.getFieldValue('contato');
                            const unMaskedValue = unMask(value);
                            const regex = new RegExp(/^(?:(55\d{2})|\d{2})[6-9]\d{8}$/);
                            if (!regex.test(unMaskedValue) && fieldValue?.celularDDI.includes('BR')) {
                              return Promise.reject('Número de celular inválido');
                            }
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <Input
                      disabled={viewClient}
                      onKeyUp={(e) => handleMask(e, pattern.celular, 'celular')}
                      maxLength={20}
                      placeholder=" DDD + Telefone"
                      style={{ width: '70%' }} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="8">
              <p className="heading-small text-muted mb-2">
                <small>Email</small>
              </p>
              <Form.Item className="my-2" name="email">
                <Input placeholder=" Email" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="4">
              <p className="heading-small text-muted mb-2">
                <small>Nascimento</small>
              </p>
              <Form.Item className="my-2" name="nascimento">
                <DatePicker
                  disabled={viewClient}
                  locale={locale}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder=" Data do nasc."
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
        <Row className="mx-1">
          <Col xs="12">
            <Divider />
          </Col>
        </Row>
        <Collapse.Panel key="CP-2" header="Dados avançados">
          <Row>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>CPF</small>
              </p>
              <Form.Item
                className="my-2"
                name="CPF"
                rules={[
                  // eslint-disable-next-line no-empty-pattern
                  ({ }) => ({
                    validator(rule, value) {
                      if (value) {
                        const unMaskedValue = unMask(value);
                        const isDocumento = checkDocumento('cpf', unMaskedValue);
                        if (isDocumento === true) {
                          return Promise.reject('Número de CPF inválido');
                        }
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}>
                <Input
                  disabled={viewClient}
                  placeholder=" Número do CPF"
                  onKeyUp={(e) => handleMask(e, pattern.cpf, 'CPF')}
                />
              </Form.Item>
            </Col>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Telefone</small>
              </p>
              <Form.Item className="my-2">
                <Input.Group compact>
                  <Form.Item noStyle name={['contato', 'telefoneDDI']}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      disabled={viewClient}
                      placeholder=" DDI"
                      style={{ width: '25%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.props.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                      }
                      options={coutriesDDI.map(item => ({
                        label: <span>{`${item.name}(${item.code})`}</span>,
                        value: `${item.iso}${item.code}`
                      }))} />
                  </Form.Item>
                  <Form.Item noStyle name={['contato', 'telefone']}>
                    <Input
                      placeholder=" DDD + Telefone"
                      disabled={viewClient}
                      maxLength={20}
                      onKeyUp={(e) => handleMask(e, pattern.telefone, 'telefone')}
                      style={{ width: '75%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Nacionalidade</small>
              </p>
              <Form.Item className="my-2" name="nacionalidadeId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  placeholder=" Nacionalidade">
                  {dataNacionalidades.map((item) => (
                    <Option key={item.id}>{item.descricao}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Naturalidade</small>
              </p>
              <Form.Item className="my-2" name="naturalidadeId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  placeholder=" Naturalidade"
                  onSearch={searchCidadesDropdown}>
                  {dataCidadesDropdown &&
                    dataCidadesDropdown.map((item) => (
                      <Option key={item.id}>{item.nome}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Time do coração</small>
              </p>
              <Form.Item className="my-2" name="timeFavoritoId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  filterOption={false}
                  placeholder=" Time favorito"
                  onSearch={searchTimeFavorito}>
                  {dataTimeFavorito.map((item) => (
                    <Option key={item.id}>{item.descricao}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Comida favorita</small>
              </p>
              <Form.Item className="my-2" name="comidaFavoritaId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  filterOption={false}
                  placeholder=" Comida favorita"
                  onSearch={searchComidaFavorita}>
                  {dataComidaFavorita.map((item) => (
                    <Option key={item.id}>{item.descricao}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!viewClient && (
              <Col xs="12" md="1" className="my-2">
                <p className="heading-small text-muted mb-1 text-white">
                  <small>&nbsp;</small>
                </p>
                <Tooltip
                  placement="topRight"
                  title={'Cadastrar Comida Favorita'}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => setOpenModalComidaFavorita(true)}
                    icon
                    color="violet">
                    <Icon name="plus" />
                  </Button>
                </Tooltip>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>RG</small>
              </p>
              <Form.Item className="my-2" name="rgNumero">
                <Input type="number" placeholder=" RG" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>RG Orgão Expeditor</small>
              </p>
              <Form.Item name="rgOrgaoExpedidor">
                <Input placeholder=" Orgão expeditor" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>RG UF</small>
              </p>
              <Form.Item name="rgUf">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  placeholder=" RG UF">
                  {estados.map((item) => (
                    <Option key={item.id}>{item.nome}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>RG Emissão</small>
              </p>
              <Form.Item className="my-2" name="rgEmissao">
                <DatePicker
                  disabled={viewClient}
                  locale={locale}
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder=" RG Emissão"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {tipoPessoa !== 'conjuge' && <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Estado civil</small>
              </p>
              <Form.Item className="my-2" name="estadoCivil">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  disabled={viewClient}
                  placeholder=" Estado civil"
                  onChange={changeEstadoCivil}>
                  {enumEstadoCivil.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>}
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Formação</small>
              </p>
              <Form.Item className="my-2" name="formacaoId">
                <Select
                  disabled={viewClient}
                  getPopupContainer={(node) => node.parentNode}
                  placeholder=" Formação">
                  {formacoes.map((item) => (
                    <Option key={item?.id}>{item?.nome}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Profissão</small>
              </p>
              <Form.Item className="my-2" name="profissaoId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  filterOption={false}
                  placeholder=" Profissao"
                  onSearch={onSearchProfissao}>
                  {profissoes.map((item) => (
                    <Option key={item.id}>{item.nome}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!viewClient && (
              <Col xs="12" md="1" className="my-2">
                <p className="heading-small text-muted mb-1 text-white">
                  <small>&nbsp;</small>
                </p>
                <Tooltip
                  placement="topRight"
                  title={'Cadastrar Profissão'}>
                  <Button
                    size="tiny"
                    style={{ width: '100%' }}
                    onClick={() => setOpenModalProfissoes(true)}
                    icon
                    color="violet">
                    <Icon name="plus" />
                  </Button>
                </Tooltip>
              </Col>
            )}
            {isMaster && (
              <Col xs="12" md="4" className="my-2">
                <p className="heading-small text-muted mb-2">
                  <small>Captador</small>
                </p>
                <Form.Item name="captadorId">
                  <Select
                    allowClear
                    showSearch
                    disabled={viewClient}
                    filterOption={false}
                    onSearch={onSearchCaptador}
                    placeholder="Captador">
                    {dataCorretores.map((item) => (
                      <Option key={item.id} disabled={item.isSeparator}>
                        {item?.nome?.toString()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Divider plain orientation="left" />
              {/* <h5 className="title">Contato(s)</h5> */}
            </Col>
          </Row>

          {isCasado === true ? (
            <Fragment>
              <Row>
                <Col xs="12" md="6" className="my-2">
                  <p className="heading-small text-muted mb-2">
                    <small>Conjuge</small>
                  </p>
                  <Form.Item className="my-2" name="conjugeId">
                    <Select
                      getPopupContainer={(node) => node.parentNode}
                      placeholder=" Buscar Conjuge"
                      showSearch
                      disabled={viewClient}
                      optionFilterProp="children"
                      onChange={setConjuge}
                      onSearch={onSearchConjuge}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          ?.indexOf(input?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                      }>
                      {dataPessoas.map((item) => (
                        <Option key={item.id}>{item.nome}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {!viewClient && (
                  <Col xs="12" md="1" className="my-2">
                    <p className="heading-small text-muted mb-1 text-white">
                      <small>&nbsp;</small>
                    </p>
                    <Tooltip
                      placement="topRight"
                      title={'Cadastrar Conjuge'}>
                      <Button
                        style={{ width: '100%' }}
                        onClick={() => novaPessoa(true)}
                        icon
                        color="violet">
                        <Icon name="plus" />
                      </Button>
                    </Tooltip>
                  </Col>)}
              </Row>
            </Fragment>
          ) : null}

          <Row>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Sexo</small>
              </p>
              <Form.Item className="my-2" name="masculino">
                <Select
                  disabled={viewClient}
                  getPopupContainer={(node) => node.parentNode}
                  placeholder=" Sexo">
                  <Option value={1}>Masculino</Option>
                  <Option value={2}>Feminino</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Mãe</small>
              </p>
              <Form.Item className="my-2" name="mae">
                <Input placeholder=" Mãe" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="6" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Pai</small>
              </p>
              <Form.Item className="my-2" name="pai">
                <Input placeholder=" Pai" disabled={viewClient} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Observação</small>
              </p>
              <Form.Item className="my-2" name="observacao">
                <TextArea
                  disabled={viewClient}
                  placeholder=" Observações"
                  rows={4}
                  maxLength={500}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Divider plain orientation="left" />
              {/* <h5 className="title">Endereço</h5> */}
            </Col>
          </Row>
          {tipoPessoa !== 'conjuge' && <Fragment>
            {loadingEndereco && <SkeletonTableBasic cardShadowless />}
            {!loadingEndereco && (
              <Fragment>
                <Row>
                <Col xs="12" md="2" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>CEP</small>
                    </p>
                    <Form.Item className="my-2" name="cep">
                      <Input
                        disabled={viewClient}
                        placeholder=" CEP"
                        onKeyUp={(e) => {
                          setCurrentCep(e.currentTarget.value);
                          handleCep(e.currentTarget.value, pattern.cep);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!viewClient && (
                    <Col xs="12" md="1" className="my-2">
                      <p className="heading-small text-muted mb-1 text-white">
                        <small>&nbsp;</small>
                      </p>
                      <Tooltip placement="topRight" title={'Cadastrar Logradouro'}>
                        <Button
                          style={{ width: '100%' }}
                          onClick={() => setModalLogradouroOpen(true)}
                          icon
                          color="violet">
                          <Icon name="plus" />
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                  <Col xs="12" md="9" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Logradouro</small>
                    </p>
                    <Form.Item className="my-2" name="enderecoLogradouroId">
                      <Select
                        getPopupContainer={(node) => node.parentNode}
                        showSearch
                        disabled={viewClient}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children[0] &&
                          option.children[0]
                            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                        }
                        placeholder=" Logradouro"
                        onSearch={onSearchLogradouro}
                        onChange={onChangeLogradouro}
                        onClear={onChangeLogradouro}
                        rules={[
                          {
                            required: true,
                            message: 'Favor informar o logradouro',
                          },
                        ]}>
                        {dataLogradouros.map((item) => (
                          <Option key={item.id}>
                            {item.nome}
                            {item?.bairro?.nome &&
                              `, Bairro: ${item?.bairro?.nome}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Tipo</small>
                    </p>
                    <Form.Item className="my-2" name="tipoLogradouro">
                      <Input disabled placeholder=" Tipo" />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Bairro</small>
                    </p>
                    <Form.Item className="my-2" name="bairro">
                      <Input placeholder=" Bairro" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Cidade</small>
                    </p>
                    <Form.Item className="my-2" name="cidade">
                      <Input placeholder=" Cidade" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="3" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Estado</small>
                    </p>
                    <Form.Item className="my-2" name="estado">
                      <Input placeholder=" Estado" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="3" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>País</small>
                    </p>
                    <Form.Item className="my-2" name="pais">
                      <Input disabled placeholder=" País" />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="6" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Complemento</small>
                    </p>
                    <Form.Item name="enderecoComplemento">
                      <Input placeholder=" Complemento" disabled={viewClient} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="2" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Número</small>
                    </p>
                    <Form.Item className="my-2" name="enderecoNumero">
                      <Input placeholder=" Número" disabled={viewClient} />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="2" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Lote/Apto</small>
                    </p>
                    <Form.Item className="my-2" name="loteApto">
                      <Input placeholder=" Lote/Apto" disabled={viewClient} />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="2" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Quadra/Bloco</small>
                    </p>
                    <Form.Item className="my-2" name="quadraBloco">
                      <Input placeholder=" Quadra/Bloco" disabled={viewClient} />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="6" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Condomínio/Empreendimento</small>
                    </p>
                    <Form.Item name="condominioId">
                      <Select
                        getPopupContainer={(node) => node.parentNode}
                        showSearch
                        disabled={viewClient}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")
                            .indexOf(input?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")) >= 0
                        }
                        placeholder=" Condomínio/Empreendimento">
                        {dataCondominios.map((item) => (
                          <Option key={item.id}>
                            {`${item.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Fragment>
            )}
          </Fragment>}
        </Collapse.Panel>
        <Row className="mx-1">
          <Col xs="12">
            <Divider />
          </Col>
        </Row>
      </Collapse>

      <ModalGenerico
        open={modalPessoa}
        close={() => closeNovaPessoa()}
        title={'Conjuge'}>
        <Pessoa
          match={{ params: { modo: 'cadastrar', tipoPessoa: 'conjuge' } }}
          close={() => closeNovaPessoa()}
          isAtendimento={false}
        />
      </ModalGenerico>

      <ModalLogradouro
        open={modalLogradouroOpen}
        close={() => {
          setModalLogradouroOpen(false);
          if (currentCep) handleCep(currentCep, pattern.cep);
        }}
      />

      <ModalComidaFavorita
        open={openModalComidaFavorita}
        close={() => {
          setOpenModalComidaFavorita(false);
          searchComidaFavorita();
        }}
      />

      <ModalGenericoProfissao
        open={openModalProfissoes}
        close={() => {
          setOpenModalProfissoes(false);
          onSearchProfissao();
        }}
        children={<CompProfissoes
          match={{ params: { modo: 'cadastrar' } }}
          location={{ state: { identificador: undefined } }}
          onVoltar={() => {
            setOpenModalProfissoes(false);
            onSearchProfissao();
          }}
        />}
      />
    </Fragment>
  );
}

export default FormFisica;
