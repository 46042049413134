import React, { Fragment } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { Select } from 'antd';
import Button from '~/shared/Button';

const { Option } = Select;

const SecondConfirmationModal = ({open, onConfirm, onCancel}) => {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => {
          onCancel();
        }}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>Confirmar atribuição</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span>
              <strong>Atenção:</strong> Você está prestes a enviar uma grande quantidade de atendimentos para um corretor específico. Tem certeza de que deseja continuar?
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span style={{ fontSize: 13 }}>
              Observação: Dependendo do volume de dados, este processo pode levar alguns minutos.
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => onConfirm()}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              onClick={() => onCancel()}>
              <Icon name="times" />
              Cancelar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

function ModalAtribuirLead({
  open,
  close,
  selectedEvent,
  confirmEvent,
  listCorretores,
  textTitle,
  isHandlingLeads,
  corretorId,
  showSecondConfirmation
}) {
  const [_showSecondConfirmationModal, setShowSecondConfirmationModal] = React.useState(false);

  const onSubmit = () => {
    if (showSecondConfirmation) {
      setShowSecondConfirmationModal(true);
      return;
    }

    if (corretorId) {
      confirmEvent(corretorId);
    }
  };

  function removeInvalidOptions(item) {
    return item?.nome !== 'TODOS';
  }

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => close()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>{textTitle}</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <Select
              style={{ width: '85%' }}
              placeholder=" Corretor"
              showSearch
              onChange={selectedEvent}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .indexOf(
                    input
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, ''),
                  ) >= 0
              }>
              {listCorretores?.filter(removeInvalidOptions).map((item) => (
                <Option
                  key={item.id}
                  disabled={item.isSeparator}>
                  {item?.nome?.toString()}
                </Option>
              ))}
            </Select>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => onSubmit()}
              isloading={isHandlingLeads ? 'true' : 'false'}
              loadtext={'Processando...'}
              disabled={corretorId === undefined || corretorId === null}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              onClick={() => close()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <SecondConfirmationModal
        open={_showSecondConfirmationModal}
        onCancel={() => {
          setShowSecondConfirmationModal(false);
        }}
        onConfirm={() => {
          if (corretorId) {
            confirmEvent(corretorId);
            setShowSecondConfirmationModal(false);
          }
        }}
      />
    </Fragment>
  );
}

export default ModalAtribuirLead;

