import React, {Fragment} from 'react';
import {Calendar, Radio, Select, Badge} from 'antd';
import {Row, Col, Card, CardBody, CardHeader} from 'reactstrap';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const {Option} = Select;

const colors = ['error', 'processing', 'success', 'warning'];

function onSelectDate(
  value,
  dataEscalas,
  setModo,
  setOpenModal,
  setSelectedDate,
  incluir,
  editar,
) {
  const plantao = dataEscalas.find((item) =>
    moment(item.diaMes).isSame(value, 'day'),
  );

  if ((editar && plantao) || incluir) {
    setModo(plantao ? MODO.EDITAR : MODO.CADASTRAR);
    console.log(moment(value).format('DD/MM/YYYY'));
    setSelectedDate(moment(value, 'DD/MM/YYYY'));
    setOpenModal(true);
  }
}

function onPanelChange(value, mode, setMesAtual, setAnoAtual) {
  setAnoAtual(value.year());
  setMesAtual(value.month());
}

function customHeader(
  value,
  type,
  onChange,
  onTypeChange,
  mesAtual,
  setIsYearMonth,
) {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();

  if (type === 'year') {
    setIsYearMonth(true);
  } else {
    setIsYearMonth(false);
  }

  const months = [];
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(
      localeData.months(current).replace(/^./, (str) => str.toUpperCase()),
    );
  }

  for (let index = start; index < end; index++) {
    monthOptions.push(
      <Option className="month-item" key={`${index}`}>
        {months[index]}
      </Option>,
    );
  }

  const year = value.year();
  const yearOptions = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    yearOptions.push(
      <Option key={i} className="year-item">
        {i}
      </Option>,
    );
  }

  return (
    <>
      <Row>
        <Col xs="12">
          <Radio.Group
            value={type}
            onChange={(e) => onTypeChange(e.target.value)}>
            <Radio value="month">Mês</Radio>
            <Radio value="year">Ano</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="my-4">
        {type === 'month' && (
          <Col xs="12" md="2">
            <Select
              style={{width: '100%'}}
              value={mesAtual.toString()}
              onChange={(selectedMonth) => {
                const newValue = value.clone();
                newValue.month(parseInt(selectedMonth, 10));
                onChange(newValue);
              }}>
              {monthOptions}
            </Select>
          </Col>
        )}
        <Col xs="12" md="2">
          <Select
            style={{width: '100%'}}
            value={year.toString()}
            onChange={(newYear) => {
              const now = value.clone().year(Number(newYear));
              onChange(now);
            }}>
            {yearOptions}
          </Select>
        </Col>
      </Row>
    </>
  );
}

function customDate(date, selectedDate, mesAtual, dataPlantao, dataEscalas) {
  const escalasDoDia = dataEscalas.filter((item) =>
    moment(item.diaMes).isSame(date, 'day'),
  );
  const isSameDate = moment(selectedDate).isSame(date);
  const plantoesPorEscala = dataPlantao.filter((f) =>
    escalasDoDia.some((e) => e.plantaoId === f.id),
  );

  return (
    <Card
      style={{maxHeight: 162, height: 162}}
      className={isSameDate ? 'atendimentoActive' : ''}>
      <CardHeader className="d-flex justify-content-between pt-2">
        <span className="font-weight-bold">{moment(date).format('D')}</span>
        <span>
          {moment(date)
            .format('ddd')
            .replace(/^./, (str) => str.toUpperCase())}
        </span>
      </CardHeader>
      <CardBody
        className="pt-2 d-flex flex-column"
        style={{
          height: 105,
          overflowY: 'auto',
          width: 'auto',
          marginRight: 15,
        }}>
        {plantoesPorEscala &&
          plantoesPorEscala.length > 0 &&
          date.month() === mesAtual &&
          plantoesPorEscala.map((item, index) => (
            <Badge
              key={item.id}
              className="d-flex align-items-center"
              status={index > 2 ? colors[3] : colors[index]}
              text={item.descricao}
            />
          ))}
      </CardBody>
    </Card>
  );
}

const CalendarioEscalas = ({
  dataEscalas,
  dataPlantao,
  setModo,
  setOpenModal,
  setSelectedDate,
  selectedDate,
  setAnoAtual,
  setMesAtual,
  mesAtual,
  incluir,
  editar,
  setIsYearMonth,
}) => (
  <Fragment>
    <Calendar
      className="calendarHeader"
      headerRender={({value, type, onChange, onTypeChange}) =>
        customHeader(
          value,
          type,
          onChange,
          onTypeChange,
          mesAtual,
          setIsYearMonth,
        )
      }
      dateFullCellRender={(date) =>
        customDate(date, selectedDate, mesAtual, dataPlantao, dataEscalas)
      }
      onSelect={(date) =>
        onSelectDate(
          date,
          dataEscalas,
          setModo,
          setOpenModal,
          setSelectedDate,
          incluir,
          editar,
        )
      }
      onPanelChange={(date, mode) =>
        onPanelChange(date, mode, setMesAtual, setAnoAtual)
      }
    />
  </Fragment>
);
export default CalendarioEscalas;
