import { decrypt } from '~/infra/resources/pattern/cripto';

const allActions = [
  {
    id: 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
    descricao: 'Incluir',
  },
  {
    id: '5aeb97ac-ca9b-4c05-977d-1a3106eedd59',
    descricao: 'Inativar',
  },
  {
    id: 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
    descricao: 'Alterar',
  },
  {
    id: 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
    descricao: 'Excluir',
  },
  {
    id: '8a9e5a53-6a15-4f05-bc95-7db5c716bce0',
    descricao: 'Mudar situação',
  },
  {
    id: '5022d55b-05a8-4dd8-85df-9e105ba545e1',
    descricao: 'Editar imóveis de outros captadores',
  },
  {
    id: 'e17b0d5c-6453-44ef-b782-92ff6b5b191b',
    descricao: 'Emitir relatórios',
  },
  {
    id: '9ea7c131-4575-4d03-a469-256977cc9546',
    descricao: 'Visualizar atendimentos de outros usuários',
  },
  {
    id: '3bb68202-94f8-484c-b223-b8d714bee5cc',
    descricao: 'Visualizar aba Portais do Imóvel',
  },
  {
    id: '493dabac-0877-4683-ad0f-164f3cdf6a48',
    descricao: 'Visualizar aba Proprietários',
  },
  {
    id: '38833ac6-a442-42a0-9376-ebfcded51da3',
    descricao: 'Visualizar aba Imagens',
  },
  {
    id: '85b9d87f-aacf-49ea-a630-5ce8b278f765',
    descricao: 'Permitir republicação',
  },
];

function getActionsMenus(identificador) {
  const roles = JSON.parse(localStorage.getItem('icaseRoles') || []);
  const encryptedMenus = localStorage.getItem('icaseStructuredMenus');
  const decryptedMenus = decrypt(encryptedMenus);
  const structuredMenus = JSON.parse(decryptedMenus) || [];

  const filteredStructuredMenu = structuredMenus.find(
    (menu) => menu.identificador === Number(identificador),
  );

  const roleValidated = roles.find(
    (role) => role === 'SystemAdmin' || role === 'Master' || role === 'Diretor' || role === 'AdmMatriz',
  );

  return roleValidated
    ? { actions: allActions }
    : filteredStructuredMenu || { actions: [] };
}

export default getActionsMenus;
