import React from 'react';
import useLeadOrigin from './use-lead-origin';

import {Card, Row, Col} from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';

import {Pie} from '@ant-design/charts';

export default function LeadOrigin({filters}) {
  const { mainPeriod, broker, brokerGroup, initialDate, finalDate } = filters;
  const {config, data} = useLeadOrigin(mainPeriod, broker, brokerGroup, initialDate, finalDate);

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Origem dos Leads</CardTitle>
      {data && data?.length > 0 ? <Row>
        <Col>
          <Pie {...config} />
        </Col>
      </Row> : <span>Sem dados...</span>}
    </Card>
  );
}

