import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useFormacoes(current, pageSize, nomeFormacao) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : null;
    const tamanhoPagina = pageSize ? pageSize : null;
    const filterNomeFormacao = nomeFormacao ? nomeFormacao : null;
    let filter = null;

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (tamanhoPagina) {
      if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
      else filter = `?tamanhoPagina=${tamanhoPagina}`;
    }

    if (filterNomeFormacao) {
      if (filter.length > 0) filter += `&nome=${filterNomeFormacao}`;
      else filter = `?nome=${filterNomeFormacao}`;
    }

    try {
      await api;
      api
        .get(`${url.FORMACOES.CONSULTA()}${filter ? filter : ''}`)
        .then((res) => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.data?.length);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize, nomeFormacao, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
