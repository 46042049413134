import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useCaptador(nome, cpf, empresaId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const localEmpresaId = localStorage.getItem('icaseEmpresaId');
    const filterNome = nome ? nome : null;
    const filterCPF = cpf ? cpf : null;
    const filterEmpresa = empresaId ? empresaId : localEmpresaId;
    let filter = '';

    if (filterNome) {
      if (filter.length > 0) filter += `&nome=${filterNome}`;
      else filter = `?nome=${filterNome}`;
    }

    if (filterCPF) {
      if (filter.length > 0) filter += `&cpf=${filterCPF}`;
      else filter = `?cpf=${filterCPF}`;
    }

    if (filterEmpresa) {
      if (filter.length > 0) filter += `&empresaId=${filterEmpresa}`;
      else filter = `?empresaId=${filterEmpresa}`;
    }

    if (filter) {
      try {
        await api
          .get(`${url.DROPDOWN.CAPTADORES_DROPDOWN()}${filter ? filter : ''}`)
          .then((res) => {
            let corretoresAtivos = res?.data.filter((corretor) => !corretor.excluido) || []
            let corretoresInativos = res?.data.filter((corretor) => corretor.excluido).map((corretor) => {
              corretor.nome = `${corretor.nome.toString()} (inativo)`
              return corretor
            }) || []

            if(corretoresInativos.length > 0){
              corretoresInativos.unshift({isSeparator: true, id: 'separadorInativo', nome:'Inativos'})
            }

            setData([...corretoresAtivos, ...corretoresInativos] || []);
            setQuantidade(res?.data?.data?.length);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [cpf, empresaId, nome]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}

