import {useMediaQuery} from 'react-responsive';
import {sizeScreenRules} from '~/styles/dimensions';

export function useDeviceScreen() {
  const isSmall = useMediaQuery({query: sizeScreenRules.small});
  const isLarge = useMediaQuery({query: sizeScreenRules.large});
  const isXLarge = useMediaQuery({query: sizeScreenRules.xLarge});
  const isXxLarge = useMediaQuery({query: sizeScreenRules.xxLarge});
  const isXxxLarge = useMediaQuery({query: sizeScreenRules.xxxLarge});

  return {
    isSmall,
    isLarge,
    isXLarge,
    isXxLarge,
    isXxxLarge,
  };
}

