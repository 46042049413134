import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, TimePicker } from 'antd';
import { Icon } from 'semantic-ui-react';

import ModalConfirm from '~/components/ModalConfirm';
import Page from '~/components/Page';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Plantao(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [identificador, setIdentificador] = useState();
  const [plantaoId, setPlantaoId] = useState(null);
  const [form] = Form.useForm();

  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const empresaId = localStorage.getItem('icaseEmpresaId');

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const { row: editData, identificador } = props.location.state;

        const splitHoraInicio = editData.horaInicio.split(':')
        const splitHoraFim = editData.horaFim.split(':')

        editData.horaInicioT = moment().set('hour', splitHoraInicio[0]).set('minute', splitHoraInicio[1]).set('second', splitHoraInicio[2]);
        editData.horaFimT = moment().set('hour', splitHoraFim[0]).set('minute', splitHoraFim[1]).set('second', splitHoraFim[2]);

        setInitialValues(editData);
        setIdentificador(identificador);
        setModo(MODO.EDITAR);
        setPlantaoId(props.location.state.row.id);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        const { identificador } = props.location.state;

        setIdentificador(identificador);
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/plantoes/${identificador}`);
    } else {
      history.push('/app/plantoes');
    }
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (dados.horaInicio > dados.horaFim) {
          Notify('error', pt.comum.atencao, 'Hora inicial não pode ser maior que a hora final');
          setIsLoading(false);
        }
        else {
          if (modo === MODO.CADASTRAR) {
            const payload = {
              descricao: dados.descricao,
              horaInicio: moment(dados.horaInicioT).format('HH:mm'),
              horaFim: moment(dados.horaFimT).format('HH:mm'),
              empresaId,
            };

            await api
              .post(url.PLANTAO.CADASTRAR(), payload)
              .then((res) => {
                setIsLoading(false);

                if (props?.onVoltar) {
                  localStorage.setItem('icasePlantaoId', res.data?.data?.id);
                  localStorage.setItem('icasePlantaoDesc', res.data?.data?.descricao);
                  voltar();
                } else {
                  setOpenModalConfirm(true);
                }
              })
              .catch((err) => {
                setIsLoading(false);
                const { error } = err.response.data;
                if (error) {
                  for (const item in error) {
                    Notify('error', pt.comum.atencao, error[item]);
                  }
                }
              });
          } else if (modo === MODO.EDITAR) {
            const payload = {
              id: plantaoId,
              descricao: dados.descricao,
              horaInicio: moment(dados.horaInicioT).format('HH:mm'),
              horaFim: moment(dados.horaFimT).format('HH:mm'),
              empresaId,
            };

            await api
              .put(url.PLANTAO.EDITAR(), payload)
              .then((res) => {
                setIsLoading(false);
                setOpenModalConfirm(true);
              })
              .catch((err) => {
                setIsLoading(false);
                const { error } = err.response.data;
                if (error) {
                  for (const item in error) {
                    Notify('error', pt.comum.atencao, error[item]);
                  }
                }
              });
          }
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }


  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? 'Cadastrar plantão'
                    : 'Atualizar plantão'}
                </h5>
                <p className="category">Manutenção de plantão</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="8">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="descricao"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o nome!',
                                },
                              ]}>
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="6" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>Hora Inicial</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="horaInicioT"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a hora inicial!',
                                },
                              ]}>
                              <TimePicker
                                style={{ width: '100%' }}
                                showSecond={false}
                                format="HH:mm"
                                placeholder=" Hora Inicial"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs="6" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>Hora Final</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="horaFimT"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a hora final!',
                                },
                              ]}>
                              <TimePicker
                                style={{ width: '100%' }}
                                showSecond={false}
                                format="HH:mm"
                                placeholder=" Hora Final"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-4">
                        <Col xs="12" md="3">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR
                                ? 'Cadastrando...'
                                : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR
                              ? 'Cadastrar'
                              : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="undo" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={() => modo === MODO.CADASTRAR ? voltar() : setOpenModalConfirm(false)}
              message={modo === MODO.CADASTRAR ? 'Cadastrado com Sucesso' : 'Alterações salvas'}
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Plantao;
