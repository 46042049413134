import { useEffect, useState } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import api from '~/services/api';
import url from '~/infra/urls';
import useLocalStorage from '~/hooks/localstorage/useLocalStorage';

const allowTypes = ['proposta-ativa-fila-atendimento'];

export default function useRetainObjectService({
  objectRetainedId,
  retainedType,
}) {
  const [retainerId] = useLocalStorage('UsuarioId', null);
  const [lifetime, setLifetime] = useState(0);

  const allowedType = allowTypes.includes(retainedType);
  const allowedRetainObject = objectRetainedId && allowedType;

  const {
    data: retainedObject,
    error: retainedObjectError,
    isLoading: isRetainingObject,
  } = useSWR(allowedRetainObject ? [url.RETAIN_SERVICE.RETAIN_OBJECT(), objectRetainedId] : null, ([url, objectRetainedId]) => api.post(url, {
    retainerId,
    objectRetainedId,
    retainedType: retainedType,
  }), {
    revalidateOnFocus: true,
    refreshInterval: lifetime * 1000,
  });

  const { trigger: triggerReleaseObject, isMutating: isReleasingObject } = useSWRMutation(retainedObject?.data?.id ? url.RETAIN_SERVICE.RELEASE_OBJECT(retainedObject?.data.id) : null, (url) => {
    return api.delete(url);
  });

  useEffect(() => {
    if (retainedObject) {
      const expiresAt = new Date(retainedObject?.data?.expiresAt);
      setLifetime(Math.abs(expiresAt - new Date()) / 1000);
    }
  }, [retainedObject]);

  return {
    retainedObject,
    retainedObjectError,
    isRetainingObject,
    releaseObject: async () => {
      if (retainedObject) {
        return triggerReleaseObject();
      }
    },
    isReleasingObject,
    lifetime,
    expired: lifetime <= 0,
  };
}
