import {Row, Progress, Col} from 'antd';
import React, {useEffect, useState} from 'react';
import * as signalR from '@microsoft/signalr';
import api from '~/services/api';

function ProgressBarAcaoEmLote({usuarioId}) {
  const [uploadProgress, setUploadProgress] = useState(
    localStorage.getItem('progress-acao-em-lote') || 0,
  );

  useEffect(() => {
    const fetchProgress = async () => {
      const response = await api.get(`atendimento/progress`);
      if (response.data.progress === 100) response.data.progress = 0;
      setUploadProgress(response.data.progress);

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BASE_URL.replace('/api', '')}/acao-em-lote`,
          {withCredentials: false},
        )
        .build();

      connection.on('ReceiveProgress', (userReceive, progress) => {
        if (userReceive === usuarioId) {
          setUploadProgress(progress);
          localStorage.setItem('progress-acao-em-lote', progress);
        }
      });

      connection
        .start()
        .catch((err) => console.error('SignalR connection error: ', err));

      return () => {
        connection.stop();
      };
    };

    fetchProgress();
  }, [usuarioId]);

  return (
    <Row className="w-100">
      <Col className="w-100">
        <div className="p-2 mb-4 w-100">
          <p>
            <strong>Progresso da ação em lote:</strong>
          </p>
          <Progress
            percent={uploadProgress}
            status="normal"
            size="default"
            strokeColor={{from: '#108ee9', to: '#87d068'}}
          />
        </div>
      </Col>
    </Row>
  );
}

export default ProgressBarAcaoEmLote;
