import React from 'react';
import useRealEstate from './use-real-estate';

import { Card, Row, Col } from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';
import { Pie } from '@ant-design/charts';
import { Grid, GridItem } from './Grid';

export default function RealEstate({ filters }) {
  const { mainPeriod, broker,brokerGroup, initialDate, finalDate } = filters;
  const { data, config, totalAmount } = useRealEstate(mainPeriod, broker, brokerGroup, initialDate, finalDate);

  function formatToCurrency(value) {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Imóveis</CardTitle>
      <Row>
        <Col>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 8,
            }}>
            <span
              style={{
                fontSize: 30,
                fontWeight: 'bold',
              }}>
              {totalAmount}
            </span>
            <span>Disponíveis</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            !data || data.length === 0 ? <span>Sem Dados ...</span>
              : <Grid>
                <GridItem span={3} color="#fff">
                  <span>Operação</span>
                </GridItem>
                <GridItem span={2} color="#fff">
                  <span>Quantidade</span>
                </GridItem>
                <GridItem span={6} color="#fff">
                  <span>VGV potencial</span>
                </GridItem>

                {data[0] && <>
                  <GridItem span={3} color="#f56384" textColor="#FFF">
                    <span>{data[0].operacao}</span>
                  </GridItem>
                  <GridItem span={2} color="#f56384" textColor="#FFF">
                    <span>{data[0].quantidade}</span>
                  </GridItem>
                  <GridItem span={6} color="#f56384" textColor="#FFF">
                    <span>{formatToCurrency(data[0].vgvPotencial)}</span>
                  </GridItem>
                </>}

                {data[1] && <>
                  <GridItem span={3} color="#f7a651" textColor="#FFF">
                    <span>{data[1].operacao}</span>
                  </GridItem>
                  <GridItem span={2} color="#f7a651" textColor="#FFF">
                    <span>{data[1].quantidade}</span>
                  </GridItem>
                  <GridItem span={6} color="#f7a651" textColor="#FFF">
                    <span>{formatToCurrency(data[1].vgvPotencial)}</span>
                  </GridItem>
                </>}

                {data[2] && <>
                  <GridItem span={3} color="#5dc5c8" textColor="#FFF">
                    <span>{data[2].operacao}</span>
                  </GridItem>
                  <GridItem span={2} color="#5dc5c8" textColor="#FFF">
                    <span>{data[2].quantidade}</span>
                  </GridItem>
                  <GridItem span={6} color="#5dc5c8" textColor="#FFF">
                    <span>{formatToCurrency(data[2].vgvPotencial)}</span>
                  </GridItem>
                </>}
              </Grid>
          }

        </Col>
        <Col>
          <Pie {...config} />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Pie {...config} />
        </Col>
      </Row> */}
    </Card>
  );
}

