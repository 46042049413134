import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';

export default function useDistrbuicao() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.get(url.DISTRIBUICAO_LEAD.ALL_BY_EMPRESA())
        .then((res) => {
          setData(res?.data);
        });
    } catch (e) {
      setData(null);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const atualizaOrdem = async (distribuicaoLeadId, ordem) => {
    try {
      setIsLoading(true)

      const payload = {
        distribuicaoLeadId,
        ordem
      };

      await api.put(url.DISTRIBUICAO_LEAD.UPDATE_ORDER(), payload).then(() => {
        Notify('success', '', "Reordenado com sucesso!");
      });
    } catch (e) {
      console.log(e);
    } finally {
      await onLoad();
    }

  };

  const excluir = async (distribuicaoLeadId) => {
    try {
      setIsLoading(true);

      await api.delete(url.DISTRIBUICAO_LEAD.DELETAR(distribuicaoLeadId)).then(() => {
        Notify('success', '', "Excluído com sucesso!");
      });
    } catch (e) {
      console.log(e);
    } finally {
      await onLoad();
    }

  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
    atualizaOrdem,
    excluir
  };
}
