import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useCidadesDropdown(estadoId, cidade, cidadeId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      const filterEstadoId = estadoId ? estadoId : null;
      const filterNome = cidade ? cidade : null;
      const filterCidadeId = cidadeId ? cidadeId : null;
      let filter = '';

      if (filterEstadoId && !params?.estadoId) {
        if (filter.length > 0) filter += `&estadoId=${filterEstadoId}`;
        else filter = `?estadoId=${filterEstadoId}`;
      }

      if (filterNome && !params?.cidade) {
        if (filter.length > 0) filter += `&cidade=${filterNome}`;
        else filter = `?cidade=${filterNome}`;
      }

      if (filterCidadeId && !params?.cidadeId) {
        if (filter.length > 0) filter += `&cidadeId=${filterCidadeId}`;
        else filter = `?cidadeId=${filterCidadeId}`;
      }

      if (params?.estadoId) {
        if (filter.length > 0) filter += `&estadoId=${params.estadoId}`;
        else filter = `?estadoId=${params.estadoId}`;
      }

      if (params?.cidade) {
        if (filter.length > 0) filter += `&cidade=${params.cidade}`;
        else filter = `?cidade=${params.cidade}`;
      }

      if (params?.cidadeId) {
        if (filter.length > 0) filter += `&cidadeId=${params.cidadeId}`;
        else filter = `?cidadeId=${params.cidadeId}`;
      }

      try {
        await api
          .get(`${url.DROPDOWN.CIDADE_DROPDOWN()}${filter}`)
          .then((res) => {
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [estadoId, cidade, cidadeId],
  );

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
