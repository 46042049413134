import { TiposImoveisFields } from '~/assets/data/imovel';

export default function useImovelValidate(tipoImovelId, finalidadeId) {
  const fieldIsRequired = (fieldId) => {
    return TiposImoveisFields.some(t =>
      t.id === tipoImovelId
      && t.finalidadeId === finalidadeId
      && t?.requiredFields?.some(r => r === fieldId))
  };

  const fieldIsHidden = (fieldId) => {
    return TiposImoveisFields.some(t =>
      t.id === tipoImovelId
      && t.finalidadeId === finalidadeId
      && t?.hiddenFields?.some(r => r === fieldId))
  };

  return {
    fieldIsRequired,
    fieldIsHidden,
  };
}
