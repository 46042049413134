import React, {Fragment, useState, useRef} from 'react';
import {Form, Select, Input, Tooltip, Badge, Table} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

import Page from '~/components/Page';
import usePerfilAcesso from '~/actions/PerfilAcesso/usePerfilAcesso';
import HeaderFiltros from '~/components/HeaderFiltro';
import history from '~/services/history';
import Button from '~/shared/Button';
import {Icon} from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';

const {Option} = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const text = <span>Ver perfil</span>;

export function rowEdit(row) {
  history.push({
    pathname: `/app/perfilacesso/manutencao/${MODO.EDITAR}`,
    state: {row},
  });
}

export function navToNovo() {
  history.push(`/app/perfilacesso/manutencao/${MODO.CADASTRAR}`);
}

function ConsultaPerfilAcesso() {
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [nomePerfilAcesso, setNomePerfilAcesso] = useState('');
  const [status, setStatus] = useState(null);

  const ref = useRef();
  const {isLoading, data, quantidade} = usePerfilAcesso(
    current,
    pageSize,
    nomePerfilAcesso,
    status,
  );

  // DEPOIS QUE O ENDPOINT ESTIVER PRONTO RETIRAR AS LINHAS ABAIXO
  data.pop();
  data.pop();
  data.pop();
  data.push({nome: 'Perfil Acesso 01', status: 1});
  data.push({nome: 'Perfil Acesso 02', status: 1});
  data.push({nome: 'Perfil Acesso 03', status: 3});
  // RETIRAR ATÉ AQUI

  // eslint-disable-next-line no-unused-vars
  async function onShowSizeChange(current, pageSize) {
    setPageSize(pageSize);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    const {nomePerfilAcesso, status} = ref.current.getFieldsValue();

    setNomePerfilAcesso(nomePerfilAcesso);
    setStatus(status);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Situação',
      dataIndex: '',
      key: 'status',
      responsive: ['lg'],
      render: (row) => (
        <Badge
          text={row.status === 1 ? 'Ativo' : 'Inativo'}
          color={row.status === 1 ? 'green' : 'red'}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          <Tooltip placement="topRight" title={text}>
            <i
              onClick={() => rowEdit(row)}
              className="ri-user-location-line linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </Tooltip>
        </div>
      ),
    },
  ];

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de Perfil Acesso</h5>
                <p className="category">Manutenção de Perfil Acesso</p>
                <HeaderFiltros navToNovo={() => navToNovo()} />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        ref={ref}
                        initialValues={{
                          status: '1',
                        }}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="3">
                            <Form.Item className="my-2" name="status">
                              <Select placeholder=" Situação">
                                <Option value="1">Ativo</Option>
                                <Option value="3">Inativo</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="nomeEmpresa">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <Button
                              className="my-2"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={{
                          current: current,
                          showSizeChanger: true,
                          defaultCurrent: 1,
                          total: quantidade,
                          responsive: true,
                          onChange: changePage,
                          onShowSizeChange: onShowSizeChange,
                        }}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaPerfilAcesso;

