/* eslint-disable no-unused-vars */
import React, {Fragment, useEffect, useState} from 'react';
import {Modal, Button, Icon} from 'semantic-ui-react';
import {Table, Tag, Switch, Tooltip} from 'antd';
import ModalAndamentoOcorrencia from '~/components/ModalOcorrencia/ModalAndamentoOcorrencia';
import {AuditOutlined} from '@ant-design/icons';
import useOcorrencia from '~/actions/Atendimento/useOcorrencia';
import api from '~/services/api';
import url from '~/infra/urls';
import fileWarning from '~/assets/img/file-warning.svg';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

function ModalOcorrencia({
  open,
  close,
  onChangeQtd,
  statusOcorrencia,
  atendente,
}) {
  const [row, setRow] = useState({});
  const [andamentoAnaliseOpen, setAndamentoAnaliseOpen] = useState(false);
  const [loadCheck, setLoadCheck] = useState(false);
  const [current, setCurrent] = useState(1);

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isCorretor = !!roles.find((x) => x.toUpperCase() === 'CORRETOR');
  const usuarioId = localStorage.getItem('icaseUsuarioId');

  const {
    isLoading: isLoadingData,
    data,
    quantidade,
    onLoad,
  } = useOcorrencia(
    current,
    10,
    isCorretor ? usuarioId : atendente || null,
    statusOcorrencia,
  );

  const definirEmAnalise = async (id) => {
    setLoadCheck(true);
    await api
      .put(url.ATENDIMENTO.DEFINIR_EM_ANALISE(id))
      .catch((err) => {
        const {erros} = err.response.data;
        if (erros) {
          for (const item in erros) {
          }
        }
      })
      .finally(() => {
        setLoadCheck(false);
        onLoad();
      });
  };

  function rowEdit(row) {
    setRow(row);
    setAndamentoAnaliseOpen(true);
  }

  useEffect(() => {
    onChangeQtd && onChangeQtd(quantidade);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantidade]);

  // useEffect(() => {
  //   if (open) {
  //     onLoad();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);

  const columnsCorretor = [
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
      responsive: ['lg'],
      render: (cliente) => <span>{cliente.nome}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      responsive: ['lg'],
      render: (row) => (
        <div key={row.index}>
          {row?.ocorrencia?.status === 1 && row.emAnalise && (
            <Tag color="blue">Em Conferência</Tag>
          )}
          {row?.ocorrencia?.status === 1 && !row.emAnalise && (
            <Tag color="gold">Aguardando Conferência</Tag>
          )}
          {row?.ocorrencia?.status === 3 && (
            <Tag color="green">Sem Pendência</Tag>
          )}
          {row?.ocorrencia?.status === 2 && (
            <Tag color="red">Com Pendência</Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Corretor',
      dataIndex: 'corretor',
      key: 'corretor',
      responsive: ['lg'],
      render: (corretor) => <span>{corretor.nome}</span>,
    },
    {
      title: 'Data/Hora envio',
      key: 'dataStatus',
      render: (row) => (
        <span>
          {moment(row?.ocorrencia?.dataStatus).format('DD/MM/YYYY - HH:mm')}
        </span>
      ),
    },
    {
      title: 'Ações',
      key: 'acoes',
      className: 'column-action',
      render: (row) => (
        <div key={row.index}>
          {row?.ocorrencia?.status === 2 && (
            <Tooltip
              title="Resultado Conferência"
              getPopupContainer={(node) => node.parentNode}>
              <i
                onClick={() => rowEdit(row)}
                className="linkTable"
                style={{fontSize: '25px'}}>
                <AuditOutlined />
              </i>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const columnsMaster = [
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
      responsive: ['lg'],
      render: (cliente) => <span>{cliente.nome}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      responsive: ['lg'],
      render: (row) => (
        <div key={row.index}>
          {row?.ocorrencia?.status === 1 && row.emAnalise && (
            <Tag color="blue">Em Conferência</Tag>
          )}
          {row?.ocorrencia?.status === 1 && !row.emAnalise && (
            <Tag color="gold">Aguardando Conferência</Tag>
          )}
          {row?.ocorrencia?.status === 3 && (
            <Tag color="green">Sem Pendência</Tag>
          )}
          {row?.ocorrencia?.status === 2 && (
            <Tag color="red">Com Pendência</Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Corretor',
      dataIndex: 'corretor',
      key: 'corretor',
      responsive: ['lg'],
      render: (corretor) => <span>{corretor.nome}</span>,
    },
    {
      title: 'Data/Hora envio',
      key: 'dataStatus',
      render: (row) => (
        <span>
          {moment(row?.ocorrencia?.dataStatus).format('DD/MM/YYYY - HH:mm')}
        </span>
      ),
    },
    {
      title: 'Em Conferência?',
      key: 'emAnalise',
      render: (row) => (
        <div key={row.index}>
          {row.emAnalise && row.idAtendenteAtual !== usuarioId ? (
            <span>{row.nomeAtendenteAtual || 'Outro Usuário'}</span>
          ) : (
            <Switch
              loading={loadCheck}
              checked={row.emAnalise}
              onChange={async () => await definirEmAnalise(row.id)}
              checkedChildren="Sim"
              unCheckedChildren="Não"
              size="small"
            />
          )}
        </div>
      ),
    },
    {
      title: 'Ações',
      key: 'acoes',
      className: 'column-action',
      render: (row) => (
        <div key={row.index}>
          <Tooltip
            title="Realizar Conferência"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowEdit(row)}
              className="linkTable"
              style={{fontSize: '25px'}}>
              <AuditOutlined />
            </i>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="large"
        dimmer="blurring"
        open={open}
        onClose={() => close()}>
        <Modal.Header>
          <div
            style={{display: 'flex', alignItems: 'center'}}
            className="symbol symbol-50 symbol-light">
            <img
              className="mr-3 h-50 align-self-end"
              src={fileWarning}
              alt="..."
            />
            <h6 className="title">Ocorrência</h6>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Table
            loading={isLoadingData}
            rowClassName={(row) =>
              row.emAnalise ? 'table-row-blue' : 'table-row-retorno'
            }
            rowKey={'id'}
            columns={isCorretor ? columnsCorretor : columnsMaster}
            dataSource={data}
            pagination={{
              current: current,
              showSizeChanger: false,
              defaultCurrent: 1,
              total: quantidade,
              responsive: true,
              onChange: (page) => setCurrent(page),
            }}
          />
          <ModalAndamentoOcorrencia
            open={andamentoAnaliseOpen}
            close={() => {
              onLoad();
              setAndamentoAnaliseOpen(false);
            }}
            row={row}
            isCorretor={isCorretor}
            statusOcorrencia={statusOcorrencia}
          />
        </Modal.Content>
        <Modal.Actions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Total Ocorrências: {quantidade}</span>
          <Button icon labelPosition="left" onClick={() => onLoad()}>
            <Icon name="sync alternate" />
            Atualizar
          </Button>
          <Button icon labelPosition="left" onClick={() => close()}>
            <Icon name="times" />
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}

export default ModalOcorrencia;

