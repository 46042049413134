import React, { Fragment, useEffect } from 'react'
import moment from 'moment';
import 'moment/locale/pt-br';
import { Icon } from 'semantic-ui-react';

import Button from '~/shared/Button';
import useLocalStorage from '~/hooks/localstorage/useLocalStorage';
import ModalContainer from './ModalContainer';
import useOfertaAtivaCorretorStore from './useOfertaAtivaCorretorStore';
import useGetOfertasAtivasDisponiveis from '~/actions/OfertaAtiva/useGetOfertasAtivasDisponiveis';

moment.locale('pt-BR');

/* Funções */

function OfertaAtivaCorretorWidget() {
  const { ofertasAtivasDisponiveis, isLoading, error, mutate } = useGetOfertasAtivasDisponiveis();
  const [ofertaAtivaSelecionada, setOfertaAtivaSelecionada] = useLocalStorage('ofertaAtivaSelecionada');
  const { verOfertaAtiva, listaOfertasAtivas, closeModal } = useOfertaAtivaCorretorStore();

  /* Sync the changes on the store with the local storage */
  useEffect(() => useOfertaAtivaCorretorStore.subscribe((state) => {
    if (state.ofertaAtivaSelecionada === undefined) {
      setOfertaAtivaSelecionada(undefined);
    } else {
      setOfertaAtivaSelecionada(state.ofertaAtivaSelecionada);
    }

    if (error) {
      state.closeModal();
    }

  }), [setOfertaAtivaSelecionada, error]);

  return (
    <Fragment>
      {(ofertasAtivasDisponiveis && ofertasAtivasDisponiveis.length > 0) && (
        <Button
          icon
          labelPosition="left"
          size="tiny"
          fluid
          color={'green'}
          disabled={isLoading}
          onClick={() => {
            if (isLoading) return;
            if (!ofertasAtivasDisponiveis || ofertasAtivasDisponiveis.length === 0) {
              closeModal();
              mutate();
              return;
            }

            if (ofertasAtivasDisponiveis.find((item) => item.id === ofertaAtivaSelecionada?.id)) {
              verOfertaAtiva(ofertaAtivaSelecionada);
              return;
            }

            listaOfertasAtivas();
          }}
        >
          {isLoading ? (<Icon name="spinner" loading />) : (<Icon name="check square" />)}
          {isLoading ? 'Carregando Ofertas...' : 'Ofertas Ativas'}
        </Button>
      )}

      <ModalContainer
        onReload={() => {
          mutate();
        }}
      />
    </Fragment>
  );
}

export default OfertaAtivaCorretorWidget;
