export const sizeScreenRules = {
  small: '(max-width: 766px)',
  large: '(min-width: 768px) and (max-width: 1280px)',
  xLarge: '(min-width: 1280px) and (max-width: 1400px)',
  xxLarge: '(min-width: 1400px)',
  xxxLarge: '(min-width: 1500px)',
};

export const device = {
  small: '@media only screen and (max-width: 766px)',
  medium: '@media only screen and (min-width: 768px) and (max-width: 992px)',
  large: '@media only screen and (min-width: 992px) and (max-width: 1280px)',
  xLarge: '@media only screen and (min-width: 1280px) and (max-width: 1400px)',
  xxLarge: '@media only screen and (min-width: 1400px)',
  xxxLarge: '@media only screen and (min-width: 1500px)',
};

