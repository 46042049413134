import React, {useState} from 'react';
import usePessoaDropdown from '~/actions/DropDown/usePessoa';

import {Button, Select} from 'antd';
import {Icon} from 'semantic-ui-react';

import ModalGenerico from '~/components/ModalGenerico';
import Pessoa from '~/views/private/Cadastros/Pessoa/Manutencao';

const {Option} = Select;

export default function SelectCliente(props) {
  const {data,onLoad: onLoadPessoas, isLoading: isLoadindPessoa} = usePessoaDropdown();
  const [openModalPessoa, setOpenModalPessoa] = useState(false);
  const [modoPessoa, setModoPessoa] = useState('cadastrar');
  

  function handleNewClient(event) {
    if (event) event.preventDefault();
    setModoPessoa('cadastrar');
    setOpenModalPessoa(true);
  }

  async function handleClient(value) {
    if (props.handleSelectClient) {
      props.handleSelectClient(value);
    }
  }

  async function onSearchCliente(value) {
    await onLoadPessoas({ nome: value, tipo: null });
  }

  const handleFilterOption = (input, option) =>
  option?.children && option.children
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .indexOf(
        input
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      ) >= 0;

  const renderOptions = isLoadindPessoa ? <span>Carregando...</span> : data?.map((item) => (
     <Option key={item.id} value={item.id}>
      {item.nome}
    </Option>
  ));

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'end',
          columnGap: 12,
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <p className="heading-small text-muted mb-2">
            <small>Selecione um cliente:</small>
          </p>
          <Select
            style={{
              width: '100%',
            }}
            size="middle"
            placeholder=" Cliente"
            onChange={handleClient}
            allowClear
            showSearch
            optionFilterProp="children"
            onSearch={onSearchCliente}
            filterOption={handleFilterOption}
            >
            {renderOptions}
          </Select>
        </div>
        <Button
          onClick={handleNewClient}
          icon={<Icon name="plus" />}
          labelPosition="left"
          size="tiny"
          fluid
          type="primary"
          style={{
            backgroundColor: '#1D1F8C',
            borderColor: 'transparent',
          }}>
          Novo Cliente
        </Button>
      </div>
      <ModalGenerico
        open={openModalPessoa}
        close={() => setOpenModalPessoa(false)}
        title={'Pessoa'}>
        <Pessoa
          match={{params: {modo: modoPessoa}}}
          close={() => setOpenModalPessoa(false)}
          isAtendimento
        />
      </ModalGenerico>
    </>
  );
}

