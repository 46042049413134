import React, { useState, memo } from 'react';
import Carousel from 'react-grid-carousel';
import { CardImg } from 'reactstrap';
import imgImovel from '~/assets/img/sala.jpg';
import { Modal } from 'antd';

function CarouselModal({ imagensModal, handleFecharModal, isModalOpen }) {
  return (
    <>
      <Modal
        centered
        open={isModalOpen}
        width={800}
        onCancel={handleFecharModal}
        footer={null}>
        <Carousel cols={1} rows={1} style={{ position: 'absolute' }} loop>
          {imagensModal.map((f, i) => (
            <Carousel.Item key={i}>
              <img
                width="100%"
                height="auto"
                src={f.arquivo ? f.arquivo : imgImovel}
                alt=""
                style={{ 'borderRadius': '4px' }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Carousel cols={4} rows={1} style={{ position: 'absolute' }} loop>
          {imagensModal?.map((f, i) => (
            <Carousel.Item key={i}>
              <img
                width="194px"
                height="124px"
                style={{ 'borderRadius': '4px' }}
                src={f.arquivo ? f.arquivo : imgImovel}
                alt=""
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal>
    </>
  );
}

function CarouselImagem({ dataImages }) {
  const [fileList] = useState(dataImages || []);
  const [exibirModal, setExibirModal] = useState(false);
  const [imagensModal, setImagensModal] = useState([]);

  const exibirCarouselModal = (index) => {
    if (index && fileList.length) {
      let selectedImage = fileList[index];
      let newImages = fileList.filter((f) => f.id !== selectedImage.id);
      newImages.unshift(selectedImage);
      setImagensModal(newImages);
    } else {
      setImagensModal(fileList);
    }
    setExibirModal(true);
  };

  const handleFecharModal = () => {
    setImagensModal([]);
    setExibirModal(false);
  };

  const isEmpty = fileList.length === 0;
  return (
    <>
      {!isEmpty ? (
        <>
          <Carousel cols={5} rows={1} gap="5px" loop>
            {fileList?.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  width="298px"
                  height="190px"
                  src={item.arquivo ? item.arquivo : imgImovel}
                  className="teste"
                  alt=""
                  style={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => exibirCarouselModal(index)}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          {exibirModal && (
            <CarouselModal
              imagensModal={imagensModal}
              handleFecharModal={handleFecharModal}
              isModalOpen={exibirModal}
              fileList={fileList}
            />
          )}
        </>
      ) : (
        <>
          <CardImg variant="top" src={imgImovel} />
          <br />
        </>
      )}
    </>
  );
}
export default memo(CarouselImagem);
