import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Select, Divider } from 'antd';
import { Icon, Transition } from 'semantic-ui-react';
import { mask } from 'remask';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';

import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CircularProgress from '~/components/HomeLoading';

import url from '~/infra/urls';
import enumPessoaTipo from '~/infra/resources/strings/enums/tipoPessoa';
import coutriesDDI from '~/infra/resources/strings/enums/countries';

import FormFisica from '~/components/FormFisica';
import FormJuridica from '~/components/FormJuridica';

import useProfissoes from '~/actions/Profissoes/useProfissoes';
import useEstado from '~/actions/DropDown/useEstado';
import useFormacao from '~/actions/Formacoes/useFormacoes';
import useCidadesDropdown from '~/actions/DropDown/useCidadesDropdown';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Pessoa(props) {
  const [visible, setVisible] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({
    contato: {
      celularDDI: 'BR+55',
      telefoneDDI: 'BR+55',
    }
  });
  const [identificador, setIdentificador] = useState();
  const [loadPage, setloadPage] = useState(true);
  const [pessoaId, setPessoaId] = useState(null);
  const [pessoaFisicaId, setPessoaFisicaId] = useState();
  const [pessoaJuridicaId, setPessoaJuridicaId] = useState();
  const [NovoConjugeId, setNovoConjugeId] = useState(undefined);
  const [tipo, setTipo] = useState();
  const [fromAtendimento, setFromAtendimento] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const { data: Profissoes, onLoad: onLoadProfissoes } = useProfissoes();
  const { data: estados } = useEstado();
  const { data: formacoes } = useFormacao();
  const { data: dataCidadesDropdown, onLoad: onLoadCidadesDropdown } =
    useCidadesDropdown();

  const [form] = Form.useForm();

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isCorretor = !!roles.find((x) => x.toUpperCase() === 'CORRETOR');
  const isMaster = !!roles.find((x) => x.toUpperCase() === 'MASTER');

  async function onChangeInitialValues(dados) {
    const dataCelular = dados?.telefones.find(telefone => telefone.tipo === 2);
    const dataTelefone = dados?.telefones.find(telefone => telefone.tipo === 1);

    const celularDDI = coutriesDDI.find(item => item.iso === dataCelular?.ddiIso);
    const telefoneDDI = coutriesDDI.find(item => item.iso === dataTelefone?.ddiIso);

    const formData = {
      isUpdate: dados.id ? true : false,
      CPF: dados?.pessoaFisica?.cpf
        ? mask(dados?.pessoaFisica?.cpf, [pattern.cpf])
        : undefined,
      cnpj: dados?.pessoaJuridica?.cnpj
        ? mask(dados?.pessoaJuridica?.cnpj, [pattern.cnpj])
        : undefined,
      nome: dados?.nome || null,
      tipo: String(dados.tipo),
      nascimento: dados?.pessoaFisica?.nascimento
        ? moment(dados?.pessoaFisica?.nascimento.toString())
        : null,
      nacionalidadeId: dados?.pessoaFisica?.nacionalidadeId || undefined,
      naturalidadeId: dados?.pessoaFisica?.naturalidadeId
        ? String(dados?.pessoaFisica?.naturalidadeId)
        : undefined,
      naturalidadeEstado: dados?.pessoaFisica?.cidade?.estadoId,
      naturalidade: dados?.pessoaFisica?.cidade?.nome,
      rgNumero: dados?.pessoaFisica?.rgNumero || undefined,
      rgOrgaoExpedidor: dados?.pessoaFisica?.rgOrgaoExpedidor || null,
      rgUf: dados?.pessoaFisica?.rgUf || null,
      rgEmissao: dados?.pessoaFisica?.rgEmissao
        ? moment(dados?.pessoaFisica?.rgEmissao.toString())
        : null,
      estadoCivil: dados?.pessoaFisica?.estadoCivil || undefined,
      formacaoId: dados?.pessoaFisica?.formacaoId || undefined,
      profissaoId: dados?.pessoaFisica?.profissaoId || undefined,
      conjugeId: dados?.pessoaFisica?.conjugeId || undefined,
      masculino: dados?.pessoaFisica?.sexo || undefined,
      email: dados?.email || null,
      mae: dados?.pessoaFisica?.mae || null,
      pai: dados?.pessoaFisica?.pai || null,
      contato: {
        celular: dataCelular?.numero
          ? dataCelular?.ddi === 55 || dataCelular?.ddi === null
            ? mask(dataCelular.numero, [pattern.celular])
            : dataCelular.numero
          : null,
        celularDDI: celularDDI
          ? `${celularDDI?.iso}${celularDDI?.code}`
          : 'BR+55',
        telefone: dataTelefone?.numero
          ? dataTelefone?.ddi === 55 || dataTelefone?.ddi === null
            ? mask(dataTelefone.numero, [pattern.telefone])
            : dataTelefone.numero
          : null,
        telefoneDDI: telefoneDDI
          ? `${telefoneDDI?.iso}${telefoneDDI?.code}`
          : 'BR+55',
      },
      observacao: dados?.observacao || null,
      estado: dados?.enderecoLogradouro?.bairro?.cidade?.estadoId,
      cidadeId: dados?.enderecoLogradouro?.bairro?.cidadeId
        ? dados?.enderecoLogradouro?.bairro?.cidadeId.toString()
        : undefined,
      bairroId: dados?.enderecoLogradouro?.bairroId
        ? dados?.enderecoLogradouro?.bairroId.toString()
        : undefined,
      enderecoLogradouroId: dados?.enderecoLogradouroId
        ? dados?.enderecoLogradouroId.toString()
        : undefined,
      enderecoLogradouro: dados?.enderecoLogradouro?.nome || null,
      cidade:
        String(dados?.enderecoLogradouro?.bairro?.cidade?.nome.toUpperCase()) ||
        null,
      bairro: dados?.enderecoLogradouro?.bairro?.nome || null,
      tipoLogradouro:
        dados?.enderecoLogradouro?.tipoLogradouro?.descricao || null,
      cep: dados.cep ? mask(dados.cep, [pattern.cep]) : null,
      pais: dados?.enderecoLogradouro?.bairro?.cidade?.estado?.pais?.nome || '',
      enderecoNumero: dados?.enderecoNumero || null,
      loteApto: dados?.loteApto || null,
      quadraBloco: dados?.quadraBloco || null,
      enderecoComplemento: dados?.enderecoComplemento || null,
      condominioId: dados?.condominioId || undefined,
      captadorId: dados?.captadorId || undefined,
      timeFavoritoId: dados?.pessoaFisica?.timeFavoritoId || undefined,
      comidaFavoritaId: dados?.pessoaFisica?.comidaFavoritaId || undefined,
    };

    setInitialValues(formData);
  }

  async function fetch(id) {
    await api
      .get(url.PESSOA.CONSULTA_ID(id))
      .then((res) => {
        const editData = res.data;

        setModo(MODO.EDITAR);
        setPessoaId(id);
        setTipo(Number(editData.tipo));

        setNovoConjugeId(editData?.pessoaFisica?.conjugeId);
        onChangeInitialValues(editData);

        if (Number(editData.tipo) === 1)
          setPessoaFisicaId(editData.pessoaFisicaId);
        else setPessoaJuridicaId(editData.pessoaJuridicaId);

        setVisible(true);
      })
      .catch((err) => {
        Notify(
          'error',
          pt.comum.atencao,
          'Ocorreu um erro ao carregar os dados da pessoa, tente novamente!',
        );
      });
  }

  useEffect(() => {
    if (props.match.params) {
      async function fetchDados() {
        const params = {
          current: 1,
          pageSize: 9999,
        };
        onLoadProfissoes(params);

        if (props.match.params.modo === MODO.EDITAR) {
          await fetch(props.location.state.row.id);
          const identificador = props?.location?.state?.identificador;
          setIdentificador(identificador);

          setTimeout(() => {
            setloadPage(false);
            setIsLoading(false);
          }, 2000);
        } else {
          const identificador = props?.location?.state?.identificador;
          const isAtendimento = props?.isAtendimento ? true : false;

          if(props?.match?.params?.tipoPessoa === 'conjuge')
            onChangeTipo(1);

          const formData = {
            contato: {
              celularDDI: 'BR+55',
              telefoneDDI: 'BR+55',
              tipo: (props?.match?.params?.tipoPessoa === 'conjuge') && 1
            },
          };

          setInitialValues(formData);
          setFromAtendimento(isAtendimento);
          setIdentificador(identificador);
          setModo(MODO.CADASTRAR);
          setloadPage(false);
          setIsLoading(false);
        }
      }

      fetchDados();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function onChangeTipo(tipo) {
    setTipo(Number(tipo));
    setVisible(true);
  }

  function voltar() {
    if (props?.close) {
      props.close();
    } else if (identificador) {
      history.push(`/app/pessoa/${identificador}`);
    } else {
      history.push('/app/pessoa');
    }
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (dados.estadoCivil === 1 && !NovoConjugeId) {
          setIsLoading(false);
          Notify('error', pt.comum.atencao, 'Favor selecionar o conjuge');
        } else {
          const splitedCelularCodeISO = dados?.contato?.celularDDI?.split('+');
          const splitedTelefoneCodeISO = dados?.contato?.telefoneDDI?.split('+');

          let celularDDI = undefined;
          let telefoneDDI = undefined;
          if (splitedCelularCodeISO)
            celularDDI = coutriesDDI.find(item => item.iso === splitedCelularCodeISO[0]);

          if (splitedTelefoneCodeISO)
            telefoneDDI = coutriesDDI.find(item => item.iso === splitedTelefoneCodeISO[0]);

          if (modo === MODO.CADASTRAR) {
            if (tipo === 1) {
              const payload = {
                tipo: 1, //Física
                pessoaFisicaId: pessoaId,
                pessoaId,
                userId: '',
                conjugeId: NovoConjugeId ? NovoConjugeId : null,
                nome: dados.nome,
                telefone: dados?.contato?.telefone
                  ? dados?.contato?.telefone.replace(/\D/g, '')
                  : dados?.contato?.telefone,
                telefoneDdi: telefoneDDI?.code
                  ? Number(telefoneDDI?.code.replace('+', ''))
                  : undefined,
                telefoneDdiIso: telefoneDDI?.iso,
                celular: dados?.contato?.celular
                  ? dados?.contato.celular.replace(/\D/g, '')
                  : dados?.contato.celular,
                celularDdi: celularDDI?.code
                  ? Number(celularDDI?.code.replace('+', ''))
                  : undefined,
                celularDdiIso: celularDDI?.iso,
                email: dados.email,
                observacao: dados.observacao,
                cep: dados.cep ? dados.cep.replace(/\D/g, '') : dados.cep,
                enderecoLogradouroId: dados.enderecoLogradouroId,
                enderecoComplemento: dados.enderecoComplemento,
                enderecoNumero: dados.enderecoNumero,
                loteApto: dados.loteApto,
                quadraBloco: dados.quadraBloco,
                condominioId: dados.condominioId,
                profissaoId: dados.profissaoId,
                rgNumero: dados.rgNumero,
                rgOrgaoExpedidor: dados.rgOrgaoExpedidor,
                rgUf: dados.rgUf,
                rgEmissao: dados.rgEmissao,
                formacaoId: dados.formacaoId,
                naturalidadeId: dados.naturalidadeId,
                nacionalidadeId: dados.nacionalidadeId,
                estadoCivil: dados.estadoCivil,
                cpf: dados.CPF ? dados.CPF.replace(/\D/g, '') : dados.CPF,
                nascimento: dados.nascimento,
                masculino: dados.masculino,
                mae: dados.mae,
                pai: dados.pai,
                timeFavoritoId: dados?.timeFavoritoId,
                comidaFavoritaId: dados?.comidaFavoritaId,
                ignoreDuplicate: props?.match?.params?.ignoreDuplicate || false,
              };

              if (fromAtendimento && isCorretor) {
                payload.captadorId = localStorage.getItem('icaseUsuarioId');
              } else if (isMaster) {
                payload.captadorId = dados.captadorId;
              }

              await api
                .post(url.PESSOA.CADASTRAR_FISICA(), payload)
                .then((res) => {
                  setIsLoading(false);

                  if (props?.close) {
                    localStorage.setItem('icasePessoaId', res.data?.pessoaId);
                    localStorage.setItem('icasePessoaDesc', res.data?.pessoa?.nome);
                    voltar();
                  } else {
                    setOpenModalConfirm(true);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  const { error, errors } = err.response.data;
                  if (error) {
                    for (const item in error) {
                      Notify('error', pt.comum.atencao, error[item]);
                    }
                  }

                  if (errors) {
                    for (const item in errors) {
                      Notify('error', pt.comum.atencao, errors[item]);
                    }
                  }
                });
            } else {
              const payload = {
                tipo: 2, //Jurídica
                pessoaJuridicaId: '',
                nome: dados.nome,
                telefone: dados?.contato?.telefone
                  ? dados?.contato?.telefone.replace(/\D/g, '')
                  : dados?.contato?.telefone,
                telefoneDdi: telefoneDDI?.code
                  ? Number(telefoneDDI?.code.replace('+', ''))
                  : undefined,
                telefoneDdiIso: telefoneDDI?.iso,
                celular: dados?.contato?.celular
                  ? dados?.contato.celular.replace(/\D/g, '')
                  : dados?.contato.celular,
                celularDdi: celularDDI?.code
                  ? Number(celularDDI?.code.replace('+', ''))
                  : undefined,
                celularDdiIso: celularDDI?.iso,
                email: dados.email,
                observacao: dados.observacao,
                cep: dados.cep ? dados.cep.replace(/\D/g, '') : dados.cep,
                enderecoLogradouroId: dados.enderecoLogradouroId,
                enderecoComplemento: dados.enderecoComplemento,
                enderecoNumero: dados.numero,
                razao: dados.razao,
                areaAtuacaoId: dados.areaAtuacaoId,
                ocupacaoId: dados.ocupacaoId,
                tipoEstabelecimentoId: dados.tipoEstabelecimentoId,
                cnpj: dados.cnpj ? dados.cnpj.replace(/\D/g, '') : dados.cnpj,
                fundacao: dados.fundacao,
                ignoreDuplicate: props?.match?.params?.ignoreDuplicate || false,
              };

              if (fromAtendimento && isCorretor) {
                payload.captadorId = localStorage.getItem('icaseUsuarioId');
              } else if (isMaster) {
                payload.captadorId = dados.captadorId;
              }

              await api
                .post(url.PESSOA.CADASTRAR_JURIDICA(), payload)
                .then((res) => {
                  setIsLoading(false);

                  if (props?.close) {
                    localStorage.setItem('icasePessoaId', res.data?.pessoaId);
                    localStorage.setItem('icasePessoaDesc', res.data?.pessoa?.nome);
                    voltar();
                  } else {
                    setOpenModalConfirm(true);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  const { error, errors } = err.response.data;
                  if (error) {
                    for (const item in error) {
                      Notify('error', pt.comum.atencao, error[item]);
                    }
                  }

                  if (errors) {
                    for (const item in errors) {
                      Notify('error', pt.comum.atencao, errors[item]);
                    }
                  }
                });
            }
          } else if (modo === MODO.EDITAR) {
            if (tipo === 1) {
              if (dados.estadoCivil === 1 && !NovoConjugeId) {
                setIsLoading(false);
                Notify('error', pt.comum.atencao, 'Favor selecionar o conjuge');
              } else {
                const payload = {
                  pessoaId,
                  tipo: 1, //Física
                  pessoaFisicaId,
                  userId: '',
                  conjugeId: NovoConjugeId ? NovoConjugeId : null,
                  nome: dados.nome,
                  telefone: dados?.contato?.telefone
                    ? dados?.contato?.telefone.replace(/\D/g, '')
                    : dados?.contato?.telefone,
                  telefoneDdi: telefoneDDI?.code
                    ? Number(telefoneDDI?.code.replace('+', ''))
                    : undefined,
                  telefoneDdiIso: telefoneDDI?.iso,
                  celular: dados?.contato?.celular
                    ? dados?.contato.celular.replace(/\D/g, '')
                    : dados?.contato.celular,
                  celularDdi: celularDDI?.code
                    ? Number(celularDDI?.code.replace('+', ''))
                    : undefined,
                  celularDdiIso: celularDDI?.iso,
                  email: dados.email,
                  observacao: dados.observacao,
                  cep: dados.cep ? dados.cep.replace(/\D/g, '') : dados.cep,
                  enderecoLogradouroId: dados.enderecoLogradouroId,
                  enderecoComplemento: dados.enderecoComplemento,
                  enderecoNumero: dados.enderecoNumero,
                  loteApto: dados.loteApto,
                  quadraBloco: dados.quadraBloco,
                  condominioId: dados.condominioId,
                  profissaoId: dados.profissaoId,
                  rgNumero: dados.rgNumero,
                  rgOrgaoExpedidor: dados.rgOrgaoExpedidor,
                  rgUf: dados.rgUf,
                  rgEmissao: dados.rgEmissao,
                  formacaoId: dados.formacaoId,
                  naturalidadeId: dados.naturalidadeId,
                  nacionalidadeId: dados.nacionalidadeId,
                  estadoCivil: dados.estadoCivil,
                  cpf: dados.CPF ? dados.CPF.replace(/\D/g, '') : dados.CPF,
                  nascimento: dados.nascimento,
                  masculino: dados.masculino,
                  mae: dados.mae,
                  pai: dados.pai,
                  captadorId: dados?.captadorId || undefined,
                  timeFavoritoId: dados?.timeFavoritoId,
                  comidaFavoritaId: dados?.comidaFavoritaId,
                };

                await api
                  .put(url.PESSOA.EDITAR_FISICA(), payload)
                  .then((res) => {
                    setIsLoading(false);

                    if (props?.close) {
                      localStorage.setItem('icasePessoaId', res.data?.pessoaId);
                      localStorage.setItem('icasePessoaDesc', res.data?.pessoa?.nome);
                      voltar();
                    } else {
                      setOpenModalConfirm(true);
                    }
                  })
                  .catch((err) => {
                    setIsLoading(false);
                    const { error, errors } = err.response.data;

                    if (error) {
                      for (const item in error) {
                        Notify('error', pt.comum.atencao, error[item]);
                      }
                    }

                    if (errors) {
                      for (const item in errors) {
                        Notify('error', pt.comum.atencao, errors[item]);
                      }
                    }
                  });
              }
            } else {
              const payload = {
                pessoaId,
                tipo: 2, //Jurídica
                pessoaJuridicaId,
                nome: dados.nome,
                telefone: dados?.contato?.telefone
                  ? dados?.contato?.telefone.replace(/\D/g, '')
                  : dados?.contato?.telefone,
                telefoneDdi: telefoneDDI?.code
                  ? Number(telefoneDDI?.code.replace('+', ''))
                  : undefined,
                telefoneDdiIso: telefoneDDI?.iso,
                celular: dados?.contato?.celular
                  ? dados?.contato.celular.replace(/\D/g, '')
                  : dados?.contato.celular,
                celularDdi: celularDDI?.code
                  ? Number(celularDDI?.code.replace('+', ''))
                  : undefined,
                celularDdiIso: celularDDI?.iso,
                email: dados.email,
                observacao: dados.observacao,
                cep: dados.cep ? dados.cep.replace(/\D/g, '') : dados.cep,
                enderecoLogradouroId: dados.enderecoLogradouroId,
                enderecoComplemento: dados.enderecoComplemento,
                enderecoNumero: dados.numero,
                razao: dados.razao,
                areaAtuacaoId: dados.areaAtuacaoId,
                ocupacaoId: dados.ocupacaoId,
                tipoEstabelecimentoId: dados.tipoEstabelecimentoId,
                cnpj: dados.cnpj ? dados.cnpj.replace(/\D/g, '') : dados.cnpj,
                fundacao: dados.fundacao,
                captadorId: dados?.captadorId || undefined,
              };

              await api
                .put(url.PESSOA.EDITAR_JURIDICA(), payload)
                .then((res) => {
                  setIsLoading(false);

                  if (props?.close) {
                    localStorage.setItem('icasePessoaId', res.data?.pessoaId);
                    localStorage.setItem('icasePessoaDesc', res.data?.pessoa?.nome);
                    voltar();
                  } else {
                    setOpenModalConfirm(true);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  const { errors } = err.response.data;
                  if (errors) {
                    for (const item in errors) {
                      Notify('error', pt.comum.atencao, errors[item]);
                    }
                  }
                });
            }
          }
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  const viewClient = props?.isAtendimento && modo === MODO.EDITAR;

  return (
    <Fragment>
      {loadPage && <CircularProgress />}
      <div>{loadPage && <SkeletonTableBasic />}</div>
      {!loadPage && (
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? `Cadastrar ${props?.match?.params?.tipoPessoa || 'pessoa'}`
                    : `Atualizar ${props?.match?.params?.tipoPessoa || 'pessoa'}`}
                </h5>
                <p className="category">Manutenção de pessoa</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="mb-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row className="px-3">
                          <Col xs="12" md="3">
                            <Form.Item className="my-2" name="tipo">
                              <p className="heading-small text-muted mb-2">
                                <small>Tipo de Pessoa</small>
                              </p>
                              <Select
                                defaultValue={
                                  tipo ? tipo.toString() : undefined
                                }
                                placeholder=" Tipo"
                                disabled={viewClient}
                                onChange={onChangeTipo}>
                                {enumPessoaTipo.map((item) => (
                                  <Option key={item.id}>{item.nome}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mt-1">
                            <Transition
                              visible={visible}
                              animation="scale"
                              duration={500}>
                              {tipo === 1 ? (
                                <Fragment>
                                  <Row className="mx-2">
                                    <Col xs="12">
                                      <Divider />
                                    </Col>
                                  </Row>
                                  <div className="p-2">
                                    <FormFisica
                                      form={form}
                                      profissoes={Profissoes}
                                      onLoadProfissoes={onLoadProfissoes}
                                      estados={estados}
                                      formacoes={formacoes}
                                      dataCidadesDropdown={dataCidadesDropdown}
                                      onLoadCidadesDropdown={
                                        onLoadCidadesDropdown
                                      }
                                      setNovoConjugeId={(value) =>
                                        setNovoConjugeId(value)
                                      }
                                      viewClient={viewClient}
                                      showAdvanceData={props?.showAdvanceData}
                                      tipoPessoa={props?.match?.params?.tipoPessoa}
                                    />
                                  </div>
                                </Fragment>
                              ) : (
                                <div className="py-2 px-4">
                                  <FormJuridica
                                    form={form}
                                    profissoes={Profissoes}
                                    estados={estados}
                                    viewClient={viewClient}
                                  />
                                </div>
                              )}
                            </Transition>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-2 px-3">
                        {!viewClient && (
                          <Col xs="12" md="3">
                            <Button
                              onClick={onSave}
                              icon
                              labelPosition="left"
                              size="medium"
                              fluid
                              className="mt-1"
                              color={visible ? 'violet' : ''}
                              disabled={!visible}
                              isloading={loading.toString()}
                              loadtext={
                                modo === MODO.CADASTRAR
                                  ? 'Cadastrando...'
                                  : 'Atualizando...'
                              }>
                              <Icon name="check" />
                              {modo === MODO.CADASTRAR
                                ? 'Cadastrar'
                                : 'Atualizar'}
                            </Button>
                          </Col>
                        )}
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-1"
                            onClick={() => voltar()}>
                            <Icon name="reply" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={voltar}
              message={
                modo === MODO.CADASTRAR
                  ? 'Cadastrado com sucesso'
                  : 'Alterações salvas'
              }
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

export default Pessoa;

