import React, {useState, useEffect, Fragment} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Form, Input} from 'antd';
import {Icon} from 'semantic-ui-react';

import Page from '~/components/Page';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import history from '~/services/history';
import api from '~/services/api';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Acoes(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [acaoId, setAcaoId] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const editData = props.location.state.row;

        setInitialValues(editData);
        setModo(MODO.EDITAR);
        setAcaoId(props.location.state.row.id);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function voltar() {
    history.push('/app/acoes');
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            descricao: dados.descricao,
          };

          await api
            .post(url.ACOES.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);

              Notify('success', '', pt.geral.cadastro_com_sucesso);
              voltar();
            })
            .catch((err) => {
              setIsLoading(false);
              const {error} = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: acaoId,
            descricao: dados.descricao,
          };

          await api
            .put(url.ACOES.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);

              Notify('success', '', pt.geral.atualizado_com_sucesso);
              history.push('/app/acoes');
            })
            .catch((err) => {
              setIsLoading(false);
              const {error} = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? 'Cadastrar Ação'
                    : 'Atualizar Ação'}
                </h5>
                <p className="category">Manutenção de Ações</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="12">
                            <p className="heading-small text-muted mb-2">
                              <small>Descrição</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="descricao"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a descrição!',
                                },
                              ]}>
                              <Input placeholder=" Descrição" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-4">
                        <Col xs="12" md="3">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR
                                ? 'Cadastrando...'
                                : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR
                              ? 'Cadastrar'
                              : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="reply" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Acoes;
