import useSWR, { useSWRConfig } from 'swr';

import url from '~/infra/urls';


export default function useGetOfertaAtiva({
  propostaAtivaId
}, options = {}) {

  const config = useSWRConfig();
  const apiUrl = url.OFERTA_ATIVA.CONSULTAR();
  const queryArgs = `?propostaAtivaId=${propostaAtivaId}`;
  const ret = useSWR(propostaAtivaId ? `${apiUrl}${queryArgs}` : null, (url) => {
    return config?.fetcher(url)
      .then(res => {
        if (res.status === 204) {
          throw new Error('No content');
        } else {
          return res;
        }
      });
  }, options)

  const ofertaAtiva = ret?.data;
  const hasOferta = !!ofertaAtiva;

  return {
    ...ret,
    ofertaAtiva,
    hasOferta
  };
}
