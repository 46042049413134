import React, {Fragment, useEffect, useState} from 'react';
import {Select, Tag, Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';

import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';

const {Option, OptGroup} = Select;

const TipoMembro = {
  CORRETOR: 'corretor',
  GRUPO_CORRETORES: 'grupo-corretores',
};

const mapOptionsGroup = (corretores, grupoCorretores) => [
  {
    key: TipoMembro.CORRETOR,
    label: <strong>Corretores</strong>,
    title: 'Corretores',
    options: corretores
      .filter((item) => !item.excluido)
      .map((item) => ({
        id: item.id,
        label: item.nome,
        key: TipoMembro.CORRETOR,
      })),
  },
  {
    key: TipoMembro.GRUPO_CORRETORES,
    label: <strong>Grupo de Corretores</strong>,
    title: 'Grupo de Corretores',
    options: grupoCorretores.map((item) => ({
      id: item.id,
      label: item.grupo,
      key: TipoMembro.GRUPO_CORRETORES,
    })),
  },
];

const SelectCorretoresOrGrupoCorretoresInput = ({
  label,
  placeholder,
  enableHelperTooltip,
  enableTagRender,
  onChange,
  defaultValue,
  ...props
}) => {
  const {data: grupoCorretores, isLoading: isLoadingGrupoCorretores} =
    useGrupoCorretores();
  const {data: corretores, isLoading: isLoadingCorretores} =
    useCorretorDropdown();
  const optionsGroup = mapOptionsGroup(corretores, grupoCorretores);
  const whichGroupItBelongs = (id) =>
    optionsGroup.find(
      (item) => item.options.find((option) => option.id === id) !== undefined,
    );
  const [members, setMembers] = useState([]);
  useEffect(() => {
    if (defaultValue) {
      const handledMembros = defaultValue.map((membro) => {
        return {
          key: membro.id,
          value: membro.id,
          options: {
            type: membro.tipo === 1 ? 'corretor' : 'grupo-corretores',
          },
          label: membro.nome,
          children: membro.nome,
        };
      });
      setMembers(handledMembros);
    }
  }, [defaultValue]);

  const filterOption = (input, option) => {
    if (Array.isArray(option.options)) {
      return false;
    }

    return (
      option.children
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .indexOf(
          input
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        ) >= 0
    );
  };

  const tagRender = (props) => {
    const belongsTo = whichGroupItBelongs(props.value);
    return (
      <Tag
        style={{lineHeight: '22px', fontSize: '14px'}}
        color={belongsTo?.key === TipoMembro.CORRETOR ? null : '#1d1f8c'}
        closable={props.closable}
        onClose={props.onClose}
        placeholder={
          belongsTo?.key === TipoMembro.CORRETOR
            ? 'Corretor'
            : 'Grupo de Corretores'
        }>
        {props.label}
      </Tag>
    );
  };

  return isLoadingCorretores || isLoadingGrupoCorretores ? (
    <div className="w-100">
      {enableHelperTooltip && (
        <p className="heading-small text-muted mb-2">
          {enableHelperTooltip && <small>{label}</small>}
          {enableHelperTooltip && <HelperTooltipMembros />}
        </p>
      )}
      <Select
        mode="multiple"
        allowClear
        placeholder={` ${placeholder}`}
        style={{width: '100%'}}
        disabled={isLoadingCorretores || isLoadingGrupoCorretores}></Select>
    </div>
  ) : (
    <div className="w-100">
      {enableHelperTooltip && (
        <p className="heading-small text-muted mb-2">
          {enableHelperTooltip && <small>{label}</small>}
          {enableHelperTooltip && <HelperTooltipMembros />}
        </p>
      )}
      <Select
        value={members}
        style={{width: '100%'}}
        placeholder={` ${placeholder}`}
        mode="multiple"
        tagRender={enableTagRender ? tagRender : null}
        filterOption={filterOption}
        disabled={isLoadingCorretores || isLoadingGrupoCorretores}
        showArrow
        allowClear
        onChange={(_, options) => {
          setMembers(options);
          onChange && onChange(options);
        }}
        {...props}>
        {optionsGroup.map((item) => (
          <OptGroup key={item.title} value={item.title} label={item.title}>
            {item.options.map((option) => {
              return (
                <Option
                  key={option.id}
                  value={option.id}
                  options={{
                    type: item.key,
                  }}>
                  {option.label}
                </Option>
              );
            })}
          </OptGroup>
        ))}
      </Select>
    </div>
  );
};

/**
 * HelperTooltipMembros
 */
const HelperTooltipMembros = () => (
  <Tooltip
    placement="top"
    title={
      <Fragment>
        <p>
          Neste campo você pode selecionar um ou mais corretores ou grupo de
          corretores.
        </p>
        <p>
          Para identificar o <b>"Grupo de Corretores"</b> facilmente, bastar
          observar a cor do item.
        </p>
        <small>
          Obs: <br />
          <p>
            Ao selecionar um "Grupo de Corretores", todos os corretores
            pertencentes ao grupo mesmo que não estejam listados no campo
            abaixo, terão acesso a oferta ativa.
          </p>
        </small>
        <small>
          <p>
            Caso o corretor esteja listado abaixo e também pertença a um grupo,
            o grupo prevalecerá garantindo acesso a oferta ativa.
          </p>
        </small>
        <p>
          <p>
            Grupo de Corretores: <Tag color="#1d1f8c">Azul</Tag>
          </p>
          <p>
            Corretor:{' '}
            <Tag color="lightgray" style={{color: 'black'}}>
              Cinza
            </Tag>
          </p>
        </p>
      </Fragment>
    }>
    <QuestionCircleOutlined
      style={{
        fontSize: '16px',
        fontWeight: 'bold',
        marginLeft: '5px',
        cursor: 'pointer',
        color: '#1d1f8c',
      }}
    />
  </Tooltip>
);

export default SelectCorretoresOrGrupoCorretoresInput;
