import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import history from '~/services/history';
import formatTelefone from '~/shared/formatTelefone';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

export function rowEdit(id, identificador, empresaId) {
  history.push({
    pathname: `/app/atendimentos/manutencao/editar`,
    state: { id, identificador, empresaId },
  });
}

const RenderCardAtendimentos = ({
  row,
  index,
  controlaOcorrencias = false,
  parmIdentificador,
  empresaId,
}) => {
  let cardBorder = '';
  let category = '';
  let status = '';
  switch (row.validadeAtendimento) {
    case 1:
      category = 'Normal';
      cardBorder = 'border-dashboard-normal';
      break;

    case 2:
      category = 'Vencendo no dia';
      cardBorder = 'border-dashboard-dia';
      break;

    case 3:
      category = 'Pendente de Retorno';
      cardBorder = 'border-dashboard-retorno';
      break;

    case 4:
      category = 'Irregular';
      cardBorder = 'border-dashboard-irregular';
      break;

    default:
      cardBorder = 'border-dashboard-normal';
  }

  switch (row.situacao) {
    case 2:
    case 3:
      cardBorder = 'border-dashboard-concluido';
      category = 'Concluído';
      break;
    default:
      break;
  }

  switch (row.status) {
    case 1:
      status = 'Negócio Fechado';
      break;

    case 2:
      status = 'Em Atendimento';
      break;

    case 3:
      status = 'Desistência';
      break;

    case 4:
      status = 'Lead na Base';
      break;

    case 5:
      status = 'Em Análise Financeira';
      break;

    case 6:
      status = 'Análise Financeira Aprovada';
      break;

    case 7:
      status = 'Análise Financeira Reprovada';
      break;

    case 8:
      status = 'Em Tentativa';
      break;

    default:
      status = '';
  }

  if (controlaOcorrencias) {
    if (row?.ocorrencia?.status) {
      switch (row?.ocorrencia?.status) {
        case 1:
          category = 'Normal';
          status = 'Aguardando Conferência';
          cardBorder = 'border-dashboard-dia';
          break;

        case 2:
          category = 'Irregular';
          status = 'Com Pendência';
          cardBorder = 'border-dashboard-irregular';
          break;

        case 3:
          category = 'Normal';
          status = 'Contrato Fechado';
          cardBorder = 'border-dashboard-normal';
          break;

        default:
          status = '';
      }
    } else if (row.status === 1) {
      // 1 - Negócio Fechado
      category = 'Normal';
      status = 'Ocorrência Não Enviada';
      cardBorder = 'border-dashboard-dia';
    }
  }

  const name = row?.cliente?.nome?.split(' ');
  const proximoContato = row?.eventos?.map(function(e) { return e?.proximoContato; })?.sort()?.reverse()[0];
  return (
    <Col xs="12" md="4" key={index}>
      <Card
        className={`${cardBorder} divClick card-customer`}
        onClick={() => rowEdit(row.id, parmIdentificador, empresaId)}>
        <CardBody className="px-3">
          <span className="category">{category}</span>
          <div className="mt-1">
            Código:
            <span className="text-dark-75 font-weight-bold font-size-lg mb-2 ml-2">
              {row?.identificador || null}
            </span>
          </div>
          <div className="mt-1">
            <span className="text-dark-75 font-weight-bold font-size-lg mb-2">
              {(name && name[0]) ? name[0] : ''}{' '}
              {(name && name[1]) ? name[1] : ''}{' '}
              {(name && name[2]) ? name[2] : ''}
            </span>
          </div>

          <div className="mt-1">
            {row?.cliente?.celular && formatTelefone(row?.cliente?.celular)}
            {row?.cliente?.telefone &&
              !row?.cliente?.celular &&
              formatTelefone(row?.cliente?.telefone)}
          </div>
          <div className="mt-1">
            <span style={{ fontSize: '11px' }}>
              {row?.cliente?.email
                ? row?.cliente?.email
                : row?.cliente?.email2
                  ? row?.cliente?.email2
                  : null}
            </span>
          </div>
          <div className="mt-1">
            <p className="font-weight-bold">{status}</p>
          </div>
          {row?.corretor?.nome && <div style={{ fontSize: '12px' }} className="mt-1">
            Corretor:
            <span style={{ fontSize: '12px' }} className="text-dark-75 font-weight-bold font-size-lg mb-2 ml-2">
              {row.corretor.nome}
            </span>
          </div>}
          {proximoContato && <div style={{ fontSize: '12px' }} className="mt-1">
            Próximo Contato:
            <span style={{ fontSize: '12px' }} className="text-dark-75 font-weight-bold font-size-lg mb-2 ml-2">
              {moment(proximoContato).format('DD/MM/YYYY')}
            </span>
          </div>}
        </CardBody>
      </Card>
    </Col>
  );
};

export default RenderCardAtendimentos;
