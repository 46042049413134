/* eslint-disable no-unused-vars */
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Row, Col} from 'reactstrap';
import {Pie, G2} from '@ant-design/charts';
import api from '~/services/api';
import url from '~/infra/urls';
import './index.css';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';

const ImoveisPizza = ({idCorretor}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const {registerTheme} = G2;
  registerTheme('custom-theme', {
    colors10: ['#FF6384', '#FEA752', '#5CC5C5'],
  });

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isCorretor = !!roles.find((x) => x.toUpperCase() === 'CORRETOR');
  const usuarioId = localStorage.getItem('icaseUsuarioId');

  const onLoad = useCallback(async () => {
    setIsLoading(true);

    let configuracoessistemas = `?corretorId=${
      isCorretor ? usuarioId : idCorretor ? idCorretor : ''
    }`;

    await api
      .get(url.IMOVEL.IMOVEIS_POR_OPERACAO(configuracoessistemas))
      .then((res) => {
        if (res?.data)
          setData(
            res.data.map((d) => {
              return {
                type:
                  d.operacaoImobiliaria === 1
                    ? 'Locação'
                    : d.operacaoImobiliaria === 2
                    ? 'Venda'
                    : 'Venda / Locação',
                value: d.quantidade,
              };
            }),
          );

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const {erros} = err.response.data;
        if (erros) {
          for (const item in erros) {
          }
        }
      });
    // eslint-disable-next-line
  }, [idCorretor]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  var config = {
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    legend: false,
    label: {
      type: 'inner',
      offset: '-40%',
      content: function content(_ref) {
        var percent = _ref.percent;
        return ''.concat((percent * 100).toFixed(0), '%');
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{type: 'element-active'}],
    theme: 'custom-theme',
  };

  return (
    <Fragment>
      {isLoading ? (
        <SkeletonTableBasic cardShadowless />
      ) : (
        <>
          <Row className="pl-4">
            <p className="dash-content-title">Imóveis</p>
          </Row>
          <div style={{paddingLeft: 50, paddingRight: 50}}>
            <Row style={{height: 450, alignContent: 'center'}}>
              <Col xs="12" md="6">
                <div style={{marginTop: 15, marginBottom: 0}}>
                  <span
                    className="text-dark-75 font-weight-bold"
                    style={{fontSize: '30px'}}>
                    {data?.reduce((a, b) => a + b['value'], 0) || 0}
                  </span>
                </div>
                <div style={{marginTop: 0, marginBottom: 0}}>
                  <span className="text-dark-75" style={{fontSize: '12px'}}>
                    Disponíveis
                  </span>
                </div>
                <div style={{marginTop: 15}}>
                  <div className="row-color venda">
                    <span>Venda</span>
                    <span>
                      {data?.find((f) => f.type === 'Venda')?.value || 0}
                    </span>
                  </div>
                  <div className="row-color locacao">
                    <span>Locação</span>
                    <span>
                      {data?.find((f) => f.type === 'Locação')?.value || 0}
                    </span>
                  </div>
                  <div className="row-color locacaoVenda">
                    <span>Venda / Locação</span>
                    <span>
                      {data?.find((f) => f.type === 'Venda / Locação')?.value ||
                        0}
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="text-center" style={{height: '300px'}}>
                  <Pie loading={isLoading} {...config} />
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default ImoveisPizza;
