export const PESQUISA_POR_CODIGO_EXTERNO = 'PESQUISA_POR_CODIGO_EXTERNO';
export const UTILIZA_ANALISE_FINANCEIRA = 'UTILIZA_ANALISE_FINANCEIRA';
export const BOARD_TIPO_MIDA = 'BOARD_TIPO_MIDA';
export const BOARD_OPERACAO_IMOBILIARIA = 'BOARD_OPERACAO_IMOBILIARIA';
export const BOARD_ANIVERSARIANTES = 'BOARD_ANIVERSARIANTES';
export const CONTROLA_OCORRENCIAS = 'CONTROLA_OCORRENCIAS';
export const DIAS_ATENDIMENTO_IRREGULAR = 'DIAS_ATENDIMENTO_IRREGULAR';
export const CONTROLA_IR = 'CONTROLA_IR';
export const CONSULTAR_IMOVEIS_REDE_LATITUDE =
  'CONSULTAR_IMOVEIS_REDE_LATITUDE';
export const INTEGRAR_COM_ONEPLUS = 'INTEGRAR_COM_ONEPLUS';
export const INTEGRAR_COM_A_ORULO = 'INTEGRAR_COM_A_ORULO';
export const ORULO_CLIENT_SECRET = 'ORULO_CLIENT_SECRET';
export const ORULO_CLIENT_ID = 'ORULO_CLIENT_ID';
export const NOTIFICAR_GERENTE_ATENDIMENTO_IRREGULAR =
  'NOTIFICAR_GERENTE_ATENDIMENTO_IRREGULAR';
export const PERMITE_TRANSF_CARTEIRA_PROPRIA =
  'PERMITE_TRANSF_CARTEIRA_PROPRIA';
export const TENTATIVAS_ATENDIMENTO = 'TENTATIVAS_ATENDIMENTO';
export const DIRECIONA_LEAD_CAPTADOR = 'DIRECIONA_LEAD_CAPTADOR';
export const EXIBIR_ENDERECO_REDE_LATITUDE = 'EXIBIR_ENDERECO_REDE_LATITUDE';
export const TIPO_ENDERECO_XML = 'TIPO_ENDERECO_XML';
export const PERMITE_LEAD_DUPLICADO = 'PERMITE_LEAD_DUPLICADO';
export const COR_PRIMARIA_APRESENTACAO = 'COR_PRIMARIA_APRESENTACAO';
export const COR_SECUNDARIA_APRESENTACAO = 'COR_SECUNDARIA_APRESENTACAO';
export const BADGE_ESCURA = 'BADGE_ESCURA';
