import React from 'react';
import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import Header from "./Header";
import Footer from "./Footer";
import { config } from '../commom/config';

import chunckArray from '~/infra/resources/pattern/chunkArray';

const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingHorizontal: 20,
    paddingBottom: 162,
    color: config.textColor,
    fontFamily: config.fontFamily,
  },
  info: {
    fontSize: config.tertiaryFontSize,
  },
  title: {
    fontWeight: config.fontWeight,
    fontSize: config.secondaryFontSize,
    color: config.titleColor,
    marginBottom: 10,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  image: {
    width: '49%',
    height: 152,
  }
});

const Pictures = ({ corretor, empresa, imovel, index }) => {
  const result = chunckArray(2, imovel?.imagens);

  const imovelNumber = index + 1;
  let textInfo = `Imóvel ${imovelNumber.toString().padStart(2, '0')}`;
  textInfo += imovel?.tipoImovel?.descricao ? ` - ${imovel?.tipoImovel?.descricao}` : '';
  textInfo += imovel?.logradouro?.bairro ? ` - ${imovel?.logradouro?.bairro?.nome} - ${imovel?.logradouro?.bairro?.cidade?.nome}` : '';

  return (
    <Page size="A4" style={styles.page}>
      <Header logoEmpresaUrl={corretor?.logoEmpresaUrl}/>

      <Text style={styles.info} fixed>{textInfo}</Text>
      <Text style={styles.title} fixed>Fotos</Text>
      <View style={styles.body}>
        {result.map((item, index) => (
          <View key={`PI-${index}`} style={styles.row}>
            {item.map(picture => (
              <Image
                cache={false}
                key={picture?.id}
                style={styles.image}
                src={{ uri: picture?.arquivo, method: 'GET', headers: { "Cache-Control": "no-cache", "Access-Control-Allow-Origin": "*" }, body: "" }}
              />
            ))}
          </View>
        ))}
      </View>

      <Footer corretor={corretor} empresa={empresa} />
    </Page>
  );
}

export default Pictures;
