import React, { Fragment } from 'react';
import { Card } from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';
import useSalesRanking from './use-sales-ranking'

export default function SalesRanking({ filters }) {

  const { mainPeriod, brokerGroup, initialDate, finalDate } = filters;
  const { data: rankingData } = useSalesRanking(mainPeriod, brokerGroup, initialDate, finalDate);

  const selfPosition = rankingData && rankingData.length > 0 && rankingData.filter(p => !!p?.minhaPosicao)[0];

  const RenderSelfPosition = () => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
          gap: '1rem',
          padding: '0.5rem',
          borderRadius: '0.5rem',
          backgroundColor: '#6496f9',
          color: '#FFF',
        }}>
        <Fragment>
          <div
            style={{
              gridColumn: 'span 1 / span 1',
            }}>
            <span>{selfPosition.posicao}°</span>
          </div>
          <div
            style={{
              gridColumn: 'span 2 / span 2',
            }}>
            <span>{selfPosition.nomeGrupoCorretores}</span>
          </div>
          <div
            style={{
              gridColumn: 'span 6 / span 6',
            }}>
            <span>{selfPosition.nomeCorretora}</span>
          </div>
          <div
            style={{
              gridColumn: 'span 3 / span 3',
            }}>
            <span>{Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(selfPosition.totalVendas || 0)} (Você)</span>
          </div>
        </Fragment>
      </div>
    );
  };

  const renderRanking = rankingData && rankingData.length > 0 && rankingData.map((item) => (
    !!item?.minhaPosicao ?
      item?.posicao < 5 && <RenderSelfPosition key={item?.posicao} />
      : <div
        key={item?.posicao}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
          gap: '1rem',
          padding: '0 0.5rem',
          borderRadius: '0.5rem',
        }}>
        <Fragment>
          <div
            style={{
              gridColumn: 'span 1 / span 1',
            }}>
            <span>{item.posicao}°</span>
          </div>
          <div
            style={{
              gridColumn: 'span 2 / span 2',
            }}>
            <span>{item.nomeGrupoCorretores}</span>
          </div>
          <div
            style={{
              gridColumn: 'span 6 / span 6',
            }}>
            <span>{item.nomeCorretora}</span>
          </div>
          <div
            style={{
              gridColumn: 'span 3 / span 3',
            }}>
            <span>{Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(item.totalVendas || 0)}</span>
          </div>
        </Fragment>
      </div>
  ));

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 330,
        display: 'flex',
        gap: 5,
      }}>
      <CardTitle>Ranking Geral Vendas:</CardTitle>
      {(!rankingData || rankingData.length === 0) ?
        <span>Sem dados ...</span> :
        <>
          {renderRanking}
          <div
            style={{
              padding: '0 0.5rem',
              borderRadius: '0.5rem',
            }}>
            <span>...</span>
          </div>
          {!!selfPosition && selfPosition?.posicao > 4 && <RenderSelfPosition />}
        </>
      }

    </Card>
  );
}

