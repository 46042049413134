import React, { Fragment,useEffect, useState } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Form, Input } from 'antd';
import { Row, Col } from 'reactstrap';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls';

const { TextArea } = Input;

function ModalAndamentoAnalise({ loading, open, close, row, isCorretor }) {

  const [isLoading, setIsLoading] = useState(false);
  const [formRef] = Form.useForm();

  const onSave = async (aprovada) => {
    setIsLoading(true);

    const { resultado } = formRef.getFieldsValue();

    const payload = {
      obsAnaliseFinanceira: resultado,
      atendimentoId: row?.id,
      aprovada
    }

    await api.post(url.ATENDIMENTO.EFETIVAR_ANALISE_FINANCEIRA(), payload)
      .then((res) => {
        setIsLoading(false);
        Notify('success', '', pt.geral.progresso_com_sucesso);
        close();
      })
      .catch((err) => {
        setIsLoading(false);
        const { erros } = err.response.data;
        if (erros) {
          for (const item in erros) {
            Notify('error', pt.comum.atencao, erros[item]);
          }
        }
      });
  }

  useEffect(() => {
    formRef.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        dimmer="blurring"
        open={open}
        onClose={() => close()}>
        <Modal.Header>
          <Row>
            <Col xs="12" md="12">
              <h6 className="title">Progresso da análise</h6>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row>
            <Col>
              <div className="p-2">
                <Form
                  form={formRef}
                  initialValues={{ resultado: row?.obsAnaliseFinanceira }}
                  scrollToFirstError>
                  <Row>
                    <Col xs="12" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Resultado</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="resultado"
                        rules={[
                          {
                            required: true,
                            message: pt.form.rules.resultado,
                          },
                        ]}>
                        <TextArea
                          disabled={isCorretor}
                          placeholder={'Resultado da análise financeira.'}
                          rows={4}
                          maxLength={500}
                          showCount
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Button icon labelPosition="left"
            disabled={isLoading}
            onClick={() => close()}>
            <Icon name="angle left" />
            Voltar
          </Button>
          {!isCorretor && <Button
            disabled={isLoading}
            content="Reprovar"
            labelPosition="right"
            icon="times"
            onClick={() => onSave(false)}
            color="red"
          />
          }
          {!isCorretor && <Button
            content="Aprovar"
            labelPosition="right"
            icon="checkmark"
            onClick={() => onSave(true)}
            color="blue"
          />}
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}



export default ModalAndamentoAnalise;
