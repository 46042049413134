import styled from 'styled-components';

import {Col, Row} from 'reactstrap';

export const CardGrid = styled(Col).attrs(() => ({
  xs: '12',
}))``;

export const ContentRow = styled(Row)`
  flex-direction: row;

  @media only screen and (max-width: 1500px) {
    flex-direction: column-reverse;
  }
`;

