import {useState} from 'react';

import pt from '~/infra/resources/strings/pt';
import URL from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';

export default function useRemoverAtendimentoEmLote() {
  const [isLoading, setIsLoading] = useState(false);
  const removerAtendimentoEmLote = async (
    atendimentoIds,
    filtros,
    lanes,
    usuarioId,
  ) => {
    setIsLoading(true);
    try {
      await api
        .delete(URL.ATENDIMENTO.REMOVER_EM_LOTE(), {
          data: {
            atendimentoIds,
            filtros,
            lanes,
            usuarioId,
          },
        })
        .then((res) => {
          Notify(
            'success',
            pt.comum.aviso,
            'Atendimentos removidos com sucesso',
          );
        });
    } catch (err) {
      Notify('error', pt.comum.aviso, 'Não foi possivel remover atendimentos');
    } finally {
      setIsLoading(false);
    }
  };
  return {
    removerAtendimentoEmLote,
    isLoading,
  };
}
