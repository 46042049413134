import { BehaviorSubject } from 'rxjs'

export const tryParseJSON = (value, defaultValue = null) => {
  try {
    return JSON.parse(value)
  } catch (error) {
    if (!value) return defaultValue
    return value
  }
}

export default (property, defaultValue) => {
  const value = tryParseJSON(localStorage.getItem(property), defaultValue);
  const propertyBehavior = new BehaviorSubject(value);
  propertyBehavior.subscribe((value) => {
    if (typeof value === 'object') {
      localStorage.setItem(property, JSON.stringify(value));
    } else {
      localStorage.setItem(property, value);
    }
  });

  return propertyBehavior;
}
