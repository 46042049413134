import {useState} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

export default function useInativarUsuario() {
  const [isLoading, setIsLoading] = useState(false);
  const inativarUsuario = async (
    usuarioId,
    decisaoAtendimentos,
    distribuirAtendimentosParaCorretoresIds,
    distribuirAtendimentosParaGrupoCorretoresIds,
    decisaoImoveis,
    distribuirImoveisParaCorretoresIds,
    distribuirImoveisParaGrupoCorretoresIds,
  ) => {
    setIsLoading(true);
    console.log(
      usuarioId,
      decisaoAtendimentos,
      distribuirAtendimentosParaCorretoresIds,
      distribuirAtendimentosParaGrupoCorretoresIds,
      decisaoImoveis,
      distribuirImoveisParaCorretoresIds,
      distribuirImoveisParaGrupoCorretoresIds,
    );
    try {
      await api
        .patch(url.CORRETOR.INATIVAR(usuarioId), {
          decisaoAtendimentos: decisaoAtendimentos,
          distribuirAtendimentosParaCorretores:
            distribuirAtendimentosParaCorretoresIds,
          distribuirAtendimentosParaGrupoCorretores:
            distribuirAtendimentosParaGrupoCorretoresIds,
          decisaoImoveis: decisaoImoveis,
          distribuirImoveisParaCorretores: distribuirImoveisParaCorretoresIds,
          distribuirImoveisParaGrupoCorretores:
            distribuirImoveisParaGrupoCorretoresIds,
        })
        .then((res) => {
          if (res.status >= 400) {
            throw new Error();
          }
          Notify('success', pt.comum.aviso, 'Usuário inativo com sucesso!');
        });
    } catch (err) {
      Notify('error', pt.comum.aviso, 'Não foi possivel inativar o usuário.');
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    inativarUsuario,
  };
}
