import React, { Fragment, useState } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { Form, Select } from 'antd';
import { Row, Col } from 'reactstrap';
import api from '~/services/api';
import url from '~/infra/urls';
import { pdf } from '@react-pdf/renderer';
import Button from '~/shared/Button';
import ModeloAtendimentoPadrao from './ModeloAtendimentoPadrao';
import ModeloAtendimentoDet from './ModeloAtendimentoDet';
import _ from 'lodash';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const { Option } = Select;

function ModalRelatorioAtendimento({
  open,
  close,
  empresaId,
  isCorretor,
  corretorId,
  usuarioId,
  filterParams,
}) {
  const [loading, setIsLoading] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [modelo, setModelo] = useState(1);

  const obterUrl = (tipo) => {
    switch (modelo) {
      case 2:
        return url.RELATORIOS.ATENDIMENTOS_DET();
      default:
        if (tipo === 'excel') return url.RELATORIOS.ATENDIMENTOS_EXCEL();
        return url.RELATORIOS.ATENDIMENTOS();
    }
  };

  const obterLayout = (data) => {
    switch (modelo) {
      case 2: {
        var grouped = _.groupBy(data, function (atd) {
          return atd.corretorId;
        });

        return (
          <ModeloAtendimentoDet
            dados={grouped}
            nomeEmpresa={nomeEmpresa}
            qtdRegistros={data?.length || 0}
          />
        );
      }
      default:
        return (
          <ModeloAtendimentoPadrao dados={data} nomeEmpresa={nomeEmpresa} />
        );
    }
  };

  const downloadDoArquivo = (response) => {
    const url = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `atendimentos_${new Date().toLocaleString()}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();
  };

  async function gerarRelatorio(tipo) {
    setIsLoading(true);

    try {
      if (empresaId) {
        await api.get(`empresas/${empresaId}`).then((res) => {
          setNomeEmpresa(res?.data?.nome);
        });
      }

      const payload = {
        corretorId: isCorretor
          ? usuarioId
          : corretorId === 'TODOS'
            ? null
            : corretorId,
        empresaId,
        ...filterParams
      };

      await api
        .post(obterUrl(tipo), payload, {
          responseType: tipo === 'excel' ? 'blob' : 'json',
        })
        .then(async (res) => {
          if (res?.data) {
            if (tipo === 'excel') {
              downloadDoArquivo(res.data);
            } else {
              const blob = await pdf(obterLayout(res.data)).toBlob();

              const pdfURL = URL.createObjectURL(blob);
              window.open(pdfURL, '_blank');
            }
          }
        });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Modal size="mini" open={open} onClose={() => close()}>
        <Modal.Header>
          <Row>
            <Col xs="12">
              <div>
                <h4 className="titleLogin">Relatório de Atendimentos</h4>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row className="p-2">
            <Col>
              <div>
                <Row>
                  <Col xs="12" md="12" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Modelo Relatório</small>
                    </p>
                    <Form.Item className="my-2" name="modelo">
                      <Select
                        defaultValue={modelo}
                        placeholder=" Modelo"
                        onChange={(value) => setModelo(value)}>
                        <Option value={1}>Padrão</Option>
                        {/* <Option value={2}>Detalhado</Option> */}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                {/*   <Col xs="12" className="mt-4">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      color="blue"
                      isloading={loading.toString()}
                      onClick={gerarRelatorio}
                      loadtext="Gerando PDF...">
                      <Icon name="file pdf outline" />
                      Gerar PDF
                    </Button>
                  </Col>*/}
                  {modelo === 1 && (
                    <Col xs="12">
                      <Button
                        icon
                        labelPosition="left"
                        size="medium"
                        fluid
                        color="green"
                        className="mt-1"
                        isloading={loading.toString()}
                        onClick={() => gerarRelatorio('excel')}
                        loadtext="Gerando Excel...">
                        <Icon name="file excel outline" />
                        Gerar Excel
                      </Button>
                    </Col>
                  )}
                  <Col xs="12">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-1"
                      onClick={close}>
                      <Icon name="reply" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalRelatorioAtendimento;

