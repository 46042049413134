import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

export default function RouteWrapper({component: Component, ...rest}) {
  const Default = (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );

  return Default;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
