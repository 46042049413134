import {useState} from 'react';

import pt from '~/infra/resources/strings/pt';
import URL from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';

export default function useAlterarStatusEmLote() {
  const [isLoading, setIsLoading] = useState(false);
  const alterarStatusEmLote = async (
    atendimentoIds,
    filtros,
    lanes,
    status,
    motivoDesistencia,
    justificativa,
    usuarioId,
  ) => {
    setIsLoading(true);
    try {
      await api
        .put(URL.ATENDIMENTO.ALTERAR_STATUS_EM_LOTE(), {
          atendimentoIds,
          filtros,
          lanes,
          status,
          motivoDesistencia,
          justificativa,
          usuarioId,
        })
        .then((res) => {
          Notify(
            'success',
            pt.comum.aviso,
            'Status dos atendimentos foram alterados',
          );
        });
    } catch (err) {
      Notify('error', pt.comum.aviso, 'Não foi possivel alterar os status');
    } finally {
      setIsLoading(false);
    }
  };
  return {
    alterarStatusEmLote,
    isLoading,
  };
}
