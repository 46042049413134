import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import { checkDocumento } from '~/shared/checkDocumento';

import url from '~/infra/urls';
import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';

import enumPessoaTipo from '~/infra/resources/strings/enums/tipoPessoa';

import useEmpresa from '~/actions/Empresa/useEmpresa';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Master(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(1);
  const [modo, setModo] = useState();
  const [masterId, setMasterId] = useState();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const { data: Empresas } = useEmpresa(1, 9999, null, null);

  const [form] = Form.useForm();

  const empresaId = localStorage.getItem('icaseEmpresaId');
  const roles = JSON.parse(localStorage.getItem('icaseRoles')) || [];

  const administrador = roles.find((role) => role === 'SystemAdmin');

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const user = props.location.state.user;

        setMasterId(user.id);
        setModo(MODO.EDITAR);
        setSelectedType(user.tipo || 1);

        form.setFieldsValue({
          empresaId: user.empresaId,
          tipo: user.tipo.toString(),
          nome: user.nome,
          email: user.email,
          cpfCnpj:
            user.tipo === 1 ?
              mask(user?.pessoaFisica?.cpf || '', pattern.cpf) :
              mask(user?.pessoaJuridica?.cnpj || '', pattern.cnpj),
        });
      } else {
        form.setFieldsValue({ tipo: '1' });
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }

      setTimeout(() => {
        setloadPage(false);
      }, 1200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function voltar() {
    history.push('/app/master');
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const cpf = selectedType === 1 ?
          dados.cpfCnpj ?
            dados.cpfCnpj.replace(/\D/g, '') :
            dados?.cpfCnpj :
          '';

        const cnpj = selectedType === 2 ?
          dados.cpfCnpj ?
            dados.cpfCnpj.replace(/\D/g, '') :
            dados?.cpfCnpj :
          '';

        if (modo === MODO.CADASTRAR) {
          const payload = {
            nome: dados?.nome,
            email: dados?.email,
            cpf,
            cnpj,
            empresaId: administrador ? dados?.empresaId : empresaId,
          };

          await api
            .post(url.MASTER.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { errors } = err.response.data;
              if (errors) {
                for (const item in errors) {
                  Notify('error', item ? item : pt.comum.atencao, errors[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: masterId,
            nome: dados?.nome,
            email: dados?.email,
            cpf,
            cnpj,
            empresaId: administrador ? dados?.empresaId : empresaId,
          };

          await api
            .put(url.MASTER.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { errors } = err.response.data;
              if (errors) {
                for (const item in errors) {
                  Notify('error', item ? item : pt.comum.atencao, errors[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function handleMask(e) {
    const type = selectedType === 1 ? pattern.cpf : pattern.cnpj;
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    form.setFieldsValue({ cpfCnpj: value });
  }

  function onChangeTipo(value) {
    setSelectedType(Number(value));
    form.setFieldsValue({ cpfCnpj: undefined });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Cadastrar Master</h5>
                <p className="category">Manutenção de master</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form form={form} scrollToFirstError>
                        {administrador && (
                          <Row className="mb-3">
                            <Col xs="12" md="6">
                              <p className="heading-small text-muted mb-2">
                                <small>Empresa</small>
                              </p>
                              <Form.Item
                                className="my-2"
                                name="empresaId"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Favor informar o Empresa',
                                  },
                                ]}>
                                <Select placeholder=" Empresa">
                                  {Empresas.map((item) => (
                                    <Option key={item.id}>{item.nome}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xs="12" md="3">
                            <Form.Item className="my-2" name="tipo">
                              <p className="heading-small text-muted mb-2">
                                <small>Tipo</small>
                              </p>
                              <Select
                                placeholder=" Tipo"
                                onChange={onChangeTipo}
                                defaultValue={selectedType}
                              >
                                {enumPessoaTipo.map((item) => (
                                  <Option key={item.id} value={item.id}>{item.nome}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="nome"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o nome!',
                                },
                              ]}>
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Email</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  type: 'email',
                                  message: 'Favor inserir um E-mail válido',
                                },
                              ]}>
                              <Input placeholder=" Email" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>{selectedType === 1 ? 'CPF' : 'CNPJ'}</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="cpfCnpj"
                              rules={[
                                // eslint-disable-next-line no-empty-pattern
                                ({ }) => ({
                                  validator(rule, value) {
                                    if (value) {
                                      const unMaskedValue = unMask(value);
                                      const typeDoc = selectedType === 1 ? 'cpf' : 'cnpj';
                                      const isDocumento = checkDocumento(
                                        typeDoc,
                                        unMaskedValue,
                                      );
                                      if (isDocumento === true) {
                                        if (selectedType === 1) {
                                          return Promise.reject(
                                            'Número de CPF inválido',
                                          );
                                        } else {
                                          return Promise.reject(
                                            'Número do CNPJ inválido',
                                          );
                                        }
                                      }
                                    }

                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <Input
                                placeholder={
                                  selectedType === 1 ?
                                    ' Número do CPF' :
                                    ' Número do CNPJ'
                                }
                                onKeyUp={(e) => handleMask(e)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-4">
                        <Col xs="12" md="3">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={'Cadastrando...'}>
                            <Icon name="check" />
                            Cadastrar
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="reply" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={voltar}
              message={modo === MODO.CADASTRAR ? 'Cadastrado com sucesso' : 'Alterações salvas'}
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Master;
