import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useProprietariosDropdown(name) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (param) => {
      const filterName = name ? `?name=${name}` : param ? `?name=${param}` : '';

      try {
        await api
          .get(`${url.DROPDOWN.PROPRIETARIOS_DROPDOWN()}${filterName}`)
          .then((res) => {
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [name],
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
