import api from '~/services/api';
import url from '~/infra/urls';
import {useState} from 'react';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

function useEditarOfertaAtiva() {
  const [isLoading, setIsLoading] = useState(false);
  const editarOfertaAtiva = async (
    ofertaAtivaId,
    nome,
    corretoresIds,
    grupoCorretoresIds,
  ) => {
    setIsLoading(true);
    try {
      await api
        .put(url.OFERTA_ATIVA.ALTERAR(ofertaAtivaId), {
          nome,
          corretoresIds,
          grupoCorretoresIds,
        })
        .then((res) => {
          Notify('success', pt.comum.aviso, 'Oferta ativa editada com sucesso');
        });
    } catch (err) {
      Notify(
        'error',
        pt.comum.aviso,
        'Não foi possivel alterar a oferta ativa',
      );
    } finally {
      setIsLoading(false);
    }
  };
  return {
    editarOfertaAtiva,
    isLoading,
  };
}

export default useEditarOfertaAtiva;
