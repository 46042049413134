import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { Form, Input } from 'antd';
import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import { Tabs } from '~/components/Tabs';
import { Tab } from '~/components/Tabs';

import history from '~/services/history';
import api from '~/services/api';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';

import PerfilMenus from './Menus';
import PerfilAcoes from './Acoes';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  PERFIL: 1,
  MENUS: 2,
  ACOES: 3,
};

function Perfil(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [identificador, setIdentificador] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [perfilId, setPerfilId] = useState(null);
  const [activeTab, setActiveTab] = useState(
    props?.match?.params?.tab || TABS.PERFIL,
  );
  const [disabledTabs, setDisabledTabs] = useState(true);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [form] = Form.useForm();

  // async function getMenusActionsProfile(profile) {
  //   await api.get(url.MENU.PERFIL(profile)).then((res) => {
  //     setMenusPerfil(res.data || []);
  //   });
  // }

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const { row: editData, identificador } = props.location.state;

        setIdentificador(identificador);
        setInitialValues(editData);
        setModo(MODO.EDITAR);
        setPerfilId(props.location.state.row.id);
        setDisabledTabs(false);

        // getMenusActionsProfile(props.location.state.row.id);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        const { identificador } = props.location.state;

        setIdentificador(identificador);
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            descricao: dados.descricao,
          };

          await api
            .post(url.PERFIL.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setPerfilId(res.data?.data?.id);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: perfilId,
            descricao: dados.descricao,
          };

          await api
            .put(url.PERFIL.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  async function selectTab(tab) {
    setActiveTab(tab);
  }

  function nextTab() {
    setOpenModalConfirm(false);
    setActiveTab(TABS.MENUS);
  }

  function voltar() {
    if (identificador) {
      history.push(`/app/perfil/${identificador}`);
    } else {
      history.push('/app/perfil');
    }
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="card-custom-basic card-transparent">
              <CardBody className="p-0">
                <Tabs
                  activeTab={activeTab}
                  setTab={(tab) => selectTab(tab)}
                  disabledTabs={disabledTabs}>
                  <Tab title="Perfil" desc="Manutenção Perfil">
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0 p-4">
                      <CardHeader className="pl-2">
                        <h5 className="title">Dados básicos</h5>
                        {/* <p className="category">Manutenção de Perfil</p> */}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs="12">
                            <div className="my-2 p-2">
                              <Form
                                form={form}
                                initialValues={initialValues}
                                scrollToFirstError>
                                <Row>
                                  <Col xs="12">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Descrição</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="descricao"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            'Favor informar a descricao!',
                                        },
                                      ]}>
                                      <Input placeholder=" Descrição" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                              <Row className="mt-4">
                                <Col xs="12" md="3">
                                  <Button
                                    onClick={onSave}
                                    icon
                                    labelPosition="left"
                                    size="medium"
                                    fluid
                                    className="mt-3"
                                    color="violet"
                                    isloading={loading.toString()}
                                    loadtext={
                                      modo === MODO.CADASTRAR
                                        ? 'Cadastrando...'
                                        : 'Atualizando...'
                                    }>
                                    <Icon name="check" />
                                    {modo === MODO.CADASTRAR
                                      ? 'Cadastrar'
                                      : 'Atualizar'}
                                  </Button>
                                </Col>
                                <Col xs="12" md="3">
                                  <Button
                                    icon
                                    labelPosition="left"
                                    size="medium"
                                    fluid
                                    className="mt-3"
                                    onClick={() => voltar()}>
                                    <Icon name="undo" />
                                    Voltar
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Tab>
                  <Tab title="Menus" desc="Manutenção de Menus">
                    <PerfilMenus
                      setActiveTab={(value) => selectTab(value)}
                      perfilId={perfilId}
                      identificador={identificador}
                    />
                  </Tab>
                  <Tab title="Ações" desc="Manutenção de Ações">
                    <PerfilAcoes
                      setActiveTab={(value) => selectTab(value)}
                      perfilId={perfilId}
                      identificador={identificador}
                    />
                  </Tab>
                </Tabs>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={modo === MODO.CADASTRAR ? nextTab : voltar}
              message={modo === MODO.CADASTRAR ? 'Cadastro realizado' : 'Alterações salvas'}
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Perfil;
