import React, { useCallback, useRef } from 'react';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  StandaloneSearchBox,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

export const GoogleMaps = ({
  latitude,
  longitude,
  setLatitudeLongitude,
  editable = true,
  suggestionPlace = "",
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBrRR6AmjH8-F1KcfihK4WqnqtfuggJg6M',
    libraries: ['places'],
  });


  const mapRef = useRef();
  const searchBoxRef = useRef();

  const onLoad = useCallback((map) => {
    mapRef.current = map;

    if (suggestionPlace && !latitude && !longitude) {
      let request = {
        query: suggestionPlace,
        fields: ["name", "geometry"]
      };

      // eslint-disable-next-line no-undef
      let service = new google.maps.places.PlacesService(map);

      service.findPlaceFromQuery(request, (results, status) => {
        // eslint-disable-next-line no-undef
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (results.length > 0)
            setLatitudeLongitude(results[0].geometry.location.lat(), results[0].geometry.location.lng());
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchBoxLoad = useCallback((searchBox) => {
    searchBoxRef.current = searchBox;
  }, []);


  const onPlacesChanged = () => {
    const searchBox = searchBoxRef.current

    if (!searchBox) return

    const places = searchBox.getPlaces()
    if (!places || places.length === 0) return

    const lat = places[0].geometry?.location?.lat()
    const lng = places[0].geometry?.location?.lng()

    if (lat && lng) {
      setLatitudeLongitude(lat, lng);
    }
  }

  return isLoaded ? (
    <>
      {editable ? (
        <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}>
          <input
            type="text"
            placeholder="buscar um endereço..."
            style={{
              zIndex: 2,
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: '75%',
              height: '32px',
              marginTop: '12px',
              padding: '0 12px',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '14px',
              outline: 'none',
              textOverflow: 'ellipses',
              position: 'absolute',
              left: '25%',
              marginLeft: '-120px',
            }}
          />
        </StandaloneSearchBox>
      ) : null}
      <GoogleMap
        id="gMaps"
        onLoad={onLoad}
        mapContainerStyle={containerStyle}
        center={{ lat: latitude, lng: longitude }}
        zoom={15}
        onClick={(e) =>
          editable ? setLatitudeLongitude(e.latLng.lat(), e.latLng.lng()) : null
        }>
        <Marker
          position={{ lat: latitude, lng: longitude }}
          onDragEnd={(e) =>
            editable
              ? setLatitudeLongitude(e.latLng.lat(), e.latLng.lng())
              : null
          }
          draggable
        />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

