import React from 'react'
import createBehaviourSubjectForLocalStorageItem from '~/hooks/localstorage/createBehaviourSubjectForLocalStorageItem'

const defaultContext = {
  prefix: 'icase',
  localStorageProperties: new Map(),
  getLocalStorageItem(key)  {
    if (this.localStorageProperties.has(key)) {
      return this.localStorageProperties.get(key);
    }

    const subject = createBehaviourSubjectForLocalStorageItem(this.prefix + key, null);
    this.localStorageProperties.set(key, subject);
    return subject;
  },
}

export const LocalStorageContext = React.createContext(defaultContext);

export const LocalStorageProvider = ({defaultPrefix, children}) => {
  if (!localStorage) {
    console.warn('Local Storage is not available');
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <LocalStorageContext.Provider value={{
      prefix: defaultPrefix,
      ...defaultContext,
    }}>
      {children}
    </LocalStorageContext.Provider>
  );
}
