/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { Divider, Form, Select, Modal as AntdModal, Skeleton, Switch } from 'antd';


import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import ButtonDynamic from '~/shared/Button';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Icon } from 'semantic-ui-react';


const { Option } = Select;

const filterOption = (input, option) => option?.children
    ?.toLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.indexOf(
        input
            ?.toLowerCase()
            ?.normalize('NFD')
            ?.replace(/[\u0300-\u036f]/g, '')
    ) >= 0;

function FacebookParam({ setFinalValues, finalValues, setPaginaFacebookId, paginaFacebookId, form }) {

    const [pages, setPages] = useState([]);
    const [forms, setForms] = useState([]);
    const [statusFacebook, setStatusFacebook] = useState('disconnected');
    const [showConfirmModal, setShowConfirmModal] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [todosForms, setTodosForms] = useState(finalValues?.todosFormsFacebook);

    const [tokenUser, setTokenUser] = useState(
        localStorage.getItem("fbToken") || ''
    )

    const [code, setCode] = useState(
        localStorage.getItem("fbAuth") || ''
    )

    if (pages?.length === 0 && finalValues?.paginaFacebookId && finalValues?.paginaFacebookDesc) {
        setPages([{ id: finalValues.paginaFacebookId, name: finalValues.paginaFacebookDesc }]);
    }

    if (forms?.length === 0 && finalValues?.formulariosFacebookDesc && finalValues?.formulariosFacebookDesc.length > 0) {
        setForms(finalValues?.formulariosFacebookDesc.map(c => { return { id: c.formId, name: c.desc } }));
    }

    if (forms?.length === 0 && finalValues?.formFacebookDesconsiderarDesc && finalValues?.formFacebookDesconsiderarDesc.length > 0) {
        setForms(finalValues?.formFacebookDesconsiderarDesc.map(c => { return { id: c.formId, name: c.desc } }));
    }

    function handleConfirmClose() {
        setShowConfirmModal(false);

        if (showConfirmModal === 'D')
            disconectFacebook();
        else
            handleLogin();
    }

    useEffect(() => {
        const OnStorageEvent = async () => {
            const value = localStorage.getItem('fbAuth') || ''

            if (code !== value) {
                // value change
                setCode(value);
            }
        }

        // eslint-disable-next-line no-restricted-globals
        addEventListener("storage", OnStorageEvent)

        // eslint-disable-next-line no-restricted-globals
        return () => removeEventListener("storage", OnStorageEvent)
    }, [code]);

    useEffect(() => {
        const OnStorageEvent = async () => {
            const value = localStorage.getItem('fbToken') || ''

            if (tokenUser !== value) {
                // value change
                setTokenUser(value);
            }
        }

        // eslint-disable-next-line no-restricted-globals
        addEventListener("storage", OnStorageEvent)

        // eslint-disable-next-line no-restricted-globals
        return () => removeEventListener("storage", OnStorageEvent)
    }, [tokenUser])

    // Função para realizar o login
    const handleLogin = () => {
        if (!!tokenUser) {
            loadPages();
        }
        else {
            disconectFacebook();
            window.open(`${process.env.REACT_APP_BASE_URL}/Facebook/login`, "_blank", "scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=600");
        }
    };

    const handlePageSubscribe = async (selectedPage) => {
        if (!!selectedPage && !!tokenUser & statusFacebook === 'connected') {
            const selectedPageData = pages.find(page => page.id === selectedPage);

            if (!selectedPageData?.access_token)
                return;

            const responseSubscribe = await api.post(`Facebook/subscribed_apps?selectedPage=${selectedPage}&accessToken=${selectedPageData.access_token}`);

            if (responseSubscribe.status === 200) {
                Notify('success', pt.comum.sucesso, 'Aplicativo instalado com sucesso na página');
            }

            const resToken = await api.get(`/Facebook/token-long-time?accessToken=${tokenUser}`).catch(() => {
                Notify('error', pt.comum.sucesso, 'Não foi possível obter o token de longa duração do usuário.');
            });

            if (resToken?.data) {
                const responsePages = await api.get(`Facebook/pages?accessToken=${resToken?.data}`).catch(() => {
                    Notify('error', pt.comum.sucesso, 'Não foi possível obter as páginas do Facebook.');
                });

                if (!!responsePages?.data && responsePages?.data.length > 0) {
                    let dataPages = responsePages?.data;

                    const accessTokenPageLongTime = dataPages.find(page => page.id === selectedPage)?.access_token;

                    if (!!accessTokenPageLongTime) {

                        setFinalValues({
                            ...finalValues, ...{ accessTokenPageLongTime: accessTokenPageLongTime, paginaFacebookDesc: selectedPageData?.name }
                        });

                        form.setFieldsValue({ accessTokenPageLongTime: accessTokenPageLongTime });
                    }
                    else
                        Notify('error', pt.comum.sucesso, 'Não foi possível obter o token de longa duração da página.');
                }
                else {
                    Notify('error', pt.comum.sucesso, 'Não foi possível obter as páginas do Facebook.');
                }
            }
        }
    };

    // Função para carregar páginas
    const getTokenUser = async () => {
        try {
            setStatusFacebook('connected');

            setIsLoading(true);

            const resToken = await api.get(`Facebook/callback?code=${code}`);

            if (!!resToken?.data) {
                setTokenUser(resToken?.data);
                localStorage.setItem('fbToken', resToken?.data)
            }
            else {
                Notify(
                    'error',
                    pt.comum.atencao,
                    'Não foi possível realizar login no Facebook, favor tente novamente mais tarde.',
                );

                disconectFacebook();
            }
        } catch (error) {
            console.error('Erro ao carregar páginas:', error);
            Notify(
                'error',
                pt.comum.atencao,
                'Não foi possível realizar login no Facebook, favor tente novamente mais tarde.',
            );

            disconectFacebook();
        }
        finally {
            setIsLoading(false);
        }
    };

    const loadPages = async () => {
        try {
            if (!tokenUser)
                return;

            setStatusFacebook('connected');
            setPaginaFacebookId(null);
            setTodosForms(false);

            setIsLoading(true);

            const data = await api.get(`Facebook/pages?accessToken=${tokenUser}`);
            setPages(data?.data);

            setFinalValues({ ...finalValues, ...{ paginaFacebookId: null, formulariosFacebookId: [], formulariosFacebookDesc: [], formFacebookDesconsiderarId: [], formFacebookDesconsiderarDesc: [] } });
            form.setFieldsValue({ paginaFacebookId: null, formulariosFacebookId: [], formFacebookDesconsiderarId: [] });

        } catch (error) {
            console.error('Erro ao carregar páginas:', error);
            Notify(
                'error',
                pt.comum.atencao,
                'Não foi possível realizar login no Facebook, favor tente novamente mais tarde.',
            );

            disconectFacebook();
        }
        finally {
            setIsLoading(false);
        }
    };

    const loadForms = async () => {
        if (paginaFacebookId && statusFacebook === 'connected') {
            try {
                const accesTokenPage = pages.find(page => page.id === paginaFacebookId)?.access_token;

                if (!accesTokenPage)
                    return;

                setIsLoadingForm(true);

                const responseForms = await api.get(`Facebook/leadgen_forms?pageId=${paginaFacebookId}&accessToken=${accesTokenPage}`);

                setFinalValues({
                    ...finalValues,
                    ...{
                        formulariosFacebookId: [],
                        formulariosFacebookDesc: [],
                        formFacebookDesconsiderarId: [],
                        formFacebookDesconsiderarDesc: [],
                        mapeamentoCampos: []
                    }
                });

                form.setFieldsValue({
                    formulariosFacebookId: [],
                    formFacebookDesconsiderarId: [],
                    mapeamentoCampos: []
                });

                setForms(responseForms.data);

                form.setFieldsValue({ mapeamentoCampos: [] });

                await handlePageSubscribe(paginaFacebookId);
            } catch (error) {
                console.error('Erro ao carregar formulários:', error);
            } finally {
                setIsLoadingForm(false);
            }
        }
    };

    const loadFields = async (arrayId, arrayObj) => {
        try {
            const selectedForm = !!arrayId && arrayId?.length > 0 && arrayId[arrayId.length - 1];
            const accessTokenPage = pages.find(page => page.id === paginaFacebookId)?.access_token;

            if (!accessTokenPage || !selectedForm)
                return;

            setIsLoadingForm(true);

            let newMapemaentoCampos = [];

            const responseFields = await api.get(`Facebook/fields_form?formId=${selectedForm}&accessToken=${accessTokenPage}`);

            for (const item of responseFields?.data?.questions) {
                if (finalValues?.mapeamentoCampos?.length > 0) {
                    const exist = finalValues.mapeamentoCampos.find(c => c.campoFormulario === item.key);

                    if (!exist)
                        newMapemaentoCampos.push({
                            campoLatitude: null,
                            campoFormulario: item.key
                        });
                }
                else
                    newMapemaentoCampos.push({
                        campoLatitude: null,
                        campoFormulario: item.key
                    });
            }

            if (newMapemaentoCampos.length > 0) {
                setFinalValues({
                    ...finalValues, ...{
                        mapeamentoCampos: [...(finalValues?.mapeamentoCampos || []), ...newMapemaentoCampos],
                        formulariosFacebookDesc: arrayObj?.map(c => {
                            return { formId: c.key, desc: c.children }
                        })
                    }
                });

                form.setFieldsValue({
                    mapeamentoCampos: [...(finalValues?.mapeamentoCampos || []), ...newMapemaentoCampos],
                });
            }
            else
                setFinalValues({
                    ...finalValues, ...{
                        formulariosFacebookDesc: arrayObj?.map(c => {
                            return { formId: c.key, desc: c.children }
                        })
                    }
                });


        } catch (error) {
            console.error('Erro ao carregar campos:', error);
        }
        finally {
            setIsLoadingForm(false);
        }
    };

    const loadFieldsAllForns = async () => {
        try {
            const accessTokenPage = pages.find(page => page.id === paginaFacebookId)?.access_token;

            if (!accessTokenPage || !paginaFacebookId)
                return;

            setIsLoadingForm(true);

            let newMapemaentoCampos = [];

            const responseFields = await api.get(`Facebook/fields_form_by_page?pageId=${paginaFacebookId}&accessToken=${accessTokenPage}`);

            for (const item of responseFields?.data) {
                newMapemaentoCampos.push({
                    campoLatitude: null,
                    campoFormulario: item.key
                });
            }

            if (responseFields?.data && responseFields?.data?.length > 0) {
                const newMapemaentoCampos = responseFields?.data.map(c => {
                    return { campoLatitude: null, campoFormulario: c.key }
                });

                setFinalValues({
                    ...finalValues, ...{
                        mapeamentoCampos: newMapemaentoCampos,
                    }
                });

                form.setFieldsValue({
                    mapeamentoCampos: newMapemaentoCampos,
                });
            }


        } catch (error) {
            console.error('Erro ao carregar campos:', error);
        }
        finally {
            setIsLoadingForm(false);
        }
    };

    const disconectFacebook = () => {
        localStorage.removeItem('fbAuth');
        localStorage.removeItem('fbToken');
        setCode(null);
        setTokenUser(null);
        setStatusFacebook('disconnected');
        setTodosForms(false);
        setPaginaFacebookId(null);
        setFinalValues({ ...finalValues, ...{ paginaFacebookId: null, paginaFacebookDesc: null, formulariosFacebookId: [], formulariosFacebookDesc: [], mapeamentoCampos: [] } });
        form.setFieldsValue({ paginaFacebookId: null, paginaFacebookDesc: null, formulariosFacebookId: [], mapeamentoCampos: [] });
    }


    useEffect(() => {
        if (!paginaFacebookId && !!code)
            getTokenUser();
    }, [code]);

    useEffect(() => {
        if (!paginaFacebookId)
            loadPages();
    }, [tokenUser]);

    useEffect(() => {
        if (paginaFacebookId) {
            loadForms();
        }
    }, [paginaFacebookId]);

    return <>
        <Divider orientation="left"><small>Configurações para recepção de Leads do Facebook - Meta</small></Divider>
        <Col xs="12">
            <ButtonDynamic icon color="violet" onClick={() => {
                if (!!paginaFacebookId)
                    setShowConfirmModal('C');
                else
                    handleLogin();
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name="facebook" size='large' />
                    {(!!paginaFacebookId || statusFacebook === 'connected') ? `Reconectar no Facebook` : `Conectar no Facebook`}
                </div>
            </ButtonDynamic>
            {(!!paginaFacebookId || statusFacebook === 'connected') && <ButtonDynamic
                className="ml-2"
                icon
                onClick={() => {
                    if (!!paginaFacebookId)
                        setShowConfirmModal('D');
                    else
                        disconectFacebook();
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon size='large' name="times" />Desconectar do Facebook
                </div>
            </ButtonDynamic>}
        </Col>
        <Col xs="12" className=" mb-2">
            <InfoCircleOutlined size={8} />
            <small className="text-dark-75 font-weight-bold pl-2">É necessário realizar login no facebook, conceder acesso as páginas e instalar o App do Latitude para recepção de leads.</small>
        </Col>
        {isLoading ? <Skeleton /> : <>
            <Col xs="12" md="12">
                <p className="heading-small text-muted mb-2">
                    <small>Página Facebook</small>
                </p>
                <Form.Item className="my-2" name="paginaFacebookId"
                    rules={[
                        {
                            required: true,
                            message: 'Informe uma página do facebook',
                        },
                    ]}>
                    <Select
                        disabled={statusFacebook === 'disconnected'}
                        showSearch
                        filterOption={filterOption}
                        placeholder=" Informe uma página do facebook"
                        onChange={(value, obj) => {
                            setPaginaFacebookId(value);
                        }}
                        allowClear>
                        {pages && pages?.length > 0 && pages.map(page => (
                            <Option key={page.id} value={page.id}>{page.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            {!!paginaFacebookId && <Col xs="12" md="12">
                <p className="heading-small text-muted mt-2 mb-0">
                    <small>Todos Formulários?</small>
                </p>
                <Form.Item className="mb-2" name="todosFormsFacebook">
                    <Switch
                        disabled={statusFacebook === 'disconnected' || isLoadingForm}
                        defaultChecked={todosForms}
                        onChange={(value) => {
                            setTodosForms(value);

                            if (!!value)
                                loadFieldsAllForns();
                        }}
                        checkedChildren="Sim"
                        unCheckedChildren="Não"
                    />
                </Form.Item>
            </Col>}
            {!!paginaFacebookId && !todosForms &&
                <Col xs="12" md="12">
                    {isLoadingForm ? <Skeleton /> : <>
                        <p className="heading-small text-muted mb-2">
                            <small>Formulário(s) Facebook</small>
                        </p>
                        <Form.Item className="my-2" name="formulariosFacebookId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Informe um formulário do facebook',
                                },
                            ]}>
                            <Select
                                disabled={statusFacebook === 'disconnected'}
                                mode='multiple'
                                showSearch
                                filterOption={filterOption}
                                placeholder=" Informe um formulário do facebook"
                                onChange={loadFields}
                                allowClear>
                                {forms && forms?.length > 0 && forms.map(item => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>}
                </Col>}
            {!!paginaFacebookId && !!todosForms &&
                <Col xs="12" md="12">
                    {isLoadingForm ? <Skeleton /> : <>
                        <p className="heading-small text-muted mb-2">
                            <InfoCircleOutlined size={8} color='red' style={{ color: 'red' }}/>
                            <small className="pl-2" style={{ color: 'red' }}>Ignorar Formulário(s) Facebook</small>
                        </p>
                        <Form.Item className="my-2" name="formFacebookDesconsiderarId">
                            <Select
                                disabled={statusFacebook === 'disconnected'}
                                mode='multiple'
                                showSearch
                                filterOption={filterOption}
                                placeholder=" Informe formulário(s) do facebook para ignorar"
                                onChange={(arrayId, arrayObj) => {
                                    setFinalValues({
                                        ...finalValues,
                                        ...{
                                            formFacebookDesconsiderarDesc: arrayObj?.map(c => {
                                                return { formId: c.key, desc: c.children }
                                            })
                                        }
                                    });
                                }}
                                allowClear>
                                {forms && forms?.length > 0 && forms.map(item => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div>
                            <InfoCircleOutlined size={8} color='red' style={{ color: 'red' }} />
                            <small className="font-weight-bold pl-2" style={{ color: 'red' }}>Atenção: os formulários selecionados acima serão ignorados na distribuição. Deixe o campo vazio caso não deseje ignorar formulários.</small>
                        </div>
                    </>}
                </Col>}
        </>}
        <AntdModal
            centered
            open={showConfirmModal === 'C' || showConfirmModal === 'D'}
            closable={false}
            onOk={handleConfirmClose}
            onCancel={() => setShowConfirmModal('')}
            okType="danger"
            okText="Sim">
            <p>
                Deseja realmente desconectar/reconectar do Facebook?<br />Os mapeamentos de campos terão que ser refeitos.
            </p>
        </AntdModal>
    </>;
}

export default FacebookParam;