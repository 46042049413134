const getTiposMidia = (idMidia) => {
  switch (idMidia) {
    case 1:
      return 'Whatsapp';
    case 2:
      return 'Ligação';
    case 3:
      return 'Email';
    case 4:
      return 'Parceria';
    case 5:
      return 'Oferta Ativa';
    case 100:
      return 'Grupo Zap';
    case 101:
      return 'OLX';
    case 102:
      return 'Imovel Web';
    case 103:
      return 'Site Próprio';
    case 104:
      return 'Mooving';
    case 105:
      return 'Mercado Livre';
    case 106:
      return 'Casa Mineira';
    case 107:
      return 'Facebook';
    case 108:
      return 'Instagram';
    case 109:
      return 'Carteira Própria';
    case 110:
      return 'Placa';
    case 112:
      return 'Chave na mao';
    case 113:
      return 'ADS WhatsApp';
    case 114:
      return 'ADS Facebook';
    case 119:
      return 'ADS Facebook - Equipe';
    case 120:
      return 'Google ADS';
    case 121:
      return 'Tiktok ADS';
    case 122:
      return 'Viva Real';
    case 123:
      return 'Rise Sales I.A.';
    case 124:
      return 'Desconhecido';
    case 125:
      return 'PDV"';
    case 126:
      return 'Ação Externa"';
    case 127:
      return 'Plantão Imobiliária';
    case 128:
      return 'Plantão Construtora';
    case 129:
      return 'Indicacão';
    case 130:
      return 'Relacionamento';
    case 131:
      return 'Evento';
    case 132:
      return 'Rodada de Negócios"';
    default:
      return '';
  }
};

export default getTiposMidia;
