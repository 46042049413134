import React, {useState, useEffect, Fragment, memo} from 'react';
import {Form, Input, Select, DatePicker, Divider, Table, Tag} from 'antd';
import {Row, Col, CardHeader} from 'reactstrap';
import {Icon} from 'semantic-ui-react';
import {mask, unMask} from 'remask';

import '~/assets/css/preview.css';

import url from '~/infra/urls';
import api from '~/services/api';

import * as JSZip from 'jszip';
import {saveAs} from 'file-saver';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
// import history from '~/services/history';

import pt from '~/infra/resources/strings/pt';

import enumSituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';
import enumSituacaoFinanceiraImovel from '~/infra/resources/strings/enums/situacaoFinanceiraImovel';
import enumSituacaoDocumentalImovel from '~/infra/resources/strings/enums/situacaoDocumentalImovel';
import enumPadraoSocialImovel from '~/infra/resources/strings/enums/padraoSocialImovel';
import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import enumBenfeitoriasImovel from '~/infra/resources/strings/enums/benfeitoriasImovel';

import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import pattern from '~/infra/resources/pattern/mask';
// import { CheckProfile } from '~/shared/checkProfile';

import 'moment/locale/pt-br';
import moment from 'moment';
import useConfigSistema from '~/actions/Empresa/useConfigSistema';
import formatCurrency from '~/shared/formatCurrency';
import SkeletonButton from 'antd/lib/skeleton/Button';
import {GoogleMaps} from '~/components/GoogleMaps';
moment.locale('pt-BR');

const {Option} = Select;
const {TextArea} = Input;

let dataCelular;
let dataTelefone;

const columns = [
  {
    title: 'Tipo',
    dataIndex: 'tipo',
    key: 'tipo',
  },
  {
    title: 'Área',
    dataIndex: 'area',
    key: 'area',
    render: (row) => `${Math.ceil(row)}m²`,
  },
  {
    title: 'Estacionamento',
    dataIndex: 'estacionamentos',
    key: 'estacionamentos',
  },
  {
    title: 'Quartos',
    dataIndex: 'quartos',
    key: 'quartos',
  },
  {
    title: 'Suites',
    dataIndex: 'suites',
    key: 'suites',
  },
  {
    title: 'Estoque',
    dataIndex: 'estoque',
    key: 'estoque',
  },
  {
    title: 'Valor',
    dataIndex: 'preco',
    key: 'preco',
    render: (row) => `R$ ${formatCurrency(row)}`,
  },
];

function CadastroGeralPreview({
  dataImages,
  dataFinalidades,
  dataTipoImovel,
  dataCondominio,
  dataLogradouros,
  dataUnidadeMedida,
  initialValues,
  setImovelId: setImovelIdAba,
  onEditarClick,
  identificador,
}) {
  const empresaId = localStorage.getItem('icaseEmpresaId');
  const currentUser = JSON.parse(localStorage.getItem('icaseUser')) ?? null;
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  // const {isDiretor, isGerente} = useRoles();
  const {getValueNumberConfig} = useConfigSistema(
    'EXIBIR_ENDERECO_REDE_LATITUDE',
  );
  // const isCaptador = () => {
  //   return (
  //     initialValues.corretorCaptacaoId === currentUser?.id ||
  //     initialValues.corretorCaptacao2?.id === currentUser?.id
  //   );
  // };

  const canDownloadImage = () => {
    return (
      currentUser?.empresaId === initialValues?.corretorCaptacao?.empresaId
    );
  };

  const canViewEndereco = () => {
    if (currentUser.empresaId === initialValues?.corretorCaptacao?.empresaId)
      return true;
    return getValueNumberConfig('EXIBIR_ENDERECO_REDE_LATITUDE') === 1;
  };

  const isMaster =
    roles && !!roles.find((perfil) => perfil.toUpperCase() === 'MASTER');
  const isMasterLead = JSON.parse(localStorage.getItem('icaseMasterLeads'));
  const isCorretor =
    roles && !!roles.find((perfil) => perfil.toUpperCase() === 'CORRETOR');

  const {actions} = getActionsMenus(identificador);

  const isRoledToEdit = !!actions?.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );

  const isRoledToEditOtherUser = !!actions?.find(
    (action) => action.id === '5022d55b-05a8-4dd8-85df-9e105ba545e1',
  );

  // TODO VERIFICAR QUE SEMPRE RETORNA hasManager === true
  // const encryptedProfiles = localStorage.getItem('latitudeProfiles');
  // let hasManager = CheckProfile.getProfiles(encryptedProfiles);

  const fileList = dataImages || [];
  const [visibleForm, setVisibleForm] = useState(false);
  const [condition, setCondition] = useState('');
  const [finalidade, setFinalidade] = useState('');
  const [showExternalCode, setShowExternalCode] = useState(false);
  const [showUrlExternal, setShowUrlExternal] = useState(null);
  const [situacaoFinanceira, setSituacaoFinanceira] = useState(null);
  const [loadingEndereco, setLoadingEndereco] = useState(false);
  const [codeOrulo, setCodeOrulo] = useState(null);
  const [commercialContact, setCommercialContact] = useState(null);
  const [isLoadingContatoComercial, setLoadingContatoComercial] =
    useState(false);
  const [redirectUri, setRedirectUri] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
    if (authorizationCode) {
      setCodeOrulo(authorizationCode);
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('code');
      window.history.replaceState({}, document.title, newUrl.toString());
      setRedirectUri(
        process.env.NODE_ENV === 'development'
          ? 'urn:ietf:wg:oauth:2.0:oob'
          : `${window.location.origin}${window.location.pathname}`,
      );
    }
  }, []);

  useEffect(() => {
    setLoadingEndereco(true);

    dataTelefone = initialValues?.corretorCaptacao?.telefones.find(
      (telefone) => telefone.tipo === 1,
    );
    dataCelular = initialValues?.corretorCaptacao?.telefones.find(
      (telefone) => telefone.tipo === 2,
    );

    if (initialValues?.finalidade) {
      setFinalidade(initialValues.finalidade.toLowerCase());
    }

    setShowExternalCode(initialValues.codigoExterno);
    setShowUrlExternal(initialValues.url);

    if (initialValues?.tipoImovel) {
      setCondition(initialValues.tipoImovel.toLowerCase());
    }

    if (initialValues?.situacaoFinanceira) {
      setSituacaoFinanceira(initialValues.situacaoFinanceira);
    }

    setVisibleForm(true);

    setTimeout(() => {
      setLoadingEndereco(false);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleMask(e, type) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (type === pattern.cep) form.setFieldsValue({cep: value});
  }

  function voltar() {
    window.close();
  }

  async function abrirContatoComercial() {
    if (!codeOrulo) {
      const redirect_uri =
        process.env.NODE_ENV === 'development'
          ? 'urn:ietf:wg:oauth:2.0:oob'
          : `${window.location.origin}${window.location.pathname}`;
      setRedirectUri(redirect_uri);
      const authorizationUrl = `https://www.orulo.com.br/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID_ORULO}&redirect_uri=${redirect_uri}&response_type=code`;
      window.location.href = authorizationUrl;

      await handleTokenExchange();
    } else {
      await handleTokenExchange();
    }
  }

  const obterDadosContatoComercial = async (token) => {
    const codigoImovel = initialValues?.codigoExterno.split('.')[1];
    const responseImovel = await fetch(
      `https://www.orulo.com.br/api/v2/buildings/${codigoImovel}`,
      {headers: {Authorization: `Bearer ${token}`}},
    );
    const imovel = await responseImovel.json();
    const idCommercialContact = imovel.commercial_contacts[0].id;
    const responseCommercialContact = await fetch(
      `https://www.orulo.com.br/api/v2/buildings/${codigoImovel}/commercial_contacts/${idCommercialContact}`,
      {headers: {Authorization: `Bearer ${token}`}},
    );
    const commercialContact = await responseCommercialContact.json();
    setCommercialContact(commercialContact);
  };

  const handleTokenExchange = async () => {
    try {
      setLoadingContatoComercial(true);
      const response = await fetch('https://www.orulo.com.br/oauth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID_ORULO,
          client_secret: process.env.REACT_APP_CLIENT_SECRET_ORULO,
          code: codeOrulo,
          redirect_uri: redirectUri,
          grant_type: 'authorization_code',
        }),
      });
      const data = await response.json();
      await obterDadosContatoComercial(data.access_token);
      setLoadingContatoComercial(false);
    } catch (error) {
      setLoadingContatoComercial(false);
      console.error('Erro ao trocar código por token:', error);
    }
  };

  function baixarImagens() {
    const zip = new JSZip();
    var count = 0;
    var erro = false;
    fileList.forEach(async (file) => {
      if (erro) return;

      const urlImagem = file.url ? file.url : file.arquivo;
      await api
        .get(url.SERVICES.GERA_BASE64_IMAGEM(urlImagem))
        .then(async (res) => {
          const imageBase64 = res.data.base64.split('base64,')[1];
          zip.file(count + '.jpg', imageBase64, {base64: true});
          ++count;
          if (count === fileList.length) {
            zip
              .generateAsync({
                type: 'blob',
              })
              .then(function (content) {
                saveAs(content, `imovel_${initialValues.id}.zip`);
              });
          }
        })
        .catch((error) => {
          if (!erro)
            Notify(
              'error',
              pt.comum.atencao,
              'Não foi possível baixar as imagens',
            );
          erro = true;
        });
    });
  }

  const isEmpty = !fileList.length;

  const hasImmobileBroker =
    !!(
      initialValues.corretorCaptacaoId ===
      localStorage.getItem('icaseUsuarioId')
    ) && isCorretor;

  // TODO VERIFICAR PORQUE SEMPRE RETORNA hasManager === true
  // let isAutorizeToEdit = false;
  // if (hasManager === true) {
  //   isAutorizeToEdit = true;
  // } else if (hasImmobileBroker === true) {
  //   isAutorizeToEdit = true;
  // }

  const exibeBotaoEditar =
    (isMaster ||
      isMasterLead ||
      (isRoledToEdit && hasImmobileBroker) ||
      (isRoledToEditOtherUser && !hasImmobileBroker)) &&
    empresaId === initialValues.empresaId;

  let numberSpan = 0;

  return (
    <div className="px-4 pb-4">
      <Row>
        <Col>
          {!visibleForm && <SkeletonTableBasic cardShadowless />}
          {visibleForm && (
            <Form form={form} initialValues={initialValues} scrollToFirstError>
              <Row>
                {exibeBotaoEditar && (
                  <Col xs="12" md="3">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      className="mt-1"
                      fluid
                      color="violet">
                      <Icon name="edit" />
                      Editar Cadastro
                    </Button>
                  </Col>
                )}
                <Col xs="12" md="3">
                  {!isEmpty && canDownloadImage() && (
                    <Button
                      onClick={baixarImagens}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-1"
                      isloading={'false'}
                      loadtext="Baixando...">
                      <Icon name="download" />
                      Baixar Imagens
                    </Button>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Divider />
                  <CardHeader className="pl-0 mt-3 mb-4">
                    <h5 className="title">Dados Gerais</h5>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <Form.Item
                    label="Código do Imóvel:"
                    name="codImovel"
                    style={{marginBottom: 0}}
                    className="description-preview-imoveis">
                    <Input bordered={false} disabled />
                  </Form.Item>
                </Col>
                {showExternalCode && (
                  <Col xs="12" m="4">
                    {showUrlExternal ? (
                      <a
                        href={initialValues.url}
                        target="_blank"
                        rel="noopener noreferrer">
                        {initialValues.codigoExterno}
                      </a>
                    ) : (
                      <Form.Item name="codigoExterno">
                        <Input
                          bordered={false}
                          disabled
                          className="description-preview-input"
                        />
                      </Form.Item>
                    )}
                  </Col>
                )}
                <Col xs="12" md="4">
                  <Form.Item
                    label="Tipo do Imóvel"
                    name="tipoImovelId"
                    style={{marginBottom: 0}}
                    className="description-preview-imoveis">
                    <Select
                      defaultOpen
                      className="description-preview-input"
                      showArrow={false}
                      bordered={false}
                      disabled>
                      {dataTipoImovel.map((item) => (
                        <Option key={item.id}>{item.descricao}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <Form.Item
                    label="Finalidade"
                    name="finalidadeId"
                    style={{marginBottom: 0}}
                    className="description-preview-imoveis">
                    <Select showArrow={false} bordered={false} disabled>
                      {dataFinalidades.map((item) => (
                        <Option key={item.id}>{item.nome}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs="12" md="4">
                  <Form.Item
                    className="description-preview-imoveis"
                    label="Situação do Imóvel"
                    style={{marginBottom: 0}}
                    name="situacao">
                    {initialValues?.lancamento ? (
                      <span className="description-preview-input">{`${initialValues?.lancamento?.estagioObra}`}</span>
                    ) : (
                      <Select
                        defaultOpen
                        bordered={false}
                        showArrow={false}
                        disabled>
                        {enumSituacaoImovel.map((item) => (
                          <Option key={item.id}>{item.nome}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="4">
                  <Form.Item
                    className="description-preview-imoveis"
                    label="Operação Imobiliária"
                    style={{marginBottom: 0}}
                    name="operacaoImobiliaria">
                    <Select
                      defaultOpen
                      showArrow={false}
                      bordered={false}
                      disabled>
                      {enumOperacaoImobiliaria.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {initialValues?.lancamento ? null : (
                  <Col xs="12" md="4">
                    <Form.Item
                      label="Email do Captador"
                      className="description-preview-imoveis"
                      style={{marginBottom: 0}}>
                      <Input
                        disabled
                        bordered={false}
                        value={initialValues?.corretorCaptacao?.email}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row>
                <Col xs="12" md="4">
                  {initialValues?.lancamento ? null : (
                    <Form.Item
                      label="Nome do Captador"
                      className="description-preview-imoveis"
                      style={{marginBottom: 0}}>
                      <Input
                        disabled
                        bordered={false}
                        value={initialValues?.corretorCaptacao?.nome}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col xs="12" md="4">
                  {!initialValues?.lancamento ? (
                    <Form.Item
                      label="Telefone do Captador"
                      className="description-preview-imoveis"
                      style={{marginBottom: 0}}>
                      <Input
                        disabled
                        bordered={false}
                        value={
                          dataCelular?.numero
                            ? mask(dataCelular?.numero, [pattern.celular])
                            : dataTelefone?.numero
                            ? mask(dataTelefone?.numero, [pattern.celular])
                            : ''
                        }
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              <Row>
                {initialValues?.valorVenda && (
                  <Col xs="12" md="4">
                    <Form.Item
                      label="Valor do imóvel:"
                      style={{marginBottom: 0}}
                      className="description-preview-imoveis">
                      <span style={{fontWeight: 'lighter'}}>
                        R$ {formatCurrency(initialValues.valorVenda)}
                      </span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues?.valorCondominio && (
                  <Col xs="12" md="4">
                    <Form.Item
                      label="Valor do condomínio:"
                      style={{marginBottom: 0}}
                      className="description-preview-imoveis">
                      <span style={{fontWeight: 'lighter'}}>
                        R$ {formatCurrency(initialValues.valorCondominio)}
                      </span>
                    </Form.Item>
                  </Col>
                )}
                {initialValues?.valorIptu && (
                  <Col xs="12" md="4">
                    <Form.Item
                      label="Valor do IPTU:"
                      style={{marginBottom: 0}}
                      className="description-preview-imoveis">
                      <span style={{fontWeight: 'lighter'}}>
                        R$ {formatCurrency(initialValues.valorIptu)}
                      </span>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="12" md="4">
                  {isLoadingContatoComercial && (
                    <SkeletonButton
                      active={isLoadingContatoComercial}></SkeletonButton>
                  )}
                  {!isLoadingContatoComercial && initialValues?.lancamento ? (
                    !commercialContact ? (
                      <Button
                        size="small"
                        className="mt-1"
                        onClick={abrirContatoComercial}>
                        {codeOrulo ? 'Visualizar Dados' : 'Contato Comercial'}
                      </Button>
                    ) : null
                  ) : null}
                </Col>
              </Row>
              {commercialContact ? (
                <Row>
                  <Col xs="12" md="3">
                    <Form.Item
                      className="description-preview-imoveis"
                      label="Contato Comercial"
                      style={{marginBottom: 0}}
                      name="contatoComercial">
                      <span className="description-preview-input">{`${commercialContact?.name}`}</span>
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="3">
                    <Form.Item
                      className="description-preview-imoveis"
                      label="E-mail do Contato Comercial"
                      style={{marginBottom: 0}}
                      name="emailContatoComercial">
                      <span className="description-preview-input">{`${commercialContact?.emails[0]}`}</span>
                    </Form.Item>
                    3
                  </Col>
                  <Col xs="12" md="3">
                    <Form.Item
                      className="description-preview-imoveis"
                      label="Telefone"
                      style={{marginBottom: 0}}
                      name="telefoneContatoComercial">
                      <span className="description-preview-input">
                        {commercialContact?.phones.map((p) => (
                          <Tag>{p}</Tag>
                        ))}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="3">
                    <Form.Item
                      className="description-preview-imoveis"
                      label="Whatsapp"
                      style={{marginBottom: 0}}
                      name="whatsappContatoComercial">
                      <span className="description-preview-input">
                        {commercialContact?.whatsapps.map((p) => (
                          <Tag>{p}</Tag>
                        ))}
                      </span>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Data de Criação"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}>
                    <DatePicker
                      disabled
                      bordered={false}
                      format={'DD/MM/YYYY HH:mm'}
                      defaultValue={initialValues?.dataCriacao}
                      suffixIcon={null}
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    className="description-preview-imoveis"
                    label="Criado Por"
                    style={{marginBottom: 0}}
                    name="emailContatoComercial">
                    <span className="description-preview-input">
                      {initialValues?.usuarioInclusao?.nome}
                    </span>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Data de Alteração"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}>
                    {initialValues?.dataAtualizacao ? (
                      <DatePicker
                        disabled
                        bordered={false}
                        format={'DD/MM/YYYY HH:mm'}
                        defaultValue={initialValues?.dataAtualizacao}
                        suffixIcon={null}
                      />
                    ) : (
                      <span className="description-preview-input">
                        Sem alteração
                      </span>
                    )}
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    className="description-preview-imoveis"
                    label="Alterador Por"
                    style={{marginBottom: 0}}
                    name="emailContatoComercial">
                    <span className="description-preview-input">
                      {initialValues?.usuarioAlteracao?.nome}
                    </span>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Divider />
                  <CardHeader className="pl-0 mb-4">
                    <h5 className="title">Endereço/Localização</h5>
                  </CardHeader>
                </Col>
              </Row>
              {loadingEndereco && <SkeletonTableBasic cardShadowless />}
              {!loadingEndereco && (
                <Fragment>
                  <Row>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Logradouro"
                        name="logradouroId"
                        style={{marginBottom: 0}}>
                        <Select
                          defaultOpen
                          disabled
                          showArrow={false}
                          bordered={false}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (option.children && option.children.length > 0) {
                              return (
                                option.children
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .indexOf(
                                    input
                                      .toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, ''),
                                  ) >= 0
                              );
                            }
                          }}>
                          {dataLogradouros.map((item) => (
                            <Option key={item.id}>{item.nome}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="País"
                        name="pais"
                        style={{marginBottom: 0}}>
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="CEP"
                        name="cep"
                        style={{marginBottom: 0}}>
                        <Input
                          disabled
                          bordered={false}
                          maxLength={10}
                          onKeyUp={(e) => handleMask(e, pattern.cep)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Complemento"
                        name="complemento"
                        style={{marginBottom: 0}}>
                        {canViewEndereco() && (
                          <Input
                            disabled
                            className="description-preview-input"
                            bordered={false}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Tipo de Logradouro"
                        name="tipoLogradouro"
                        style={{marginBottom: 0}}>
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Número"
                        name="numero"
                        style={{marginBottom: 0}}>
                        {canViewEndereco() && (
                          <Input
                            className="description-preview-input"
                            disabled
                            bordered={false}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Bairro"
                        name="bairro"
                        style={{marginBottom: 0}}>
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Bairro Comercial"
                        name="bairroComercialNome"
                        style={{marginBottom: 0}}>
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Lote/Apto"
                        name="loteApto"
                        style={{marginBottom: 0}}>
                        {canViewEndereco() && (
                          <Input
                            disabled
                            className="description-preview-input"
                            bordered={false}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Cidade"
                        name="cidade"
                        style={{marginBottom: 0}}>
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>

                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Quadra/Bloco"
                        name="quadraBloco"
                        style={{marginBottom: 0}}>
                        {canViewEndereco() && (
                          <Input
                            disabled
                            bordered={false}
                            className="description-preview-input"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Estado"
                        name="estado"
                        style={{marginBottom: 0}}>
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col xs="12">
                      <Form.Item
                        className="description-preview-imoveis"
                        label="Condomínio/Empreendimento"
                        name="condominioId"
                        style={{marginTop: 0}}>
                        {initialValues?.lancamento ? (
                          <span className="description-preview-input">{`${initialValues?.condominio} - ${initialValues?.logradouro} - ${initialValues?.cidade} - ${initialValues?.estado}`}</span>
                        ) : (
                          <Select
                            showArrow={false}
                            disabled
                            bordered={false}
                            className="description-preview-input">
                            {dataCondominio.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {initialValues?.lancamento
                                  ? `${item.nome} - ${item.construtora}`
                                  : `${item.nome} - ${item?.logradouro?.bairro?.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <GoogleMaps
                        latitude={initialValues.latitude ?? 0}
                        longitude={initialValues.longitude ?? 0}
                        editable={false}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
              <Row>
                <Col>
                  <Divider />
                  <CardHeader className="pl-0 mb-4">
                    <h5 className="title">Dados do Imóvel</h5>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12">
                  <Form.Item
                    className="description-preview-imoveis"
                    label="Título Amigável"
                    name="tituloAmigavel"
                    style={{marginBottom: 0}}>
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Área Total"
                    className="description-preview-imoveis"
                    name="areaTerreno"
                    style={{marginBottom: 0}}>
                    <span className="description-preview-input">
                      {initialValues?.lancamento
                        ? `${Math.ceil(
                            initialValues?.lancamento?.areaMinima,
                          )} a ${Math.ceil(
                            initialValues?.lancamento?.areaMaxima,
                          )}`
                        : `${
                            initialValues?.areaTerreno
                              ? initialValues?.areaTerreno
                              : 0
                          }
                      ${
                        initialValues?.areaTerrenoUnidadeMedida
                          ? initialValues?.areaTerrenoUnidadeMedida
                          : ''
                      }`}
                    </span>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Aceita Permuta"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}
                    name="aceitaPermuta">
                    <Select showArrow={false} disabled bordered={false}>
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Situação Documental"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}
                    name="situacaoDocumental">
                    <Select showArrow={false} disabled bordered={false}>
                      {enumSituacaoDocumentalImovel.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Área Privada"
                    className="description-preview-imoveis"
                    name="areaConstruida"
                    style={{marginBottom: 0}}>
                    <span className="description-preview-input">
                      {`${
                        initialValues?.areaConstruida
                          ? initialValues?.areaConstruida
                          : 0
                      }
                      ${
                        initialValues?.areaConstruidaUnidadeMedida
                          ? initialValues?.areaConstruidaUnidadeMedida
                          : ''
                      }`}
                    </span>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Situação Financeira"
                    className="description-preview-imoveis"
                    name="situacaoFinanceira"
                    style={{marginBottom: 0}}>
                    <Select disabled showArrow={false} bordered={false}>
                      {enumSituacaoFinanceiraImovel.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Padrão Social"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}
                    name="padraoSocial">
                    <Select showArrow={false} disabled bordered={false}>
                      {enumPadraoSocialImovel.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Posição do Sol"
                    className="description-preview-imoveis"
                    name="porDoSol"
                    style={{marginBottom: 0}}>
                    <Select
                      className="description-preview-input"
                      showArrow={false}
                      disabled
                      bordered={false}>
                      <Option value="M">Manhã</Option>
                      <Option value="T">Tarde</Option>
                      <Option value="I">Intermediário</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    style={{marginBottom: 0}}
                    label="Andar Térreo"
                    className="description-preview-imoveis"
                    name="andarTerreo">
                    <Select
                      showArrow={false}
                      className="description-preview-input"
                      disabled
                      bordered={false}>
                      <Option value="S">Sim</Option>
                      <Option value="N">Não</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Qtd. Torres"
                    className="description-preview-imoveis"
                    name="quantidadeTorres"
                    style={{marginBottom: 0}}>
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="2">
                  <Form.Item
                    label="Qtd. Andares"
                    style={{marginBottom: 0}}
                    className="description-preview-imoveis"
                    name="qtdAndares">
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
                <Col xs="12" md="2">
                  <Form.Item
                    label="Nº Andar"
                    style={{marginBottom: 0}}
                    className="description-preview-imoveis"
                    name="nroAndar">
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
                <Col xs="12" md="2">
                  <Form.Item
                    style={{marginBottom: 0}}
                    label="Apto. p/ Andar"
                    className="description-preview-imoveis"
                    name="apartamentosPorAndar">
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    style={{marginBottom: 0}}
                    label="Elevador(es)"
                    className="description-preview-imoveis"
                    name="possuiElevador">
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Garagem Descoberta(s)"
                    className="description-preview-imoveis"
                    name="vagasGaragemDescobertas"
                    style={{marginBottom: 0}}>
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Garagem Coberta(s)"
                    className="description-preview-imoveis"
                    name="vagasGaragem"
                    style={{marginBottom: 0}}>
                    <Input disabled bordered={false} />
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Total Garagem(ns)"
                    className="description-preview-imoveis"
                    name="totalGaragens"
                    style={{marginBottom: 0}}>
                    {initialValues?.lancamento ? (
                      <span className="description-preview-input">
                        {initialValues?.lancamento?.quantidadeVagasMinimo} a{' '}
                        {initialValues?.lancamento?.quantidadeVagasMaximo}
                      </span>
                    ) : (
                      <Input disabled bordered={false} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Suítes"
                    style={{marginBottom: 0}}
                    className="description-preview-imoveis"
                    name="quantidadeSuites">
                    {initialValues?.lancamento ? (
                      <span className="description-preview-input">
                        {initialValues?.lancamento?.quantidadeSuiteMinima} a{' '}
                        {initialValues?.lancamento?.quantidadeSuiteMaxima}
                      </span>
                    ) : (
                      <Input disabled bordered={false} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    label="Quarto(s)"
                    className="description-preview-imoveis"
                    name="quantidadeQuartos"
                    style={{marginBottom: 0}}>
                    {initialValues?.lancamento ? (
                      <span className="description-preview-input">
                        {initialValues?.lancamento?.quantidadeQuartoMinimo} a{' '}
                        {initialValues?.lancamento?.quantidadeQuartoMaximo}
                      </span>
                    ) : (
                      <Input disabled bordered={false} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs="12" md="3">
                  <Form.Item
                    style={{marginBottom: 0}}
                    label="Banheiros"
                    className="description-preview-imoveis"
                    name="quantidadeBanheiros">
                    {initialValues?.lancamento ? (
                      <span className="description-preview-input">
                        {initialValues?.lancamento?.quantidadeBanheiroMinimo} a{' '}
                        {initialValues?.lancamento?.quantidadeBanheiroMaximo}
                      </span>
                    ) : (
                      <Input disabled bordered={false} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <Form.Item
                    label={
                      initialValues?.lancamento
                        ? 'Data Lançamento'
                        : 'Ano Construção'
                    }
                    className="description-preview-imoveis"
                    name="anoConstrucao"
                    style={{marginBottom: 0}}>
                    {initialValues?.lancamento ? (
                      <span className="description-preview-input">
                        {new Date(
                          initialValues?.lancamento?.dataLancamento,
                        ).toLocaleDateString('pt-br')}
                      </span>
                    ) : (
                      <Input disabled bordered={false} />
                    )}
                  </Form.Item>
                </Col>
                {initialValues?.lancamento ? (
                  <Col xs="12" md="3">
                    <Form.Item
                      label="Data Entrega"
                      className="description-preview-imoveis"
                      style={{marginBottom: 0}}>
                      <span className="description-preview-input">
                        {new Date(
                          initialValues?.lancamento?.dataEntrega,
                        ).toLocaleDateString('pt-br')}
                      </span>
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xs="12" md="3">
                  <Form.Item
                    label="Captador"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}>
                    <Input
                      disabled
                      bordered={false}
                      value={initialValues?.corretorCaptacao2?.nome}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {situacaoFinanceira === 2 && (
                <Row>
                  <Col xs="12" md="3">
                    <Form.Item
                      style={{marginBottom: 0}}
                      label="Valor da Prestação"
                      className="description-preview-imoveis"
                      name="prestacaoValor">
                      <span className="description-preview-input">
                        {initialValues?.prestacaoValor
                          ? `R$ ${initialValues?.prestacaoValor}`
                          : ''}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="3">
                    <Form.Item
                      style={{marginBottom: 0}}
                      label="Prestações Faltantes"
                      className="description-preview-imoveis"
                      name="prestacoesFaltantes">
                      <Input disabled bordered={false} />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="3">
                    <Form.Item
                      style={{marginBottom: 0}}
                      label="Saldo Devedor"
                      className="description-preview-imoveis"
                      name="saldoDevedor">
                      <span className="description-preview-input">
                        {initialValues?.saldoDevedor
                          ? `R$ ${initialValues?.saldoDevedor}`
                          : ''}
                      </span>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row className="mb-2">
                <Col xs="12">
                  <Form.Item
                    label="Benfeitorias/Benefícios"
                    className="description-preview-imoveis mb-0"
                    name="beneficios"></Form.Item>
                </Col>
                {enumBenfeitoriasImovel.map((item) => {
                  const index = initialValues?.beneficios?.findIndex(
                    (beneficio) => beneficio === item.value,
                  );
                  if (index > -1) numberSpan++;
                  return index > -1 ? (
                    <Col key={item.value} xs="12" md="2">
                      <span className="description-preview-input">
                        {`${numberSpan}: ${item.label}`}
                      </span>
                    </Col>
                  ) : null;
                })}
              </Row>
              <Row>
                <Col xs="12" md="12">
                  <Form.Item
                    label="Procedimentos de Visita"
                    className="description-preview-imoveis"
                    style={{marginBottom: 0}}
                    name="procedimentoVisita">
                    <TextArea disabled bordered={false} autoSize />
                  </Form.Item>
                </Col>
              </Row>
              {initialValues.lancamento ? (
                <Row>
                  <Col xs="12" md="12">
                    <Form.Item
                      label="Tags"
                      className="description-preview-imoveis"
                      style={{marginBottom: 0}}
                      name="procedimentoVisita">
                      {initialValues?.lancamento?.tags?.split(',').map((t) => (
                        <Tag>{t}</Tag>
                      ))}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col xs="12" md="12">
                  <Form.Item
                    className="description-preview-imoveis"
                    name="caracteristicas"
                    label="Site - Característica descritivas">
                    <TextArea disabled bordered={false} autoSize />
                  </Form.Item>
                </Col>
              </Row>
              {initialValues.lancamento ? (
                <Row>
                  <Col xs="12" md="12">
                    <Table
                      rowKey={(row) => row.id}
                      columns={columns}
                      dataSource={initialValues?.lancamento?.tipologias}
                    />
                  </Col>
                </Row>
              ) : null}
              {condition &&
                finalidade &&
                (finalidade.includes('rural') ||
                  finalidade.includes('comercial')) && (
                  <Row>
                    <Col xs="12" md="12">
                      <Form.Item
                        label="Negócio indicado"
                        className="my-2"
                        name="negocioIndicado">
                        <Input disabled bordered={false} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </Form>
          )}
          <Row>
            <Col xs="12" md="3">
              <Button
                icon
                labelPosition="left"
                size="medium"
                fluid
                className="mt-3"
                onClick={voltar}>
                <Icon name="undo" />
                Voltar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default memo(CadastroGeralPreview);
