import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import filter from '~/infra/resources/pattern/filter';

export default function useTipoAtendimento(
  current,
  pageSize,
  nomeTipoAtendimento
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    // const tamanhoPagina = pageSize ? pageSize : 10;
    const conditions = [];

    if (nomeTipoAtendimento)
      conditions.push({
        columnName: 'descricao',
        type: filter.type.Varchar,
        operator: filter.operator.LikeInitialMatch,
        value: nomeTipoAtendimento,
      });

    conditions.push({
      columnName: 'excluido',
      type: filter.type.Boolean,
      operator: filter.operator.Equal,
      value: 'false',
    });

    try {
      await api
        .post(url.TIPO_ATENDIMENTO.FILTER(), { conditions })
        .then((res) => {
          setData(res.data || []);
          setQuantidade(res.data.length);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [nomeTipoAtendimento]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
