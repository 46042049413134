import { useCallback, useEffect, useState } from "react";
import api from '~/services/api';
import { parseToQueryString } from "~/shared/parseToQueryString";
import moment from 'moment';
moment.locale('pt-BR');

export default function useDashVendas(corretorId, dateMonth) {
  const usuarioId = localStorage.getItem('icaseUsuarioId');

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    resultLocacao: 0,
    resultVenda: 0,
    resultComissao: 0
  });
  const onLoad = useCallback(
    async () => {
      const filtro = parseToQueryString({
        corretorId: corretorId ?? usuarioId,
        dataIni: dateMonth[0] ? moment(dateMonth[0], 'MM/YYYY').date(1).format('YYYY/MM/DD') : null,
        dataFim: dateMonth[1] ? moment(dateMonth[1], 'MM/YYYY').endOf('month').format('YYYY/MM/DD') : null
      });
      try {
        await api.get(`Dashboard/total-por-tipo?${filtro}`).then((res) => {
          setData({
            resultLocacao: res?.data?.find(t => t.tipo === 1)?.valorTotal,
            resultVenda: res?.data?.find(t => t.tipo === 2)?.valorTotal,
            resultComissao: res?.data?.find(t => t.tipo === 3)?.valorTotal
          });
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [corretorId,dateMonth],
  );

  useEffect(() => {
    onLoad();
  }, [corretorId, dateMonth, onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  }
}
