import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap';
import { DatePicker, Dropdown, Form, Image, Input, Menu, Radio, Space } from 'antd';
import { Icon } from 'semantic-ui-react';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import imgImovel from '~/assets/img/sala.jpg';

import Button from '~/shared/Button';
import formatedReal from '~/shared/formatedReal';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import ModalGenerico from '~/components/ModalGenerico';
import Pessoa from '~/views/private/Cadastros/Pessoa/Manutencao';
import ModalConfirm from '~/components/ModalConfirm';
import { CloseCircleOutlined, UndoOutlined } from '@ant-design/icons';
moment.locale('pt-BR');


const dateFormat = 'DD/MM/YYYY';

const TYPEOFREFUSE = {
  REINICIAR: 'reiniciar',
  ENCERRAR: 'encerrar',
};

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
  VISUALIZAR: 'visualizar',
};

const statusProposta = [
  {
    id: 1,
    name: 'Proposta',
  },
  {
    id: 2,
    name: 'Proposta Aceita',
  },
  {
    id: 3,
    name: 'Proposta Recusada',
  },
  {
    id: 4,
    name: 'Proposta Cancelada',
  },
];

/* STATUS DA PROPOSTA
  1 - PROPOSTA
  2 - PROPOSTA ACEITA
  3 - PROPOSTA RECUSADA
  4 - PROPOSTA CANCELADA
*/

function Proposta({
  modo,
  propostaId,
  setShowButtonProposta,
  refreshStatusAtendimento,
  fetchRelacionamentos,
  closeModal,
  atendimentoId,
  imovelId,
  imovel,
  cliente,
  onSubmit,
  isUpdating = false,
  setIsAtendimentoFinalizado,
  showFecharNegocio = false,
  showRecusar = false
}) {

  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [modoPessoa, setModoPessoa] = useState('edit');
  const [openModalPessoa, setOpenModalPessoa] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [tipoDoNegocioRecusado, setTipoDoNegocioRecusado] = useState(
    TYPEOFREFUSE.REINICIAR,
  );
  const [openModalNegocioFechado, setOpenModalNegocioFechado] = useState(false);
  const [openModalNegocioRecusado, setOpenModalNegocioRecusado] = useState(false);

  const [form] = Form.useForm();


  const menuItems = [
    {
      label: 'Reiniciar atendimento',
      key: 1,
      icon: <UndoOutlined />,
      onClick: () => {
        setOpenModalNegocioRecusado(true);
        setTipoDoNegocioRecusado(TYPEOFREFUSE.REINICIAR);
      },
    },
    {
      label: 'Encerrar atendimento',
      key: 2,
      icon: <CloseCircleOutlined />,
      onClick: () => {
        setOpenModalNegocioRecusado(true);
        setTipoDoNegocioRecusado(TYPEOFREFUSE.ENCERRAR);
      },
    },
  ];

  async function handleNegocioFechado() {
    setLoadingSave(true);

    await api
      .patch(url.ATENDIMENTO.FECHAR_NEGOCIO(atendimentoId))
      .then(() => {
        setLoadingSave(false);
        setIsAtendimentoFinalizado(true);
        setOpenModalNegocioFechado(false);
        Notify(
          'success',
          pt.comum.atencao,
          'Atendimento finalizado com sucesso!',
        );

        onSubmit();
      })
      .catch((err) => {
        setLoadingSave(false);
        setOpenModalNegocioFechado(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  async function handleNegocioRecusado() {
    setLoadingSave(true);

    if (tipoDoNegocioRecusado === TYPEOFREFUSE.REINICIAR) {
      await api
        .patch(url.ATENDIMENTO.REINICIAR_ATENDIMENTO(atendimentoId))
        .then(() => {
          form.setFieldsValue({ situacao: 1, status: 2 });
          setShowButtonProposta(true);
          setOpenModalNegocioRecusado(false);
          Notify(
            'success',
            pt.comum.atencao,
            'Atendimento reiniciado com sucesso!',
          );
          
          onSubmit();
        })
        .catch((err) => {
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
        
    }

    if (tipoDoNegocioRecusado === TYPEOFREFUSE.ENCERRAR) {
      await api
        .patch(url.ATENDIMENTO.ENCERRAR_ATENDIMENTO(atendimentoId))
        .then(() => {
          form.setFieldsValue({ situacao: 2, status: 3 });
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
          setIsAtendimentoFinalizado(true);
          Notify(
            'success',
            pt.comum.atencao,
            'Atendimento finalizado com sucesso!',
          );

          onSubmit();
        })
        .catch((err) => {
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }

  function handleOpenModalNegocioFechado(event) {
    event.preventDefault();
    setOpenModalNegocioFechado(true);
  }


  useEffect(() => {
    if (propostaId) {
      fetch();
    };
    async function fetch() {
      setLoadingData(true);

      await api.get(url.ATENDIMENTO.CONSULTA_PROPOSTA(propostaId))
        .then(res => {
          const response = res?.data;
          const valorComissao = formatedReal(response?.imovel?.comissaoVenda?.toFixed(2)?.toString())?.replace('R$', '')?.trim();
          const dataProposta = response.dataProposta ? moment(response.dataProposta.toString()) : moment();

          form.setFieldsValue({
            valorProposta: formatedReal(response.valorProposta.toFixed(2).toString())
              .replace('R$', '')
              .trim(),
            valorComissao,
            tipoProposta: response?.tipoProposta === 2 ? 'V' : 'L',
            status: statusProposta.find(item => item.id === response?.status)?.name || '',
            descricao: response?.descricao,
            dataProposta,
          });

          setShowStatus(response?.status !== 1);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propostaId]);

  function handleEditClient(event) {
    if (event)
      event.preventDefault();
    setModoPessoa('editar');
    setOpenModalPessoa(true);
  }

  async function onSaveProposta() {
    setLoading(true);
    form
      .validateFields()
      .then(async dados => {
        let payload = {
          atendimentoId,
          imovelId,
          valorProposta:
            Number(
              dados.valorProposta.replaceAll('.', '').replace(',', '.'),
            ).toFixed(2),
          valorComissao:
            Number(
              dados.valorComissao.replaceAll('.', '').replace(',', '.'),
            ).toFixed(2),
          descricao: dados.descricao,
          dataProposta: dados.dataProposta,
          status: 1, // PROPOSTA
          tipoProposta: dados.tipoProposta === 'V' ? 2 : 1,
        };

        if (isUpdating) {
          payload = {
            ...payload,
            id: propostaId,
          };
        }

        await api({
          method: isUpdating ? 'PUT' : 'POST',
          url: isUpdating ? url.ATENDIMENTO.EDITAR_PROPOSTA() : url.ATENDIMENTO.PROPOSTA(),
          data: payload,
        }).then(async (dataReponse) => {
          if (fetchRelacionamentos)
            await fetchRelacionamentos(atendimentoId, imovelId);

          if (refreshStatusAtendimento) refreshStatusAtendimento(10);
          if (setShowButtonProposta) setShowButtonProposta(false);

          setLoading(false);
          form.resetFields();

          onSubmit(dataReponse?.data?.data?.id);
        })
          .catch(err => {
            setLoading(false);
            const { error } = err.response.data;

            var fixCliente = false;

            if (error) {
              for (const item in error) {

                if (error[item].includes('não informado'))
                  fixCliente = true;

                Notify('error', pt.comum.atencao, error[item]);
              }
            }

            if (fixCliente)
              handleEditClient();
          })
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function handleMaskCurrency(e, field) {
    const maskedValue = formatedReal(e.target.value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.'),
    ).toFixed(2);
    form.setFieldsValue({ [field]: numberValue > 0 ? maskedValue : undefined });
  }

  return (
    <Fragment>
      {loadingData && (
        <Row className="mx-1 mt-4">
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
        </Row>
      )}
      {!loadingData && (
        <Fragment>
          <Form form={form} initialValues={{
            valorProposta:
              imovel?.valorVenda
                ? formatedReal(imovel?.valorVenda.toFixed(2).toString())
                  .replace('R$', '')
                  .trim()
                : imovel?.valorAluguel
                  ? formatedReal(imovel?.valorAluguel.toFixed(2).toString())
                    .replace('R$', '')
                    .trim() : undefined,
            valorComissao:
              imovel?.comissaoVenda
                ? formatedReal(imovel?.comissaoVenda.toFixed(2).toString())
                  .replace('R$', '')
                  .trim() : undefined,
            tipoProposta:
              imovel?.operacaoImobiliaria === 1
                ? 'L' :
                imovel?.operacaoImobiliaria === 2
                  ? 'V' : undefined
          }}>
            <Row>
              <Col>
                <p className="heading-small text-muted mb-2">
                  <small>Imóvel</small>
                </p>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {!!imovel?.imagens && !!imovel?.imagens[0] ? <Image
                    style={{
                      minWidth: 100,
                      maxWidth: 100,
                      minHeight: 70,
                      maxHeight: 70,
                    }}
                    preview={false}
                    src={imovel?.imagens[0]?.arquivo}
                  /> : <Image
                    style={{
                      minWidth: 100,
                      maxWidth: 100,
                      minHeight: 70,
                      maxHeight: 70,
                    }}
                    preview={false}
                    src={imgImovel}
                  />}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{imovel?.endereco?.condominio
                      ? imovel?.endereco?.condominio?.nome
                      : imovel?.tituloAmigavel}</span>
                    <span>
                      <b>
                        {imovel?.valorVenda
                          ? formatedReal(imovel?.valorVenda?.toFixed(2)?.toString())?.replace('R$', '')?.trim()
                          : imovel?.valorAluguel
                            ? formatedReal(imovel?.valorAluguel?.toFixed(2)?.toString())?.replace('R$', '')?.trim()
                            : 'R$ 0,00'}
                      </b>
                    </span>
                    <span>
                      Código: <b>{imovel?.codImovel}</b>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col xs="12" md="3" className="my-2">
                <p className="heading-small text-muted mb-2">
                  <small>Cliente</small>
                </p>
                <span className="content-imovel-text">
                  {cliente?.nome || (
                    <small>&nbsp;</small>
                  )}
                </span>
              </Col>
              <Col xs="12" md="3" className="my-2">
                <Button
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  className="mt-1"
                  onClick={(event) => handleEditClient(event)}>
                  <Icon name="user" />
                  Alterar cliente
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="2" className="my-2">
                <p className="heading-small text-muted mb-2">
                  <small>Data da Proposta</small>
                </p>
                <Form.Item
                  className="my-2"
                  initialValue={moment()}
                  name="dataProposta"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a data da proposta',
                    },
                  ]}>
                  <DatePicker
                    disabled={modo === MODO.VISUALIZAR}
                    locale={locale}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder=" Data da Proposta"
                  />
                </Form.Item>
              </Col>
              <Col xs="12" md="3" className="my-2">
                <p className="heading-small text-muted mb-2">
                  <small>Valor Proposta</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="valorProposta"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o valor da proposta.',
                    },
                  ]}>
                  <Input
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Valor da proposta"
                    onChange={(e) =>
                      handleMaskCurrency(e, 'valorProposta')
                    }
                    prefix="R$"
                  />
                </Form.Item>
              </Col>
              <Col xs="12" md="3" className="my-2">
                <p className="heading-small text-muted mb-2">
                  <small>Valor Comissão</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="valorComissao"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o valor da comissão.',
                    },
                  ]}>
                  <Input
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Valor da comissão"
                    onChange={(e) =>
                      handleMaskCurrency(e, 'valorComissao')
                    }
                    prefix="R$"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Item
                  className="my-2"
                  name="tipoProposta"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o tipo da proposta',
                    },
                  ]}>
                  <Radio.Group disabled={imovel?.operacaoImobiliaria !== 3 || modo === MODO.VISUALIZAR} >
                    <Space direction='horizontal'>
                      <Radio value="V">Venda</Radio>
                      <Radio value="L">Locação</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {showStatus && (
              <Row>
                <Col xs="12">
                  <p className="heading-small text-muted mb-2">
                    <small>Status</small>
                  </p>
                  <Form.Item className="my-2" name="status">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="12">
                <p className="heading-small text-muted mb-2">
                  <small>Descrição</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="descricao"
                  rules={[
                    {
                      required: false,
                      message: 'Favor informar a descrição.',
                    },
                  ]}>
                  <Input.TextArea
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Descrição"
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                    maxLength={1000}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row className="pt-3">
            {modo !== MODO.VISUALIZAR && (
              <>
                <Col xs="12" md="3">
                  <Button
                    className="mt-1"
                    onClick={(event) => {
                      event.preventDefault();
                      onSaveProposta();
                    }}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    color="violet"
                    isloading={loading.toString()}
                    loadtext={isUpdating ? 'Atualizando...' : "Enviando..."}>
                    <Icon name="send" />
                    {isUpdating ? 'Atualizar' : 'Enviar'}
                  </Button>
                </Col>
                {!!showFecharNegocio && <Col xs="12" md="3">
                  <Button
                    className="mt-1"
                    onClick={(event) =>
                      handleOpenModalNegocioFechado(event)
                    }
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    color="green">
                    <Icon name="gavel" />
                    Negócio fechado
                  </Button>
                </Col>}
                {!!showRecusar && <Col xs="12" md="3">
                  <Dropdown
                    overlay={<Menu items={menuItems} />}
                    placement="top">
                    <Button
                      className="mt-1"
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      color="orange">
                      <Icon name="trash" />
                      Recusado
                    </Button>
                  </Dropdown>
                </Col>}
              </>
            )}
            <Col xs="12" md="3">
              <Button
                className="mt-1"
                onClick={(event) => {
                  event.preventDefault();
                  closeModal();
                }}
                icon
                labelPosition="left"
                size="medium"
                fluid>
                <Icon name="undo" />
                Voltar
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
      <ModalGenerico
        open={openModalPessoa}
        close={() => setOpenModalPessoa(false)}
        title={'Pessoa'}>
        <Pessoa
          match={{ params: { modo: modoPessoa } }}
          location={{ state: { row: { id: cliente?.id } } }}
          close={() => setOpenModalPessoa(false)}
          isAtendimento={false}
          showAdvanceData={true}
        />
      </ModalGenerico>

      <ModalConfirm
        isDelete
        modalType="update"
        icon="warning sign"
        loading={loadingSave}
        loadingText="Prosseguir"
        open={openModalNegocioFechado}
        buttonColor="violet"
        buttonText="Prosseguir"
        confirm={handleNegocioFechado}
        close={() => {
          setLoadingSave(false);
          setOpenModalNegocioFechado(false);
        }}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>Tem certeza que gostaria de finalizar esse atendimento?</span>
            <br />
            <span>
              Após finalizar o atendimento não será mais possível alterá-lo!
            </span>
          </Fragment>
        }
      />

      <ModalConfirm
        isDelete
        modalType="update"
        icon="warning sign"
        loading={loadingSave}
        loadingText="Prosseguir"
        open={openModalNegocioRecusado}
        buttonColor="violet"
        buttonText="Prosseguir"
        confirm={handleNegocioRecusado}
        close={() => {
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
        }}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>
              {tipoDoNegocioRecusado === TYPEOFREFUSE.REINICIAR
                ? 'Tem certeza que gostaria de reiniciar o atendimento?'
                : 'Tem certeza que gostaria de finalizar esse atendimento?'}
            </span>
            {tipoDoNegocioRecusado === TYPEOFREFUSE.ENCERRAR && (
              <Fragment>
                <br />
                <span>
                  Após finalizar o atendimento não será mais possível alterá-lo!
                </span>
              </Fragment>
            )}
          </Fragment>
        }
      />

    </Fragment>
  )
}

export default Proposta;
