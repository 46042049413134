import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useBairroImovel() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async (params) => {
    if (params) {
      let payload = '';
      let index = 1;

      for (const [key, value] of Object.entries(params)) {
        if (index > 1) {
          payload += '&'
        }

        if (value?.length > 0) {
          if (key === 'cidadeId') {
            for (const cityIndex in value) {
              const cityValue = value[cityIndex];

              if (cityIndex > 0) {
                payload += '&';
              }

              payload += `${key}=${cityValue}`;
            }
          } else {
            payload += `${key}=${value}`;
          }
        } else if (!isNaN(parseFloat(value)) && isFinite(value)) {
          payload += `${key}=${value}`;
        }

        index++;
      }

      try {
        const data = await api.get(`${url.DROPDOWN.BAIRRO_IMOVEL()}?${payload}`);
        setData(data.data || []);
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
