import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

export default function useDashResumoNegocios(corretorId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      const roles = JSON.parse(localStorage.getItem('icaseRoles'));
      const master = roles.find((x) => x.toUpperCase() === 'MASTER');
      const masterLeads =
        localStorage.getItem('icaseMasterLeads') === 'false' ? false : true;
      const usuarioId = localStorage.getItem('icaseUsuarioId');
      let filtro;

      if (!params) {
        if (master || masterLeads)
          filtro = corretorId ? `?corretorId=${corretorId}` : '';
        else filtro = `?corretorId=${usuarioId}`;
      } else filtro = `?corretorId=${params}`;

      try {
        await api.get(`Dashboard/resumo-negocios${filtro}`).then((res) => {
          setData(res.data || []);
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [corretorId],
  );

  useEffect(() => {
    onLoad(corretorId);
  }, [corretorId, onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
