import React, { Fragment, useState, useEffect } from 'react';
import { Table, Image } from 'antd';

import imgImovel from '~/assets/img/sala.jpg';

import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import formatCurrency from '~/shared/formatCurrency';

function HistoricoImoveis({ atendimentoId }) {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  async function fetch(id) {
    setLoading(true);
    await api.get(url.ATENDIMENTO.CONSULTA_HIST_IMOVEL(id))
      .then(res => {
        setHistoryData(res.data || []);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  useEffect(() => {
    fetch(atendimentoId);
  }, [atendimentoId]);

  const renderImage = (row) => {
    const indexDestaque = row?.imovel?.imagens?.length > 0
      ? row?.imovel?.imagens?.findIndex(image => image.destaque === true)
      : -1

    return (
      <Fragment>
        {indexDestaque > -1 && (
          <Image
            className='fix-dimension-image-imovel'
            preview={false}
            src={row?.imovel?.imagens[indexDestaque]?.arquivo} />
        )}
        {indexDestaque < 0 && (
          <Image
            className='fix-dimension-image-imovel'
            preview={false}
            src={imgImovel} />
        )}
      </Fragment>
    );
  }

  const renderAdress = (row) => {
    let adress = '';

    adress += row?.imovel?.logradouro?.nome || '';
    adress += row?.imovel?.endereco?.numero ? `, ${row?.imovel?.endereco?.numero} - ` : ' - ';
    adress += row?.imovel?.logradouro?.bairro ? `${row?.imovel?.logradouro?.bairro?.nome} - ` : ' - ';
    adress += row?.imovel?.logradouro?.bairro?.cidade ? `${row?.imovel?.logradouro?.bairro?.cidade?.nome} - ` : ' - ';
    adress += row?.imovel?.logradouro?.bairro?.cidade ? `${row?.imovel?.logradouro?.bairro?.cidade?.estadoId}` : '';

    return (
      <span>{adress}</span>
    );
  }

  const columns = [
    {
      key: '1',
      title: '',
      width: 100,
      render: (row) => renderImage(row)
    },
    {
      key: '2',
      title: 'Nome',
      render: (row) => <span>{row?.imovel?.tituloAmigavel}</span>
    },
    {
      key: '3',
      title: 'Tipo',
      render: (row) => <span>{row?.imovel?.tipoImovel?.descricao}</span>
    },
    {
      key: '4',
      title: 'Valor',
      className: 'text-right',
      render: (row) => <span style={{ whiteSpace: 'nowrap' }}>{
        row?.imovel?.valorVenda
          ? `R$ ${formatCurrency(row?.imovel?.valorVenda)}`
          : row?.imovel?.valorAluguel
            ? `R$ ${formatCurrency(row?.imovel?.valorAluguel)}`
            : 'R$ 0,00'
      }</span>
    },
    {
      key: '5',
      title: 'Endereço',
      render: (row) => renderAdress(row)
    },
  ];

  return (
    <Fragment>
      <Table
        loading={loading}
        dataSource={historyData}
        columns={columns}
        bordered={false}
        pagination={{ position: ['none', 'none'] }}
      />
    </Fragment>
  );
};

export default HistoricoImoveis;
