import {fakerPT_BR as faker} from '@faker-js/faker';

export const mainPeriodOptions = [
  {
    label: 'Últimos 15 dias',
    value: '15d',
    onclick: () => {
      alert('Teste de botão.');
    },
  },
  {label: 'Últimos 30 dias', value: '30d'},
];

export const compareWithOptions = [
  {label: 'Período anterior', value: 'previous'},
  {label: 'Mesmo período do ano anterior', value: 'same'},
];

export function generateFakeRank(numItems) {
  const itemList = [];

  for (let i = 0; i < numItems; i++) {
    const item = {
      nomeCorretor: faker.person.fullName(),
      quantidadeOfertasAtivas: faker.number.int({min: 1, max: 200}),
    };

    itemList.push(item);
  }

  return itemList.sort(
    (a, b) => b.quantidadeOfertasAtivas - a.quantidadeOfertasAtivas,
  );
}

export function generateFakeFunnel(numItems) {
  const itemList = [];
  const labels = [
    'LEAD NA BASE',
    'EM TENTATIVA',
    'EM ATENDIMENTO',
    'EM VISITA',
    'EM PROPOSTA',
    'FECHADO',
  ];
  for (let i = 0; i < numItems; i++) {
    const item = {
      categoria: labels[i],
      quantidade: faker.number.int({min: 1, max: 200}),
      valor: Number(faker.finance.amount()),
    };

    itemList.push(item);
  }

  return itemList.sort((a, b) => b.valor - a.valor);
}

export function generateQualityOfService(numItems) {
  const itemList = [];

  for (let i = 0; i < numItems; i++) {
    const item = {
      nomeCorretor: faker.person.fullName(),
      irregulares: faker.number.int({min: 1, max: 60}),
      regulares: faker.number.int({min: 1, max: 60}),
    };

    itemList.push(item);
  }

  return itemList.sort((a, b) => b.valor - a.valor);
}

export function generateDaysWithoutSelling(numItems) {
  // nomeCorretor: string;
  // dias: number;
  // meta: number;

  const itemList = [];

  for (let i = 0; i < numItems; i++) {
    const days = faker.number.int({min: 1, max: 60});

    const item = {
      nomeCorretor: faker.person.fullName(),
      dias: [days],
      meta: faker.number.int({min: 1, max: 60}),
      ranges: [30, 90, 120],
    };

    itemList.push(item);
  }

  return itemList.sort((a, b) => a.dias[0] - b.dias[0]);
}

export function generateLeadOrigin(numItems) {
  // origem: string;
  // valor: number;

  const itemList = [];
  const origins = [
    'Grupo Zap',
    'Site Próprio',
    'Parceria',
    'Placa',
    'Mercado Livre',
    'Oferta Ativa',
    'Email',
    'Instagram',
    'Facebook',
    'ADS Facebook',
    'Carteira Própria',
  ];

  for (let i = 0; i < numItems; i++) {
    const item = {
      origem: origins[i],
      valor: faker.number.int({min: 1, max: 100}),
    };

    itemList.push(item);
  }

  return itemList.sort((a, b) => b.valor - a.valor);
}

export function generateRealEstate(numItems) {
  // operacao: string;
  // quantidade: number;
  // vgvPotencial: number;

  const itemList = [];

  const operations = ['Venda', 'Locação', 'Venda / Locação'];

  for (let i = 0; i < numItems; i++) {
    const item = {
      operacao: operations[i],
      quantidade: faker.number.int({min: 1, max: 100}),
      vgvPotencial: faker.finance.amount({min: 0, max: 1000000}),
    };

    itemList.push(item);
  }
  return itemList;
}

export function generateRankingCaptors(numItems) {
  // nomeCaptador: string;
  // vendaCaptada: number;
  // locacaoCaptada: number;

  const itemList = [];

  for (let i = 0; i < numItems; i++) {
    const item = {
      nomeCaptador: faker.person.fullName(),
      vendaCaptada: faker.number.int({min: 1, max: 10}),
      locacaoCaptada: faker.number.int({min: 1, max: 10}),
    };

    itemList.push({
      nomeCaptador: item.nomeCaptador,
      tipo: 'Venda',
      valor: item.vendaCaptada,
    });

    itemList.push({
      nomeCaptador: item.nomeCaptador,
      tipo: 'Locação',
      valor: item.locacaoCaptada,
    });

    // Define um valor total entre venda + locacao afim de ordenar a lista
    itemList.forEach((current) => {
      if (current.nomeCaptador === item.nomeCaptador && !current.total) {
        current.total = Number(item.vendaCaptada + item.locacaoCaptada);
      }
    });
  }

  return itemList.sort((a, b) => b.total - a.total);
}

export function generateBirthdayOfTheDay(numItems) {
  // nomeAniversariante: string;
  // idade: number;
  // telefone: string;

  const itemList = [];

  for (let i = 0; i < numItems; i++) {
    const item = {
      nomeAniversariante: faker.person.fullName(),
      idade: faker.number.int({min: 1, max: 65}),
      telefone: faker.phone.number(),
    };

    itemList.push(item);
  }
  return itemList;
}
