import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import { Form, Select, Input, DatePicker, TimePicker } from 'antd';
import { Icon } from 'semantic-ui-react';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';

import Button from '~/shared/Button';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
moment.locale('pt-BR');

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
  VISUALIZAR: 'visualizar',
};

const dateFormat = 'DD/MM/YYYY';
const hourFormat = 'HH:mm:ss';

function AgendarVisita({
  modo,
  interesseId,
  closeModal,
  refreshStatusAtendimento,
  fetchRelacionamentos,
  isChangeSituacaoAtendimento = false,
  onFiltros,
  setShowButtonVisita,
  atendimentoId,
  imovelId,
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (interesseId) fetch();

    async function fetch() {
      setLoadingData(true);

      await api.get(url.ATENDIMENTO.CONSULTA_INTERESSE(interesseId))
        .then(res => {
          const response = res?.data;

          const horaInicio = moment(response?.dataHoraVisita, 'YYYY-MM-DDTHH:mm:ss');
          const horaFim = moment(response?.dataHoraVisitaFim, 'YYYY-MM-DDTHH:mm:ss');

          form.setFieldsValue({
            dataVisita: response?.dataHoraVisita
              ? moment(response?.dataHoraVisita, 'YYYY-MM-DD')
              : undefined,
            horaInicio: response?.dataHoraVisita
              ? moment(horaInicio, hourFormat)
              : undefined,
            horaFim: response?.dataHoraVisitaFim
              ? moment(horaFim, hourFormat)
              : undefined,
            saidaChave: response?.saidaChave,
            prazoChave: response?.prazoDevolucaoChave > 0
              ? response?.prazoDevolucaoChave : undefined,
            descricao: response?.descricaoDocumento,
          });
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interesseId]);

  async function onSaveAgendarVisita() {
    setLoading(true);

    form
      .validateFields()
      .then(async dados => {
        const payload = {
          atendimentoId,
          tipoAtendimento: dados?.tipoAtendimento,
          dataHoraVisita:
            moment(dados.dataVisita, 'YYYY-MM-DDTHH:mm:ss').set({
              day: dados.dataVisita.get('day'),
              month: dados.dataVisita.get('month'),
              year: dados.dataVisita.get('year'),
              hour: dados.horaInicio.get('hour'),
              minute: dados.horaInicio.get('minute'),
              second: dados.horaInicio.get('second'),
            }) || null,
          dataHoraVisitaFim:
            moment(dados.dataVisita, 'YYYY-MM-DDTHH:mm:ss').set({
              day: dados.dataVisita.get('day'),
              month: dados.dataVisita.get('month'),
              year: dados.dataVisita.get('year'),
              hour: dados.horaFim.get('hour'),
              minute: dados.horaFim.get('minute'),
              second: dados.horaFim.get('second'),
            }) || null,
          descricaoDocumento: dados?.descricao,
          imovelId,
          acompanhado: true,
          saidaChave: dados?.saidaChave,
          concluido: true,
          prazoDevolucaoChave: dados?.prazoChave,
        };

        await api
          .post(url.ATENDIMENTO.INTERESSE(), payload)
          .then(async () => {
            if (fetchRelacionamentos)
              await fetchRelacionamentos(atendimentoId, imovelId);

            if (refreshStatusAtendimento) refreshStatusAtendimento(9); // EM VISITA

            if (isChangeSituacaoAtendimento && onFiltros) onFiltros();

            if (setShowButtonVisita) setShowButtonVisita(false);

            setLoading(false);
            form.resetFields();
            closeModal();
          })
          .catch((err) => {
            setLoading(false);

            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <Fragment>
      {loadingData && (
        <Row className="mx-1 mt-4">
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
        </Row>
      )}
      {!loadingData && (
        <Fragment>
          <Form form={form} initialValues={{ saidaChave: false }}>
            <Row>
              <Col xs="12" md="3">
                <p className="heading-small text-muted mb-2">
                  <small>Data da visita</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="dataVisita"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a data da visita.',
                    },
                  ]}>
                  <DatePicker
                    disabled={modo === MODO.VISUALIZAR}
                    locale={locale}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder=" Data da visita"
                  />
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Horário início</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="horaInicio"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o horário início.',
                    },
                  ]}>
                  <TimePicker
                    format={hourFormat}
                    disabled={modo === MODO.VISUALIZAR}
                    style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Horário do fim</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="horaFim"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o horário início.',
                    },
                  ]}>
                  <TimePicker
                    format={hourFormat}
                    disabled={modo === MODO.VISUALIZAR}
                    style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col xs="12">
                <Form.Item className="my-0" name="emailCliente">
                  <Checkbox disabled={modo === MODO.VISUALIZAR}>Enviar e-mail para o cliente com os dados da visita</Checkbox>
                </Form.Item>
              </Col>
              <Col xs="12">
                <Form.Item className="my-0" name="emailCorretor">
                  <Checkbox disabled={modo === MODO.VISUALIZAR}>Enviar cópia do e-mail para o corretor</Checkbox>
                </Form.Item>
              </Col>
              <Col xs="12">
                <Form.Item className="my-0" name="emailProprietario">
                  <Checkbox disabled={modo === MODO.VISUALIZAR}>Enviar e-mail para o proprietário da visita</Checkbox>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col xs="12" md="4">
                <p className="heading-small text-muted mb-2">
                  <small>Saída da chave?</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="saidaChave"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a saída da chave.',
                    },
                  ]}>
                  <Select
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Selecione">
                    <Option value={true}>Sim</Option>
                    <Option value={false}>Não</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs="12" md="4">
                <p className="heading-small text-muted mb-2">
                  <small>Prazo para retorno da chave</small>
                </p>
                <Form.Item className="my-2" name="prazoChave">
                  <Select
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Selecione">
                    <Option value={1}>01 horas</Option>
                    <Option value={2}>02 horas</Option>
                    <Option value={3}>03 horas</Option>
                    <Option value={4}>04 horas</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <p className="heading-small text-muted mb-2">
                  <small>Descrição</small>
                </p>
                <Form.Item className="my-2" name="descricao">
                  <Input.TextArea
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Descrição"
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                    maxLength={1000}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row className="pt-3">
            {modo !== MODO.VISUALIZAR && (
              <Col xs="12" md="4">
                <Button
                  className="mt-1"
                  onClick={(event) => {
                    event.preventDefault();
                    onSaveAgendarVisita();
                  }}
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  color="violet"
                  isloading={loading.toString()}
                  loadtext="Enviando...">
                  <Icon name="send" />
                  Enviar
                </Button>
              </Col>
            )}
            <Col xs="12" md="4">
              <Button
                className="mt-1"
                onClick={(event) => {
                  event.preventDefault();
                  closeModal();
                }}
                icon
                labelPosition="left"
                size="medium"
                fluid>
                <Icon name="undo" />
                Voltar
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}

export default AgendarVisita;
