import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Switch, Form, Input, Select } from 'antd';
import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';
import useMenu from '~/actions/Menu/useMenu';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const { Option } = Select;

function Menu(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [menuId, setMenuId] = useState(null);
  const [menuPaiId, setMenuPaiId] = useState(null);
  const [form] = Form.useForm();
  const { isLoading, data: menuLista } = useMenu();

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const editData = props.location.state.row;

        setInitialValues(editData);
        setModo(MODO.EDITAR);
        setMenuId(props.location.state.row.id);
        setMenuPaiId(props.location.state.row.menuPaiId);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function voltar() {
    history.push('/app/menu');
  }

  async function onChangeMenuPai(menuPaiId) {
    setMenuPaiId(menuPaiId);
  }

  function onSave() {
    setIsLoading(true);
    form.validateFields().then(async (dados) => {
      if (modo === MODO.CADASTRAR) {
        const payload = {
          descricao: dados.descricao,
          primario: !menuPaiId ? true : false,
          menuPaiId: menuPaiId,
          componente: dados.componente,
          url: dados.url,
          urlExterna: dados.urlExterna,
          novaAba: dados.novaAba,
          icone: dados.icone,
          ordem: dados.ordem,
        };

        await api
          .post(url.MENU.CADASTRAR(), payload)
          .then((res) => {
            setIsLoading(false);
            Notify('success', '', pt.geral.cadastro_com_sucesso);
            history.push('/app/menu');
          })
          .catch((err) => {
            setIsLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      } else if (modo === MODO.EDITAR) {
        const payload = {
          id: menuId,
          descricao: dados.descricao,
          primario: !menuPaiId ? true : false,
          menuPaiId: menuPaiId,
          componente: dados.componente,
          url: dados.url,
          urlExterna: dados.urlExterna,
          novaAba: dados.novaAba,
          icone: dados.icone,
          ordem: dados.ordem,
        };

        await api
          .put(url.MENU.EDITAR(), payload)
          .then((res) => {
            setIsLoading(false);
            Notify('success', '', pt.geral.atualizado_com_sucesso);
            history.push('/app/menu');
          })
          .catch((err) => {
            setIsLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      }
    });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage && !isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? 'Cadastrar menu'
                    : 'Atualizar menu'}
                </h5>
                <p className="category">Manutenção de menu</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Descrição</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="descricao"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a descrição!',
                                },
                              ]}>
                              <Input placeholder=" Descrição" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Menu Pai</small>
                            </p>
                            <Form.Item className="my-2" name="menuPaiId">
                              <Select
                                showSearch
                                onChange={onChangeMenuPai}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder=" Menu Pai">
                                {menuLista.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.descricao}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>Componente</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="componente"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o componente!',
                                },
                              ]}>
                              <Input placeholder=" Componente" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>URL</small>
                            </p>
                            <Form.Item className="my-2" name="url">
                              <Input placeholder=" URL" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>URL Externa</small>
                            </p>
                            <Form.Item className="my-2" name="urlExterna">
                              <Input placeholder=" URL Externa" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-0">
                              <small>Abrir em nova aba?</small>
                            </p>
                            <Form.Item valuePropName="checked" name="novaAba">
                              <Switch
                                checkedChildren="Sim"
                                unCheckedChildren="Não"
                                size="small"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Icone</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="icone"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o icone!',
                                },
                              ]}>
                              <Input placeholder=" Icone" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Ordem</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="ordem"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a ordem!',
                                },
                              ]}>
                              <Input type="number" placeholder=" Ordem" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row>
                        <Col xs="12" md="4">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR
                                ? 'Cadastrando...'
                                : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR
                              ? 'Cadastrar'
                              : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="4">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="reply" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Menu;
