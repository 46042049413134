import React, {Fragment} from 'react';
import useBirthdayOfTheDay from './use-birthday-of-the-day';

import {Card, Row, Col} from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';

import {Grid, GridItem} from './Grid';

export default function BirthdayOfTheDay() {
  const {data: birthdayList} = useBirthdayOfTheDay();

  const renderGrid = birthdayList
    .filter((person) => person?.idade > 0)
    .map((item, index) => (
      <Fragment key={index}>
        <GridItem key={index}>
          <span>{String(item.nomeAniversariante || '').toUpperCase()}</span>
        </GridItem>
        <GridItem>
          <span>{item.idade}</span>
        </GridItem>
        <GridItem>
          <span>{item.telefone}</span>
        </GridItem>
      </Fragment>
    ));

  return (
    <Card
      className="p-3"
      style={{
        height: birthdayList?.length > 0 ? 461 : 'fit-content',
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Aniversariantes do dia</CardTitle>
      {birthdayList?.length > 0 ? (
        <>
          <Row>
            <Col>
              <Grid>
                <GridItem>
                  <strong>Nome</strong>
                </GridItem>
                <GridItem>
                  <strong>Idade</strong>
                </GridItem>
                <GridItem>
                  <strong>Telefone</strong>
                </GridItem>
              </Grid>
            </Col>
          </Row>
          <Row
            style={{
              overflow: 'scroll',
              overflowX: 'hidden',
            }}>
            <Col>
              <Grid>{renderGrid}</Grid>
            </Col>
          </Row>
        </>
      ) : (
        <span>Nenhum aniversário encontrado.</span>
      )}
    </Card>
  );
}
