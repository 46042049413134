import {create} from 'zustand';

export const ModalType = {
  None: 'none',
  ListaOfertasDisponiveis: 'ofertas-ativas-disponiveis',
  VerOfertasAtivas: 'ver-ofertas-ativas',
  RealizarAtendimento: 'realizar-atendimento',
};

/*
 * Store to control the modal state of the OfertaAtivaCorretorWidget
 */

export default create((set, get) => ({
  modal: ModalType.None,
  ofertasAtivaSelecionada: null,
  atendimentoId: null,
  isModal(type) {
    return get().modal === type;
  },
  verOfertaAtiva: (ofertaAtivaSelecionada) => {
    if (!ofertaAtivaSelecionada) return;
    set({ modal: ModalType.VerOfertasAtivas, ofertaAtivaSelecionada });
  },
  listaOfertasAtivas: () => {
    set({ modal: ModalType.ListaOfertasDisponiveis, ofertaAtivaSelecionada: null });
  },
  closeModal: () => {
    set({ modal: ModalType.None, ofertasAtivaSelecionada: null , atendimentoId: null });
  },
  startAtendimento: (atendimentoId) => {
    if (!atendimentoId) return;
    if (!get().isModal(ModalType.VerOfertasAtivas)) return;
    set({ atendimentoId, modal: ModalType.RealizarAtendimento });
  },
}));
