const MotivoDesistenciaAtendimento = [
    { nome: 'Já comprou imóvel', id: 1 },
    { nome: 'Tentativas de contato sem sucesso', id: 2 },
    { nome: 'Não aprovou financiamento', id: 3 },
    { nome: 'Contato inexistente', id: 4 },
    { nome: 'Apenas pesquisando', id: 5 },
    { nome: 'Não gostou da localização', id: 6 },
    { nome: 'Não gostou do projeto', id: 7 },
    { nome: 'Achou caro', id: 8 },
    { nome: 'Corretor do Mercado', id: 9 },
    { nome: 'Lead duplicado', id: 10 },
  ];

export default MotivoDesistenciaAtendimento;