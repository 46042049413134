import React, { useState } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { Form, Input, Select } from 'antd';
import { Row, Col } from 'reactstrap';
import { pdf } from '@react-pdf/renderer';

import usePessoaDropdown from '~/actions/DropDown/usePessoa';

import ModalGenerico from '~/components/ModalGenerico';
import Pessoa from '~/views/private/Cadastros/Pessoa/Manutencao';

import Button from '~/shared/Button';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import chunckArray from '~/infra/resources/pattern/chunkArray';

import ApresentacaoImoveis from '../ApresentacaoImoveis';

const { Option } = Select;

const typesOfApresentation = {
  EMAIL: 1,
  WHATSAPP: 2,
  DOWNLOAD: 3,
};

function ModalSendApresentation({
  open,
  close,
  cleanSelectedRealState,
  realStateForPresentation,
  typeOfApresentation,
}) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [client, setClient] = useState({});
  const [openModalPessoa, setOpenModalPessoa] = useState(false);
  const [modoPessoa, setModoPessoa] = useState('cadastrar');

  const { data, onLoad } = usePessoaDropdown();

  const [form] = Form.useForm();

  const user = JSON.parse(localStorage.getItem('icaseUser'));
  const companyData = JSON.parse(localStorage.getItem('latitudeCompanyData'));

  function handleNewClient(event) {
    event.preventDefault();
    setModoPessoa('cadastrar');
    setOpenModalPessoa(true);
  }

  function closeModalPessoa() {
    const pessoaId = localStorage.getItem('icasePessoaId');
    if (pessoaId) {
      setOpenModalPessoa(false);
      onLoad({ pessoaId });
      form.setFieldsValue({ clientId: pessoaId });
      handleClient(pessoaId);

      localStorage.removeItem('icasePessoaId');
    }
  }

  async function handleClient(value) {
    setDisabled(true);
    setCheckEmail(false);
    form.validateFields();
    await api.get(url.PESSOA.CONSULTA_ID(value)).then((res) => {
      const data = res.data;
      setClient(data);
      form.setFieldsValue({ email: data?.email || data?.email2 || data?.email3 });
      setCheckEmail(!data?.email && !data?.email2 && !data?.email3);
      form.validateFields();
      setDisabled(false);
    });
  }

  function handleSendApresentation() {
    setLoading(true);
    form
      .validateFields()
      .then((dados) => {
        gerarApresentacaoImoveis(dados.message);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function sendEmail(blob, message) {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64 = reader.result;
      const payload = {
        clienteId: client.id,
        base64: base64.substring(28, base64.length),
        conteudo: message,
      };

      await api
        .post(url.IMOVEL.ENVIAR_EMAIL(), payload)
        .then(() => {
          Notify('success', '', 'Email enviado com sucesso!');
          closeModal();
        })
        .catch((err) => {
          Notify('error', '', err?.message);
          setLoading(false);
        });
    };
    reader.readAsDataURL(blob);
  }

  function sendWhatsAppMessage(corretor) {
    const mensagem =
      `${client?.nome},%0A%0AO corretor *${corretor?.nomeCompleto}* compartilhou com você alguns imóveis, confira o anexo.` +
      `%0A%0A${corretor?.nomeCompleto}%0A${corretor?.email}` +
      `%0A%0A*MY BROKER IMOVEIS LTDA*${companyData?.urlSite ? `%0A${companyData?.urlSite}` : ''
      }`;

    window.open(`https://wa.me/send?text=${mensagem}`);

    closeModal();
  }

  async function downloadApresentacao(blobUrl) {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', 'Apresentacao.pdf');
    aElement.href = blobUrl;
    aElement.setAttribute('target', '_blank');
    aElement.click();

    URL.revokeObjectURL(blobUrl);

    closeModal();
  }

  function closeModal() {
    form.resetFields();
    setLoading(false);
    cleanSelectedRealState();
    close();
  }

  async function handleCorretor(userId) {
    return await api.get(url.USUARIO.CONSULTA_BY_ID(userId)).catch(error => {
      console.log('Falha ao buscar os dados do corretor => ', error);
      Notify(
        'error',
        'Atenção',
        'Falha ao buscar os dados do corretor.',
      );
    });
  }

  function gerarApresentacaoImoveis(message) {
    const highlightCompare = chunckArray(3, realStateForPresentation);

    const gerarPdf = async () => {
      const { data: corretor } = await handleCorretor(user?.id);

      const blob = await pdf(
        <ApresentacaoImoveis
          cliente={client}
          corretor={corretor}
          imoveis={realStateForPresentation}
          highlightCompare={highlightCompare}
          message={message}
        />,
      ).toBlob();

      const pdfURL = URL.createObjectURL(blob);

      switch (typeOfApresentation) {
        case 1:
          sendEmail(blob, message);
          break;

        case 2:
          sendWhatsAppMessage(corretor[0]);
          downloadApresentacao(pdfURL);
          break;

        case 3:
          downloadApresentacao(pdfURL);
          break;

        default:
          Notify(
            'error',
            'Atenção',
            'Falha ao gerar o arquivo de apresentação.',
          );
          setLoading(false);
          break;
      }
    };

    gerarPdf();
  }

  return (
    <>
      <Modal size="small" open={open} onClose={() => close()}>
        <Modal.Header>
          <Row>
            <Col xs="12">
              <div>
                <h4 className="titleLogin">
                  {(typeOfApresentation === typesOfApresentation.EMAIL &&
                    'Enviar apresentação por email') ||
                    (typeOfApresentation === typesOfApresentation.WHATSAPP &&
                      'Enviar apresentação por whatsapp') ||
                    (typeOfApresentation === typesOfApresentation.DOWNLOAD &&
                      'Download da apresentação')}
                </h4>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row className="p-2">
            <Col>
              <Form form={form} scrollToFirstError>
                <Row>
                  <Col xs="12" md="9" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Cliente</small>
                    </p>
                    <Form.Item
                      className="my-2"
                      name="clientId"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o cliente',
                        },
                      ]}>
                      <Select
                        placeholder=" Cliente"
                        onChange={(value) => handleClient(value)}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .indexOf(
                              input
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, ''),
                            ) >= 0
                        }>
                        {data.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="3" className="my-2">
                    <p className="heading-small text-muted mb-1">
                      <small>&nbsp;</small>
                    </p>
                    <Button
                      onClick={(event) => handleNewClient(event)}
                      icon
                      labelPosition="left"
                      size="tiny"
                      fluid
                      color="violet">
                      <Icon name="plus" />
                      Novo Cliente
                    </Button>
                  </Col>
                </Row>

                {typeOfApresentation === typesOfApresentation.EMAIL && (
                  <Row>
                    <Col xs="12" md="12" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Email</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="email"
                        rules={[
                          {
                            required:
                              checkEmail &&
                              typeOfApresentation ===
                              typesOfApresentation.EMAIL,
                            message:
                              'Cliente selecionado não possui um email cadastrado',
                          },
                        ]}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs="12" md="12" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Mensagem</small>
                    </p>
                    <Form.Item
                      className="my-2"
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a mensagem',
                        },
                      ]}>
                      <Input.TextArea
                        maxLength={2000}
                        showCount
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" className="mt-4">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-2"
                      color="violet"
                      disabled={disabled}
                      isloading={loading.toString()}
                      onClick={() => handleSendApresentation()}
                      loadtext="Gerando PDF...">
                      <Icon
                        name={
                          typeOfApresentation === typesOfApresentation.EMAIL
                            ? 'send'
                            : typeOfApresentation ===
                              typesOfApresentation.WHATSAPP
                              ? 'whatsapp'
                              : 'download'
                        }
                      />
                      {typeOfApresentation === typesOfApresentation.EMAIL
                        ? 'Enviar email'
                        : typeOfApresentation === typesOfApresentation.WHATSAPP
                          ? 'Enviar mensagem'
                          : 'Download'}
                    </Button>
                  </Col>
                  <Col xs="12">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      onClick={close}>
                      <Icon name="undo" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal.Content>
      </Modal>

      <ModalGenerico
        open={openModalPessoa}
        close={() => closeModalPessoa()}
        title={'Pessoa'}>
        <Pessoa
          match={{ params: { modo: modoPessoa } }}
          // location={{ state: { row: { id: NovoClienteId } } }}
          close={() => closeModalPessoa()}
          isAtendimento
        />
      </ModalGenerico>
    </>
  );
}

export default ModalSendApresentation;

