import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Checkbox, Select } from 'antd';
import { Icon } from 'semantic-ui-react';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';

import api from '~/services/api';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import Button from '~/shared/Button';
import history from '~/services/history';

import useAcoes from '~/actions/Acoes/useAcoes';

const { Option } = Select;

const TABS = {
  PERFIL: 1,
  MENUS: 2,
  ACOES: 3,
};

const PerfilAcoes = ({ setActiveTab, perfilId, identificador }) => {
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [optionsLoad, setOptionsLoad] = useState(false);
  const [enabledButton, setEnabledButton] = useState(true);
  const [menusPerfil, setMenusPerfil] = useState();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const { isLoading, data: Acoes } = useAcoes();

  const [form] = Form.useForm();

  useEffect(() => {
    async function render() {
      await api.get(url.MENU.PERFIL(perfilId)).then((res) => {
        setMenusPerfil(res.data || []);
        setPageLoad(false);
      });
    }

    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveTab]);

  function voltarAba() {
    setActiveTab(TABS.MENUS);
  }

  function voltar() {
    if (identificador) {
      history.push(`/app/perfil/${identificador}`);
    } else {
      history.push('/app/perfil');
    }
  }

  async function onSave() {
    setLoading(true);

    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          perfilId: perfilId,
          menuId: dados?.menuId,
          acaoId: dados?.actionGroup || [],
        };

        await api
          .post('/acao-perfil-menu', payload)
          .then((res) => {
            setLoading(false);
            setOpenModalConfirm(true);
          })
          .catch((err) => {
            setLoading(false);
            const { errors } = err.response.data;
            if (errors) {
              for (const item in errors) {
                Notify('error', item ? item : pt.comum.atencao, errors[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        setLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function onChangeProfile(id) {
    setOptionsLoad(true);
    if (id) {
      menusPerfil.forEach((item) => {
        if (id === item.id) {
          const remap = [];
          item.acoes.forEach((child) => {
            remap.push(child.id);
          });

          form.setFieldsValue({ actionGroup: remap });
        }
      });

      setTimeout(() => {
        setEnabledButton(false);
        setOptionsLoad(false);
      }, 1200);
    }
  }

  const loadingSkeleton = isLoading || optionsLoad

  return (
    <>
      <Page loadPage={pageLoad}>
        <Card className="card-custom card-shadowless rounded-top-0 mb-0 p-4">
          <CardHeader className="pl-2">
            <h5 className="title">Dados básicos</h5>
            {/* <p className="category">Manutenção de Perfil</p> */}
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className="my-3 p-2">
                  {loadingSkeleton && <SkeletonTableBasic cardShadowless />}
                  {!loadingSkeleton && (
                    <>
                      <Form
                        form={form}
                        scrollToFirstError={true}>
                        <Row>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Menus liberados</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="menuId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o Menu',
                                },
                              ]}>
                              <Select
                                placeholder=" Menu"
                                onChange={onChangeProfile}>
                                {menusPerfil?.map((item) => (
                                  <Option key={item.id}>
                                    {item.descricao}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col xs="12">
                            <Form.Item
                              className="my-2 mx-3"
                              name="actionGroup">
                              <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                  {Acoes?.map((child) => (
                                    <Col key={child.id} xs="12" md="3">
                                      <Checkbox
                                        value={child.id}
                                        style={{ lineHeight: '32px' }}>
                                        {child.descricao}
                                      </Checkbox>
                                    </Col>
                                  ))}
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-3">
                        <Col xs="12" md="3">
                          <Button
                            onClick={() => onSave()}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-1"
                            color={enabledButton ? '' : 'violet'}
                            disabled={enabledButton}
                            isloading={loading.toString()}
                            loadtext={'Cadastrando...'}>
                            <Icon name="check" />
                            Cadastrar
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-1"
                            onClick={() => voltarAba()}>
                            <Icon name="reply" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ModalConfirm
          isDelete={false}
          icon="check circle"
          open={openModalConfirm}
          close={voltar}
          message="Cadastrado com sucesso"
        />
      </Page>
    </>
  );
};

export default PerfilAcoes;
