/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import Page from '~/components/Page';




function FacebookLoginRedirect() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
    if (!!authorizationCode) {
      localStorage.setItem('fbAuth', authorizationCode)
      window.close();
    }
  }, []);


  return (
    <Page loadPage={true}>
    </Page>
  );
}

export default FacebookLoginRedirect;

