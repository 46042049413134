import api from '~/services/api';

export default async (url) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
}
