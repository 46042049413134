import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
// import filter from '~/infra/resources/pattern/filter';

export default function useFinalidades(
  current,
  pageSize,
  nomeFinalidades,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : null;
    const tamanhoPagina = pageSize ? pageSize : null;
    const filterNomeFinalidades = nomeFinalidades ? nomeFinalidades : null;
    let filter = null;

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (tamanhoPagina) {
      if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
      else filter = `?tamanhoPagina=${tamanhoPagina}`;
    }

    if (filterNomeFinalidades) {
      if (filter.length > 0) filter += `&nome=${filterNomeFinalidades}`;
      else filter = `?nome=${filterNomeFinalidades}`;
    }

    try {
      await api
        .get(`${url.FINALIDADE.CONSULTA()}${filter ? filter : ''}`)
        .then((res) => {
          setData(res.data.data || []);
          setQuantidade(res.data.data.length);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize, nomeFinalidades, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
