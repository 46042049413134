import React, {Fragment, useState, useEffect} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {useParams} from 'react-router-dom';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';
import Notify from '~/shared/Notify';

import usePlantao from '~/actions/Plantoes/usePlantoes';

import CalendarioEscalas from './components/CadastroBasico';
import ModalEscalas from './components/CadastroBasico/components/ModalEscalas';

import url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

import './style.css';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Escalas() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isYearMonth, setIsYearMonth] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [dataEscalas, setDataEscalas] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [anoAtual, setAnoAtual] = useState(moment().year());
  const [mesAtual, setMesAtual] = useState(moment().month());
  const [modo, setModo] = useState(MODO.CADASTRAR);
  const [actions, setActions] = useState([]);

  const {data: dataPlantao} = usePlantao();

  const {identificador} = useParams();

  async function getEscalas() {
    await api
      .get(
        `${url.ESCALAS.CONSULTA()}?ano=${Number(anoAtual)}&mes=${Number(
          mesAtual + 1,
        )}`,
      )
      .then((res) => {
        setDataEscalas(res?.data || []);
      })
      .catch((err) => {
        setDataEscalas([]);
        const {error} = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const {actions} = getActionsMenus(identificador);

    setActions(actions || []);

    setIsLoading(true);
    getEscalas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mesAtual, anoAtual, identificador]);

  async function onConfirmDelete() {
    if (selectedDate) {

      setIsLoadingConfirm(true);

      const payload = {
        diaMesIni: isYearMonth ? moment(selectedDate).startOf('month').format('YYYY-MM-DD') : moment(selectedDate).format('YYYY-MM-DD'),
        diaMesFim: isYearMonth ? moment(selectedDate).endOf('month').format('YYYY-MM-DD') : moment(selectedDate).format('YYYY-MM-DD'),
      };
      await api
        .delete(url.ESCALAS.DELETAR(), {data: payload})
        .then((response) => {
          getEscalas();
          setIsLoadingConfirm(false);
          setOpenModalConfirm(false);
          setOpenModal(false);
          setSelectedDate('');
          Notify('success', '', 'Escala(s) excluída(s) com sucesso!');
        })
        .catch((error) => {
          setIsLoadingConfirm(false);
          setOpenModalConfirm(false);
          setOpenModal(false);
          setSelectedDate('');
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCloseModalConfirm() {
    setOpenModalConfirm(false);
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de escalas</h5>
                <p className="category">Manutenção de escalas</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" className="my-2 p-4">
                    <CalendarioEscalas
                      dataEscalas={dataEscalas}
                      dataPlantao={dataPlantao}
                      setModo={setModo}
                      setOpenModal={setOpenModal}
                      setSelectedDate={setSelectedDate}
                      selectedDate={selectedDate}
                      setAnoAtual={setAnoAtual}
                      setMesAtual={setMesAtual}
                      mesAtual={mesAtual}
                      incluir={incluir}
                      editar={editar}
                      setIsYearMonth={setIsYearMonth}
                    />

                    <ModalEscalas
                      modo={modo}
                      open={openModal}
                      close={onCloseModal}
                      dataPlantao={dataPlantao}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      getEscalas={getEscalas}
                      dataEscalas={dataEscalas}
                      setOpenModalConfirm={setOpenModalConfirm}
                      excluir={excluir}
                      isYearMonth={isYearMonth}
                    />

                    <ModalConfirm
                      isDelete
                      icon="warning sign"
                      loading={isLoadingConfirm}
                      open={openModalConfirm}
                      confirm={onConfirmDelete}
                      close={onCloseModalConfirm}
                      message="Deseja realmente excluir esse registro?"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Escalas;
