/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '~/services/api';
import { Funnel, FUNNEL_CONVERSATION_FIELD } from '@ant-design/plots';

export default function useSalesFunnel(
  mainPeriod,
  broker,
  brokerGroup,
  initialDate,
  finalDate,
) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(async () => {
    try {
      const request = await api.get('Dashboard/gerente/funil-vendas', {
        params: {
          periodoPrincipal: mainPeriod,
          corretorId: broker,
          grupoCorretoresId: brokerGroup,
          dataIni: initialDate,
          dataFim: finalDate,
        },
      });

      if (request) {
        const columns = [
          {name: 'LEAD NA BASE', number: 600},
          {name: 'EM TENTATIVA', number: 500},
          {name: 'EM ATENDIMENTO', number: 400},
          {name: 'EM VISITA', number: 300},
          {name: 'EM PROPOSTA', number: 200},
          {name: 'FECHADO', number: 100}
        ];
        const values = Object.values(request?.data?.data);

        setData(() => {
          return (
            columns?.map((column, index) => ({
              categoriaValor: `${column.name}|${Number(values[index]?.valor || 0)}|${Number(values[index]?.quantidade || 0)}`,
              quantidade: Number(column.number || 0),
              qtdReal: Number(values[index]?.quantidade || 0),
            })) || []
          );
        });
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [mainPeriod, broker, brokerGroup, initialDate, finalDate]);

  const config = useMemo(() => {
    return {
      data,
      xField: 'categoriaValor',
      yField: 'quantidade',
      color: [ '#BBDEFB', '#2196F3', '#FFE082', '#FFCA28', '#FF6F00', '#DD2C00' ],
      legend: false,
      label: {
        formatter: ({ categoriaValor }) => `${categoriaValor.split('|')[0]} - ${categoriaValor.split('|')[2]}  (${((categoriaValor.split('|')[2] / data.reduce((a, b) => a + b.qtdReal, 0)) * 100).toFixed(2)}%)`
        ,style: {
          textAlign: 'center',
          fontWeight: 'bold',
          fill: '#0000007a'
        },
      },
      conversionTag: false,
      minSize: 0.1,
      tooltip: {
        formatter: (datum) => {

          return {
            name: datum.categoriaValor.split('|')[0], value: Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(datum.categoriaValor.split('|')[1] || 0)
          };
        },
      }
    };
  }, [data]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    config,
    onLoad,
  };
}
