import React, { Fragment } from 'react'
import { Button, Icon, Image, Modal } from 'semantic-ui-react'
import { Col, Row } from 'reactstrap'
import { SwapOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import PhoneLink from '~/components/PhoneLink/PhoneLink'

// Add separate line for assets
import newLead from '~/assets/img/NewLead.png';
import useGetOfertaAtiva from '~/actions/OfertaAtiva/useGetOfertaAtiva';
import useRetainObjectService from '~/hooks/retain-object-service';

const Loading = (props) => <Row className="justify-content-center">
  <div className="d-flex flex-nowrap mr-3">
    <div className="pt-4">
      {props.text || "Carregando..."}
    </div>
  </div>
</Row>

const ActionButton = (props) => (
  <Button
    className="my-3"
    size="tiny"
    icon
    labelPosition="left"
    fluid
    {...props}
  >
    <Icon name={props.icon} />
    {props.children}
  </Button>
)

const ModalActions = ({ loading, handleResponse, cliente }) => {
  const buttons = [
    {
      color: 'red',
      icon: 'times',
      label: 'Cliente Recusou',
      onClick: () => handleResponse(cliente, 3),
    },
    {
      icon: 'exclamation-triangle',
      label: 'Fone Inexistente',
      onClick: () => handleResponse(cliente, 6),
    },
    {
      icon: 'ban',
      label: 'Não Atendeu',
      onClick: () => handleResponse(cliente, 4),
    },
    {
      color: 'blue',
      icon: 'check',
      label: 'Iniciar Atendimento',
      onClick: () => handleResponse(cliente, 2),
    },
  ];

  return (
    <Modal.Actions>
      <Row className="justify-content-center mb-4 mx-2">
        {buttons.map((buttonProps, index) => (
          <Col key={index} xs="12" md="3" className="mb-2">
            <ActionButton
              className="my-2"
              size="tiny"
              labelPosition="left"
              fluid
              disabled={loading}
              icon={buttonProps.icon}
              color={buttonProps.color}
              onClick={buttonProps.onClick}
            >
              {buttonProps.label}
            </ActionButton>
          </Col>
        ))}
      </Row>
    </Modal.Actions>
  );
}


const Phone = ({ phone }) => (
  phone ? (
    <span className="font-weight-bold">
      <PhoneLink phone={phone} />
    </span>
  ) : null
)

const ModalHeader = ({ onClickTrocaLista, ofertaAtiva }) => (
  <Row className="mx-auto" style={{ display: "inline-table", width: "100%" }}>
    <Col xs="12" className="justify-content-center text-center">
      <div>
        <h2 className="font-weight-bold mb-4 text-center">
          Novo contato, ligue e oferte!
        </h2>
        <Row style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
          <Text strong className="font-weight-bold font-size-1-5 md:text-center text-left">
            {`Lista: ${ofertaAtiva?.nome}`}
          </Text>
          <span
            style={{ cursor: "pointer" }}
            onClick={onClickTrocaLista}>
            <SwapOutlined />
            <span className="p-3">
              Trocar de Lista
            </span>
          </span>
        </Row>
      </div>
    </Col>
  </Row>
)

const Message = ({ title, message, isError }) => (
  <Row className="mx-auto" style={{ display: "inline-table", width: "100%" }}>
    <Col xs="12" className="justify-content-center text-center">
      <div>
        <h2 className="font-weight-bold mb-4 text-center">
          {title}
        </h2>
      </div>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", lineHeight: "2.5" }}>
        {isError ? <Icon name="exclamation triangle" color="orange" size="small" /> : <Icon name="spinner" loading size="small" />}
        <Loading text={message} />
      </div>
    </Col>
  </Row>
)

const ModalOfertaAtiva = ({ open, response, ofertaAtivaSelecionada, onTrocaLista }) => {
  const { ofertaAtiva, isValidating, isLoading, error, mutate } = useGetOfertaAtiva({
    propostaAtivaId: ofertaAtivaSelecionada?.id,
  }, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    retainedObjectError,
    isRetainingObject,
    releaseObject,
    isReleasingObject
  } = useRetainObjectService({
    objectRetainedId: ofertaAtiva?.id,
    retainedType: 'proposta-ativa-fila-atendimento',
  });

  const onResponse = async (cliente, status) => {
    await response(cliente, status);
    await releaseObject();
    await mutate(undefined, true);
  }

  const display = !isLoading && !isRetainingObject && !isReleasingObject && !retainedObjectError && !error && !isValidating;
  const displayError = !isLoading && !isRetainingObject && !isReleasingObject && (retainedObjectError || error) && !isValidating;
  const displayLoading = (isLoading || isRetainingObject || isValidating) && !retainedObjectError && !error && !isReleasingObject;

  return (
    <Modal
    closeOnDimmerClick={false}
    size="large"
      dimmer="blurring"
      open={open}
    >
      <Modal.Content image>
        <div className="m-2 text-center">
          <Image size="small" src={newLead} wrapped />
        </div>
        <Col className="p-2 w-100 text-center" style={{ display: "inline-table", width: "100%" }}>
          {displayError && (
            <Message title="Erro ao buscar oferta ativa..." message="Não foi possível exibir os dados. O tempo de retenção da oferta pode ter expirado, tente novamente mais tarde." isError={true} />
          )}

          {displayLoading && (
            <Message title="Carregando oferta ativa..." message="Aguarde enquanto buscamos uma oferta ativa disponível para você." isError={false} />
          )}

          {isReleasingObject && (
            <Message title="Saindo da fila de atendimento..." message="Aguarde enquanto liberamos a oferta ativa." isError={false} />
          )}

          {display && (
            <Fragment>
              <ModalHeader
                ofertaAtiva={ofertaAtivaSelecionada}
                onClickTrocaLista={async () => {
                  await releaseObject();
                  onTrocaLista();
                }}
              />

              <Col xs={12} offset={7} className="justify-content-center text-center">
                <p className="mt-3 font-weight-light font-size-1-5">
                  {ofertaAtiva?.nomeCompleto}
                </p>
                <p className="my-2">Telefone(s)</p>
                <Phone phone={ofertaAtiva?.telefone1} />
                <Phone phone={ofertaAtiva?.telefone2} />
                <Phone phone={ofertaAtiva?.telefone3} />
                <Phone phone={ofertaAtiva?.telefone4} />
              </Col>
            </Fragment>
          )}
        </Col>
      </Modal.Content>

      <ModalActions loading={!display} handleResponse={onResponse} cliente={ofertaAtiva} />
    </Modal>
  )
}

export default ModalOfertaAtiva;
