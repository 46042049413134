import React, {Fragment} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

import Page from '~/components/Page';

function Relatorios() {
  return (
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Relatórios</h5>
                <p className="category">Controle de relatórios</p>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Relatorios;
