import React, {Fragment, useState, useEffect} from 'react';
import {Form, Select, Table, Tooltip} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Icon} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import usePerfil from '~/actions/Perfil/usePerfil';
import useMenu from '~/actions/Menu/useMenu';

import Page from '~/components/Page';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import EmptyBox from '~/components/EmptyBox';
import ModalConfirm from '~/components/ModalConfirm';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

const {Option} = Select;

const text = {
  delete: <span>Desvincular menu</span>,
};

function PerfilMenu() {
  const [actions, setActions] = useState([]);
  const [loadTable, setloadTable] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [menuId, setMenuId] = useState(undefined);
  const [perfilId, setPerfilId] = useState(undefined);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [form] = Form.useForm();

  const { identificador } = useParams();

  const {data: menus} = useMenu();
  const {data: perfis} = usePerfil();

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  function handleSubmit() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          menuIdentifiers: dados.menusId,
          perfilId: dados.perfilId,
        };

        await api
          .post(url.MENU_PERFIL.CADASTRAR(), payload)
          .then((res) => {
            setIsLoading(false);

            Notify('success', '', pt.geral.cadastro_com_sucesso);

            form.setFieldsValue({menusId: undefined});

            onChangeProfile(dados.perfilId);
          })
          .catch((err) => {
            setIsLoading(false);
            const {error} = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        setIsLoading(false);
      });
  }

  const onChangeProfile = async (ProfileId) => {
    setloadTable(true);
    setPerfilId(ProfileId);

    await api
      .get(url.MENU.PERFIL(ProfileId))
      .then((res) => {
        setData(res.data);
        setloadTable(false);
      })
      .catch((err) => {
        setloadTable(false);
        const {error} = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  };

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Submenu',
      dataIndex: 'menuPaiId',
      key: 'menuPaiId',
      render: (row) => <p>{row !== null ? 'SIM' : 'NÃO'}</p>,
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          {excluir === true ? (
            <Tooltip placement="topRight" title={text.delete}>
              <i
                onClick={() => handleModalConfirm(row.identificador)}
                className="ri-close-circle-line linkTable"
                style={{fontSize: '26px', marginRight: '10px'}}></i>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  const columnsExpanded = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Submenu',
      dataIndex: 'menuPaiId',
      key: 'menuPaiId',
      render: (row) => <p>{row !== null ? 'SIM' : 'NÃO'}</p>,
    },
  ];

  const expandedRowRender = (filhos) => {
    return (
      <div className="p-3">
        <Table
          columns={columnsExpanded}
          dataSource={filhos}
          pagination={false}
        />
      </div>
    );
  };

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  async function handleConfirmDelete() {
    if (menuId && perfilId) {
      setIsLoading(true);

      const payload = {
        menuIdentifiers: [menuId],
        perfilId: perfilId,
      };

      await api
        .delete(url.MENU_PERFIL.DELETAR(), { data: payload })
        .then((response) => {
          setIsLoading(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
          onChangeProfile(perfilId);
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleModalConfirm(identificador) {
    setOpenModalConfirm(true);
    setMenuId(identificador);
  }

  const isEmpty = !loading && !data.length;

  return (
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Menus do perfil</h5>
                <p className="category">Manutenção de menus do perfil</p>
              </CardHeader>
              <CardBody>
                <Form form={form}>
                  <Row>
                    <Col xs="12" md="6" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Perfil</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="perfilId"
                        rules={[
                          {
                            required: true,
                            message: 'Favor selecionar um perfil.',
                          },
                        ]}>
                        <Select
                          placeholder=" Perfil"
                          showSearch
                          onChange={onChangeProfile}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                              .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                          }>
                          {perfis.map((item) => (
                            <Option key={item.id}>{item.descricao}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="6" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Menu(s)</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="menusId"
                        rules={[
                          {
                            required: true,
                            message: 'Favor selecionar ao menos um menu.',
                          },
                        ]}>
                        <Select
                          mode="multiple"
                          placeholder=" Menu"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                              .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                          }>
                          {menus.map((item) => (
                            <Option key={item.identificador}>
                              {item.descricao}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                {incluir === true ? (
                  <Row>
                    <Col xs="12" className="mt-1 mb-3">
                      <Button
                        onClick={handleSubmit}
                        icon
                        labelPosition="left"
                        size="medium"
                        fluid
                        className="mt-3"
                        color="blue"
                        isloading={loading.toString()}
                        loadtext="Confirmando...">
                        <Icon name="check" />
                        Adicionar Menu
                      </Button>
                    </Col>
                  </Row>
                ) : null}

                {!isEmpty && !loading && (
                  <div className="mt-4">
                    <Table
                      rowKey={(row) => row.id}
                      columns={columns}
                      dataSource={data}
                      loading={loadTable}
                      expandable={{
                        expandedRowRender: (record) =>
                          expandedRowRender(record.filhos),
                        rowExpandable: (record) => record.filhos.length > 0,
                      }}
                    />
                  </div>
                )}

                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>

      <ModalConfirm
        loading={loading}
        open={openModalConfirm}
        confirm={handleConfirmDelete}
        close={handleCloseModal}
        title="Atenção"
        message="Deseja realmente desvincular esse menu?"
        loadingText="Desvinculando..."
      />
    </Fragment>
  );
}

export default PerfilMenu;
