/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function usePerfil() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const empresaId = localStorage.getItem('icaseEmpresaId');

  const onLoad = useCallback(async () => {
    try {
      if (empresaId.length > 4) {
        await api.get(url.PERFIL.CONSULTA(empresaId)).then((res) => {
          setData(res?.data || []);
          setQuantidade(res?.data?.total);
        });
      } else {
        setData([]);
      }
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    quantidade,
    onLoad,
  };
}
