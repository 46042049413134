/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {Form, Input, DatePicker, Divider, Select} from 'antd';
import {Icon} from 'semantic-ui-react';

import {checkDocumento} from '~/shared/checkDocumento';
import URL from '~/infra/urls';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import history from '~/services/history';
import api from '~/services/api';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
moment.locale('pt-BR');

const dateFormat = 'DD/MM/YYYY';
const {Option} = Select;

function FormUsuario({profile}) {
  const [loading, setloading] = useState(false);
  const [cidadeDisabled, setCidadeDisabled] = useState(true);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [dateString, setDateString] = useState();

  useEffect(() => {
    // async function fetchData() {
    //   await api.get(URL.ESTADOS.LISTAR()).then((res) => {
    //     setEstados(res.data);
    //   });
    // }
    // fetchData();
  }, []);

  async function alterarDados(dados) {
    setloading(true);
  }

  function onChangeDate(date, dateString) {
    setDateString(moment(dateString, 'YYYY-MM-DD'));
  }

  async function onChangeEstado(estado) {
    setCidadeDisabled(false);
    const payload = {id: estado};
    await api.post(URL.CIDADES.LISTAR(), payload).then((res) => {
      setCidades(res.data);
    });
  }

  function voltar() {
    history.push('/app');
  }

  return (
    <Form
      initialValues={{remember: true}}
      onFinish={alterarDados}
      scrollToFirstError
      initialValues={{
        nome: profile.nome ? profile.nome : '',
        email: profile.email ? profile.email : '',
        CPF: profile.cpf ? profile.cpf : '',
        telefone: profile.telefone ? profile.telefone : '',
        cidade_id: profile.cidade_id ? profile.cidade_id.toString() : undefined,
        cep: profile.cep ? profile.cep : '',
        logradouro: profile.logradouro ? profile.logradouro : '',
        numero: profile.numero ? profile.numero : '',
        bairro: profile.bairro ? profile.bairro : '',
        dataAniversario: profile.data_nascimento
          ? moment(profile.data_nascimento, 'YYYY-MM-DD')
          : undefined,
        complemento: profile.complemento ? profile.complemento : '',
      }}>
      <Row>
        <Col xs="12">
          <p className="heading-small text-muted mb-2">
            <small>Nome</small>
          </p>
          <Form.Item
            className="my-2"
            name="nome"
            rules={[
              {
                required: true,
                message: 'Favor informar o Nome',
              },
            ]}>
            <Input size="large" placeholder=" Nome" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <p className="heading-small text-muted mb-2">
            <small>Email</small>
          </p>
          <Form.Item
            className="my-2"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Favor inserir um E-mail válido',
              },
              {
                required: true,
                message: 'Favor informar o seu E-mail',
              },
            ]}>
            <Input size="large" placeholder=" Email" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>CPF</small>
          </p>
          <Form.Item
            className="my-2"
            name="CPF"
            rules={[
              {
                required: true,
                message: 'Favor informar o CPF',
              },
              // eslint-disable-next-line no-empty-pattern
              ({}) => ({
                validator(rule, value) {
                  if (value) {
                    const isDocumento = checkDocumento('cpf', value);
                    if (isDocumento === true) {
                      return Promise.reject('Número de CPF inválido');
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <Input type="number" size="large" placeholder=" Número do CPF" />
          </Form.Item>
        </Col>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Telefone</small>
          </p>
          <Form.Item
            className="my-2"
            name="telefone"
            rules={[
              {
                required: true,
                message: 'Favor informar o número do seu telefone',
              },
            ]}>
            <Input
              size="large"
              placeholder=" DDD + Telefone"
              type="number"
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider dashed />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>CEP</small>
          </p>
          <Form.Item
            className="my-2"
            name="cep"
            rules={[
              {
                required: true,
                message: 'Favor informar o CEP',
              },
            ]}>
            <Input size="large" placeholder=" CEP" />
          </Form.Item>
        </Col>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>RUA</small>
          </p>
          <Form.Item
            className="my-2"
            name="logradouro"
            rules={[
              {
                required: true,
                message: 'Favor informar a Rua',
              },
            ]}>
            <Input size="large" placeholder=" Rua" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Estado</small>
          </p>
          <Form.Item
            className="my-2"
            name="estado"
            rules={[
              {
                required: true,
                message: 'Favor informar o Estado',
              },
            ]}>
            <Select
              placeholder=" Estado"
              size="large"
              onChange={onChangeEstado}>
              {estados.map((item) => (
                <Option key={item.id}>{item.nome}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Cidade</small>
          </p>
          <Form.Item
            className="my-2"
            name="cidade"
            dependencies={['estado']}
            rules={[
              {
                required: true,
                message: 'Favor informar a Cidade',
              },
            ]}>
            <Select
              placeholder=" Cidade"
              size="large"
              disabled={cidadeDisabled}>
              {cidades.map((item) => (
                <Option key={item.id}>{item.nome}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Número</small>
          </p>
          <Form.Item
            className="my-2"
            name="numero"
            rules={[
              {
                required: true,
                message: 'Favor informar o número',
              },
            ]}>
            <Input size="large" placeholder=" Número" />
          </Form.Item>
        </Col>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Bairro</small>
          </p>
          <Form.Item
            className="my-2"
            name="bairro"
            rules={[
              {
                required: true,
                message: 'Favor informar o Bairro',
              },
            ]}>
            <Input size="large" placeholder=" Bairro" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Data de nascimento</small>
          </p>
          <Form.Item
            className="my-2"
            name="dataAniversario"
            // label="Nascimento"
            rules={[
              {
                required: true,
                message: 'Favor informar a data de nascimento',
              },
            ]}>
            <DatePicker
              locale={locale}
              format={dateFormat}
              size="large"
              onChange={onChangeDate}
              style={{width: '100%'}}
              placeholder=" Data de nascimento"
            />
          </Form.Item>
        </Col>
        <Col xs="12" md="6">
          <p className="heading-small text-muted mb-2">
            <small>Complemento</small>
          </p>
          <Form.Item className="my-2" name="complemento">
            <Input size="large" placeholder=" Complemento" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider dashed />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Button
            icon
            labelPosition="left"
            size="medium"
            fluid
            className="mt-3"
            color="blue"
            isloading={loading.toString()}
            loadtext="Alterando...">
            <Icon name="check" />
            Alterar
          </Button>
        </Col>
        <Col xs="12">
          <Button
            icon
            labelPosition="left"
            size="medium"
            fluid
            className="mt-1"
            onClick={() => voltar()}>
            <Icon name="reply" />
            Voltar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default FormUsuario;
