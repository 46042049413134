import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useLeadOfertaAtiva(current, pageSize, nome, corretorId, status, ofertaId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : 1;
    const filterPageSize = pageSize ? pageSize : 12;
    const filterNomeCliente = nome ? nome : null;
    const filterCorretorId = corretorId ? corretorId : null;
    const filterStatusPagina = status ? status : null;
    const filterOfertaId = ofertaId ? ofertaId : null;

    if (!filterOfertaId && !filterCorretorId)
      return;

    let filter = '';

    if (filterOfertaId) {
      if (filter.length > 0) filter += `&ofertaId=${filterOfertaId}`;
      else filter = `?ofertaId=${filterOfertaId}`;
    }

    if (filterCorretorId) {
      if (filter.length > 0) filter += `&corretorId=${filterCorretorId}`;
      else filter = `?corretorId=${filterCorretorId}`;
    }

    if (filterCurrent) filter += `&numeroPagina=${filterCurrent}`;

    if (filterPageSize) filter += `&tamanhoPagina=${filterPageSize}`;

    if (filterNomeCliente) filter += `&nome=${filterNomeCliente}`;

    if (filterStatusPagina) {
      String(filterStatusPagina).split(',').forEach(element => {
        filter += `&status=${element}`;
      });
    }

    try {
      await api.get(`${url.OFERTA_ATIVA.PROPOSTA_ATIVA_FILA_PAGED()}${filter}`)
        .then(res => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.total || 0);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [current, pageSize, nome, corretorId, status, ofertaId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
