import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useImovelProprietarios(imovelId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async (parms) => {
      setIsLoading(true);

      if (imovelId || parms) {
        try {
          api
            .get(url.IMOVEL_PROPRIETARIOS.CONSULTA(imovelId ? imovelId : parms))
            .then((res) => {
              setData(res?.data || []);
              setQuantidade(res?.data?.length);
            });
        } catch (e) {
          setData([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setData([]);
        setQuantidade(0);
      }
    },
    [imovelId]
  );

  useEffect(() => {
    onLoad(imovelId);
  }, [imovelId, onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
