import {useEffect, useState, useCallback} from 'react';
// import api from '~/services/api';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

export default function useDashFechamento(atendente) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    // const icaseOperadorId = await localStorage.getItem('icaseOperadorId');
    // const roles = JSON.parse(localStorage.getItem('icaseRoles'));
    // const administrador = roles.find((x) => x === 'ADMINISTRADOR');
    // const masterLeads = JSON.parse(localStorage.getItem('icaseMasterLeads'));

    // let corretor = '';
    // if (atendente !== null) {
    //   corretor = atendente ? atendente : icaseOperadorId;
    // } else if (!administrador && !masterLeads) {
    //   corretor = icaseOperadorId;
    // }

    try {
      // await api
      //   .get(`dashboard/fechamento?corretorId=${corretor}`)
      //   .then((res) => {
      //     setData(res.data || []);
      //   });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
