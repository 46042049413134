import React, { Fragment, useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { Form, Input, Upload, DatePicker } from 'antd';
import { mask, unMask } from 'remask';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { red } from '@material-ui/core/colors';

import moment from 'moment';
import Button from '~/shared/Button';
import pt from '~/infra/resources/strings/pt';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';
import api from '~/services/api';
import pattern from '~/infra/resources/pattern/mask';

function ModalEmpresa({ open, close, empresaId, initialValues = {} }) {
  const [loading, setIsLoading] = useState(false);
  const [fileLogo, setFileLogo] = useState(null);
  const [errorLogo, setErrorLogo] = useState([]);
  const [form] = Form.useForm();

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleChangeImg = async (image) => {
    if (!image.file.url) {
      var base64 = await getBase64(image.file);
      let im = new Image();
      im.src = base64;
      im.onload = () => {
        if (im?.width !== 250 || im?.height !== 250) {
          setErrorLogo([
            `Sua imagem é tamanho ${im?.width}px por ${im?.height}px. Favor informe uma no tamanho 250px por 250px.`,
          ]);
        } else {
          image.file.url = base64;
          setErrorLogo([]);
          setFileLogo(image.file);
        }
      };
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload logo filial</div>
    </div>
  );

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          nome: dados.nome,
          cnpj: dados.cnpj.replace(/\D/g, ''),
          email: dados.email,
          cep: dados.cep.replace(/\D/g, ''),
          endereco: dados.endereco,
          numero: dados.numero,
          complemento: dados.complemento,
          inscricaoEstadual: dados.inscricaoEstadual || null,
          inscricaoMunicipal: dados.inscricaoMunicipal || null,
          numeroRegistroJuntaComercial:
            dados.numeroRegistroJuntaComercial || null,
          pessoaContato: dados.pessoaContato || null,
          telefone: dados.telefone ? dados.telefone.replace(/\D/g, '') : null,
          dataAbertura: dados.dataAbertura,
          matrizId: empresaId,
          urlApiDesktop: dados.urlApiDesktop,
          logoBase64: fileLogo?.url,
        };

        await api
          .post(url.EMPRESAS.CADASTRAR(), payload)
          .then((res) => {
            setIsLoading(false);
            form.resetFields();
            Notify('success', '', pt.geral.cadastro_com_sucesso);
            close();
          })
          .catch((err) => {
            setIsLoading(false);
            const { errors } = err.response.data;
            if (errors) {
              for (const item in errors) {
                Notify('error', pt.comum.atencao, errors[item]);
              }
            }
            close();
          });
      })
      .catch((errorInfo) => {
        setIsLoading(false);
      });
  }

  function voltar() {
    close();
  }

  function handleMask(e, typeMask) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [typeMask]);

    if (typeMask === pattern.cnpj) form.setFieldsValue({ cnpj: value });
    if (typeMask === pattern.cep) form.setFieldsValue({ cep: value });
    if (typeMask === pattern.telefone) form.setFieldsValue({ telefone: value });
  }

  return (
    <Fragment>
      <Modal open={open} onClose={() => close()}>
        <Modal.Content scrolling>
          <h5 className="title">Cadastro de Filial</h5>
          <p className="category">Manutenção de Filial</p>
          <div className="px-2 py-4">
            <Form
              form={form}
              scrollToFirstError={true}
              initialValues={initialValues}>
              <Row>
                <Col xs="12">
                  <p className="heading-small text-muted mb-2">
                    <small>Nome</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o nome!',
                      },
                    ]}>
                    <Input placeholder=" Nome" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <p className="heading-small text-muted mb-2">
                    <small>CNPJ</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="cnpj">
                    <Input
                      placeholder=" CNPJ"
                      maxLength={18}
                      onKeyUp={(e) => handleMask(e, pattern.cnpj)}
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" md="6">
                  <p className="heading-small text-muted mb-2">
                    <small>E-mail</small>
                  </p>
                  <Form.Item className="my-2" name="email">
                    <Input placeholder=" E-mail" type="email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <p className="heading-small text-muted mb-2">
                    <small>Data de Abertura</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="dataAbertura"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar a data de abertura!',
                      },
                    ]}>
                    <DatePicker
                      placeholder=" Data de Abertura"
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      disabledDate={(current) => {
                        return current && current > moment().endOf('day');
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs="12" md="6">
                  <p className="heading-small text-muted mb-2">
                    <small>URL Api Desktop</small>
                  </p>
                  <Form.Item className="my-2" name="urlApiDesktop">
                    <Input placeholder=" URL Api Desktop" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <p className="heading-small text-muted mb-2">
                    <small>CEP</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="cep"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o CEP!',
                      },
                    ]}>
                    <Input
                      placeholder=" CEP"
                      onKeyUp={(e) => handleMask(e, pattern.cep)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <p className="heading-small text-muted mb-2">
                    <small>Endereço</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="endereco"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o endereço!',
                      },
                    ]}>
                    <Input placeholder=" Endereço" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="3">
                  <p className="heading-small text-muted mb-2">
                    <small>Número</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="numero"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o número!',
                      },
                    ]}>
                    <Input placeholder=" Número" />
                  </Form.Item>
                </Col>
                <Col xs="12" md="9">
                  <p className="heading-small text-muted mb-2">
                    <small>Complemento</small>
                  </p>
                  <Form.Item className="my-2" name="complemento">
                    <Input placeholder=" Complemento" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="4">
                  <p className="heading-small text-muted mb-2">
                    <small>Inscrição Estadual</small>
                  </p>
                  <Form.Item className="my-2" name="inscricaoEstadual">
                    <Input placeholder=" Inscrição Estadual" />
                  </Form.Item>
                </Col>
                <Col xs="12" md="4">
                  <p className="heading-small text-muted mb-2">
                    <small>Inscrição Municipal</small>
                  </p>
                  <Form.Item className="my-2" name="inscricaoMunicipal">
                    <Input placeholder=" Inscrição Municipal" />
                  </Form.Item>
                </Col>
                <Col xs="12" md="4">
                  <p className="heading-small text-muted mb-2">
                    <small>Número de Registro</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="numeroRegistroJuntaComercial">
                    <Input placeholder=" Número de Registro" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="8">
                  <p className="heading-small text-muted mb-2">
                    <small>Contato</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="pessoaContato"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o Contato!',
                      },
                    ]}>
                    <Input placeholder=" Contato" />
                  </Form.Item>
                </Col>
                <Col xs="12" md="4">
                  <p className="heading-small text-muted mb-2">
                    <small>Telefone</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="telefone"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o Telefone!',
                      },
                    ]}>
                    <Input
                      placeholder=" (99) 9999-9999"
                      onKeyUp={(e) => handleMask(e, pattern.telefone)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col xs="12" md="4">
                <p className="heading-small text-muted mb-2">
                  <small>Logo Empresa</small>
                </p>
                <Upload
                  accept={'image/png,image/jpeg'}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  multiple={false}
                  showUploadList={false}
                  onChange={handleChangeImg}
                  beforeUpload={(file) => {
                    return false;
                  }}>
                  {fileLogo?.url ? (
                    <img
                      src={fileLogo.url}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                {errorLogo.map((e, index) => (
                  <p key={index} style={{ color: red[400] }}>{`- ${e}`}</p>
                ))}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12" md="4">
                <Button
                  onClick={onSave}
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  className="mt-1"
                  color="violet"
                  isloading={loading.toString()}
                  loadtext="Cadastrando...">
                  <Icon name="check" />
                  Cadastrar
                </Button>
              </Col>
              <Col xs="12" md="4">
                <Button
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  className="mt-1"
                  onClick={() => voltar()}>
                  <Icon name="undo" />
                  Voltar
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalEmpresa;
