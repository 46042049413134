import React, { useState, useEffect, Fragment } from 'react';
import { Form, Input, Switch, Badge } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Row, Col } from 'reactstrap';
import { Icon, Card } from 'semantic-ui-react';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import midiaDivulgacao from '~/infra/resources/strings/enums/midiaDivulgacao';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  GERAL: 1,
  PORTAIS: 2,
  PROPRIETARIOS: 3,
  IMAGENS: 4,
};

function Portais({ imovelId, identificador, modo, keepFilter, setActiveTab, showTabs }) {
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(true);
  const [dataPortais, setDataPortais] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [totais, setTotais] = useState({
    destaques: 0,
    superDestaques: 0,
    destaquesEspecial: 0,
    destaquesPremium: 0,
  });

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);


  function getDestaque(tipoMidia, listDivulgacoes) {
    const result = listDivulgacoes.find(
      (divulgacao) => divulgacao.tipoMidia === tipoMidia,
    );

    return result?.destaque || false;
  }

  function getSuperDestaque(tipoMidia, listDivulgacoes) {
    const result = listDivulgacoes.find(
      (divulgacao) => divulgacao.tipoMidia === tipoMidia,
    );

    return result?.superDestaque || false;
  }

  function getDestaqueEspecial(tipoMidia, listDivulgacoes) {
    const result = listDivulgacoes.find(
      (divulgacao) => divulgacao.tipoMidia === tipoMidia,
    );

    return result?.destaqueEspecial || false;
  }

  function getDestaquePremium(tipoMidia, listDivulgacoes) {
    const result = listDivulgacoes.find(
      (divulgacao) => divulgacao.tipoMidia === tipoMidia,
    );

    return result?.destaquePremium || false;
  }


  function portalUsaDestaquesEspeciais(portalId) {
    switch (portalId) {
      case 100:
        return true;
      default:
        return false;
    }
  }

  useEffect(() => {
    async function getData() {
      await api
        .get(url.IMOVEL_DIVULGACAO.CONSULTAR(imovelId))
        .then((res) => {
          const listDivulgacoes = res.data || [];
          
          midiaDivulgacao.forEach((midia, index) => {
            midia.divulgacao =
              listDivulgacoes.findIndex((item) => item.tipoMidia === midia.id) >
                -1
                ? true
                : false;

            if (midia.id === 115 && listDivulgacoes.length === 0 && modo === MODO.CADASTRAR)
              midia.divulgacao = true

            midia.destaque = getDestaque(midia.id, listDivulgacoes);
            midia.superDestaque = getSuperDestaque(midia.id, listDivulgacoes);
            midia.destaqueEspecial = getDestaqueEspecial(midia.id, listDivulgacoes);
            midia.destaquePremium = getDestaquePremium(midia.id, listDivulgacoes);

            form.setFieldsValue({
              [index]: {
                tipoMidia: midia.id,
                divulgacao: midia.divulgacao,
                destaque: midia.destaque,
                superDestaque: midia.superDestaque,
                destaqueEspecial: midia.destaqueEspecial,
                destaquePremium: midia.destaquePremium,
              },
            });
          });

          setDataPortais(midiaDivulgacao);
          setLoadingUpdate(false);
        })
        .catch((err) => {
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imovelId]);

  useEffect(() => {
    if (!formValues) return;
    
    const formArrayValues = Object.entries(formValues);
    let totalDestaques = 0;
    let totalSuperDestaques = 0;
    let totalDestaquesEspecial = 0;
    let totalDestaquesPremium = 0;

    for (let i = 0; i < formArrayValues.length; i++) {
      if (formArrayValues[i][1]?.destaque) totalDestaques += 1;
      if (formArrayValues[i][1]?.superDestaque) totalSuperDestaques += 1;
      if (formArrayValues[i][1]?.destaqueEspecial) totalDestaquesEspecial += 1;
      if (formArrayValues[i][1]?.destaquePremium) totalDestaquesPremium += 1;
    }

    setTotais({destaques: totalDestaques, superDestaques: totalSuperDestaques, destaquesEspecial: totalDestaquesEspecial, destaquesPremium: totalDestaquesPremium});

    // set dataPortais
    setDataPortais((prevState) => {
      return prevState.map((item, index) => {
        return {
          ...item,
          divulgacao: formValues[index]?.divulgacao,
          destaque: formValues[index]?.destaque,
          superDestaque: formValues[index]?.superDestaque,
          destaqueEspecial: formValues[index]?.destaqueEspecial,
          destaquePremium: formValues[index]?.destaquePremium,
        };
      });
    });

  }, [formValues]);

  async function onSavePortais() {
    setLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        let divulgacoes = [];
        Object.entries(dados).forEach((entry) => {
          // eslint-disable-next-line no-unused-vars
          const [_, value] = entry;

          if (value?.divulgacao) divulgacoes.push(value);
        });

        const payload = {
          imovelId,
          divulgacoes,
        };

        await api
          .post(url.IMOVEL_DIVULGACAO.CADASTRAR(), payload)
          .then((res) => {
            setLoading(false);
            setOpenModalConfirm(true);
          });
      })
      .catch((err) => {
        setLoading(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  function voltar() {
    keepFilter();
    window.close();
  }


  return (
    <div className="px-4 pb-4">
      {loadingUpdate && <SkeletonTableBasic cardShadowless />}
      {!loadingUpdate && (
        <Fragment>
          <Form form={form} scrollToFirstError>
            <Row>
              <Col xs="12" md="3">
                <Badge
                  style={{ top: '7px', right: '-15px' }}
                  showZero
                  count={totais.destaques}
                  offset={[10, 0]}>
                  <span className="font-weight-bold">Total de Destaques</span>
                </Badge>
              </Col>
              <Col xs="12" md="3">
                <Badge
                  style={{ top: '7px', right: '-15px' }}
                  showZero
                  count={totais.superDestaques}
                  offset={[10, 0]}>
                  <span className="font-weight-bold">
                    Total de Super Destaques
                  </span>
                </Badge>
              </Col>
              <Col xs="12" md="3">
                <Badge
                  style={{ top: '7px', right: '-15px' }}
                  showZero
                  count={totais.destaquesEspecial}
                  offset={[10, 0]}>
                  <span className="font-weight-bold">
                    Total de Super Destaques Especial
                  </span>
                </Badge>
              </Col>
              <Col xs="12" md="3">
                <Badge
                  style={{ top: '7px', right: '-15px' }}
                  showZero
                  count={totais.destaquesPremium}
                  offset={[10, 0]}>
                  <span className="font-weight-bold">
                    Total de Super Destaques Premium
                  </span>
                </Badge>
              </Col>
            </Row>
            <Row>
              {dataPortais.map((portal, index) => (
                <Col key={portal.id} xs="12" sm="3" md="4" className="mt-2">
                  <Card fluid>
                    {/* <Image
                      src={IconPortal}
                      wrapped
                      ui={false}
                      className="p-4 d-flex justify-content-center"
                    /> */}
                    <Card.Content>
                      <Card.Meta className="font-weight-bold text-dark">
                        {portal.descricao}
                      </Card.Meta>
                      <Row className="no-gutters">
                        <Col xs="12">
                          <p className="heading-small text-muted mb-2">
                            <small>Divulgar Portal?</small>
                          </p>
                          <Form.Item name={[`${index}`, 'tipoMidia']} hidden>
                            <Input value={portal.id} hidden />
                          </Form.Item>
                          <Form.Item name={[`${index}`, 'divulgacao']}>
                            <Switch
                              defaultChecked={portal.divulgacao}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="no-gutters">
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Destaque</small>
                          </p>
                          <Form.Item name={[`${index}`, 'destaque']}>
                            <Switch
                              disabled={portal.id === 115 || portal.divulgacao === false}
                              defaultChecked={portal.destaque}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Super Destaque</small>
                          </p>
                          <Form.Item name={[`${index}`, 'superDestaque']}>
                            <Switch
                              disabled={portal.id === 115 || portal.divulgacao === false}
                              defaultChecked={portal.superDestaque}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {portalUsaDestaquesEspeciais(portal?.id) && <Row className="no-gutters">
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Destaque Especial</small>
                          </p>
                          <Form.Item name={[`${index}`, 'destaqueEspecial']}>
                            <Switch
                              disabled={portal.divulgacao === false}
                              defaultChecked={portal.destaqueEspecial}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Destaque Premium</small>
                          </p>
                          <Form.Item name={[`${index}`, 'destaquePremium']}>
                            <Switch
                              disabled={portal.divulgacao === false}
                              defaultChecked={portal.destaquePremium}
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Col>
                        <span style={{ color: 'red', fontSize: 10 }}>* Destaques Especial/Premium estão disponíveis apenas para os contratos Zap+.</span>
                      </Row>}
                    </Card.Content>
                  </Card>
                </Col>
              ))}
            </Row>
          </Form>
          <Row className="mt-2">
            <Col xs="12" md="3">
              <Button
                onClick={onSavePortais}
                icon
                labelPosition="left"
                size="medium"
                fluid
                className="mt-3"
                color="violet"
                isloading={loading.toString()}>
                <Icon name="check" />
                Atualizar
              </Button>
            </Col>
            <Col xs="12" md="3">
              <Button
                className="mt-3"
                icon
                labelPosition="left"
                fluid
                onClick={() => voltar()}>
                <Icon name="undo" />
                Voltar
              </Button>
            </Col>
          </Row>

          <ModalConfirm
            isDelete={false}
            icon="check circle"
            open={openModalConfirm}
            close={() => {
              setOpenModalConfirm(false)
              if (showTabs.showTabImages) setActiveTab(TABS.PROPRIETARIOS);
            }
            }
            message="Dados salvos com sucesso"
          />
        </Fragment>
      )}
    </div>
  );

}

export default Portais;
