import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useGrupoCorretores(
  current,
  pageSize,
  nomeGrupo,
  descricaoGrupo,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : null;
    const tamanhoPagina = pageSize ? pageSize : null;
    const filterNomeGrupo = nomeGrupo ? nomeGrupo : null;
    const filterDescricaoGrupo = descricaoGrupo ? descricaoGrupo : null;
    let filter = null;

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (tamanhoPagina) {
      if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
      else filter = `?tamanhoPagina=${tamanhoPagina}`;
    }

    if (filterNomeGrupo) {
      if (filter.length > 0) filter += `&grupo=${filterNomeGrupo}`;
      else filter = `?grupo=${filterNomeGrupo}`;
    }

    if (filterDescricaoGrupo) {
      if (filter.length > 0) filter += `&descricao=${filterDescricaoGrupo}`;
      else filter = `?descricao=${filterDescricaoGrupo}`;
    }

    try {
      await api
        .get(`${url.GRUPO_CORRETORES.CONSULTA()}${filter ? filter : ''}`)
        .then((res) => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.total || 0);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize, nomeGrupo, descricaoGrupo, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data: data.map((item) => ({
      ...item,
      nomeGerente: item.gerente?.nome || '',
    })),
    quantidade,
    isLoading,
    onLoad,
  };
}
