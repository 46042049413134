import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useTipoMidiaDropdown(nome, tipoMidiaId, utilizaIntegracao) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      const filterNome = nome ? nome : null;
      const filtertipoMidiaId = tipoMidiaId ? tipoMidiaId : null;
      let filter = '';


      if (filterNome && !params?.nome) {
        if (filter.length > 0) filter += `&nome=${filterNome}`;
        else filter = `?nome=${filterNome}`;
      }

      if (filtertipoMidiaId && !params?.tipoMidiaId) {
        if (filter.length > 0) filter += `&tipoMidiaId=${filtertipoMidiaId}`;
        else filter = `?tipoMidiaId=${filtertipoMidiaId}`;
      }

      if (utilizaIntegracao === true || utilizaIntegracao === false) {
        if (filter.length > 0) filter += `&utilizaIntegracao=${utilizaIntegracao}`;
        else filter = `?utilizaIntegracao=${utilizaIntegracao}`;
      }

      if (params?.nome) {
        if (filter.length > 0) filter += `&nome=${params.nome}`;
        else filter = `?nome=${params.nome}`;
      }

      if (params?.tipoMidiaId) {
        if (filter.length > 0) filter += `&tipoMidiaId=${params.tipoMidiaId}`;
        else filter = `?tipoMidiaId=${params.tipoMidiaId}`;
      }

      if (params?.utilizaIntegracao === true || params?.utilizaIntegracao === false) {
        if (filter.length > 0) filter += `&utilizaIntegracao=${params.utilizaIntegracao}`;
        else filter = `?utilizaIntegracao=${params.utilizaIntegracao}`;
      }

      try {
        await api
          .get(`${url.DROPDOWN.TIPOMIDIA_DROPDOWN()}${filter}`)
          .then((res) => {
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nome, tipoMidiaId, utilizaIntegracao],
  );

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
