import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input } from 'antd';
import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function TipoImovel(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [identificador, setIdentificador] = useState();
  const [tipoImovelId, setTipoImovelId] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [form] = Form.useForm();

  const empresaId = localStorage.getItem('icaseEmpresaId');
  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const { row: editData, identificador } = props.location.state;

        setInitialValues(editData);
        setIdentificador(identificador);
        setModo(MODO.EDITAR);
        setTipoImovelId(props.location.state.row.id);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        const identificador = props?.location?.state?.identificador;

        setIdentificador(identificador);
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/tipoimovel/${identificador}`);
    } else {
      history.push('/app/tipoimovel');
    }
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            empresaId,
            descricao: dados.descricao
          };

          await api
            .post(url.TIPO_IMOVEL.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);

              if (props?.onVoltar) {
                localStorage.setItem('icaseTipoImovelId', res.data?.data?.id);
                voltar();
              } else {
                setOpenModalConfirm(true);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            tipoImovelId,
            descricao: dados.descricao,
            empresaId,
          };

          await api
            .put(url.TIPO_IMOVEL.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">{modo === MODO.CADASTRAR ? 'Cadastrar tipo de imóvel' : 'Atualizar tipo de imóvel'}</h5>
                <p className="category">Manutenção de tipo de imóvel</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError
                      >
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="descricao"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o nome!',
                                },
                              ]}
                            >
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mt-4">
                        <Col xs="12" md="3">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR ? 'Cadastrando...' : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR ? 'Cadastrar' : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="undo" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={voltar}
              message={modo === MODO.CADASTRAR ? 'Cadastro realizado' : 'Alterações salvas'}
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default TipoImovel;
