import React, {Fragment, useEffect, useState} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import {Input} from 'antd';
import Button from '~/shared/Button';
import useTransformarAtendimentoEmOfertaAtiva from '~/actions/Atendimento/useTransformarAtendimentoEmOfertaAtiva';
import ProgressBarAcaoEmLote from '../ProgressBarAcaoEmLote';
import SelectCorretoresOrGrupoCorretoresInput from '~/components/SelectCorretoresOrGrupoCorretoresInput';

function ModalTransoformarAtendimentoEmOfertaAtiva({
  open,
  close,
  atendimentos,
  textTitle,
  atualizarQuadro,
}) {
  const [nomeOfertaAtiva, setNomeOfertaAtiva] = useState(null);
  const [membros, setMembros] = useState([]);
  const [allowConfirm, setAllowConfirm] = useState(false);
  const [showSecondConfirmationModal, setShowSecondConfirmationModal] =
    useState(false);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const {transformarAtendimentoEmOfertaAtiva, isLoading} =
    useTransformarAtendimentoEmOfertaAtiva();

  useEffect(() => {
    setSelectedTotal(atendimentos?.total ?? 0);
  }, [atendimentos, selectedTotal]);

  useEffect(() => {
    setAllowConfirm(!!membros.length && !!nomeOfertaAtiva);
  }, [membros, nomeOfertaAtiva, allowConfirm]);

  const onConfirmSubmit = () => {
    const {atendimentoIds, filtros, lanes} = atendimentos;
    transformarAtendimentoEmOfertaAtiva(
      atendimentoIds,
      filtros,
      lanes,
      nomeOfertaAtiva,
      membros,
    ).then(() => {
      close();
      atualizarQuadro();
    });
  };

  const handleCloseModal = () => {
    if (!isLoading) {
      close();
    }
  };

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => handleCloseModal()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>{textTitle}</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <Input
              type="text"
              name="nomeOfertaAtiva"
              disabled={isLoading}
              placeholder="Nome da oferta ativa"
              onChange={(e) => setNomeOfertaAtiva(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <SelectCorretoresOrGrupoCorretoresInput
              label="Membros (Corretores ou Grupo de Corretores)"
              enableHelperTooltip
              enableTagRender
              disabled={isLoading}
              placeholder=" Corretores ou Grupo de Corretores"
              onChange={(value) => {
                setMembros(
                  value.map((membro) => ({
                    id: membro.key,
                    nome: membro.children,
                    tipo: membro.options.type,
                  })),
                );
              }}
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4"
            hidden={!isLoading}>
            <ProgressBarAcaoEmLote
              usuarioId={localStorage.getItem('icaseUsuarioId')}
            />
          </div>

          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              disabled={!allowConfirm}
              onClick={() => setShowSecondConfirmationModal(true)}
              isloading={`${isLoading}`}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              disabled={isLoading}
              onClick={() => handleCloseModal()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <SecondConfirmationModal
        open={showSecondConfirmationModal}
        total={selectedTotal}
        onCancel={() => {
          setShowSecondConfirmationModal(false);
        }}
        onConfirm={() => {
          onConfirmSubmit();
          setShowSecondConfirmationModal(false);
        }}
      />
    </Fragment>
  );
}

const SecondConfirmationModal = ({open, onConfirm, onCancel, total}) => {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => {
          onCancel();
        }}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>Confirmar transformação de atendimento para oferta ativa</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span>
              <strong>Atenção:</strong> Você está prestes a transformar{' '}
              {total > 99
                ? 'uma grande quantidade de atendimentos'
                : `${total} atendimentos`}
              em uma oferta ativa.
              <br /> Tem certeza de que deseja continuar?
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span style={{fontSize: 13}}>
              Observação: Dependendo do volume de dados, este processo pode
              levar alguns minutos.
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => onConfirm()}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              onClick={() => onCancel()}>
              <Icon name="times" />
              Cancelar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default ModalTransoformarAtendimentoEmOfertaAtiva;
