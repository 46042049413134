import { useEffect, useState, useCallback, useMemo } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import debounce from "lodash.debounce";

let controller;
let canceledRequest = false;

export default function usePessoaDropdown(tipo, nome, pessoaId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const cancelRequest = () => {
    if (controller) {
      canceledRequest = true;
      controller.abort();
    }
  }

  const onLoad = useCallback(
    async (params) => {
      setIsLoading(true);

      controller = new AbortController();
      const signal = controller.signal;

      let filterTipo = '';
      if (tipo && !params) {
        filterTipo = `?tipo=${tipo}`;
      } else if (params && params.tipo !== undefined && params.tipo !== null) {
        filterTipo = `?tipo=${params.tipo}`;
      }

      let filterNome = '';
      if (nome && !params) {
        filterNome = `&nome=${nome}`;
      } else if (
        params &&
        params.nome !== undefined &&
        params.nome !== null &&
        filterTipo.length >= 1
      ) {
        filterNome = `&nome=${params.nome}`;
      } else if (
        params &&
        params.nome !== undefined &&
        params.nome !== null &&
        filterTipo.length === 0
      ) {
        filterNome = `?nome=${params.nome}`;
      }

      let filterPessoaId = '';
      if (params?.pessoaId && filterTipo.length === 0 && filterNome.length === 0) {
        filterPessoaId = `?pessoaId=${params.pessoaId}`;
      } else if (params?.pessoaId && (filterTipo.length > 0 || filterNome.length > 0)) {
        filterPessoaId = `&pessoaId=${params.pessoaId}`;
      }

      try {
        await api
          .get(`${url.DROPDOWN.PESSOA()}${filterTipo}${filterNome}${filterPessoaId}`, { signal })
          .then((res) => {
            canceledRequest = false;
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        if (!canceledRequest) setIsLoading(false);
      }
    },
    [tipo, nome],
  );

  useEffect(() => {
    const params = { tipo, nome, pessoaId };
    onLoad(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo, nome, onLoad]);

  const debouncedChangeHandler = useMemo(
    () => debounce(onLoad, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      cancelRequest();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    data,
    isLoading,
    onLoad: debouncedChangeHandler,
    cancelRequest,
  };
}
