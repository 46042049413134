import React, { useState, useRef, Fragment } from 'react';
import  { Form, Input, Tooltip, Table, Select, Checkbox } from 'antd';
import EmptyBox from '~/components/EmptyBox';
import Page from '~/components/Page';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Button from '~/shared/Button';
import { Icon } from 'semantic-ui-react';
import useEmpresaIntegracao from '~/actions/Empresa/useEmpresaIntegracao';
import urlPattern from '~/shared/urlPattern';
import ModalConfirm from '~/components/ModalConfirm';

function EmpresaIntegracao() {
  const [loadTable, setloadTable] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const ref = useRef();
  const [form] = Form.useForm();

  const text = {
    display: <span>Visualizar integração de empresa</span>,
    delete: <span>Excluir integração de empresa</span>,
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      visible: false
    },
    {
      title: 'ID Empresa',
      dataIndex: 'codigo_empresa',
      key: 'codigo_empresa',
    },
    {
      title: 'Empresa',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Importar Fotos',
      dataIndex: 'importarFotos',
      key: 'importarFotos',
      render: (row) => {
        return (
          <Checkbox
            disabled={true}
            checked={row}
          />
        )
      },
    },
    {
      title: 'Full Sync',
      dataIndex: 'fullSync',
      key: 'fullSync',
      render: (row) => {
        return (
          <Checkbox
            disabled={true}
            checked={row}
          />
        )
      },
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          <Tooltip placement="topRight" title={text.delete}>
            <i
              onClick={() => handleModalConfirm(row.id)}
              className="ri-delete-bin-line linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </Tooltip>
        </div>
      ),
    },
  ];


  const { data, empresas, isLoading, onFiltros, save, remove} = useEmpresaIntegracao();

  const isEmpty = !isLoading && !data?.length;

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setId(id);
  }

  function handleRemove() {
    setloadTable(true);
    remove(id);
    setOpenModalConfirm(false);
    setloadTable(false);
  }

  function onSave() {
    form
    .validateFields()
    .then(async (dados) => {
      setLoading(true);
      save(dados);
      setLoading(false);
    })
  }

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de integração de empresa</h5>
                <p className="category">Manutenção de integração de empresa</p>
                <Form
                  form={form}
                  scrollToFirstError
                >
                <Row>
                  <Col xs="12" md="4">
                    <Form.Item
                    className="my-2"
                    name="empresaId"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar a empresa'
                      },
                    ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                          .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                          .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                        }
                        placeholder=" Empresa"
                        >
                      {empresas.map((item) => (
                        <Select.Option key={item.id} value={item.id}>{item.nome}</Select.Option>
                      ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="4">
                    <Form.Item
                      className="my-2"
                      name="url"
                      rules={[
                        {
                          required: true,
                          message: 'Informe uma URL correta!',
                          pattern: urlPattern
                        },
                      ]}>
                      <Input placeholder=" URL" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                <Col xs="1" md="1" className="my-2">
                  <p className="heading-small text-muted mb-2">
                    <small>Importar Fotos</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="importarFotos"
                    valuePropName="checked">
                    <Checkbox
                      options={{
                        label: 'Importar Fotos',
                        value: 1
                      }}
                      onChange={value => {}}
                    />
                  </Form.Item>
                </Col>
                <Col xs="1" md="1" className="my-2">
                  <p className="heading-small text-muted mb-2">
                    <small>Full Sync</small>
                  </p>
                  <Form.Item
                    className="my-2"
                    name="fullSync"
                    valuePropName="checked">
                    <Checkbox
                      options={{
                        label: 'Full Sync',
                        value: 1
                      }}
                      onChange={value => {}}
                    />
                  </Form.Item>
                </Col>
                </Row>
                <Row>
                  <Col xs="12" md="4">
                    <Button
                    onClick={onSave}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    color="blue"
                    isloading={loading.toString()}
                    loadtext="Aguarde..."
                    >
                    <Icon name="save" />
                    SALVAR
                  </Button>
                  </Col>
                </Row>
                </Form>

              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="nomeTipoImovel">
                              <Input placeholder=" Nome" onChange={el => setNomeEmpresa(el.target.value)}/>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <Button
                              className="my-2"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => {
                                onFiltros(nomeEmpresa)
                              }}>
                              <Icon name="search" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              loading={loading}
              open={openModalConfirm}
              confirm={handleRemove}
              close={() => setOpenModalConfirm(false)}
              title="Atenção"
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default EmpresaIntegracao;
