import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import ImovelExport from '~/views/private/ImovelExport';

export const PublicExportRoutes = ({match}) => (
  <Fragment>
    <Switch>
      <Route
        path="/public-export/imovel-export/imovel/:imovelId/user/:userId/empresa/:empresaId"
        exact
        component={ImovelExport}
      />
    </Switch>
  </Fragment>
);
