import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Icon } from 'semantic-ui-react';
import { Table } from 'antd';

import ModalConfirm from '~/components/ModalConfirm';
import ModalFilial from '~/components/ModalFilial';

import Button from '~/shared/Button';
import formatedMask from '~/shared/formatedMask';
import Notify from '~/shared/Notify';
import EmptyBox from '~/components/EmptyBox';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import Page from '~/components/Page';
import url from '~/infra/urls';

const TABS = {
  EMPRESA: 1,
  FILIAL: 2,
};

const EmpresaFilial = ({ setActiveTab, empresaId }) => {
  const [loadTable, setloadTable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filialId, setFilialId] = useState(undefined);
  const [openModalFilial, setOpenModalFilial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataAllCompany, setDataAllCompany] = useState([]);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const getCompany = async () => {
    await api
      .get('empresas/all')
      .then((res) => {
        const company = res.data.find((item) => item.id === empresaId);
        setDataAllCompany(company?.filiais || []);
        setIsLoading(false);
      })
      .catch((err) => {
        Notify(
          'error',
          pt.comum.atencao,
          'Problema ao carregar filiais, favor tente novamente mais tarde!',
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleModalConfirm(id) {
    setFilialId(id);
    setOpenModalConfirm(true);
  }

  async function handleConfirmDelete() {
    if (empresaId && filialId) {
      setLoading(true);
      setloadTable(true);

      await api
        .delete(`${url.EMPRESAS.DELETAR()}/${filialId}`)
        .then((response) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
          getCompany();
          setloadTable(false);
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          setloadTable(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function voltar() {
    setActiveTab(TABS.EMPRESA);
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      responsive: ['md', 'lg'],
      render: (cnpj) => <span>{cnpj ? formatedMask(cnpj, '##.###.###/####-##') : ''}</span>,
    },
    {
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
  ];

  function novaFilial() {
    setOpenModalFilial(true);
  }

  function closeModalFilial() {
    getCompany();
    setOpenModalFilial(false);
  }

  const isEmpty = !isLoading && !dataAllCompany.length;

  return (
    <>
      <Page loadPage={isLoading}>
        <Card className="card-custom card-shadowless rounded-top-0 mb-0 p-4">
          <CardBody>
            <Row>
              <Col xs="12" md="3">
                <div className="my-2 p-2">
                  <Button
                    onClick={() => novaFilial()}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    color="violet">
                    <Icon name="plus" />
                    Nova Filial
                  </Button>
                </div>
              </Col>
            </Row>
            {!isEmpty && !isLoading && (
              <Fragment>
                <div className="my-2">
                  <Table
                    rowKey={(row) => row.id}
                    columns={columns}
                    dataSource={dataAllCompany}
                    loading={loadTable}
                  />
                </div>
              </Fragment>
            )}
            {isEmpty && (
              <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
            )}
            <Row>
              <Col xs="12" md="3">
                <Button
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  className="mt-1"
                  onClick={() => voltar()}>
                  <Icon name="undo" />
                  Voltar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ModalConfirm
          isDelete
          icon="warning sign"
          loading={loading}
          open={openModalConfirm}
          confirm={handleConfirmDelete}
          close={handleCloseModal}
          message="Deseja realmente excluir esse registro?"
        />

        <ModalFilial
          open={openModalFilial}
          close={() => closeModalFilial()}
          empresaId={empresaId}
        />
      </Page>
    </>
  );
};

export default EmpresaFilial;
