import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useTimeFavorito(nome) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const filter = nome ? `?nome=${nome}` : '';

  const onLoad = useCallback(async () => {
    try {
      const data = await api.get(`${url.DROPDOWN.TIME_FAVORITO()}${filter}`);
      setData(data.data || []);
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
