import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useBairro(current, pageSize, nomeBairro, cidadeId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async (params) => {
      setIsLoading(true);
      const filterCurrent = current ? current : null;
      const tamanhoPagina = pageSize ? pageSize : null;
      const filterNomeBairro = nomeBairro ? nomeBairro : null;
      let filtroCidadeId = cidadeId ? cidadeId : null;
      let filter = null;

      if (filterCurrent && !params?.current) filter = `?numeroPagina=${filterCurrent}`;

      if (params?.current) filter = `?numeroPagina=${params.current}`;

      if (tamanhoPagina && !params?.pageSize) {
        if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
        else filter = `?tamanhoPagina=${tamanhoPagina}`;
      }

      if (params?.pageSize) {
        if (filter.length > 0) filter += `&tamanhoPagina=${params.pageSize}`;
        else filter = `?tamanhoPagina=${params.pageSize}`;
      }

      if (filterNomeBairro) {
        if (filter.length > 0) filter += `&nome=${filterNomeBairro}`;
        else filter = `?nome=${filterNomeBairro}`;
      }

      if (filtroCidadeId && !params?.cidadeId) {
        if (filter.length > 0) filter += `&cidadeId=${filtroCidadeId}`;
        else filter = `?cidadeId=${filtroCidadeId}`;
      }

      if (params?.cidadeId) {
        if (filter.length > 0) filter += `&cidadeId=${params.cidadeId}`;
        else filter = `?cidadeId=${params.cidadeId}`;
      }

      if (filter) {
        try {
          await api
            .get(`${url.BAIRRO.CONSULTA()}${filter ? filter : ''}`)
            .then((res) => {
              setData(res?.data?.data || []);
              setQuantidade(res?.data?.total);
            });
        } catch (e) {
          setData([]);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [pageSize, nomeBairro, cidadeId, current],
  );

  useEffect(() => {
    const params = { current, pageSize, cidadeId };
    onLoad(params);
  }, [current, pageSize, cidadeId, onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
