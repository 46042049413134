import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody} from 'reactstrap';

import {Modal, Button, Icon} from 'semantic-ui-react';
import {
  Form,
  InputNumber,
  Select,
  Switch,
  Input,
  Upload,
  Divider,
  Table,
  Space,
  Popconfirm,
  Spin,
} from 'antd';
import {
  InboxOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {Row, Col} from 'reactstrap';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls';
import {Tabs} from '~/components/Tabs';
import {Tab} from '~/components/Tabs';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import {saveAs} from 'file-saver';

const {TextArea} = Input;
const {Dragger} = Upload;

const {Option} = Select;

const TABS = {
  GERAL: 1,
  ANEXOS: 2,
};

function ModalCheckListAnalise({open, onSuccesso, onCancel, row, readOnly}) {
  const [isLoading, setIsLoading] = useState(false);
  const [possuiDependente, setPossueDependente] = useState(false);
  const [qtdParticipantes, setQtdParticipantes] = useState(1);
  const [formRef] = Form.useForm();
  const [activeTab, setActiveTab] = useState(TABS.GERAL);
  const [checklistId, setChecklistId] = useState(null);
  const [filesList, setFilesList] = useState([]);

  const carregaArquivos = async () => {
    setIsLoading(true);

    await api
      .get(url.ATENDIMENTO.CHECKLIST_ALL_FILES(checklistId))
      .then((res) => {
        const newFileList =
          res?.data &&
          res.data.map((f) => {
            return {
              uid: f.id,
              name: f.nome,
              status: 'done',
              url: f.arquivo,
              tipo: f.tipo,
            };
          });

        if (newFileList && newFileList.length > 0) setFilesList(newFileList);
        else setFilesList([]);

        setIsLoading(false);
      })
      .catch((err) => {
        setFilesList([]);
        setIsLoading(false);
        const {erros} = err.response.data;
        if (erros) {
          for (const item in erros) {
            Notify('error', pt.comum.atencao, erros[item]);
          }
        }
      });
  };

  const onSave = async () => {
    if (checklistId && activeTab === TABS.ANEXOS) {
      formRef.resetFields();
      onSuccesso();
    } else {
      setIsLoading(true);
      const valores = formRef.getFieldsValue();
      const payload = {...valores, atendimentoId: row?.id};

      await api
        .post(url.ATENDIMENTO.ATUALIZAR_CHECKLIST(), payload)
        .then((res) => {
          setChecklistId(res?.data?.data?.id || null);
          setIsLoading(false);
          Notify('success', '', pt.geral.progresso_com_sucesso);
          setActiveTab(TABS.ANEXOS);
        })
        .catch((err) => {
          setIsLoading(false);
          const {erros} = err.response.data;
          if (erros) {
            for (const item in erros) {
              Notify('error', pt.comum.atencao, erros[item]);
            }
          }
        });
    }
  };

  function valoresInicias() {
    if (row?.checkListAnaliseFinanceira) return row?.checkListAnaliseFinanceira;

    return {qtdParticipantes: qtdParticipantes};
  }

  useEffect(() => {
    setChecklistId(row?.checkListAnaliseFinanceira?.id || null);
    setPossueDependente(
      row?.checkListAnaliseFinanceira?.possuiDependente || false,
    );
    setQtdParticipantes(row?.checkListAnaliseFinanceira?.qtdParticipantes || 0);
    setActiveTab(TABS.GERAL);

    formRef.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (activeTab === TABS.ANEXOS) carregaArquivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const atualizaArquivoRequest = ({onSuccess, onError, file}) => {
    const formData = new FormData();
    formData.append('checklistId', checklistId);
    formData.append('atendimentoId', '');
    formData.append('arquivo', file);

    api
      .post(url.ATENDIMENTO.CHECKLIST_UPLOAD_FILE(), formData)
      .then((res) => {
        file.url = res.url;
        onSuccess(null, file);
      })
      .catch((err) => {
        const {error} = err.response?.data;
        let msgErro = '';

        if (error) {
          for (const item in error) {
            msgErro += item;
          }
        }

        onError(msgErro);
      });
  };

  const onRemoveFile = (uid) => {
    api
      .delete(url.ATENDIMENTO.CHECKLIST_DELETE_FILE(uid))
      .then(carregaArquivos)
      .catch((err) => {
        Notify('error', '', 'Oops erro ao excluir arquivo!');
      });
  };

  const atualizaTipoDocumento = (uid, tipo) => {
    api
      .put(url.ATENDIMENTO.CHECKLIST_ATUALIZA_TIPO_DOCUMENTO(uid, tipo))
      .then(carregaArquivos)
      .catch((err) => {
        Notify('error', '', 'Oops erro ao atualizar arquivo!');
      });
  };

  const props = {
    name: 'file',
    multiple: true,
    accept:
      'image/png,image/gif,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.png,.gif,.jpng,.pdf,.doc,.docx,.xls,.xlsx',
    showUploadList: false,
    customRequest: atualizaArquivoRequest,
    onChange(info) {
      setFilesList(info?.fileList);

      if (
        info?.fileList?.every((e) => e.status !== 'uploading') &&
        info.file.status !== 'removed'
      )
        carregaArquivos();

      const {status} = info.file;

      if (status === 'error') {
        Notify('error', '', `${info.file.name} erro ao fazer Upload.`);
      }
    },
  };

  const columnsFiles = [
    {
      title: 'Arquivo',
      dataIndex: 'name',
      key: 'name',
      render: (text, row, index) => {
        return row.status === 'uploading' ? (
          <Spin />
        ) : (
          <div style={{maxWidth: '250px'}}>
            {/* eslint-disable-next-line */}
            <a key={index} href={row.url} target="_blank">
              {text?.trim()?.substring(0, 200)}{' '}
              {text?.trim()?.length > 200 ? '...' : ''}
            </a>
          </div>
        );
      },
    },
    {
      title: 'Tipo de Documento',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (text, row, index) => {
        return (
          <Select
            key={index}
            disabled={readOnly}
            style={{width: '300px'}}
            allowClear={true}
            value={row.tipo}
            getPopupContainer={(node) => node.parentNode}
            onChange={(value) => atualizaTipoDocumento(row.uid, value)}
            placeholder="Tipo Documento">
            <Option value={1}>RG</Option>
            <Option value={2}>CPF</Option>
            <Option value={3}>Comprovante Endereço</Option>
            <Option value={4}>Holerite</Option>
            <Option value={5}>Holerite Participante</Option>
            <Option value={6}>Certidão Nascimento/Casamento</Option>
            <Option value={7}>Extrato FGTS</Option>
            <Option value={8}>FGTS Desbloqueado</Option>
            <Option value={9}>Imposto Renda</Option>
            <Option value={10}>Ficha SIGE</Option>
            <Option value={11}>CTPS</Option>
            <Option value={12}>Extrato Bancário</Option>
            <Option value={13}>RG Dependente</Option>
            <Option value={14}>CPF Dependente</Option>
          </Select>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record, index) => (
        <Space key={index} size="middle">
          <CloudDownloadOutlined
            onClick={() => saveAs(record.url, record.name)}
          />
          {!readOnly && (
            <Popconfirm
              getPopupContainer={(node) => node.parentNode}
              onConfirm={() => onRemoveFile(record.uid)}
              title="Confirma a exclusão do anexo?"
              okText="Sim"
              cancelText="Não">
              <DeleteOutlined />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="large"
        dimmer="blurring"
        open={open}
        onClose={() => onSuccesso()}>
        <Modal.Header>
          <Row>
            <Col xs="12" md="12">
              <h6 className="title">Análise Financeira</h6>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Tabs activeTab={activeTab} setTab={(tab) => setActiveTab(tab)}>
            <Tab title="Itens" desc="">
              {isLoading && <SkeletonTableBasic />}
              {!isLoading && (
                <Card className="card-custom card-shadowless rounded-top-0 mb-0 pt-0">
                  <CardBody>
                    <Row>
                      <Col>
                        <Form
                          form={formRef}
                          initialValues={valoresInicias()}
                          scrollToFirstError>
                          <Row>
                            <Col xs="12" md="6">
                              <p className="heading-small text-muted mb-2">
                                <small>Quantidade Participantes</small>
                              </p>
                              <Form.Item
                                className="my-2"
                                name="qtdParticipantes">
                                <InputNumber
                                  disabled={readOnly}
                                  min={0}
                                  max={3}
                                  onChange={(qtd) => setQtdParticipantes(qtd)}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {qtdParticipantes > 0 &&
                            Array.from(Array(qtdParticipantes).keys()).map(
                              (q, index) => {
                                return (
                                  <Row key={index}>
                                    <Divider plain>
                                      {index + 1}º Participante
                                    </Divider>
                                    <Col key={index} xs="12" md="3">
                                      <p className="heading-small text-muted mb-2">
                                        <small>Quantidade Renda</small>
                                      </p>
                                      <Form.Item name={`qtdRenda${index + 1}`}>
                                        <InputNumber
                                          disabled={readOnly}
                                          min={0}
                                          max={3}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs="12" md="3" className="my-2">
                                      <p className="heading-small text-muted mb-0">
                                        <small>Utiliza FGTS ?</small>
                                      </p>
                                      <Form.Item
                                        name={`utilizaFgts${index + 1}`}
                                        valuePropName="checked">
                                        <Switch
                                          disabled={readOnly}
                                          checkedChildren="Sim"
                                          unCheckedChildren="Não"
                                          size="small"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs="12" md="3" className="my-2">
                                      <p className="heading-small text-muted mb-0">
                                        <small>Possue 3 anos de CLT ?</small>
                                      </p>
                                      <Form.Item
                                        name={`possueTresAnosClt${index + 1}`}
                                        valuePropName="checked">
                                        <Switch
                                          disabled={readOnly}
                                          checkedChildren="Sim"
                                          unCheckedChildren="Não"
                                          size="small"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                );
                              },
                            )}
                          <Row>
                            <Divider plain>Titular</Divider>
                            <Col xs="12" md="5" className="my-2">
                              <p className="heading-small text-muted mb-2">
                                <small>Estado Civil</small>
                              </p>
                              <Form.Item name="estadoCivil">
                                <Select
                                  disabled={readOnly}
                                  style={{width: '300px'}}
                                  getPopupContainer={(node) => node.parentNode}
                                  placeholder="Selecione o estado civil">
                                  <Option value={0}>Indefinido</Option>
                                  <Option value={1}>Solteiro</Option>
                                  <Option value={2}>Casado</Option>
                                  <Option value={3}>Divorciado</Option>
                                  <Option value={4}>Viúvo</Option>
                                  <Option value={5}>União Estável</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs="12" md="2" className="my-2">
                              <p className="heading-small text-muted mb-0">
                                <small>Utiliza FGTS ?</small>
                              </p>
                              <Form.Item
                                name="utilizaFgts"
                                valuePropName="checked">
                                <Switch
                                  disabled={readOnly}
                                  checkedChildren="Sim"
                                  unCheckedChildren="Não"
                                  size="small"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs="12" md="2" className="my-2">
                              <p className="heading-small text-muted mb-0">
                                <small>Possue 3 anos de CLT ?</small>
                              </p>
                              <Form.Item
                                name="possueTresAnosClt"
                                valuePropName="checked">
                                <Switch
                                  disabled={readOnly}
                                  checkedChildren="Sim"
                                  unCheckedChildren="Não"
                                  size="small"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs="12" md="3" className="my-2">
                              <p className="heading-small text-muted mb-0">
                                <small>Possui Dependente ?</small>
                              </p>
                              <Form.Item
                                name="possuiDependente"
                                valuePropName="checked">
                                <Switch
                                  disabled={readOnly}
                                  checkedChildren="Sim"
                                  unCheckedChildren="Não"
                                  size="small"
                                  onChange={(value) =>
                                    setPossueDependente(value)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            {possuiDependente && (
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-0">
                                  <small>Residência Compartilhada ?</small>
                                </p>
                                <Form.Item
                                  name="residenciaCompartilhada"
                                  valuePropName="checked">
                                  <Switch
                                    disabled={readOnly}
                                    checkedChildren="Sim"
                                    unCheckedChildren="Não"
                                    size="small"
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {possuiDependente && (
                              <Col xs="12" md="3" className="my-2">
                                <p className="heading-small text-muted mb-0">
                                  <small>Filho menor de 21 anos ?</small>
                                </p>
                                <Form.Item
                                  name="filhoMenor21Anos"
                                  valuePropName="checked">
                                  <Switch
                                    disabled={readOnly}
                                    checkedChildren="Sim"
                                    unCheckedChildren="Não"
                                    size="small"
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            <Col xs="12" className="my-2">
                              <p className="heading-small text-muted mb-2">
                                <small>Observação</small>
                              </p>
                              <Form.Item name="obsDependente">
                                <TextArea
                                  disabled={readOnly}
                                  placeholder={'Obs'}
                                  rows={4}
                                  maxLength={500}
                                  showCount
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Tab>
            {checklistId && (
              <Tab title="Anexos" desc="">
                {isLoading && <SkeletonTableBasic />}
                {!isLoading && (
                  <>
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0">
                      <CardBody>
                        <Row>
                          <Col xs="12">
                            <div className="p-2">
                              <Dragger
                                {...props}
                                disabled={readOnly}
                                fileList={filesList}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                  Clique aqui ou arraste arquivos para upload.
                                </p>
                              </Dragger>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0">
                      <CardBody>
                        <Row>
                          <Col xs="12">
                            <div className="p-2">
                              <Table
                                rowKey="uid"
                                dataSource={filesList}
                                columns={columnsFiles}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </>
                )}
              </Tab>
            )}
          </Tabs>
          <Row>
            <Col xs="12" md="4">
              <Button
                onClick={onSave}
                icon
                labelPosition="left"
                size="medium"
                fluid
                className="mt-3"
                color="violet">
                <Icon name="check" />
                {activeTab === TABS.ANEXOS ? 'Fechar' : 'Salvar'}
              </Button>
            </Col>
            <Col xs="12" md="4">
              <Button
                icon
                labelPosition="left"
                size="medium"
                className="mt-3"
                fluid
                onClick={() => onCancel()}>
                <Icon name="angle left" />
                Voltar
              </Button>
            </Col>
          </Row>
          {/* <Row>
            <Col xs="12" md="4">
              <Button
                icon
                labelPosition="left"
                size="medium"
                className="mt-3"
                fluid
                onClick={() => onCancel()}>
                <Icon name="angle left" />
                Voltar
              </Button>
            </Col>
            <Col xs="12" md="4">
              <Button
                size="medium"
                className="mt-3"
                fluid
                disabled={isLoading || readOnly}
                labelPosition="left"
                onClick={onSave}
                color="blue">
                <Icon name="checkmark left" />
                {activeTab === TABS.ANEXOS ? 'Fechar' : 'Salvar'}
              </Button>
            </Col>
          </Row> */}
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalCheckListAnalise;
