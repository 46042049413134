import React from "react";
import { View, Image, StyleSheet } from '@react-pdf/renderer';

import logo from '~/assets/img/logo_latitude_azul.png';

const styles = StyleSheet.create({
  View: {
    position: 'absolute',
    left: 20,
    top: 20,
  },
  ImageLogo: {
    maxWidth: 233,
    maxHeight: 72,
  },
});

const Header = ({logoEmpresaUrl}) => (
  <View style={styles.View} fixed>
    <Image source={logoEmpresaUrl || logo} style={styles.ImageLogo}  />
  </View>
);

export default Header;
