import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useGrupoCorretoresByRole(filtro) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterFiltro = filtro ? filtro : null;
    let filter = null;

    if (filterFiltro) filter = `?filtro=${filterFiltro}`;

    try {
      await api
        .get(`${url.GRUPO_CORRETORES.CORRETORESBYROLE()}${filter ? filter : ''}`)
        .then((res) => {
          let corretoresAtivos = res?.data.filter((corretor) => !corretor.excluido) || []
          let corretoresInativos = res?.data.filter((corretor) => corretor.excluido).map((corretor) => {
            corretor.nome = `${corretor.nome.toString()} (inativo)`
            return corretor
          }) || []

          if(corretoresInativos.length > 0){
            corretoresInativos.unshift({isSeparator: true, id: 'separadorInativo', nome:'Inativos'})
          }

          setData([...corretoresAtivos, ...corretoresInativos] || []);
          setQuantidade(res?.data.length || 0);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [filtro]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
