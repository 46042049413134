export const tipoDistribuicao = [
  {label: 'Enviar para o corretor responsável pela captação', value: 1},
  {label: 'Enviar para o receptor de lead da empresa', value: 2},
  {label: 'Distribuir entre corretores da escala', value: 3},
  {label: 'Distribuir entre corretores da empresa', value: 4},
  {label: 'Distribuir entre equipes', value: 5},
  {label: 'Enviar para usuário(s) específico(s)', value: 6},
  {label: 'Enviar para tipos de usuário(s) específico(s)', value: 7},
 // {label: 'Distribuir entre empresas', value: 8},
];

export const camposFacebook = [
  {label: 'form_name', value: 'form_name'},
  {label: 'page_name', value: 'page_name'},
  {label: 'campaign_name', value: 'campaign_name'},
];


export const camposLatitude = [
  {label: 'Nome', value: 'Nome'},
  {label: 'Celular', value: 'Celular'},
  {label: 'Telefone', value: 'Telefone'},
  {label: 'E-mail', value: 'Email'},
  {label: 'Observações', value: 'Mensagem'},
  {label: 'Cód. Imóvel', value: 'CodImovel'},
];