import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import formatCurrency from '~/shared/formatCurrency';


import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const today = moment();
const data = moment().format('DD/MM/YYYY');
const hora = moment(today).format('HH:mm:ss');

const styles = StyleSheet.create({
  Page: {
    backgroundColor: '#FFF',
    paddingBottom: '50px'
  },
  Container: {
    padding: 15,
    width: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Section: {
    flexGrow: 1,
    margin: '5px 0',
  },
  SectionHeader: {
    paddingBottom: 5,
    marginBottom: 5
  },
  DividerSection: {
    borderBottom: '1px dashed #808080',
    width: '100%',
    marginBottom: '2px'
  },
  Title: {
    fontSize: 16,
    fontWeight: 600,
    margin: '10px 0',
    textAlign: 'left',
  },
  SubtitleFirst: {
    fontSize: 14,
    textAlign: 'center',
    margin: '12px 0',
    paddingBottom: 8,
    textDecoration: 'underline'
  },
  Span: {
    fontSize: '10px',
    letterSpacing: '0.5px',
    lineHeight: '1.1',
    color: '#000',
    paddingRight: '10px'
  },
  Label: {
    fontSize: '10px',
    margin: '2px 0',
    color: '#333333',
    fontWeight: 800,
    paddingRight: '10px'
  },
  pageDate: {
    fontSize: 12,
    position: 'absolute',
    top: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
  },
  pageNumber: {
    fontSize: 12,
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  registerCount: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: '10px',
    textAlign: 'left',
  }
});

const ImovelDocumentDefault = ({ dados, nomeEmpresa }) => {
  return (
    <Document>
      <Page size="A4" style={styles.Page} orientation="landscape">
        <Text style={[styles.pageDate, styles.Label]}>
          {data} - {hora}
        </Text>
        <View style={styles.Container}>
          <View style={styles.SectionHeader}>
            <Text style={styles.Title}>
              {(nomeEmpresa || 'Locare Gestão Imobiliária').toUpperCase()}
            </Text>
          </View>

          <Text style={styles.SubtitleFirst}>
            Imóveis
          </Text>

          <View style={[styles.Row, styles.DividerSection]}>
            <Text style={[styles.Span,
            {
              minWidth: '45px',
              maxWidth: '45px'
            }]}>Codigo</Text>
            <Text style={[styles.Span,
            {
              minWidth: '155px',
              maxWidth: '155px'
            }]}>Titulo</Text>
            <Text style={[styles.Span,
            {
              minWidth: '300px',
              maxWidth: '300px'
            }]}>Descrição</Text>
            <Text style={[styles.Span,
            {
              minWidth: '80px',
              maxWidth: '80px'
            }]}>Captador</Text>
            <Text style={[styles.Span,
            {
              minWidth: '80px',
              maxWidth: '80px'
            }]}>Propietário</Text>
            <Text style={[styles.Span,
            {
              minWidth: '90px',
              maxWidth: '90px'
            }]}>Fone</Text>
            <Text style={[styles.Span,
            {
              minWidth: '90px',
              maxWidth: '90px'
            }]}>Valor</Text>
            <Text style={[styles.Span,
            {
              minWidth: '90px',
              maxWidth: '90px'
            }]}>Criado Por</Text>
            <Text style={[styles.Span,
            {
              minWidth: '90px',
              maxWidth: '90px'
            }]}>Criado Em</Text>
            <Text style={[styles.Span,
            {
              minWidth: '90px',
              maxWidth: '90px'
            }]}>Atualizado Por</Text>
            <Text style={[styles.Span,
            {
              minWidth: '90px',
              maxWidth: '90px'
            }]}>Atualizado Em</Text>
          </View>

          {dados.map((imov) => {
            return (
              <View style={styles.Row} key={imov.id}>
                <Text style={[styles.Label,
                {
                  minWidth: '45px',
                  maxWidth: '45px',
                  textAlign: 'center'
                }]}>{imov?.codImovel || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '155px',
                  maxWidth: '155px'
                }]}>{imov?.titulo || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '300px',
                  maxWidth: '300px'
                }]}>{imov?.descricao ? `${imov.descricao.trim().substring(0, 500)} ${imov.descricao.trim().length > 500 ? '...' : ''}` : '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '80px',
                  maxWidth: '80px'
                }]}>{imov?.nomeCorretor || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '80px',
                  maxWidth: '80px'
                }]}>{imov?.nomeProprietario || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '90px',
                  maxWidth: '90px'
                }]}>{imov?.celularProprietario || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '90px',
                  maxWidth: '90px'
                }]}>{(imov?.valorVenda || imov?.valorAluguel) ? `R$ ${formatCurrency(imov?.valorVenda || imov?.valorAluguel)}` : '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '90px',
                  maxWidth: '90px'
                }]}>{imov?.usuarioInclusao?.nome || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '90px',
                  maxWidth: '90px'
                }]}>{imov?.dataCriacao || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '90px',
                  maxWidth: '90px'
                }]}>{imov?.usuarioAlteracao?.nome || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '90px',
                  maxWidth: '90px'
                }]}>{imov?.dataAtualizacao || '---'}</Text>
              </View>
            );
          })}

        </View>
        <Text style={[styles.registerCount, styles.Label]} render={({ pageNumber, totalPages }) => (
          pageNumber === totalPages ? `Qde de Registros: ${dados?.length || 0}` : ''
        )} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default ImovelDocumentDefault;
