import React, {Fragment, useState, useEffect} from 'react';
import {Form, Select, Table, Tooltip} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Icon} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import usePerfil from '~/actions/Perfil/usePerfil';
import useUsuario from '~/actions/Usuario/useUsuario';

import Page from '~/components/Page';
import EmptyBox from '~/components/EmptyBox';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

const { Option } = Select;

function PerfilUsuario() {
  const [actions, setActions] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [userId, setUserId] = useState();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [perfilId, setPerfilId] = useState();
  const [dataPerfis, setDataPerfis] = useState([]);

  const { identificador } = useParams();

  const [form] = Form.useForm();

  const { data: usuarios } = useUsuario();
  const { data: perfis } = usePerfil();

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  async function getPerfisUsuario(userId) {
    setLoadTable(true);
    await api.get(url.USUARIO_PERFIL.CONSULTA_BY_USER(userId))
      .then(res => {
        setUserId(userId);
        setDataPerfis(res.data.length > 0 ? res.data : []);
        setLoadTable(false);
      })
      .catch(err => {
        setLoadTable(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  function handleUser(userId) {
    getPerfisUsuario(userId);
  }

  function handleSubmit() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          userId: dados.userId,
          perfilId: dados.roleId,
        };

        await api.post(url.USUARIO_PERFIL.CADASTRAR(), payload)
          .then(res => {
            setIsLoading(false);

            getPerfisUsuario(dados.userId);

            Notify('success', '', pt.geral.cadastro_com_sucesso);

            form.setFieldsValue({ roleId: undefined });
          })
          .catch(err => {
            setIsLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        setIsLoading(false);
      });
  }

  function handleModalConfirm(perfilId) {
    setOpenModalConfirm(true);
    setPerfilId(perfilId);
  }

  async function handleConfirmDelete() {
    if (perfilId) {
      setIsLoading(true);

      const payload = {
        userId,
        perfilId,
      };

      await api
        .delete(url.USUARIO_PERFIL.DELETAR(), {data: payload})
        .then((response) => {
          setIsLoading(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');

          getPerfisUsuario(userId);
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const columns = [
    {
      title: 'Perfil',
      dataIndex: '',
      key: '',
      render: (row) => <span key={row.id}>{row.perfil ? row.perfil.descricao : ''}</span>,
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          {excluir === true ? (
            <Tooltip placement="topRight" title="Excluir perfil">
              <i
                onClick={() => handleModalConfirm(row.perfilId)}
                className="ri-delete-bin-6-fill linkTable"
                style={{fontSize: '26px', marginRight: '10px'}}></i>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  const isEmpty = !dataPerfis.length;

  return (
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Perfis do usuário</h5>
                <p className="category">Manutenção de perfis do usuário</p>
              </CardHeader>
              <CardBody>
                <Form form={form}>
                  <Row>
                    <Col xs="12" md="6" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Usuário</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: 'Favor selecionar um usuário.',
                          },
                        ]}>
                        <Select
                          placeholder=" Usuário"
                          showSearch
                          optionFilterProp="children"
                          onChange={handleUser}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                              .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                          }>
                          {usuarios.map((item) => (
                            <Option key={item.id}>{item.nome}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs="12" md="6" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Perfil</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="roleId"
                        rules={[
                          {
                            required: true,
                            message: 'Favor selecionar ao menos um perfil.',
                          },
                        ]}>
                        <Select
                          placeholder=" Perfil"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                              .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                          }>
                          {perfis.map((item) => (
                            <Option key={item.id}>
                              {item.descricao}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                {incluir === true ? (
                  <Row>
                    <Col xs="12" className="mt-1">
                      <Button
                        onClick={handleSubmit}
                        icon
                        labelPosition="left"
                        size="medium"
                        fluid
                        className="mt-3"
                        color="blue"
                        isloading={loading.toString()}
                        loadtext="Confirmando...">
                        <Icon name="check" />
                        Confirmar
                      </Button>
                    </Col>
                  </Row>
                ) : null}

                {!isEmpty && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={dataPerfis}
                        loading={loadTable}
                        // pagination={{
                        //   current: current,
                        //   showSizeChanger: true,
                        //   defaultCurrent: 1,
                        //   total: quantidade,
                        //   responsive: true,
                        //   onChange: changePage,
                        //   onShowSizeChange: onShowSizeChange,
                        // }}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}

                <ModalConfirm
                  loading={loading}
                  open={openModalConfirm}
                  confirm={handleConfirmDelete}
                  close={handleCloseModal}
                  title="Atenção"
                  message="Deseja realmente excluir esse registro?"
                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default PerfilUsuario;
