import React, {Fragment, useEffect, useState} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import Button from '~/shared/Button';
import useRemoverAtendimentoEmLote from '~/actions/Atendimento/useRemoverAtendimentoEmLote';
import ProgressBarAcaoEmLote from '../ProgressBarAcaoEmLote';

function ModalRemoverAtendimentoEmLote({
  open,
  close,
  atendimentos,
  textTitle,
  atualizarQuadro,
}) {
  const [showSecondConfirmationModal, setShowSecondConfirmationModal] =
    useState(false);
  const [selectedTotal, setSelectedTotal] = useState(0);

  const {removerAtendimentoEmLote, isLoading} = useRemoverAtendimentoEmLote();
  useEffect(() => {
    setSelectedTotal(atendimentos?.total ?? 0);
  }, [atendimentos, selectedTotal]);

  const handleCloseModal = () => {
    if (!isLoading) {
      close();
    }
  };
  const onConfirmSubmit = () => {
    const {atendimentoIds, filtros, lanes} = atendimentos;
    const usuarioId = localStorage.getItem('icaseUsuarioId');
    removerAtendimentoEmLote(atendimentoIds, filtros, lanes, usuarioId).then(
      () => {
        // Sucesso no processo de alteração
        close(); // Fecha o modal após a alteração bem-sucedida
        atualizarQuadro();
      },
    );
  };

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => handleCloseModal()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>
              Este procedimento é{' '}
              <strong style={{color: '#FF0000'}}>irreversível</strong>, você
              confirma a <strong style={{color: '#FF0000'}}>exclusão</strong>{' '}
              dos atendimentos selecionados?
            </h5>
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4"
            hidden={!isLoading}>
            <ProgressBarAcaoEmLote
              usuarioId={localStorage.getItem('icaseUsuarioId')}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => setShowSecondConfirmationModal(true)}
              isloading={`${isLoading}`}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              disabled={isLoading}
              labelPosition="left"
              size="medium"
              onClick={() => handleCloseModal()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <SecondConfirmationModal
        open={showSecondConfirmationModal}
        total={selectedTotal}
        onCancel={() => {
          setShowSecondConfirmationModal(false);
        }}
        onConfirm={() => {
          onConfirmSubmit();
          setShowSecondConfirmationModal(false);
          close();
        }}
      />
    </Fragment>
  );
}

const SecondConfirmationModal = ({open, onConfirm, onCancel, total}) => {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => {
          onCancel();
        }}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>Confirmar remoção de atendimentos</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span>
              <strong>Atenção esta ação é irreversivel:</strong> Você está
              prestes a remover{' '}
              {total > 99
                ? 'uma grande quantidade de atendimentos.'
                : `${total} atendimentos.`}
              <br /> Tem certeza de que deseja continuar?
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span style={{fontSize: 13}}>
              Observação: Dependendo do volume de dados, este processo pode
              levar alguns minutos.
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="red"
              labelPosition="left"
              fluid
              onClick={() => onConfirm()}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              onClick={() => onCancel()}>
              <Icon name="times" />
              Cancelar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default ModalRemoverAtendimentoEmLote;
