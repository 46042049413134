/* eslint-disable */
import React, { useState } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { Form, Input } from 'antd';

import CardFooter from './CardFooter';

import Url from '~/infra/urls';
import history from '~/services/history';
import api from '~/services/api';
import Notify from '~/shared/Notify';
import Button from '~/shared/Button';

import backgroundLogin from '~/assets/img/fundo_login_latitude.png';

let userEmail = '';

function EsqueciSenha() {
  const [loading, setIsLoading] = useState(false);
  const [textTitle, setTextTitle] = useState('RECUPERAR SENHA');
  const [showPasswordRecover, setShowPasswordRecover] = useState(true);

  async function alterarSenha(values) {
    setIsLoading(true);
    userEmail = values?.email;

    await api
      .put(Url.LOGIN.RECUPERAR_SENHA(), values)
      .then(() => {
        setIsLoading(false);
        setTextTitle('AGRADECEMOS');
        setShowPasswordRecover(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const { errors } = err.response.data;

        if (errors) {
          for (const item in errors) {
            if (item.length > 0) {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            } else {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            }
          }
        }
      });
  }

  return (
    <div id="latitude-login" style={{ backgroundImage: `url(${backgroundLogin})` }}>
      <Row>
        <Col>
          <Card className="latitude-login-card">
            <CardBody className="px-3 py-4">
              <Row className="mx-1 pb-2">
                <Col xs="12">
                  <div id="latitude-login-header">
                    {textTitle}
                  </div>
                </Col>
              </Row>
              {showPasswordRecover && (
                <Form
                  initialValues={{ remember: true }}
                  onFinish={alterarSenha}
                  scrollToFirstError>
                  <Row className="mx-1">
                    <Col xs="12">
                      <p className="latitude-login-label">
                        Digite o email que você usa para acessar sua conta
                      </p>
                      <Form.Item
                        className="mb-2"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message: 'Favor inserir um E-mail válido',
                          },
                        ]}>
                        <Input placeholder=" Email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mx-1">
                    <Col xs="12">
                      <Form.Item>
                        <div className="mt-4 pt-3 text-center">
                          <Button
                            isloading={loading.toString()}
                            loadtext="Validando..."
                            fluid
                            className="my-2"
                            type="submit">
                            Enviar
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
              {!showPasswordRecover && (
                <Row className="mx-1 mb-4">
                  <Col xs="12">
                    <p className="latitude-login-label" style={{ fontWeight: '400' }}>
                      Enviamos as intruções de como redefinir sua senha para o email <b>{userEmail}</b>.
                    </p>
                    <p className="latitude-login-label pt-2" style={{ fontWeight: '400' }}>
                      Se não recebe nenhum email em 10 min, verifique se o endereço de email enviado está correto.
                    </p>
                  </Col>
                </Row>
              )}
              <Row className="mx-1 mb-3">
                <Col xs="12">
                  <CardFooter />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EsqueciSenha;
