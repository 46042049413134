/* eslint-disable */
import React from 'react';
import {notification} from 'antd';
import {Icon} from 'semantic-ui-react';

function Notify(type, title, message, width, getContainer) {
  let icone = null;
  switch (type) {
    case 'error':
      icone = <Icon color="red" name="times circle" size="big" />;
      break;
    case 'success':
      icone = <Icon color="green" name="check circle" size="big" />;
      break;
    case 'warning':
      icone = <Icon color="orange" name="exclamation circle" size="big" />;
      break;
    case 'info':
      icone = <Icon color="blue" name="info circle" size="big" />;
      break;
    default:
      icone = <Icon color="orange" name="exclamation triangle" size="big" />;
  }

  notification[type]({
    message: title ? title : 'Atenção',
    description: message,
    icon: icone,
    duration: 8,
    style: {
      width: width && width,
    },
    getContainer: getContainer
  });
}

export default Notify;
