import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import CardUpload from '~/components/CardUpload';

import history from '~/services/history';

const antButton = <Button icon={<UploadOutlined />}>
  Buscar imagens
</Button>;

function Imagens({
  modo,
  condominioId,
  listImages,
  initialValuesImages,
  generateInitialValueImages,
  setInitialValuesImages,
  onVoltar,
  identificador,
}) {
  function voltar() {
    if (onVoltar) {
      onVoltar();
    } else if (identificador) {
      history.push(`/app/condominios/${identificador}`);
    } else {
      history.push('/app/condominios');
    }
  }

  return (
    <div className="px-4 mb-4">
      <Row>
        <Col xs="12">
          <CardUpload
            modo={modo}
            multiFile
            antButton={antButton}
            dataImages={listImages}
            apiURL="/condominios/imagens"
            id={condominioId}
            endPoint="condominio"
            nameIdInFormDataPost="condominioId"
            nameIdInFormDataPut="CondominioImagemId"
            voltar={() => voltar()}
            showDescricao={true}
            showDestaque={true}
            descriptionDestaque="Planta"
            showSuperDestaque={false}
            showPublicavel={true}
            initialValuesImages={initialValuesImages}
            generateInitialValueImages={(value) => generateInitialValueImages(value)}
            setInitialValuesImages={(value) => setInitialValuesImages(value)}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Imagens;