import React, {Fragment} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import {Modal as AntdModal} from 'antd';
import './index.css';

function ModalGenerico({
  open,
  close,
  size,
  title = undefined,
  children,
  requireConfirmation = false,
}) {
  const [showConfirmCloseModal, setShowConfirmCloseModal] =
    React.useState(false);

  function handleClose() {
    if (requireConfirmation) {
      setShowConfirmCloseModal(true);
      return;
    }

    close();
  }

  function handleConfirmClose() {
    setShowConfirmCloseModal(false);
    close();
  }

  return (
    <Fragment>
      <Modal size={size || 'large'} open={open} onClose={handleClose}>
        {close && title && (
          <Modal.Header>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 20,
                  color: '#1D1F8C',
                }}>
                {title}
              </span>
              <Icon
                style={{cursor: 'pointer'}}
                name="times"
                onClick={handleClose}
              />
            </div>
          </Modal.Header>
        )}
        {close && !title && (
          <Modal.Header>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Icon
                style={{cursor: 'pointer'}}
                name="times"
                onClick={handleClose}
              />
            </div>
          </Modal.Header>
        )}

        <Modal.Content>
          <div className="p-2 over-scroll">{children}</div>
        </Modal.Content>

        {requireConfirmation && (
          <AntdModal
            centered
            open={showConfirmCloseModal}
            closable={false}
            onOk={handleConfirmClose}
            onCancel={() => setShowConfirmCloseModal(false)}
            okType="danger"
            okText="Fechar">
            <p>
              Deseja realmente fechar esta janela? Os dados não salvos serão
              perdidos.
            </p>
          </AntdModal>
        )}
      </Modal>
    </Fragment>
  );
}

export default ModalGenerico;
