import React, {Fragment, useState, useRef, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {Form, Input, Table} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

import Page from '~/components/Page';
import useFormacoes from '~/actions/Formacoes/useFormacoes';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import {Icon} from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(row, identificador) {
  history.push({
    pathname: `/app/formacoes/manutencao/${MODO.EDITAR}`,
    state: {row, identificador},
  });
}

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/formacoes/manutencao/${MODO.CADASTRAR}`,
    state: {identificador},
  });
}

function ConsultaFormacao() {
  const [actions, setActions] = useState([]);
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [formacaoId, setFormacaoId] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [nomeFormacao, setNomeFormacao] = useState('');
  const { identificador } = useParams();

  const ref = useRef();
  const {isLoading, data, quantidade, onLoad} = useFormacoes(
    current,
    pageSize,
    nomeFormacao
  );

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    const {nomeFormacao} = ref.current.getFieldsValue();

    setNomeFormacao(nomeFormacao);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setFormacaoId(id);
  }

  async function handleConfirmDelete() {
    if (formacaoId) {
      setLoading(true);

      await api
        .delete(`${url.FORMACOES.DELETAR()}/${formacaoId}`)
        .then(() => {
          onLoad({
            current,
            pageSize,
            nomeFormacao,
          });
          setLoading(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch(() => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
  ];

  if (editar) {
    columns.push({
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row, identificador)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  if (excluir) {
    columns.push({
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de formação</h5>
                <p className="category">Manutenção de formação</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="nomeFormacao">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <Button
                              className="mb-1"
                              size="tiny"
                              color="green"
                              style={{ marginTop : "0.45rem"}}
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${quantidade | 0})`}</h5>
                {incluir === true ? (
                  <HeaderFiltros
                    navToNovo={() => navToNovo(identificador)}
                    description="Nova Formação"
                  />
                ) : null}
              </CardHeader>
              <CardBody>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={defaultPagination(current, quantidade, changePage)}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaFormacao;
