import React, { useCallback, useEffect } from 'react'
import { LocalStorageContext } from '~/hooks/localstorage/localStorageContext'

const useLocalStorage = (key, initialValue) => {
  const context = React.useContext(LocalStorageContext);
  const localStorageItem = context.getLocalStorageItem(key);
  const [storedValue, setStoredValue] = React.useState(localStorageItem.value || initialValue);

  useEffect(() => {
    const listener = localStorageItem.subscribe((value) => {
      setStoredValue(value);
    });

    return () => {
      listener.unsubscribe();
    }
  }, [storedValue, setStoredValue, localStorageItem]);

  const setValue = useCallback((value) => {
    localStorageItem.next(value);
  }, [localStorageItem]);

  return [
    storedValue,
    setValue
  ];
}

export default useLocalStorage;
