import Logo from '~/assets/img/logo_latitude_azul.png';
import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useSwipeable} from 'react-swipeable';
import './styles.css';

import {useParams} from 'react-router-dom';
import {mask} from 'remask';
import {Helmet} from 'react-helmet';
import useImovelComCorretor from '~/actions/ImovelComCorretor/useImovelComCorretor';

import formatCurrency from '~/shared/formatCurrency';
import IcoMoon from '~/components/IcoMoon';
import {
  CaracteristicaItem,
  CaracteristicaItemDescription,
  CaracteristicaItemTitle,
  CaracteristicasBlock,
  CaracteristicasContainer,
  CaracteristicasGrid,
  CaracteristicasTitle,
  CarouselCloseButton,
  CarouselContainer,
  CarouselImage,
  CarouselImageContainer,
  CarouselNavigation,
  Container,
  ContentContainer,
  CorretorBox,
  CorretorButton,
  CorretorContainer,
  CorretorCreci,
  CorretorDados,
  CorretorDadosContainer,
  CorretorImage,
  CorretorImageBox,
  CorretorMessage,
  CorretorModal,
  CorretorNome,
  CorretorNomeModal,
  DescricaoImovel,
  GaleriaCloseButton,
  GaleriaGrid,
  GaleriaHeader,
  GaleriaModal,
  GalleryContainer,
  GridContainer,
  Header,
  HeaderContent,
  ImageBlock,
  LocalizationContainerTitle,
  LocalizationContent,
  LogoCircle,
  PrimaryImage,
  ScrollableContent,
  SecondaryImage,
  SecondaryImagesContainer,
  SocialContainer,
  TituloImovel,
  ValorBlock,
  ValorContainer,
  VerMais,
  WhatsappButton,
} from './styles';
import pattern from '~/infra/resources/pattern/mask';
import {GoogleMaps} from '~/components/GoogleMaps';
import {readableColor} from 'polished';

const ImovelExportComp = () => {
  const {imovelId, userId, empresaId} = useParams();
  const {isLoading, data} = useImovelComCorretor({
    imovelId: imovelId,
    userId: userId,
    empresaId: empresaId,
  });

  const imagemDestaque = data.imovel.imagens?.find((img) => img.destaque);

  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [isCorretorModalOpen, setIsCorretorModalOpen] = useState(true);
  const [isGaleriaOpen, setIsGaleriaOpen] = useState(false);
  const [miniature, setMiniature] = useState(false);
  const headerRef = useRef(null);
  const toggleCarousel = (position) => {
    setCarouselPosition(position);
    setIsCarouselOpen((prev) => !prev);
  };

  const setCorretorModalOff = () => {
    setIsCorretorModalOpen(false);
  };

  // const PDF = () => (
  //   <PdfView
  //     title={
  //       data?.imovel.tituloAmigavel ||
  //       data?.imovel.tipoImovel?.descricao ||
  //       'Apresentação do imóvel'
  //     }
  //     description={description || ''}
  //     address={data.imovel.logradouro}
  //     builtArea={data.imovel.caracteristica?.areaConstruida ?? 'N/D'}
  //     suits={data.imovel.caracteristica?.quantidadeSuites ?? 0}
  //     rooms={data.imovel.caracteristica?.quantidadeQuartos ?? 0}
  //     bathrooms={data.imovel.caracteristica?.quantidadeBanheiros ?? 0}
  //     garages={data.imovel.caracteristica?.vagasGaragem ?? 0}
  //     value={
  //       data.imovel?.valorVenda
  //         ? `R$ ${formatCurrency(data.imovel?.valorVenda)}`
  //         : '-'
  //     }
  //     images={data.imovel.imagens ?? []}
  //     corretorNome={data.pessoa.nomeCompleto}
  //     corretorImagem={data.pessoa.imagemUrl}
  //   />
  // );

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const offsetHeight = headerRef.current.offsetHeight;
        const scrollPosition = window.scrollY;

        setMiniature(scrollPosition > offsetHeight * 2);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
        }}>
        <h1 style={{fontSize: '1.5rem'}}>Carregando...</h1>
      </div>
    );
  }

  return (
    <>
      {/* <PDFDownloadLink
        fileName={data?.imovel.tituloAmigavel || 'imovel'}
        document={<PDF />}>
        {({loading, error}) => (
          <button disabled={isLoading} className="download-button">
            <span>
              {error && !loading && 'Erro ao gerar PDF.'}
              {loading ? (
                'Carregando...'
              ) : (
                <IcoMoon icon={'download3'} size={24} color="white" />
              )}
            </span>
          </button>
        )}
      </PDFDownloadLink> */}
      <Helmet>
        <title>
          {' '}
          {data?.imovel.tituloAmigavel ||
            data?.imovel.tipoImovel?.descricao ||
            'Apresentação do imóvel'}
        </title>
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={
            data?.imovel.tituloAmigavel ||
            data?.imovel.tipoImovel?.descricao ||
            'Apresentação do imóvel'
          }
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="652" />
        <meta property="og:image:height" content="408" />

        <meta
          property="og:description"
          content={`Estou compartilhando um imóvel que você possa ter interesse`}
        />
        <meta property="og:image" content={imagemDestaque?.arquivo ?? ''} />
        <style>
          {`body {
              background-color:${readableColor(
                data.empresa?.primaryColor ?? '#000',
                '#212121',
                '#f5f5f5',
              )};
            }`}
        </style>
      </Helmet>
      <Header ref={headerRef}>
        <HeaderContent secondaryColor={data.empresa.secondaryColor}>
          {/* <LogoContainer>
            <img src={data.empresa.urlLogo ?? Logo} alt="Logo" />
          </LogoContainer> */}
          <div
            style={{
              display: 'flex',
              flexDirection: miniature ? 'row-reverse' : 'row',
              justifyContent: miniature ? 'start' : 'space-between',
              alignItems: 'center',
              flex: 1,
            }}>
            <CorretorContainer>
              <CorretorDadosContainer primaryColor={data.empresa.primaryColor}>
                <CorretorNome>{data.pessoa.nomeCompleto}</CorretorNome>

                <CorretorCreci style={{textWrap: 'nowrap'}}>
                  <strong>CRECI:</strong> <span>{data.pessoa.creci}</span>
                </CorretorCreci>
                {!miniature && (
                  <SocialContainer>
                    {data.pessoa.instagram && (
                      <a
                        href={`https://www.instagram.com/${data.pessoa.instagram}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <IcoMoon
                          icon="instagram"
                          size={18}
                          color={data.empresa.primaryColor}
                        />
                      </a>
                    )}
                    {data.pessoa.telefone && (
                      <a
                        href={`https://wa.me/${data.pessoa.telefone}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <IcoMoon
                          icon="whatsapp"
                          size={18}
                          color={data.empresa.primaryColor}
                        />
                      </a>
                    )}
                    {data.pessoa.email && (
                      <a
                        href={`mailto:${data.pessoa.email}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <IcoMoon
                          icon="mail"
                          size={18}
                          color={data.empresa.primaryColor}
                        />
                      </a>
                    )}
                  </SocialContainer>
                )}
              </CorretorDadosContainer>
            </CorretorContainer>
            <CorretorImageBox space miniature={miniature}>
              <CorretorImage miniature={miniature}>
                <img src={data.pessoa.imagemUrl} alt="" />
              </CorretorImage>
              <LogoCircle
                miniature={miniature}
                badgeEscura={data.empresa.badgeEscura}>
                <img src={data.empresa.urlLogo ?? Logo} alt="Logo" />
              </LogoCircle>
            </CorretorImageBox>
          </div>
          {miniature && data.pessoa.telefone && (
            <WhatsappButton
              href={`https://wa.me/${data.pessoa.telefone}`}
              target="_blank"
              rel="noopener noreferrer">
              <IcoMoon
                icon="whatsapp"
                size={18}
                color={data.empresa.primaryColor}
              />{' '}
              <span>Conversar pelo WhatsApp</span>
            </WhatsappButton>
          )}
        </HeaderContent>
      </Header>
      <Container primaryColor={data.empresa.primaryColor}>
        {isCorretorModalOpen && (
          <SeuCorretor
            corretor={data.pessoa}
            setModalOff={setCorretorModalOff}
            primaryColor={data.empresa.primaryColor}
            badgeEscura={data.empresa.badgeEscura}
            logo={data.empresa.urlLogo}
          />
        )}

        <Container>
          {data.imovel.imagens?.length > 0 && isGaleriaOpen && (
            <Galeria
              onClick={(index) => toggleCarousel(index)}
              images={data.imovel.imagens}
              closeGaleria={() => setIsGaleriaOpen(false)}
            />
          )}
          {data.imovel.imagens?.length > 0 && isCarouselOpen && (
            <Carousel
              images={data.imovel.imagens}
              initialPosition={carouselPosition}
              onClose={() => toggleCarousel(0)}
              secondaryColor={data.empresa.secondaryColor}
            />
          )}
          {data.imovel.imagens?.length > 0 && (
            <>
              <GalleryContainer>
                <PrimaryImage
                  fullSize={data.imovel.imagens?.length === 1}
                  onClick={() => toggleCarousel(0)}>
                  <img
                    src={data.imovel.imagens?.[0]?.arquivo}
                    alt={`imagem-do-imovel-1`}
                  />
                </PrimaryImage>
                {data.imovel.imagens?.length > 1 && (
                  <SecondaryImagesContainer
                    imageCount={data.imovel.imagens.length}>
                    <SecondaryImage onClick={() => toggleCarousel(1)}>
                      <img
                        src={data.imovel.imagens?.[1]?.arquivo}
                        alt={`imagem-do-imovel-2`}
                      />
                    </SecondaryImage>
                    {data.imovel.imagens?.length > 2 && (
                      <SecondaryImage onClick={() => toggleCarousel(2)}>
                        <img
                          src={data.imovel.imagens?.[2]?.arquivo}
                          alt={`imagem-do-imovel-3`}
                        />
                      </SecondaryImage>
                    )}
                    {data.imovel.imagens?.length > 3 && (
                      <SecondaryImage onClick={() => toggleCarousel(3)}>
                        <img
                          src={data.imovel.imagens?.[3]?.arquivo}
                          alt={`imagem-do-imovel-4`}
                        />
                      </SecondaryImage>
                    )}
                    {data.imovel.imagens?.length > 4 && (
                      <SecondaryImage onClick={() => toggleCarousel(4)}>
                        <img
                          src={data.imovel.imagens?.[4]?.arquivo}
                          alt={`imagem-do-imovel-5`}
                        />
                      </SecondaryImage>
                    )}
                  </SecondaryImagesContainer>
                )}
                <VerMais onClick={() => setIsGaleriaOpen(true)}>
                  Visualizar mais fotos
                </VerMais>
              </GalleryContainer>
              <hr style={{paddingBlock: '2rem'}} />
            </>
          )}

          <ContentContainer>
            <TituloImovel primaryColor={data.empresa.primaryColor}>
              {data?.imovel.tituloAmigavel ||
                data?.imovel.tipoImovel?.descricao ||
                'Apresentação do imóvel'}
            </TituloImovel>
            <DescricaoImovel>
              {data.imovel.caracteristica?.caracteristicas ??
                'Descrição do imóvel'}
            </DescricaoImovel>
            <CaracteristicasContainer>
              <CaracteristicasBlock>
                <CaracteristicasTitle primaryColor={data.empresa.primaryColor}>
                  Características
                </CaracteristicasTitle>
                <hr />
                <CaracteristicasGrid>
                  <CaracteristicaItem>
                    <CaracteristicaItemTitle
                      primaryColor={data.empresa.primaryColor}>
                      <IcoMoon
                        icon="square-dashed-square"
                        size={16}
                        color={data.empresa.primaryColor}
                      />
                      <h5>Área Total</h5>
                    </CaracteristicaItemTitle>
                    <CaracteristicaItemDescription
                      primaryColor={data.empresa.primaryColor}>
                      {data.imovel.caracteristica?.areaConstruida ?? 'N/D'}{' '}
                      <span className="text-sm font-light">m²</span>
                    </CaracteristicaItemDescription>
                  </CaracteristicaItem>
                  <CaracteristicaItem>
                    <CaracteristicaItemTitle
                      primaryColor={data.empresa.primaryColor}>
                      <IcoMoon
                        icon="shower"
                        size={16}
                        color={data.empresa.primaryColor}
                      />
                      <h5>Quantidade de suítes</h5>
                    </CaracteristicaItemTitle>
                    <CaracteristicaItemDescription
                      primaryColor={data.empresa.primaryColor}>
                      {data.imovel.caracteristica?.quantidadeSuites}{' '}
                      <span className="text-sm font-light">Suíte</span>
                    </CaracteristicaItemDescription>
                  </CaracteristicaItem>
                  <CaracteristicaItem>
                    <CaracteristicaItemTitle
                      primaryColor={data.empresa.primaryColor}>
                      <IcoMoon
                        icon="bed-solid"
                        size={16}
                        color={data.empresa.primaryColor}
                      />
                      <h5>Quantidade de quartos</h5>
                    </CaracteristicaItemTitle>
                    <CaracteristicaItemDescription
                      primaryColor={data.empresa.primaryColor}>
                      {data.imovel.caracteristica?.quantidadeQuartos}{' '}
                      <span className="text-sm font-light">Quartos</span>
                    </CaracteristicaItemDescription>
                  </CaracteristicaItem>
                  <CaracteristicaItem>
                    <CaracteristicaItemTitle
                      primaryColor={data.empresa.primaryColor}>
                      <IcoMoon
                        icon="toilet-solid"
                        size={16}
                        color={data.empresa.primaryColor}
                      />
                      <h5>Quantidade de banheiros</h5>
                    </CaracteristicaItemTitle>
                    <CaracteristicaItemDescription
                      primaryColor={data.empresa.primaryColor}>
                      {data.imovel.caracteristica?.quantidadeBanheiros}{' '}
                      <span className="text-sm font-light">Banheiros</span>{' '}
                    </CaracteristicaItemDescription>
                  </CaracteristicaItem>
                  <CaracteristicaItem>
                    <CaracteristicaItemTitle
                      primaryColor={data.empresa.primaryColor}>
                      <IcoMoon
                        icon="car-solid"
                        size={16}
                        color={data.empresa.primaryColor}
                      />
                      <h5>Garagens</h5>
                    </CaracteristicaItemTitle>
                    <CaracteristicaItemDescription
                      primaryColor={data.empresa.primaryColor}>
                      {data.imovel.caracteristica?.vagasGaragem}{' '}
                      <span className="text-sm font-light">Garagens</span>
                    </CaracteristicaItemDescription>
                  </CaracteristicaItem>
                </CaracteristicasGrid>
              </CaracteristicasBlock>
              <ValorBlock>
                <ValorContainer
                  primaryColor={data.empresa.primaryColor}
                  secondaryColor={data.empresa.secondaryColor}>
                  <h6>Valor do imóvel</h6>
                  <span>
                    {data.imovel?.valorVenda
                      ? `R$ ${formatCurrency(data.imovel?.valorVenda)}`
                      : '-'}
                  </span>
                </ValorContainer>
              </ValorBlock>
            </CaracteristicasContainer>
            <hr style={{paddingBlock: '2rem'}} />
            <LocalizationContainerTitle
              primaryColor={data.empresa.primaryColor}>
              <IcoMoon
                icon="location"
                size={16}
                color={data.empresa.primaryColor}
              />
              <h6>Localização</h6>
            </LocalizationContainerTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                filter: 'brightness(0.9)',
              }}>
              <span>
                {data.imovel.logradouro?.nome ?? ''}
                {data.imovel.logradouro?.nome != null ? ', ' : ''}
                {data.imovel.endereco?.numero ?? ''}
                {data.imovel.endereco?.numero != null ? ', ' : ''}
                {data.imovel.logradouro?.bairro?.nome ?? ''}
                {data.imovel.logradouro?.bairro?.nome != null ? ', ' : ''}
                {data.imovel.logradouro?.bairro?.cidade?.nome ?? ''} -
                {data.imovel.logradouro?.bairro?.cidade?.estado?.id ?? ''}
              </span>
              <span>
                {data.imovel.logradouro?.cep
                  ? mask(data.imovel.logradouro?.cep, [pattern.cep])
                  : ''}
              </span>
            </div>
            {data.imovel.latitude != null && data.imovel.longitude != null && (
              <LocalizationContent>
                <GoogleMaps
                  editable={false}
                  latitude={data.imovel.latitude}
                  longitude={data.imovel.longitude}
                />
              </LocalizationContent>
            )}
          </ContentContainer>
        </Container>
      </Container>
    </>
  );
};

const Carousel = ({images, initialPosition = 0, onClose, secondaryColor}) => {
  const [position, setPosition] = useState(initialPosition);

  const handleNext = () => {
    setPosition((prevPosition) => (prevPosition + 1) % images.length);
  };

  const handlePrev = () => {
    setPosition(
      (prevPosition) => (prevPosition - 1 + images.length) % images.length,
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return createPortal(
    <CarouselContainer {...handlers}>
      <div
        style={{
          width: '100%',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <CarouselCloseButton onClick={onClose}>
          <IcoMoon icon="cross" size={10} color="#000" />
        </CarouselCloseButton>
        <div>
          <span>
            {position + 1}/{images.length}
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flex: 1,
        }}>
        <CarouselNavigation
          onClick={handlePrev}
          secondaryColor={secondaryColor}>
          <IcoMoon
            icon="circle-left"
            size={18}
            color={readableColor(secondaryColor, '#000', '#fff')}
          />
        </CarouselNavigation>
        <CarouselImageContainer>
          <CarouselImage>
            <img
              src={images[position].arquivo}
              alt={`imagem-carrossel-${position}`}
            />
          </CarouselImage>
        </CarouselImageContainer>
        <CarouselNavigation
          onClick={handleNext}
          secondaryColor={secondaryColor}>
          <IcoMoon
            icon="circle-right"
            size={18}
            color={readableColor(secondaryColor, '#000', '#fff')}
          />
        </CarouselNavigation>
      </div>
    </CarouselContainer>,
    document.body,
  );
};

const SeuCorretor = ({
  corretor,
  primaryColor,
  badgeEscura,
  setModalOff,
  logo,
}) => {
  return createPortal(
    <CorretorModal primaryColor={primaryColor}>
      <CorretorBox>
        <CorretorImageBox>
          <CorretorImage>
            <img src={corretor.imagemUrl} alt="" />
          </CorretorImage>
          <LogoCircle badgeEscura={badgeEscura}>
            <img src={logo ?? Logo} alt="Logo" />
          </LogoCircle>
        </CorretorImageBox>

        <CorretorDados>
          <CorretorNomeModal>{corretor.nomeCompleto}</CorretorNomeModal>
          <CorretorCreci>
            <strong>CRECI:</strong> {corretor.creci}
          </CorretorCreci>
        </CorretorDados>
        <CorretorMessage>
          Sou eu, seu corretor(a)! Econtrei uma ótima oportunidade para você.
          Confira este imóvel e me diga o que acha.
        </CorretorMessage>
        <CorretorButton onClick={setModalOff} autoFocus>
          <span>OK</span>
        </CorretorButton>
      </CorretorBox>
    </CorretorModal>,
    document.body,
  );
};

const Galeria = ({images, onClick, closeGaleria}) => {
  return (
    <GaleriaModal>
      <GridContainer>
        <ScrollableContent>
          <GaleriaHeader>
            <GaleriaCloseButton onClick={closeGaleria}>
              <IcoMoon icon="cross" size={10} color="#000" />
            </GaleriaCloseButton>
          </GaleriaHeader>
          <GaleriaGrid>
            {images.map((image, index) => (
              <ImageBlock
                key={index}
                noSplit={(index + 1) % 3 === 0}
                onClick={() => onClick(index)}>
                <img src={image.arquivo} alt="" />
              </ImageBlock>
            ))}
          </GaleriaGrid>
        </ScrollableContent>
      </GridContainer>
    </GaleriaModal>
  );
};

export default ImovelExportComp;
