const pt = {
  geral: {
    login_boas_vindas: 'Bem vindo ao maior Marketplace ótico do Brasil',
    login: 'Login',
    login_com_sucesso: 'Login realizado com sucesso!!',
    esqueci_senha: 'Esqueci senha',
    esqueceu_sua_senha: 'Esqueceu sua senha ?',
    criar_nova_conta: 'Criar nova conta',
    voltar_para_site: 'Voltar para o site',
    qual_seu_perfil: 'Qual seu perfil ?',
    cadastro_com_sucesso: 'Cadastro realizado com sucesso!!',
    deletado_com_sucesso: 'Registro deletado com sucesso!!',
    atualizado_com_sucesso: 'Cadastro atualizado com sucesso!!',
    progresso_com_sucesso: 'Progresso realizado com sucesso!!',
    usuario_nao_encontrado: 'Usuário não encontrado em nossa base de dados!!',
  },
  imagem: {
    upload_com_sucesso: "Imagens salvas com sucesso!!"
  },
  comum: {
    aviso: 'Aviso',
    atencao: 'Atenção',
    entrar: 'Entrar',
    confirmar: 'Confirmar',
    voltar: 'Voltar',
    alterar_Senha: 'Alterar senha',
    validando: 'Validando...',
    usuario: 'Usuário',
    empresa: 'Empresa',
  },
  form: {
    rules: {
      email_obrigatorio: 'Favor informar o seu E-mail',
      email_validacao_tipo: 'Favor inserir um E-mail válido',
      perfil_obrigatorio: 'Favor informar o perfil',
      senha_obrigatorio: 'Favor informar a sua senha',
      empresa_obrigatio: 'Favor informar a imobiliária',
      acao_tomada: 'Favor informar a ação tomada',
      resultado: 'Favor informar o resultado',
      data_de_contato: 'Favor informar a data de contado',
      data_proximo_contato: 'Favor informar o próximo contato',
    },
    placeholder: {
      perfil: ' Perfil',
      email: ' Email',
      senha: ' Senha',
      empresa: ' Imobiliária',
      data_de_contato: ' Dta. Contato',
      data_proximo_contato: ' Dta. Próximo contato',
      acao_tomada: ' Ação tomada',
      resultado: ' Resultado',
    },
  },
};

export default pt;
