import React, { Fragment } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { Select } from 'antd';
import Button from '~/shared/Button';
import CryptoJS from 'crypto-js';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

const { Option } = Select;
const secretKey = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data) => {
  const value = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  localStorage.setItem('userEncrypt', value);
};

const decryptData = () => {
  const ciphertext = localStorage.getItem('userEncrypt');
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

function ModalEmpresas({
  open,
  close,
  selectedEvent,
  confirmEvent,
  listEmpresas,
  textTitle,
  loadingEmpresa,
  formValues,
  empresaId,
  type = 'login' || 'changeCompany'
}) {
  const onSubmit = () => {

    if (type === 'changeCompany') {
      try {
        let payload = decryptData();
        payload.empresaId = empresaId;
        confirmEvent(payload);
      } catch {
        Notify(
          'error',
          pt.comum.atencao,
          'Não foi possível alterar a empresa!',
        );

        close();
      }
      return;
    }

    const payload = {
      email: formValues.email,
      senha: formValues.senha,
      empresaId: empresaId,
    };

    try {
      encryptData(payload);
    } catch (error) {
      console.log('error ao gravar criptografia', error);
    }

    confirmEvent(payload);
  };

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => close()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>{textTitle}</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <Select
              style={{ width: '85%' }}
              placeholder=" Empresa"
              showSearch
              onChange={selectedEvent}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                  .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
              }>
              {listEmpresas.map((item) => (
                <Option key={item?.empresa?.id || item?.id}>
                  {item?.empresa?.nome || item?.nome}
                </Option>
              ))}
            </Select>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => onSubmit()}
              isloading={loadingEmpresa?.toString()}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button icon fluid labelPosition="left" size="medium" onClick={() => close()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalEmpresas;
