function getMaskedNumberBR(phoneNumber) {
    let digits = phoneNumber.replace(/\D/g, '');
  
    switch (digits.length) {
        case 10:
            return `(${digits.substring(0, 2)}) ${digits.substring(2, 6)}-${digits.substring(6)}`;
        case 11:
            return `(${digits.substring(0, 2)}) ${digits.substring(2, 7)}-${digits.substring(7)}`;
        default:
            return "";
        }
    }
export default getMaskedNumberBR