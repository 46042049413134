import React from 'react';
import useRankingCaptors from './use-ranking-captors';

import {Card, Row, Col} from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';

import {Bar} from '@ant-design/charts';

export default function RankingCaptors({filters}) {
  const { mainPeriod, broker, brokerGroup, initialDate, finalDate } = filters;
  const {config} = useRankingCaptors(mainPeriod, broker, brokerGroup, initialDate, finalDate);

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Ranking Captadores</CardTitle>
      <Row>
        <Col>
          <Bar {...config} />
        </Col>
      </Row>
    </Card>
  );
}

