import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useImovelCidadesDropdown(estadoId, cidade, cidadesIds) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      const filterEstadoId = estadoId ? estadoId : null;
      const filterNome = cidade ? cidade : null;
      const filterCidadesIds = cidadesIds && cidadesIds.length > 0 ? cidadesIds : null;
      let filter = '';

      if (filterEstadoId && !params.estadoId) {
        if (filter.length > 0) filter += `&estadoId=${filterEstadoId}`;
        else filter = `?estadoId=${filterEstadoId}`;
      }

      if (filterNome && !params.cidade) {
        if (filter.length > 0) filter += `&cidade=${filterNome}`;
        else filter = `?cidade=${filterNome}`;
      }

      if (filterCidadesIds && !params?.cidadesIds && !params?.cidade) {
        for (const index in filterCidadesIds) {
          const value = filterCidadesIds[index];

          if (index > 0 || filter.length > 0) {
            filter += '&';
          } else {
            filter += '?';
          }

          filter += `cidadesIds=${value}`;
        }
      }

      if (params?.estadoId) {
        if (filter.length > 0) filter += `&estadoId=${params.estadoId}`;
        else filter = `?estadoId=${params.estadoId}`;
      }

      if (params?.cidade) {
        if (filter.length > 0) filter += `&cidade=${params.cidade}`;
        else filter = `?cidade=${params.cidade}`;
      }

      if (params?.cidadesIds && params?.cidadesIds.length > 0) {
        for (const index in params.cidadesIds) {
          const value = params.cidadesIds[index];

          if (index > 0 || filter.length > 0) {
            filter += '&';
          } else {
            filter += '?';
          }

          filter += `cidadesIds=${value}`;
        }
      }

      try {
        await api
          .get(`${url.DROPDOWN.IMOVEL_CIDADES_DROPDOWN()}${filter}`)
          .then((res) => {
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [estadoId, cidade],
  );

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
