import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useEmpresasGrupoDropdown() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    try {
      await api.get(url.EMPRESAS.ALL_GRUPO_DROPDOWN(localStorage.getItem('icaseEmpresaId')))
        .then((res) => {
          setData(res?.data || []);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
