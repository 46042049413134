export const TiposImoveisFields = [
  {
    id: "dedd27e3-7c96-4e97-8ec9-9c8aa0f39aba",
    name: "ANDAR / LAJE CORPORATIVA",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
  },
  {
    id: "c337bde6-5bfc-4c74-8b51-291782ffc69f",
    name: "APARTAMENTO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "d87f53b7-2bf3-4f93-abe5-f1b846f25025",
    name: "APARTAMENTO DUPLEX",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "0b179b95-4d4f-4c4e-8e48-ef469b6462c0",
    name: "APARTAMENTO GARDEN",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "f11f868d-f6bc-4050-a617-15dca42abe49",
    name: "APARTAMENTO TRIPLEX",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "120b5e79-4c38-4318-918e-976b226b29d4",
    name: "CASA",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'condominioId',
      'quantidadeTorres',
      'qtdAndares', 'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
    ]
  },
  {
    id: "151a296a-983d-401c-949d-046418de2f54",
    name: "CASA CONDOMÍNIO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeTorres',
      'qtdAndares', 'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
    ]
  },
  {
    id: "59a2205e-5d35-4aa5-9bf1-136b33ffc674",
    name: "CHÁCARA",
    finalidadeId: "576fbd06-0479-4441-9376-bbabc56e0582",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'anoConstrucao',
      'valorVenda',
      'valorCondominio',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'vagasGaragem',
      'quantidadeTorres',
      'qtdAndares',
      'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
  
    ]
  },
  {
    id: "cd197a51-3ebd-48a5-b346-8980662e8baf",
    name: "CHÁCARA EM CONDOMÍNIO ",
    finalidadeId: "576fbd06-0479-4441-9376-bbabc56e0582",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'anoConstrucao',
      'valorVenda',
      'valorCondominio',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'vagasGaragem',
      'quantidadeTorres',
      'caracteristicas',
    ]
  },
  {
    id: "acab78a7-fc24-425b-9b64-7dc25608a05e",
    name: "COBERTURA ",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "a715f2c7-7c64-4659-a5dd-267e6d966532",
    name: "COBERTURA DUPLEX",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "f124eba3-93ef-4514-8113-dbe7833b3248",
    name: "APARTAMENTO TRIPLEX",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "54cb3a93-25d6-47fd-868c-fb0ff1227240",
    name: "Casa Térrea em Condominio ",
    requiredFields: ['condicoesPgtoIptu',

    ]
  },
  {
    id: "2c3a60a4-7c37-4b3b-abbe-56f0d1189fd2",
    name: "EDIFICIO",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',

    ]
  },
  {
    id: "fb2e7d47-0b63-4d61-885e-85de714e88fa",
    name: "FAZENDA",
    finalidadeId: "576fbd06-0479-4441-9376-bbabc56e0582",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'anoConstrucao',
      'valorVenda',
      'valorCondominio',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'vagasGaragem',
      'quantidadeTorres',
      'qtdAndares',
      'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
    ]
  },
  {
    id: "ac53bbe5-bbae-4327-97fd-dde839819bb1",
    name: "FLAT",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "f4e24208-7dab-51cc-e894-f092551da5aa",
    name: "GALPÃO",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'bairroComercialId',
      'logradouroId',
      'numero',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas'
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
      'condominioId',
    ]
  },
  {
    id: "7da0f508-de08-48ce-981e-be7aea5fed02",
    name: "GARAGEM",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
      'quantidadeBanheiros',
    ]
  },
  {
    id: "2028d633-99d5-94d4-5f7c-4cbc04311ac7",
    name: "KITNET / CONJUGADO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "eaeac4f6-2c2a-43b0-ad82-f9b9cde2308f",
    name: "LOFT / STUDIO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ]
  },
  {
    id: "83b4fc36-c1f8-4078-b33d-7cac87359683",
    name: "LOJA",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas'
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
    ]
  },
  {
    id: "62f4ce3b-cee2-4e49-acd6-d33ade3d45a9",
    name: "PRÉDIO ",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas'
    ]
  },
  {
    id: "cb6cb2f2-2076-4a90-884b-79816d5fe9be",
    name: "SALA COMERCIAL",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
    ]
  },
  {
    id: "36afbb9d-d5dc-47aa-a1ff-58fee7c9f442",
    name: "SOBRADO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'condominioId',
      'quantidadeTorres',
      'qtdAndares', 'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
    ]
  },
  {
    id: "5f466695-3b70-47a6-81fd-cc0d0581cb48",
    name: "SOBRADO EM CONDOMÍNIO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'quantidadeQuartos',
      'quantidadeBanheiros',
      'totalGaragens',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'anoConstrucao',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeTorres',
      'qtdAndares', 'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
    ]
  },
  {
    id: "1d07a805-d6c8-4ceb-80fa-c8ae22871ae8",
    name: "SÍTIO",
    finalidadeId: "576fbd06-0479-4441-9376-bbabc56e0582",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'anoConstrucao',
      'valorVenda',
      'valorCondominio',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'vagasGaragem',
      'quantidadeTorres',
      'qtdAndares',
      'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
    ]
  },
  {
    id: "9f677db8-7df4-4d5d-8660-104b8d7e4cf6",
    name: "TERRENO ",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'valorVenda',
      'comissaoVenda',
      'tituloAmigavel',
      'caracteristicas'
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
      'quantidadeBanheiros',
      'totalGaragens',
      'condominioId',
      'vagasGaragem',
      'vagasGaragemDescobertas',
      'quantidadeTorres',
      'qtdAndares', 'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo',
      'valorCondominio',
    ]
  },
  {
    id: "9f677db8-7df4-4d5d-8660-104b8d7e4cf6",
    name: "TERRENO ",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
      'quantidadeBanheiros',
      'totalGaragens',
    ]
  },
  {
    id: "743ecb82-6b5f-4a19-adf0-822685b57e73",
    name: "TERRENO COMERCIAL",
    finalidadeId: "d1a87f1d-ca04-45f4-8d12-9fb1ae8c3311",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'valorVenda',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
      'quantidadeBanheiros',
      'totalGaragens',
    ]
  },
  {
    id: "98026795-f26e-4551-a2c4-f0aab6e5b3f7",
    name: "TERRENO CONDOMÍNIO",
    finalidadeId: "e092dd4c-e587-4284-83bf-d881dd741690",
    requiredFields: ['condicoesPgtoIptu',
      'areaConstruida',
      'condominioId',
      'cep',
      'cidade',
      'estado',
      'bairro',
      'logradouroId',
      'numero',
      'complemento',
      'valorVenda',
      'valorCondominio',
      'tituloAmigavel',
      'caracteristicas',
    ],
    hiddenFields: [
      'quantidadeQuartos',
      'quantidadeSuites',
      'quantidadeBanheiros',
      'totalGaragens',
      'vagasGaragem',
      'vagasGaragemDescobertas',
      'quantidadeTorres',
      'qtdAndares', 'nroAndar',
      'apartamentosPorAndar',
      'possuiElevador',
      'andarTerreo'
    ]
  }
];


