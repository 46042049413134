import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useUserByRole() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    const empresaId = localStorage.getItem('icaseEmpresaId');

    try {
      const data = await api.get(url.USUARIO.BY_ROLE(empresaId));
      setData(data.data || []);
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
