const origemImovel = [
  {
    id: 1,
    descricao: 'Na base',
  },
  {
    id: 2,
    descricao: 'Mercado/Parceria',
  },
  {
    id: 3,
    descricao: 'Lançamento',
  },
];

export default origemImovel;
