import React, { Fragment, useEffect, useState } from 'react';
import { Form, Select, Input, Table, Space, Popconfirm, Spin, Upload } from 'antd';
import { InboxOutlined, CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls';
import { saveAs } from 'file-saver';

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;

function ModalAndamentoOcorrencia({ loading, open, close, row, isCorretor, envioInicial, onSuccesso, statusOcorrencia }) {

  const [isLoading, setIsLoading] = useState(false);
  const [formRef] = Form.useForm();
  const [checklistId, setChecklistId] = useState(null);
  const [filesList, setFilesList] = useState([]);

  const carregaArquivos = async () => {
    if (!checklistId)
      return;

    setIsLoading(true);

    await api.get(url.ATENDIMENTO.CHECKLIST_ALL_FILES(checklistId))
      .then((res) => {
        const newFileList = res?.data && res.data.map(f => {
          return {
            uid: f.id,
            name: f.nome,
            status: 'done',
            url: f.arquivo,
            tipo: f.tipo,
            situacao: f.situacao
          }
        });

        if (newFileList && newFileList.length > 0)
          setFilesList(newFileList);
        else
          setFilesList([]);

        setIsLoading(false);
      })
      .catch((err) => {
        setFilesList([]);
        setIsLoading(false);
        const { erros } = err.response.data;
        if (erros) {
          for (const item in erros) {
            Notify('error', pt.comum.atencao, erros[item]);
          }
        }
      });
  };


  const onSave = async (status) => {
    setIsLoading(true);

    const { obsResultado, obsEnvio } = formRef.getFieldsValue();

    const payload = {
      atendimentoId: row?.id,
      obsResultado: obsResultado,
      obsEnvio: obsEnvio,
      status: status // 2 - Com Pendência, 3 - Sem Pendência 
    }

    await api.post(url.OCORRENCIA.EFETIVAR_OCORRENCIA(), payload)
      .then((res) => {
        setIsLoading(false);
        Notify('success', '', pt.geral.progresso_com_sucesso);
        close();
      })
      .catch((err) => {
        setIsLoading(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item], null, () => document.getElementById(`modalOcorrencia${statusOcorrencia || 0}`));
          }
        }
      });
  }

  useEffect(() => {
    setChecklistId(row?.checkListAnaliseFinanceira?.id || null);
    formRef.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    carregaArquivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistId]);

  const atualizaTipoDocumento = (uid, tipo) => {
    api.put(url.ATENDIMENTO.CHECKLIST_ATUALIZA_TIPO_DOCUMENTO(uid, tipo))
      .then(carregaArquivos)
      .catch((err) => {
        Notify('error', '', 'Oops erro ao atualizar arquivo!');
      });
  };

  const atualizaSituacaoDocumento = (uid, situacao) => {
    api.put(url.ATENDIMENTO.CHECKLIST_ATUALIZA_SITUACAO_DOCUMENTO(uid, situacao))
      .then(carregaArquivos)
      .catch((err) => {
        Notify('error', '', 'Oops erro ao atualizar arquivo!');
      });
  };

  const columnsFiles = [
    {
      title: 'Arquivo',
      dataIndex: 'name',
      key: 'name',
      render: (text, row, index) => {
        return row.status === 'uploading' ? <Spin /> :
          <div style={{ maxWidth: '250px' }}>
            {/* eslint-disable-next-line */}
            <a style={{ color: row?.situacao === 1 ? "#00cc66" : row?.situacao > 1 ? "#ff5c33" : "rgba(0, 0, 0, 0.85)" }} key={index} href={row.url} target="_blank">{text?.trim()?.substring(0, 200)} {text?.trim()?.length > 200 ? '...' : ''}</a>
          </div>
      }
    },
    {
      title: 'Tipo de Documento',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (text, row, index) => {
        return <Select key={index}
          style={{ width: '300px' }}
          allowClear={true}
          disabled={isLoading}
          value={row.tipo}
          getPopupContainer={node => node.parentNode}
          onChange={(value) => atualizaTipoDocumento(row.uid, value)}
          placeholder="Tipo Documento">
          <Option value={1}>RG/CNH</Option>
          <Option value={2}>CPF</Option>
          <Option value={3}>Comprovante Endereço</Option>
          <Option value={4}>Holerite</Option>
          <Option value={5}>Holerite Participante</Option>
          <Option value={6}>Certidão Nascimento/Casamento</Option>
          <Option value={7}>Extrato FGTS</Option>
          <Option value={8}>FGTS Desbloqueado</Option>
          <Option value={9}>Imposto Renda</Option>
          <Option value={10}>Ficha SIGE</Option>
          <Option value={11}>CTPS</Option>
          <Option value={12}>Extrato Bancário</Option>
          <Option value={13}>RG Dependente</Option>
          <Option value={14}>CPF Dependente</Option>
        </Select>
      }
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      key: 'situacao',
      render: (text, row, index) => {
        return <Select key={index}
          disabled={isLoading || isCorretor}
          style={{ width: '300px' }}
          allowClear={true}
          value={row.situacao}
          getPopupContainer={node => node.parentNode}
          onChange={(value) => atualizaSituacaoDocumento(row.uid, value)}
          placeholder="Situação">
          <Option value={1}>Ok</Option>
          <Option value={2}>Ilegível</Option>
          <Option value={3}>Desatualizado</Option>
          <Option value={4}>Inválido</Option>
        </Select>
      }
    },
    {
      title: 'Ações',
      key: 'action',
      render: (text, record, index) => (
        <Space key={index} size="middle">
          <CloudDownloadOutlined onClick={() => saveAs(record.url, record.name)} />
          {(!isCorretor || record?.situacao > 1) && <Popconfirm getPopupContainer={node => node.parentNode} onConfirm={() => onRemoveFile(record.uid)} align="l" onPopupAlign="l" title="Confirma a exclusão do anexo?" okText="Sim" cancelText="Não">
            <DeleteOutlined />
          </Popconfirm>}
        </Space>
      ),
    },
  ];


  const onRemoveFile = (uid) => {
    api.delete(url.ATENDIMENTO.CHECKLIST_DELETE_FILE(uid))
      .then(carregaArquivos)
      .catch((err) => {
        Notify('error', '', 'Oops erro ao excluir arquivo!');
      });
  }

  const uploadArquivoRequest = ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append('checklistId', checklistId || '');
    formData.append('atendimentoId', row?.id || '');
    formData.append('arquivo', file);

    api.post(url.ATENDIMENTO.CHECKLIST_UPLOAD_FILE(), formData)
      .then((res) => {
        file.url = res?.data?.data?.arquivo;
        if (!checklistId && res?.data?.data?.checklistId)
          setChecklistId(res.data.data.checklistId);

        onSuccess(null, file);
      })
      .catch((err) => {
        const { error } = err.response?.data;
        let msgErro = '';

        if (error) {
          for (const item in error) {
            msgErro += item;
          }
        }

        onError(msgErro);
      });
  };

  const configFiles = {
    name: 'file',
    multiple: true,
    accept: 'image/png,image/gif,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.png,.gif,.jpng,.pdf,.doc,.docx,.xls,.xlsx',
    showUploadList: false,
    customRequest: uploadArquivoRequest,
    onChange(info) {
      setFilesList(info?.fileList);

      if (info?.fileList?.every(e => e.status !== 'uploading') && info.file.status !== 'removed')
        carregaArquivos();

      const { status } = info.file;

      if (status === 'error') {
        Notify('error', '', `${info.file.name} erro ao fazer Upload.`);
      }
    }
  };


  return (
    <Fragment>
      <Modal
        id={`modalOcorrencia${statusOcorrencia || 0}`}
        closeOnDimmerClick={false}
        size="large"
        dimmer="blurring"
        open={open}
        onClose={() => close()}>
        <Modal.Header>
          <Row>
            <Col xs="12" md="12">
              <h6 className="title">Conferência de Documentação</h6>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row>
            <Col>
              <div className="pl-4 pr-4">
                <Row>
                  <Col xs="12">
                    <div className="p-2">
                      <Dragger {...configFiles} fileList={filesList}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Clique aqui ou arraste arquivos para upload.</p>
                      </Dragger>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="p-2">
                      <Table
                        loading={isLoading}
                        rowClassName={(row) => row?.situacao === 1 ? 'table-row-normal' : row?.situacao > 1 ? 'table-row-irregular' : null}
                        rowKey="uid"
                        dataSource={filesList}
                        columns={columnsFiles} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pl-4 pr-4">
                <Form
                  form={formRef}
                  initialValues={{
                    obsResultado: row?.ocorrencia?.obsResultado,
                    obsEnvio: row?.ocorrencia?.obsEnvio
                  }}
                  scrollToFirstError>
                  <Row>
                    <Col xs="12" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Observação Envio</small>
                      </p>
                      <Form.Item
                        name="obsEnvio"
                        className="my-2">
                        <TextArea
                          disabled={!isCorretor}
                          placeholder={'Observação de envio para conferência'}
                          rows={4}
                          maxLength={500}
                          showCount
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!envioInicial && <Row>
                    <Col xs="12" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Conferência</small>
                      </p>
                      <Form.Item
                        className="my-2"
                        name="obsResultado"
                        rules={[
                          {
                            required: true,
                            message: pt.form.rules.obsResultado,
                          },
                        ]}>
                        <TextArea
                          disabled={isCorretor}
                          placeholder={'Resultado da análise de documentação.'}
                          rows={4}
                          maxLength={500}
                          showCount
                        />
                      </Form.Item>
                    </Col>
                  </Row>}
                </Form>
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Button icon labelPosition="left"
            disabled={isLoading}
            onClick={() => close()}>
            <Icon name="angle left" />
            Voltar
          </Button>
          {envioInicial ? <Button
            content="Enviar para Ocorrência"
            labelPosition="right"
            icon="checkmark"
            onClick={() => onSuccesso(formRef.getFieldValue('obsEnvio'))}
            color="blue"
          /> : <>
            {!isCorretor && <Button
              disabled={isLoading}
              content="Pendente"
              labelPosition="right"
              icon="times"
              onClick={() => onSave(2)} // 2 - Com Pendência
              color="red"
            />
            }
            {(!isCorretor && !filesList?.some(o => o.situacao !== 1)) && <Button
              content="Documentação OK"
              labelPosition="right"
              icon="checkmark"
              onClick={() => onSave(3)} // 3 - Sem Pendênca
              color="blue"
            />}
            {isCorretor && <Button
              content="Reenviar para Conferência"
              labelPosition="right"
              icon="checkmark"
              onClick={() => onSave(1)} // 1 - Aguardando Conferência
              color="blue"
            />}
          </>
          }
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}



export default ModalAndamentoOcorrencia;
