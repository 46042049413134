import React from 'react';
import styled from 'styled-components';

import {Select} from 'antd';

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;

  .sort-by-label {
    font-weight: bold;
  }
`;

export default function SortByDropdown(props) {
  const options = [
    {label: 'Mais recentes', value: 'mais_recentes'},
    {label: 'Mais antigos', value: 'mais_antigos'},
    {label: 'Maior preço', value: 'maior_preco'},
    {label: 'Menor preço', value: 'menor_preco'},
    {label: 'Maior área', value: 'maior_area'},
    {label: 'Menor área', value: 'menor_area'},
  ];

  return (
    <Container>
      <span className="sort-by-label">Ordenar por</span>
      <Select
        defaultValue={props?.defaultValue || options[0].value}
        onChange={(value) => {
          if (props.onChange) props.onChange(value);
        }}
        options={options}
      />
    </Container>
  );
}

