import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Maintenance from './views/Maintenance/Maintenance';

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    {process.env.REACT_APP_MAINTENANCE ? <Maintenance /> : <App />}
  </DndProvider>,
  document.getElementById('root'),
);

