import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Input, Select, Radio, Upload, Button as ButtonAntd, Tooltip } from 'antd';
import { mask, unMask } from 'remask';
import { Icon } from 'semantic-ui-react';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { red } from '@material-ui/core/colors';

import useTipoImovel from '~/actions/DropDown/useTipoImovel';
import useLogradouroDropdown from '~/actions/DropDown/useLogradouroDropdown';
import useUnidadeMedida from '~/actions/DropDown/useUnidadeMedida';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';

import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';

import Notify from '~/shared/Notify';
import Button from '~/shared/Button';
import url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';
import formatedReal from '~/shared/formatedReal';

import './index.css';
import useCondominioDropdown from '~/actions/DropDown/useCondomionio';
import ModalCondominio from '../ModalCondominio';
import useRoles from '~/hooks/use-roles';

const { OptGroup, Option } = Select;

const optionsSimple = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4 ou +', value: 4 },
];


function ImovelSimplificado({
  origemImovel,
  setShowButtonVisita,
  setOpenModalImovelSimplificado,
  setImovel
}) {
  const [loading, setLoading] = useState(false);
  const [loadingEndereco, setLoadingEndereco] = useState(false);
  const [finalidadeId, setFinalidadeId] = useState();
  const [clearFiltroQuartos, setClearFiltroQuartos] = useState(0);
  const [clearFiltroVagas, setClearFiltroVagas] = useState(0);
  const [clearFiltroSuites, setClearFiltroSuites] = useState(0);
  const [clearFiltroBanheiros, setClearFiltroBanheiros] = useState(0);
  const [selectedOperacaoImobiliaria, setSelectedOperacaoImobiliaria] = useState(2);
  const [fileLogo, setFileLogo] = useState(null);
  const [errorLogo, setErrorLogo] = useState([]);
  const [modalCondominioOpen, setModalCondominioOpen] = useState(false);
  const { isGerente, isDiretor } = useRoles();

  const { data: dataTipoImoveis } = useTipoImovel();
  const {
    data: condominios,
    onLoad: onLoadCond,
    cancelRequest: cancelRequestCond,
  } = useCondominioDropdown();

  const { data: dataLogradouros, onLoad: onLoadLogradouro } = useLogradouroDropdown('');
  const { data: dataUnidadeMedida } = useUnidadeMedida();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleConvertNumber(value) {
    return isNaN(value) && value === '' ? undefined : value;
  }

  async function onSave() {
    setLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const { nome: nomeCondominio } = dados.condominioId
          ? condominios.find((item) => item.id === dados.condominioId)
          : '';

        const nomeBairro = dados.bairro !== undefined ? dados.bairro : '';

        let tituloAmigavel = '';
        if (nomeCondominio) tituloAmigavel = `Cond.: ${nomeCondominio} `;

        tituloAmigavel += nomeBairro !== '' ? `Bairro: ${nomeBairro} ` : '';

        if (
          (selectedOperacaoImobiliaria === 2 || selectedOperacaoImobiliaria === 3) &&
          dados.valorVenda &&
          tituloAmigavel !== ''
        ) {
          tituloAmigavel += `Valor: R$ ${dados.valorVenda}`;
        }

        const payload = {
          logradouroId: dados.logradouroId,
          ocupado: false,
          situacao: 5, // CADASTRO
          operacaoImobiliaria: dados?.operacaoImobiliaria || 2,
          tipoImovelId: dados.tipoImovelId,
          finalidadeId,
          tituloAmigavel: tituloAmigavel.trim(),
          imagemBase64: fileLogo?.url,
          comissaoVenda: dados.comissaoVenda
            ? Number(
              dados.comissaoVenda.replaceAll('.', '').replace(',', '.'),
            ).toFixed(2)
            : undefined,
          valorVenda: dados.valorVenda
            ? Number(
              dados.valorVenda.replaceAll('.', '').replace(',', '.'),
            ).toFixed(2)
            : undefined,
          imovelEndereco: {
            loteApto: dados.loteApto,
            // complemento: dados.complemento,
            condominioId: dados.condominioId,
            numero: dados.numero,
            quadraBloco: dados.quadraBloco,
          },
          imovelCaracteristica: {
            vagasGaragem: handleConvertNumber(dados.vagasGaragem),
            areaConstruida: handleConvertNumber(dados.areaConstruida),
            areaTotalUniMedidaId:
              dataUnidadeMedida.find(item => item.descricao.toLowerCase() === 'm2')?.id
              || undefined,
            quantidadeQuartos: handleConvertNumber(dados.quantidadeQuartos),
            sendoSuite: "S/",
            quantidadeSuites: handleConvertNumber(dados.quantidadeSuites),
            quantidadeBanheiros: handleConvertNumber(
              dados.quantidadeBanheiros,
            ),
          },
        };

        await api
          .post(url.IMOVEL.SIMPLIFICADO(), payload)
          .then((res) => {
            setImovel(res?.data?.data);
            setShowButtonVisita(true);
            setOpenModalImovelSimplificado(false);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        const { errorFields } = errorInfo;
        setLoading(false);
        if (errorFields) {
          const fieldName = errorFields[0]?.name[0];
          const fieldProps = form.getFieldInstance(fieldName);
          fieldProps.focus();
        }
      });
  }

  function handleOnChangeTipoImovel(value) {
    dataTipoImoveis.forEach(tipo => {
      const index = tipo?.options.findIndex(item => item.id === value);

      if (index > -1) setFinalidadeId(tipo?.id);
    })
  }

  function handleClearTipoImovel() {
    setFinalidadeId(undefined);
  }

  async function handleSearchCondominio(value) {
    cancelRequestCond();
    await onLoadCond({ nome: value });
  }

  async function changeCondominio(value) {
    setLoadingEndereco(true);

    if (value) {
      const selectedCondominio = condominios.find(item => item.id === value);

      if (selectedCondominio?.logradouroId)
        await onLoadLogradouro(selectedCondominio?.logradouro?.nome)

      form.setFieldsValue({
        cep: selectedCondominio?.logradouro?.cep
          ? mask(selectedCondominio?.logradouro?.cep, [pattern.cep])
          : '',
        estado: selectedCondominio?.logradouro?.bairro?.cidade?.estadoId,
        logradouroId: selectedCondominio?.logradouroId,
        cidade: selectedCondominio?.logradouro?.bairro?.cidade?.nome,
        bairro: selectedCondominio?.logradouro?.bairro?.nome,
      });

      setLoadingEndereco(false);
    } else {
      await onLoadLogradouro();

      form.setFieldsValue({
        cep: null,
        estado: null,
        logradouroId: null,
        cidade: null,
        bairro: null,
      });

      setLoadingEndereco(false);
    }
  }

  function onClickRadioQuartos(e) {
    if (e.target.value === clearFiltroQuartos) {
      form.setFieldsValue({ dormitorios: null });
    }
    else setClearFiltroQuartos(e.target.value);
  }

  function onClickRadioVagas(e) {
    if (e.target.value === clearFiltroVagas) {
      form.setFieldsValue({ vagas: null });
    }
    else setClearFiltroVagas(e.target.value);
  }

  function onClickRadioSuites(e) {
    if (e.target.value === clearFiltroSuites) {
      form.setFieldsValue({ suites: null });
    }
    else setClearFiltroSuites(e.target.value);
  }

  function onClickRadioBanheiros(e) {
    if (e.target.value === clearFiltroBanheiros) {
      form.setFieldsValue({ banheiros: null });
    }
    else setClearFiltroBanheiros(e.target.value);
  }

  async function handleEndereco(e, type) {
    let cep = e.currentTarget.value;
    cep = unMask(cep);
    cep = mask(cep, [type]);

    if (type === pattern.cep) form.setFieldsValue({ cep });

    if (cep.length === 10) {
      const unmaskedCEP = unMask(cep);
      setLoadingEndereco(true);
      await api
        .get(url.SERVICES.CONSULTA_CEP(unmaskedCEP))
        .then(async (res) => {
          const { data: dataEndereco, status } = res;

          if (status === 204) {
            Notify(
              'warning',
              pt.comum.atencao,
              'Nenhum logradouro encontrado com o CEP informado.',
            );
          } else if (dataEndereco) {
            await onLoadLogradouro(dataEndereco.nome);
            form.setFieldsValue({
              logradouroId: dataEndereco.id,
              cep: dataEndereco.cep
                ? mask(dataEndereco.cep, [pattern.cep])
                : '',
              bairro: dataEndereco.bairro.nome,
              cidade: dataEndereco.bairro.cidade.nome.toUpperCase(),
              estado: dataEndereco.bairro.cidade.estadoId,
              pais: 'BRASIL',
            });
          }

          setLoadingEndereco(false);
        })
        .catch(() => {
          setLoadingEndereco(false);
        });
    }
  }

  async function onSearchLogradouro(value) {
    await onLoadLogradouro(value);
  }

  function handleMaskCurrency(e, field) {
    const maskedValue = formatedReal(e.target.value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.'),
    ).toFixed(2);

    form.setFieldsValue({
      [field]: numberValue > 0 ? maskedValue : undefined,
    });
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleChangeImg = async (image) => {
    const isJpgOrPng =
      image.file.type === 'image/jpeg' ||
      image.file.type === 'image/jpg' ||
      image.file.type === 'image/png';

    const isLt2M = image.file.size / 1024 / 1024 < 5;
    if (isJpgOrPng && isLt2M) {
      if (!image.file.url) {
        var base64 = await getBase64(image.file);
        let im = new Image();
        im.src = base64;
        im.onload = () => {
          // if (im?.width !== 250 || im?.height !== 250) {
          //   setErrorLogo([
          //     `Sua imagem é tamanho ${im?.width}px por ${im?.height}px. Favor informe uma no tamanho 250px por 250px.`,
          //   ]);
          // } else {
          image.file.url = base64;
          setErrorLogo([]);
          setFileLogo(image.file);
          // }
        };
      }
    } else {
      setErrorLogo(['Seleção contém imagem(s) com tamanho ou extensão inválida!']);
    }
  };

  const uploadButton = (
    <ButtonAntd icon={<UploadOutlined />}>Arraste a imagem até aqui</ButtonAntd>
  );

  return (
    <Fragment>
      <Form
        form={form}
        scrollToFirstError
        initialValues={{ operacaoImobiliaria: 2 }}>
        <Row>
          {origemImovel === '2' && <Col xs="12" md="2" className="my-2">
            <p className="heading-small text-muted mb-2">
              <small>Operação</small>
            </p>
            <Form.Item
              className="my-2"
              name="operacaoImobiliaria"
              rules={[
                {
                  required: true,
                  message: 'Favor informar a operação',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, ''),
                    ) >= 0
                }
                allowClear
                placeholder=" Tipo de imóvel"
                onChange={(value) => setSelectedOperacaoImobiliaria(value)}>
                {enumOperacaoImobiliaria.map(item => (
                  <Option key={item.id} value={item.id}>{item.nome}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>}
          <Col xs="12" md="8" className="my-2">
            <p className="heading-small text-muted mb-2">
              <small>Condomínio/Empreendimento</small>
            </p>
            <Form.Item name="condominioId">
              <Select

                placeholder=" Digite aqui o nome do Condomínio/Empreendimento"
                rules={[
                  {
                    required: true,
                    message: 'Favor informar o Condomínio/Empreendimento',
                  },
                ]}
                showSearch

                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, ''),
                    ) >= 0
                }
                allowClear
                onSearch={handleSearchCondominio}
                onChange={changeCondominio}
                onClear={changeCondominio}>
                <>
                  {condominios?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {`${item.nome} - ${item?.logradouro?.bairro?.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                    </Option>
                  ))}
                </>
              </Select>
            </Form.Item>
          </Col>
          {(isGerente || isDiretor) && <Col xs="12" md="1" className="my-2">
            <p className="heading-small text-muted mb-1 text-white">
              <small>&nbsp;</small>
            </p>
            <Tooltip
              placement="topRight"
              title={'Cadastrar Novo Condomínio/Empreendimento'}>
              <Button
                style={{ width: '100%' }}
                onClick={() => setModalCondominioOpen(true)}
                icon
                color="violet">
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Col>}
          <Col xs="12" md="3" className="my-2">
            <p className="heading-small text-muted mb-2">
              <small>Tipo de imóvel</small>
            </p>
            <Form.Item
              className="my-2"
              name="tipoImovelId"
              rules={[
                {
                  required: true,
                  message: 'Favor informar o Tipo de imóvel',
                },
              ]}>
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (option?.children) {
                    if (option?.value === 'TODOS')
                      return option?.children;

                    return (
                      option?.children
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, ''),
                        ) >= 0
                    );
                  }
                }
                }
                allowClear
                onChange={handleOnChangeTipoImovel}
                onClear={handleClearTipoImovel}
                placeholder=" Tipo de imóvel">
                {dataTipoImoveis.map(item => (
                  item.options.length > 0 &&
                  <OptGroup key={item.id} label={item.label}>
                    {item.options.map(option => (
                      <Option key={option.id} value={option.id}>{option.label}</Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="3">
            <p className="heading-small text-muted mb-2">
              <small>Qtd. de quartos</small>
            </p>
            <Form.Item className="my-2" name="quantidadeQuartos">
              <Radio.Group
                allowClear
                className='simpleFiltersRadio'
                optionType="button"
                buttonStyle="solid">
                {optionsSimple.map((item) => (
                  <Radio.Button
                    className='simpleFilterRadioButtonMulti'
                    key={item.value}
                    value={item.value}
                    onClick={onClickRadioQuartos}>
                    {item.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs="12" md="3">
            <p className="heading-small text-muted mb-2">
              <small>Suítes</small>
            </p>
            <Form.Item className="my-2" name="quantidadeSuites">
              <Radio.Group
                allowClear
                className='simpleFiltersRadio'
                optionType="button"
                buttonStyle="solid">
                {optionsSimple.map((item) => (
                  <Radio.Button
                    className='simpleFilterRadioButtonMulti'
                    key={item.value}
                    value={item.value}
                    onClick={onClickRadioSuites}>
                    {item.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs="12" md="3">
            <p className="heading-small text-muted mb-2">
              <small>Banheiro social</small>
            </p>
            <Form.Item className="my-2" name="quantidadeBanheiros">
              <Radio.Group
                allowClear
                className='simpleFiltersRadio'
                optionType="button"
                buttonStyle="solid">
                {optionsSimple.map((item) => (
                  <Radio.Button
                    className='simpleFilterRadioButtonMulti'
                    key={item.value}
                    value={item.value}
                    onClick={onClickRadioBanheiros}>
                    {item.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs="12" md="3">
            <p className="heading-small text-muted mb-2">
              <small>Garagem</small>
            </p>
            <Form.Item className="my-2" name="vagasGaragem">
              <Radio.Group
                allowClear
                className='simpleFiltersRadio'
                optionType="button"
                buttonStyle="solid">
                {optionsSimple.map((item) => (
                  <Radio.Button
                    className='simpleFilterRadioButtonMulti'
                    key={item.value}
                    value={item.value}
                    onClick={onClickRadioVagas}>
                    {item.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {loadingEndereco && <SkeletonTableBasic cardShadowless lineNumber={1} />}
          {!loadingEndereco && (
            <Fragment>
              <Col xs="12" md="3">
                <p className="heading-small text-muted mb-2">
                  <small>CEP</small>
                </p>
                <Form.Item className="my-2" name="cep">
                  <Input
                    placeholder=" CEP"
                    maxLength={10}
                    onChange={(e) => handleEndereco(e, pattern.cep)}
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o CEP',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Número</small>
                </p>
                <Form.Item className="my-2" name="numero">
                  <Input placeholder=" Número" />
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Área privada em <b>M²</b></small>
                </p>
                <Form.Item className="my-2" name="areaConstruida">
                  <Input
                    prefix="M²"
                    placeholder=' 0000,00'
                    min={0}
                    type='number' />
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Lote/Apto</small>
                </p>
                <Form.Item className="my-2" name="loteApto">
                  <Input placeholder=" Lote/Apto" />
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Quadra/Bloco</small>
                </p>
                <Form.Item className="my-2" name="quadraBloco">
                  <Input placeholder=" Quadra/Bloco" />
                </Form.Item>
              </Col>
            </Fragment>
          )}
        </Row>
        <Row>
          {loadingEndereco && <SkeletonTableBasic cardShadowless lineNumber={1} />}
          {!loadingEndereco && (
            <Fragment>
              <Col xs="12" md="4">
                <p className="heading-small text-muted mb-2">
                  <small>Logradouro</small>
                </p>
                <Form.Item className="my-2" name="logradouroId">
                  <Select
                    disabled
                    showSearch


                    filterOption={(input, option) =>
                      option?.children[0] &&
                      option.children[0]
                        .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                    }
                    placeholder=" Logradouro"
                    onSearch={onSearchLogradouro}
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar o logradouro',
                      },
                    ]}>
                    {dataLogradouros?.map((item) => (
                      <Option key={item.id}>
                        {item.nome}
                        {item?.bairro?.nome &&
                          `, Bairro: ${item?.bairro?.nome}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs="12" md="2">
                <p className="heading-small text-muted mb-2">
                  <small>Estado</small>
                </p>
                <Form.Item className="my-2" name="estado">
                  <Input placeholder=" Estado" disabled />
                </Form.Item>
              </Col>
              <Col xs="12" md="3">
                <p className="heading-small text-muted mb-2">
                  <small>Cidade</small>
                </p>
                <Form.Item className="my-2" name="cidade">
                  <Input placeholder=" Cidade" disabled />
                </Form.Item>
              </Col>
              <Col xs="12" md="3">
                <p className="heading-small text-muted mb-2">
                  <small>Bairro</small>
                </p>
                <Form.Item className="my-2" name="bairro">
                  <Input placeholder=" Bairro" disabled />
                </Form.Item>
              </Col>
            </Fragment>
          )}
        </Row>
        <Row>
          {selectedOperacaoImobiliaria !== 1 && (
            <Fragment>
              <Col xs="12" md="4">
                <p className="heading-small text-muted mb-2">
                  <small>Valor de Venda</small>
                </p>
                <Form.Item className="my-2" name="valorVenda">
                  <Input
                    placeholder=" Valor de Venda"
                    onChange={(e) =>
                      handleMaskCurrency(e, 'valorVenda')
                    }
                    prefix="R$"
                  />
                </Form.Item>
              </Col>
              <Col xs="12" md="4">
                <p className="heading-small text-muted mb-2">
                  <small>Comissão de Venda</small>
                </p>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a Comissão de Venda',
                    },
                  ]}
                  className="my-2" name="comissaoVenda">
                  <Input
                    placeholder=" Comissão de Venda"
                    onChange={(e) => handleMaskCurrency(e, 'comissaoVenda')}
                    prefix="R$"
                  />
                </Form.Item>
              </Col>
            </Fragment>
          )}
          {selectedOperacaoImobiliaria !== 2 && (
            <Col xs="12" md="4">
              <p className="heading-small text-muted mb-2">
                <small>Valor do Aluguel</small>
              </p>
              <Form.Item className="my-2"
                rules={[
                  {
                    required: true,
                    message: 'Favor informar o Valor do Aluguel',
                  },
                ]}
                name="valorAluguel">
                <Input
                  placeholder=" Valor do Aluguel"
                  onChange={(e) =>
                    handleMaskCurrency(e, 'valorAluguel')
                  }
                  prefix="R$"
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs="12">
            <p className="heading-small text-muted mb-2">
              <small>Foto do imóvel</small>
            </p>
            <Upload
              accept={'image/png,image/jpeg,image/png'}
              name="foto"
              className={fileLogo ? 'selectedImage' : ''}
              listType="picture-card"
              multiple={false}
              showUploadList={false}
              onChange={handleChangeImg}
              beforeUpload={(file) => {
                return false;
              }}>
              {fileLogo?.url ? (
                <img
                  className='selectedImageMaxHeight'
                  src={fileLogo.url}
                  alt="foto"
                />
              ) : (
                uploadButton
              )}
            </Upload>
            {errorLogo.map((e, index) => (
              <p
                key={index}
                style={{ color: red[400] }}>{`- ${e}`}</p>
            ))}
          </Col>
        </Row>
      </Form>
      <Row className="mt-1">
        <Col xs="12" md="4">
          <Button
            onClick={() => onSave()}
            icon
            labelPosition="left"
            size="medium"
            fluid
            className="mt-3"
            color="violet"
            isloading={loading.toString()}
            loadtext="Cadastrando...">
            <Icon name="check" />
            Cadastrar
          </Button>
        </Col>
        <Col xs="12" md="4">
          <Button
            icon
            labelPosition="left"
            size="medium"
            fluid
            className="mt-3"
            onClick={() => setOpenModalImovelSimplificado(false)}>
            <Icon name="undo" />
            Voltar
          </Button>
        </Col>
      </Row>
      <ModalCondominio
        open={modalCondominioOpen}
        close={() => {
          setModalCondominioOpen(false);
        }}
      />
    </Fragment>
  );
}

export default ImovelSimplificado;
