/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useRef } from 'react';
import { Form, Input, Tooltip, Table } from 'antd';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import Page from '~/components/Page';
import useMenu from '~/actions/Menu/useMenu';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import { Icon } from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';
import disabled from '~/assets/img/cancel.png';
import enabled from '~/assets/img/check-mark.png';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const text = {
  display: <span>Visualizar menu</span>,
  enabled: <span>Ativar menu</span>,
  disabled: <span>Inativar menu</span>,
  delete: <span>Excluir menu</span>,
};

export function rowEdit(row) {
  history.push({
    pathname: `/app/menu/manutencao/${MODO.EDITAR}`,
    state: { row },
  });
}

export function navToNovo() {
  history.push(`/app/menu/manutencao/${MODO.CADASTRAR}`);
}

function ConsultaMenu() {
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [menuId, setMenuId] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState(null);
  const [modalDsc, setModalDsc] = useState('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const ref = useRef();
  const { isLoading, data, quantidade, onLoad } = useMenu();

  // eslint-disable-next-line no-unused-vars
  async function onShowSizeChange(current, pageSize) {
    setPageSize(pageSize);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id, message, type, desc) {
    setModalMessage(message);
    setModalType(type);
    setModalDsc(desc);
    setOpenModalConfirm(true);
    setMenuId(id);
  }

  async function handleConfirmDelete() {
    if (menuId) {
      setLoading(true);
      setloadTable(true);

      await api
        .delete(`${url.MENU.DELETAR(menuId)}`)
        .then((response) => {
          setLoading(false);
          onLoad();
          setloadTable(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro inativado com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setloadTable(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  async function handleConfirmEnabled() {
    if (menuId) {
      setLoading(true);
      setloadTable(true);

      const payload = {
        id: menuId,
        excluido: false,
        descricao: modalDsc,
      };

      await api
        .put(url.MENU.EDITAR(), payload)
        .then((res) => {
          setLoading(false);
          onLoad();
          setloadTable(false);
          setOpenModalConfirm(false);
          Notify('success', '', pt.geral.atualizado_com_sucesso);
        })
        .catch((err) => {
          setLoading(false);
          setloadTable(false);
          setOpenModalConfirm(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Submenu',
      dataIndex: 'menuPaiId',
      key: 'menuPaiId',
      render: (row) => <p>{row !== null ? 'SIM' : 'NÃO'}</p>,
    },
    {
      title: '',
      dataIndex: 'excluido',
      key: 'excluido',
      render: (row) => (
        <Tooltip
          placement="topRight"
          title={row === true ? 'Inativo' : 'Ativo'}>
          <img
            src={row === true ? disabled : enabled}
            alt="Menu Inativo"
            style={{ marginTop: '-5px' }}
          />
        </Tooltip>
      ),
    },
    {
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
    {
      title: 'Inativar/Ativar',
      key: '',
      fixed: 'right',
      width: 140,
      render: (row) => (
        <div className="p-1" key={row.index}>
          {row.excluido === true ? (
            <i
              onClick={() =>
                handleModalConfirm(
                  row.id,
                  'Deseja realmente ativar esse menu?',
                  'update',
                  row.descricao,
                )
              }
              className="ri-checkbox-circle-line linkTable"
              style={{ fontSize: '26px', marginRight: '10px' }}></i>
          ) : null}
          {row.excluido === false ? (
            <i
              onClick={() =>
                handleModalConfirm(
                  row.id,
                  'Deseja realmente inativar esse menu?',
                  'delete',
                  row.descricao,
                )
              }
              className="ri-close-circle-line linkTable"
              style={{ fontSize: '26px', marginRight: '10px' }}></i>
          ) : null}
        </div>
      ),
    },
  ];

  const expandedRowRender = (filhos) => {
    return (
      <div className="p-3">
        <Table columns={columns} dataSource={filhos} pagination={false} />
      </div>
    );
  };

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de menu</h5>
                <p className="category">Manutenção de menu</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item className="my-2" name="nomeMenu">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="3">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              size="tiny"
                              icon
                              color="green"
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${quantidade})`}</h5>
                <HeaderFiltros
                  navToNovo={() => navToNovo()}
                  description="Novo menu"
                />
              </CardHeader>
              <CardBody>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        expandable={{
                          expandedRowRender: (record) =>
                            expandedRowRender(record.filhos),
                          rowExpandable: (record) => record.filhos.length > 0,
                        }}
                        pagination={defaultPagination(
                          current,
                          quantidade,
                          changePage,
                        )}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              buttonText={modalType === 'delete' ? 'Excluir' : 'Confirmar'}
              buttonColor={modalType === 'delete' ? 'red' : 'violet'}
              buttonIcon={modalType === 'delete' ? 'trash' : 'check'}
              loading={loading}
              open={openModalConfirm}
              confirm={
                modalType === 'delete'
                  ? handleConfirmDelete
                  : handleConfirmEnabled
              }
              close={handleCloseModal}
              message={modalMessage}
              loadingText={
                modalType === 'delete' ? 'Inativando...' : 'Ativando...'
              }
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaMenu;
