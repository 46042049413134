import React, {Fragment, useState, useRef} from 'react';
import {Form, Input, Tooltip, Table} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

import Page from '~/components/Page';
import useTipoHonorario from '~/actions/TipoHonorario/useTipoHonorario';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import {Icon} from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const text = {
  display: <span>Visualizar tipo de honorário</span>,
  delete: <span>Excluir tipo de honorário</span>,
};

export function rowEdit(row) {
  history.push({
    pathname: `/app/tipohonorario/manutencao/${MODO.EDITAR}`,
    state: {row},
  });
}

export function navToNovo() {
  history.push(`/app/tipohonorario/manutencao/${MODO.CADASTRAR}`);
}

function ConsultaTipoHonorario() {
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [tipoHonorarioId, setTipoHonorarioId] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isRecarregar, setIsRecarregar] = useState(false);

  const [nomeTipoHonorario, setNomeTipoHonorario] = useState('');

  const ref = useRef();
  const {isLoading, data, quantidade} = useTipoHonorario(
    current,
    pageSize,
    nomeTipoHonorario,
    isRecarregar,
  );

  // eslint-disable-next-line no-unused-vars
  async function onShowSizeChange(current, pageSize) {
    setPageSize(pageSize);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    const {nomeTipoHonorario} = ref.current.getFieldsValue();

    setNomeTipoHonorario(nomeTipoHonorario);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setTipoHonorarioId(id);
  }

  async function handleConfirmDelete() {
    if (tipoHonorarioId) {
      setLoading(true);

      await api
        .delete(`${url.TIPO_HONORARIO.DELETAR()}/${tipoHonorarioId}`)
        .then((response) => {
          setLoading(false);
          setIsRecarregar(!isRecarregar);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          <Tooltip placement="topRight" title={text.display}>
            <i
              onClick={() => rowEdit(row)}
              className="ri-account-circle-fill linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </Tooltip>
          <Tooltip placement="topRight" title={text.delete}>
            <i
              onClick={() => handleModalConfirm(row.id)}
              className="ri-delete-bin-6-fill linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </Tooltip>
        </div>
      ),
    },
  ];

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de tipo de honorário</h5>
                <p className="category">Manutenção de tipo de honorário</p>
                <HeaderFiltros navToNovo={() => navToNovo()} />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <Form.Item
                              className="my-2"
                              name="nomeTipoHonorario">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <Button
                              className="my-2"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={{
                          current: current,
                          showSizeChanger: true,
                          defaultCurrent: 1,
                          total: quantidade,
                          responsive: true,
                          onChange: changePage,
                          onShowSizeChange: onShowSizeChange,
                        }}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              title="Atenção"
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaTipoHonorario;
