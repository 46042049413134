import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

let controller;
let canceledRequest = false;

export default function useCidadesDropdownGrupos(term) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const cancelRequest = () => {
    if (controller) {
      canceledRequest = true;
      controller.abort();
    }
  }

  const onLoad = useCallback(
    async (params) => {
      controller = new AbortController();
      const signal = controller.signal;

      const filterTerm = term ? term : null;
      let filter;

      if (params) filter = `?term=${params}`
      else filter = `?term=${filterTerm}`

      if (term || params) {
        setIsLoading(true);
        var url_busca = String(params).includes('|') ? `${url.DROPDOWN.CIDADE_DROPDOWN_GRUPO_ID()}${filter}` : `${url.DROPDOWN.CIDADE_DROPDOWN_GRUPO()}${filter}`;
        try {
          await api
            .get(url_busca, { signal })
            .then((res) => {
              canceledRequest = false;
              setData(res.data || []);
            });
        } catch (e) {
          setData([]);
        } finally {
          if (!canceledRequest) setIsLoading(false);
        }
      } else {
        setData([]);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [term],
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
    cancelRequest,
  };
}
