const statusAtendimento = [
  { id: 4, name: 'Lead na Base' },
  { id: 8, name: 'Em Tentativa' },
  { id: 2, name: 'Em Atendimento' },
  { id: 9, name: 'Em Visita' },
  { id: 10, name: 'Em Proposta' },
  { id: 1, name: 'Negócio Fechado' },
  { id: 3, name: 'Perdido' },
  { id: 5, name: 'Em Análise Financeira' },
  { id: 6, name: 'Análise Financeira Aprovada' },
  { id: 7, name: 'Análise Financeira Reprovada' },
];

export default statusAtendimento;
