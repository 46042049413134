import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';

export default function usePlantoes(current, pageSize, nomePlantao) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const tamanhoPagina = pageSize ? pageSize : 10;
    const numeroPagina = current ? current : 1;
    const descricao = nomePlantao ? `&descricao=${nomePlantao}` : '';

    try {
      await api
        .get(
          `plantoes?numeroPagina=${numeroPagina}&tamanhoPagina=${tamanhoPagina}${descricao}`,
        )
        .then((res) => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.total);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [current, nomePlantao, pageSize]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
