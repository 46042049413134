import React, {useEffect, useState} from 'react';
import {Form, Switch, Collapse, Input, Select} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Icon} from 'semantic-ui-react';
import { mask, unMask } from 'remask';

import Notify from '~/shared/Notify';
import Button from '~/shared/Button';
import Page from '~/components/Page';

import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls';
import history from '~/services/history';
import pattern from '~/infra/resources/pattern/mask';
import {CaretDownOutlined} from '@ant-design/icons';
import useConfigSite from '~/actions/Site/useConfigSite';
import UploadImage from '~/components/UploadImage';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import {HexColorInput, HexColorPicker} from 'react-colorful';

const {Option} = Select;

function Site() {
  const [loading, setLoading] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [fileLogo, setFileLogo] = useState(null);
  const [fileLogoRodape, setFileLogoRodape] = useState(null);
  const [fileBackgroundTopo, setFileBackgroundTopo] = useState(null);
  const [isAlterLogo, setAlterLogo] = useState(false);
  const [isAlterLogoRodape, setAlterLogoRodape] = useState(false);
  const [fileFavicon, setFileFavicon] = useState(null);
  const [isAlterIcone, setAlterIcone] = useState(false);
  const [isAlterBanner, setAlterBanner] = useState(false);
  const [isAlterBackgroundTopo, setAlterBackgroundTopo] = useState(false);
  const [operacaoImobiliaria, setOperacaoImobiliaria] = useState(null);
  const [fileBanner, setBanner] = useState([]);
  const {data, onLoad, isLoading} = useConfigSite();
  const [form] = Form.useForm();

  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#000000');
  const [tertiaryColor, setTertiaryColor] = useState('#000000');
  const [fonteMenuColor, setFonteMenuColor] = useState('#000000');
  const [backgroundColorTopo, setBackgroundColorTopo] = useState('#000000');

  useEffect(() => {
    setInitialValues({
      textoDestaque: data?.textoDestaque,
      netlifySiteId: data?.netlifySiteId,
      primaryColor: data?.primaryColor,
      secondaryColor: data?.secondaryColor,
      fonteMenuColor: data?.fonteMenuColor,
      backgroundColorTopo: data?.backgroundColorTopo,
      tertiaryColor: data?.tertiaryColor,
      urlFacebook: data?.urlFacebook,
      urlYoutube: data?.urlYoutube,
      urlInstagram: data?.urlInstagram,
      urlX: data?.urlX,
      idGoogleAnalytics: data?.idGoogleAnalytics,
      idFacebookPixel: data?.idFacebookPixel,
      idGoogleTagManager: data?.idGoogleTagManager,
      utilizaSimulacao: data?.utilizaSimulacao,
      tipoOperacao: data?.tipoOperacao,
      telefoneWhatsapp: data?.telefoneWhatsapp ? mask(data?.telefoneWhatsapp, [pattern.celular]) : null,
      telefoneRodape: data?.telefoneRodape ? mask(data?.telefoneRodape, [pattern.celular]) : null,
    });
    setOperacaoImobiliaria(data?.tipoOperacao);
    setFileLogo({url: data?.urlLogoMarca});
    setFileLogoRodape({url: data?.urlLogoRodape});
    setFileFavicon({url: data?.urlIcone});
    setFileBackgroundTopo({url: data?.urlBackgroundTopo});
    if (data?.banners) setBanner(data?.banners);

    setPrimaryColor(data?.primaryColor || '#0019ff');
    setSecondaryColor(data?.secondaryColor || '#00183f');
    setTertiaryColor(data?.tertiaryColor || '#b2ceff');
    setFonteMenuColor(data?.fonteMenuColor || '#f7f7f7');
    setBackgroundColorTopo(data?.backgroundColorTopo || '#4d4d4d');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function handlerChange(img) {
    setBanner((prev) => [...prev, img]);
  }

  const limparImagens = () => {
    setBanner([]);
    setAlterBanner(true);
  };

  const handleInputChange = (index, value) => {
    const updatedLinkBanner = [...fileBanner];
    updatedLinkBanner[index].linkBanner = value;
    setBanner(updatedLinkBanner);
  };

  function changeOperacaoImobiliaria(value) {
    setOperacaoImobiliaria(value);
  }

  async function onSave() {
    setLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const banners = fileBanner.map((banner, index) => ({
          urlBanner: banner.url,
          linkBanner: banner.linkBanner,
        }));
        const payload = {
          ...dados,
          tipoOperacao: operacaoImobiliaria,
          urlLogoMarca: fileLogo.url,
          urlLogoRodape: fileLogoRodape.url,
          banners: banners,
          urlIcone: fileFavicon.url,
          urlBackgroundTopo: fileBackgroundTopo.url,
          isAlterBanner,
          isAlterLogo,
          isAlterLogoRodape,
          isAlterIcone,
          isAlterBackgroundTopo,
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          tertiaryColor: tertiaryColor,
          fonteMenuColor: fonteMenuColor,
          backgroundColorTopo: backgroundColorTopo,
        };
        api
          .post(url.CONFIGURACOES_SITE.CONFIGURACOES_SITE(), payload)
          .then(async (res) => {
            Notify('success', '', 'Configuração atualizada com sucesso');
            await onLoad();
            history.push('/app/site');
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            const {errors} = err.response.data;
            if (errors) {
              for (const item in errors) {
                Notify('error', pt.comum.atencao, errors[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        setLoading(false);
        window.scrollTo(1, 1);
      });
  }

  async function onPublish() {
    setPublishing(true);
    api
      .post(`${url.CONFIGURACOES_SITE.CONFIGURACOES_SITE()}/publish`, null)
      .then(async (res) => {
        Notify('success', '', 'Publicação enviada com sucesso');
        await onLoad();
        history.push('/app/site');
        setPublishing(false);
      })
      .catch((err) => {
        setPublishing(false);
        const {error} = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  function handleMask(e, type, field, formField) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (field === 'celular' || field === 'telefone') {
      form.setFieldsValue({ [formField]: value });
    } else
      form.setFieldsValue({ [field]: value });
    }

  return isLoading ? (
    <SkeletonTableBasic />
  ) : (
    <Page loadPage={isLoading}>
      <Row>
        <Col>
          <Card className="p-2">
            <CardHeader>
              <h5 className="title">Site</h5>
              <p className="category">Configurações do site</p>
            </CardHeader>
            <CardBody>
              <div className="p-2">
                <Form
                  form={form}
                  initialValues={initialValues}
                  scrollToFirstError>
                  <Collapse
                    className="fixPadding"
                    defaultActiveKey={[
                      'CP-1',
                      'CP-2',
                      'CP-2',
                      'CP-3',
                      'CP-4',
                      'CP-5',
                    ]}
                    bordered={false}
                    ghost
                    expandIconPosition="right"
                    expandIcon={({isActive}) => (
                      <div className="container-expand">
                        <span className="content-expand">
                          {isActive ? 'Ver menos' : 'Ver mais'}
                        </span>
                        <CaretDownOutlined
                          style={{fontSize: 20}}
                          rotate={isActive ? 180 : 0}
                        />
                      </div>
                    )}>
                    <Collapse.Panel key="CP-1" header="Layout">
                      <Row>
                        <Col xs="12" md="12">
                          <p className="heading-small text-muted mb-2">
                            <small>Texto em Destaque no Site</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            rules={[
                              {
                                required: true,
                                message: 'Informe um texto para o site',
                              },
                            ]}
                            name="textoDestaque">
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Id do Site na Netlify</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            rules={[
                              {
                                required: true,
                                message: 'Informe um id do site',
                              },
                            ]}
                            name="netlifySiteId">
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs="12" md="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Operação Imobiliária</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="tipoOperacao"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Favor informar a operação imobiliária',
                              },
                            ]}>
                            <Select
                              placeholder=" Operação Imobiliária"
                              onChange={changeOperacaoImobiliaria}>
                              {enumOperacaoImobiliaria.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.nome}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Url Facebook</small>
                          </p>
                          <Form.Item className="my-2" name="urlFacebook">
                            <Input prefix="facebook.com/" />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Url Youtube</small>
                          </p>
                          <Form.Item className="my-2" name="urlYoutube">
                            <Input prefix="youtube.com/" />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Url Instagram</small>
                          </p>
                          <Form.Item className="my-2" name="urlInstagram">
                            <Input prefix="instagram.com/" />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Url X (Twitter)</small>
                          </p>
                          <Form.Item className="my-2" name="urlX">
                            <Input prefix="x.com/" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Telefone (WhatsApp)</small>
                          </p>
                          <Form.Item className="my-2" name="telefoneWhatsapp">
                            <Input
                              onKeyUp={(e) => handleMask(e, pattern.celular, 'celular', 'telefoneWhatsapp')}
                              maxLength={20}
                              placeholder=" DDD + Telefone"
                              style={{ width: '70%' }} />
                          </Form.Item>
                        </Col>

                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Telefone (rodapé)</small>
                          </p>
                          <Form.Item className="my-2" name="telefoneRodape">
                          <Input
                              onKeyUp={(e) => handleMask(e, pattern.celular, 'celular', 'telefoneRodape')}
                              maxLength={20}
                              placeholder=" DDD + Telefone"
                              style={{ width: '70%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-2" header="Cores">
                      <Row>
                        <Col xs="12" md="6" lg="4" xl="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Cor Primária</small>
                          </p>
                          <Form.Item className="my-2" name="primaryColor">
                            <HexColorPicker
                              color={primaryColor}
                              onChange={setPrimaryColor}
                            />
                            <HexColorInput
                              prefixed
                              name="primaryColor"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={primaryColor}
                              onChange={setPrimaryColor}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6" lg="4" xl="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Cor Secundária</small>
                          </p>
                          <Form.Item className="my-2" name="secondaryColor">
                            <HexColorPicker
                              color={secondaryColor}
                              onChange={setSecondaryColor}
                            />
                            <HexColorInput
                              prefixed
                              name="secondaryColor"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={secondaryColor}
                              onChange={setSecondaryColor}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6" lg="4" xl="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Cor Terciária</small>
                          </p>
                          <Form.Item className="my-2" name="tertiaryColor">
                            <HexColorPicker
                              color={tertiaryColor}
                              onChange={setTertiaryColor}
                            />
                            <HexColorInput
                              prefixed
                              name="tertiaryColor"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={tertiaryColor}
                              onChange={setTertiaryColor}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6" lg="4" xl="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Cor Fonte Menu</small>
                          </p>
                          <Form.Item className="my-2" name="fonteMenuColor">
                            <HexColorPicker
                              color={fonteMenuColor}
                              onChange={setFonteMenuColor}
                            />
                            <HexColorInput
                              prefixed
                              name="fonteMenuColor"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={fonteMenuColor}
                              onChange={setFonteMenuColor}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6" lg="4" xl="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Cor Background Topo</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="backgroundColorTopo">
                            <HexColorPicker
                              color={backgroundColorTopo}
                              onChange={setBackgroundColorTopo}
                            />
                            <HexColorInput
                              prefixed
                              name="backgroundColorTopo"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={backgroundColorTopo}
                              onChange={setBackgroundColorTopo}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-3" header="Imagens">
                      <Row>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Logomarca</small>
                          </p>
                          <Row>
                            <Col xs="12" md="2">
                              <UploadImage
                                minHeight={46}
                                minWidth={151}
                                setAlterLogo={setAlterLogo}
                                setFileLogo={setFileLogo}
                                fileLogo={fileLogo}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Logomarca de rodapé</small>
                          </p>
                          <Row>
                            <Col xs="12" md="2">
                              <UploadImage
                                minHeight={46}
                                minWidth={151}
                                setAlterLogo={setAlterLogoRodape}
                                setFileLogo={setFileLogoRodape}
                                fileLogo={fileLogoRodape}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Ícone</small>
                          </p>
                          <Row>
                            <Col xs="12" md="2">
                              <UploadImage
                                minHeight={46}
                                minWidth={151}
                                setAlterLogo={setAlterIcone}
                                setFileLogo={setFileFavicon}
                                fileLogo={fileFavicon}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="12">
                          <p className="heading-small text-muted mb-2">
                            <small>Background Topo</small>
                          </p>
                          <Row>
                            <Col xs="12" md="2">
                              <UploadImage
                                minHeight={472}
                                minWidth={708}
                                setAlterLogo={setAlterBackgroundTopo}
                                setFileLogo={setFileBackgroundTopo}
                                fileLogo={fileBackgroundTopo}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="12">
                          <p className="heading-small text-muted mb-2">
                            <small>Banners</small>
                          </p>
                          <Row>
                            <Col xs="12" md="12">
                              <p className="heading-small text-muted mb-2">
                                <small>Banners do Site</small>
                              </p>
                              <UploadImage
                                multiple
                                showUploadList
                                minHeight={620}
                                minWidth={1920}
                                setAlterLogo={setAlterBanner}
                                setFileLogo={handlerChange}
                                fileLogo={fileBanner}
                                limparImagens={limparImagens}
                                showClear
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        {fileBanner &&
                          fileBanner.map((file, index) => {
                            return (
                              <Col key={`col-${index}`} xs="12" md="3">
                                <p
                                  key={`p-${index}`}
                                  className="heading-small text-muted mb-2">
                                  <small>{`Url do Banner ${index + 1}`}</small>
                                </p>
                                <Input
                                  value={fileBanner[index].linkBanner}
                                  onChange={(e) =>
                                    handleInputChange(index, e.target.value)
                                  }
                                />
                              </Col>
                            );
                          })}
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-4" header="Google">
                      <Row>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Id Google Analytics</small>
                          </p>
                          <Form.Item className="my-2" name="idGoogleAnalytics">
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Id Google Tag Manager</small>
                          </p>
                          <Form.Item className="my-2" name="idGoogleTagManager">
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-5" header="Facebook">
                      <Row>
                        <Col xs="12" md="6">
                          <p className="heading-small text-muted mb-2">
                            <small>Id Facebook Pixel</small>
                          </p>
                          <Form.Item className="my-2" name="idFacebookPixel">
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-6" header="Imóveis">
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Utiliza Simulação?</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="utilizaSimulacao"
                            valuePropName="checked">
                            <Switch
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Form>
                <Row className="mt-2">
                  <Col xs="12" md="3">
                    <Button
                      onClick={onSave}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      color="violet"
                      isloading={loading.toString()}
                      loadtext="Salvando...">
                      <Icon name="check" />
                      Salvar
                    </Button>
                  </Col>
                  <Col xs="12" md="3">
                    <Button
                      onClick={onPublish}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      color="violet"
                      isloading={publishing.toString()}
                      loadtext={'Publicando...'}
                      disabled={initialValues.id === null}>
                      <Icon name="building" />
                      Publicar
                    </Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

export default Site;
