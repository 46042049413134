/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useRef } from 'react';
import { Form, Input, Table } from 'antd';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { mask, unMask } from 'remask';

import Page from '~/components/Page';
import useEmpresa from '~/actions/Empresa/useEmpresa';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';
import history from '~/services/history';
import Button from '~/shared/Button';
import formatedMask from '~/shared/formatedMask';
import Notify from '~/shared/Notify';
import { Icon } from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';
import api from '~/services/api';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(row) {
  history.push({
    pathname: `/app/empresa/manutencao/${MODO.EDITAR}`,
    state: { row },
  });
}

export function navToNovo() {
  history.push(`/app/empresa/manutencao/${MODO.CADASTRAR}`);
}

function ConsultaEmpresa() {
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(9999);

  const [loading, setLoading] = useState(false);
  const [empresaId, setEmpresaId] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [cnpj, setCNPJ] = useState('');

  const ref = useRef();
  const { isLoading, data, quantidade, onLoad } = useEmpresa(
    current,
    pageSize,
    nomeEmpresa,
    cnpj
  );

  // eslint-disable-next-line no-unused-vars
  async function onShowSizeChange(current, pageSize) {
    setPageSize(pageSize);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    const { nomeEmpresa, cnpj } = ref.current.getFieldsValue();

    setNomeEmpresa(nomeEmpresa);
    if(cnpj !== undefined){
      setCNPJ(unMask(cnpj));
    }

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setEmpresaId(id);
  }

  async function handleConfirmDelete() {
    if (empresaId) {
      setLoading(true);
      setloadTable(true);
      await api
        .delete(`${url.EMPRESAS.DELETAR()}/${empresaId}`)
        .then((response) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
          onLoad();
          setloadTable(false);
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          setloadTable(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function handleMask(e, typeMask) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [typeMask]);

    if (typeMask === pattern.cnpj) ref.current.setFieldsValue({ cnpj: value });
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      responsive: ['md', 'lg'],
      render: (cnpj) => <span>{cnpj ? formatedMask(cnpj, '##.###.###/####-##') : ''}</span>,
    },
    {
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
    {
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
  ];

  const columnsExpanded = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      responsive: ['md', 'lg'],
      render: (cnpj) => <span>{formatedMask(cnpj || '', '##.###.###/####-##')}</span>,
    },
    {
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
    {
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    },
  ];

  const expandedRowRender = (filiais) => {
    return (
      <div className="p-3">
        <Table
          columns={columnsExpanded}
          dataSource={filiais}
          pagination={false}
        />
      </div>
    );
  };

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de empresa</h5>
                <p className="category">Manutenção de empresa</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item className="my-2" name="nomeEmpresa">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="3">
                            <p className="heading-small text-muted mb-2">
                              <small>CNPJ</small>
                            </p>
                            <Form.Item className="my-2" name="cnpj">
                              <Input
                                placeholder=" CNPJ"
                                maxLength={18}
                                onKeyUp={(e) => handleMask(e, pattern.cnpj)}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              size="tiny"
                              icon
                              color="green"
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${quantidade})`}</h5>
                <HeaderFiltros navToNovo={() => navToNovo()} description="Nova empresa" />
              </CardHeader>
              <CardBody>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        expandable={{
                          expandedRowRender: (record) =>
                            expandedRowRender(record?.filiais),
                          rowExpandable: (record) =>
                            record?.filiais?.length > 0,
                        }}
                        pagination={defaultPagination(current, quantidade, changePage)}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              message="Deseja realmente excluir o registro? A empresa e todas as suas filiais serão deletadas!"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaEmpresa;
