/* eslint-disable no-unused-expressions */
import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useEmpresas(current, pageSize, nomeEmpresa, cnpj) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);

    const filterCurrent = current ? current : null;
    const tamanhoPagina = pageSize ? pageSize : null;
    const filterNomeFormacao = nomeEmpresa ? nomeEmpresa : null;
    const filterCNPJ = cnpj ? cnpj : null;
    let filter = '';

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (tamanhoPagina) {
      if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
      else filter = `?tamanhoPagina=${tamanhoPagina}`;
    }

    if (filterNomeFormacao) {
      if (filter.length > 0) filter += `&nome=${filterNomeFormacao}`;
      else filter = `?nome=${filterNomeFormacao}`;
    }

    if (filterCNPJ) {
      if (filter.length > 0) filter += `&cnpj=${filterCNPJ}`;
      else filter = `?cnpj=${filterCNPJ}`;
    }

    try {
      await api
        .get(`${url.EMPRESAS.ALL()}${filter}`)
        .then((res) => {
          setData(res?.data || []);
          setQuantidade(res?.data.length || 0);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize, nomeEmpresa, cnpj, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
