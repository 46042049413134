/* eslint-disable no-unused-expressions */
import React, {Fragment, useState} from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardImg,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import {Menu, Dropdown, Checkbox, message, Switch, Tooltip, Image} from 'antd';
import {
  MoreOutlined,
  SearchOutlined,
  EditOutlined,
  CopyOutlined,
  ShareAltOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {Icon} from 'semantic-ui-react';

import Notify from '~/shared/Notify';
import formatCurrency from '~/shared/formatCurrency';
import imgImovel from '~/assets/img/sala.jpg';
import api from '~/services/api';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';

import OperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import SituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';

import formatNumber from '~/shared/formatNumber';
import getMaskedNumberBR from '~/shared/getMaskedNumberBR';

import './style.css';

function RowCardImovel({
  imovel,
  identificador,
  onPreview,
  onEdit,
  actions,
  isModalConsulta,
  realStateForPresentation,
  setShowClearSelection,
  setRealStateForPresentation,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkImovel, setCheckImovel] = useState();

  const empresaId = localStorage.getItem('icaseEmpresaId');
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isMaster =
    roles && !!roles.find((perfil) => perfil.toUpperCase() === 'MASTER');
  const isMasterLead = JSON.parse(localStorage.getItem('icaseMasterLeads'));
  const isCorretor =
    roles && !!roles.find((perfil) => perfil.toUpperCase() === 'CORRETOR');

  const operacao = OperacaoImobiliaria.find(
    (item) => item.id === imovel.operacaoImobiliaria,
  );

  const situacao = SituacaoImovel.find((item) => item.id === imovel.situacao);
  const hasImagens = imovel.imagens ? !!imovel.imagens[0] : false;

  const checked = !isModalConsulta
    ? !!realStateForPresentation.find((item) => item.id === imovel.id)
    : false;

  const editar = !!actions?.find(
    (create) => create.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );

  const isPublishImovel = !!actions?.find(
    (create) => create.id === '85b9d87f-aacf-49ea-a630-5ce8b278f765',
  );

  const isRoledToEditOtherUser = !!actions?.find(
    (action) => action.id === '5022d55b-05a8-4dd8-85df-9e105ba545e1',
  );


  const hasImmobileBroker =
    (imovel.corretorCaptacaoId === localStorage.getItem('icaseUsuarioId') || imovel.corretorCaptacao2Id === localStorage.getItem('icaseUsuarioId')) &&
    isCorretor;

  const exibeBotaoEditar =
    (isMaster ||
      isMasterLead ||
      (editar && hasImmobileBroker) ||
      (isRoledToEditOtherUser && !hasImmobileBroker)) &&
    empresaId === imovel.empresaId;

  const items = [];

  const initItems = async () => {
    await imovel?.imagens.forEach((imagem, index) => {
      items.push({key: index + 1, src: imagem?.arquivo, altText: ''});
    });
  };

  initItems();
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items
    ? items?.map((item) => {
        return (
          <CarouselItem
            className="containerWidth"
            key={item.src}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}>
            <Image
              width={'100%'}
              height={'100%'}
              style={{height: '100%', width: '100%', objectFit: 'cover'}}
              src={item.src}
              alt={item.altText}
            />
          </CarouselItem>
        );
      })
    : null;

  const copyLink = async (urlSite, link) => {
    if (urlSite) {
      try {
        await navigator.clipboard.writeText(link);
        message.success('URL do imóvel copiada com sucesso!');
      } catch (err) {
        message.error('Ocorreu um erro ao copiar a URL do imóvel!');
      }
    } else {
      Notify('error', 'URL do site não informada no cadastro de empresa!');
    }
  };

  const shareLink = async (urlSite, link) => {
    if (urlSite) {
      try {
        window.open(`https://wa.me/send?text=${link}`);
      } catch (err) {
        message.error('Ocorreu um erro ao compartilhar o link do imóvel');
      }
    } else {
      Notify('error', 'URL do site não informada no cadastro de empresa!');
    }
  };

  const handleOnChange = (value = false, imovel) => {
    const newRealStateForPresentation = [...realStateForPresentation];
    const index = newRealStateForPresentation.findIndex(
      (item) => item.id === imovel.id,
    );

    if (newRealStateForPresentation.length === 3 && value === true) {
      Notify(
        'error',
        'Atenção',
        'Permitida somente a seleção de 03(três) imóveis para apresentação.',
      );
    } else {
      if (value) {
        if (index < 0) {
          newRealStateForPresentation.push(imovel);
        }
      } else {
        if (index > -1) {
          newRealStateForPresentation.splice(index, 1);
        }
      }

      setRealStateForPresentation(newRealStateForPresentation);
      setShowClearSelection(!!newRealStateForPresentation.length);
    }
  };

  const menuItems = [];
  menuItems.push({
    label: 'Visualizar preview',
    key: 0,
    icon: <SearchOutlined />,
    onClick: onPreview ? () => onPreview(imovel, identificador) : null,
  });

  if (exibeBotaoEditar) {
    menuItems.push({
      label: 'Editar imóvel',
      key: 1,
      icon: <EditOutlined />,
      onClick: onEdit ? () => onEdit(imovel, identificador) : null,
    });
  }

  const hasOwnWebsite = !!imovel?.divulgacoes?.find(
    (x) => x?.tipoMidia === 103,
  ); // 103 - Site próprio
  if (imovel?.divulgacoes.length > 0 && hasOwnWebsite) {
    menuItems.push({type: 'divider'});

    menuItems.push({
      label: 'Copiar link',
      key: 2,
      icon: <CopyOutlined />,
      onClick: () =>
        copyLink(
          imovel?.empresa?.urlSite,
          `${imovel?.empresa?.urlSite || ''}/imovel/${imovel?.codImovel}`,
        ),
    });

    menuItems.push({
      label: 'Compartilhar link',
      key: 3,
      icon: <ShareAltOutlined />,
      onClick: () =>
        shareLink(
          imovel?.empresa?.urlSite,
          `${imovel?.empresa?.urlSite || ''}/imovel/${imovel?.codImovel}`,
        ),
    });
  }

  const overlay = <Menu items={menuItems} />;

  async function handlePublicarImovel(imovelId, checked) {
    setLoading(true);
    const payload = {
      imovelPublico: checked,
      imovelId,
    };

    await api
      .patch(url.IMOVEL.PUBLICAR_IMOVEL(), payload)
      .then((res) => {
        setLoading(false);
        setCheckImovel(res?.data?.data?.imovelPublico);
        Notify(
          'success',
          pt.comum.aviso,
          `Imóvel ${
            checked ? 'liberado para' : 'retirado da'
          } publicação em outras agências com sucesso!`,
        );
      })
      .catch((err) => {
        setLoading(false);
        Notify(
          'error',
          pt.comum.atencao,
          'Ocorreu um erro ao liberar o imóvel para outras agências!',
        );
      });
  }

  return (
    <>
      <Card className="h-100 justify-content-between">
        <Row className="no-gutters">
          <Col className="p-0">
            <div style={{position: 'relative'}}>
              {hasImagens ? (
                <Carousel
                  className="containerWidth"
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  interval={false}>
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Anterior"
                    onClickHandler={() => previous()}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Próximo"
                    onClickHandler={() => next()}
                  />
                </Carousel>
              ) : (
                <CardImg variant="top" src={imgImovel} />
              )}
              {!isModalConsulta && (
                <div style={{position: 'absolute', top: 8, right: 10}}>
                  <Checkbox
                    onChange={(event) =>
                      handleOnChange(event?.target?.checked, imovel)
                    }
                    checked={checked}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row
          className={isModalConsulta ? 'no-gutters divClick' : 'no-gutters'}
          onClick={
            isModalConsulta ? () => onPreview(imovel, identificador) : null
          }>
          <Col>
            <CardBody className="font-card">
              <div className="p1-2">
                <Row className="d-flex align-items-center justify-content-between">
                  <Col xs="3" md="4">
                    <span className="py-1 mr-2">
                      Código: <b>{imovel.codImovel}</b>
                    </span>
                  </Col>
                  {operacao && (
                    <Col xs="3" md="5">
                      <span className="py-1 font-weight-bold">
                        <Icon name="check circle" color="green" />
                        {operacao.nome.toUpperCase()}
                      </span>
                    </Col>
                  )}
                  {!isModalConsulta &&
                    isPublishImovel &&
                    empresaId === imovel.empresaId && (
                      <Col xs="3" md="1">
                        <Tooltip placement="top" title="Permitir republicação">
                          <Switch
                            loading={loading}
                            checked={
                              checkImovel !== undefined
                                ? checkImovel
                                : imovel?.imovelPublico
                            }
                            unCheckedChildren={<SwapOutlined />}
                            checkedChildren={<SwapOutlined />}
                            onChange={(checked) =>
                              handlePublicarImovel(imovel?.id, checked)
                            }
                          />
                        </Tooltip>
                      </Col>
                    )}
                  {!isModalConsulta && (
                    <Col
                      xs="3"
                      md={empresaId === imovel.empresaId ? '2' : '3'}
                      className="pr-3">
                      <Dropdown.Button
                        placement="topLeft"
                        icon={<MoreOutlined size={24} />}
                        buttonsRender={([leftButton, rightButton]) => [
                          React.cloneElement(leftButton),
                          React.cloneElement(rightButton, {
                            type: 'primary',
                            style: {borderRadius: '50%'},
                          }),
                        ]}
                        style={{justifyContent: 'flex-end'}}
                        overlay={overlay}
                        trigger={['click']}
                      />
                    </Col>
                  )}
                </Row>
                {imovel.tipoImovel?.descricao && (
                  <Row>
                    <Col xs="12">
                      <div className="mt-1 card-dashboard-number ">
                        <h3 className="font-weight-bold ">
                          {imovel.tipoImovel?.descricao}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs="12">
                    <div className="card-dashboard-number ">
                      <h4 className="font-weight-bold">
                        {  (imovel.endereco?.bairroComercial?.nome && imovel.endereco?.condominio?.nome) ?
                              `${imovel.endereco?.bairroComercial?.nome} - ${imovel.endereco?.condominio?.nome}`:
                          imovel.endereco?.bairroComercial?.nome ||
                          imovel.endereco?.condominio?.nome ||
                          imovel?.logradouro?.bairro?.nome}
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-1">
                      <p>
                        <span>
                          {`
                          ${
                            imovel?.logradouro?.bairro?.nome &&
                            imovel?.logradouro?.bairro?.nome + ', '
                          }
                          ${imovel?.logradouro?.bairro?.cidade?.nome} -
                          ${imovel?.logradouro?.bairro?.cidade?.estado?.id}`}
                        </span>
                        {situacao ? (
                          <Fragment>
                            <span> | </span>
                            <span>{situacao.nome.toUpperCase()}</span>
                          </Fragment>
                        ) : null}
                      </p>
                    </div>
                  </Col>
                </Row>
                <MetrosQuadrados imovel={imovel} />
                {imovel?.valorCondominio ? (
                  <Row className="my-1">
                    <Col xs="12">
                      <span className="mr-2">
                        <b>Valor condomínio:</b> R${' '}
                        {formatCurrency(imovel?.valorCondominio)}
                      </span>
                    </Col>
                  </Row>
                ) : null}
                {imovel?.valorIptu ? (
                  <Row className="my-1">
                    <Col xs="12">
                      <span className="mr-2">
                        <b>IPTU:</b> R$ {formatCurrency(imovel?.valorIptu)}
                      </span>
                    </Col>
                  </Row>
                ) : null}
                <ValorNegocio imovel={imovel} />
                <Row className="d-flex w-90">
                  {imovel?.caracteristica?.quantidadeQuartos ? (
                    <Col>
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.quantidadeQuartos}</b>{' '}
                        Quarto(s)
                      </span>
                    </Col>
                  ) : null}
                  {imovel?.caracteristica?.quantidadeBanheiros ? (
                    <Col>
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.quantidadeBanheiros}</b>{' '}
                        Banheiro(s)
                      </span>
                    </Col>
                  ) : null}
                  {imovel?.caracteristica?.quantidadeSuites ? (
                    <Col>
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.quantidadeSuites}</b> Suíte(s)
                      </span>
                    </Col>
                  ) : null}
                  {imovel?.caracteristica?.vagasGaragem ? (
                    <Col>
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.vagasGaragem}</b> Vaga(s)
                      </span>
                    </Col>
                  ) : null}
                </Row>
                {imovel?.empresa?.nome && (
                  <Row>
                    <Col xs="12">
                      <div className="mt-1 card-dashboard-number ">
                        <h5
                          className="font-weight-bold "
                          style={{color: '#818181'}}>
                          {imovel.empresa.nome}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                )}
                <NumeroCorretor imovel={imovel} />
              </div>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </>
  );
}

function CardImovel({
  onPreview,
  onEdit,
  actions,
  data,
  identificador,
  clickEnabled,
  isModalConsulta = false,
  setShowClearSelection,
  realStateForPresentation,
  setRealStateForPresentation,
}) {
  return (
    <Fragment>
      <Row>
        {data.map((imovel, index) => (
          <Col xs="12" md="4" className="mb-3" key={imovel?.id || index}>
            <RowCardImovel
              key={index}
              onPreview={(row, identificador) => onPreview(row, identificador)}
              onEdit={(row, identificador) => onEdit(row, identificador)}
              actions={actions}
              imovel={imovel}
              identificador={identificador}
              clickEnabled={clickEnabled}
              isModalConsulta={isModalConsulta}
              realStateForPresentation={realStateForPresentation}
              setShowClearSelection={setShowClearSelection}
              setRealStateForPresentation={setRealStateForPresentation}
            />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
}

const MetrosQuadrados = ({imovel}) => {
  const areaTerreno = imovel?.caracteristica?.areaTerreno;
  const areaTerrenoUnidadeMedida =
    imovel?.caracteristica?.areaTotalUniMedida?.descricao;
  const areaConstruida = imovel?.caracteristica?.areaConstruida;
  const areaConstruidaUnidadeMedida =
    imovel?.caracteristica?.areaPrivadaUniMedida?.descricao;

  const baseString = (label, value, unidadeMedida) => {
    if (value) {
      return (
        <span className="py-2 mr-2">
          <b>{label}:</b> {value} {unidadeMedida}
        </span>
      );
    }
    return null;
  };

  return (
    <Row className="my-1">
      {areaTerreno && (
        <Col xs="12" md="6">
          {baseString('Área total', areaTerreno, areaTerrenoUnidadeMedida)}
        </Col>
      )}
      {areaConstruida && (
        <Col xs="12" md="6">
          {baseString(
            'Área privada',
            areaConstruida,
            areaConstruidaUnidadeMedida,
          )}
        </Col>
      )}
    </Row>
  );
};

const ValorNegocio = ({imovel}) => {
  return (
    <Row>
      {imovel?.valorVenda ? (
        <Col>
          <span>
            <b>Valor Venda</b>
          </span>
          <h4 className="font-weight-bold mt-0">
            R$ {formatCurrency(imovel?.valorVenda)}
          </h4>
        </Col>
      ) : null}
      {imovel?.valorAluguel ? (
        <Col>
          <span>
            <b>Valor Aluguel</b>
          </span>
          <h4 className="font-weight-bold mt-0">
            R$ {formatCurrency(imovel?.valorAluguel)}
          </h4>
        </Col>
      ) : null}
    </Row>
  );
};

const NumeroCorretor = ({imovel}) => {
  const brokerPhoneNumber = imovel?.corretorCaptacao?.telefones[0]?.numero;

  if (!brokerPhoneNumber) return null;

  const broker = imovel?.corretorCaptacao?.nome;
  const nomeBroker = broker.replace('Corretor -', '');
  return (
    <Row>
      <Col xs="12">
        <div
          style={{alignItems: 'center'}}
          className="card-dashboard-number row px-3">
          <b className="font-weight-bold" style={{color: '#818181'}}>
            Captador:
          </b>
          <p
            className="ml-2 font-weight-light"
            onClick={() => handleWhats(brokerPhoneNumber)}
            style={{color: '#818181', cursor: 'pointer'}}>
            {` ${nomeBroker} - ${getMaskedNumberBR(brokerPhoneNumber)}`}
          </p>
        </div>
      </Col>
    </Row>
  );
};

const handleWhats = (numero) => {
  const formatedNumber = formatNumber(numero);
  try {
    window.open(`https://wa.me/${formatedNumber}`);
  } catch (err) {
    message.error('Ocorreu um erro ao compartilhar o link do imóvel');
  }
};
export default CardImovel;
