import React from 'react';

import {
  HighlightContainer,
  Image,
  ImageContainer,
  RemoveImageIcon,
} from './styles';

import {DeleteOutlined} from '@ant-design/icons';

export default function SimpleImageCard({
  src,
  title,
  id,
  isHighlight,
  onDeleteImage,
}) {
  const renderIfIsHighlight = isHighlight && (
    <HighlightContainer>
      <p>Foto de capa</p>
    </HighlightContainer>
  );

  return (
    <ImageContainer>
      <RemoveImageIcon
        className="remove-button"
        onClick={() => onDeleteImage(id)}>
        <DeleteOutlined size={24} />
      </RemoveImageIcon>
      <Image src={src} alt={title} width={200} height={'100%'} />
      {renderIfIsHighlight}
    </ImageContainer>
  );
}

