import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

export default function useEmpresaIntegracao(){
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const getEmpresas = async () => {
    const res = await api.get(url.EMPRESAS.ALL());

    if (res?.data) {
      setEmpresas(res?.data.map(x => ({
        id: x.id,
        nome: x.nome
      })));
    }
  }
  const onLoad = useCallback(async () => {
    try {
      setIsLoading(true);

      await api
        .get(url.IMPORTACAO_IMOVEIS.CONSULTA())
        .then((res) => {
          if (res?.data){
            const data_formatted = res?.data.map(x => (
              {
                id: x.id,
                codigo_empresa: x.empresaId,
                descricao: x.empresa.nome,
                url: x.url,
                importarFotos: x.importarFotos,
                fullSync: x.fullSync
              }
            ));
            setData(data_formatted);
            setQuantidade(data_formatted?.length || 0);
          }
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const save = useCallback(async (request) => {
    try {
      setIsLoading(true);

      if (data.some(x => x.codigo_empresa === request.empresaId && x.url === request.url)){
        return Notify(
          'warning',
          pt.comum.aviso,
          'Já existe uma integração cadastrada para essa empresa.',
        );
      }

      await api.post(url.IMPORTACAO_IMOVEIS.CONSULTA(), request);

      Notify(
        'success',
        pt.geral.cadastro_com_sucesso,
        'OK',
      );
    }
    catch {
      Notify(
        'error',
        pt.comum.atencao,
        'Erro ao tentar salvar.',
      );
    }
    finally {
      onLoad();
    }
  }, [data, onLoad])

  const remove = useCallback(async (id) => {
    try {
      if (!id){
        return;
      }

      await api.delete(url.IMPORTACAO_IMOVEIS.DELETAR(id));

      Notify(
        'success',
        pt.geral.deletado_com_sucesso,
        'OK',
      );
    }
    catch {
      Notify(
        'error',
        pt.comum.atencao,
        'Erro ao tentar deletar o registro.',
      );
    }
    finally {
      onLoad();
    }
  }, [onLoad])

  const onFiltros = useCallback(async (term) => {
    const res = await api.get(url.IMPORTACAO_IMOVEIS.CONSULTA(term));

    if (res?.data){
      const data_formatted = res?.data.map(x => (
        {
          id: x.id,
          codigo_empresa: x.empresaId,
          descricao: x.empresa.nome,
          url: x.url,
          importarFotos: x.importarFotos,
          fullSync: x.fullSync
        }
      ));
      setData(data_formatted);
      setQuantidade(data_formatted?.length || 0);
    }

  }, [])

  useEffect(() => {
    getEmpresas();
    onLoad();
  }, [onLoad]);

  return {
    data,
    empresas,
    quantidade,
    isLoading,
    onLoad,
    onFiltros,
    save,
    remove,
    setData
  };
}
