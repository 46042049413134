
export default function chunckArray(perChunck, array) {
  return array.reduce((resultArray, picture, index) => {
    const chunckIndex = Math.floor(index / perChunck);
    if (!resultArray[chunckIndex]) {
      resultArray[chunckIndex] = [];
    }

    resultArray[chunckIndex].push(picture);

    return resultArray;
  }, []);
};
