import {formatNumber} from 'nmbr';

const options = {
  fractionSize: 2,
  minFractionSize: 2,
  thousandDelimiter: '.',
  fractionDelimiter: ',',
};

function formatCurrency(
  value,
  places = 2,
  removeZero = false
) {
  let currency = formatNumber(value, {
    ...options,
    fractionSize: places,
    minFractionSize: places,
  });

  if (removeZero) {
    currency = currency.replace(/(?:,?0+)$/g, '');
  }

  return currency;
}

export default formatCurrency;
