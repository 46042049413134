import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 8px;
`;

export function Grid({children}) {
  return <GridContainer>{children}</GridContainer>;
}

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

