import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import getTiposMidia from '~/infra/resources/strings/enums/getTiposMidia';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const today = moment();
const data = moment().format('DD/MM/YYYY');
const hora = moment(today).format('HH:mm:ss');

const styles = StyleSheet.create({
  Page: {
    backgroundColor: '#FFF',
    paddingBottom: '50px'
  },
  Container: {
    padding: 15,
    width: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Section: {
    flexGrow: 1,
    margin: '5px 0',
  },
  SectionHeader: {
    paddingBottom: 5,
    marginBottom: 5
  },
  DividerSection: {
    borderBottom: '1px dashed #808080',
    width: '100%',
    marginBottom: '2px'
  },
  Title: {
    fontSize: 16,
    fontWeight: 600,
    margin: '10px 0',
    textAlign: 'left',
  },
  SubtitleFirst: {
    fontSize: 14,
    textAlign: 'center',
    margin: '12px 0',
    paddingBottom: 8,
    textDecoration: 'underline'
  },
  Span: {
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: '1.1',
    color: '#333333',
    paddingRight: '10px'
  },
  Label: {
    fontSize: '10px',
    margin: '2px 0',
    color: '#000',
    fontWeight: 800,
    paddingRight: '10px'
  },
  pageDate: {
    fontSize: 12,
    position: 'absolute',
    top: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
  },
  pageNumber: {
    fontSize: 12,
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  registerCount: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: '10px',
    textAlign: 'left',
  }
});

const getStatusNegocio = (idStatus) => {
  switch (idStatus) {
    case 1:
      return 'Negócio Fechado';
    case 2:
      return 'Em Atendimento';
    case 3:
      return 'Desistência';
    case 4:
      return 'Lead na Base'
    case 5:
      return 'Em Análise Financeira'
    case 6:
      return 'Análise Financeira Aprovada'
    case 7:
      return 'Análise Financeira Reprovada'
    case 8:
      return 'Em Tentativa'
    default:
      return '';
  }
}

const ModeloAtendimentoPadrao = ({ dados, nomeEmpresa }) => {
  return (
    <Document>
      <Page size="A4" style={styles.Page}>
        <Text style={[styles.pageDate, styles.Label]}>
          {data} - {hora}
        </Text>
        <View style={styles.Container}>
          <View style={styles.SectionHeader}>
            <Text style={styles.Title}>
              {(nomeEmpresa || 'Locare Gestão Imobiliária').toUpperCase()}
            </Text>
          </View>

          <Text style={styles.SubtitleFirst}>
            Controle de Atendimentos
          </Text>

          <View style={[styles.Row, styles.DividerSection]}>
            <Text style={[styles.Span,
            {
              minWidth: '200px',
              maxWidth: '200px'
            }]}>Nome</Text>
            <Text style={[styles.Span,
            {
              minWidth: '200px',
              maxWidth: '200px'
            }]}>Midia</Text>
            <Text style={[styles.Span,
            {
              minWidth: '200px',
              maxWidth: '200px'
            }]}>Status de Negócio</Text>
          </View>

          {dados.map((atd) => {
            return (
              <View style={styles.Row} key={atd.id}>
                <Text style={[styles.Label,
                {
                  minWidth: '200px',
                  maxWidth: '200px'
                }]}>{atd?.cliente?.nome || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '200px',
                  maxWidth: '200px'
                }]}>{getTiposMidia(atd?.tipoMidia) || '---'}</Text>
                <Text style={[styles.Label,
                {
                  minWidth: '200px',
                  maxWidth: '200px'
                }]}>{getStatusNegocio(atd?.status) || '---'}</Text>
              </View>
            );
          })}

        </View>
        <Text style={[styles.registerCount, styles.Label]} render={({ pageNumber, totalPages }) => (
          pageNumber === totalPages ? `Qde de Registros: ${dados?.length || 0}` : ''
        )} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default ModeloAtendimentoPadrao;
