import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { Form, Select, Table, Badge } from 'antd';
import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';

import useMenu from '~/actions/Menu/useMenu';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import api from '~/services/api';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import Button from '~/shared/Button';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

const { Option } = Select;

const TABS = {
  PERFIL: 1,
  MENUS: 2,
  ACOES: 3,
};

const PerfilMenus = ({ setActiveTab, perfilId, identificador }) => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [menusPerfil, setMenusPerfil] = useState();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [menuIdentificadorId, setMenuIdentificadorId] = useState();

  const { isLoading, data: menus } = useMenu();

  const [form] = Form.useForm();

  async function getMenusLiberados(perfilId) {
    await api.get(url.MENU.PERFIL(perfilId)).then((res) => {
      setMenusPerfil(res.data || []);
      setPageLoad(false);
      setLoadTable(false);
    });
  }

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);

    getMenusLiberados(perfilId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveTab]);

  function voltar() {
    setActiveTab(TABS.PERFIL);
  }

  async function onSave() {
    setLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          menuIdentifiers: [Number(dados.menuIdentificadorId)],
          perfilId,
        };

        await api
          .post(url.MENU_PERFIL.CADASTRAR(), payload)
          .then((res) => {
            setLoading(false);
            setDisabledButton(true);

            Notify('success', '', pt.geral.cadastro_com_sucesso);

            form.setFieldsValue({ menusId: undefined });

            getMenusLiberados(perfilId);
          })
          .catch((err) => {
            setLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        setLoading(false);
      });
  }

  function onChangeMenu() {
    setDisabledButton(false);
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function handleModalConfirm(menuIdentificadorId) {
    setOpenModalConfirm(true);
    setMenuIdentificadorId(menuIdentificadorId);
  }

  async function handleConfirmDelete() {
    if (menuIdentificadorId && perfilId) {
      setLoadTable(true);

      const payload = {
        menuIdentifiers: [menuIdentificadorId],
        perfilId,
      };

      await api
        .delete(url.MENU_PERFIL.DELETAR(), { data: payload })
        .then((response) => {
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
          getMenusLiberados(perfilId);
        })
        .catch((error) => {
          setLoadTable(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Submenu',
      dataIndex: 'menuPaiId',
      key: 'menuPaiId',
      render: (row) => (
        <Badge>
          <Icon name={row !== null ? 'check circle' : 'warning circle'} color={row !== null ? 'green' : 'red'} />
          {row !== null ? 'Sim' : 'Não'}
        </Badge>
      ),
    },
  ];

  if (excluir) {
    columns.push({
      title: 'Desvincular',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.identificador)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  const columnsExpanded = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Submenu',
      dataIndex: 'menuPaiId',
      key: 'menuPaiId',
      render: (row) => (
        <Badge>
          <Icon name={row !== null ? 'check circle' : 'warning circle'} color={row !== null ? 'green' : 'red'} />
          {row !== null ? 'Sim' : 'Não'}
        </Badge>
      ),
    },
  ];

  const expandedRowRender = (filhos) => {
    return (
      <div className="p-3">
        <Table
          columns={columnsExpanded}
          dataSource={filhos}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      <Page loadPage={pageLoad}>
        <Card className="card-custom card-shadowless rounded-top-0 mb-0 p-4">
          <CardHeader className="pl-2">
            <h5 className="title">Dados básicos</h5>
            {/* <p className="category">Manutenção de Perfil</p> */}
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs="12">
                <div className="my-3 p-2">
                  {isLoading && <SkeletonTableBasic cardShadowless />}
                  {!isLoading && (
                    <>
                      <Form
                        form={form}
                        scrollToFirstError={true}>
                        <Row>
                          <Col xs="12">
                            <p className="heading-small text-muted mb-2">
                              <small>Menus para liberação</small>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="5">
                            <Form.Item
                              className="my-2"
                              name="menuIdentificadorId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o Menu',
                                },
                              ]}>
                              <Select
                                placeholder=" Menu"
                                onChange={onChangeMenu}>
                                {menus?.map((item) => (
                                  !item.excluido &&
                                  <Option key={item.identificador}>
                                    {item.descricao}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="3">
                            <Button
                              onClick={() => onSave()}
                              icon
                              labelPosition="left"
                              size="tiny"
                              fluid
                              className="mt-2"
                              color={disabledButton ? '' : 'violet'}
                              disabled={disabledButton}
                              isloading={loading.toString()}
                              loadtext={'Cadastrando...'}>
                              <Icon name="check" />
                              Liberar menu
                            </Button>
                          </Col>
                        </Row>
                      </Form>

                      <div className="mt-4 my-2">
                        <Table
                          rowKey={(row) => row.id}
                          columns={columns}
                          dataSource={menusPerfil}
                          loading={loadTable}
                          expandable={{
                            expandedRowRender: (record) =>
                              expandedRowRender(record.filhos),
                            rowExpandable: (record) => record.filhos.length > 0,
                          }}
                        />
                      </div>

                      <Row>
                        <Col xs="12" md="3">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-2"
                            onClick={() => voltar()}>
                            <Icon name="undo" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>

      <ModalConfirm
        isDelete
        icon="warning sign"
        loading={loading}
        open={openModalConfirm}
        confirm={handleConfirmDelete}
        close={handleCloseModal}
        message="Deseja realmente desvincular esse menu?"
        loadingText="Desvinculando..."
      />
    </>
  );
};

export default PerfilMenus;