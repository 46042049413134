import React, {Fragment, useState, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Form, Input, Select, Switch} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Icon} from 'semantic-ui-react';

import Button from '~/shared/Button';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Page from '~/components/Page';
import EmptyBox from '~/components/EmptyBox';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalRelatorioCorretor from './components/ModalRelatorioCorretor';
import CorretoresAgrupados from './components/CorretoresAgrupados';
import history from '~/services/history';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import InfiniteScroll from 'react-infinite-scroller';

import useUsuario from '~/actions/Corretor/useUsuario';
import useEmpresa from '~/actions/Empresa/useEmpresa';
import usePerfil from '~/actions/Perfil/usePerfil';
import useTipoUsuario from '~/actions/TipoUsuario/useTipoUsuario';
import ModalAtivarUsuario from './components/ModalAtivarUsuario';
import ModalInativarUsuario from './components/ModalInativarUsuario';

const {Option} = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/corretor/manutencao/${MODO.CADASTRAR}`,
    state: {identificador},
  });
}

function ConsultaMaster() {
  const [actions, setActions] = useState([]);
  const [loadCards, setloadCards] = useState(true);
  const [filtro, setFiltro] = useState('');
  const [perfil, setPerfil] = useState('');
  const [tipoDeUsuario, setTipoDeUsuario] = useState('');
  const [filiaisId, setFiliaisId] = useState([]);
  const [modalRelatorioOpen, setModalRelatorioOpen] = useState(false);
  const [modalAtivarUsuarioOpen, setModalAtivarUsuarioOpen] = useState(false);
  const [modalInativarUsuarioOpen, setModalInativarUsuarioOpen] =
    useState(false);
  const [userId, setUserId] = useState();
  const [remapEmpresas, setRemapEmpresas] = useState([]);
  const [exibirInativos, setExibirInativos] = useState(false);
  const [agruparPor] = useState('TipoDeUsuario');
  const {data: dataEmpresas} = useEmpresa(1, 9999, null, null);
  const pageSize = 50;

  const {isLoading, data, onLoad, onFilter, hasMore} = useUsuario(
    filtro,
    filiaisId,
    tipoDeUsuario,
    perfil,
    pageSize,
  );

  const {identificador} = useParams();

  const [form] = Form.useForm();

  const empresaId = localStorage.getItem('icaseEmpresaId');
  const latitudeCompanyData = JSON.parse(
    localStorage.getItem('latitudeCompanyData'),
  );
  const isMatriz = !!!latitudeCompanyData.matrizId;

  const {data: tipoUsuarios} = useTipoUsuario();
  const {data: perfis} = usePerfil();

  function onEndReached() {
    if (isLoading) return;
    onLoad();
  }

  useEffect(() => {
    setloadCards(false);

    const {actions} = getActionsMenus(identificador);

    setActions(actions || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, identificador]);

  useEffect(() => {
    if (isMatriz) {
      const remap = [];
      const index = dataEmpresas.findIndex((item) => item.id === empresaId);

      if (index > -1) {
        if (dataEmpresas[index]?.filiais !== null) {
          remap.push({
            id: dataEmpresas[index].id,
            nome: dataEmpresas[index].nome,
          });

          dataEmpresas[index].filiais.forEach((child) => {
            remap.push({
              id: child.id,
              nome: child.nome,
            });
          });

          setRemapEmpresas(remap);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmpresas]);

  function handleOpenModalInativarUsuario(corretor) {
    setUserId(corretor.usuarioId);
    setModalInativarUsuarioOpen(true);
  }

  function handleCloseModalInativarUsuario() {
    setModalInativarUsuarioOpen(false);
    setUserId();
  }

  function handleOpenModalAtivarUsuario(corretor) {
    setUserId(corretor.usuarioId);
    setModalAtivarUsuarioOpen(true);
  }
  function handleCloseModalAtivarUsuario() {
    setModalAtivarUsuarioOpen(false);
    setUserId();
  }
  function handleOpenModal(corretor) {
    corretor.excluido === true
      ? handleOpenModalAtivarUsuario(corretor)
      : handleOpenModalInativarUsuario(corretor);
  }
  function onFiltros() {
    setloadCards(true);

    const {filtro, empresaId, exibirInativos, tipoDeUsuario, perfil} =
      form.getFieldsValue();
    setFiltro(filtro);
    setPerfil(perfil);
    setTipoDeUsuario(tipoDeUsuario);
    setFiliaisId(empresaId);
    setExibirInativos(exibirInativos);

    onFilter({
      filtro,
      empresaId,
      exibirInativos,
      tipoDeUsuario,
      perfil,
      pageSize,
    });

    setloadCards(false);
  }

  async function resetFilter() {
    form.resetFields();
    setFiltro('');
    setPerfil('');
    setTipoDeUsuario('');
    setFiliaisId([]);
    setExibirInativos(false);

    await onFilter({
      filtro: '',
      empresaId: [],
      exibirInativos: false,
      tipoDeUsuario: '',
      perfil: '',
      pageSize,
    });
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const MODO_AGRUPAR_POR = {
    TipoDeUsuario: (usuario) => usuario.roles[0]?.role,
    Perfil: (usuario) => usuario.perfil,
  };

  const gruposDeCorretores = useMemo(
    () => groupBy(data, MODO_AGRUPAR_POR[agruparPor]),
    // eslint-disable-next-line
    [data, agruparPor],
  );
  const isEmpty = !isLoading && !loadCards && data.length === 0;
  const hierarchySort = [
    'SysAdmin',
    'Master',
    'AdmMatriz',
    'Diretor',
    'SDR',
    'Gerente',
    'Corretor',
  ];

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de Usuário</h5>
                <p className="category">Manutenção Usuário</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form form={form} scrollToFirstError>
                        <Row>
                          {isMatriz && (
                            <Col xs="12" md="2">
                              <p className="heading-small text-muted mb-2">
                                <small>Empresa</small>
                              </p>
                              <Form.Item className="my-2" name="empresaId">
                                <Select
                                  placeholder=" Empresa"
                                  mode="multiple"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                      .indexOf(
                                        input
                                          .toLowerCase()
                                          .normalize('NFD')
                                          .replace(/[\u0300-\u036f]/g, ''),
                                      ) >= 0
                                  }
                                  allowClear>
                                  {remapEmpresas.map((item) => (
                                    <Option key={item.id}>{item.nome}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Filtro</small>
                            </p>
                            <Form.Item className="my-2" name="filtro">
                              <Input placeholder="Nome do usuário, email, CPF/CNPJ, CRECI ou telefone" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>Tipo de Usuário</small>
                            </p>
                            <Form.Item className="my-2" name="tipoDeUsuario">
                              <Select
                                placeholder="Tipo de Usuário"
                                showSearch
                                optionFilterProp="name"
                                filterOption={(input, option) =>
                                  option.name
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .indexOf(
                                      input
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, ''),
                                    ) >= 0
                                }>
                                {tipoUsuarios.map((item) => (
                                  <Option
                                    key={item.id}
                                    disabled={item?.disabled}
                                    value={item.name}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small> Perfil</small>
                            </p>
                            <Form.Item className="my-2" name="perfil">
                              <Select
                                placeholder="Perfil"
                                showSearch
                                optionFilterProp="descricao"
                                filterOption={(input, option) =>
                                  option.descricao
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .indexOf(
                                      input
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, ''),
                                    ) >= 0
                                }>
                                {perfis.map((item) => (
                                  <Option
                                    key={item.id}
                                    disabled={item?.disabled}
                                    value={item.descricao}>
                                    {item?.descricao}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              color="green"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              size="tiny"
                              icon
                              fluid
                              onClick={() => resetFilter()}>
                              LIMPAR FILTRO
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="1">
                            <p className="heading-small text-muted mb-0">
                              <small>Exibir Inativos ?</small>
                            </p>
                            <Form.Item
                              valuePropName="checked"
                              name="exibirInativos">
                              <Switch
                                loading={loadCards}
                                checked={exibirInativos}
                                checkedChildren="Sim"
                                unCheckedChildren="Não"
                                size="small"
                                onChange={(value) => setExibirInativos(value)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mt-2">
              <CardHeader>
                <Row>
                  {/* <Col xs="12" md="3">
                    <h5 className="title mt-4">{`Resultados: (${
                      quantidade ?? 0
                    })`}</h5>
                  </Col>
                  <Col xs="12" md="3">
                    <p className="heading-small text-muted mb-2">
                      <small>Agrupar por</small>
                    </p>
                    <Form.Item className="my-2" name="agruparPor">
                      <Select
                        size="large"
                        loading={loadCards}
                        defaultValue={'TipoDeUsuario'}
                        onChange={(value) => setAgruparPor(value)}>
                        <Option value="TipoDeUsuario">Tipo de usuário</Option>
                        <Option value="Perfil">Perfil</Option>
                      </Select>
                    </Form.Item>
                  </Col>  */}
                </Row>
                <Row>
                  <Col xs="12" md="2">
                    {incluir === true ? (
                      <HeaderFiltros
                        classMd="12" //dentro do header filtros está pegando apenas 3 dos 12 disponiveis por isso o 12
                        navToNovo={() => navToNovo(identificador)}
                        description="Novo Usuário"
                      />
                    ) : null}
                  </Col>
                  <Col xs="12" md="2">
                    <div className="pt-2">
                      <Button
                        className="my-1"
                        size="small"
                        icon
                        labelPosition="left"
                        fluid
                        onClick={() => setModalRelatorioOpen(true)}>
                        <Icon name="file outline" />
                        Relatório
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {data.length > 0 && (
                  <Fragment>
                    <InfiniteScroll
                      pageStart={1}
                      threshold={250}
                      loadMore={onEndReached}
                      hasMore={hasMore}
                      loading={
                        <div>
                          <span>Carregando...</span>
                        </div>
                      }>
                      {Array.from(gruposDeCorretores.keys())
                        .sort(
                          (a, b) =>
                            hierarchySort.indexOf(a) - hierarchySort.indexOf(b),
                        )
                        .map((key) => {
                          return (
                            <CorretoresAgrupados
                              key={key.toString()}
                              nomeAgrupamento={key || 'Sem agrupamento'}
                              corretores={gruposDeCorretores.get(key)}
                              exibirInativos={exibirInativos}
                              agruparPor={agruparPor}
                              isLoading={isLoading}
                              handleOpenModal={(corretor) =>
                                handleOpenModal(corretor)
                              }
                            />
                          );
                        })}
                    </InfiniteScroll>
                  </Fragment>
                )}

                {isLoading ? (
                  <Row className="mt-4">
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                    <Col xs="12" md="3">
                      <SkeletonTableBasic cardShadowless lineNumber={1} />
                    </Col>
                  </Row>
                ) : null}

                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalAtivarUsuario
          open={modalAtivarUsuarioOpen}
          close={() => handleCloseModalAtivarUsuario()}
          usuarioId={userId}
          onUpdate={() => {
            const index = data.findIndex((u) => u.usuarioId === userId);
            data[index].excluido = false;
            setloadCards(false);
          }}
        />
        <ModalInativarUsuario
          open={modalInativarUsuarioOpen}
          close={() => handleCloseModalInativarUsuario()}
          usuarioId={userId}
          onUpdate={() => {
            const index = data.findIndex((u) => u.usuarioId === userId);
            data[index].excluido = true;
            setloadCards(true);
          }}
        />
        <ModalRelatorioCorretor
          open={modalRelatorioOpen}
          close={() => setModalRelatorioOpen(false)}
          empresaId={empresaId}
          filterParams={{filtro, perfil, tipoDeUsuario}}
        />
      </Page>
    </Fragment>
  );
}

export default ConsultaMaster;
