/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { Form, Input } from 'antd';
import { Modal, Dimmer, Loader } from 'semantic-ui-react';

import history from '~/services/history';
import Notify from '~/shared/Notify';
import Button from '~/shared/Button';
import CardFooter from './components/CardFooter';
import ModalEmpresas from '~/components/ModalEmpresas';
import checkAlfanumerico from '~/shared/checkAlfanumerico';

import { encrypt, encryptAll } from '~/infra/resources/pattern/cripto';
import Url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import backgroundLogin from '~/assets/img/fundo_login_latitude.png';
import jwtDecode from 'jwt-decode';

function Login(props) {
  const [loading, setIsLoading] = useState(false);
  const [loadingEmpresa, setIsLoadingEmpresa] = useState(false);
  const [email, setEmail] = useState('');
  const [senhaAtual, setSenhaAtual] = useState('');
  const [formValues, setFormValues] = useState();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalEmpresas, setOpenModalEmpresas] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState();
  const [listEmpresas, setListEmpresas] = useState([]);
  const [redefinePassword, setRedefinePassword] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [loadingRedefinedPass, setLoadingRedefinedPass] = useState(false);

  const [form] = Form.useForm();
  const [formLogin] = Form.useForm();

  useEffect(() => {
    async function redefinePassword() {
      if (props?.location?.state?.redefinePassword) {
        setLoadingRedefinedPass(true);
        await api
          .get(Url.PESSOA.CONSULTA_ID(props?.location?.state?.userId))
          .then((res) => {
            setRedefinePassword(true);
            setEmail(res.data.email);
            setInitialValues({
              senhaAtual: props?.location?.state?.key,
            });
            setLoadingRedefinedPass(false);
            setOpenModalConfirm(true);
          })
          .catch((err) => {
            setLoadingRedefinedPass(false);
            if (err?.response?.data) {
              const { errors } = err?.response?.data;
              if (errors) {
                for (const item in errors) {
                  if (item.length > 0) {
                    errors[item].forEach((valor) => {
                      Notify('error', item, valor);
                    });
                  } else {
                    errors[item].forEach((valor) => {
                      Notify('error', item, valor);
                    });
                  }
                }
              }
            }
          });
      }
    }

    redefinePassword();
  }, [props]);

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function handleCloseModalEmpresas() {
    setOpenModalEmpresas(false);
  }

  async function AlterarSenha(values) {
    setIsLoading(true);

    const dados = {
      email,
      senhaAtual: redefinePassword ? values.senhaAtual : senhaAtual,
      senha: values.senhaUpdate,
      senhaConfirmacao: values.senhaConfirmacaoUpdate,
    };

    await api
      .put(Url.LOGIN.ALTERAR_SENHA(), dados)
      .then((res) => {
        setIsLoading(false);
        setOpenModalConfirm(false);
        setRedefinePassword(false);

        formLogin.setFieldsValue({ email, senha: values.senhaUpdate });
        logar({ email, senha: values.senhaUpdate });
      })
      .catch((err) => {
        setIsLoading(false);
        const { errors } = err.response.data;

        if (errors) {
          for (const item in errors) {
            if (item.length > 0) {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            } else {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            }
          }
        }
      });
  }

  function onChangeEmpresa(empresaId) {
    setSelectedEmpresaId(empresaId);
  }

  async function redirectLogin(user) {
    const {
      jwt,
      id: usuarioId,
      empresaId,
      logoEmpresa,
      roles,
      menusGranted,
    } = user.data.data;

    const { token, refreshToken } = jwt;

    const {unique_name: userCpf, exp} = jwtDecode(token);
    localStorage.setItem('icaseToken', token);
    localStorage.setItem('icasetokenExpiration', exp);
    localStorage.setItem('icaseRefreshToken', JSON.stringify(refreshToken));
    localStorage.setItem('icaseUserCpf', userCpf);
    localStorage.setItem('icaseEmpresaId', empresaId);
    localStorage.setItem('icaseUsuarioId', usuarioId);
    localStorage.setItem('icaseUser', JSON.stringify(user.data.data));


    await api
      .get(Url.USUARIO_PERFIL.CONSULTA_BY_USER(usuarioId))
      .then((res) => {
        const perfis = res.data;
        if (perfis) {
          const encryptedData = encryptAll(JSON.stringify(perfis));
          localStorage.setItem('latitudeProfiles', encryptedData);
        }
      });

    if (logoEmpresa) localStorage.setItem('icaseLogoEmpresa', logoEmpresa);
    else localStorage.removeItem('icaseLogoEmpresa');

    if (empresaId || selectedEmpresaId) {
      const searchEmpresaId = selectedEmpresaId ? selectedEmpresaId : empresaId;

      await api
        .get(Url.EMPRESAS.CONSULTA_BY_ID(searchEmpresaId))
        .then((res) => {
          localStorage.setItem('latitudeCompanyData', JSON.stringify(res.data));
        });
    }

    const master_leads = roles.find((role) => role === 'MASTER LEADS');

    if (master_leads) {
      localStorage.setItem('icaseMasterLeads', true);
    } else {
      localStorage.setItem('icaseMasterLeads', false);
    }

    localStorage.setItem('icaseRoles', JSON.stringify(roles));

    const { menus } = menusGranted ? menusGranted : [];

    const encryptedMenus = encrypt(JSON.stringify(menus || []));

    localStorage.setItem('icaseMenus', encryptedMenus);
    localStorage.setItem('icaseEmpresas', JSON.stringify(listEmpresas || []));

    setIsLoading(false);
    history.push('/app');
  }

  async function handleLoginEmpresa(payloadEmpresa) {
    setIsLoadingEmpresa(true);
    await api
      .post(Url.LOGIN.WEB_POR_EMPRESA(), payloadEmpresa)
      .then((user) => {
        if (user.data) {
          localStorage.setItem('icaseUserEmail', email);
          redirectLogin(user);
        } else {
          Notify(
            'error',
            pt.comum.atencao,
            'Não foi encontrado usuário com os dados informados!',
          );

          setIsLoading(false);
          setIsLoadingEmpresa(false);
          history.push('/');
        }
      })
      .catch((err) => {
        const { errors } = err.response.data;
        if (errors) {
          for (const item in errors) {
            if (item === 'TemporaryPassword') {
              setEmail(values.email);
              setSenhaAtual(values.senha);
              setOpenModalConfirm(true);
            } else {
              Notify('error', item ? item : pt.comum.atencao, errors[item]);
            }
          }
        }

        setIsLoading(false);
        setIsLoadingEmpresa(false);
        history.push('/');
      });
  }

  async function handleLogin(payload, mail) {
    await api
      .post(Url.LOGIN.WEB(), payload)
      .then(async (user) => {
        redirectLogin(user);
        localStorage.setItem('icaseUserEmail', email || mail);
      })
      .catch((err) => {
        const { errors } = err.response.data;
        if (errors) {
          for (const item in errors) {
            if (item === 'TemporaryPassword') {
              setEmail(values.email);
              setSenhaAtual(values.senha);
              setOpenModalConfirm(true);
            } else {
              Notify('error', item ? item : pt.comum.atencao, errors[item]);
            }
          }
        }

        setIsLoading(false);
        history.push('/');
      });
  }

  async function logar(values) {
    setIsLoading(true);

    const payloadLogar = {
      email: values.email,
      senha: values.senha,
      empresaId: null,
    };

    await api
      .post(Url.LOGIN.VER_EMPRESAS(), payloadLogar)
      .then((res) => {
        setFormValues(values);
        setEmail(values.email);

        const hasResp = res?.data?.data;
        const auxEmpresaId = hasResp?.empresas[0]?.empresaId || null;
        setSelectedEmpresaId(auxEmpresaId);

        if (hasResp?.empresas?.length > 0 && auxEmpresaId) {
          const payloadEmpresa = {
            email: values.email,
            senha: values.senha,
            empresaId: auxEmpresaId,
          };

          if (hasResp?.empresas?.length > 1) {
            setListEmpresas(
              res.data?.data?.empresas || [res.data?.data[0]?.empresa],
            );
            setOpenModalEmpresas(true);
            setIsLoading(false);
          } else if (hasResp?.empresas?.length === 1) {
            handleLoginEmpresa(payloadEmpresa);
          } else {
            handleLogin(payloadLogar, values.email);
          }
        } else {
          handleLogin(payloadLogar, values.email);
        }
      })
      .catch((err) => {
        if (err?.response?.data) {
          const { errors } = err?.response?.data;
          if (errors) {
            for (const item in errors) {
              if (item === 'TemporaryPassword') {
                setEmail(values.email);
                setSenhaAtual(values.senha);
                setOpenModalConfirm(true);
              } else {
                Notify('error', item ? item : pt.comum.atencao, errors[item]);
              }
            }
          }
        }

        setIsLoading(false);
        history.push('/');
      });
  }

  return (
    <Fragment>
      {loadingRedefinedPass ? (
        <Dimmer active>
          <Loader size="massive">Validando...</Loader>
        </Dimmer>
      ) : (
        <div id="latitude-login" style={{ backgroundImage: `url(${backgroundLogin})` }}>
          <Row>
            <Col>
              <Card className="latitude-login-card">
                <CardBody className="px-3 py-4">
                  <Row className="mx-1 pb-2">
                    <Col xs="12">
                      <div id="latitude-login-header">
                        LATITUDE
                      </div>
                    </Col>
                  </Row>
                  <Form
                    form={formLogin}
                    initialValues={{ remember: true }}
                    onFinish={logar}
                    scrollToFirstError
                  >
                    <Row className="mx-1">
                      <Col xs="12">
                        <p className="latitude-login-label">Usuário</p>
                        <Form.Item
                          className="mb-3"
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: 'Favor inserir um E-mail válido',
                            },
                          ]}>
                          <Input placeholder=" Usuário" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mx-1">
                      <Col xs="12">
                        <p className="latitude-login-label">Senha</p>
                        <Form.Item
                          autoComplete="off"
                          className="my-3"
                          name="senha"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar a sua senha!',
                            },
                          ]}>
                          <Input.Password
                            autoComplete="off"
                            placeholder=" Senha"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="mx-1">
                      <Col xs="12">
                        <Form.Item>
                          <div className="mt-4 text-center">
                            <Button
                              fluid
                              className="my-1"
                              color="violet"
                              isloading={loading.toString()}
                              loadtext="Validando...">
                              ENTRAR
                            </Button>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Row className="mx-1">
                    <Col xs="12">
                      <CardFooter />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      <ModalEmpresas
        open={openModalEmpresas}
        close={handleCloseModalEmpresas}
        selectedEvent={onChangeEmpresa}
        confirmEvent={(v) => handleLoginEmpresa(v)}
        listEmpresas={listEmpresas}
        loadingEmpresa={loadingEmpresa}
        formValues={formValues}
        empresaId={selectedEmpresaId}
        textTitle={'Selecione uma empresa para logar no sistema!'}
      />

      <Modal
        size="tiny"
        open={openModalConfirm}
        onClose={() => handleCloseModal()}>
        <Modal.Header>
          <div className="my-1">
            <p>Alteração de senha temporária</p>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form
            form={form}
            scrollToFirstError
            onFinish={AlterarSenha}
            initialValues={initialValues}>
            <Row>
              {redefinePassword && (
                <Col xs="12">
                  <p className="heading-small text-muted mb-2">
                    <small>Senha Atual</small>
                  </p>
                  <Form.Item
                    autoComplete="off"
                    className="my-2"
                    name="senhaAtual"
                    rules={[
                      {
                        required: true,
                        message: 'Favor informar a sua atual!',
                      },
                    ]}>
                    <Input.Password size="large" placeholder=" Senha Atual" />
                  </Form.Item>
                </Col>
              )}

              <Col xs="12">
                <p className="heading-small text-muted mb-2">
                  <small>Senha</small>
                </p>
                <Form.Item
                  autoComplete="off"
                  className="my-2"
                  name="senhaUpdate"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a sua senha!',
                    },
                    // eslint-disable-next-line no-empty-pattern
                    ({ }) => ({
                      validator(rule, value) {
                        if (value && value.length < 6) {
                          return Promise.reject(
                            'Sua senha deve possuir no mínimo 6 dígitos',
                          );
                        } else {
                          const isAlfaNumerico = checkAlfanumerico(value);
                          if (!isAlfaNumerico) {
                            return Promise.reject(
                              'Sua senha deve possuir letras e números',
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Input.Password size="large" placeholder=" Senha" />
                </Form.Item>
              </Col>

              <Col xs="12">
                <p className="heading-small text-muted mb-2">
                  <small>Confirma senha</small>
                </p>
                <Form.Item
                  className="my-2"
                  autoComplete="off"
                  name="senhaConfirmacaoUpdate"
                  dependencies={['senhaUpdate']}
                  rules={[
                    {
                      required: true,
                      message: 'Favor confirmar a senha!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('senhaUpdate') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'Confirmação de senha diferentes!',
                        );
                      },
                    }),
                  ]}>
                  <Input.Password size="large" placeholder=" Confirma senha" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <div className="mt-4 text-center">
                    <Button
                      isloading={loading.toString()}
                      loadtext="Validando..."
                      size="large"
                      fluid
                      className="my-2"
                      primary
                      type="submit">
                      Alterar senha
                    </Button>
                  </div>
                  {!redefinePassword && (
                    <div className="text-center">
                      <Button
                        size="large"
                        fluid
                        className="my-2"
                        onClick={() => setOpenModalConfirm(false)}>
                        Voltar
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default Login;
