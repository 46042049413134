import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Form, Badge, Table, Select } from 'antd';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Icon } from 'semantic-ui-react';

import { useParams } from 'react-router-dom';

import Page from '~/components/Page';
import usePerfil from '~/actions/Perfil/usePerfil';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import EmptyBox from '~/components/EmptyBox';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import useEmpresa from '~/actions/Empresa/useEmpresa';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(row, identificador) {
  history.push({
    pathname: `/app/perfil/manutencao/${MODO.EDITAR}`,
    state: { row, identificador },
  });
}

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/perfil/manutencao/${MODO.CADASTRAR}`,
    state: { identificador },
  });
}

function ConsultaPerfil() {
  const [loadTable, setloadTable] = useState(false);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [perfilId, setPerfilId] = useState(undefined);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [dataSysAdmin, setDataAdmin] = useState([]);
  const { data: Empresas } = useEmpresa(1, 9999, null, null);

  const ref = useRef();
  const { isLoading, data, onLoad } = usePerfil();

  const { identificador } = useParams();

  const company = JSON.parse(localStorage.getItem('latitudeCompanyData'));
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const administrador = roles.find((perfil) => perfil === 'SystemAdmin');

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  async function onFiltros() {
    setloadTable(true);
    setLoading(true);

    const { empresaId } = ref.current.getFieldsValue();

    if (empresaId) {
      await api
        .get(`perfil/${empresaId}`)
        .then((response) => {
          setDataAdmin(response.data);
          setLoading(false);
          onLoad();
          setloadTable(false);
        })
        .catch((error) => {
          setLoading(false);
          setloadTable(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro na consulta, favor realizar uma nova tentativa mais tarde!',
          );
        });
    }

    setLoading(false);
    setloadTable(false);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setPerfilId(id);
  }

  async function handleConfirmDelete() {
    if (perfilId) {
      setLoading(true);

      await api
        .delete(url.PERFIL.DELETAR())
        .then((response) => {
          setLoading(false);
          onLoad();
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  ) && !company.matrizId;
  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  ) && !company.matrizId;
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  ) && !company.matrizId;

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Situação',
      dataIndex: '',
      key: 'excluido',
      responsive: ['lg'],
      render: (row) => (
        <Badge>
          <Icon
            name={!row.excluido ? 'check circle' : 'warning circle'}
            color={!row.excluido ? 'green' : 'red'}
          />
          {!row.excluido ? 'Ativo' : 'Inativo'}
        </Badge>
      ),
    },
  ];

  if (editar) {
    columns.push({
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row, identificador)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  if (excluir) {
    columns.push({
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  const isEmpty = administrador ? dataSysAdmin <= 0 : data.length <= 0;
  const dataFinally = administrador ? dataSysAdmin : data;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de Perfil</h5>
                <p className="category">Manutenção de Perfil</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="p-2">
                      <Form ref={ref} scrollToFirstError>
                        {administrador && (
                          <Row>
                            <Col xs="12">
                              <p className="heading-small text-muted mb-2">
                                <small>Empresa</small>
                              </p>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          {administrador && (
                            <>
                              <Col xs="12" md="6">
                                <Form.Item className="my-2" name="empresaId">
                                  <Select placeholder=" Empresa">
                                    {Empresas.map((item) => (
                                      <Option key={item.id}>{item.nome}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs="12" md="2">
                                <Button
                                  className="mb-1"
                                  size="tiny"
                                  color="green"
                                  icon
                                  labelPosition="left"
                                  fluid
                                  onClick={() => onFiltros()}>
                                  <Icon name="filter" />
                                  Filtrar
                                </Button>
                              </Col>
                            </>
                          )}
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${dataFinally.length})`}</h5>
                {incluir === true ? (
                  <HeaderFiltros
                    navToNovo={() => navToNovo(identificador)}
                    description="Novo Perfil"
                  />
                ) : null}
              </CardHeader>
              <CardBody>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={dataFinally}
                        loading={loadTable}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaPerfil;
