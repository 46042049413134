import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useLogradouroDropdown(logradouro) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      if (!logradouro && !params)
        return;

      const filterLogradouro = logradouro && !params ? `?logradouro=${logradouro}` : params && params ? `?logradouro=${params}` : '';

      try {
        await api.get(url.DROPDOWN.LOGRADOURO_DROPDOWN(filterLogradouro))
          .then(res => {
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [logradouro]
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
