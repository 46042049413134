/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Form, Input} from 'antd';
import checkAlfanumerico from '~/shared/checkAlfanumerico';
import FormUsuario from './components/FormUsuario';

import Button from '~/shared/Button';
import {authenticationService} from '~/services/authentication.service';
import history from '~/services/history';
import Notify from '~/shared/Notify';
import Page from '~/components/Page';
import DadosChave from '~/components/DadosChave';

export function HandleForm({profile}) {
  return <FormUsuario profile={profile} />;
}

function Profile() {
  const [loadPage, setloadPage] = useState(true);
  const [loadingSenha, setIsLoadingSenha] = useState(false);
  const [profile, setProfile] = useState({});

  let currentUser = JSON.parse(localStorage.getItem('icaseUser'));

  useEffect(() => {
    setTimeout(() => {
      setloadPage(false);
    }, 500);

    setProfile(currentUser);
  }, []);

  async function alterarSenha(dados) {
    setIsLoadingSenha(true);
    setTimeout(() => {
      Notify('success', 'Senha', 'Senha alterada com sucesso!');
      authenticationService.logout();
    }, 1000);
  }

  function voltar() {
    history.push('/');
  }

  return (
    <Page loadPage={loadPage}>
      <DadosChave />
      <div className="p-4 container-custom">
        <Row>
          <Col xs="12" md="6">
            <Card className="card-custom">
              <CardHeader>
                <div className="loginMessage">
                  <h6 className="card-title">Minha conta</h6>
                </div>
              </CardHeader>
              <CardBody>
                <div className="p-4">
                  <Row>
                    <Col>
                      <HandleForm profile={profile} />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="6">
            <Card className="card-custom">
              <CardHeader>
                <div className="loginMessage">
                  <h6 className="card-title">Alterar senha</h6>
                </div>
              </CardHeader>
              <CardBody>
                <div className="p-4">
                  <Row>
                    <Col>
                      <Form
                        initialValues={{remember: true}}
                        onFinish={alterarSenha}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12">
                            <p className="heading-small text-muted mb-2">
                              <small>Senha atual</small>
                            </p>
                            <Form.Item
                              autoComplete="off"
                              className="my-2"
                              name="oldPassword"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a senha atual!',
                                },
                              ]}>
                              <Input.Password
                                size="large"
                                placeholder=" Senha atual"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <p className="heading-small text-muted my-2">
                              <small>Nova senha</small>
                            </p>
                            <Form.Item
                              autoComplete="off"
                              className="my-2"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar a sua senha!',
                                },
                                ({}) => ({
                                  validator(rule, value) {
                                    if (value && value.length < 6) {
                                      return Promise.reject(
                                        'Sua senha deve possuir no mínimo 6 dígitos',
                                      );
                                    } else {
                                      const isAlfaNumerico = checkAlfanumerico(
                                        value,
                                      );
                                      if (!isAlfaNumerico) {
                                        return Promise.reject(
                                          'Sua senha deve possuir letras e números',
                                        );
                                      }
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <Input.Password
                                size="large"
                                placeholder=" Nova senha"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <p className="heading-small text-muted my-2">
                              <small>Confirma nova senha</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              autoComplete="off"
                              name="senhaConfirmacao"
                              dependencies={['password']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor confirmar a sua nova senha!',
                                },
                                ({getFieldValue}) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      getFieldValue('password') === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      'Confirmação de senha diferentes!',
                                    );
                                  },
                                }),
                              ]}>
                              <Input.Password
                                size="large"
                                placeholder=" Confirma nova senha"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <Form.Item>
                              <div className="mt-3 text-center">
                                <Button
                                  size="medium"
                                  fluid
                                  className="my-1"
                                  isloading={loadingSenha.toString()}
                                  loadtext="Validando...">
                                  Alterar senha
                                </Button>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

export default Profile;
