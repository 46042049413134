import React from 'react';
import {Card} from 'reactstrap';
import {Tooltip} from 'antd';

export default function CardWithBadge({
  titulo,
  quantidade,
  flutuacao,
  tituloFlutuacao,
  tipo,
  valor,
}) {
  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        height: 130,
        display: 'flex',
        gap: 12,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {titulo && (
          <h1
            style={{
              fontSize: 18,
              margin: 0,
              padding: 0,
            }}>
            {titulo}:
          </h1>
        )}

        {flutuacao && (
          <Tooltip placement="right" title={tituloFlutuacao || 'Mensagem'}>
            <span
              style={{
                backgroundColor: tipo === 'queda' ? '#FF0000' : '#35a650',
                padding: 1,
                paddingInline: 10,
                borderRadius: 10,
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                color: '#FFFF',
                justifyContent: 'space-between',
              }}>
              <span
                style={{
                  fontSize: 10,
                }}>
                {tipo === 'queda' ? '▼' : '▲'}
              </span>
              <span
                style={{
                  color: '#FFFF',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}>
                {flutuacao}%
              </span>
            </span>
          </Tooltip>
        )}
      </div>

      <div>
        {valor && (
          <span
            style={{
              fontSize: 22,
              fontWeight: 'bold',
            }}>
            {valor}
          </span>
        )}
      </div>

      <div
        style={{
          display: quantidade ? 'flex' : 'none',
        }}>
        <span
          style={{
            fontSize: 18,
          }}>
          Quantidade: {quantidade}
        </span>
      </div>
    </Card>
  );
}
