import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

export default function useAnaliseFinanceira(
  current,
  pageSize,
  corretorId) {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);

    let filter = '';
    if (current) filter = `?numeroPagina=${current}`;

    if (pageSize) {
      if (filter.length > 0) filter += `&tamanhoPagina=${pageSize}`;
      else filter = `?tamanhoPagina=${pageSize}`;
    }

    if (corretorId && corretorId !== 'TODOS') {
      if (filter.length > 0) filter += `&corretorId=${corretorId}`;
      else filter = `?corretorId=${corretorId}`;
    }

    try {
      await api.get(url.ATENDIMENTO.ANALISE_FINANCEIRA(filter)).then((res) => {
        setData(res?.data?.data || []);
        setQuantidade(res.data.total);
      });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [current, pageSize, corretorId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
