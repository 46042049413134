import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Select, Spin, Divider, InputNumber } from 'antd';

import { tipoDistribuicao } from '~/infra/resources/strings/enums/enumDistribuicaoLeads';
import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import useTipoUsuario from '~/actions/TipoUsuario/useTipoUsuario';
import useEmpresasGrupoDropdown from '~/actions/DropDown/useEmpresasGrupoDropdown';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import SkeletonInput from 'antd/lib/skeleton/Input';

const { Option } = Select;


function FormaRedistribuicao({ setFinalValues, finalValues, isLoading, tipoRedistribuicaoId, setTipoRedistribuicaoId, setUsuariosRedOptions, usuariosRedOptions }) {
    const latitudeCompanyData = JSON.parse(localStorage.getItem('latitudeCompanyData'));
    const isMatriz = !!!latitudeCompanyData.matrizId;
    const listEmpresas = JSON.parse(localStorage.getItem('icaseEmpresas')) || [];

    const { data: dataGrupoCorretores, onLoad: onLoadGruposCorretores } = useGrupoCorretores(null, null, null, true);
    const { data: dataEmpresas, isLoading: isLoadingCompany } = useEmpresasGrupoDropdown();
    const { data: tipoUsuarios } = useTipoUsuario();
    const {
        data: dataCorretores,
        onLoad: onLoadCorretores,
        isLoading: isLoadingCorretores,
    } = useCorretorDropdown(true);

    function onSearchCorretor(value) {
        if (value.length > 3) {
            onLoadCorretores({ nome: value, empresas: finalValues?.empresasRedistribuicaoId });
        }
    }

    const filterOption = (input, option) => option?.children
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.indexOf(
            input
                ?.toLowerCase()
                ?.normalize('NFD')
                ?.replace(/[\u0300-\u036f]/g, '')
        ) >= 0;

    useEffect(() => {
        onLoadGruposCorretores({ empresas: finalValues?.empresasRedistribuicaoId });
        onLoadCorretores({ empresas: finalValues?.empresasRedistribuicaoId });
    }, [finalValues.empresasRedistribuicaoId, onLoadCorretores, onLoadGruposCorretores]);

    useEffect(() => {
        const newUsers = [];
        for (let index = 0; index < dataCorretores?.length; index++) {
            const element = dataCorretores[index];
            if (!usuariosRedOptions?.some(u => u.id === element.id))
                newUsers.push(element);
        }

        setUsuariosRedOptions([...usuariosRedOptions, ...newUsers])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCorretores]);


    return <Row className="p-4">
        <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2">
                <small>Prazo redistribuição</small>
            </p>
            <Form.Item
                className="my-2"
                name="prazoRedistribuicao"
                rules={[
                    {
                        required: true,
                        message: 'Informe um prazo para redistribuição',
                    },
                ]}>
                <InputNumber min={1} max={4320} placeholder="Prazo" />
            </Form.Item>
            <div>
                <InfoCircleOutlined size={8} />
                <small className="text-dark-75 font-weight-bold pl-2">Informe o prazo em minutos em que o corretor manterá a posse do Lead. Ex: 1440</small>
            </div>
        </Col>
        <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2 mt-2">
                <small>Quantidade redistribuição</small>
            </p>
            <Form.Item
                className="my-2"
                name="qtdRedistribuicao"
                rules={[
                    {
                        required: true,
                        message: 'Informe a quantidade de redistribuição',
                    },
                ]}>
                <InputNumber min={1} max={100} placeholder="Quantidade" />
            </Form.Item>
            <div>
                <InfoCircleOutlined size={8} />
                <small className="text-dark-75 font-weight-bold pl-2">Informe a quantidade de vezes em que o Lead será redistribuído para outros corretores. Ex: 2</small>
            </div>
        </Col>
        <Divider />
        {(isMatriz || listEmpresas.length > 1) ? isLoadingCompany ?
            <SkeletonInput />
            : <Col xs="12" md="12">
                <p className="heading-small text-muted mb-2">
                    <small>Empresas</small>
                </p>
                <Form.Item className="my-2" name="empresasRedistribuicaoId"
                    rules={[
                        {
                            required: true,
                            message: 'Informe uma ou mais Empresas',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        filterOption={filterOption}
                        onChange={(value, obj) =>
                            setFinalValues(
                                {
                                    ...finalValues,
                                    ...{
                                        empresasRedistribuicaoDesc: obj?.map(c => c.children)
                                    },
                                    empresasRedistribuicaoId: value
                                })
                        }
                        placeholder=" Informe uma ou mais Empresas"
                        mode='multiple'
                        allowClear>
                        {dataEmpresas.map((item) => (
                            <Option
                                key={item.id}
                            >
                                {item.nome}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col> : null}
        <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2">
                <small>Tipo de Distribuição</small>
            </p>
            <Form.Item className="my-2" name="tipoRedistribuicaoId"
                rules={[
                    {
                        required: true,
                        message: 'Informe um Tipo de Distribuição',
                    },
                ]}>
                <Select
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, obj) => {
                        setFinalValues({ ...finalValues, ...{ tipoRedistribuicaoDesc: obj?.children } })
                        setTipoRedistribuicaoId(value);
                    }}
                    allowClear>
                    {tipoDistribuicao.map((item) => (
                        <Option
                            disabled={item.disabled}
                            key={item.value}
                        >
                            {item.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>

        {tipoRedistribuicaoId === '5' && <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2">
                <small>Equipe(s)</small>
            </p>
            <Form.Item className="my-2" name="equipesRedistribuicaoId"
                rules={[
                    {
                        required: true,
                        message: 'Informe uma ou mais equipes',
                    },
                ]}>
                <Select
                    showSearch
                    filterOption={filterOption}
                    placeholder=" Informe uma ou mais equipes"
                    mode='multiple'
                    onChange={(value, obj) => setFinalValues({ ...finalValues, ...{ equipesRedistribuicaoDesc: obj?.map(c => c.children) } })}
                    allowClear>
                    {dataGrupoCorretores.map((item) => (
                        <Option
                            disabled={item.disabled}
                            key={item.id}
                        >
                            {item.grupo}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>}

        {tipoRedistribuicaoId === '6' && <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2">
                <small>Usuário(s)</small>
            </p>
            <Form.Item className="my-2" name="usuariosRedistribuicaoId"
                rules={[
                    {
                        required: true,
                        message: 'Informe um ou mais usuários',
                    },
                ]}>
                <Select
                    defaultActiveFirstOption
                    showSearch
                    optionFilterProp="children"
                    onChange={(value, obj) => setFinalValues({ ...finalValues, ...{ usuariosRedistribuicaoDesc: obj?.map(c => c.children) } })}
                    filterOption={filterOption}
                    placeholder=" Informe um ou mais usuários"
                    mode='multiple'
                    disabled={isLoading}
                    notFoundContent={isLoadingCorretores ? (
                        <Spin indicator={<LoadingOutlined spin style={{ marginRight: 5, fontSize: 16 }} />}>
                            Carregando...
                        </Spin>
                    ) : null}
                    onSearch={onSearchCorretor}>
                    {!isLoadingCorretores &&
                        usuariosRedOptions.map((item) => (
                            <Option
                                key={item.id}
                                disabled={item.isSeparator}
                            >
                                {item?.nome?.toString()}
                            </Option>
                        ))}
                </Select>
            </Form.Item>
        </Col>}

        {tipoRedistribuicaoId === '7' && <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2">
                <small>Tipo de Usuários</small>
            </p>
            <Form.Item className="my-2" name="tiposUsuarioRedistribuicaoId"
                rules={[
                    {
                        required: true,
                        message: 'Informe um ou mais Tipos de Usuários',
                    },
                ]}
            >
                <Select
                    showSearch
                    filterOption={filterOption}
                    onChange={(value, obj) => setFinalValues({ ...finalValues, ...{ tiposUsuarioRedistribuicaoDesc: obj?.map(c => c.children) } })}
                    placeholder=" Informe um ou mais Tipos de Usuários"
                    mode='multiple'
                    allowClear>
                    {tipoUsuarios.map((item) => (
                        <Option
                            key={item.id}
                        >
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>}
    </Row>;
}

export default FormaRedistribuicao;