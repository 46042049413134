import React from "react";

import { Table } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const DragHandle = sortableHandle(({ active }) => (
  <MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999", fontSize: '30px' }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

function SortableTable({data: dataResponse, handleOrder, handleEdit, handleDrop, handleView, handleLog}) {
  const [dataSource, setDataSource] = React.useState(dataResponse.map(d => {
    return {...d, index: d.ordem}
  }));
  const [selectedItems, setSelectedItems] = React.useState([]);

  const getColumns = () => {
    return [
      {
        title: "Reordenar",
        dataIndex: "",
        width: 30,
        className: "drag-visible",
        render: (d, dd, i) => (
          <div style={{textAlign: 'center'}}> 
            <DragHandle active={selectedItems.includes(i)} />
          </div>
        )
      },
      {
        title: "Ordem",
        dataIndex: "",
        width: 30,
        className: "drag-visible",
        render: (d, dd, i) => (
          <>
            <strong>{i+1}º</strong>
          </>
        )
      },
      {
        title: "Regra",
        dataIndex: "nome",
        className: "drag-visible"
      },
      {
        title: "Tipo",
        dataIndex: "tipoDistribuicao"
      },
      {
        title: "Última Alteração",
        render: (row) => (
          <span key={row.index}>{moment(row).format('DD/MM/YYYY - HH:mm')}</span>
        ),
        dataIndex: "dataAtualizacao"
      },
      {
        title: "Usuário Alteração",
        dataIndex: "usuarioAlteracao"
      },
      {
        title: 'Log',
        key: '',
        fixed: 'right',
        width: 80,
        render: (row) => (
          <div className="p-1" key={row.index}>
            <i
              onClick={() => handleLog(row.id)}
              className="ri-history-line linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </div>
        ),
      },
      {
        title: 'Expiar',
        key: '',
        fixed: 'right',
        width: 80,
        render: (row) => (
          <div className="p-1" key={row.index}>
            <i
              onClick={() => handleView(row.id)}
              className="ri-eye-fill linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </div>
        ),
      },
      {
        title: 'Editar',
        key: '',
        fixed: 'right',
        width: 80,
        render: (row) => (
          <div className="p-1" key={row.index}>
            <i
              onClick={() => handleEdit(row.id)}
              className="ri-pencil-fill linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </div>
        ),
      },
      {
        title: 'Excluir',
        key: '',
        fixed: 'right',
        width: 80,
        render: (row) => (
          <div className="p-1" key={row.index}>
            <i
              onClick={() => handleDrop(row.id)}
              className="ri-delete-bin-5-fill linkTable"
              style={{fontSize: '26px', marginRight: '10px'}}></i>
          </div>
        ),
      }
    ];
  };
  const merge = (a, b, i = 0) => {
    let aa = [...a];
    return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tempDataSource = dataSource;

    if (oldIndex !== newIndex) {
      if (!selectedItems.length) {
        let movingItem = tempDataSource[oldIndex];
        tempDataSource.splice(oldIndex, 1);
        tempDataSource = merge(tempDataSource, [movingItem], newIndex);
      } else {
        let filteredItems = [];
        selectedItems.forEach((d) => {
          filteredItems.push(tempDataSource[d]);
        });
        let newData = [];
        tempDataSource.forEach((d, i) => {
          if (!selectedItems.includes(i)) {
            newData.push(d);
          }
        });
        tempDataSource = [...newData];
        tempDataSource = merge(tempDataSource, filteredItems, newIndex);
      }
      setDataSource(tempDataSource);
      setSelectedItems([]);

      handleOrder(newIndex + 1, tempDataSource[newIndex]?.id);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        selected={selectedItems.length}
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) {
            selectedItems.includes(index)
              ? selectedItems.splice(selectedItems.indexOf(index), 1)
              : selectedItems.push(index);
            setSelectedItems(selectedItems);
          } else {
            setSelectedItems([]);
          }
        }}
      />
    );
  };

  return (
    <>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={getColumns()}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
      {selectedItems.length ? <>{selectedItems.length} items selected </> : ""}
    </>
  );
}

export default SortableTable;

