/* eslint-disable no-unused-vars */
import React from 'react';
import { useMemo } from 'react';
import useQualityOfService from './use-quality-of-service';

import { Card, Col, Row } from 'reactstrap';
import { BidirectionalBar, Pie } from '@ant-design/charts';

import CardTitle from '../../CardTitle/CardTitle';

export default function QualityOfService({ filters }) {

  const { mainPeriod, broker, brokerGroup, initialDate, finalDate } = filters;
  const { data } = useQualityOfService(mainPeriod, broker, brokerGroup, initialDate, finalDate);

  const totalQualityOfService = useMemo(() => {
    const total = data?.reduce(
      (acc, cur) => {
        acc.irregulares += cur.irregulares;
        acc.regulares += cur.regulares;

        return acc;
      },
      {
        irregulares: 0,
        regulares: 0,
      },
    );

    return total;
  }, [data]);


  const config = {
    data: data,
    xField: 'nomeCorretor',
    xAxis: {
      position: 'bottom',
    },
    interactions: [
      {
        type: 'active-region',
      },
    ],
    yField: ['irregulares', 'regulares'],
    color: ['#FF647C', '#6395fa'],
    tooltip: {
      shared: true,
      showMarkers: false,
    },
  };

  const pieChartData = [
    {
      type: 'Irregulares',
      value: totalQualityOfService?.irregulares,
    },
    {
      type: 'Regulares',
      value: totalQualityOfService?.regulares,
    },
  ];

  const pieChartConfig = {
    appendPadding: 10,
    data: pieChartData,
    angleField: 'value',
    colorField: 'type',
    color: ({ type }) => {
      if (type === 'Irregulares') {
        return '#f30104';
      }
      if (type === 'Regulares') {
        return '#6395fa';
      }
    },
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
      formatter: ({ irregulares, regulares }) => `${((regulares / 30) * 100).toFixed(2)} %`
    },
    tooltip: {
      formatter: (datum) => {

        return {
          name: datum?.type,
          value: `${datum?.value} (${((datum?.value / (totalQualityOfService?.irregulares + totalQualityOfService?.regulares)) * 100).toFixed(2)}%)`
        };
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Qualidade dos atendimentos</CardTitle>
      <Row>
        <Col>
          <Pie {...pieChartConfig} />
        </Col>
        <Col>
          <BidirectionalBar {...config} />
        </Col>
      </Row>
    </Card>
  );
}

