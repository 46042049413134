function formatNumber(numero){

    if (!numero || numero === 'undefined') {
      return ''
    }  
    const numeroApenasNumeros = numero.replace(/\D/g, '');
    if (
      numeroApenasNumeros.length !== 10 &&
      numeroApenasNumeros.length !== 11
    ) {
      return ''
    }
    if (!/^[0-9]+$/.test(numeroApenasNumeros)) {
      return ''
    }
    if (
      numeroApenasNumeros.length === 11 &&
      !/^(\d{2}9\d{8})$/.test(numeroApenasNumeros)
    ) {
      return ''
    }
    if (
      numeroApenasNumeros.length === 10 &&
      !/^(\d{2}[2-5]\d{7})$/.test(numeroApenasNumeros)
    ) {
      return''
    }
    return numeroApenasNumeros;  
  }
  export default formatNumber