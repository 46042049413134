import React, {Fragment, useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {useParams} from 'react-router-dom';

import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import Page from '~/components/Page';
import CardUser from '~/components/CardUser';
import ModalGenerico from '~/components/ModalGenerico';
import ModalTransferirUsuario from '../ModalTransferirUsuario';
import ModalConfirm from '~/components/ModalConfirm';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function CorretoresAgrupados({
  nomeAgrupamento,
  corretores,
  exibirInativos,
  isLoading,
  handleOpenModal,
}) {
  const [actions, setActions] = useState([]);
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isMaster = !!roles.find((x) => x.toUpperCase() === 'MASTER');
  const isSysAdmin = !!roles.find((x) => x.toUpperCase() === 'SYSTEMADMIN');

  const {identificador} = useParams();
  const corretoresFiltrados = corretores?.filter(
    (u) => !u?.excluido || exibirInativos,
  );
  const [openModalTransferir, setOpenModalTransferir] = useState({
    open: false,
    id: null,
  });
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const closeModalTransferirUsuario = () => {
    setOpenModalTransferir({open: false, id: null});
  };
  useEffect(() => {
    const {actions} = getActionsMenus(identificador);

    setActions(actions || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  return (
    !!corretoresFiltrados.length && (
      <Fragment>
        <Page loadPage={isLoading}></Page>
        <Row xs="12">
          <h5 className="title mt-2 p-3">{`${nomeAgrupamento}: ${corretoresFiltrados.length}`}</h5>
        </Row>
        <Row className="px-1">
          {corretoresFiltrados.map((corretor, index) => (
            <Col xs="12" md="6" key={index} className="p-1">
              <CardUser
                key={index}
                user={corretor}
                type="usuário"
                showEdit
                url={`/app/corretor/manutencao/${MODO.EDITAR}`}
                identificador={identificador}
                actions={actions}
                showInactivate
                functionInactivate={() => handleOpenModal(corretor)}
                showTransfer={isMaster || isSysAdmin}
                functionTransfer={() => {
                  setOpenModalTransferir({open: true, id: corretor.usuarioId});
                }}
              />
            </Col>
          ))}
        </Row>
        <ModalGenerico
          requireConfirmation={openModalTransferir.requireConfirmation}
          open={openModalTransferir.open}
          close={closeModalTransferirUsuario}
          title={'Transferir usuário'}>
          <ModalTransferirUsuario
            userId={openModalTransferir.id}
            functionClose={closeModalTransferirUsuario}
          />
        </ModalGenerico>
        <ModalConfirm
          isDelete
          icon="warning sign"
          loading={isLoading}
          open={openModalConfirm}
          confirm={() => {
            setOpenModalConfirm(false);
          }}
          close={() => setOpenModalConfirm(false)}
          title="Atenção"
          message="Deseja realmente transferir este usuário? Esta ação NÃO TEM VOLTA."
        />
      </Fragment>
    )
  );
}

export default CorretoresAgrupados;
