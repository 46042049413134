import { useCallback, useEffect, useState } from "react";
import { measureTextWidth } from '@ant-design/charts';
import api from '~/services/api';
import { parseToQueryString } from "~/shared/parseToQueryString";
import moment from 'moment';
moment.locale('pt-BR');


export default function useLeadOrigin(mainPeriod, broker, brokerGroup, initialDate, finalDate) {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(
    async () => {
      const filtro = parseToQueryString({
        periodoPrincipal: mainPeriod,
        corretorId: broker,
        grupoCorretoresId: brokerGroup,
        dataIni: initialDate,
        dataFim: finalDate
      });

      try {
        await api.get(`Dashboard/gerente/origem-dos-leads?${filtro}`).then((res) => {
          setData(res?.data?.data);
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [mainPeriod, broker, brokerGroup, initialDate, finalDate],
  );



  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style,
    );
    const R = containerWidth / 2;

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
            (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)),
          ),
        ),
        1,
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'
      };">${text}</div>`;
  }

  const config = {
    appendPadding: 10,
    data,
    angleField: 'valor',
    colorField: 'origem',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.origem : 'Atendimentos';
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '32px',
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.valor}`
            : `${data?.reduce((r, d) => r + d.valor, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },

    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    config,
    isLoading,
    onLoad,
    data
  };
}

