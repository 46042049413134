import React, { Fragment } from 'react';

import { Card } from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';
import { Bar } from '@ant-design/charts';
import useActiveOfferRank from './use-active-offer-rank';

export default function ActiveOfferRank({ filters }) {
  const { mainPeriod, broker, brokerGroup, initialDate, finalDate } = filters;
  const { config } = useActiveOfferRank(mainPeriod, broker, brokerGroup, initialDate, finalDate);

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Ranking Oferta Ativa</CardTitle>
      <Fragment>
        <Bar {...config} />
      </Fragment>
    </Card>
  );
}

