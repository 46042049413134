import React, {Fragment} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import useAtivarUsuario from '~/actions/Usuario/useAtivarUsuario';
import Button from '~/shared/Button';

function ModalAtivarUsuario({open, close, usuarioId, onUpdate}) {
  const {ativarUsuario, isLoading} = useAtivarUsuario();

  const handleCloseModal = () => {
    if (!isLoading) {
      close();
    }
  };

  const onConfirmSubmit = () => {
    ativarUsuario(usuarioId).then(() => {
      onUpdate();
      close();
    });
  };

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => handleCloseModal()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>Você tem certeza que deseja ativar este usuário?</h5>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => onConfirmSubmit()}
              isloading={`${isLoading}`}
              loadtext={'Ativando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              disabled={isLoading}
              labelPosition="left"
              size="medium"
              onClick={() => handleCloseModal()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalAtivarUsuario;
