import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';
import Logradouro from '~/views/private/Cadastros/Logradouro/Manutencao';

function ModalLogradouro({ open, close }) {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="large"
        dimmer="blurring"
        open={open}
        onClose={() => close()}>
        <Modal.Content>
          <Logradouro
            match={{ params: { modo: 'cadastrar' } }}
            location={{ state: { identificador: undefined } }}
            onVoltar={close}
          />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalLogradouro;
