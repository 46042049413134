import {Tag, Tooltip} from 'antd';
import React from 'react';

const takeOnly = 5;
export default function TagMembros({membros}) {
  const validMembros = Array.isArray(membros) && membros.length >= 1;
  const onlyFirsts = validMembros ? membros.slice(0, takeOnly) : [];
  const hasMore = validMembros ? Math.max(membros.length - takeOnly, 0) : 0;
  const others = validMembros ? membros.slice(takeOnly, membros.length) : [];

  return validMembros ? (
    <div>
      <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
        {onlyFirsts.map(({children: nome, options: {type: tipo}}) => {
          return (
            <Tag
              style={{lineHeight: '22px', fontSize: '14px'}}
              color={tipo === 'corretor' ? null : '#1d1f8c'}>
              {nome}
            </Tag>
          );
        })}
        {hasMore > 0 && (
          <Tooltip
            title={
              <div
                style={{
                  minWidth: '200px',
                }}>
                {others.map(({children: nome, options: {type: tipo}}) => (
                  <Tag
                    style={{lineHeight: '22px', fontSize: '14px'}}
                    color={tipo === 'corretor' ? null : '#1d1f8c'}>
                    {nome}
                  </Tag>
                ))}
              </div>
            }>
            <span
              style={{
                color: '#1d1f8c',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}>
              {` +${hasMore}`}
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  ) : null;
}
