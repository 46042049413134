const midiaDivulgacao = [
  {
    id: 100,
    descricao: 'Grupo Zap',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 101,
    descricao: 'OLX',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 102,
    descricao: 'Imovel Web',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 103,
    descricao: 'Site Próprio',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
        destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 104,
    descricao: 'Mooving',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 105,
    descricao: 'Mercado Livre',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 106,
    descricao: 'Casa Mineira',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 112,
    descricao: 'Chave na Mão',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 115,
    descricao: 'Rede Latitude',
    divulgacao: true,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 116,
    descricao: '62imoveis',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 117,
    descricao: 'WImoveis',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  },
  {
    id: 118,
    descricao: 'DF Imoveis',
    divulgacao: false,
    destaque: false,
    superDestaque: false,
    destaqueEspecial: false,
    destaquePremium: false
  }
];

export default midiaDivulgacao;
