import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useBairro(
  current,
  pageSize,
  nomeCondominio,
  tipoCondominioId,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : null;
    const tamanhoPagina = pageSize ? pageSize : null;
    const filterNomeCondominio = nomeCondominio ? nomeCondominio : null;
    const filtroTipoCondominioId = tipoCondominioId ? tipoCondominioId : null;
    let filter = null;

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (tamanhoPagina) {
      if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
      else filter = `?tamanhoPagina=${tamanhoPagina}`;
    }

    if (filterNomeCondominio) {
      if (filter.length > 0) filter += `&nome=${filterNomeCondominio}`;
      else filter = `?nome=${filterNomeCondominio}`;
    }

    if (filtroTipoCondominioId) {
      if (filter.length > 0)
        filter += `&tipoCondominioId=${filtroTipoCondominioId}`;
      else filter = `?tipoCondominioId=${filtroTipoCondominioId}`;
    }

    try {
      await api
        .get(`${url.CONDOMINIO.CONSULTA()}${filter ? filter : ''}`)
        .then((res) => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.total);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [pageSize, nomeCondominio, tipoCondominioId, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
