import { useCallback, useEffect, useState, useMemo } from "react";
import api from '~/services/api';
import { parseToQueryString } from "~/shared/parseToQueryString";
import moment from 'moment';
moment.locale('pt-BR');


export default function useRealEstate(mainPeriod, broker, brokerGroup, initialDate, finalDate) {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(
    async () => {
      const filtro = parseToQueryString({
        periodoPrincipal: mainPeriod,
        corretorId: broker,
        grupoCorretoresId: brokerGroup,
        dataIni: initialDate,
        dataFim: finalDate
      });

      try {
        await api.get(`Dashboard/gerente/total-operacao-imobiliaria?${filtro}`).then((res) => {
          setData(res?.data?.data);
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [mainPeriod, broker, brokerGroup, initialDate, finalDate],
  );

  const config = {
    appendPadding: 10,
    data,
    angleField: 'quantidade',
    colorField: 'operacao',
    color: ['#f56384', '#f7a651', '#5dc5c8'],
    radius: 0.9,
    legend: false,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const totalAmount = useMemo(() => {
    return data?.reduce((acc, cur) => acc + cur.quantidade, 0);
  }, [data]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    config,
    totalAmount,
    isLoading,
    onLoad
  };
}
