import { useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import { FilterCache } from '~/shared/filterCache';

let controller;
let canceledRequest = false;

const payloadComposite = (obj, key, value) => {
  if (value) {
    return (obj[key] = value);
  }

  return null;
};

export default function useImovel() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const cancelRequest = () => {
    if (controller) {
      canceledRequest = true;
      controller.abort();
    }
  }

  const onLoad = useCallback(async ({ simpleFilters = false, relatorio = false, params }) => {
    controller = new AbortController();
    const signal = controller.signal;

    const payload = {
      numeroPagina: params?.numeroPagina || 1,
      tamanhoPagina: params?.tamanhoPagina || 12,
    };

    for (const [key, value] of Object.entries(params)) {
      if (value?.length || typeof value === 'boolean') {
        payloadComposite(payload, key, value);
      } else if (!isNaN(parseFloat(value)) && isFinite(value)) {
        payloadComposite(payload, key, value);
      }
    }

    if (relatorio) {
      setIsLoading(true);
      try {
        await api.post(url.RELATORIOS.IMOVEIS(), payload, { signal })
          .then((res) => {
            setDadosRelatorio(res?.data || []);
          });
      } catch (e) {
        setDadosRelatorio([]);
      } finally {
        setIsLoading(false);
      }
    } else if (Object.keys(payload).length > 0) {
      setIsLoading(true);

      if (!simpleFilters && payload.imovelPublico) {
        FilterCache.setFiltrosImoveisCompartilhados(payload);
      } else {
        FilterCache.setFiltrosImoveis(payload);
      }

      try {
        await api.post(url.IMOVEL.FILTER(), payload, { signal })
          .then((res) => {
            canceledRequest = false;
            setData(res?.data?.data || []);
            setQuantidade(res?.data?.total || 0);
          });
      } catch (e) {
        setData([]);
      } finally {
        if (!canceledRequest) setIsLoading(false);
      }
    }
  }, []);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
    dadosRelatorio,
    cancelRequest,
  };
}
