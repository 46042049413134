import React, {useState} from 'react';

import {Button} from 'antd';
import {CheckOutlined, CopyOutlined} from '@ant-design/icons';

import ModalGenerico from '~/components/ModalGenerico';
import SelectCliente from './components/SelectCliente';

export default function ModalApresentacaoImoveis({
  open,
  close,
  onChangeClientId,
  generatePresentationLink = 'Link gerado',
}) {
  const [clienteId, setClienteId] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  function copyToClipboard() {
    setIsCopied(true);
    navigator.clipboard.writeText(generatePresentationLink);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  function handleSelectClient(clientId) {
    if (onChangeClientId) {
      onChangeClientId(clientId);
    }

    setClienteId(clientId);
  }

  return (
    <ModalGenerico
      size="small"
      open={open}
      close={close}
      title="Gerar link de apresentação">
      <div
        style={{
          width: '100%',
          display: 'flex',
          rowGap: 12,
          flexDirection: 'column',
        }}>
        <SelectCliente handleSelectClient={handleSelectClient} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 15,
          }}>
          <span
            style={{
              fontWeight: 'bold',
              display: clienteId ? 'block' : 'none',
              fontSize: 15,
            }}>
            Link gerado com sucesso. Clique no botão abaixo para copiá-lo.
          </span>
          <span
            style={{
              border: '0.5px dashed #D9D9D9 ',
              borderRadius: 5,
              padding: 4,
            }}>
            {clienteId
              ? generatePresentationLink
              : 'Selecione um cliente para gerar o link de compartilhamento.'}
          </span>
          <Button
            disabled={!clienteId}
            type="primary"
            onClick={copyToClipboard}
            style={{
              backgroundColor: clienteId ? '#1D1F8C' : '',
            }}>
            {isCopied ? <CheckOutlined /> : <CopyOutlined />}
            {isCopied ? 'Copiado' : 'Copiar'}
          </Button>
        </div>
      </div>
    </ModalGenerico>
  );
}
