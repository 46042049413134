/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Switch,
  Radio,
  Divider,
  Spin,
  Slider,
} from 'antd';
import { Transition, Icon } from 'semantic-ui-react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import Page from '~/components/Page';
import api from '~/services/api';
import useImovel from '~/actions/Imovel/useImovel';
import useTipoImovel from '~/actions/DropDown/useTipoImovel';
import useCidadesDropdownGrupos from '~/actions/DropDown/useCidadesDropdownGrupos';

import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';

import Button from '~/shared/Button';
import formatedReal from '~/shared/formatedReal';
import url from '~/infra/urls';

import { ContentRow } from './styles';
import { useDeviceScreen } from '~/hooks/use-device-screen';
import moment from 'moment';
import CardImovel from '~/components/CardImovel';

moment.locale('pt-BR');


const { Option, OptGroup } = Select;
const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};


const optionsSimple = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4 ou +', value: 4 },
];


const antIcon = (
  <Fragment>
    <LoadingOutlined spin style={{ marginRight: 5, fontSize: 14 }} />
    <span>Carregando...</span>
  </Fragment>
);

const estadosIds = [];
const cidadesIds = [];
const bairrosIds = [];
const logradourosIds = [];
const condominiosIds = [];

export function rowEdit(row, identificador) {
  window.open(
    `/app/imoveis/manutencao/${MODO.EDITAR}/${identificador}/${row.id}`,
    '_blank',
  );
}

export function rowPreview(row, identificador) {
  window.open(
    `/app/imoveis/preview/visualizar/${identificador}/${row.id}`,
    '_blank',
  );
}

export function navToNovo(identificador) {
  window.open(
    `/app/imoveis/manutencao/${MODO.CADASTRAR}/${identificador}`,
    '_blank',
  );
}

function PerfilAtendimento({ isModalConsulta, chavesNaEmpresa, setImovel, close, ...props }) {

  const [clearFiltroQuartos, setClearFiltroQuartos] = useState(0);
  const [clearFiltroVagas, setClearFiltroVagas] = useState(0);
  const [clearFiltroSuites, setClearFiltroSuites] = useState(0);
  const [clearFiltroBanheiros, setClearFiltroBanheiros] = useState(0);
  const [initialValues, setInitialValues] = useState();
  const { data: dataTipoImoveis } = useTipoImovel();
  const [visible, setVisible] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [hasSearching, setHasSearching] = useState(false);

  const {
    isLoading: isLoadingCidadesDropdown,
    data: dataCidadesGrupos,
    onLoad: onLoadCidadesGrupos,
    cancelRequest: cancelRequestCidadeGrupos,
  } = useCidadesDropdownGrupos();

  const { isLoading, data: dataImovel, onLoad } = useImovel();

  // TERMINO DAS CHAMADAS DOS ACTIONS

  const [formPerfilAtendimento] = Form.useForm();

  useEffect(() => {
    if (isLoading === false && dataImovel.length > 0) {
      setVisible(true);
      setLoadButton(false);
    } else if (isLoading === false) {
      if (hasSearching && chavesNaEmpresa === false) {
        Notify(
          'warning',
          '',
          'Não foram encontrados imóveis para os filtros informados!',
        );
      } else if (hasSearching && chavesNaEmpresa === true) {
        Notify(
          'warning',
          pt.comum.atencao,
          'No momento não existem imóveis com as chaves na empresa !',
        );
      }

      setTimeout(() => {
        setLoadButton(false);
      }, 1000);
      window.scrollTo(1, 1);
    }
  }, [isLoading]);

  useEffect(() => {
    setInitialValues({
      localizacao: props?.initialValues?.localizacao?.split(','),
      codImovel: props?.initialValues?.codigoImovel,
      tipoImoveis: props?.initialValues?.tipoImovel !== '' ? props?.initialValues?.tipoImovel?.split(',') : undefined,
      valorVendaDe: formataReal(props?.initialValues?.valorMinimo),
      valorVendaAte: formataReal(props?.initialValues?.valorMaximo),
      valorCondominioDe: formataReal(props?.initialValues?.valorCondominioMinimo),
      valorCondominioAte: formataReal(props?.initialValues?.valorCondominioMaximo),
      areaDe: props?.initialValues?.areaMinima,
      areaAte: props?.initialValues?.areaMaxima,
      dormitorios: props?.initialValues?.numeroDormitorios,
      suites: props?.initialValues?.numeroSuites,
      banheiros: props?.initialValues?.numeroBanheiros,
      vagas: props?.initialValues?.numeroVagas,
      numeroPercentualMargemValor: [0, props?.initialValues?.numeroPercentualMargemValor],
      consultaRedeLatitude: props?.initialValues?.consultaRedeLatitude
    });
    onLoadCidadesGrupos(props?.initialValues?.localizacao);
  }, [props.initialValues])

  function formataReal(value) {
    if (!value)
      return value;

    if (!isNaN(value) && !((typeof value) === 'string'))
      value = Number(value).toFixed(2).toString();

    const maskedValue = formatedReal(value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.')
    ).toFixed(2);
    return numberValue > 0 ? maskedValue : undefined;
  }

  function handleMaskCurrency(e, field) {
    const maskedValue = formatedReal(e.target.value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.'),
    ).toFixed(2);

    formPerfilAtendimento.setFieldsValue({
      [field]: numberValue > 0 ? maskedValue : undefined,
    });

  }

  function handleTipoImoveis(values) {
    const value = values.slice(-1); // COPIANDO ÚLTIMA POSIÇÃO

    if (value[0] === 'TODOS') {
      // LIMPAR TODOS OS VALORES, POIS IRÁ FILTRAR POR TODOS
      formPerfilAtendimento.setFieldsValue({ tipoImoveis: ['TODOS'] });
    } else if (values[0] === 'TODOS') {
      // VAI RETIRAR A OPÇÃO DE TODOS SE EXISTIR
      formPerfilAtendimento.setFieldsValue({ tipoImoveis: [values[1]] });
    }


  }

  function handlerConsultaRedeLatitude(value) {
    formPerfilAtendimento.setFieldsValue({ consultaRedeLatitude: value });
  }

  function onFiltros() {
    setVisible(false);
    setHasSearching(true);
    setVisible(false);
    setLoadButton(true);
    formPerfilAtendimento
      .validateFields()
      .then((dados) => {
        const codImovelFilter = dados?.codImovel;
        onSave();
        onLoad({
          relatorio: false,
          params: {
            consultaRedeLatitude: dados?.consultaRedeLatitude,
            cidades: cidadesIds,
            bairros: bairrosIds,
            condominios: dados?.condominioId,
            operacaoImobiliaria: dados?.operacaoImobiliaria !== undefined ? dados?.operacaoImobiliaria : 2,
            situacao: codImovelFilter ? null : dados?.situacao ? dados?.situacao : null,
            tipoImoveis: dados?.tipoImoveis,
            logradouro: dados?.logradouro,
            valorVendaDe: dados?.valorVendaDe
              ? Number(
                dados?.valorVendaDe.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            valorVendaAte: dados?.valorVendaAte
              ? Number(
                dados?.valorVendaAte.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            valorLocacaoDe: dados?.valorLocacaoDe
              ? Number(
                dados?.valorLocacaoDe.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            valorLocacaoAte: dados?.valorLocacaoAte
              ? Number(
                dados?.valorLocacaoAte.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            areaDe: dados?.areaDe,
            areaAte: dados?.areaAte,
            dormitorios: dados?.dormitorios,
            vagas: dados?.vagas,
            suites: dados?.suites,
            codImovel: codImovelFilter ? Number(codImovelFilter) : undefined,
            finalidadeId: dados?.finalidadeId,
            percentualMargemValor: dados?.numeroPercentualMargemValor[1],
            quantidadeBanheiros: dados?.banheiros
          },
        });
      })
      .catch(() => {
        setLoadButton(false);
        window.scrollTo(1, 1);
      });

  }

  function onSave() {
    formPerfilAtendimento
      .validateFields()
      .then(async (dados) => {
        const perfil =
        {
          id: props.id,
          consultaRedeLatitude: dados?.consultaRedeLatitude,
          operacaoImobiliaria: dados?.operacaoImobiliaria
            ? dados?.operacaoImobiliaria
            : 2,
          codigoImovel: dados?.codImovel ? Number(dados?.codImovel) : undefined,
          tipoImovel: dados?.tipoImoveis?.join(','),
          valorMinimo: dados?.valorVendaDe
            ? Number(
              dados?.valorVendaDe.toString().replaceAll('.', '').replace(',', '.'),
            ).toFixed(2)
            : undefined,
          valorMaximo: dados?.valorVendaAte
            ? Number(
              dados?.valorVendaAte.toString().replaceAll('.', '').replace(',', '.'),
            ).toFixed(2)
            : undefined,
          valorCondominioMinimo: dados?.valorCondominioDe
            ? Number(
              dados?.valorCondominioDe
                .toString()
                .replaceAll('.', '')
                .replace(',', '.'),
            ).toFixed(2)
            : undefined,
          valorCondominioMaximo: dados?.valorCondominioAte
            ? Number(
              dados?.valorCondominioAte
                .toString()
                .replaceAll('.', '')
                .replace(',', '.'),
            ).toFixed(2)
            : undefined,
          numeroDormitorios: dados?.dormitorios,
          numeroSuites: dados?.suites,
          numeroVagas: dados?.vagas,
          numeroBanheiros: dados?.banheiros,
          areaMinima: dados?.areaDe,
          areaMaxima: dados?.areaAte,
          localizacao: dados?.localizacao?.join(','),
          numeroPercentualMargemValor: dados?.numeroPercentualMargemValor[1]
        };
        props.perfil(perfil)
        if (perfil.id) {
          await UpdateAtendimentoPefil(perfil)
        }
      })
  }

  async function UpdateAtendimentoPefil(atendimentoPerfil) {
    await api
      .put(url.ATENDIMENTO.ATENDIMENTO_PERFIL(), atendimentoPerfil);
    props.perfil(atendimentoPerfil)
    props.loadLocalizacao(atendimentoPerfil.localizacao)
  }

  async function handleCidadesGrupos(value) {
    cancelRequestCidadeGrupos();
    await onLoadCidadesGrupos(value);
  }

  function handleChangeCidadesGrupos(values) {
    estadosIds.splice(0, estadosIds.length);
    cidadesIds.splice(0, cidadesIds.length);
    bairrosIds.splice(0, bairrosIds.length);
    logradourosIds.splice(0, logradourosIds.length);
    condominiosIds.splice(0, condominiosIds.length);

    const sortedArray = values.sort();
    sortedArray.forEach((item) => {
      const splitArray = item.split('|');

      if (splitArray[0] === 'E') estadosIds.push(splitArray[1]);
      if (splitArray[0] === 'C') cidadesIds.push(splitArray[1]);
      if (splitArray[0] === 'B') bairrosIds.push(splitArray[1]);
      if (splitArray[0] === 'L') logradourosIds.push(splitArray[1]);
      if (splitArray[0] === 'CC') condominiosIds.push(splitArray[1]);
    });
  }

  function onClickRadioQuartos(e) {
    if (e.target.value === clearFiltroQuartos) {
      formPerfilAtendimento.setFieldsValue({ dormitorios: null });
      //onFiltros();
    } else setClearFiltroQuartos(e.target.value);
  }

  function onClickRadioVagas(e) {
    if (e.target.value === clearFiltroVagas) {
      formPerfilAtendimento.setFieldsValue({ vagas: null });
      //onFiltros();
    } else setClearFiltroVagas(e.target.value);
  }

  function onClickRadioSuites(e) {
    if (e.target.value === clearFiltroSuites) {
      formPerfilAtendimento.setFieldsValue({ suites: null });
      //        onFiltros();

    } else setClearFiltroSuites(e.target.value);
  }

  function onClickRadioBanheiros(e) {
    if (e.target.value === clearFiltroBanheiros) {
      formPerfilAtendimento.setFieldsValue({ banheiros: null });
      //onFiltros();
    } else setClearFiltroBanheiros(e.target.value);
  }

  function margemSlider(value) {
    if (value[0] === 0 && value[1] === 0)
      formPerfilAtendimento.setFieldsValue({ numeroPercentualMargemValor: undefined });
  }

  async function onSelect(imovelBusca) {
    setImovel(imovelBusca);
    const redeLatitude = formPerfilAtendimento.getFieldValue('consultaRedeLatitude');
    const numeroPercentualMargemValor = formPerfilAtendimento.getFieldValue('numeroPercentualMargemValor');

    const localizacao =
      [
        `E|${imovelBusca?.logradouro?.bairro?.cidade?.estado?.id}`,
        `C|${imovelBusca?.logradouro?.bairro?.cidade?.id}`,
        `B|${imovelBusca?.logradouro?.bairro?.id}`,
        `L|${imovelBusca?.logradouro.id}`
      ].join(",")

    const perfil =
    {
      id: props.id,
      consultaRedeLatitude: redeLatitude,
      operacaoImobiliaria: imovelBusca.operacaoImobiliaria,
      codigoImovel: Number(imovelBusca?.codImovel),
      tipoImovel: imovelBusca.tipoImovelId,
      valorMinimo: imovelBusca?.valorVenda
        ? Number(
          imovelBusca?.valorVenda.toString().replaceAll('.', '').replace(',', '.'),
        ).toFixed(2)
        : undefined,
      valorMaximo: imovelBusca?.valorVenda
        ? Number(
          imovelBusca?.valorVenda.toString().replaceAll('.', '').replace(',', '.'),
        ).toFixed(2)
        : undefined,
      valorCondominioMinimo: imovelBusca?.valorCondominio
        ? Number(
          imovelBusca?.valorCondominio
            .toString()
            .replaceAll('.', '')
            .replace(',', '.'),
        ).toFixed(2)
        : undefined,
      valorCondominioMaximo: imovelBusca?.valorCondominio
        ? Number(
          imovelBusca?.valorCondominio
            .toString()
            .replaceAll('.', '')
            .replace(',', '.'),
        ).toFixed(2)
        : undefined,
      numeroDormitorios: imovelBusca?.caracteristica?.quantidadeQuartos ? imovelBusca?.caracteristica?.quantidadeQuartos : 0,
      numeroSuites: imovelBusca?.caracteristica?.quantidadeSuites ? imovelBusca?.caracteristica?.quantidadeSuites : 0,
      numeroVagas: imovelBusca?.caracteristica?.vagasGaragem ? imovelBusca?.caracteristica?.vagasGaragem : 0,
      numeroBanheiros: imovelBusca?.caracteristica?.quantidadeBanheiros ? imovelBusca?.caracteristica?.quantidadeBanheiros : 0,
      areaMinima: imovelBusca?.caracteristica?.areaConstruida ? imovelBusca?.caracteristica?.areaConstruida : imovelBusca?.caracteristica?.areaTerreno,
      areaMaxima: imovelBusca?.caracteristica?.areaConstruida ? imovelBusca?.caracteristica?.areaConstruida : imovelBusca?.caracteristica?.areaTerreno,
      localizacao: localizacao,
      numeroPercentualMargemValor: numeroPercentualMargemValor[1],
    };
    props.perfil(perfil)
    if (perfil.id) {
      await UpdateAtendimentoPefil(perfil)
    }
    setVisible(false);
    close();
  }

  const { isSmall, isLarge, isMedium } = useDeviceScreen();
  const [isCollapsedSimpleFilter] = useState(false);

  const isRowMode = isSmall || isMedium || isLarge;

  const renderSimpleFilters = (
    <Transition
      className="mt-1"
      animation="scale"
      duration={350}>
      <Col className="filter">
        <Card
          style={{
            marginTop: '-15px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: 'none',
          }}>
          <div className="p-4">
            <CardBody className="mt-1">
              <Form form={formPerfilAtendimento} initialValues={initialValues || { "operacaoImobiliaria": 2 }} scrollToFirstError>
                <Row>
                  <Col xs="6">
                    <p className="heading-small text-muted mb-2">
                      <small>Operação</small>
                    </p>
                    <Form.Item className="my-2" name="operacaoImobiliaria">
                      <Radio.Group
                        className="simpleFiltersRadio"
                        defaultValue={2}
                        buttonStyle="solid">
                        {enumOperacaoImobiliaria.map(
                          (item) =>
                            item.id !== 3 && (
                              <Radio.Button
                                className="simpleFiltersRadioButton"
                                key={item.id}
                                value={item.id}>
                                {item.nome}
                              </Radio.Button>
                            ),
                        )}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs="6">
                    <p className="heading-small text-muted mb-2">
                      <small>Localização</small>
                    </p>
                    <Form.Item className="my-2" name="localizacao">
                      <Select
                        showArrow
                        allowClear
                        mode="multiple"
                        maxTagCount={10}
                        placeholder="Bairro, condomínio, cidade ou logradouro"
                        filterOption={false}
                        notFoundContent={
                          isLoadingCidadesDropdown ? (
                            <Spin indicator={antIcon} />
                          ) : null
                        }
                        onChange={handleChangeCidadesGrupos}
                        onSearch={handleCidadesGrupos}>
                        {dataCidadesGrupos.map(
                          (item) =>
                            item.options.length > 0 && (
                              <OptGroup key={item.label} label={item.label}>
                                {item.options.map((option) => (
                                  <Option
                                    key={option.id}
                                    value={
                                      item.label === 'Condomínios'
                                        ? `CC|${option.id}`
                                        : `${item.label.substring(0, 1)}|${option.id
                                        }`
                                    }>
                                    {option.label}
                                  </Option>
                                ))}
                              </OptGroup>
                            ),
                        )}
                      </Select>
                    </Form.Item>
                  </Col>

                </Row>
                {(isCollapsedSimpleFilter || !isRowMode) && (
                  <>
                    <Row>
                      <Col xs="6">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de Imóvel</small>
                        </p>
                        <Form.Item className="my-2" name="tipoImoveis">
                          <Select
                            showArrow
                            allowClear
                            mode="multiple"
                            maxTagCount={1}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              if (option?.children) {
                                if (option?.value === 'TODOS')
                                  return option?.children;

                                return (
                                  option?.children
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .indexOf(
                                      input
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, ''),
                                    ) >= 0
                                );
                              }
                            }}
                            placeholder=" Tipo de imóvel"
                            onChange={handleTipoImoveis}>
                            <Option key={0} value="TODOS">
                              Todos os imóveis
                            </Option>
                            {dataTipoImoveis.map(
                              (item) =>
                                item.options.length > 0 && (
                                  <OptGroup key={item.id} label={item.label}>
                                    {item.options.map((option) => (
                                      <Option key={option.id} value={option.id}>
                                        {option.label}
                                      </Option>
                                    ))}
                                  </OptGroup>
                                ),
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="3">
                        <p className="heading-small text-muted mb-2">
                          <small>Cód. do Imóvel</small>
                        </p>
                        <Form.Item className="my-2" name="codImovel">
                          <Input
                            placeholder=" Cód. do Imóvel"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="3">
                        <p className="heading-small text-muted mb-2">
                          <small>Rede Latitude</small>
                        </p>
                        <Form.Item name="consultaRedeLatitude" valuePropName="checked">
                          <Switch
                            defaultChecked={false}
                            onChange={handlerConsultaRedeLatitude}
                          />
                          <small
                            className="heading-small text-muted"
                            style={{ marginLeft: 5 }}>
                            Buscar na rede latitude
                          </small>
                        </Form.Item>
                      </Col>

                    </Row>

                    <Row>
                      <Col xs="4">
                        <p className="heading-small text-muted mb-0">
                          <small>
                            Valor em <b>R$</b>
                          </small>
                        </p>
                        <div className="mb-2 mt-0 simpleFilterGroupInput">
                          <div>
                            <p className="heading-small text-muted mb-0">
                              <small style={{ fontSize: '70%' }}>Mínimo</small>
                            </p>
                            <Form.Item className="mr-1 mb-0" name="valorVendaDe">
                              <Input
                                allowClear
                                placeholder=" Mínimo"
                                onChange={(e) => {
                                  handleMaskCurrency(e, 'valorVendaDe');
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <p className="heading-small text-muted mb-0 ml-1">
                              <small style={{ fontSize: '70%' }}>Máximo</small>
                            </p>
                            <Form.Item className="ml-1 mb-0" name="valorVendaAte">
                              <Input
                                allowClear
                                placeholder=" Máximo"
                                onChange={(e) => {
                                  handleMaskCurrency(e, 'valorVendaAte');
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col xs="4">
                        <p className="heading-small text-muted mb-0">
                          <small>
                            Valor do condomínio em <b>R$</b>
                          </small>
                        </p>
                        <div className="mb-2 mt-0 simpleFilterGroupInput">
                          <div>
                            <p className="heading-small text-muted mb-0">
                              <small style={{ fontSize: '70%' }}>Mínimo</small>
                            </p>
                            <Form.Item
                              className="mr-1 mb-0"
                              name="valorCondominioDe">
                              <Input
                                allowClear
                                placeholder=" Mínimo"
                                onChange={(e) => {
                                  handleMaskCurrency(e, 'valorCondominioDe');
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <p className="heading-small text-muted mb-0 ml-1">
                              <small style={{ fontSize: '70%' }}>Máximo</small>
                            </p>
                            <Form.Item
                              className="ml-1 mb-0"
                              name="valorCondominioAte">
                              <Input
                                allowClear
                                placeholder=" Máximo"
                                onChange={(e) => {
                                  handleMaskCurrency(e, 'valorCondominioAte');
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col xs="4">
                        <p className="heading-small text-muted mb-0">
                          <small>
                            Área em <b>M²</b>
                          </small>
                        </p>
                        <div className="mb-2 mt-0 simpleFilterGroupInput">
                          <div>
                            <p className="heading-small text-muted mb-0">
                              <small style={{ fontSize: '70%' }}>Mínimo</small>
                            </p>
                            <Form.Item className="mr-1 mb-0" name="areaDe">
                              <Input
                                placeholder=" Mínimo"
                                type="number"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <p className="heading-small text-muted mb-0 ml-1">
                              <small style={{ fontSize: '70%' }}>Máximo</small>
                            </p>
                            <Form.Item className="ml-1 mb-0" name="areaAte">
                              <Input
                                placeholder=" Máximo"
                                type="number"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Percentual para +/- do valor</small>
                        </p>
                        <Form.Item className="my-2" name="numeroPercentualMargemValor">
                          <Slider range min={0} max={100} step={5} onAfterChange={margemSlider} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Quartos</small>
                        </p>
                        <Form.Item className="my-2" name="dormitorios">
                          <Radio.Group
                            className="simpleFiltersRadio"
                            allowClear
                            optionType="button"
                            buttonStyle="solid">
                            {optionsSimple.map((item) => (
                              <Radio.Button
                                className="simpleFilterRadioButtonMulti"
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioQuartos}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Suítes</small>
                        </p>
                        <Form.Item className="my-2" name="suites">
                          <Radio.Group
                            className="simpleFiltersRadio"
                            allowClear
                            optionType="button"
                            buttonStyle="solid">
                            {optionsSimple.map((item) => (
                              <Radio.Button
                                className="simpleFilterRadioButtonMulti"
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioSuites}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Banheiros</small>
                        </p>
                        <Form.Item className="my-2" name="banheiros">
                          <Radio.Group
                            className="simpleFiltersRadio"
                            allowClear
                            optionType="button"
                            buttonStyle="solid">
                            {optionsSimple.map((item) => (
                              <Radio.Button
                                className="simpleFilterRadioButtonMulti"
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioBanheiros}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Vagas de garagem</small>
                        </p>
                        <Form.Item className="my-2" name="vagas">
                          <Radio.Group
                            className="simpleFiltersRadio"
                            allowClear
                            optionType="button"
                            buttonStyle="solid">
                            {optionsSimple.map((item) => (
                              <Radio.Button
                                className="simpleFilterRadioButtonMulti"
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioVagas}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
              <Divider />
              <Row>
                <Col xs="12" md="3">
                  <Button
                    className="my-2"
                    size="tiny"
                    icon
                    labelPosition="left"
                    fluid
                    isloading={loadButton.toString()}
                    loadtext="Buscando..."
                    onClick={() => onFiltros()}>
                    <Icon name="search" />
                    Buscar
                  </Button>
                </Col>

              </Row>
              <Row>
                <Col>
                  <Divider dashed />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <Transition
                    visible={visible}
                    animation="scale"
                    duration={500}>
                    <CardImovel
                      onPreview={(imovel) => onSelect(imovel)}
                      data={dataImovel}
                      clickEnabled={true}
                      isModalConsulta={isModalConsulta}
                    />
                  </Transition>
                </Col>
              </Row>
            </CardBody>
          </div>

        </Card>
      </Col>
    </Transition>
  );

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <ContentRow className="px-1">
              {renderSimpleFilters}
            </ContentRow>
          </Col>
        </Row>

      </Page>
    </Fragment>
  );
}

export default PerfilAtendimento;


