import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Input, Select, Tooltip } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';
import formatedReal from '~/shared/formatedReal';
import ModalLogradouro from '~/components/ModalLogradouro';


const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  GERAL: 1,
  IMAGENS: 2,
};

function CadastroGeral({
  dataLogradouros,
  onLoadLogradouros,
  dataPessoa,
  onLoadPessoa,
  dataTipoCondominio,
  generateInitialValue,
  initialValues,
  setActiveTab,
  setCondominioId: setCondominioIdAba,
  setDisabledTabs,
  modo,
  onVoltar,
  identificador,
}) {
  const [visibleForm, setVisibleForm] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [loadingEndereco, setLoadingEndereco] = useState(false);
  const [condominioId, setCondominioId] = useState(0);
  const [currentCep, setCurrentCep] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [modalLogradouroOpen, setModalLogradouroOpen] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (modo === MODO.EDITAR) {
      setCondominioId(initialValues.id);
      form.setFieldsValue({
        valor: initialValues.valor
          ? formatedReal(initialValues.valor.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
      });
    }

    if (initialValues?.logradouro) {
      onLoadLogradouros(initialValues?.logradouro?.nome);
      setTimeout(() => {
        setVisibleForm(true);
      }, 1500);
    } else {
      setVisibleForm(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function voltar() {
    if (onVoltar) {
      onVoltar();
    } else if (identificador) {
      history.push(`/app/condominios/${identificador}`);
    } else {
      history.push('/app/condominios');
    }
  }

  function redirectTab() {
    setActiveTab(TABS.IMAGENS);
  }

  function onSaveCadastroGeral() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            nome: dados.nome,
            logradouroId: dados.logradouroId,
            numero: dados.numero,
            complemento: dados.complemento,
            observacao: dados.observacao,
            valor: dados.valor
              ? Number(
                dados.valor.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            tipoCondominioId: dados.tipoCondominioId,
            sindicoId: dados.sindicoId,
          };

          await api
            .post(url.CONDOMINIO.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setDisabledTabs(false);
              setCondominioIdAba(res.data.id);
              generateInitialValue(res.data);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: condominioId,
            nome: dados.nome,
            logradouroId: dados.logradouroId,
            numero: dados.numero,
            complemento: dados.complemento,
            observacao: dados.observacao,
            valor: dados.valor
              ? Number(
                dados.valor.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            tipoCondominioId: dados.tipoCondominioId,
            sindicoId: dados.sindicoId,
          };

          await api
            .put(url.CONDOMINIO.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);
              generateInitialValue(res.data);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        const { errorFields } = errorInfo;
        setIsLoading(false);
        window.scrollTo(1, 1);
        if (errorFields) {
          const fieldName = errorFields[0]?.name[0];
          const fieldProps = form.getFieldInstance(fieldName);
          fieldProps.focus();
        }
      });
  }

  async function onSearchLogradouro(value) {
    await onLoadLogradouros(value);
  }

  async function onChangeLogradouro(logradouroId) {
    if (logradouroId) {
      const dataEndereco = dataLogradouros.find(
        (item) => item.id === logradouroId,
      );

      form.setFieldsValue({
        cep: dataEndereco.cep
          ? mask(dataEndereco.cep, [pattern.cep])
          : dataEndereco.cep,
        tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
        bairro: dataEndereco.bairro.nome,
        cidade: dataEndereco.bairro.cidade.nome,
        estado: dataEndereco.bairro.cidade.estadoId,
        pais: 'BRASIL',
      });
    } else {
      await onLoadLogradouros();
    }
  }

  async function handleEndereco(value, type) {
    let cep = value;
    cep = unMask(cep);
    cep = mask(cep, [type]);

    if (type === pattern.cep) form.setFieldsValue({ cep });

    if (cep.length === 10) {
      const unmaskedCEP = unMask(cep);
      setLoadingEndereco(true);
      await api
        .get(url.SERVICES.CONSULTA_CEP(unmaskedCEP))
        .then(async (res) => {
          const { data: dataEndereco, status } = res;

          if (status === 204) {
            Notify(
              'warning',
              pt.comum.atencao,
              'Nenhum logradouro encontrado com o CEP informado.',
            );
          } else if (dataEndereco) {
            await onLoadLogradouros(dataEndereco.nome);
            form.setFieldsValue({
              logradouroId: dataEndereco.id,
              cep: mask(dataEndereco.cep, [pattern.cep]),
              tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
              bairro: dataEndereco.bairro.nome,
              cidade: dataEndereco.bairro.cidade.nome.toUpperCase(),
              estado: dataEndereco.bairro.cidade.estadoId,
              pais: 'BRASIL',
            });
          }

          setLoadingEndereco(false);
        })
        .catch((err) => {
          setLoadingEndereco(false);
        });
    }
  }

  async function onChangeSindico(sindicoId) {
    if (sindicoId) {
      form.setFieldsValue({ sindicoId });
    } else {
      await onLoadPessoa({ tipo: 1 });
      form.setFieldsValue({ sindicoId: null });
    }
  }

  async function onSearchSindico(value) {
    await onLoadPessoa({ tipo: 1, nome: value });
  }

  function handleMaskCurrency(e, field) {
    const maskedValue = formatedReal(e.target.value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.'),
    ).toFixed(2);
    form.setFieldsValue({ [field]: numberValue > 0 ? maskedValue : undefined });
  }

  return (
    <div className="px-4 pb-4">
      <Row>
        <Col>
          {!visibleForm && <SkeletonTableBasic cardShadowless />}
          {visibleForm && (
            <>
              <Form
                form={form}
                initialValues={{
                  ...initialValues,
                  logradouroId: initialValues?.logradouroId,
                }}
                scrollToFirstError>
                <Row>
                  <Col xs="12" md="12">
                    <p className="heading-small text-muted mb-2">
                      <small>Nome</small>
                    </p>
                    <Form.Item
                      className="my-2"
                      name="nome"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o nome!',
                        },
                      ]}>
                      <Input placeholder=" Nome" />
                    </Form.Item>
                  </Col>
                </Row>
                {loadingEndereco && <SkeletonTableBasic cardShadowless />}
                {!loadingEndereco && (
                  <Fragment>
                    <Row>
                      <Col xs="12" md="5">
                        <p className="heading-small text-muted mb-2">
                          <small>Logradouro</small>
                        </p>
                        <Form.Item
                          className="my-2"
                          name="logradouroId"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o logradouro!',
                            },
                          ]}>
                          <Select
                            getPopupContainer={(node) => node.parentNode}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              if (option.children && option.children.length > 0) {
                                return (
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                );
                              }
                            }}
                            placeholder=" Logradouro"
                            allowClear
                            onSearch={onSearchLogradouro}
                            onChange={onChangeLogradouro}
                            onClear={onChangeLogradouro}>
                            {dataLogradouros.map((item) => (
                              <Option key={item.id}>{item.nome}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3">
                        <p className="heading-small text-muted mb-2">
                          <small>CEP</small>
                        </p>
                        <Form.Item className="my-2" name="cep">
                          <Input
                            placeholder=" CEP"
                            maxLength={10}
                            onChange={(e) => {
                              setCurrentCep(e.currentTarget.value);
                              handleEndereco(e.currentTarget.value, pattern.cep)
                            }}
                            rules={[
                              {
                                required: true,
                                message: 'Favor informar o CEP',
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="1">
                        <p className="heading-small text-muted mb-1 text-white">
                          <small>.</small>
                        </p>
                        <Tooltip
                          placement="topRight"
                          title={'Cadastrar Logradouro'}>
                          <Button
                            style={{ width: '100%' }}
                            onClick={() => setModalLogradouroOpen(true)}
                            icon
                            color="violet">
                            <Icon name="plus" />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="4">
                        <p className="heading-small text-muted mb-2">
                          <small>Bairro</small>
                        </p>
                        <Form.Item className="my-2" name="bairro">
                          <Input placeholder=" Bairro" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="4">
                        <p className="heading-small text-muted mb-2">
                          <small>Cidade</small>
                        </p>
                        <Form.Item className="my-2" name="cidade">
                          <Input placeholder=" Cidade" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="4">
                        <p className="heading-small text-muted mb-2">
                          <small>Estado</small>
                        </p>
                        <Form.Item className="my-2" name="estado">
                          <Input placeholder=" Estado" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de condomínio</small>
                        </p>
                        <Form.Item
                          className="my-2"
                          name="tipoCondominioId"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o tipo de condomínio!',
                            },
                          ]}>
                          <Select
                            getPopupContainer={(node) => node.parentNode}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                            }
                            placeholder=" Tipo de Condomínio"
                            allowClear>
                            {dataTipoCondominio.map((item) => (
                              <Option key={item.id}>{item.descricao}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="4">
                        <p className="heading-small text-muted mb-2">
                          <small>Número</small>
                        </p>
                        <Form.Item
                          className="my-2"
                          name="numero">
                          <Input placeholder=" Número" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="8">
                        <p className="heading-small text-muted mb-2">
                          <small>Complemento</small>
                        </p>
                        <Form.Item className="my-2" name="complemento">
                          <Input placeholder=" Complemento" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Fragment>
                )}
                <Row>
                  <Col xs="12" md="4">
                    <p className="heading-small text-muted mb-2">
                      <small>Valor</small>
                    </p>
                    <Form.Item
                      className="my-2"
                      name="valor">
                      <Input
                        placeholder=" Valor"
                        onChange={(e) => handleMaskCurrency(e, 'valor')}
                        prefix="R$"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs="12" md="8">
                    <p className="heading-small text-muted mb-2">
                      <small>Síndico(a)</small>
                    </p>
                    <Form.Item className="my-2" name="sindicoId">
                      <Select
                        getPopupContainer={(node) => node.parentNode}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                        }
                        placeholder=" Síndico(a)"
                        allowClear
                        onSearch={onSearchSindico}
                        onClear={onChangeSindico}>
                        {dataPessoa.map((item) => (
                          <Option key={item.id}>{item.nome}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12">
                    <p className="heading-small text-muted mb-2">
                      <small>Observação</small>
                    </p>
                    <Form.Item className="my-2" name="observacao">
                      <Input.TextArea
                        placeholder=" Observação"
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        maxLength={2000}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row className="mt-4">
                <Col xs="12" md="3">
                  <Button
                    onClick={onSaveCadastroGeral}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    color="violet"
                    isloading={loading.toString()}
                    loadtext={
                      modo === MODO.CADASTRAR ? 'Cadastrando...' : 'Atualizando...'
                    }>
                    <Icon name="check" />
                    {modo === MODO.CADASTRAR ? 'Cadastrar' : 'Atualizar'}
                  </Button>
                </Col>
                <Col xs="12" md="3">
                  <Button
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    onClick={() => voltar()}>
                    <Icon name="undo" />
                    Voltar
                  </Button>
                </Col>
              </Row>
            </>
          )}

          <ModalConfirm
            isDelete={false}
            icon="check circle"
            open={openModalConfirm}
            close={redirectTab}
            message={modo === MODO.CADASTRAR ? 'Cadastrado com sucesso' : 'Alterações salvas'}
          />
        </Col>
      </Row>

      <ModalLogradouro
        open={modalLogradouroOpen}
        close={() => {
          setModalLogradouroOpen(false);
          if (currentCep)
            handleEndereco(currentCep, pattern.cep);
        }}
      />
    </div>
  );
}

export default CadastroGeral;
