import React, { useEffect, useState, Fragment } from 'react';

import DashboardCorretor from './components/DashboardCorretor';
import DashboardGerente from './components/DashboardGerente';
import HomeLoading from '~/components/HomeLoading';

function DashboardType({ type }) {
  console.log('DashboardType', type);
  switch (type) {
    case 'CORRETOR':
      return <DashboardCorretor />;
    case 'GERENTE':
      return <DashboardGerente />;
    case 'MASTER':
      return <DashboardGerente isDiretorOrMaster={true} />;
    case 'DIRETOR':
      return <DashboardGerente isDiretorOrMaster={true} />;
    case 'ADMMATRIZ':
      return <DashboardGerente isDiretorOrMaster={true} />;
    default:
      return <DashboardCorretor />;
  }
}

function Home() {
  const [dashType, setDashboardType] = useState(null);

  let roles = JSON.parse(localStorage.getItem('icaseRoles'));

  useEffect(() => {
    roles.forEach((item) => {
      const sanitized = String(item).toUpperCase();
      switch (sanitized) {
        case 'ADMINISTRADOR':
          setDashboardType('ADMINISTRADOR');
          break;
        case 'CORRETOR':
          setDashboardType('CORRETOR');
          break;
        case 'GERENTE':
          setDashboardType('GERENTE');
          break;
        case 'MASTER':
          setDashboardType('MASTER');
          break;
        case 'DIRETOR':
          setDashboardType('DIRETOR');
          break;
        case 'ADMMATRIZ':
          setDashboardType('ADMMATRIZ');
          break
        default:
          setDashboardType(null);
      }
    });
  }, [roles]);

  return (
    <Fragment>
       {dashType === null ? <HomeLoading /> : <DashboardType type={dashType} />}
    </Fragment>
  );
}

export default Home;

