import React, {Fragment} from 'react';
import {Modal, Image, Button, Icon} from 'semantic-ui-react';
import newLead from '~/assets/img/tag.svg';

function ModalUsuarioAssociado({open, close}) {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => close()}>
        <Modal.Content image>
          <Image size="small" src={newLead} wrapped />
          <div className="d-flex flex-column justify-content-center align-items-center p-3">
            <h5 className="mt-2 font-weight-light">
              Este login não conseguirá realizar todas as operações pois não
              possui usuário associado !
            </h5>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button icon labelPosition="left" onClick={() => close()}>
            <Icon name="times" />
            ok, entendi
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}

export default ModalUsuarioAssociado;
