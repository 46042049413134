/* eslint-disable */
import React from 'react';
import { Button } from 'antd';

import history from '~/services/history';

function CardFooter() {
  function esqueciSenha() {
    history.push('/esqueci-senha');
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button type="link" id="latitude-forgot" onClick={esqueciSenha}>
        Esqueci minha senha
      </Button>
    </div>
  );
}

export default CardFooter;
