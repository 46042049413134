import {useState, useCallback, useMemo, useEffect} from 'react';

import api from '~/services/api';

export default function useDaysWithoutSelling(mainPeriod, broker, brokerGroup, initialDate, finalDate) {
  // nomeCorretor: string;
  // dias: number;
  // meta: number;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(async () => {
    try {
      await api
        .get(`Dashboard/gerente/dias-sem-vender`, {
          params: {
            periodoPrincipal: mainPeriod,
            corretorId: broker,
            grupoCorretoresId: brokerGroup,
            dataIni: initialDate,
            dataFim: finalDate,
          },
        })
        .then((res) => {
          const items = res?.data?.data;

          if (!items || items.length === 0) {
            setData([]);
            setIsLoading(false);
            return;
          }

          setData(() => {
            return (
              items
                ?.sort((a, b) => a.dias[0] - b.dias[0])
                .map((item) => ({
                  nomeCorretor: item?.nomeCorretor || 'Corretor sem nome',
                  dias: [item?.dias > 120 ? 120 : item?.dias],
                  meta: 29,
                  ranges: [30, 90, 120],
                })) || []
            );
          });
          setIsLoading(false);
        });
    } catch (e) {
      setData([]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [mainPeriod, broker, brokerGroup, initialDate, finalDate]);

  const config = useMemo(() => {
    return {
      data,
      measureField: 'dias',
      rangeField: 'ranges',
      targetField: 'meta',
      xField: 'nomeCorretor',
      color: {
        range: ['#a3fb9e', '#f4dfa8', '#f69194'],
        measure: '#5B8FF9',
        target: '#39a3f4',
      },
      label: {
        measure: {
          position: 'middle',
          style: {
            fill: '#fff',
          },
        },
      },
      xAxis: {
        line: null,
      },
      yAxis: false,
      legend: {
        custom: true,
        position: 'bottom',
        items: [
          {
            value: 'Normal',
            name: 'Normal',
            marker: {
              symbol: 'square',
              style: {
                fill: '#a3fb9e',
                r: 5,
              },
            },
          },
          {
            value: 'Atenção',
            name: 'Atenção',
            marker: {
              symbol: 'square',
              style: {
                fill: '#f4dfa8',
                r: 5,
              },
            },
          },
          {
            value: 'Alerta',
            name: 'Alerta',
            marker: {
              symbol: 'square',
              style: {
                fill: '#f69194',
                r: 5,
              },
            },
          },
          {
            value: 'Dias sem vender',
            name: 'Dias sem vender',
            marker: {
              symbol: 'square',
              style: {
                fill: '#5B8FF9',
                r: 5,
              },
            },
          },
          {
            value: 'Meta',
            name: 'Meta',
            marker: {
              symbol: 'line',
              style: {
                stroke: '#39a3f4',
                r: 5,
              },
            },
          },
        ],
      },
    };
  }, [data]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    isLoading,
    config,
    data
  };
}

