import React from "react";
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { mask } from 'remask';

import Header from './Header';
import Footer from './Footer';
import { config } from "../commom/config";

import pattern from '~/infra/resources/pattern/mask';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    color: config.textColor,
    fontFamily: config.fontFamily,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '100px 0 40px',
  },
  image: {
    width: 130,
    height: 130,
    marginRight: 12,
    borderRadius: 4,
  },
  containerCorretor: {
    display: 'flex',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  textBold: {
    fontSize: 16,
    fontWeight: config.fontWeight,
  },
  text: {
    fontSize: 16,
  },
});

const FrontPage = ({ cliente, celular, telefone, corretor, empresa, message }) => (
  <Page size="A4" style={styles.page}>
    <Header logoEmpresaUrl={corretor?.logoEmpresaUrl} />
    <View style={styles.container}>
      {corretor?.imagemUrl && (
        <Image source={corretor?.imagemUrl} style={styles.image}></Image>
      )}
      <View style={styles.containerCorretor}>
        <View style={[styles.containerText, { marginBottom: 12 }]}>
          <Text style={styles.textBold}>Corretor: </Text>
          <Text style={styles.text}>{corretor?.nomeCompleto || ''}</Text>
        </View>
        <View style={[styles.containerText, { marginBottom: 12 }]}>
          <Text style={styles.textBold}>Creci: </Text>
          <Text style={styles.text}>{corretor?.creci || ''}</Text>
        </View>
        <View style={[styles.containerText, { marginBottom: 12 }]}>
          <Text style={styles.textBold}>Telefone: </Text>
          <Text style={styles.text}>{mask(corretor?.telefone || corretor?.celular || '', [pattern.telefone])}</Text>
        </View>
        <View style={styles.containerText}>
          <Text style={styles.textBold}>Email: </Text>
          <Text style={styles.text}>{corretor?.email || ''}</Text>
        </View>
      </View>
    </View>

    {cliente && (
      <View style={[styles.ViewFlex, { marginBottom: 110 }]}>
        <View style={[styles.containerText, { marginBottom: 12 }]}>
          <Text style={styles.textBold}>Cliente: </Text>
          <Text style={styles.text}>{cliente?.nome || ''}</Text>
        </View>
        <View style={[styles.containerText, { marginBottom: 12 }]}>
          <Text style={styles.textBold}>Email: </Text>
          <Text style={styles.text}>{cliente?.email || cliente?.email2 || cliente?.email3 || ''}</Text>
        </View>
        <View style={styles.containerText}>
          <Text style={styles.textBold}>Telefone: </Text>
          <Text style={styles.text}>{
            telefone?.numero
              ? telefone?.ddi === 55 || telefone?.ddi === null
                ? mask(telefone?.numero, [pattern.telefone])
                : `+${telefone?.ddi} ${telefone?.numero}`
              : celular?.numero
                ? celular?.ddi === 55 || celular?.ddi === null
                  ? mask(celular?.numero, [pattern.celular])
                  : `+${celular?.ddi} ${celular?.numero}`
                : ''
          }</Text>
        </View>
      </View>
    )}

    {message && (
      <View style={[styles.containerText, { marginBottom: 145 }]}>
        <Text style={styles.text}>{message}</Text>
      </View>
    )}

    <Footer corretor={corretor} empresa={empresa} />
  </Page>
);

export default FrontPage;
