
import React from 'react';
//import { Tabs } from 'antd';
import Geral from './components/geral';
//import Distribuicao from './components/distribuicao/distribuicao';
//import { SettingOutlined, PullRequestOutlined } from '@ant-design/icons';
// import FacebookLoginRedirect from './components/distribuicao/components/facebookLoginRedirect';

/* const onChange = (key) => {
  console.log(key);
};

const items = [
  {
    key: '1',
    label: (
      <span>
        <SettingOutlined/>
        Geral
      </span>
    ),
    children: <Geral />,
  },
  {
    key: '2',
    label: (
      <span>
        <PullRequestOutlined />
        Distribuição de Leads
      </span>
    ),
    children: <Distribuicao />,
  },
  // {
  //   key: '3',
  //   label: (
  //     <span>
  //       <FacebookOutlined />
  //       Distribuição Meta (Facebook)
  //     </span>
  //   ),
  //   children: <FacebookLoginRedirect />,
  // }
];

*/

function Configuracoes() {
  return <Geral />
}

export default Configuracoes;