function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {
      console.error('Erro ao copiar telefone: ', err);
    },
  );
}

export default copyToClipboard;

