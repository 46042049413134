import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useGrupoCorretores(grupo, descricao, tipoGrupoCorretoresId, abortPreload) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      const filterGrupo = grupo ? grupo : null;
      const filterDescricao = descricao ? descricao : null;
      const filterIipoGrupoCorretoresId = tipoGrupoCorretoresId ? tipoGrupoCorretoresId : null;
      let filter = '';

      if (!!params?.empresas && params.empresas.length > 0) filter = '?' + params.empresas?.map(guid => `empresasId=${encodeURIComponent(guid)}`).join('&');

      if (filterGrupo && !params.grupo) {
        if (filter.length > 0) filter += `&grupo=${filterGrupo}`;
        else filter = `?grupo=${filterGrupo}`;
      }

      if (params.grupo) {
        if (filter.length > 0) filter += `&grupo=${params.grupo}`;
        else filter = `?grupo=${params.grupo}`;
      }

      if (filterDescricao && !params.descricao) {
        if (filter.length > 0) filter += `&descricao=${filterDescricao}`;
        else filter = `?descricao=${filterDescricao}`;
      }

      if (params.descricao) {
        if (filter.length > 0) filter += `&descricao=${params.descricao}`;
        else filter = `?descricao=${params.descricao}`;
      }

      if (filterIipoGrupoCorretoresId && !params.filterIipoGrupoCorretoresId) {
        if (filter.length > 0) filter += `&tipoGrupoCorretoresId=${filterIipoGrupoCorretoresId}`;
        else filter = `?tipoGrupoCorretoresId=${filterIipoGrupoCorretoresId}`;
      }

      if (params.filterIipoGrupoCorretoresId) {
        if (filter.length > 0) filter += `&tipoGrupoCorretoresId=${params.filterIipoGrupoCorretoresId}`;
        else filter = `?tipoGrupoCorretoresId=${params.filterIipoGrupoCorretoresId}`;
      }

      try {
        await api.get(`${url.DROPDOWN.GRUPO_CORRETORES()}${filter}`)
          .then(res => {
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [grupo, descricao, tipoGrupoCorretoresId]
  );

  useEffect(() => {
    if (abortPreload)
      return;

    const params = { grupo, descricao, tipoGrupoCorretoresId };
    onLoad(params);
  }, [grupo, descricao, tipoGrupoCorretoresId, onLoad, abortPreload]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
