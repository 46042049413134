import React from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Select, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { camposFacebook, camposLatitude } from '~/infra/resources/strings/enums/enumDistribuicaoLeads';

const { Option } = Select;


function MapeamentoCampos({ finalValues }) {

  let remapCamposFacebook = [...(finalValues?.mapeamentoCampos?.map(c => ({
    label: c.campoFormulario,
    value: c.campoFormulario
  })) || []), ...camposFacebook];

  remapCamposFacebook = remapCamposFacebook.filter((element, index, array) =>
    index === array.findIndex((t) => (
      t?.label === element?.label
    ))
  );

  const filterOption = (input, option) => option?.children
    ?.toLowerCase()
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.indexOf(
      input
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
    ) >= 0;

  return <Row className="p-4">
    <Col xs="12" md="12">
      <Form.List name="mapeamentoCampos">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key}>
                <Col xs="5" md="5">
                  <Form.Item
                    {...restField}
                    name={[name, 'campoFormulario']}
                    rules={[{ required: true, message: 'Informe um campo do Facebook' }]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder=" Informe um campo do Facebook"
                      allowClear>
                      {remapCamposFacebook.map((item) => (
                        <Option
                          disabled={item.disabled}
                          key={item.value}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="5" md="5">
                  <Form.Item
                    {...restField}
                    name={[name, 'campoLatitude']}
                    rules={[{ required: true, message: 'Informe um campo do Latitude' }]}
                  >
                    <Select
                      showSearch
                      filterOption={filterOption}
                      placeholder=" Informe um campo do Latitude"
                      allowClear>
                      {camposLatitude.map((item) => (
                        <Option
                          disabled={item.disabled}
                          key={item.value}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs="1" md="1">
                  <MinusCircleOutlined className="pt-2" onClick={() => remove(name)} />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Adicionar Mapeamento
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Col>
  </Row>;
}

export default MapeamentoCampos;

