import React, { Fragment, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
// import SkeletonTable from '~/components/SkeletonTableBasic';;

function SiteExterno(props) {
  // const [loadingIframe, setLoadingIframe] = useState(true);

  const userCpf = localStorage.getItem('icaseUserCpf');

  let urlExterna = props?.match?.params?.urlExterna;

  if (urlExterna?.includes('{userCpf}'))
    urlExterna = urlExterna.replace('{userCpf}', userCpf);


  const elemRef = useCallback((node) => {
    if (node !== null) {
      const latitudeToken = localStorage.getItem('icaseToken');
      
      node.onload = () => {
        //   setLoadingIframe(false);

        if (localStorage.getItem('externalTokenSetted') !== latitudeToken) {
          setTimeout(() => {
            // eslint-disable-next-line no-self-assign
            node.src = node.src;
          }, 500);
        }

        localStorage.setItem('externalTokenSetted', latitudeToken);
        return node?.contentWindow?.postMessage({ latitudeToken }, '*');
      };
    }
  }, [])

  //recebe mensagem do iframe
  window.addEventListener('message', (event) => {
    if (event.data === 'Token não recebido') {
      window.location.reload();
    }
  });

  return (
    <Fragment>
      <Row>
        <Col xs="12">
          {/* {loadingIframe && (<SkeletonTable lineNumber={20} />)} */}
          <iframe
            ref={elemRef}
            id='iframe-externo'
            title='Exibição de site externo'
            style={{
              // opacity: loadingIframe ? 0 : 1,
              width: '100%',
              height: 'calc(100vh - 120px)',
              border: 'none'
            }}
            src={decodeURIComponent(urlExterna)}
            loading="lazy"></iframe>
        </Col>
      </Row>
    </Fragment>
  );
}

export default SiteExterno;
