import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import {
  Collapse,
  Divider,
  Tooltip,
  Image,
  Form,
  Select,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Tag,
} from 'antd';
import {
  CaretDownOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Icon } from 'semantic-ui-react';
import { mask } from 'remask';
import { decryptAll } from '~/infra/resources/pattern/cripto';

import Button from '~/shared/Button';
import Page from '~/components/Page';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CircularProgress from '~/components/HomeLoading';
import ModalGenerico from '~/components/ModalGenerico';
import ModalBusca from '~/components/ModalBuscaImoveis';
import ModalConfirm from '~/components/ModalConfirm';

import Pessoa from '~/views/private/Cadastros/Pessoa/Manutencao';
import TipoCliente from '~/views/private/Cadastros/TipoAtendimento/Manutencao';
import Plantao from '~/views/private/Cadastros/Plantao/Manutencao';
import RegistroAtendimento from './Components/RegistroAtendimento';
import AgendarVisita from './Components/AgendarVisita';
import Proposta from './Components/Proposta';
import HistoricoImoveis from './Components/HistoricoImoveis';
import ImovelSimplificado from '~/components/ImovelSimplificado';
import useConfigSistema from '~/actions/Empresa/useConfigSistema';
import * as configConst from '~/infra/config/configSistemaConst';

import './index.css';

import usePlantao from '~/actions/Plantoes/usePlantoes';
import usePessoaDropdown from '~/actions/DropDown/usePessoa';
import useTipoAtendimento from '~/actions/TipoAtendimento/useTipoAtendimento';

import statusAtendimento from '~/infra/resources/strings/enums/statusAtendimento';
import origemImovel from '~/infra/resources/strings/enums/origemImovel';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import history from '~/services/history';
import api from '~/services/api';
import imgImovel from '~/assets/img/sala.jpg';
import Notify from '~/shared/Notify';
import formatCurrency from '~/shared/formatCurrency';
import pattern from '~/infra/resources/pattern/mask';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import useQueryParams from '~/hooks/use-query-params';
import PerfilAtendimento from './Components/PerfilAtendimento';
import useCidadesDropdownGrupos from '~/actions/DropDown/useCidadesDropdownGrupos';
import useTipoImovel from '~/actions/DropDown/useTipoImovel';
import AtendimentoPerdido from './Components/AtendimentoPerdido';
import useTipoMidiaDropdown from '~/actions/DropDown/useTipoMidiaDropdown';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import TimelineRelacionamentos from './Components/TimelineRelacionamentos';
moment.locale('pt-BR');

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
  VISUALIZAR: 'visualizar',
};

const TYPE = {
  RELACIONAMENTO: 'C',
  VISITA: 'V',
  PROPOSTA: 'P',
};

const THERMOMETER = {
  CONGELADO: 1,
  FRIO: 2,
  MORNO: 3,
  QUENTE: 4,
  FERVENDO: 5,
};

const TYPEOFREFUSE = {
  REINICIAR: 'reiniciar',
  ENCERRAR: 'encerrar',
};

const { Option } = Select;
const today = new Date();
const dateFormat = 'DD/MM/YYYY';

let valueOfClient,
  cliente,
  corretor,
  proximoContato,
  listStatusAgrupado,
  statusAgrupado = undefined;
let nameOfClient,
  statusAtendimentoName = '';
let selectedCodImovel;
let structuredProfiles = [];

export async function transferAtendimento(
  atendimentoId,
  corretorId,
  clienteId,
) {
  const payload = {
    atendimentoId,
    corretorId,
    clienteId,
  };

  await api.put(url.ATENDIMENTO.TRANSFERIR(), payload).catch((err) => {
    const { errors } = err.response.data;
    if (errors) {
      for (const item in errors) {
        Notify('error', pt.comum.atencao, errors[item]);
      }
    }
  });
}

function Atendimentos(props) {

  const [loadPage, setLoadPage] = useState(true);
  const [loadingNewClient, setLoadingNewClient] = useState(false);
  const [loadingSelectedClient, setLoadingSelectedClient] = useState(false);
  const [loadingFilterImovel, setLoadingFilterImovel] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [actions, setActions] = useState([]);
  const [modo, setModo] = useState('');
  const [modoRelacionamento, setModoRelacionamento] = useState(MODO.CADASTRAR);
  const [dataAtendimento, setDataAtendimento] = useState([]);
  const [atendimentoId, setAtendimentoId] = useState(null);
  const [eventoId, setEventId] = useState();
  const [interesseId, setInteresseId] = useState();
  const [propostaId, setPropostaId] = useState(undefined);
  const [perfilAtendimento, setPerfilAtendimento] = useState();
  const [filterNomeTipoAtendimento, setFilterNomeTipoAtendimento] =
    useState('');
  const [filterNomePlantao, setFilterNomePlantao] = useState('');
  const [identificador, setIdentificador] = useState();
  const [novoClienteId, setNovoClienteId] = useState();
  const [novoCorretorId, setNovoCorretorId] = useState(undefined);
  const [modoPessoa, setModoPessoa] = useState('cadastrar');
  const [openModalSelectedClient, setOpenModalSelectedClient] = useState(false);
  const [openModalTipoCliente, setOpenModalTipoCliente] = useState(false);
  const [openModalPlantao, setOpenModalPlantao] = useState(false);
  const [openModalPessoa, setOpenModalPessoa] = useState(false);
  const [openModalPerfilAtendimento, setOpenModalPerfilAtendimento] =
    useState(false);
  const [openModalImovel, setOpenModalImovel] = useState(false);
  const [openModalAtendimento, setOpenModalAtendimento] = useState(false);
  const [openModalAgendarVisita, setOpenModalAgendarVisita] = useState(false);
  const [openModalProposta, setOpenModalProposta] = useState(false);
  const [openModalHistorico, setOpenModalHistorico] = useState(false);
  const [openModalImovelSimplificado, setOpenModalImovelSimplificado] =
    useState(false);
  const [openModalNegocioFechado, setOpenModalNegocioFechado] = useState(false);
  const [openModalNegocioRecusado, setOpenModalNegocioRecusado] =
    useState(false);
  const [openModalConfirmImovel, setOpenModalConfirmImovel] = useState(false);
  const [openModalClienteEmAtendimento, setOpenModalClienteEmAtendimento] =
    useState(false);
  const [selectedImovelId, setSelectedImovelId] = useState();
  const [tipoDoNegocioRecusado, setTipoDoNegocioRecusado] = useState(
    TYPEOFREFUSE.REINICIAR,
  );
  const [selectedImovel, setSelectedImovel] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedThermometer, setSelectedThermometer] = useState();
  const [selectedReasonMissedService, setSelectedReasonMissedService] = useState();
  const [dataRelacionamentos, setDataRelacionamentos] = useState([]);
  const [selectedOrigemImovel, setSelectedOrigemImovel] = useState('1');
  const [showButtonVisita, setShowButtonVisita] = useState(true);
  const [showButtonProposta, setShowButtonProposta] = useState(true);
  const [isAtendimentoFinalizado, setIsAtendimentoFinalizado] = useState(false);
  const { checkConfig, data: dataConfig } = useConfigSistema();
  const [openModalPerdido, setOpenModalPerdido] = useState(false);
  const { data: tiposMidia } = useTipoMidiaDropdown();
  const [lastProposta, setLastProposta] = useState();

  const { data: plantao } = usePlantao(1, 99999999, filterNomePlantao);
  const { data: corretores } = useCorretorDropdown(false);
  const { data: dataCidadesGrupos, onLoad: onLoadCidadesGrupos } =
    useCidadesDropdownGrupos();
  const { data: dataTipoImoveis } = useTipoImovel();
  const {
    data: pessoas,
    onLoad: onLoadPessoas,
    cancelRequest,
  } = usePessoaDropdown();
  const { data: dataTipoAtendimento } = useTipoAtendimento(
    1,
    99999,
    filterNomeTipoAtendimento,
  );

  const encryptedProfiles = localStorage.getItem('latitudeProfiles');
  if (encryptedProfiles) {
    const decryptProfiles = decryptAll(encryptedProfiles);
    structuredProfiles = JSON.parse(decryptProfiles);
  }

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isMaster =
    roles.some((role) => role.toUpperCase() === 'MASTER') ||
    structuredProfiles.some(
      (profile) => profile?.perfil?.descricao.toUpperCase() === 'GERENTE',
    );
  const isGerente = roles.some((x) => x.toUpperCase() === 'GERENTE');
  const isDiretor = roles.some(
    (x) => x.toUpperCase() === 'MASTER' || x.toUpperCase() === 'DIRETOR' || x.toUpperCase() === 'ADMMATRIZ',
  );

  const isMasterLead = JSON.parse(localStorage.getItem('icaseMasterLeads'));
  const hasSelectCorretor = (isMasterLead || isMaster) && corretores;
  const isCorretor = !!roles.find((role) => role.toUpperCase() === 'CORRETOR');
  const usuarioId = localStorage.getItem('icaseUsuarioId');
  const queryParams = useQueryParams();

  const [form] = Form.useForm();
  const [isMissingProperty, setIsMissingProperty] = useState(false);
  const [isEditingProposal, setIsEditingProposal] = useState(false);
  const [textoAviso, setTextoAviso] = useState('');

  async function fetchRelacionamentos(atendimentoId, imovelId) {
    await api
      .get(url.ATENDIMENTO.RELACIONAMENTOS(atendimentoId))
      .then((res) => {
        const dataRelacionamentos = res?.data;
        setDataRelacionamentos(dataRelacionamentos || []);
      })
      .catch(() => {
        setDataRelacionamentos([]);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const enableDisableBrokerInput = (dataAtendimento) => {
    if (
      !!dataAtendimento?.corretorId &&
      dataAtendimento?.tipoMidia === 109 &&
      (!isMaster || !checkConfig(configConst.PERMITE_TRANSF_CARTEIRA_PROPRIA))
    ) {
      setCanChangeBroker(false);
    } else {
      setCanChangeBroker(true);
    }
  };

  async function fetch(id) {
    setLoadPage(true);

    await api.get(url.ATENDIMENTO.FILTER_BY_ID(id)).then(async (res) => {
      const dataAtendimento = res?.data;

      const ativesPropostas = dataAtendimento?.propostas && dataAtendimento?.propostas?.length > 0 ? dataAtendimento.propostas.reduce((latest, current) => {
        return current?.dataCriacao > latest?.dataCriacao ? current : latest;
      }) : null;

      const lastProposta = ativesPropostas?.status !== 3 ? ativesPropostas : null;

      cancelRequest();
      await onLoadPessoas({ nome: dataAtendimento?.cliente?.nome });


      setNovoClienteId(dataAtendimento?.clienteId);
      setSelectedClient(dataAtendimento?.cliente);
      setSelectedImovel(dataAtendimento?.imovel);
      setSelectedThermometer(dataAtendimento?.termometro);
      setSelectedReasonMissedService(dataAtendimento?.motivoDesistenciaAtendimento)
      setPerfilAtendimento(dataAtendimento?.atendimentoPerfil);
      setPerfilAtendimento(dataAtendimento?.atendimentoPerfil);
      await onLoadCidadesGrupos(
        dataAtendimento?.atendimentoPerfil?.localizacao,
      );
      form.setFieldsValue({
        novoCorretorId: dataAtendimento?.corretorId,
        codigo: dataAtendimento?.imovel?.codImovel,
        tipoAtendimentoId: dataAtendimento?.tipoAtendimentoId,
        tipoMidia: dataAtendimento?.tipoMidia
          ? String(dataAtendimento?.tipoMidia)
          : undefined,
        plantao: dataAtendimento?.plantaoId,
        situacao: dataAtendimento?.situacao,
        dataCriacao: dataAtendimento?.dataCriacao
          ? moment(dataAtendimento?.dataCriacao.toString())
          : moment(today, 'YYYY-MM-DD'),
        status: dataAtendimento?.status,
        observacao: dataAtendimento?.obs,
        campanha: dataAtendimento?.campanha,
        formulario: dataAtendimento?.formulario,
        pagina: dataAtendimento?.pagina,
      });

      if (dataAtendimento.status === 9) setShowButtonVisita(false);

      const propostas = dataAtendimento?.propostas;
      if (propostas.length > 0 && dataAtendimento?.status !== 3) {
        setIsAtendimentoFinalizado(lastProposta?.imovelId === dataAtendimento?.imovelId && lastProposta?.status === 2);
      }

      /* ATENDIMENTO DESISTÊNCIA */
      if (dataAtendimento?.status === 3) {
        setIsAtendimentoFinalizado(true);
      }
      /* ####################### */

      setDataAtendimento(dataAtendimento || []);

      await fetchRelacionamentos(id, dataAtendimento?.imovelId);

      setLastProposta(lastProposta);

      if (!!lastProposta) {
        setPropostaId(lastProposta?.id);

        setIsEditingProposal(dataAtendimento.status === 10);
        setOpenModalProposta(dataAtendimento.status === 10);
      }
      else {
        setIsEditingProposal(false);
        setPropostaId(null);
      }

      setShowButtonProposta(!lastProposta);

    }).finally(() => setLoadPage(false));
  }

  useEffect(() => {
    if (dataConfig?.length > 0 && dataAtendimento)
      enableDisableBrokerInput(dataAtendimento);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConfig, dataAtendimento]);

  useEffect(() => {
    onLoadCidadesGrupos(perfilAtendimento?.localizacao);
  }, [onLoadCidadesGrupos, perfilAtendimento]);

  useEffect(() => {
    if (props?.match?.params) {
      if (props?.match?.params?.modo === MODO.EDITAR) {
        setModo(MODO.EDITAR);

        const id = props?.location?.state?.id || queryParams.get('id');
        const identificador =
          props?.location?.state?.identificador ||
          queryParams.get('identificador');

        if (identificador) setIdentificador(identificador);

        if (id) {
          setAtendimentoId(id);
          fetch(id);
        }

        const hasValidUser = corretores?.find(
          (y) => y.id === dataAtendimento?.corretorId,
        );

        if (hasValidUser !== undefined) {
          setNovoCorretorId(hasValidUser?.id);
        }
      } else {
        const identificador = props?.location?.state?.identificador;
        if (identificador) setIdentificador(identificador);

        setModo(MODO.CADASTRAR);
        if (corretores && pessoas) {
          setLoadPage(false);
        }
      }

      const { actions } = getActionsMenus(identificador);

      setActions(actions || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSave(ignoreMotivo, payloadDesistencia) {
    setLoadingSave(true);

    form
      .validateFields()
      .then(async (dados) => {
        if (!novoClienteId) {
          setLoadingSave(false);

          if (!novoClienteId)
            Notify('error', pt.comum.atencao, 'Favor selecionar um cliente!');
        } else {
          const payload = {
            id: atendimentoId ? atendimentoId : undefined,
            corretorId: novoCorretorId
              ? novoCorretorId
              : isCorretor
                ? usuarioId
                : dados?.novoCorretorId,
            dataUltimoAtendimento: dados.dataCriacao
              ? moment(dados.dataCriacao, 'YYYY-MM-DD')
              : moment(today, 'YYYY-MM-DD'),
            descricaoUltimoAtendimento: '.',
            tipoAtendimentoId: dados.tipoAtendimentoId,
            situacao: dados.situacao,
            status: Number(dados.status),
            tipoMidia: Number(dados.tipoMidia),
            clienteId: novoClienteId,
            plantaoId: dados.plantao,
            termometro: selectedThermometer,
            motivoDesistenciaAtendimento: selectedReasonMissedService,
            imovelId: selectedImovel?.id ? selectedImovel?.id : undefined,
            observacao: dados?.observacao,
            perfilAtendimento,
            motivoDesistencia: payloadDesistencia?.motivo,
            justificativaDesistencia: payloadDesistencia?.justificativa,
            campanha: dados?.campanha,
            formulario: dados?.formulario,
            pagina: dados?.pagina,
          };

          if (modo === MODO.CADASTRAR) {
            await api
              .post(url.ATENDIMENTO.CADASTRO_GERAL(), payload)
              .then(async (res) => {
                const recentAtendimentoId = res?.data?.data?.id;

                setLoadingSave(false);

                setAtendimentoId(recentAtendimentoId);
                Notify('success', '', pt.geral.cadastro_com_sucesso);
                setModo(MODO.EDITAR);

                if (novoCorretorId) {
                  transferAtendimento(
                    recentAtendimentoId,
                    novoCorretorId,
                    novoClienteId,
                  );
                }
              })
              .catch((err) => {
                setLoadingSave(false);
                const { error } = err.response.data;
                if (error) {
                  if (error.length > 0) {
                    for (const item in error) {
                      Notify('error', pt.comum.atencao, error[item]);
                    }
                  } else if (Object.keys(error).length > 0) {
                    const arrayErros = Object.keys(error).map((key) => [key, error[key]]);
                    for (let index = 0; index < arrayErros.length; index++) {
                      const element = arrayErros[index];
                      const msg = element?.join(' - ');
                      !!msg && Notify('error', pt.comum.atencao, msg);
                    }
                  }
                  else {
                    cliente = error?.cliente;
                    corretor = error?.corretor
                      ? error?.corretor
                      : 'sem corretor';
                    proximoContato = error?.proximoContato
                      ? moment(error?.proximoContato).format('L')
                      : 'sem data';
                    listStatusAgrupado =
                      error?.statusAgrupado.split(/(?=[A-Z])/);

                    statusAgrupado = '';
                    if (listStatusAgrupado.length > 0) {
                      for (const item in listStatusAgrupado) {
                        statusAgrupado += listStatusAgrupado[item] + ' ';
                      }
                    }
                    statusAgrupado = statusAgrupado.trim();

                    setLoadingSelectedClient(true);

                    if (!checkConfig(configConst.PERMITE_LEAD_DUPLICADO))
                      setOpenModalClienteEmAtendimento(true);
                  }
                }
              });
          } else if (modo === MODO.EDITAR) {
            await api
              .put(url.ATENDIMENTO.CADASTRO_GERAL(), payload)
              .then(async (res) => {
                setLoadingSave(false);
                Notify('success', '', 'Atendimento atualizado com sucesso!!');

                if (statusAtendimento.find((x) => x.id === Number(dados.status))?.name === "Perdido") {
                  setIsAtendimentoFinalizado(true);
                };

                if (novoCorretorId) {
                  await transferAtendimento(
                    res?.data?.data?.id,
                    novoCorretorId,
                    novoClienteId,
                  );
                  setNovoCorretorId(undefined);
                }
              })
              .catch((err) => {
                setLoadingSave(false);
                const { error } = err.response.data;
                if (error) {
                  for (const item in error) {
                    Notify('error', pt.comum.atencao, error[item]);
                  }
                }
                else if (Object.keys(error).length > 0) {
                  const arrayErros = Object.keys(error).map((key) => [key, error[key]]);
                  for (let index = 0; index < arrayErros.length; index++) {
                    const element = arrayErros[index];
                    const msg = element?.join(' - ');
                    !!msg && Notify('error', pt.comum.atencao, msg);
                  }
                }
              });
          }
        }
      })
      .catch(() => {
        setLoadingSave(false);
        window.scrollTo(1, 1);
      });
  }

  async function onSearchCliente(value) {
    cancelRequest();
    await onLoadPessoas({ nome: value, tipo: null });
  }

  function onSearchTipoCliente(value) {
    const tipoAtendimentoId = localStorage.getItem('icaseTipoAtendimentoId');
    const tipoAtendimentoDesc = localStorage.getItem(
      'icaseTipoAtendimentoDesc',
    );

    if (tipoAtendimentoId) {
      setFilterNomeTipoAtendimento(tipoAtendimentoDesc);
      form.setFieldsValue({ tipoAtendimentoId });

      localStorage.removeItem('icaseTipoAtendimentoId');
      localStorage.removeItem('icaseTipoAtendimentoDesc');
    } else {
      setFilterNomeTipoAtendimento(value);
    }
  }

  function onSearchPlantao(value) {
    const plantaoId = localStorage.getItem('icasePlantaoId');
    const plantaoDesc = localStorage.getItem('icasePlantaoDesc');

    if (plantaoId) {
      setFilterNomePlantao(plantaoDesc);
      form.setFieldsValue({ plantao: plantaoId });

      localStorage.removeItem('icasePlantaoId');
      localStorage.removeItem('icasePlantaoDesc');
    } else {
      setFilterNomePlantao(value);
    }
  }

  async function handleSelectedClient(value, option) {
    await api
      .get(url.PESSOA.CONSULTA_ATENDIMENTO(value))
      .then((res) => {
        const response = res?.data;
        // Ignora Negócio Fechado e Desistência
        if (
          !checkConfig(configConst.PERMITE_LEAD_DUPLICADO) &&
          response.length > 0 &&
          response[0]?.codigoStatus !== 1 &&
          response[0]?.codigoStatus !== 3
        ) {
          cliente = response[0]?.cliente;
          corretor = response[0]?.corretor
            ? response[0]?.corretor
            : 'sem corretor';
          proximoContato = response[0]?.proximoContato
            ? moment(response[0]?.proximoContato).format('L')
            : 'sem data';
          listStatusAgrupado = response[0]?.statusAgrupado.split(/(?=[A-Z])/);

          statusAgrupado = '';
          if (listStatusAgrupado.length > 0) {
            for (const item in listStatusAgrupado) {
              statusAgrupado += listStatusAgrupado[item] + ' ';
            }
          }
          statusAgrupado = statusAgrupado.trim();

          setOpenModalClienteEmAtendimento(true);
        } else {
          valueOfClient = value;
          nameOfClient = option?.children || '';
          setOpenModalSelectedClient(true);
        }
      })
      .catch((err) => {
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  async function handleConfirmSelectedClient() {
    setLoadingSelectedClient(true);
    setNovoClienteId(valueOfClient);
    setOpenModalSelectedClient(false);

    await api
      .get(url.PESSOA.CONSULTA_ID(valueOfClient))
      .then((res) => {
        setSelectedClient(res.data);
        setLoadingSelectedClient(false);
      })
      .catch(() => setLoadingSelectedClient(false));
  }

  function handleCloseSelectedClient() {
    form.setFieldsValue({ clienteId: undefined });
    setOpenModalSelectedClient(false);
  }

  function newClient(event) {
    event.preventDefault();
    setLoadingNewClient(true);
    setModoPessoa('cadastrar');
    setOpenModalPessoa(true);
  }

  async function closeModalPerfilAtendimento() {
    setOpenModalPerfilAtendimento(false);
  }
  async function closeModalPessoa() {
    const pessoaId = localStorage.getItem('icasePessoaId');
    if (pessoaId) {
      setLoadingSelectedClient(true);
      setNovoClienteId(pessoaId);
      setOpenModalPessoa(false);
      setLoadingNewClient(false);

      await api
        .get(url.PESSOA.CONSULTA_ID(pessoaId))
        .then(async (res) => {
          setSelectedClient(res.data);
          cancelRequest();
          await onLoadPessoas({ pessoaId });
          form.setFieldsValue({ clienteId: pessoaId });
          setLoadingSelectedClient(false);
        })
        .catch(() => setLoadingSelectedClient(false));

      localStorage.removeItem('icasePessoaId');
    } else {
      setOpenModalPessoa(false);
      setLoadingNewClient(false);
      cancelRequest();
      await onLoadPessoas({ pessoaId: novoClienteId });
    }
  }

  function handleViewClient(event) {
    event.preventDefault();
    setModoPessoa('editar');
    setOpenModalPessoa(true);
  }

  async function changeImovel(imovelId) {
    await api
      .get(url.IMOVEL.CONSULTA(imovelId))
      .then(async (res) => {
        if (atendimentoId) {
          const payload = {
            atendimentoId,
            imovelId,
          };

          await api
            .post(url.ATENDIMENTO.HISTORICO_IMOVEL(), payload)
            .then(() => {
              setSelectedImovel(res?.data);
              setLoadingFilterImovel(false);
              setOpenModalConfirmImovel(false);
              setLoadingSave(false);
            })
            .catch(() => {
              form.setFieldsValue({ codigo: undefined });
              setLoadingFilterImovel(false);
              setOpenModalConfirmImovel(false);
              setLoadingSave(false);
              setSelectedImovel(undefined);
            });
        } else {
          setLoadingFilterImovel(false);
          setOpenModalConfirmImovel(false);
          setLoadingSave(false);
          setSelectedImovel(res?.data);
        }
      })
      .catch(() => {
        form.setFieldsValue({ codigo: undefined });
        setSelectedImovel(undefined);
        setLoadingFilterImovel(false);
        setOpenModalConfirmImovel(false);
        setLoadingSave(false);
        Notify('error', pt.comum.atencao, 'Imóvel não encontrado.');
      });
  }

  async function handleImovelId(value) {
    setLoadingFilterImovel(true);

    await api
      .get(url.ATENDIMENTO.CHECK_IMOVEL_EM_ATENDIMENTO(value?.id))
      .then(async (res) => {
        if (res?.data.length > 0) {
          const isNegocioFechado = res?.data.some(
            (atendimento) => atendimento.status === 1,
          );

          if (isNegocioFechado) {
            setLoadingFilterImovel(false);
            setSelectedImovel(undefined);
            form.setFieldsValue({ codigo: undefined });

            Notify(
              'error',
              pt.comum.atencao,
              'Imóvel selecionado já está sendo usado em outro atendimento com o status Negócio Fechado.',
            );
          } else {
            selectedCodImovel = value?.codImovel;

            switch (res?.data[0].statusAgrupado) {
              case 3:
                statusAtendimentoName = 'Em visita';
                break;
              case 4:
                statusAtendimentoName = 'Em proposta';
                break;

              default: // VALOR 2
                statusAtendimentoName = 'Em Atendimento';
                break;
            }
            setSelectedImovelId(value?.id);
            setOpenModalConfirmImovel(true);
          }
        } else {
          form.setFieldsValue({ codigo: value?.codImovel || undefined });

          if (value?.codImovel) {
            changeImovel(value?.id);
          } else {
            setLoadingFilterImovel(false);
            Notify('error', pt.comum.atencao, 'Imóvel não encontrado.');
          }
        }
      })
      .catch((err) => {
        setLoadingFilterImovel(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  function handleConfirmImovel() {
    setLoadingSave(true);
    if (selectedImovelId) changeImovel(selectedImovelId);
  }

  function handleCloseConfirmImovel() {
    setSelectedImovelId(undefined);
    setLoadingFilterImovel(false);
    setSelectedImovel(undefined);
    form.setFieldsValue({ codigo: undefined });
    setOpenModalConfirmImovel(false);
  }

  function handleViewImovel(event, selectedImovel) {
    event.preventDefault();

    let a = document.createElement('a');
    a.target = '_blank';
    a.href = `/app/imoveis/preview/visualizar/${identificador}/${selectedImovel.id}`;
    a.click();
  }

  async function clearSelectedImovel() {
    setLoadingSave(true);

    if (atendimentoId) {
      setSelectedImovel(undefined);
      form.setFieldsValue({ codigo: undefined });
      setLoadingSave(false);
    } else {
      setSelectedImovel(undefined);
      form.setFieldsValue({ codigo: undefined });
      setLoadingSave(false);
    }
  }

  function closeModalImovel() {
    setOpenModalImovel(false);
  }

  function disabledDate(current) {
    return current && current < moment().subtract(1, 'day').endOf('day');
  }

  function handleStatusNegocio(value) {
    if (value === 3 || value === 1) {
      form.setFieldsValue({ situacao: 3 });
    } else {
      form.setFieldsValue({ situacao: 1 });
    }
  }

  function handleOpenModalNegocioFechado(event) {
    event.preventDefault();
    setOpenModalNegocioFechado(true);
  }

  async function handleNegocioFechado() {
    setLoadingSave(true);

    await api
      .patch(url.ATENDIMENTO.FECHAR_NEGOCIO(atendimentoId))
      .then(() => {
        setLoadingSave(false);
        setIsAtendimentoFinalizado(true);
        setOpenModalNegocioFechado(false);
        Notify(
          'success',
          pt.comum.atencao,
          'Atendimento finalizado com sucesso!',
        );
      })
      .catch((err) => {
        setLoadingSave(false);
        setOpenModalNegocioFechado(false);
        const { error } = err.response.data;
        if (error) {
          for (const item in error) {
            Notify('error', pt.comum.atencao, error[item]);
          }
        }
      });
  }

  async function handleNegocioRecusado() {
    setLoadingSave(true);

    if (tipoDoNegocioRecusado === TYPEOFREFUSE.REINICIAR) {
      await api
        .patch(url.ATENDIMENTO.REINICIAR_ATENDIMENTO(atendimentoId))
        .then(() => {
          form.setFieldsValue({ situacao: 1, status: 2 });
          setLoadingSave(false);
          setShowButtonVisita(true);
          setShowButtonProposta(true);
          setOpenModalNegocioRecusado(false);
          Notify(
            'success',
            pt.comum.atencao,
            'Atendimento reiniciado com sucesso!',
          );
        })
        .catch((err) => {
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }

    if (tipoDoNegocioRecusado === TYPEOFREFUSE.ENCERRAR) {
      await api
        .patch(url.ATENDIMENTO.ENCERRAR_ATENDIMENTO(atendimentoId))
        .then(() => {
          form.setFieldsValue({ situacao: 2, status: 3 });
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
          setIsAtendimentoFinalizado(true);
          Notify(
            'success',
            pt.comum.atencao,
            'Atendimento finalizado com sucesso!',
          );
        })
        .catch((err) => {
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }

  function voltar() {
    if (props.onClose) {
      props.onClose();
    }

    const getIdIfExists =
      identificador &&
        identificador !== undefined &&
        identificador !== 'undefined'
        ? identificador
        : '';

    history.push(`/app/atendimentos/${getIdIfExists}`);
  }

  function handleRelacionamento(event, type) {
    event.preventDefault();
    setModoRelacionamento(MODO.CADASTRAR);

    if (type === TYPE.RELACIONAMENTO) {
      setEventId(undefined);
      setOpenModalAtendimento(true);
    }

    if (type === TYPE.VISITA) {
      setInteresseId(undefined);
      setOpenModalAgendarVisita(true);
    }

    if (type === TYPE.PROPOSTA) {
      setPropostaId(null);
      setIsEditingProposal(false);
      setOpenModalProposta(true);
    }
  }

  async function closeModal() {
    if (openModalClienteEmAtendimento) {
      cancelRequest();
      await onLoadPessoas();
      form.setFieldsValue({ clienteId: undefined });
      setNovoClienteId(undefined);
      setSelectedClient(undefined);
      setLoadingSelectedClient(false);
    }

    setOpenModalClienteEmAtendimento(false);
    setOpenModalAtendimento(false);
    setOpenModalAgendarVisita(false);
    setOpenModalProposta(false);
    setModoRelacionamento(MODO.CADASTRAR);
  }

  async function onSubmitProposta() {
    setOpenModalProposta(false);

    if (
      (isGerente || isDiretor) &&
      checkConfig(configConst.INTEGRAR_COM_ONEPLUS)
    ) {
      history.push(`/app/secretaria-vendas/${atendimentoId}/${selectedClient?.id}/${selectedImovel?.id}`);

    } else {
      // await onSave(false, null);
      const id = props?.location?.state?.id || queryParams.get('id');
      await fetch(id);
    }
  }

  function refreshStatusAtendimento(newStatus) {
    form.setFieldsValue({ status: newStatus });

    if (newStatus === 9) setShowButtonVisita(false);

    if (newStatus === 10) setShowButtonProposta(false);
  }


  const handleDetalhesRelacionamento = (row) => {
    setModoRelacionamento(MODO.VISUALIZAR);

    if (row?.tipoRelacionamento === 1) {
      setEventId(row?.id);
      setOpenModalAtendimento(true);
    }

    if (row?.tipoRelacionamento === 2) {
      setInteresseId(row?.id);
      setOpenModalAgendarVisita(true);
    }

    if (row?.tipoRelacionamento === 3) {
      setPropostaId(row?.id);
      setOpenModalProposta(true);
    }
  };

  const menuItems = [
    {
      label: 'Reiniciar atendimento',
      key: 1,
      icon: <UndoOutlined />,
      onClick: () => {
        setOpenModalNegocioRecusado(true);
        setTipoDoNegocioRecusado(TYPEOFREFUSE.REINICIAR);
      },
    },
    {
      label: 'Encerrar atendimento',
      key: 2,
      icon: <CloseCircleOutlined />,
      onClick: () => {
        setOpenModalNegocioRecusado(true);
        setTipoDoNegocioRecusado(TYPEOFREFUSE.ENCERRAR);
      },
    },
  ];

  const canManageAllServices = !!actions.find(
    (action) => action.id === '9ea7c131-4575-4d03-a469-256977cc9546',
  );

  const renderTelefone = (telefones) => {
    const dataTelefone = telefones.find((telefone) => telefone.tipo === 1);
    const dataCelular = telefones.find((telefone) => telefone.tipo === 2);

    if (dataCelular?.numero?.length >= 12)
      return `+${dataCelular?.numero?.substring(0, 2)} ${mask(dataCelular?.numero?.substring(2, dataCelular?.numero?.length), [pattern.celular])}`;

    return dataCelular?.numero ? (
      dataCelular?.ddi === 55 || dataCelular.ddi === null ? (
        `+${dataCelular?.ddi} ${mask(dataCelular?.numero, [pattern.celular])}`
      ) : (
        `+${dataCelular?.ddi} ${dataCelular?.numero}`
      )
    ) : dataTelefone?.numero ? (
      dataTelefone?.ddi === 55 || dataTelefone?.ddi === null ? (
        `+${dataTelefone?.ddi} ${mask(dataTelefone?.numero, [
          pattern.telefone,
        ])}`
      ) : (
        `+${dataTelefone?.ddi} ${dataTelefone?.numero}`
      )
    ) : (
      <small>&nbsp;</small>
    );
  };

  const [canChangeBroker, setCanChangeBroker] = useState(true);

  const handleValuesChange = (changedValues, allValues) => {
    const tipoMidia = allValues?.tipoMidia;

    if (
      (!!allValues?.corretorId || !!allValues?.novoCorretorId) &&
      tipoMidia === '109' &&
      (!isMaster || !checkConfig(configConst.PERMITE_TRANSF_CARTEIRA_PROPRIA))
    ) {
      setCanChangeBroker(false);
    } else {
      setCanChangeBroker(true);
    }
  };

  function beforeOnSave() {
    const businessStatus = statusAtendimento
      .find(status => status.id === form.getFieldValue('status'))
      ?.name || null;

    const imovelSelecionado = form.getFieldValue('codigo');
    const hasSelectedImovel = imovelSelecionado || selectedImovel;

    if (!businessStatus) return;
    if ((businessStatus === 'Em Visita' || businessStatus === 'Em Proposta') && !hasSelectedImovel) {
      setTextoAviso(
        businessStatus === 'Em Visita'
          ? 'agendar uma visita'
          : 'registrar uma proposta',
      );

      setIsMissingProperty(true);
      return;
    };

    if (businessStatus === 'Em Proposta' && hasSelectedImovel && !propostaId) {
      setOpenModalProposta(true);
      return;
    };

    if (businessStatus === 'Perdido') {
      setOpenModalPerdido(true);
      return;
    }

    onSave(false, null);
  }

  const renderLocalizacao = () => {
    const localizacao = dataCidadesGrupos.reduce((acc, current) => {
      return acc.concat(current.options);
    }, []);

    const tags = localizacao.map((item) => (
      <Tag icon={<Icon name="map marker" />} key={item.id}>
        {item.label}
      </Tag>
    ));

    return <>{tags}</>;
  };

  const renderTipoImovel = () => {
    const tipos = dataTipoImoveis.reduce((acc, current) => {
      return acc.concat(current.options);
    }, []);
    const tipoImovel = tipos.filter((x) =>
      perfilAtendimento?.tipoImovel?.split(',').includes(x.id),
    );
    const tags = tipoImovel.map((item) => (
      <Tag icon={<Icon name="building outline" />} key={item.id}>
        {item.label}
      </Tag>
    ));

    return <>{tags}</>;
  };

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Card className="card-custom-basic">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="title">Controle de atendimentos</h5>
                <p className="category">Manutenção de atendimentos</p>
              </div>
            </div>
          </CardHeader>
          <CardBody className="pt-0 px-0">
            {loadPage && (
              <Row className="mx-1 mt-4">
                <Col xs="12" md="4">
                  <SkeletonTableBasic cardShadowless lineNumber={1} />
                </Col>
                <Col xs="12" md="4">
                  <SkeletonTableBasic cardShadowless lineNumber={1} />
                </Col>
                <Col xs="12" md="4">
                  <SkeletonTableBasic cardShadowless lineNumber={1} />
                </Col>
              </Row>
            )}
            {!loadPage && (
              <Fragment>
                <Form
                  form={form}
                  initialValues={{ situacao: 1, status: 4 }}
                  onValuesChange={handleValuesChange}>
                  <Collapse
                    defaultActiveKey={['CP-1']}
                    bordered={false}
                    ghost
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                      <div className="container-expand">
                        <span className="content-expand">
                          {isActive ? 'Ver menos' : 'Ver mais'}
                        </span>
                        <CaretDownOutlined
                          style={{ fontSize: 20 }}
                          rotate={isActive ? 180 : 0}
                        />
                      </div>
                    )}>
                    <Collapse.Panel key="CP-1" header="Leads">
                      <Row className="px-2">
                        {(!!hasSelectCorretor || canManageAllServices) && (
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Corretor</small>
                            </p>
                            <Form.Item className="my-2" name="novoCorretorId">
                              <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                disabled={!canChangeBroker}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .indexOf(
                                      input
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, ''),
                                    ) >= 0
                                }
                                placeholder=" Escolha o corretor"
                                style={{ width: '100%' }}
                                onChange={(user) => setNovoCorretorId(user)}>
                                {corretores.map((item) => (
                                  <Option
                                    key={item.id}
                                    disabled={item.isSeparator}>
                                    {item?.nome?.toString()}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {!novoClienteId && (
                          <Fragment>
                            <Col xs="12" md="4">
                              <p className="heading-small text-muted mb-2">
                                <small>Cliente</small>
                              </p>
                              <Form.Item className="my-2" name="clienteId">
                                <Select
                                  placeholder=" Escolha um cliente"
                                  showSearch
                                  filterOption={false}
                                  onSearch={onSearchCliente}
                                  onChange={(value, option) =>
                                    handleSelectedClient(value, option)
                                  }>
                                  {pessoas.map((item) => (
                                    <Option key={item.id}>{item.nome}</Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs="12" md="4">
                              <p className="heading-small text-muted mb-2">
                                <small>&nbsp;</small>
                              </p>
                              <Button
                                onClick={(event) => newClient(event)}
                                icon
                                labelPosition="left"
                                size="tiny"
                                fluid
                                color="violet"
                                disabled={loadingNewClient}>
                                <Icon name="plus" />
                                Novo Cliente
                              </Button>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                      {novoClienteId && (
                        <Fragment>
                          <Row className="px-2">
                            <Col xs="12">
                              {loadingSelectedClient && <CircularProgress />}
                              {!loadingSelectedClient && novoClienteId && (
                                <div className="container-cliente">
                                  <div className="container-content-cliente">
                                    <span className="content-imovel-title">
                                      Nome
                                    </span>
                                    <span className="content-imovel-text">
                                      {selectedClient?.nome || (
                                        <small>&nbsp;</small>
                                      )}
                                    </span>
                                  </div>
                                  <div className="container-content-cliente">
                                    <span className="content-imovel-title">
                                      Telefone
                                    </span>
                                    <span className="content-imovel-text">
                                      {renderTelefone(
                                        selectedClient?.telefones,
                                      )}
                                      {/* {
                                        selectedClient?.telefone
                                          ? mask(selectedClient?.telefone, [pattern.telefone]) :
                                          selectedClient?.telefone2
                                            ? mask(selectedClient?.telefone2, [pattern.telefone]) :
                                            selectedClient?.telefone3
                                              ? mask(selectedClient?.telefone3, [pattern.telefone]) :
                                              selectedClient?.celular
                                                ? mask(selectedClient?.celular, [pattern.celular]) :
                                                <small>&nbsp;</small>
                                      } */}
                                    </span>
                                  </div>
                                  <div className="container-content-cliente">
                                    <span className="content-imovel-title">
                                      E-mail
                                    </span>
                                    <span className="content-imovel-text">
                                      {selectedClient?.email || (
                                        <small>&nbsp;</small>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Col>
                            {!loadingSelectedClient && novoClienteId && (
                              <Col xs="12" md="3">
                                <Button
                                  icon
                                  labelPosition="left"
                                  size="medium"
                                  fluid
                                  className="mt-3"
                                  onClick={(event) => handleViewClient(event)}>
                                  <Icon name="user" />
                                  Visualizar cliente
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </Fragment>
                      )}
                      <Divider />
                      <Row className="px-2">
                        {!selectedImovel && !isAtendimentoFinalizado && (
                          <Col xs="12" md="3">
                            <p className="heading-small text-muted mb-2">
                              <small>Origem do imóvel</small>
                            </p>
                            <Form.Item className="my-2" name="origemImovel">
                              <Select
                                defaultValue={'1'}
                                allowClear
                                placeholder=" Escolha a origem do imóvel"
                                onChange={(value) => {
                                  if (value !== '1')
                                    setOpenModalImovelSimplificado(true);
                                  setSelectedOrigemImovel(value);
                                }}>
                                {origemImovel.map((item) => (
                                  <Option key={item.id}>
                                    {item.descricao}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Imóvel de interesse</small>
                          </p>
                          <Form.Item className="my-2" name="codigo">
                            <Input
                              type="number"
                              placeholder=" Clique em consultar para buscar o imóvel"
                              disabled
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Favor informar o imóvel de interesse.',
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        {!selectedImovel &&
                          selectedOrigemImovel === '1' &&
                          !isAtendimentoFinalizado && (
                            <Col xs="12" md="3">
                              <p className="heading-small text-muted mb-2">
                                <small>&nbsp;</small>
                              </p>
                              <Button
                                className="my-2"
                                size="tiny"
                                icon
                                color="violet"
                                labelPosition="left"
                                fluid
                                onClick={(event) => {
                                  event.preventDefault();
                                  //setOpenModalImovel(true);
                                  setOpenModalPerfilAtendimento(true);
                                }}>
                                <Icon name="search" />
                                Consultar / Criar Perfil
                              </Button>
                            </Col>
                          )}
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-1">
                            <small>Termômetro</small>
                          </p>
                          <div className="container-icon-thermometer">
                            <Tooltip title="Frio">
                              <div
                                className={`content-icon-thermometer${selectedThermometer === THERMOMETER.CONGELADO
                                  ? ' content-icon-thermometer-selected'
                                  : ''
                                  }`}
                                style={{ background: '#10B4C9', marginLeft: 0 }}
                                onClick={() =>
                                  setSelectedThermometer(THERMOMETER.CONGELADO)
                                }>
                                <Icon name="snowflake" size="large" />
                              </div>
                            </Tooltip>
                            <Tooltip title="Morno">
                              <div
                                className={`content-icon-thermometer${selectedThermometer === THERMOMETER.MORNO
                                  ? ' content-icon-thermometer-selected'
                                  : ''
                                  }`}
                                style={{ background: '#C9B838' }}
                                onClick={() =>
                                  setSelectedThermometer(THERMOMETER.MORNO)
                                }>
                                <Icon name="theme" size="large" />
                              </div>
                            </Tooltip>
                            <Tooltip title="Quente">
                              <div
                                className={`content-icon-thermometer${selectedThermometer === THERMOMETER.FERVENDO
                                  ? ' content-icon-thermometer-selected'
                                  : ''
                                  }`}
                                style={{ background: '#E01D20' }}
                                onClick={() =>
                                  setSelectedThermometer(THERMOMETER.FERVENDO)
                                }>
                                <Icon name="fire" size="large" />
                              </div>
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>
                      {perfilAtendimento && !selectedImovel && (
                        <Fragment>
                          <Divider />
                          <Row className="px-2 mt-1">
                            <Col xs="12">
                              <span className="content-imovel-title">
                                Perfil de Interesse
                              </span>
                            </Col>
                          </Row>
                          <Row className="px-2 mt-1">
                            <Col xs="6">{renderLocalizacao()}</Col>
                            <Col xs="6">{renderTipoImovel()}</Col>
                          </Row>
                          <Row className="px-2 mt-1">
                            <Col xs="3">
                              {perfilAtendimento?.areaMinima >= 0 &&
                                perfilAtendimento?.areaMaxima >= 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="th" />
                                  }>{`Área de ${perfilAtendimento?.areaMinima} a ${perfilAtendimento?.areaMaxima}`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="th" />}>
                                  Área não informada
                                </Tag>
                              )}
                            </Col>
                            <Col xs="3">
                              {perfilAtendimento?.numeroBanheiros > 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="bath" />
                                  }>{`${perfilAtendimento?.numeroBanheiros} banheiros`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="bath" />}>
                                  Banheiros não informado
                                </Tag>
                              )}
                            </Col>
                            <Col xs="3">
                              {perfilAtendimento?.numeroDormitorios > 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="bed" />
                                  }>{`${perfilAtendimento?.numeroDormitorios} quartos`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="bed" />}>
                                  Quartos não informado
                                </Tag>
                              )}
                            </Col>
                            <Col xs="3">
                              {perfilAtendimento?.numeroSuites > 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="bed" />
                                  }>{`${perfilAtendimento?.numeroSuites} suítes`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="bed" />}>
                                  Suítes não informada
                                </Tag>
                              )}
                            </Col>
                          </Row>
                          <Row className="px-2 mt-1">
                            <Col xs="3">
                              {perfilAtendimento?.numeroVagas > 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="car" />
                                  }>{`${perfilAtendimento?.numeroVagas} vagas`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="car" />}>
                                  Vagas não informada
                                </Tag>
                              )}
                            </Col>
                            <Col xs="3">
                              {perfilAtendimento?.operacaoImobiliaria === 2 ? (
                                <Tag icon={<Icon name="sync" />}>Venda</Tag>
                              ) : (
                                <Tag icon={<Icon name="sync" />}>Aluguel</Tag>
                              )}
                            </Col>
                            <Col xs="3">
                              {perfilAtendimento?.valorMinimo >= 0 ||
                                perfilAtendimento?.valorMaximo >= 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="money" />
                                  }>{`Valor de ${formatCurrency(
                                    perfilAtendimento?.valorMinimo,
                                  )} a ${formatCurrency(
                                    perfilAtendimento?.valorMaximo,
                                  )}`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="money" />}>
                                  Valor não informado
                                </Tag>
                              )}
                            </Col>
                            <Col xs="3">
                              {perfilAtendimento?.valorCondominioMinimo >= 0 ||
                                perfilAtendimento?.valorCondominioMaximo >= 0 ? (
                                <Tag
                                  icon={
                                    <Icon name="money" />
                                  }>{`Valor do Condominio de ${formatCurrency(
                                    perfilAtendimento?.valorCondominioMinimo,
                                  )} a ${formatCurrency(
                                    perfilAtendimento?.valorCondominioMaximo,
                                  )}`}</Tag>
                              ) : (
                                <Tag icon={<Icon name="money" />}>
                                  Valor do Condominio não informado
                                </Tag>
                              )}
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                      <Row className="px-2 mt-1">
                        <Col xs="12">
                          {loadingFilterImovel && <CircularProgress />}
                          {!loadingFilterImovel && selectedImovel && (
                            <div className="container-image">
                              {selectedImovel?.imagens &&
                                selectedImovel?.imagens?.map((image) => {
                                  return (
                                    image.destaque && (
                                      <Image
                                        className="fix-dimension-image-imovel"
                                        preview={false}
                                        src={image?.arquivo}
                                      />
                                    )
                                  );
                                })}
                              {!selectedImovel?.imagens && (
                                <Image
                                  className="fix-dimension-image-imovel"
                                  preview={false}
                                  src={imgImovel}
                                />
                              )}
                              <div className="container-imovel">
                                <div className="container-content-imovel">
                                  <span className="content-imovel-title">
                                    Nome
                                  </span>
                                  <span className="content-imovel-text">
                                    {selectedImovel?.endereco?.condominio
                                      ? selectedImovel?.endereco?.condominio
                                        ?.nome
                                      : selectedImovel?.tituloAmigavel}
                                  </span>
                                </div>
                                <div className="container-content-imovel">
                                  <span className="content-imovel-title">
                                    Tipo
                                  </span>
                                  <span className="content-imovel-text">
                                    {selectedImovel?.tipoImovel?.descricao}
                                  </span>
                                </div>
                                <div className="container-content-imovel">
                                  <span className="content-imovel-title">
                                    Valor
                                  </span>
                                  <span className="content-imovel-text">
                                    {selectedImovel?.valorVenda
                                      ? `R$ ${formatCurrency(
                                        selectedImovel?.valorVenda,
                                      )}`
                                      : selectedImovel?.valorAluguel
                                        ? `R$ ${formatCurrency(
                                          selectedImovel?.valorAluguel,
                                        )}`
                                        : 'R$ 0,00'}
                                  </span>
                                </div>
                                <div className="container-content-imovel">
                                  <span className="content-imovel-title">
                                    Endereço
                                  </span>
                                  <span className="content-imovel-text">
                                    {`
                                  ${selectedImovel?.logradouro?.nome}
                                  ${selectedImovel?.endereco?.numero
                                        ? ', ' + selectedImovel?.endereco?.numero
                                        : ''
                                      } -
                                  ${selectedImovel?.logradouro?.bairro?.nome} -
                                  ${selectedImovel?.logradouro?.bairro?.cidade
                                        ?.nome
                                      } -
                                  ${selectedImovel?.logradouro?.bairro?.cidade
                                        ?.estadoId
                                      }
                                `}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                        {!loadingFilterImovel && selectedImovel && (
                          <Fragment>
                            <Col xs="12" md="3">
                              <Button
                                icon
                                labelPosition="left"
                                size="medium"
                                fluid
                                className="mt-3"
                                onClick={(event) =>
                                  handleViewImovel(event, selectedImovel)
                                }>
                                <Icon name="home" />
                                Visualizar imóvel
                              </Button>
                            </Col>
                            <Col xs="12" md="3">
                              <Button
                                icon
                                labelPosition="left"
                                color="green-second"
                                size="medium"
                                fluid
                                className="mt-3"
                                isloading={loadingSave.toString()}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setOpenModalHistorico(true);
                                }}>
                                <Icon name="history" />
                                Visualizar histórico
                              </Button>
                            </Col>
                            {!isAtendimentoFinalizado && (
                              <Col xs="12" md="2">
                                <Button
                                  icon
                                  labelPosition="left"
                                  color="orange"
                                  size="medium"
                                  fluid
                                  className="mt-3"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    clearSelectedImovel();
                                  }}
                                  isloading={loadingSave.toString()}
                                  loadtext="Excluíndo...">
                                  <Icon name="trash" />
                                  Excluir
                                </Button>
                              </Col>
                            )}
                          </Fragment>
                        )}
                      </Row>
                      <Divider />
                      <Row className="px-2">
                        <Col xs="12" md={!isAtendimentoFinalizado ? '3' : '4'}>
                          <p className="heading-small text-muted mb-2">
                            <small>Tipo de Cliente</small>
                          </p>
                          <Form.Item className="my-2" name="tipoAtendimentoId">
                            <Select
                              allowClear
                              placeholder=" Escolha o tipo de cliente">
                              {dataTipoAtendimento.map((item) => (
                                <Option key={item.id}>{item.descricao}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        {!isAtendimentoFinalizado && (
                          <Col xs="12" md="1">
                            <p className="heading-small text-muted mb-1 text-white">
                              <small>&nbsp;</small>
                            </p>
                            <Tooltip title="Cadastrar Tipo de Cliente">
                              <Button
                                size="tiny"
                                style={{ width: '100%' }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setOpenModalTipoCliente(true);
                                }}
                                icon
                                color="violet">
                                <Icon name="plus" />
                              </Button>
                            </Tooltip>
                          </Col>
                        )}
                        <Col xs="12" md="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Tipo de mídia</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="tipoMidia"
                            rules={[
                              {
                                required: true,
                                message: 'Favor informar o tipo de mídia',
                              },
                            ]}>
                            <Select
                              disabled={!!atendimentoId}
                              placeholder=" Escolha o tipo de mídia">
                              {tiposMidia.map((item) => (
                                <Option
                                  disabled={item?.disabled}
                                  key={item.id}>
                                  {item.descricao}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs="12" md={!isAtendimentoFinalizado ? '3' : '4'}>
                          <p className="heading-small text-muted mb-2">
                            <small>Plantão/Loja</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="plantao"
                            rules={[
                              {
                                required: false,
                                message: 'Favor informar o Plantão/Loja',
                              },
                            ]}>
                            <Select placeholder=" Escolha o plantão/loja">
                              {plantao.map((item) => (
                                <Option key={item.id}>{item.descricao}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        {!isAtendimentoFinalizado && (
                          <Col xs="12" md="1">
                            <p className="heading-small text-muted mb-1 text-white">
                              <small>&nbsp;</small>
                            </p>
                            <Tooltip title="Cadastrar PLantão/Loja">
                              <Button
                                size="tiny"
                                style={{ width: '100%' }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setOpenModalPlantao(true);
                                }}
                                icon
                                color="violet">
                                <Icon name="plus" />
                              </Button>
                            </Tooltip>
                          </Col>
                        )}
                      </Row>
                      <Row className="px-2">
                        <Col xs="12" md="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Situação</small>
                          </p>
                          <Form.Item className="my-2" name="situacao">
                            <Select placeholder=" Selecione a situação">
                              <Option value={1}>Ativo</Option>
                              <Option value={3}>Concluído</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Data de abertura</small>
                          </p>
                          <Form.Item className="my-2" name="dataCriacao">
                            <DatePicker
                              disabled={!!atendimentoId}
                              defaultValue={moment(today, 'YYYY-MM-DD')}
                              disabledDate={disabledDate}
                              locale={locale}
                              format={dateFormat}
                              style={{ width: '100%' }}
                              placeholder=" Data de abertura"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Status do negócio</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="status"
                            rules={[
                              {
                                required: true,
                                message: 'Favor informar o status do negócio',
                              },
                            ]}>
                            <Select
                              onChange={handleStatusNegocio}
                              placeholder=" Selecione o status do negócio">
                              {statusAtendimento.map((item, index) => (
                                <Option
                                  key={index}
                                  disabled={
                                    item.id === 5 ||
                                      item.id === 6 ||
                                      item.id === 7 ||
                                      item.id === 1
                                      ? true
                                      : false
                                  }
                                  value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="px-2">
                        <Col xs="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Campanha</small>
                          </p>
                          <Form.Item className="my-2" name="campanha">
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Formulário</small>
                          </p>
                          <Form.Item className="my-2" name="formulario">
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs="4">
                          <p className="heading-small text-muted mb-2">
                            <small>Página</small>
                          </p>
                          <Form.Item className="my-2" name="pagina">
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="px-2">
                        <Col xs="12">
                          <p className="heading-small text-muted mb-2">
                            <small>Descrição</small>
                          </p>
                          <Form.Item className="my-2" name="observacao">
                            <Input.TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Row className="mx-2">
                      <Col xs="12">
                        <Divider />
                      </Col>
                    </Row>
                    <Row className="mx-2 mb-2">
                      {!isAtendimentoFinalizado && (<>
                        <Col xs="12" md="3">
                          <Button
                            className="mt-1"
                            onClick={(event) =>
                              handleRelacionamento(event, TYPE.RELACIONAMENTO)
                            }
                            icon
                            labelPosition="left"
                            isloading={loadingSave.toString()}
                            size="medium"
                            fluid
                            color="violet">
                            <Icon name="send" />
                            Registrar atividade
                          </Button>
                        </Col>
                        {showButtonVisita && selectedImovel && (
                          <Col xs="12" md="3">
                            <Button
                              className="mt-1"
                              onClick={(event) =>
                                handleRelacionamento(event, TYPE.VISITA)
                              }
                              icon
                              labelPosition="left"
                              isloading={loadingSave.toString()}
                              size="medium"
                              fluid>
                              <Icon name="calendar alternate" />
                              Agendar visita
                            </Button>
                          </Col>
                        )}
                        {showButtonProposta && selectedImovel && (
                          <Col xs="12" md="2">
                            <Button
                              className="mt-1"
                              onClick={(event) => {
                                handleRelacionamento(event, TYPE.PROPOSTA)
                              }
                              }
                              icon
                              labelPosition="left"
                              size="medium"
                              fluid
                              color="green">
                              <Icon name="gavel" />
                              Enviar proposta
                            </Button>
                          </Col>
                        )}
                        {!!propostaId && dataAtendimento?.status === 10 && (
                          <Fragment>
                            <Col xs="12" md="2">
                              <Button
                                className="mt-1"
                                onClick={(event) =>
                                  handleOpenModalNegocioFechado(event)
                                }
                                icon
                                labelPosition="left"
                                size="medium"
                                fluid
                                color="green">
                                <Icon name="gavel" />
                                Negócio fechado
                              </Button>
                            </Col>
                            <Col xs="12" md="2">
                              <Dropdown
                                overlay={<Menu items={menuItems} />}
                                placement="top">
                                <Button
                                  className="mt-1"
                                  icon
                                  labelPosition="left"
                                  size="medium"
                                  fluid
                                  color="orange">
                                  <Icon name="trash" />
                                  Recusado
                                </Button>
                              </Dropdown>
                            </Col>
                          </Fragment>
                        )}
                      </>)}
                      {!!propostaId && !!lastProposta && (
                        <Col xs="12" md="2">
                          <Button
                            className="mt-1"
                            onClick={() => {
                              setIsEditingProposal(true);
                              setOpenModalProposta(true);
                            }}
                            icon
                            labelPosition="left"
                            isloading={loadingSave.toString()}
                            size="medium"
                            fluid>
                            <Icon name="pencil" />
                            Editar proposta
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <Row className="mx-2">
                      <Col xs="12">
                        <Divider />
                      </Col>
                    </Row>
                    <Row className="mx-2">
                      <Col xs="12">
                        <h5 className="title"><ClockCircleOutlined />  Histórico de atividades</h5>
                        <TimelineRelacionamentos
                          dataRelacionamentos={dataRelacionamentos}
                          handleDetalhesRelacionamento={handleDetalhesRelacionamento}
                        />
                      </Col>
                    </Row>


                  </Collapse>

                </Form>
                <Row className="mx-2">
                  <Col xs="12">
                    <Divider />
                  </Col>
                </Row>
                <Row className="m-2">
                  {!isAtendimentoFinalizado && (
                    <Col xs="12" md="3">
                      <Button
                        onClick={() => beforeOnSave()}
                        icon
                        labelPosition="left"
                        size="medium"
                        fluid
                        className="mt-3"
                        color="violet"
                        isloading={loadingSave.toString()}
                        loadtext={
                          modo === MODO.CADASTRAR
                            ? 'Cadastrando...'
                            : 'Atualizando...'
                        }>
                        <Icon name="check" />
                        {modo === MODO.CADASTRAR ? 'Cadastrar' : 'Atualizar'}
                      </Button>
                    </Col>
                  )}
                  <Col xs="12" md="3">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      onClick={() => voltar()}>
                      <Icon name="reply" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            )}
          </CardBody>
        </Card>
      </Page>

      <ModalGenerico
        open={openModalPessoa}
        close={() => closeModalPessoa()}
        title={'Pessoa'}>
        <Pessoa
          match={{ params: { modo: modoPessoa } }}
          location={{ state: { row: { id: novoClienteId } } }}
          close={() => closeModalPessoa()}
          isAtendimento={false}
        />
      </ModalGenerico>

      <ModalGenerico
        open={openModalPerfilAtendimento}
        close={() => closeModalPerfilAtendimento()}
        title={'Busca / Perfil de Interesse'}>
        <PerfilAtendimento
          perfil={setPerfilAtendimento}
          initialValues={perfilAtendimento}
          id={perfilAtendimento?.id}
          setImovel={(codigo) => handleImovelId(codigo)}
          close={() => closeModalPerfilAtendimento()}
          isModalConsulta
          chavesNaEmpresa={false}
          loadLocalizacao={onLoadCidadesGrupos}
        />
        {/* <Pessoa
          match={{ params: { modo: modoPessoa } }}
          location={{ state: { row: { id: novoClienteId } } }}
          close={() => closeModalPerfilAtendimento()}
          isAtendimento={false}
        /> */}
      </ModalGenerico>

      <ModalBusca
        open={openModalImovel}
        close={() => closeModalImovel()}
        chavesNaEmpresa={false}
        setImovel={(codigo) => handleImovelId(codigo)}
        isModalConsulta
      />

      <ModalGenerico
        open={openModalTipoCliente}
        close={() => {
          setOpenModalTipoCliente(false);
          onSearchTipoCliente();
        }}>
        <TipoCliente
          match={{ params: { modo: 'cadastrar' } }}
          location={{ state: { identificador: undefined } }}
          onVoltar={() => {
            setOpenModalTipoCliente(false);
            onSearchTipoCliente();
          }}
        />
      </ModalGenerico>

      <ModalGenerico
        open={openModalPlantao}
        close={() => {
          setOpenModalPlantao(false);
          onSearchPlantao();
        }}>
        <Plantao
          match={{ params: { modo: 'cadastrar' } }}
          location={{ state: { identificador: undefined } }}
          onVoltar={() => {
            setOpenModalPlantao(false);
            onSearchPlantao();
          }}
        />
      </ModalGenerico>

      <ModalGenerico
        close={closeModal}
        open={openModalAtendimento}
        title={modoRelacionamento === MODO.CADASTRAR ? 'Registrar atividade' : 'Ver atividade'}>
        <RegistroAtendimento
          modo={modoRelacionamento}
          eventoId={eventoId}
          closeModal={closeModal}
          fetchRelacionamentos={fetchRelacionamentos}
          refreshStatusAtendimento={refreshStatusAtendimento}
          celular={selectedClient?.celular}
          atendimentoId={atendimentoId}
          imovelId={selectedImovel?.id}
        />
      </ModalGenerico>

      <ModalGenerico
        close={closeModal}
        open={openModalAgendarVisita}
        title="Agendar visita">
        <AgendarVisita
          modo={modoRelacionamento}
          interesseId={interesseId}
          closeModal={closeModal}
          fetchRelacionamentos={fetchRelacionamentos}
          refreshStatusAtendimento={refreshStatusAtendimento}
          setShowButtonVisita={setShowButtonVisita}
          atendimentoId={atendimentoId}
          imovelId={selectedImovel?.id}
        />
      </ModalGenerico>

      <ModalGenerico
        close={closeModal}
        open={openModalProposta}
        title={isEditingProposal ? 'Editar proposta' : 'Fazer proposta'}>
        <Proposta
          modo={modoRelacionamento}
          propostaId={propostaId}
          closeModal={closeModal}
          onSubmit={onSubmitProposta}
          refreshStatusAtendimento={refreshStatusAtendimento}
          fetchRelacionamentos={fetchRelacionamentos}
          setShowButtonProposta={setShowButtonProposta}
          atendimentoId={atendimentoId}
          imovelId={selectedImovel?.id}
          imovel={selectedImovel}
          cliente={selectedClient}
          isUpdating={isEditingProposal}
          setIsAtendimentoFinalizado={setIsAtendimentoFinalizado}
          showFecharNegocio={!!propostaId && dataAtendimento?.status === 10}
          showRecusar={!!propostaId}
        />
      </ModalGenerico>

      <ModalGenerico
        close={() => setOpenModalHistorico(false)}
        open={openModalHistorico}
        title="Histórico de imóveis desse atendimento">
        <HistoricoImoveis atendimentoId={atendimentoId} />
      </ModalGenerico>

      <ModalGenerico
        close={() => setOpenModalImovelSimplificado(false)}
        open={openModalImovelSimplificado}
        title={
          selectedOrigemImovel === '2'
            ? 'Informações do imóvel Mercadoria/Parceria'
            : 'Informações do imóvel Lançamento'
        }>
        <ImovelSimplificado
          origemImovel={selectedOrigemImovel}
          setShowButtonVisita={setShowButtonVisita}
          setOpenModalImovelSimplificado={setOpenModalImovelSimplificado}
          setImovel={(codigo) => handleImovelId(codigo)}
        />
      </ModalGenerico>

      <ModalGenerico
        size="small"
        close={() => {
          setOpenModalPerdido(false);
        }}
        open={openModalPerdido}
        title="Registrar motivo">
        <AtendimentoPerdido
          isLoading={false}
          cardDetails={dataAtendimento}
          onClose={() => setOpenModalPerdido(false)}
          onSubmit={(payload) => {
            setOpenModalPerdido(false);
            onSave(true, payload);
          }}
        />
      </ModalGenerico>

      <ModalConfirm
        isDelete
        showButtonCancel={false}
        loading={false}
        modalType="update"
        icon="warning sign"
        iconColor="yellow"
        open={openModalClienteEmAtendimento}
        buttonColor="violet"
        buttonText="Fechar"
        confirm={closeModal}
        close={closeModal}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>
              O cliente <b>{cliente}</b> já possui um
            </span>
            <br />
            <span>
              atendimento em andamento com o corretor <b>{corretor}</b>,
            </span>
            <br />
            <span>
              último atendimento no dia <b>{proximoContato}</b> com o
            </span>
            <br />
            <span>
              status <b>{statusAgrupado}</b>.
            </span>
          </Fragment>
        }
      />

      <ModalConfirm
        isDelete
        modalType="update"
        icon="warning sign"
        loading={loadingSave}
        loadingText="Prosseguir"
        open={openModalConfirmImovel}
        buttonColor="violet"
        buttonText="Prosseguir"
        confirm={handleConfirmImovel}
        close={handleCloseConfirmImovel}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>
              Para o imóvel selecionado, <b>{selectedCodImovel}</b>, existe um
              atendimento com o status <b>{statusAtendimentoName}</b>!
            </span>
            <br />
            <span>Deseja prosseguir com o atendimento mesmo assim?</span>
          </Fragment>
        }
      />

      <ModalConfirm
        isDelete
        modalType="update"
        icon="warning sign"
        loading={false}
        open={openModalSelectedClient}
        buttonColor="violet"
        buttonText="Prosseguir"
        confirm={handleConfirmSelectedClient}
        close={handleCloseSelectedClient}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>
              Tem certeza que gostaria de selecionar o cliente:{' '}
              <b>{nameOfClient}</b>?
            </span>
            <br />
            <span>
              Após selecionado o cliente, <b>não será possível alterar!</b>
            </span>
          </Fragment>
        }
      />

      <ModalConfirm
        isDelete
        modalType="update"
        icon="warning sign"
        loading={loadingSave}
        loadingText="Prosseguir"
        open={openModalNegocioFechado}
        buttonColor="violet"
        buttonText="Prosseguir"
        confirm={handleNegocioFechado}
        close={() => {
          setLoadingSave(false);
          setOpenModalNegocioFechado(false);
        }}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>Tem certeza que gostaria de finalizar esse atendimento?</span>
            <br />
            <span>
              Após finalizar o atendimento não será mais possível alterá-lo!
            </span>
          </Fragment>
        }
      />

      <ModalConfirm
        isDelete
        modalType="update"
        icon="warning sign"
        loading={loadingSave}
        loadingText="Prosseguir"
        open={openModalNegocioRecusado}
        buttonColor="violet"
        buttonText="Prosseguir"
        confirm={handleNegocioRecusado}
        close={() => {
          setLoadingSave(false);
          setOpenModalNegocioRecusado(false);
        }}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>
              {tipoDoNegocioRecusado === TYPEOFREFUSE.REINICIAR
                ? 'Tem certeza que gostaria de reiniciar o atendimento?'
                : 'Tem certeza que gostaria de finalizar esse atendimento?'}
            </span>
            {tipoDoNegocioRecusado === TYPEOFREFUSE.ENCERRAR && (
              <Fragment>
                <br />
                <span>
                  Após finalizar o atendimento não será mais possível alterá-lo!
                </span>
              </Fragment>
            )}
          </Fragment>
        }
      />

      <ModalConfirm
        isDelete
        showButtonCancel={false}
        loading={false}
        modalType="update"
        icon="warning sign"
        iconColor="yellow"
        open={isMissingProperty}
        buttonColor="violet"
        buttonText="Confirmar"
        confirm={() => setIsMissingProperty(false)}
        close={() => setIsMissingProperty(false)}
        message="Atenção"
        messageAfterIcon={
          <Fragment>
            <span>
              Você precisa informar um imóvel no atendimento para {textoAviso}
              .
            </span>
          </Fragment>
        }
      />
    </Fragment>
  );
}

export default Atendimentos;
