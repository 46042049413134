import React, { Fragment, useState } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { Form, Select } from 'antd';
import { Row, Col } from 'reactstrap';
import Button from '~/shared/Button';
import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const { Option } = Select;

function ModalRelatorioImoveis(
  {
    open,
    close,
    onExecute,
    loading
  }) {

  const [modelo, setModelo] = useState(2);

  return (
    <Fragment>
      <Modal size="mini" open={open} onClose={() => close()}>
        <Modal.Header>
          <Row>
            <Col xs="12">
              <div>
                <h4 className="titleLogin">Exportar Imóveis</h4>
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row className="p-2">
            <Col>
              <div>
                <Form
                  initialValues={{ modelo: modelo }}
                  scrollToFirstError>
                  <Row>
                    <Col xs="12" md="12" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Modelo de Relatório</small>
                      </p>
                      <Form.Item className="my-2" name="modelo">
                        <Select
                          defaultValue={modelo}
                          placeholder=" Modelo"
                          onChange={(value) => setModelo(value)}>
                          {/* <Option value={1}>Padrão</Option> */}
                          <Option value={2}>Resumido</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" className="mt-4">
                      <Button
                        icon
                        labelPosition="left"
                        size="medium"
                        fluid
                        className="mt-2"
                        color="violet"
                        isloading={loading.toString()}
                        onClick={() => onExecute(modelo)}
                        loadtext="Gerando PDF...">
                        <Icon name="file pdf outline" />
                        Gerar PDF
                      </Button>
                    </Col>
                    <Col xs="12">
                      <Button
                        icon
                        labelPosition="left"
                        size="medium"
                        fluid
                        className="mt-3"
                        onClick={close}>
                        <Icon name="undo" />
                        Voltar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalRelatorioImoveis;
