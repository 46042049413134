const filter = {
  operator: {
    Bigger: 1,
    BiggerOrEqual: 2,
    Lower: 3,
    LowerOrEqual: 4,
    Equal: 5,
    Different: 6,
    IsNull: 7,
    IsNotNull: 8,
    LikeAnyMatch: 9,
    LikeInitialMatch: 10,
    LikeFinalMatch: 11
  },
  type: {
    BigInt: 1,
    Boolean: 2,
    Double: 8,
    Integer: 9,
    Text: 19,
    Varchar: 22,
    Timestamp: 21,
    Timestampz: 26,
    Uuid_Guid: 27,
  }
}

export default filter;