import {useCallback, useEffect, useState} from 'react';
import api from '~/services/api';

export default function useRankingCaptors(mainPeriod, broker, brokerGroup, initialDate, finalDate) {
  // nomeCaptador: string;
  // vendaCaptada: number;
  // locacaoCaptada: number;
  // tipo: Venda / Locação

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = useCallback(async () => {
    try {
      await api
        .get('Dashboard/gerente/lista-captadores', {
          params: {
            periodoPrincipal: mainPeriod,
            corretorId: broker,
            grupoCorretoresId: brokerGroup,
            dataIni: initialDate,
            dataFim: finalDate,
          },
        })
        .then((res) => {
          const captors = res.data?.data || [];
          let itemList = [];

          captors.forEach((captor) => {
            itemList.push({
              nomeCaptador: captor.nomeCaptador,
              tipo: 'Venda',
              valor: captor.vendaCaptada,
            });

            itemList.push({
              nomeCaptador: captor.nomeCaptador,
              tipo: 'Locação',
              valor: captor.locacaoCaptada,
            });

            // Define um valor total entre venda + locacao afim de ordenar a lista
            itemList.forEach((current) => {
              if (
                current.nomeCaptador === captor.nomeCaptador &&
                !current.total
              ) {
                current.total = Number(
                  captor.vendaCaptada + captor.locacaoCaptada,
                );
              }
            });
          });

          setData(itemList.sort((a, b) => b.total - a.total) || []);
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [mainPeriod, broker, brokerGroup, initialDate, finalDate]);

  const config = {
    data,
    isGroup: true,
    xField: 'valor',
    yField: 'nomeCaptador',

    /** 自定义颜色 */
    // color: ['#1383ab', '#c52125'],
    seriesField: 'tipo',
    marginRatio: 0,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    config,
    isLoading,
    onLoad,
  };
}

