import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useConfigSistema(nome) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    setIsLoading(true);

    try {
      await api
        .get(
          nome
            ? url.CONFIGURACOES_SISTEMA.CONFIGURACOES_SISTEMA_NOME(nome)
            : url.CONFIGURACOES_SISTEMA.CONFIGURACOES_SISTEMA_EMPRESA(),
        )
        .then((res) => {
          setData(res?.data?.data);
        });
    } catch (e) {
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, [nome]);

  const checkConfig = useCallback(
    (config) => {
      if (data && data.length > 0) {
        return data.some((d) => d.descricao === config);
      }

      return false;
    },
    [data],
  );

  const checkTextConfig = useCallback(
    (config) => {
      if (data && data.length > 0) {
        return data.find((d) => d.descricao === config)?.textValue;
      }

      return '';
    },
    [data],
  );

  const getValueNumberConfig = (config) => {
    if (data && data.length > 0) {
      const value = data.filter((d) => d.descricao === config);
      return value[0]?.numberValue || 0;
    }

    return 0;
  };

  useEffect(() => {
    void onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
    checkConfig,
    checkTextConfig,
    getValueNumberConfig,
  };
}
