import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select, Slider, Radio, Pagination } from 'antd';
import { Icon, Transition } from 'semantic-ui-react';
import {
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';

import useImovel from '~/actions/Imovel/useImovel';
import useEntreAgencias from '~/actions/EntreAgencias/useEntreAgencias';
import useImovelCidadesDropdown from '~/actions/DropDown/useImovelCidadesDropdown';
import useBairroImovel from '~/actions/DropDown/useBairroImovel';
import useFinalidades from '~/actions/Finalidades/useFinalidades';

import Page from '~/components/Page';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import EmptyBox from '~/components/EmptyBox';
import CardPublicImovel from '~/components/CardPublicImovel';

import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import enumSituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';
import api from '~/services/api';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import Notify from '~/shared/Notify';
import Button from '~/shared/Button';
import useCondominioDropdown from '~/actions/DropDown/useCondomionio';

const { Option } = Select;

const formatter = (value) => {
  let description = 'mil';
  let convertedValue = value;

  if (value > 999999) {
    convertedValue = convertedValue / 1000 / 1000;

    if (value > 1999999)
      description = 'milhões'
    else
      description = 'milhão'
  } else {
    if (value < 1000)
      description = 'reais'
    else
      convertedValue = convertedValue / 1000
  }

  return `${convertedValue} ${description}`;
};

const options = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5 ou +', value: 5 },
];

function EntreAgencias() {

  const [current, setCurrent] = useState(null);
  const [loadingCards, setLoadCards] = useState(true);
  const [maisFiltros, setMaisFiltros] = useState(true);
  const [cidadeId, setCidadeId] = useState();
  const [loadingBairro, setLoadingBairro] = useState(false);
  const [bairroDisabled, setBairroDisabled] = useState(true);
  const [tipoImoveis, setTipoImoveis] = useState([]);
  const [tipoImovelDisabled, setTipoImovelDisabled] = useState(true);
  const [clearFiltroQuartos, setClearFiltroQuartos] = useState(0);
  const [clearFiltroVagas, setClearFiltroVagas] = useState(0);
  const [clearFiltroSuites, setClearFiltroSuites] = useState(0);

  const { isLoading, data, quantidade, onLoad } = useImovel();
  const { data: dataEntreImoveis, onLoad: onLoadEntreAgencias } = useEntreAgencias(false);
  const { data: dataCidades, onLoad: onLoadCidadesDropdown } = useImovelCidadesDropdown(
    null, null, []
  );
  const { data: dataBairros, onLoad: onLoadBairrosDropdown } = useBairroImovel();
  const { data: dataCondominios, onLoad: onLoadCondominio } =
    useCondominioDropdown(null, null);
  const { data: dataFinalidades } = useFinalidades();

  const [form] = Form.useForm();

  useEffect(() => {
    setCurrent(1);

    const params = {
      imovelPublico: true,
    };

    onLoad({ relatorio: false, params })
      .finally(async () =>
        await onLoadEntreAgencias(true)
          .finally(() => setLoadCards(false))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function changePage(page) {
    window.scrollTo(1, 1);
    setLoadCards(true);
    setCurrent(page);

    const params = {
      imovelPublico: true,
      numeroPagina: page,
    };

    onLoad({ relatorio: false, params })
      .finally(async () => setLoadCards(false));
  }

  async function onFiltros() {
    setLoadCards(true);
    setCurrent(1);
    form
      .validateFields()
      .then((dados) => {
        const params = {
          cidades: dados?.cidadeId,
          bairros: dados?.bairroId,
          condominios: dados?.condominioId,
          operacaoImobiliaria: dados?.operacaoImobiliaria,
          situacao: dados?.situacao ? dados?.situacao : 1,
          tipoImoveis: dados?.tipoImoveis,
          logradouro: dados?.logradouro,
          valorVendaDe: dados?.valorVenda
            ? Number(dados?.valorVenda[0]).toFixed(2)
            : undefined,
          valorVendaAte: dados?.valorVenda
            ? Number(dados?.valorVenda[1]).toFixed(2)
            : undefined,
          valorLocacaoDe: dados?.valorLocacao
            ? Number(dados?.valorLocacao[0]).toFixed(2)
            : undefined,
          valorLocacaoAte: dados?.valorLocacao
            ? Number(dados?.valorLocacao[1]).toFixed(2)
            : undefined,
          areaDe: dados?.area ? dados?.area[0] : undefined,
          areaAte: dados?.area ? dados?.area[1] : undefined,
          dormitorios: dados?.dormitorios,
          vagas: dados?.vagas,
          suites: dados?.suites,
          codImovel: dados?.codImovel ? Number(dados?.codImovel) : undefined,
          finalidadeId: dados?.finalidadeId,
          imovelPublico: true,
        };

        onLoad({ relatorio: false, params })
          .finally(() => setLoadCards(false));
      })
      .catch((err) => {
        setLoadCards(false);
        window.scrollTo(1, 1);
      });
  }

  async function onChangeCidade(cidadeId) {
    if (cidadeId && cidadeId.length > 0) {
      setLoadingBairro(true);
      setBairroDisabled(true);

      await onLoadBairrosDropdown({ cidadeId });

      form.setFieldsValue({ bairroId: [], portais: [] });

      setCidadeId(cidadeId);
      setBairroDisabled(false);
      setLoadingBairro(false);
    } else {
      form.setFieldsValue({ bairroId: [] });
      setCidadeId(undefined);
      setBairroDisabled(true);
    }
  }

  async function onSearchCidade(value) {
    await onLoadCidadesDropdown({ cidade: value });
  }

  async function onSearchBairro(value) {
    await onLoadBairrosDropdown({ cidadeId, nome: value });
  }

  async function onSearchCondominio(value) {
    await onLoadCondominio({ nome: value });
  }

  async function onChangeFinalidade(finalidadeId) {
    if (finalidadeId) {
      setTipoImovelDisabled(false);

      await api
        .get(
          `${url.TIPO_IMOVEL.CONSULTA()}?numeroPagina=1&tamanhoPagina=99999999&finalidadeId=${finalidadeId}`,
        )
        .then((res) => {
          if (res?.data?.data) setTipoImoveis(res.data.data);
          else setTipoImoveis([]);
          form.setFieldsValue({ tipoImoveis: [] });
        })
        .catch((err) => {
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    } else {
      setTipoImovelDisabled(true);
      setTipoImoveis([]);
      form.setFieldsValue({ tipoImoveis: [] });
    }
  }

  function onClickRadioQuartos(e) {
    if (e.target.value === clearFiltroQuartos)
      form.setFieldsValue({ dormitorios: null });
    else setClearFiltroQuartos(e.target.value);
  }

  function onClickRadioVagas(e) {
    if (e.target.value === clearFiltroVagas) form.setFieldsValue({ vagas: null });
    else setClearFiltroVagas(e.target.value);
  }

  function onClickRadioSuites(e) {
    if (e.target.value === clearFiltroSuites)
      form.setFieldsValue({ suites: null });
    else setClearFiltroSuites(e.target.value);
  }

  function areaSlider(value) {
    if (value[0] === 0 && value[1] === 0)
      form.setFieldsValue({ area: undefined });
  }

  function valorVendaSlider(value) {
    if (value[0] === 1000 && value[1] === 1000)
      form.setFieldsValue({ valorVenda: undefined });
  }

  function valorLocacaoSlider(value) {
    if (value[0] === 500 && value[1] === 500)
      form.setFieldsValue({ valorLocacao: undefined });
  }

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2" style={{ borderBottomRightRadius: 0 }}>
              <CardHeader>
                <Row>
                  <Col xs="12" md="11">
                    <h5 className="title">Imóveis compartilhados</h5>
                    <p className="category">Imóveis compartilhados por outras imobiliárias. Selecione quais deseje enviar para os Portais Imobiliários.</p>
                  </Col>
                  <Col xs="12" md="1" className="d-flex justify-content-end align-items-center">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        color: '#1d1f8c',
                      }}>
                      {!maisFiltros && (
                        <div
                          onClick={() => setMaisFiltros(true)}
                          style={{ cursor: 'pointer' }}>
                          <CaretDownOutlined
                            style={{ fontSize: '20px', verticalAlign: 'top' }}
                          />
                          <span>Ver mais</span>
                        </div>
                      )}
                      {maisFiltros && (
                        <div
                          onClick={() => setMaisFiltros(false)}
                          style={{ cursor: 'pointer' }}>
                          <CaretUpOutlined
                            style={{
                              fontSize: '20px',
                              verticalAlign: 'text-bottom',
                            }}
                          />
                          <span>Ver menos</span>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={maisFiltros ? "10" : "12"}>
            <Card className="p-2">
              {data.length > 0 && !loadingCards && (
                <CardHeader className="pl-2">
                  <Row>
                    <Col>
                      <h5 className="title mt-1">{`Resultados: (${quantidade || 0
                        })`}</h5>
                    </Col>
                  </Row>
                </CardHeader>
              )}
              <CardBody>
                {loadingCards &&
                  <Row className="mt-4">
                    <Col xs="12">
                      <Card className="w-100">
                        <CardBody>
                          <div className="p-4">
                            <SkeletonTableBasic
                              cardShadowless
                              lineNumber={3}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                }
                {data.length > 0 && !loadingCards &&
                  <CardPublicImovel
                    data={data}
                    onLoadEntreAgencias={onLoadEntreAgencias}
                    dataEntreImoveis={dataEntreImoveis}
                  />
                }
                {data.length > 0 && !loadingCards &&
                  <Row className="mt-2 p-2 justify-content-end">
                    <Pagination
                      current={current}
                      defaultPageSize={12}
                      defaultCurrent={1}
                      total={quantidade}
                      showSizeChanger={false}
                      onChange={changePage}
                    />
                  </Row>
                }
                {data.length < 1 && !loadingCards &&
                  <EmptyBox message="Não foram encontrados imóveis publicados por outras agências" />
                }
              </CardBody>
            </Card>
          </Col>
          <Transition visible={maisFiltros} animation='scale' duration={350}>
            <Col xs="2">
              <Card style={{
                marginTop: '-15px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                boxShadow: 'none'
              }}>
                <CardBody className="mt-1">
                  <Form form={form} scrollToFirstError>
                    <Row>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Cód. do Imóvel</small>
                        </p>
                        <Form.Item className="my-2" name="codImovel">
                          <Input
                            placeholder=" Cód. do Imóvel"
                            type="number"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Cidade</small>
                        </p>
                        <Form.Item className="my-2" name="cidadeId">
                          <Select
                            mode='multiple'
                            showSearch
                            filterOption={false}
                            placeholder=" Cidade"
                            allowClear
                            onSearch={onSearchCidade}
                            onClear={(value) => onChangeCidade(value)}
                            onChange={(value) => onChangeCidade(value)}>
                            {dataCidades.map((item) => (
                              <Option key={item.id}>{item.nome}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Bairro</small>
                        </p>
                        <Form.Item className="my-2" name="bairroId">
                          <Select
                            mode="multiple"
                            showSearch
                            showArrow
                            filterOption={false}
                            placeholder={
                              loadingBairro ? ' Carregando...' : ' Bairro'
                            }
                            allowClear
                            onSearch={onSearchBairro}
                            disabled={bairroDisabled}>
                            {dataBairros.map((item) => (
                              <Option key={item.id}>{item.nome}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Condomínio/Empreendimento</small>
                        </p>
                        <Form.Item className="my-2" name="condominioId">
                          <Select
                            mode="multiple"
                            placeholder=" Empreendimento"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                            }
                            allowClear
                            onSearch={onSearchCondominio}>
                            {dataCondominios.map((item) => (
                              <Option key={item.id}>
                                {`${item.nome} - ${item?.logradouro?.bairro?.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Logradouro</small>
                        </p>
                        <Form.Item className="my-2" name="logradouro">
                          <Input
                            placeholder=" Logradouro"
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Área em <b>M2</b></small>
                        </p>
                        <Form.Item className="my-2" name="area">
                          <Slider range min={0} max={2000} onAfterChange={areaSlider} />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Valor de venda em <b>R$</b></small>
                        </p>
                        <Form.Item className="my-2" name="valorVenda">
                          <Slider
                            range
                            step={1000}
                            min={1000}
                            max={100000000}
                            tooltip={{ formatter }}
                            onAfterChange={valorVendaSlider}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Valor de locação em <b>R$</b></small>
                        </p>
                        <Form.Item className="my-2" name="valorLocacao">
                          <Slider
                            range
                            step={500}
                            min={500}
                            max={1000000}
                            tooltip={{ formatter }}
                            onAfterChange={valorLocacaoSlider}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Operação</small>
                        </p>
                        <Form.Item className="my-2" name="operacaoImobiliaria">
                          <Select allowClear placeholder=" Operação">
                            {enumOperacaoImobiliaria.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Situação</small>
                        </p>
                        <Form.Item className="my-2" name="situacao">
                          <Select allowClear placeholder=" Situação" defaultValue={1}>
                            {enumSituacaoImovel.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Finalidade</small>
                        </p>
                        <Form.Item className="my-2" name="finalidadeId">
                          <Select
                            placeholder=" Finalidade"
                            allowClear
                            onClear={onChangeFinalidade}
                            onChange={onChangeFinalidade}>
                            {dataFinalidades.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de Imóvel</small>
                        </p>
                        <Form.Item className="my-2" name="tipoImoveis">
                          <Select
                            mode='multiple'
                            placeholder=" Tipo de Imóvel"
                            allowClear
                            disabled={tipoImovelDisabled}>
                            {tipoImoveis.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.descricao}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Qtd. de quartos</small>
                        </p>
                        <Form.Item className="my-2" name="dormitorios">
                          <Radio.Group
                            allowClear
                            style={{ width: '100%' }}
                            optionType="button"
                            buttonStyle="solid">
                            {options.map((item) => (
                              <Radio.Button
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioQuartos}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Vagas de garagem</small>
                        </p>
                        <Form.Item className="my-2" name="vagas">
                          <Radio.Group
                            allowClear
                            style={{ width: '100%' }}
                            optionType="button"
                            buttonStyle="solid">
                            {options.map((item) => (
                              <Radio.Button
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioVagas}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <p className="heading-small text-muted mb-2">
                          <small>Suítes</small>
                        </p>
                        <Form.Item className="my-2" name="suites">
                          <Radio.Group
                            allowClear
                            style={{ width: '100%' }}
                            optionType="button"
                            buttonStyle="solid">
                            {options.map((item) => (
                              <Radio.Button
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioSuites}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs="12">
                        <Button
                          className="my-2"
                          color="green"
                          size="tiny"
                          icon
                          labelPosition="left"
                          fluid
                          isloading={loadingCards.toString()}
                          loadtext="Buscando..."
                          onClick={() => onFiltros()}>
                          <Icon name="filter" />
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Transition>
        </Row>
      </Page>
    </Fragment>
  );
}

export default EntreAgencias;
