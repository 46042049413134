import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import {
  mask,
  // unMask
} from 'remask';

import Page from '~/components/Page';
import CadastroGeral from '../components/CadastroGeral';
import Imagens from '../components/Imagens';
import { Tabs } from '~/components/Tabs';
import { Tab } from '~/components/Tabs';

import useLogradouro from '~/actions/DropDown/useLogradouroDropdown';
import usePessoaDropdown from '~/actions/DropDown/usePessoa';
import useTipoCondominio from '~/actions/TipoCondominio/useTipoCondominio';

import pattern from '~/infra/resources/pattern/mask';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  GERAL: 1,
  IMAGENS: 2,
};

function Condominio(props) {
  const [loadPage, setloadPage] = useState(true);
  const [modo, setModo] = useState('');
  const [activeTab, setActiveTab] = useState(TABS.GERAL);
  const [disabledTabs, setDisabledTabs] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [initialValuesImages, setInitialValuesImages] = useState({});
  const [identificador, setIdentificador] = useState();
  const [condominioId, setCondominioId] = useState(null);
  const [listImages, setListImages] = useState([]);

  const { data: dataLogradouros, onLoad: onLoadLogradouros } = useLogradouro();
  const { data: dataPessoa, onLoad: onLoadPessoa } = usePessoaDropdown();
  const { data: dataTipoCondominio } = useTipoCondominio();

  async function generateInitialValueImages(images) {
    setListImages(images);

    const object = images.reduce(function (acc, image, index) {
      return Object.assign(acc, {
        [`${index}_id`]: image.id ? image.id : null,
        [`${index}_descricao`]: image.descricao,
        [`${index}_publicavel`]: image.publicavel,
        [`${index}_destaque`]: image.destaque,
        [`${index}_superDestaque`]: image.superDestaque,
        [`${index}_planta`]: image.planta,
        [`${index}_ordem`]: image.ordem
      });
    }, {});

    return object;
  }

  async function generateInitialValue(dados) {
    await onLoadPessoa({ tipo: 1, nome: dados.sindico?.pessoa?.nome || '' });
    // await onLoadLogradouros(dados.logradouro.nome);
    dados.estado = dados.logradouro.bairro.cidade.estadoId;
    dados.cidade = String(dados.logradouro.bairro.cidade.nome.toUpperCase());
    dados.bairro = dados.logradouro.bairro.nome.toUpperCase();
    dados.cep = dados.logradouro.cep
      ? mask(dados.logradouro.cep, [pattern.cep])
      : '';
    setInitialValues(dados);
  }

  useEffect(() => {
    async function fetch() {
      if (props.match.params) {
        if (props.match.params.modo === MODO.EDITAR) {
          const { row: dados, identificador } = props.location.state;

          setIdentificador(identificador);
          setModo(MODO.EDITAR);
          setDisabledTabs(false);
          setCondominioId(dados.id);

          const object = await generateInitialValueImages(
            dados.condominioImagens,
          );
          setInitialValuesImages(object);
          generateInitialValue(dados);

          setTimeout(() => {
            setloadPage(false);
          }, 2000);
        } else {
          const { identificador } = props.location.state;
          setIdentificador(identificador);

          await onLoadPessoa({ tipo: 1 });
          setModo(MODO.CADASTRAR);
          setloadPage(false);
        }
      }
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Card className="card-custom-basic card-transparent">
          <CardBody className="p-0">
            <Tabs
              activeTab={activeTab}
              setTab={(tab) => setActiveTab(tab)}
              disabledTabs={disabledTabs}>
              <Tab title="Cadastro geral" desc="Dados básicos">
                <Card className="card-custom card-shadowless rounded-top-0 mb-0 p-4">
                  <CardBody>
                    <CardHeader className="pl-2 py-3">
                      <h5 className="title">Dados básicos</h5>
                      {/* <p className="category">Manutenção de Perfil</p> */}
                    </CardHeader>
                    <CadastroGeral
                      dataLogradouros={dataLogradouros}
                      onLoadLogradouros={(value) => onLoadLogradouros(value)}
                      dataPessoa={dataPessoa}
                      onLoadPessoa={(value) => onLoadPessoa(value)}
                      dataTipoCondominio={dataTipoCondominio}
                      generateInitialValue={(value) =>
                        generateInitialValue(value)
                      }
                      initialValues={initialValues}
                      setActiveTab={(value) => setActiveTab(value)}
                      setCondominioId={(value) => setCondominioId(value)}
                      setDisabledTabs={(value) => setDisabledTabs(value)}
                      modo={modo}
                      onVoltar={props?.onVoltar}
                      identificador={identificador}
                    />
                  </CardBody>
                </Card>
              </Tab>
              <Tab title="Imagens" desc="Dados das imagens">
                <Card className="card-custom card-shadowless rounded-top-0 mb-0">
                  <CardBody>
                    <CardHeader className="pl-3 py-3">
                      <h5 className="title">Upload de imagens</h5>
                      {/* <p className="category">Manutenção de Perfil</p> */}
                    </CardHeader>
                    <Imagens
                      modo={modo}
                      condominioId={condominioId}
                      listImages={listImages}
                      initialValuesImages={initialValuesImages}
                      generateInitialValueImages={(value) =>
                        generateInitialValueImages(value)
                      }
                      setInitialValuesImages={(value) =>
                        setInitialValuesImages(value)
                      }
                      onVoltar={props?.onVoltar}
                      identificador={identificador}
                    />
                  </CardBody>
                </Card>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
}

export default Condominio;
