/* eslint-disable */
import React from 'react';
import { Button } from 'antd';

import history from '~/services/history';

function CardFooter() {
  function voltar() {
    history.push('/');
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button type="link" id="latitude-forgot" onClick={voltar}>
        Voltar para tela de login
      </Button>
    </div>
  );
}

export default CardFooter;
