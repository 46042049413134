import { useEffect, useState, useCallback, useMemo } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import debounce from "lodash.debounce";

let controller;
let canceledRequest = false;

export default function useCorretorDropdown(abortPreload) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const cancelRequest = () => {
    if (controller) {
      canceledRequest = true;
      controller.abort();
    }
  }


  const onLoad = useCallback(
    async (param) => {
      setIsLoading(true);

      let filter = '';

      if (!!param?.empresas && param.empresas.length > 0) filter = '?' + param.empresas?.map((guid) => `&empresasId=${encodeURIComponent(guid)}`).join('&');

      if (!!param?.nome) {
        if (filter.length > 0) filter += `&nome=${param.nome}`;
        else filter = `?nome=${param.nome}`;
      }

      if (!!param?.id) {
        if (filter.length > 0) filter += `&id=${param.id}`;
        else filter = `?id=${param.id}`;
      }

      if (!!param?.withUserType) {
        if (filter.length > 0) filter += `&withUserType=true`;
        else filter = `?withUserType=true`;
      }

      try {
        await api
          .get(
            `${url.DROPDOWN.CORRETORES_DROPDOWN()}${filter}`,
          )
          .then((res) => {
            canceledRequest = false;

            let corretoresAtivos =
              res?.data
                .filter((corretor) => !corretor.excluido)
                .map((corretor) => {
                  if (!!param?.withUserType)
                    corretor.nome = `${corretor.tipoUsuario} - ${corretor.nome.toString()}`;
                  return corretor;
                }) ?? [];
            let corretoresInativos =
              res?.data
                .filter((corretor) => corretor.excluido)
                .map((corretor) => {
                  corretor.nome = `${corretor.nome.toString()} (inativo)`;
                  return corretor;
                }) ?? [];

            if (corretoresInativos.length > 0) {
              corretoresInativos.unshift({
                isSeparator: true,
                id: 'separadorInativo',
                nome: 'Inativos',
              });
            }
            const dataToSet = [...corretoresAtivos, ...corretoresInativos];

            setData(dataToSet ?? []);
            setQuantidade(res?.data?.data?.length);
          });
      } catch (e) {
        setData([]);
      } finally {
        if (!canceledRequest) setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (abortPreload)
      return;

    onLoad();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad, abortPreload]);


  const debouncedChangeHandler = useMemo(
    () => debounce(onLoad, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      cancelRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    quantidade,
    isLoading,
    onLoad : debouncedChangeHandler,
  };
}
