import axios from 'axios';
import { BehaviorSubject } from 'rxjs';

import Notify from '~/shared/Notify';
import history from '../services/history';
import removeSessions from './removeSessions';
//import Url from '~/infra/urls';
//import jwtDecode from 'jwt-decode';
import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');
//var refreshIsLoading = false;

const currentUserSubject = new BehaviorSubject(
  localStorage.getItem('icaseUser'),
);

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});


// const apiRefresh = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
// });

api.interceptors.request.use(async (config) => {
  var userToken = await localStorage.getItem('icaseToken');
  
  // const tokenExpiration = Number(await localStorage.getItem('icasetokenExpiration'));

  // var tokenExpirationTimeInSeconds = (tokenExpiration - moment(Math.floor(Date.now() / 1000)));
  // if (tokenExpiration && tokenExpirationTimeInSeconds < 100) {
  //   const refreshToken = JSON.parse(await localStorage.getItem('icaseRefreshToken'));

  //   if (!refreshIsLoading && refreshToken?.token) {
  //     refreshIsLoading = true

  //     const payload = {
  //       refreshToken: refreshToken?.token,
  //       userId: localStorage.getItem('icaseUsuarioId'),
  //       empresaId: localStorage.getItem('icaseEmpresaId'),
  //     }

  //     await apiRefresh
  //       .post(Url.LOGIN.REFRESH_TOKEN(), payload)
  //       .then((res) => {
  //         const { token, refreshToken } = res.data.data.jwt;
  //         const { exp } = jwtDecode(token);
  //         localStorage.setItem('icaseToken', token);
  //         localStorage.setItem('icasetokenExpiration', exp);
  //         localStorage.setItem('icaseRefreshToken', JSON.stringify(refreshToken));
  //         userToken = token;

  //         refreshIsLoading = false;
  //       })
  //       .catch((err) => {
  //         refreshIsLoading = false;
  //       });
  //   }
  // }

  if (userToken)
    config.headers.Authorization = `Bearer ${userToken}`;


  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.response.status === 401) {
      removeSessions();
      currentUserSubject.next(null);
      history.push('/');
    }
    if (err.response.status === 500) {
      err.response.data = {};
      Notify(
        'error',
        'Atenção',
        'Ocorreu um erro interno no processamento da requisição, favor entrar em contato com o suporte!',
      );
    }
    return Promise.reject(err);
  },
);


export default api;
