import React, { Fragment, useState, useEffect } from 'react';
import { Form, Input, Select, Table } from 'antd';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import { useParams } from 'react-router-dom';

import useCidadesDropdown from '~/actions/DropDown/useCidadesDropdown';
import useLogradouro from '~/actions/Logradouro/useLogradouro';
import useBairroDropdown from '~/actions/DropDown/useBairroDropdown';

import Page from '~/components/Page';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import { Icon } from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(row, identificador) {
  history.push({
    pathname: `/app/logradouros/manutencao/${MODO.EDITAR}`,
    state: { row, identificador },
  });
}

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/logradouros/manutencao/${MODO.CADASTRAR}`,
    state: { identificador },
  });
}

function ConsultaLogradouro() {
  const [actions, setActions] = useState([]);
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [logradouroId, setLogradouroId] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [nomeLogradouro, setNomeLogradouro] = useState(null);
  const [nomeBairro, setNomeBairro] = useState();

  const [cidadeId, setCidadeId] = useState();
  const [bairroId, setBairroId] = useState();

  const [bairroDisabled, setBairroDisabled] = useState(true);
  const [loadingBairro, setLoadingBairro] = useState(false);

  const { data: dataCidadesDropdown, onLoad: onLoadCidadesDropdown } = useCidadesDropdown();

  const { data: dataBairros, onLoad: onLoadBairrosDropdown } = useBairroDropdown(cidadeId, nomeBairro);

  const { isLoading, data, quantidade, onLoad } = useLogradouro(
    current,
    pageSize,
    nomeLogradouro,
    bairroId,
  );

  const { identificador } = useParams();

  const [form] = Form.useForm();

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  async function handleCidade(cidadeId) {
    if (cidadeId) {
      setNomeBairro(undefined);

      setBairroDisabled(true);
      setLoadingBairro(true);

      await onLoadBairrosDropdown({ cidadeId });

      setBairroDisabled(false);
      setLoadingBairro(false);

    } else {
      setBairroDisabled(true);
    }
  }

  async function onSearchCidade(value) {
    await onLoadCidadesDropdown({ cidade: value });
  }

  async function onSearchBairro(value) {
    if (value) setNomeBairro(value);
  }

  // eslint-disable-next-line no-unused-vars
  async function onShowSizeChange(current, pageSize) {
    setPageSize(pageSize);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    const { nomeLogradouro, bairroId, cidadeId } = form.getFieldsValue();

    setCidadeId(cidadeId);
    setBairroId(bairroId);
    setNomeLogradouro(nomeLogradouro);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setLogradouroId(id);
  }

  async function handleConfirmDelete() {
    if (logradouroId) {
      setLoading(true);

      await api
        .delete(`${url.LOGRADOURO.DELETAR()}/${logradouroId}`)
        .then((response) => {
          setLoading(false);
          setOpenModalConfirm(false);
          onLoad();
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const columns = [
    {
      title: 'Logradouro',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Bairro',
      render: (row) => (
        <div key={row.index}>{row?.bairro?.nome}</div>
      )
    },
    {
      title: 'Cidade',
      render: (row) => (
        <div key={row.index}>{row?.bairro?.cidade?.nome}</div>
      )
    },
  ];

  if (editar) {
    columns.push({
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row, identificador)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  if (excluir) {
    columns.push({
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de logradouro</h5>
                <p className="category">Manutenção de logradouro</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form form={form} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Logradouro</small>
                            </p>
                            <Form.Item className="my-2" name="nomeLogradouro">
                              <Input placeholder=" Logradouro" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Cidade</small>
                            </p>
                            <Form.Item className="my-2" name="cidadeId">
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder=" Cidade"
                                allowClear
                                onSearch={onSearchCidade}
                                onChange={handleCidade}
                                onClear={handleCidade}
                              >
                                {dataCidadesDropdown.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.nome}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Bairro</small>
                            </p>
                            <Form.Item className="my-2" name="bairroId">
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                    .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                }
                                placeholder={
                                  loadingBairro ? ' Carregando...' : ' Bairro'
                                }
                                allowClear
                                onSearch={onSearchBairro}
                                onClear={onSearchBairro}
                                disabled={bairroDisabled}
                              >
                                {dataBairros.map((item) => (
                                  <Option key={item.id}>
                                    {item.nome}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="4">
                            <Button
                              className="mt-2"
                              size="tiny"
                              color="green"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${quantidade})`}</h5>
                {incluir === true ? (
                  <HeaderFiltros
                    navToNovo={() => navToNovo(identificador)}
                    description="Novo Logradouro"
                  />
                ) : null}
              </CardHeader>
              <CardBody>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={defaultPagination(current, quantidade, changePage)}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaLogradouro;
