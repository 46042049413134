import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useUsuario(
  filtro,
  filiaisId,
  tipoDeUsuario,
  perfil,
  pageSize,
) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [quantidade, setQuantidade] = useState(0);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const payLoad = {filtro, filiaisId, tipoDeUsuario, perfil, page, pageSize};
    
    try {
      await api.post(url.USUARIO.CONSULTA_FILTRO(), payLoad).then((res) => {
        setData(current => {
          if (current.length === 0) return res?.data?.data || [];
          // Acumula itens já listados
          return [...current, ...res?.data?.data || []];
        });
        setQuantidade(res?.data?.total);
        setPage(current => current + 1);
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [filiaisId, filtro, page, pageSize, perfil, tipoDeUsuario]);

  const onFilter = async ({filtro, filiaisId, tipoDeUsuario, perfil, pageSize}) => {
    setIsLoading(true);
    setData([]);
    setPage(1);
    const payLoad = {filtro, filiaisId, tipoDeUsuario, perfil, page, pageSize};
    try {
      await api.post(url.USUARIO.CONSULTA_FILTRO(), payLoad).then((res) => {
        setData(res?.data?.data || []);
        setQuantidade(res?.data?.total);
        setPage(current => current + 1);
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (page === 1) onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad])

  return {
    data,
    quantidade,
    isLoading,
    setIsLoading,
    onLoad,
    hasMore: data.length < quantidade,
    onFilter
  };
}
