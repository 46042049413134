import styled from 'styled-components';

const getGridStyles = (imageCount) => {
  if (imageCount === 2) {
    return `
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));

        & > :nth-child(1) {
          	grid-column: span 2;
         	grid-row: span 2;
        }
        `;
  }
  if (imageCount === 3) {
    return `
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));

    & > :nth-child(1),
    & > :nth-child(2),
     {
      grid-column: span 2 ;
      grid-row: span 1 ;
    }
  `;
  }
  if (imageCount === 4) {
    return `
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));

        & > :nth-child(1) {
          grid-column: span 2;
         	grid-row: span 1;
        }
         & > :nth-child(2), & > :nth-child(3) {
          grid-column: span 1;
         	grid-row: span 1;
          }
      `;
  }
  if (imageCount >= 5) {
    return `
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
      `;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-inline: 1rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${({primaryColor}) => primaryColor ?? '#000'};
  }

  hr {
    border-top: 1px solid ${({primaryColor}) => primaryColor ?? '#000'};
  }

  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1280px;
  }

  @media screen and (min-width: 1536px) {
    max-width: 1536px;
  }
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  top: 0;
  height: 9rem;
  position: sticky;
  margin-bottom: 2rem;

  z-index: 10;
`;

export const HeaderContent = styled.div`
  z-index: 10;
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 -8px 6px 12px rgba(0, 0, 0, 0.2);
  transition: height 0.3s ease-out;
  background-color: ${({secondaryColor}) => secondaryColor ?? '#fff'};
`;

export const LogoContainer = styled.div`
  height: 4rem;
  min-height: 4rem;
  overflow: hidden;

  img {
    height: 4rem;
  }

  @media screen and (min-width: 768px) {
    height: 6rem;
    min-height: 6rem;

    img {
      height: 6rem;
    }
  }
`;

export const CorretorContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
`;

export const CorretorImageContainer = styled.div`
  height: 4.5rem;
  width: 4.5rem;
  min-height: 4.5rem;
  min-width: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f3f4f6;
  border-radius: 9999px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const CorretorDadosContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.2rem;
  color: ${({primaryColor}) => primaryColor ?? '#000'};
`;
export const CorretorNome = styled.span`
  padding-inline: 0.5rem;
  font-size: 0.95rem;
  line-height: 1.25rem;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;
export const CorretorEmail = styled.span`
  padding-inline: 0.5rem;
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const CorretorCreci = styled.div`
  padding-inline: 0.5rem;

  span {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 800;
  }
  @media screen and (min-width: 768px) {
    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 0.5rem;
  gap: 0.8rem;
`;

export const GalleryContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 0.75rem;
  height: 240px;
  position: relative;
  gap: 0.5rem;

  @media screen and (min-width: 768px) {
    height: 360px;
  }

  @media screen and (min-width: 1024px) {
    height: 482px;
  }
`;

export const PrimaryImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    width: ${({fullSize}) => (fullSize ? '100%' : '54%')};
    height: 360px;
  }

  @media screen and (min-width: 1024px) {
    width: ${({fullSize}) => (fullSize ? '100%' : '52%')};
    height: 482px;
  }
`;

export const SecondaryImagesContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    width: 46%;
    max-height: 360px;
    display: grid;
    ${({imageCount}) => getGridStyles(imageCount)};
    gap: 0.5rem;
  }

  @media screen and (min-width: 1024px) {
    width: 48%;
    max-height: 482px;
  }
`;

export const SecondaryImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const VerMais = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;

  padding-inline: 1rem;
  padding-block: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4b5563;
  background-color: #e5e7eb;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  right: 1rem;
  bottom: 0.75rem;
  transition: background-color 0.2s;

  :focus {
    transition: background-color 0.2s;
    outline: none;
  }

  :hover {
    background-color: #d1d5db;
  }

  @media screen and (min-width: 1024px) {
    right: 2rem;
    bottom: 1.5rem;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TituloImovel = styled.h1`
  filter: brightness(0.95);
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const DescricaoImovel = styled.p`
  filter: brightness(0.8);
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 300;
`;

export const CaracteristicasContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const CaracteristicaTitle = styled.h6`
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export const CaracteristicasBlock = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 45%;
  }

  @media screen and (min-width: 1024px) {
    width: 60%;
  }

  @media screen and (min-width: 1280px) {
    width: 75%;
  }
`;

export const ValorBlock = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 55%;
  }

  @media screen and (min-width: 1024px) {
    width: 40%;
  }

  @media screen and (min-width: 1280px) {
    width: 25%;
  }
`;

export const CaracteristicasTitle = styled.h6`
  color: ${({primaryColor}) => primaryColor ?? '#000'};
  filter: brightness(0.95);
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export const CaracteristicasGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.75rem;
  margin-top: 0.5rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const CaracteristicaItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CaracteristicaItemTitle = styled.div`
  color: ${({primaryColor}) => primaryColor ?? '#000'};
  filter: brightness(0.75);
  display: flex;
  align-items: center;
  gap: 0.25rem;

  h5 {
    font-weight: 500;
  }
`;

export const CaracteristicaItemDescription = styled.div`
  color: ${({primaryColor}) => primaryColor ?? '#000'};

  span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    filter: brightness(0.6);
  }
`;

export const ValorContainer = styled.div`
  color: ${({primaryColor}) => primaryColor ?? '#000'};
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: ${({secondaryColor}) => secondaryColor ?? '#fff'};
  border-radius: 0.375rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);

  h6 {
    font-weight: 800;
    filter: brightness(0.95);
  }

  span {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 900;
  }
`;

export const LocalizationContainerTitle = styled.div`
  color: ${({primaryColor}) => primaryColor ?? '#000'};
  filter: brightness(0.95);

  display: flex;
  align-items: center;
  gap: 0.25rem;

  h6 {
    font-size: 1.2rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const LocalizationContent = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #c9c9c9;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
`;

export const CarouselContainer = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 1);
`;

export const CarouselCloseButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 9999px;

  transition: background-color 0.2s;

  :hover {
    transition: background-color 0.2s;
    background-color: #f8f8f8;
  }

  :focus {
    outline: none;
  }
`;

export const CarouselNavigation = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  display: none;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  margin-inline: 0.5rem;
  background-color: ${({secondaryColor}) => secondaryColor ?? 'transparent'};
  border-radius: 9999px;
  transition: background-color 0.2s;

  :hover {
    transition: background-color 0.2s;
    filter: brightness(1.3);
    background-color: ${({secondaryColor}) => secondaryColor ?? 'transparent'};
  }

  :focus {
    outline: none;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const CarouselImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  overflow: hidden;
  height: 80%;
  @media screen and (min-width: 1024px) {
    width: 80%;
  }
`;

export const CarouselImage = styled.div`
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CorretorModal = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
`;

export const CorretorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
  padding: 1rem;
  width: 22rem;
  height: auto;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  background-color: #fff;
`;

export const CorretorImageBox = styled.div`
  margin-inline: ${(space) => (space ? '1rem' : '0')};
  width: ${({miniature}) => (miniature ? '4rem' : '8rem')};
  height: ${({miniature}) => (miniature ? '4rem' : '8rem')};

  transition: width 0.3s ease-out, height 0.3s ease-out;
  position: relative;
`;

export const CorretorImage = styled.div`
  width: ${({miniature}) => (miniature ? '4rem' : '8rem')};
  height: ${({miniature}) => (miniature ? '4rem' : '8rem')};
  transition: width 0.3s ease-out, height 0.3s ease-out;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CorretorDados = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`;

export const CorretorMessage = styled.p`
  margin-block: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
`;

export const CorretorNomeModal = styled.span`
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

export const CorretorButton = styled.button`
  cursor: pointer;
  width: 100%;
  outline: none;
  border: none;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border: 0.01rem solid #f5f5f5;
  color: #fff;
  background-color: #2961e3;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  transition: filter 0.2s;

  :hover {
    filter: brightness(1.2);
  }

  :focus {
    outline: none;
  }
`;

export const GaleriaModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 20;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 1);
`;

export const GaleriaHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  span {
    color: #000;
    font-size: 0.8rem;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 0.5rem;
    border-radius: 9999px;
    background-color: #f5f5f5;
  }
`;

export const GaleriaCloseButton = styled.button`
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 9999px;
  background-color: #f5f5f5;
  transition: background-color 0.2s;

  :hover {
    transition: background-color 0.2s;
    background-color: #f8f8f8;
  }
`;

export const GaleriaGrid = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const ImageBlock = styled.div`
  width: ${({noSplit}) => (noSplit ? '100%' : 'calc(50% - 0.25rem)')};
  display: flex;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ScrollableContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

export const LogoCircle = styled.div`
  width: ${({miniature}) => (miniature ? '2rem' : '4rem')};
  height: ${({miniature}) => (miniature ? '2rem' : '4rem')};
  transition: width 0.3s ease-out, height 0.3s ease-out;
  border-radius: 9999px;
  overflow: hidden;
  position: absolute;
  right: -1rem;
  top: 0;
  border: 1px solid #f5f5f5;
  padding: 0.5rem;
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({badgeEscura}) => (badgeEscura ? '#262626' : '#f7f7f7')};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const WhatsappButton = styled.a`
  padding: 0.5rem;
  border-radius: 9999px;
  background-color: #25d366;
  font-weight: 600;
  font-size: 0.875rem;
`;

export const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 1280px;
  }

  @media screen and (min-width: 1536px) {
    max-width: 1536px;
  }
`;
