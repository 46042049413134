import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Select, Table } from 'antd';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Icon, Modal } from 'semantic-ui-react';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import Page from '~/components/Page';
import EmptyBox from '~/components/EmptyBox';
import usePessoa from '~/actions/Pessoa/usePessoa';
import ModalConfirm from '~/components/ModalConfirm';
import HeaderFiltros from '~/components/HeaderFiltro';

import enumPessoaTipo from '~/infra/resources/strings/enums/tipoPessoa';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import formatTelefone from '~/shared/formatTelefone';

import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

export function rowEdit(row, identificador) {
  history.push({
    pathname: `/app/pessoa/manutencao/${MODO.EDITAR}`,
    state: { row, identificador },
  });
}

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/pessoa/manutencao/${MODO.CADASTRAR}`,
    state: { identificador },
  });
}

function ConsultaPessoa() {
  const [actions, setActions] = useState([]);
  const [current, setCurrent] = useState(1);
  const [loadTable, setloadTable] = useState(false);
  const [loadPromover, setloadPromover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pessoaPromocao, setPessoaPromocao] = useState({});
  const [pesquisa, setPesquisa] = useState('');
  const [tipo, setTipo] = useState(undefined);
  const [pessoaId, setPessoaId] = useState(undefined);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalPromover, setOpenModalPromover] = useState(false);
  const { isLoading, data, quantidade, onLoad } = usePessoa(
    current,
    10,
    null,
    tipo,
    null,
    pesquisa,
  );

  const ref = useRef();
  const [form] = Form.useForm();

  const { identificador } = useParams();

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const master = roles.find((x) => x.toUpperCase() === 'MASTER');
  const masterLeads =
    localStorage.getItem('icaseMasterLeads') === 'false' ? false : true;

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);

    setActions(actions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  async function promoverUsuario() {
    setloadPromover(true);

    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          pessoaId: pessoaPromocao.id,
          email: dados.email,
        };

        await api
          .post('auth/promover-pessoa-corretor', payload)
          .then((res) => {
            setloadPromover(false);
            handleCloseModalPromover();
            onLoad();
            Notify(
              'success',
              '',
              'Usuário vinculado com sucesso!, um E-mail de confirmação foi enviado!',
            );
          })
          .catch((error) => {
            form.resetFields();
            setloadPromover(false);
            handleCloseModalPromover();
            const { errors } = error.response.data;
            if (errors) {
              for (const item in errors) {
                Notify('error', pt.comum.atencao, errors[item]);
              }
            }
          });
      })
      .catch((error) => {
        setloadPromover(false);
      });
  }

  function vincularUsuario(row) {
    setPessoaPromocao(row);
    setOpenModalPromover(true);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setPessoaId(id);
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  function handleCloseModalPromover() {
    setOpenModalPromover(false);
  }

  function onFiltros() {
    setloadTable(true);

    const { pesquisa, tipo } = ref.current.getFieldsValue();

    setPesquisa(pesquisa);
    setTipo(tipo);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  async function handleConfirmDelete() {
    if (pessoaId) {
      setLoading(true);
      await api
        .delete(`${url.PESSOA.DELETAR()}/${pessoaId}`)
        .then((res) => {
          setloadTable(true);
          onLoad();
          setLoading(false);
          setloadTable(false);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const hasVincularUsuario = !!master || !!masterLeads;

  const renderDDI = (row) => {
    const dataCelular = row?.telefones.find(telefone => telefone.tipo === 2);

    return (
      <span key={row.id}>{dataCelular?.ddi ? `+${dataCelular?.ddi}` : ''}</span>
    );
  }

  const renderTelefone = (row) => {
    const dataCelular = row?.telefones.find(telefone => telefone.tipo === 2);

    return (
      <span key={row.id}>{
        dataCelular?.ddi === 55 || dataCelular?.ddi === null
          ? formatTelefone(dataCelular?.numero || '')
          : dataCelular
            ? dataCelular?.numero
            : ''
      }</span>
    );
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (row) => (
        <div className="p-1" key={row.index}>
          <span>{row === 1 ? 'Física' : 'Jurídica'}</span>
        </div>
      ),
    },
    {
      title: 'DDI',
      dataIndex: '',
      key: '',
      render: (row) => renderDDI(row),
    },
    {
      title: 'Celular',
      dataIndex: '',
      key: '',
      render: (row) => renderTelefone(row),
    },
    {
      title: 'Vincular',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          {hasVincularUsuario &&
            row?.firebirdId !== null &&
            row?.pessoaFisica?.possuiUsuarioVinculado !== true ? (
            <i
              onClick={() => vincularUsuario(row)}
              className="ri-user-star-line linkTable"
              style={{ fontSize: '26px', marginRight: '10px' }}></i>
          ) : null}
        </div>
      ),
    },
  ];

  if (editar) {
    columns.push({
      title: 'Editar',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => rowEdit(row, identificador)}
            className="ri-pencil-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  if (excluir) {
    columns.push({
      title: 'Excluir',
      key: '',
      fixed: 'right',
      width: 80,
      render: (row) => (
        <div className="p-1" key={row.index}>
          <i
            onClick={() => handleModalConfirm(row.id)}
            className="ri-delete-bin-5-fill linkTable"
            style={{ fontSize: '26px', marginRight: '10px' }}></i>
        </div>
      ),
    });
  }

  const isEmpty = !isLoading && !data.length && loadTable;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader className="pl-2">
                <h5 className="title">Controle de Pessoa</h5>
                <p className="category">Manutenção Pessoa</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Filtro</small>
                            </p>
                            <Form.Item className="my-2" name="pesquisa">
                              <Input placeholder="Nome do cliente, email, CPF/CNPJ ou telefone" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <p className="heading-small text-muted mb-2">
                              <small>Tipo</small>
                            </p>
                            <Form.Item className="my-2" name="tipo">
                              <Select placeholder=" Tipo">
                                {enumPessoaTipo.map((item) => (
                                  <Option key={item.id}>{item.nome}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <p className="heading-small text-muted mb-2">
                              <small>&nbsp;</small>
                            </p>
                            <Button
                              className="mt-2"
                              size="tiny"
                              color="green"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="mt-2">
              <CardHeader>
                <h5 className="title mt-1">{`Resultados: (${quantidade})`}</h5>
                {incluir === true ? (
                  <HeaderFiltros
                    navToNovo={() => navToNovo(identificador)}
                    description="Nova Pessoa"
                  />
                ) : null}
              </CardHeader>
              <CardBody>
                {!isEmpty && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={defaultPagination(
                          current,
                          quantidade,
                          changePage,
                        )}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              message="Deseja realmente excluir esse registro?"
            />

            <Modal
              closeOnDimmerClick={false}
              size="small"
              dimmer="blurring"
              open={openModalPromover}
              onClose={() => handleCloseModalPromover()}>
              <Modal.Content>
                <div className="d-flex flex-column justify-content-center align-items-center p-2">
                  <h4 className="mt-2 font-weight-light">
                    Informe um e-mail para o usuário
                  </h4>
                </div>

                <Form form={form}>
                  <Row className="justify-content-center">
                    <Col xs="12" md="9" className="my-2">
                      <Form.Item
                        className="my-2"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message: 'Favor inserir um E-mail válido',
                          },
                        ]}>
                        <Input placeholder=" Email" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => handleCloseModalPromover()}>
                  <Icon name="times" />
                  Cancelar
                </Button>
                <Button
                  content="Promover Usuário"
                  labelPosition="right"
                  icon="checkmark"
                  isloading={loadPromover.toString()}
                  loadtext=" Promovendo..."
                  onClick={() => promoverUsuario()}
                  color="blue"
                />
              </Modal.Actions>
            </Modal>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaPessoa;

