import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useTimeFavorito(current, pageSize, descricao) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const filterCurrent = current ? current : null;
  const tamanhoPagina = pageSize ? pageSize : null;
  const filterDescricao = descricao ? descricao : null;
  let filter = null;

  if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

  if (tamanhoPagina) {
    if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
    else filter = `?tamanhoPagina=${tamanhoPagina}`;
  }

  if (filterDescricao) {
    if (filter.length > 0) filter += `&nome=${filterDescricao}`;
    else filter = `?nome=${filterDescricao}`;
  }

  const onLoad = useCallback(async () => {
    try {
      const data = await api.get(`${url.COMIDA_FAVORITA.CONSULTA()}${filter ? filter : ''}`);
      setData(data.data || []);
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descricao]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
