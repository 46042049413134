import React from "react";
import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import QRCode from 'qrcode';

import Header from './Header';
import Footer from './Footer';
import { config } from '../commom/config'

import enumBenfeitoriasImovel from '~/infra/resources/strings/enums/benfeitoriasImovel';

import formatCurrency from '~/shared/formatCurrency';
import ImgImovel from '~/assets/img/sala.jpg';
import chunckArray from "~/infra/resources/pattern/chunkArray";

const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingHorizontal: 20,
    paddingBottom: 175,
    color: config.textColor,
    fontFamily: config.fontFamily,
  },
  info: {
    fontSize: config.tertiaryFontSize,
  },
  title: {
    fontWeight: config.fontWeight,
    fontSize: config.primaryFontSize,
    color: config.titleColor,
    marginBottom: 20,
  },
  containerGeneralInformations: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 30,
  },
  generalInformations: {
    display: 'flex',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  containerSecondText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textBold: {
    fontWeight: config.fontWeight,
    fontSize: config.secondaryFontSize,
  },
  text: {
    fontSize: config.secondaryFontSize,
  },
  image: {
    width: 271,
    height: 132,
  },
  containerBenfeitorias: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  rowBenfeitorias: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 11,
  },
  cellBenfeitorias: {
    width: '33%',
    fontSize: config.secondaryFontSize,
  },
  extraInformation: {
    fontSize: config.secondaryFontSize,
    textAlign: 'justify',
  },
});

const generateQRCode = async (urlSite) => {
  try {
    return await QRCode.toDataURL(urlSite || 'www.google.com', { width: 56, margin: 0 });
  } catch (err) {
    return '';
  }
}

const GeneralInformations = ({ corretor, empresa, imovel, index }) => {
  const urlSite = `${imovel?.empresa?.urlSite}/imovel/${imovel?.codImovel}`;
  const urlQRCode = generateQRCode(urlSite);

  let numberSpan = 0;
  const defaultBeneficios = [];

  if (imovel?.caracteristica?.piscina) defaultBeneficios.push('PI');
  if (imovel?.caracteristica?.armarios) defaultBeneficios.push('AR');
  if (imovel?.caracteristica?.despensas) defaultBeneficios.push('DE');
  if (imovel?.caracteristica?.mobiliado) defaultBeneficios.push('MO');
  if (imovel?.caracteristica?.salasDeJantar) defaultBeneficios.push('SJ');
  if (imovel?.caracteristica?.closets) defaultBeneficios.push('CL');
  if (imovel?.caracteristica?.escritorios) defaultBeneficios.push('ES');
  if (imovel?.caracteristica?.lavabos) defaultBeneficios.push('LA');
  if (imovel?.caracteristica?.dces) defaultBeneficios.push('DC');
  if (imovel?.caracteristica?.banheirosServico) defaultBeneficios.push('BS');
  if (imovel?.caracteristica?.varandas) defaultBeneficios.push('VA');
  if (imovel?.caracteristica?.armarioCozinha) defaultBeneficios.push('AC');
  if (imovel?.caracteristica?.churrasqueiras) defaultBeneficios.push('CH');
  if (imovel?.caracteristica?.saloesFesta) defaultBeneficios.push('SF');
  if (imovel?.caracteristica?.saloesDeGinastica) defaultBeneficios.push('SG');
  if (imovel?.caracteristica?.quadraDeEsportes) defaultBeneficios.push('QE');
  if (imovel?.caracteristica?.quintal) defaultBeneficios.push('QU');
  if (imovel?.caracteristica?.copa) defaultBeneficios.push('CO');
  if (imovel?.caracteristica?.redeEsgoto) defaultBeneficios.push('RE');
  if (imovel?.caracteristica?.minhaCasaMinhaVida) defaultBeneficios.push('MV');

  const result = chunckArray(3, defaultBeneficios);

  const imagemDestaque = imovel?.imagens.find(imagem => imagem.destaque === true);

  const textoSuites =
    imovel?.caracteristicas?.quantidadeSuites ?
      `(sendo ${imovel?.caracteristicas?.quantidadeSuites} suítes)` : '';

  const textoGaragensCobertasDescobertas =
    imovel?.caracteristicas?.vagasGaragem ?
      `(sendo ${imovel?.caracteristicas?.vagasGaragem} cobertas)` : '';

  const textoGaragens =
    imovel?.caracteristicas?.vagasGaragemDescobertas ?
      `${imovel?.caracteristicas?.vagasGaragemDescobertas}${textoGaragensCobertasDescobertas}` :
      `${imovel?.caracteristicas?.vagasGaragem ? `${imovel?.caracteristicas?.vagasGaragem} (todas cobertas)` : '-'}`;

  const imovelNumber = index + 1;
  let textInfo = `Imóvel ${imovelNumber.toString().padStart(2, '0')}`;
  textInfo += imovel?.tipoImovel?.descricao ? ` - ${imovel?.tipoImovel?.descricao}` : '';
  textInfo += imovel?.logradouro?.bairro ? ` - ${imovel?.logradouro?.bairro?.nome} - ${imovel?.logradouro?.bairro?.cidade?.nome}` : '';

  return (
    <Page size="A4" style={styles.page}>
      <Header logoEmpresaUrl={corretor?.logoEmpresaUrl}/>

      <Text style={styles.info}>{textInfo}</Text>
      <Text style={styles.title}>Informações gerais</Text>
      <View style={styles.containerGeneralInformations}>
        <View style={styles.generalInformations}>
          <View style={[styles.containerText, { marginBottom: 12 }]}>
            <Text style={styles.textBold}>Tipo: </Text>
            <Text style={styles.text}>{imovel?.tipoImovel?.descricao || ''}</Text>
          </View>
          <View style={[styles.containerText, { marginBottom: 12 }]}>
            <Text style={styles.textBold}>Área util: </Text>
            <Text style={styles.text}>
              {
                imovel?.caracteristica?.areaConstruida ?
                  `${imovel?.caracteristica?.areaConstruida} ${imovel?.caracteristica?.areaPrivadaUniMedida?.descricao}` :
                  '-'
              }
            </Text>
          </View>
          <View style={[styles.containerText, { marginBottom: 12 }]}>
            <Text style={styles.textBold}>Área total: </Text>
            <Text style={styles.text}>
              {
                imovel?.caracteristica?.areaTerreno ?
                  `${imovel?.caracteristica?.areaTerreno} ${imovel?.caracteristica?.areaTotalUniMedida?.descricao}` :
                  '-'
              }
            </Text>
          </View>
          <View style={[styles.containerText, { marginBottom: 12 }]}>
            <Text style={styles.textBold}>Quartos: </Text>
            <Text style={styles.text}>
              {`${imovel?.caracteristicas?.quantidadeQuartos || '-'}${textoSuites}`}
            </Text>
          </View>
          <View style={[styles.containerText, { marginBottom: 12 }]}>
            <Text style={styles.textBold}>Vagas de garagem: </Text>
            <Text style={styles.text}>{textoGaragens || '-'}</Text>
          </View>
          <View style={[styles.containerText, { marginBottom: 12 }]}>
            <Text style={styles.textBold}>Valor de venda: </Text>
            <Text style={styles.text}>{imovel?.valorVenda ? `R$ ${formatCurrency(imovel?.valorVenda)}` : '-'}</Text>
          </View>
          <View style={styles.containerText}>
            <Text style={styles.textBold}>Valor de aluguel: </Text>
            <Text style={styles.text}>{imovel?.valorAluguel ? `R$ ${formatCurrency(imovel?.valorAluguel)}` : '-'}</Text>
          </View>
        </View>
        <View>
          <Image src={imagemDestaque?.arquivo || ImgImovel} style={[styles.image, { marginBottom: 15 }]} />
          <View style={styles.containerSecondText}>
            <View style={styles.containerText}>
              <Text style={styles.textBold}>Código: </Text>
              <Text style={styles.text}>{imovel?.codImovel || '-'}</Text>
            </View>
            <View style={styles.containerText}>
              <Image style={{ height: 56, width: 56 }} src={urlQRCode} />
            </View>
          </View>
        </View>
      </View>

      <Text style={styles.title}>Características</Text>
      {defaultBeneficios.length > 0 && (
        <View syle={styles.containerBenfeitorias}>
          {result.map((resultItem, index) => (
            <View key={index} style={styles.rowBenfeitorias}>
              {enumBenfeitoriasImovel.map((item) => {
                const index = resultItem.findIndex(
                  (beneficio) => beneficio === item.value,
                );
                if (index > -1) numberSpan++;

                return (index > -1 ?
                  <Text
                    key={`${numberSpan}: ${item.label}`}
                    style={styles.cellBenfeitorias}>
                    {`${numberSpan}: ${item.label}`}
                  </Text>
                  : null
                )
              })}
            </View>
          ))}
        </View>
      )}

      <Text style={[styles.title, { marginTop: 18 }]}>Informações extras</Text>
      <Text style={styles.extraInformation}>{imovel?.caracteristica?.caracteristicas || ''}</Text>

      <Footer corretor={corretor} empresa={empresa} />
    </Page>
  );
};

export default GeneralInformations;
