import React, { useState, Fragment } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select } from 'antd';

import { mask, unMask } from 'remask';

import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import ModalConfirm from '~/components/ModalConfirm';


import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import coutriesDDI from '~/infra/resources/strings/enums/countries';
import leadMp4 from '~/assets/videos/lead.mp4';
import pattern from '~/infra/resources/pattern/mask';
import './style.css';


import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');



function AcademiaVendas(props) {
  const [loading, setIsLoading] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [form] = Form.useForm();

  function handleMask(e, type, field) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (field === 'celular' || field === 'telefone') {
      const fieldValue = form.getFieldValue('contato');

      if (
        (fieldValue?.celularDDI.includes("BR") && field === 'celular')
        ||
        (fieldValue?.telefoneDDI.includes("BR") && field === 'telefone')
      )
        form.setFieldsValue({ contato: { [field]: value } });
      else
        form.setFieldsValue({ contato: { [field]: unMask(e.currentTarget.value.replace(/\D/g, '')) } });
    }
    else
      form.setFieldsValue({ [field]: value });
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const splitedCelularCodeISO = dados?.contato?.celularDDI?.split('+');

        let celularDDI = undefined;
        if (splitedCelularCodeISO)
          celularDDI = coutriesDDI.find(item => item.iso === splitedCelularCodeISO[0]);


        const payload = {
          nome: dados.nome,
          email: dados.email,
          celular: dados?.contato?.celular
            ? dados?.contato.celular.replace(/\D/g, '')
            : dados?.contato.celular,
          celularDdi: celularDDI?.code
            ? Number(celularDDI?.code.replace('+', ''))
            : undefined,
          celularDdiIso: celularDDI?.iso
        };

        await api
          .post('academia', payload)
          .then(() => {
            setIsLoading(false);
            setOpenModalConfirm(true);

            form.resetFields();

            setTimeout(() => setOpenModalConfirm(false), 5000);
          })
          .catch((err) => {
            setIsLoading(false);
            const { error, errors } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }

            if (errors) {
              for (const item in errors) {
                Notify('error', pt.comum.atencao, errors[item]);
              }
            }
          });

      })
      .catch(() => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  return (
    <Fragment>
      <video loop autoplay="" muted id="myVideo">
        <source src={leadMp4} type="video/mp4" />
      </video>
      <div id="latitude-academia">
        <Row>
          <Col>
            <Card className="latitude-academia-card">
              <CardBody className="px-3 py-4">
                <Row className="mx-1 pb-2">
                  <Col xs="12">
                    <div id="latitude-academia-header">
                      LATITUDE
                    </div>
                  </Col>
                </Row>
                <Form
                  form={form}
                  initialValues={{
                    contato: {
                      celularDDI: 'BR+55',
                      telefoneDDI: 'BR+55',
                    }
                  }}
                  onFinish={onSave}
                  scrollToFirstError
                >
                  <Row className="mx-1">
                    <Col xs="12">
                      <p className="latitude-academia-label">Nome</p>
                      <Form.Item
                        className="mb-3"
                        name="nome"
                        rules={[
                          {
                            required: true,
                            message: 'Favor informar o Nome',
                          },
                          // eslint-disable-next-line no-empty-pattern
                          ({ }) => ({
                            validator(value) {
                              if (value) {
                                const fieldValue = form.getFieldValue('nome');
                                if (fieldValue.length < 5) {
                                  return Promise.reject('Infome no mínimo 5 caracteres.');
                                }
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <Input size='large'

                          placeholder=" Nome" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mx-1">
                    <Col xs="12">
                      <p className="latitude-academia-label">E-mail</p>
                      <Form.Item
                        autoComplete="off"
                        className="mb-3"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message: 'Favor informar um E-mail'
                          },
                        ]}>
                        <Input placeholder=" E-mail"
                          size='large'

                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mx-1">
                    <Col xs="12">
                      <p className="latitude-academia-label">Celular</p>
                      <Form.Item className="mb-3">
                        <Input.Group compact>
                          <Form.Item noStyle name={['contato', 'celularDDI']}>
                            <Select
                              size='large'
                              dropdownMatchSelectWidth={false}
                              placeholder=" DDI"
                              style={{ width: '35%' }}
                              showSearch
                              filterOption={(input, option) =>
                                option.label.props.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                  .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                              }
                              options={coutriesDDI.map(item => ({
                                label: <span style={{ height: "53px" }}>{`${item.name}(${item.code})`}</span>,
                                value: `${item.iso}${item.code}`
                              }))} />
                          </Form.Item>
                          <Form.Item
                            noStyle
                            name={['contato', 'celular']}
                            rules={[
                              {
                                required: true,
                                message: 'Favor informar o celular',
                              },
                              // eslint-disable-next-line no-empty-pattern
                              ({ }) => ({
                                validator(value) {
                                  if (value) {
                                    const fieldValue = form.getFieldValue('contato');
                                    const unMaskedValue = unMask(fieldValue?.celular);
                                    const regex = new RegExp(/^(?:(55\d{2})|\d{2})[6-9]\d{8}$/);
                                    if (!regex.test(unMaskedValue) && fieldValue?.celularDDI.includes('BR')) {
                                      return Promise.reject('Número de celular inválido');
                                    }
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}>
                            <Input
                              size='large'
                              onKeyUp={(e) => handleMask(e, pattern.celular, 'celular')}
                              maxLength={20}
                              placeholder=" DDD + Telefone"
                              style={{ width: '65%' }}
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mx-1">
                    <Col xs="12">
                      <Form.Item>
                        <div className="mt-4 text-center">
                          <Button
                            style={{ height: 70, fontSize: 22 }}
                            fluid
                            className="my-1"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext="Validando...">
                            PARTICIPAR
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>


      <ModalConfirm
        isDelete={false}
        icon="check circle"
        open={openModalConfirm}
        close={() => setOpenModalConfirm(false)}
        message="Parabéns! você está participando do LATITUDE Lead Experience!"
      />
    </Fragment>
  );
}

export default AcademiaVendas;

