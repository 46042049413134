import styled from "styled-components";

import Sider from "antd/lib/layout/Sider";
import { device } from "~/styles/dimensions";

export const SiderMenu = styled(Sider)`
    ${device.medium} {
        display: none;
    }
`;
export const UserInfoContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 370px) {
        display: none;
    }
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 490px) {
        display: none;
    }
`;