import React, {useEffect, useState} from 'react';
import {Switch, InputNumber, Form, Collapse, Radio, Space, Input} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {Icon} from 'semantic-ui-react';

import Notify from '~/shared/Notify';
import Button from '~/shared/Button';
import Page from '~/components/Page';

import useConfigSistema from '~/actions/Empresa/useConfigSistema';

import * as configConst from '~/infra/config/configSistemaConst';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import url from '~/infra/urls';
import {CaretDownOutlined} from '@ant-design/icons';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import {HexColorInput, HexColorPicker} from 'react-colorful';

function Geral() {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [integrarComOrulo, setIntegrarComOrulo] = useState(false);
  const {data, checkConfig, checkTextConfig, getValueNumberConfig, isLoading} =
    useConfigSistema();

  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#000000');

  const [form] = Form.useForm();

  useEffect(() => {
    setInitialValues({
      analiseFinanceira: checkConfig(configConst.UTILIZA_ANALISE_FINANCEIRA),
      boardAniversariantes: checkConfig(configConst.BOARD_ANIVERSARIANTES),
      boardOperacaoImob: checkConfig(configConst.BOARD_OPERACAO_IMOBILIARIA),
      boardTipoMidia: checkConfig(configConst.BOARD_TIPO_MIDA),
      controlaIR: checkConfig(configConst.CONTROLA_IR),
      controlaOcorrencias: checkConfig(configConst.CONTROLA_OCORRENCIAS),
      consultarImoveisRedeLatitude: checkConfig(
        configConst.CONSULTAR_IMOVEIS_REDE_LATITUDE,
      ),
      diasAtendimentoIrreg: getValueNumberConfig(
        configConst.DIAS_ATENDIMENTO_IRREGULAR,
      ),
      integrarOneplus: checkConfig(configConst.INTEGRAR_COM_ONEPLUS),
      permiteLeadDuplicado: checkConfig(configConst.PERMITE_LEAD_DUPLICADO),
      integrarComOrulo: checkConfig(configConst.INTEGRAR_COM_A_ORULO),
      oruloClientId: checkTextConfig(configConst.ORULO_CLIENT_ID),
      oruloClientSecret: checkTextConfig(configConst.ORULO_CLIENT_SECRET),
      notificaGerenteAtendimentoIrregular: checkConfig(
        configConst.NOTIFICAR_GERENTE_ATENDIMENTO_IRREGULAR,
      ),
      permiteTransfCarteiraPropria: checkConfig(
        configConst.PERMITE_TRANSF_CARTEIRA_PROPRIA,
      ),
      direcionaLeadParaCaptador: checkConfig(
        configConst.DIRECIONA_LEAD_CAPTADOR,
      ),
      exibirEnderecoRedeLatitude: checkConfig(
        configConst.EXIBIR_ENDERECO_REDE_LATITUDE,
      ),
      tipoEnderecoXml: checkTextConfig(configConst.TIPO_ENDERECO_XML) ?? 'C',
      primaryColor:
        checkTextConfig(configConst.COR_PRIMARIA_APRESENTACAO) ?? '#FFFFFF',
      secondaryColor:
        checkTextConfig(configConst.COR_SECUNDARIA_APRESENTACAO) ?? '#1d1f8c',
      badgeEscura: checkConfig(configConst.BADGE_ESCURA),
    });

    if (data.length > 0) {
      setPrimaryColor(
        checkTextConfig(configConst.COR_PRIMARIA_APRESENTACAO) ?? '#FFFFFF',
      );
      setSecondaryColor(
        checkTextConfig(configConst.COR_SECUNDARIA_APRESENTACAO) ?? '#1d1f8c',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function onSave() {
    setLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        const payload = [
          {
            nomeParametro: configConst.UTILIZA_ANALISE_FINANCEIRA,
            valor: dados.analiseFinanceira,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.BOARD_TIPO_MIDA,
            valor: dados.boardTipoMidia,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.BOARD_OPERACAO_IMOBILIARIA,
            valor: dados.boardOperacaoImob,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.BOARD_ANIVERSARIANTES,
            valor: dados.boardAniversariantes,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.CONTROLA_OCORRENCIAS,
            valor: dados.controlaOcorrencias,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.CONTROLA_IR,
            valor: dados.controlaIR,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.CONSULTAR_IMOVEIS_REDE_LATITUDE,
            valor: dados.consultarImoveisRedeLatitude,
            numberValue: 0,
            textValue: null,
          },
          {
            valor: true,
            nomeParametro: configConst.DIAS_ATENDIMENTO_IRREGULAR,
            numberValue: dados.diasAtendimentoIrreg,
            textValue: null,
          },
          {
            nomeParametro: configConst.INTEGRAR_COM_ONEPLUS,
            valor: dados.integrarOneplus,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.PERMITE_LEAD_DUPLICADO,
            valor: dados.permiteLeadDuplicado,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.INTEGRAR_COM_A_ORULO,
            valor: dados.integrarComOrulo,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.ORULO_CLIENT_SECRET,
            valor: true,
            numberValue: 0,
            textValue: dados.oruloClientSecret,
          },
          {
            nomeParametro: configConst.ORULO_CLIENT_ID,
            valor: true,
            numberValue: 0,
            textValue: dados.oruloClientId,
          },
          {
            nomeParametro: configConst.NOTIFICAR_GERENTE_ATENDIMENTO_IRREGULAR,
            valor: dados.notificaGerenteAtendimentoIrregular,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.PERMITE_TRANSF_CARTEIRA_PROPRIA,
            valor: dados.permiteTransfCarteiraPropria,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.DIRECIONA_LEAD_CAPTADOR,
            valor: dados.direcionaLeadParaCaptador,
            numberValue: 0,
            textValue: null,
          },
          {
            nomeParametro: configConst.EXIBIR_ENDERECO_REDE_LATITUDE,
            valor: dados.exibirEnderecoRedeLatitude,
            numberValue: 1,
            textValue: null,
          },
          {
            nomeParametro: configConst.TIPO_ENDERECO_XML,
            valor: true,
            numberValue: 0,
            textValue: dados.tipoEnderecoXml,
          },
          {
            nomeParametro: configConst.COR_PRIMARIA_APRESENTACAO,
            valor: true,
            numberValue: 0,
            textValue: primaryColor,
          },
          {
            nomeParametro: configConst.COR_SECUNDARIA_APRESENTACAO,
            valor: true,
            numberValue: 0,
            textValue: secondaryColor,
          },
          {
            nomeParametro: configConst.BADGE_ESCURA,
            valor: dados.badgeEscura,
            numberValue: 0,
            textValue: null,
          },
        ];

        api
          .post(url.CONFIGURACOES_SISTEMA.CONFIGURACOES_SISTEMA(), payload)
          .then(async (res) => {
            Notify('success', '', 'Configuração atualizada com sucesso');

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((err) => {
            setLoading(false);
            const {error} = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch((errorInfo) => {
        setLoading(false);
        window.scrollTo(1, 1);
      });
  }

  return isLoading ? (
    <SkeletonTableBasic />
  ) : (
    <Page loadPage={true}>
      <Row>
        <Col>
          <Card className="p-2">
            <CardHeader>
              <h5>Configurações gerais</h5>
              <p className="category">Controle de parâmetros</p>
            </CardHeader>
            <CardBody>
              <div className="p-2">
                <Form
                  form={form}
                  initialValues={initialValues}
                  scrollToFirstError>
                  <Collapse
                    className="fixPadding"
                    defaultActiveKey={['CP-1', 'CP-2', 'CP-2a', 'CP-3']}
                    bordered={false}
                    ghost
                    expandIconPosition="right"
                    expandIcon={({isActive}) => (
                      <div className="container-expand">
                        <span className="content-expand">
                          {isActive ? 'Ver menos' : 'Ver mais'}
                        </span>
                        <CaretDownOutlined
                          style={{fontSize: 20}}
                          rotate={isActive ? 180 : 0}
                        />
                      </div>
                    )}>
                    <Collapse.Panel key="CP-1" header="Dashboard">
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Utiliza Análise Financeira?</small>
                          </p>
                          <Form.Item className="my-2" name="analiseFinanceira">
                            <Switch
                              defaultChecked={initialValues.analiseFinanceira}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-0">
                            <small>Dashboard Tipo Midia?</small>
                          </p>
                          <Form.Item className="my-2" name="boardTipoMidia">
                            <Switch
                              defaultChecked={initialValues.boardTipoMidia}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Dashboard Imóveis?</small>
                          </p>
                          <Form.Item className="my-2" name="boardOperacaoImob">
                            <Switch
                              defaultChecked={initialValues.boardOperacaoImob}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Dashboard Aniversariantes?</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="boardAniversariantes">
                            <Switch
                              defaultChecked={
                                initialValues.boardAniversariantes
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Controla Ocorrências ?</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="controlaOcorrencias">
                            <Switch
                              defaultChecked={initialValues.controlaOcorrencias}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Controla Imposto Renda ?</small>
                          </p>
                          <Form.Item className="my-2" name="controlaIR">
                            <Switch
                              defaultChecked={initialValues.controlaIR}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-2" header="Imóveis">
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Consulta Imóveis Rede Latitude?</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="consultarImoveisRedeLatitude">
                            <Switch
                              defaultChecked={
                                initialValues.consultarImoveisRedeLatitude
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>
                              Exibir Endereço Completo de Imóveis na Rede
                              Latitude?
                            </small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="exibirEnderecoRedeLatitude">
                            <Switch
                              defaultChecked={
                                initialValues.exibirEnderecoRedeLatitude
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>
                              Direciona novos Leads para o captador do imóvel?
                            </small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="direcionaLeadParaCaptador">
                            <Switch
                              defaultChecked={
                                initialValues.direcionaLeadParaCaptador
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-2a" header="Integrações">
                      <Row>
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-2">
                            <small>Integra com a Órulo?</small>
                          </p>
                          <Form.Item className="my-2" name="integrarComOrulo">
                            <Switch
                              defaultChecked={initialValues.integrarComOrulo}
                              onChange={(value) => setIntegrarComOrulo(value)}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>

                        {(integrarComOrulo ||
                          checkConfig(configConst.INTEGRAR_COM_A_ORULO)) && (
                          <>
                            <Col xs="12" md="5">
                              <p className="heading-small text-muted mb-2">
                                <small>Client Secret</small>
                              </p>
                              <Form.Item
                                className="my-2"
                                name="oruloClientSecret">
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs="12" md="5">
                              <p className="heading-small text-muted mb-2">
                                <small>Client Id</small>
                              </p>
                              <Form.Item className="my-2" name="oruloClientId">
                                <Input />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col xs="12" md="2">
                          <p className="heading-small text-muted mb-2">
                            <small>Integra com a Secretaria de Vendas?</small>
                          </p>
                          <Form.Item className="my-2" name="integrarOneplus">
                            <Switch
                              defaultChecked={initialValues.integrarOneplus}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Tipo Endereço XML no Canal Pro</small>
                          </p>
                          <Form.Item className="my-2" name="tipoEnderecoXml">
                            <Radio.Group>
                              <Space direction="vertical">
                                <Radio value="C">Completo</Radio>
                                <Radio value="R">Somente Rua</Radio>
                                <Radio value="B">Somente Bairro</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-3" header="Atendimentos">
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Dias para atendimento irregular</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="diasAtendimentoIrreg">
                            <InputNumber min={0} />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>
                              Notificar Gerente de Atendimento Irregular?
                            </small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="notificaGerenteAtendimentoIrregular">
                            <Switch
                              defaultChecked={
                                initialValues.notificaGerenteAtendimentoIrregular
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Permite Lead duplicado?</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="permiteLeadDuplicado">
                            <Switch
                              defaultChecked={
                                initialValues.permiteLeadDuplicado
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Permite transferir carteira própria?</small>
                          </p>
                          <Form.Item
                            className="my-2"
                            name="permiteTransfCarteiraPropria">
                            <Switch
                              defaultChecked={
                                initialValues.permiteTransfCarteiraPropria
                              }
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel key="CP-3" header="Padrões de cores">
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Cor primária de apresentação (texto)</small>
                          </p>
                          <Form.Item className="my-2" name="primaryColor">
                            <HexColorPicker
                              color={primaryColor}
                              onChange={setPrimaryColor}
                            />
                            <HexColorInput
                              prefixed
                              name="primaryColor"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={primaryColor}
                              onChange={setPrimaryColor}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>
                              Cor secundária de apresentação (fundo)
                            </small>
                          </p>
                          <Form.Item className="my-2" name="primaryColor">
                            <HexColorPicker
                              color={secondaryColor}
                              onChange={setSecondaryColor}
                            />
                            <HexColorInput
                              prefixed
                              name="secondaryColor"
                              style={{
                                marginTop: 10,
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '5px',
                              }}
                              color={secondaryColor}
                              onChange={setSecondaryColor}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="3">
                          <p className="heading-small text-muted mb-2">
                            <small>Badge escuro da logo?</small>
                          </p>
                          <Form.Item className="my-2" name="badgeEscura">
                            <Switch
                              defaultChecked={initialValues.badgeEscura}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Form>
                <Row className="mt-2">
                  <Col xs="12" md="3">
                    <Button
                      onClick={onSave}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      color="violet"
                      isloading={loading.toString()}
                      loadtext={'Salvando...'}>
                      <Icon name="check" />
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}

export default Geral;
