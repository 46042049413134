import React from 'react';
import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import Header from "./Header";
import Footer from "./Footer";
import { config } from '../commom/config';

import enumBenfeitoriasImovel from '~/infra/resources/strings/enums/benfeitoriasImovel';

import formatCurrency from '~/shared/formatCurrency';
import ImgImovel from '~/assets/img/sala.jpg';

const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingHorizontal: 20,
    paddingBottom: 180,
    color: config.textColor,
    fontFamily: config.fontFamily,
  },
  info: {
    fontSize: '6px',
  },
  title: {
    fontWeight: config.fontWeight,
    fontSize: config.primaryFontSize,
    color: config.titleColor,
    marginBottom: 20,
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    width: '25%',
  },
  cellBorder: {
    borderTop: '1px solid #E6E6E6',
    borderBottom: '1px solid #E6E6E6',
  },
  text: {
    fontSize: config.tertiaryFontSize,
    paddingVertical: 2,
  },
  textBackground: {
    backgroundColor: '#f8f3fbbf',
  },
  image: {
    width: 130,
    height: 64,
  },
});

const generateTagText = (text, index, color = config.textColor) => (
  index % 2 === 0 ?
    <Text
      key={`${text}-${index}`}
      style={[styles.text, { color }]}>
      {text}
    </Text>
    :
    <Text
      key={`${text}-${index}`}
      style={[styles.text, styles.textBackground, { color }]}>
      {text}
    </Text>
)

const generateBenfeitorias = (defaultBeneficios, value, index) => {
  const exists = defaultBeneficios.includes(value);
  const text = exists ? 'SIM' : '-';
  const color = exists ? '#3F8C24' : config.textColor;

  return (generateTagText(text, index, color));
}

const generateValue = (area, value) => {
  const realValue = value && area ? value / area : 0;
  return Math.round(realValue * Math.pow(10, 2)) / Math.pow(10, 2);
}

const Compare = ({ corretor, empresa, imoveis }) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header logoEmpresaUrl={corretor?.logoEmpresaUrl}/>

      <Text style={styles.title} fixed>Comparativo</Text>
      <View style={styles.body} fixed>
        <View style={[styles.row, { marginBottom: 5 }]}>
          <View style={styles.cell} />
          {imoveis.map((imovel, index) => {
            const imagemDestaque = imovel?.imagens.find(imagem => imagem.destaque === true);

            const imovelNumber = index + 1;
            let textInfo = `Imóvel ${imovelNumber.toString().padStart(2, '0')}`;
            textInfo += imovel?.logradouro?.bairro ? ` - ${imovel?.logradouro?.bairro?.nome}` : '';

            return (
              <View key={`COMP-${imovel?.id}`} style={styles.cell}>
                {imagemDestaque ?
                  <Image style={styles.image} src={imagemDestaque?.arquivo} />
                  :
                  <Image style={styles.image} src={ImgImovel} />
                }
                <Text style={styles.info} fixed>{textInfo}</Text>
              </View>
            )
          })}
        </View>
      </View>

      <View style={styles.body}>
        <View style={styles.row}>
          <View style={[styles.cell, styles.cellBorder]}>
            <View style={styles.column}>
              <Text style={styles.text}>Dormitório</Text>
              <Text style={[styles.text, styles.textBackground]}>Suítes</Text>
              <Text style={styles.text}>Banheiros</Text>
              <Text style={[styles.text, styles.textBackground]}>Vagas de garagem</Text>
              <Text style={styles.text}>Área Útil/Construída</Text>
              <Text style={[styles.text, styles.textBackground]}>Área Total/Terreno</Text>
              <Text style={styles.text}>Valor (venda)</Text>
              <Text style={[styles.text, styles.textBackground]}>Valor (locação)</Text>
              <Text style={styles.text}>Valor/Área Útil (venda)</Text>
              <Text style={[styles.text, styles.textBackground]}>Valor/Área Útil (locação)</Text>
              <Text style={styles.text}>Valor/Área Total (venda)</Text>
              <Text style={[styles.text, styles.textBackground]}>Valor/Área Total (locação)</Text>
              {enumBenfeitoriasImovel.map((befeitoria, index) => (index <= 14 &&
                generateTagText(befeitoria.label, index)
              ))}
            </View>
          </View>

          {imoveis.map(imovel => {
            const defaultBeneficios = [];

            if (imovel?.caracteristica?.piscina) defaultBeneficios.push('PI');
            if (imovel?.caracteristica?.armarios) defaultBeneficios.push('AR');
            if (imovel?.caracteristica?.despensas) defaultBeneficios.push('DE');
            if (imovel?.caracteristica?.mobiliado) defaultBeneficios.push('MO');
            if (imovel?.caracteristica?.salasDeJantar) defaultBeneficios.push('SJ');
            if (imovel?.caracteristica?.closets) defaultBeneficios.push('CL');
            if (imovel?.caracteristica?.escritorios) defaultBeneficios.push('ES');
            if (imovel?.caracteristica?.lavabos) defaultBeneficios.push('LA');
            if (imovel?.caracteristica?.dces) defaultBeneficios.push('DC');
            if (imovel?.caracteristica?.banheirosServico) defaultBeneficios.push('BS');
            if (imovel?.caracteristica?.varandas) defaultBeneficios.push('VA');
            if (imovel?.caracteristica?.armarioCozinha) defaultBeneficios.push('AC');
            if (imovel?.caracteristica?.churrasqueiras) defaultBeneficios.push('CH');
            if (imovel?.caracteristica?.saloesFesta) defaultBeneficios.push('SF');
            if (imovel?.caracteristica?.saloesDeGinastica) defaultBeneficios.push('SG');
            if (imovel?.caracteristica?.quadraDeEsportes) defaultBeneficios.push('QE');
            if (imovel?.caracteristica?.quintal) defaultBeneficios.push('QU');
            if (imovel?.caracteristica?.copa) defaultBeneficios.push('CO');
            if (imovel?.caracteristica?.redeEsgoto) defaultBeneficios.push('RE');
            if (imovel?.caracteristica?.minhaCasaMinhaVida) defaultBeneficios.push('MV');

            const valorAreaUtilVenda = generateValue(imovel?.caracteristica?.areaConstruida, imovel?.valorVenda);
            const valorAreaUtilAluguel = generateValue(imovel?.caracteristica?.areaConstruida, imovel?.valorAluguel);

            const valorAreaTotalVenda = generateValue(imovel?.caracteristica?.areaTerreno, imovel?.valorVenda);
            const valorAreaTotalLocacao = generateValue(imovel?.caracteristica?.areaTerreno, imovel?.valorAluguel);

            return (
              <View key={`COMP-${imovel.id}`} style={[styles.cell, styles.cellBorder]}>
                <View style={styles.column}>
                  <Text style={styles.text}>{imovel?.caracteristica?.quantidadeQuartos || '-'}</Text>
                  <Text style={[styles.text, styles.textBackground]}>{imovel?.caracteristica?.quantidadeSuites || '-'}</Text>
                  <Text style={styles.text}>{imovel?.caracteristica?.quantidadeBanheiros || '-'}</Text>
                  <Text style={[styles.text, styles.textBackground]}>
                    {(imovel?.caracteristica?.vagasGaragemDescobertas || 0) +
                      (imovel?.caracteristica?.vagasGaragem || 0)}
                  </Text>
                  <Text style={styles.text}>{imovel?.caracteristica?.areaConstruida || '-'}</Text>
                  <Text style={[styles.text, styles.textBackground]}>{imovel?.caracteristica?.areaTerreno || '-'}</Text>
                  <Text style={styles.text}>
                    {
                      imovel?.valorVenda ?
                        `R$ ${formatCurrency(imovel?.valorVenda)}` : '-'
                    }
                  </Text>
                  <Text style={[styles.text, styles.textBackground]}>
                    {
                      imovel?.valorAluguel ?
                        `R$ ${formatCurrency(imovel?.valorAluguel)}` : '-'
                    }
                  </Text>
                  <Text style={styles.text}>
                    {valorAreaUtilVenda > 0 ? `R$ ${formatCurrency(valorAreaUtilVenda)}` : '-'}
                  </Text>
                  <Text style={[styles.text, styles.textBackground]}>
                    {valorAreaUtilAluguel > 0 ? `R$ ${formatCurrency(valorAreaUtilAluguel)}` : '-'}
                  </Text>
                  <Text style={styles.text}>
                    {valorAreaTotalVenda > 0 ? `R$ ${formatCurrency(valorAreaTotalVenda)}` : '-'}
                  </Text>
                  <Text style={[styles.text, styles.textBackground]}>
                    {valorAreaTotalLocacao > 0 ? `R$ ${formatCurrency(valorAreaTotalLocacao)}` : '-'}
                  </Text>
                  {enumBenfeitoriasImovel.map((befeitoria, index) => (index <= 14 &&
                    generateBenfeitorias(defaultBeneficios, befeitoria.value, index)
                  ))}
                </View>
              </View>
            )
          })}
        </View>
      </View>

      {enumBenfeitoriasImovel.length > 13 && (
        <View style={styles.body} break>
          <View style={styles.row}>
            <View style={[styles.cell, styles.cellBorder]}>
              <View style={styles.column}>
                {enumBenfeitoriasImovel.map((befeitoria, index) => (index > 14 &&
                  generateTagText(befeitoria.label, index)
                ))}
              </View>
            </View>

            {imoveis.map(imovel => {
              const defaultBeneficios = [];

              if (imovel?.caracteristica?.piscina) defaultBeneficios.push('PI');
              if (imovel?.caracteristica?.armarios) defaultBeneficios.push('AR');
              if (imovel?.caracteristica?.despensas) defaultBeneficios.push('DE');
              if (imovel?.caracteristica?.mobiliado) defaultBeneficios.push('MO');
              if (imovel?.caracteristica?.salasDeJantar) defaultBeneficios.push('SJ');
              if (imovel?.caracteristica?.closets) defaultBeneficios.push('CL');
              if (imovel?.caracteristica?.escritorios) defaultBeneficios.push('ES');
              if (imovel?.caracteristica?.lavabos) defaultBeneficios.push('LA');
              if (imovel?.caracteristica?.dces) defaultBeneficios.push('DC');
              if (imovel?.caracteristica?.banheirosServico) defaultBeneficios.push('BS');
              if (imovel?.caracteristica?.varandas) defaultBeneficios.push('VA');
              if (imovel?.caracteristica?.armarioCozinha) defaultBeneficios.push('AC');
              if (imovel?.caracteristica?.churrasqueiras) defaultBeneficios.push('CH');
              if (imovel?.caracteristica?.saloesFesta) defaultBeneficios.push('SF');
              if (imovel?.caracteristica?.saloesDeGinastica) defaultBeneficios.push('SG');
              if (imovel?.caracteristica?.quadraDeEsportes) defaultBeneficios.push('QE');
              if (imovel?.caracteristica?.quintal) defaultBeneficios.push('QU');
              if (imovel?.caracteristica?.copa) defaultBeneficios.push('CO');
              if (imovel?.caracteristica?.redeEsgoto) defaultBeneficios.push('RE');
              if (imovel?.caracteristica?.minhaCasaMinhaVida) defaultBeneficios.push('MV');

              return (
                <View key={`COMP-${imovel.id}`} style={[styles.cell, styles.cellBorder]}>
                  <View style={styles.column}>
                    {enumBenfeitoriasImovel.map((befeitoria, index) => (index > 14 &&
                      generateBenfeitorias(defaultBeneficios, befeitoria.value, index)
                    ))}
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      )}

      <Footer corretor={corretor} empresa={empresa} />
    </Page>
  );
}

export default Compare;
