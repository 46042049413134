import React, { Fragment, useState } from 'react';
import Page from '~/components/Page';
import { Row, Col } from 'reactstrap';
import HomeLoading from '~/components/HomeLoading';
import SkeletonTable from '~/components/SkeletonTableBasic';

function SecretariaVendas(props) {
  const [loadingIframe, setLoadingIframe] = useState(true);

  const userCpf = localStorage.getItem('icaseUserCpf');

  let id = props?.match?.params?.id;
  let clienteId = props?.match?.params?.clienteId;
  let imovelId = props?.match?.params?.imovelId;
  //let v2 = props?.match?.params?.v2;

  return (
    <Fragment>
      {loadingIframe && <HomeLoading />}
      {loadingIframe && <SkeletonTable />}
      <Page>
        <Row>
          <Col xs="12">
            <iframe
              title='Controle de Propostas | Secretaria de Vendas'
              style={{
                width: '100%',
                height: 'calc(100vh - 120px)',
                border: 'none'
              }}
              onLoad={() => setLoadingIframe(false)}
              src={`https://mybroker-nextjs.vercel.app/auth/signin?username=${userCpf}&lead=${clienteId}&atdId=${id}&imovelId=${imovelId}&v2=true`}
              //  src={`https://mybroker-nextjs.vercel.app/auth/signin?username=${userCpf}&lead=${clienteId}&atdId=${id}&imovelId=${imovelId}${!!v2 ? '&v2=true' : ''}`}
              loading="lazy"></iframe>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default SecretariaVendas;
