import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useTipoSolo(
  current,
  pageSize,
  nomeTipoSolo,
  isRecarregar,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterCurrent = current ? current : null;
    const tamanhoPagina = pageSize ? pageSize : null;
    const filterNomeTipoSolo = nomeTipoSolo ? nomeTipoSolo : null;
    let filter = null;

    if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

    if (tamanhoPagina) {
      if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
      else filter = `?tamanhoPagina=${tamanhoPagina}`;
    }

    if (filterNomeTipoSolo) {
      if (filter.length > 0) filter += `&nome=${filterNomeTipoSolo}`;
      else filter = `?nome=${filterNomeTipoSolo}`;
    }

    try {
      await api
        .get(`${url.TIPO_SOLO.CONSULTA()}${filter ? filter : ''}`)
        .then((res) => {
          setData(res?.data?.data || []);
          setQuantidade(res?.data?.data?.length);
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, nomeTipoSolo, isRecarregar, current]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
