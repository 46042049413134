/* eslint-disable no-unused-expressions */
import React, { Fragment, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardImg,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import { Icon, Transition } from 'semantic-ui-react';
import { Checkbox, Spin } from 'antd';

import formatCurrency from '~/shared/formatCurrency';
import formatLine from '~/shared/formatLine';
import imgImovel from '~/assets/img/sala.jpg';

import OperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import SituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';
import midiaDivulgacao from '~/infra/resources/strings/enums/midiaDivulgacao';

import api from '~/services/api';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import Notify from '~/shared/Notify';

import './style.css';

function RenderMidias({
  onLoadEntreAgencias,
  midiasImoveis,
  imovelId,
  midia,
}) {
  const [showSpin, setShowSpin] = useState(false);

  const filteredMidia = midiasImoveis.find(item => item.tipoMidiaId === midia.id);

  async function fetchImovelCompartilhado(imovelId, midiaId, imovelCompartilhadoId) {
    setShowSpin(true);

    if (imovelCompartilhadoId) {
      await api.delete(url.IMOVEL.DELETAR_IMOVEL_COMP(imovelCompartilhadoId))
        .then(async () => {
          await onLoadEntreAgencias(true);
          Notify('success', pt.comum.aviso, 'Mídia excluída com sucesso.');
          setShowSpin(false);
        })
        .catch(() => {
          Notify('error', pt.comum.atencao, 'Erro ao excluir a mídia do imóvel compartilhado.');
          setShowSpin(false);
        });
    }
    else {
      const payload = {
        imovelId,
        tipoMidiaId: midiaId,
      };

      await api.post(url.IMOVEL.CADASTRAR_IMOVEL_COMP(), payload)
        .then(async (res) => {
          await onLoadEntreAgencias(true);
          Notify('success', pt.comum.aviso, 'Imóvel selecionado com sucesso.');
          setShowSpin(false);
        })
        .catch(() => {
          Notify('error', pt.comum.atencao, 'Erro ao selecionar o imóvel compartilhado.');
          setShowSpin(false);
        });
    }
  }

  return (
    <Col xs="12" md="6">
      {showSpin && <Spin size='small' />}
      {!showSpin && (
        <Checkbox
          defaultChecked={filteredMidia}
          className='drawerFields m-1'
          onClick={() => fetchImovelCompartilhado(imovelId, midia.id, filteredMidia?.id)}
        >
          {midia.descricao}
        </Checkbox>
      )}
    </Col>
  )
}

function RowCardPublicImovel({
  imovel,
  onLoadEntreAgencias,
  dataEntreImoveis,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const operacao = OperacaoImobiliaria.find(
    (item) => item.id === imovel.operacaoImobiliaria,
  );

  const situacao = SituacaoImovel.find((item) => item.id === imovel.situacao);
  const hasImagens = imovel.imagens ? !!imovel.imagens[0] : false;

  const items = [];

  const initItems = async () => {
    await imovel?.imagens.forEach((imagem, index) => {
      items.push({ key: index + 1, src: imagem?.arquivo, altText: '' });
    });
  };

  initItems();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items
    ? items?.map((item) => {
      return (
        <CarouselItem
          className="containerWidth"
          key={item.src}
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}>
          <img
            style={{ height: '100%', width: '100%', borderRadius: '0.25rem 0.25rem 0 0' }}
            src={item.src}
            alt={item.altText}
          />
        </CarouselItem>
      );
    })
    : null;

  const midiasImoveis = dataEntreImoveis.filter(item => item.imovelId === imovel.id);

  return (
    <>
      <Card className={
        midiasImoveis.length > 0
          ? "h-100 selectedBox"
          : "h-100"
      }>
        <Row className="no-gutters">
          <Col className="p-0">
            <div style={{ position: 'relative' }}>
              {hasImagens ? (
                <Carousel
                  className="containerWidth"
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  interval={false}>
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Anterior"
                    onClickHandler={() => previous()}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Próximo"
                    onClickHandler={() => next()}
                  />
                </Carousel>
              ) : (
                <CardImg variant="top" src={imgImovel} />
              )}
            </div>
          </Col>
        </Row>
        <Row
          className="no-gutters"
          onMouseEnter={() => setShowDrawer(true)}
          onMouseLeave={() => setShowDrawer(false)}>
          <Col>
            <Transition
              animation='scale'
              visible={showDrawer}
              duration={250}>
              <div className='drawer p-3'>
                <Row className="px-2">
                  {midiaDivulgacao.map(midia =>
                    midia.id !== 103 && midia.id !== 115 && (
                      <RenderMidias
                        key={midia.id}
                        onLoadEntreAgencias={onLoadEntreAgencias}
                        midiasImoveis={midiasImoveis}
                        imovelId={imovel.id}
                        midia={midia} />
                    ))}
                </Row>
              </div>
            </Transition>
            <CardBody>
              <div className="pl-2 pt-1 drawerBack">
                <Row className="align-items-center">
                  <Col xs="3" md="4">
                    <span className="py-2 mr-2">
                      Código: <b>{imovel.codImovel}</b>
                    </span>
                  </Col>
                  {operacao && (
                    <Col xs="3" md="5">
                      <span className="py-2 font-weight-bold">
                        <Icon name="check circle" color="green" />
                        {operacao.nome.toUpperCase()}
                      </span>
                    </Col>
                  )}
                </Row>
                {imovel?.endereco?.condominio && (
                  <Row>
                    <Col xs="12">
                      <div className="mt-2 card-dashboard-number ">
                        <h3 className="font-weight-bold ">
                          {imovel.endereco.condominio.nome}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs="12">
                    <div className="mt-2 card-dashboard-number ">
                      <h4
                        className="font-weight-bold"
                        style={{ marginTop: '0.7em' }}>
                        {formatLine(imovel?.logradouro?.bairro?.nome, 18)}
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <p>
                        <span>
                          {`
                          ${imovel?.logradouro?.bairro?.cidade?.nome} -
                          ${imovel?.logradouro?.bairro?.cidade?.estado?.id}`}
                        </span>
                        {situacao ? (
                          <Fragment>
                            <span className="mx-2"> | </span>
                            <span>{situacao.nome.toUpperCase()}</span>
                          </Fragment>
                        ) : null}
                      </p>
                    </div>
                  </Col>
                </Row>
                <MetrosQuadrados imovel={imovel} />
                {imovel?.valorCondominio ? (
                  <Row>
                    <Col xs="12">
                      <span className="mr-2">
                        <b>Valor condomínio:</b> R${' '}
                        {formatCurrency(imovel?.valorCondominio)}
                      </span>
                    </Col>
                  </Row>
                ) : null}
                <ValorNegocio imovel={imovel} />
                <Row>
                  {imovel?.caracteristica?.quantidadeQuartos ? (
                    <Col xs="12" md="3">
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.quantidadeQuartos}</b>{' '}
                        Quarto(s)
                      </span>
                    </Col>
                  ) : null}
                  {imovel?.caracteristica?.quantidadeBanheiros ? (
                    <Col xs="12" md="3">
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.quantidadeBanheiros}</b>{' '}
                        Banheiro(s)
                      </span>
                    </Col>
                  ) : null}
                  {imovel?.caracteristica?.quantidadeSuites ? (
                    <Col xs="12" md="3">
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.quantidadeSuites}</b> Suíte(s)
                      </span>
                    </Col>
                  ) : null}
                  {imovel?.caracteristica?.vagasGaragem ? (
                    <Col xs="12" md="3">
                      <span className="immobile-itens">
                        <b>{imovel.caracteristica.vagasGaragem}</b> Vaga(s)
                      </span>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </>
  );
}

function CardPublicImovel({
  data,
  onLoadEntreAgencias,
  dataEntreImoveis,
}) {
  return (
    <Fragment>
      <Row>
        {data.map((imovel, index) => (
          <Col xs="12" md="4" className="mb-3" key={imovel?.id || index}>
            <RowCardPublicImovel
              key={index}
              imovel={imovel}
              onLoadEntreAgencias={onLoadEntreAgencias}
              dataEntreImoveis={dataEntreImoveis}
            />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
}

const MetrosQuadrados = ({ imovel }) => {
  const areaTerreno = imovel?.caracteristica?.areaTerreno;
  const areaTerrenoUnidadeMedida =
    imovel?.caracteristica?.areaTotalUniMedida?.descricao;
  const areaConstruida = imovel?.caracteristica?.areaConstruida;
  const areaConstruidaUnidadeMedida =
    imovel?.caracteristica?.areaPrivadaUniMedida?.descricao;

  const baseString = (label, value, unidadeMedida) => {
    if (value) {
      return (
        <span className="py-2 mr-2">
          <b>{label}:</b> {value} {unidadeMedida}
        </span>
      );
    }
    return null;
  };

  return (
    <Fragment>
      {(areaTerreno || areaConstruida) && (
        <Row className="my-2">
          {areaTerreno && (
            <Col xs="12" md="6">
              {baseString('Área total', areaTerreno, areaTerrenoUnidadeMedida)}
            </Col>
          )}
          {areaConstruida && (
            <Col xs="12" md="6">
              {baseString(
                'Área privada',
                areaConstruida,
                areaConstruidaUnidadeMedida,
              )}
            </Col>
          )}
        </Row>
      )}
    </Fragment>
  );
};

const ValorNegocio = ({ imovel }) => {
  return (
    <Row>
      {imovel?.valorVenda ? (
        <Col xs="4" md="6">
          <span>
            <b>Valor Venda</b>
          </span>
          <h4 className="font-weight-bold mt-0">
            R$ {formatCurrency(imovel?.valorVenda)}
          </h4>
        </Col>
      ) : null}
      {imovel?.valorAluguel ? (
        <Col xs="4" md="6">
          <span>
            <b>Valor Aluguel</b>
          </span>
          <h4 className="font-weight-bold mt-0">
            R$ {formatCurrency(imovel?.valorAluguel)}
          </h4>
        </Col>
      ) : null}
    </Row>
  );
};

export default CardPublicImovel;
