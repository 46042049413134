import { useCallback, useEffect, useState } from "react";
import api from '~/services/api';
import { parseToQueryString } from "~/shared/parseToQueryString";
import moment from 'moment';
moment.locale('pt-BR');

export default function useCardWithBadge(periodoPrincipal, periodoComparacao, corretorId, grupoCorretoresId, dataIni, dataFim) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    resultLocacao: {
      valorTotal: 0,
      valorTotalAnterior: 0,
      qtdTotal: 0,
      flutuacao: 0,
      tipo: 'queda' || 'aumento'
    },
    resultVenda: {
      valorTotal: 0,
      valorTotalAnterior: 0,
      qtdTotal: 0,
      flutuacao: 0,
      tipo: 'queda' || 'aumento'
    },
    resultComissao: {
      valorTotal: 0,
      valorTotalAnterior: 0,
      qtdTotal: 0,
      flutuacao: 0,
      tipo: 'queda' || 'aumento'
    }
  });
  const onLoad = useCallback(
    async () => {
      const filtro = parseToQueryString({
        periodoPrincipal,
        periodoComparacao,
        corretorId,
        grupoCorretoresId,
        dataIni,
        dataFim
      });

      try {
        await api.get(`Dashboard/gerente/totais-por-tipos?${filtro}`).then((res) => {

          const { totaisPrincipal, totaisComparativo } = res?.data?.data;

          const principalLocacao = totaisPrincipal.find(t => t.tipo === 1);
          const comparativoLocacao = totaisComparativo.find(t => t.tipo === 1);

          const principalVenda = totaisPrincipal.find(t => t.tipo === 2);
          const comparativoVenda = totaisComparativo.find(t => t.tipo === 2);

          const principalComissao = totaisPrincipal.find(t => t.tipo === 3);
          const comparativoComissao = totaisComparativo.find(t => t.tipo === 3);

          const percentLocacao = getPercent(principalLocacao?.valorTotal, comparativoLocacao?.valorTotal);
          const percentVenda = getPercent(principalVenda?.valorTotal, comparativoVenda?.valorTotal);
          const percentComissao = getPercent(principalComissao?.valorTotal, comparativoComissao?.valorTotal);

          setData({
            resultLocacao: {
              valorTotal: principalLocacao?.valorTotal,
              valorTotalAnterior: comparativoLocacao?.valorTotal,
              qtdTotal: principalLocacao?.qtdTotal,
              flutuacao: percentLocacao.toFixed(1),
              tipo: percentLocacao >= 0 ? 'aumento' : 'queda'
            }
            ,
            resultVenda: {
              valorTotal: principalVenda?.valorTotal,
              valorTotalAnterior: comparativoVenda?.valorTotal,
              qtdTotal: principalVenda?.qtdTotal,
              flutuacao: percentVenda.toFixed(1),
              tipo: percentVenda >= 0 ? 'aumento' : 'queda'
            },
            resultComissao: {
              valorTotal: principalComissao?.valorTotal,
              valorTotalAnterior: comparativoComissao?.valorTotal,
              qtdTotal: principalComissao?.qtdTotal,
              flutuacao: percentComissao.toFixed(1),
              tipo: percentComissao >= 0 ? 'aumento' : 'queda'
            }
          });
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [periodoPrincipal, periodoComparacao, corretorId, grupoCorretoresId, dataIni, dataFim],
  );

  function getPercent(fromValue, toValue) {
    if (!fromValue)
      return 0;

    if (!toValue || toValue === 0)
      toValue = fromValue;

    return (((fromValue - toValue) / toValue) * 100);
  }

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  }
}
