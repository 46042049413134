import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import Button from '~/shared/Button';
import {Icon} from 'semantic-ui-react';

function HeaderFiltro({
  navToNovo,
  classMd = '3',
  description,
  children,
  action,
}) {
  if (action && action === true) {
    return (
      <Fragment>
        <div className="mb-2">
          <Row>
            <Col xs="12" md={classMd}>
              <div className="pt-2 ml-2">
                <Button
                  icon
                  labelPosition="left"
                  size="small"
                  fluid
                  className="my-1"
                  color="violet"
                  onClick={() => navToNovo()}>
                  <Icon name="plus" />
                  {description}
                </Button>
              </div>
            </Col>
            {children}
          </Row>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="mb-2">
        <Row>
          <Col xs="12" md={classMd}>
            <div className="pt-2 ml-2">
              <Button
                icon
                labelPosition="left"
                size="small"
                fluid
                className="my-1"
                color="violet"
                onClick={() => navToNovo()}>
                <Icon name="plus" />
                {description}
              </Button>
            </div>
          </Col>
          {children}
        </Row>
      </div>
    </Fragment>
  );
}

export default HeaderFiltro;
