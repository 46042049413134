import React, {Fragment, useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Form, Input, Tooltip, Table} from 'antd';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';

import Page from '~/components/Page';
import useTipoSolo from '~/actions/TipoSolo/useTipoSolo';
import HeaderFiltros from '~/components/HeaderFiltro';
import ModalConfirm from '~/components/ModalConfirm';

import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import {Icon} from 'semantic-ui-react';
import EmptyBox from '~/components/EmptyBox';

import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import api from '~/services/api';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const text = {
  display: <span>Visualizar tipo de solo</span>,
  delete: <span>Excluir tipo de solo</span>,
};

export function rowEdit(row, identificador) {
  history.push({
    pathname: `/app/tiposolos/manutencao/${MODO.EDITAR}`,
    state: {row, identificador},
  });
}

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/tiposolos/manutencao/${MODO.CADASTRAR}`,
    state: {identificador},
  });
}

function ConsultaTipoSolo() {
  const [actions, setActions] = useState([]);
  const [loadTable, setloadTable] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(false);
  const [tipoSoloId, setTipoSoloId] = useState(0);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isRecarregar, setIsRecarregar] = useState(false);

  const [nomeTipoSolo, setNomeTipoSolo] = useState('');

  const {identificador} = useParams();

  const ref = useRef();
  const {isLoading, data, quantidade} = useTipoSolo(
    current,
    pageSize,
    nomeTipoSolo,
    isRecarregar,
  );

  useEffect(() => {
    const {actions} = getActionsMenus(identificador);

    setActions(actions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);

  // eslint-disable-next-line no-unused-vars
  async function onShowSizeChange(current, pageSize) {
    setPageSize(pageSize);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function onFiltros() {
    setloadTable(true);

    const {nomeTipoSolo} = ref.current.getFieldsValue();

    setNomeTipoSolo(nomeTipoSolo);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  function handleModalConfirm(id) {
    setOpenModalConfirm(true);
    setTipoSoloId(id);
  }

  async function handleConfirmDelete() {
    if (tipoSoloId) {
      setLoading(true);

      await api
        .delete(`${url.TIPO_SOLO.DELETAR()}/${tipoSoloId}`)
        .then((response) => {
          setLoading(false);
          setIsRecarregar(!isRecarregar);
          setOpenModalConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          setLoading(false);
          setOpenModalConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModal() {
    setOpenModalConfirm(false);
  }

  const incluir = !!actions.find(
    (action) => action.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );
  const editar = !!actions.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );
  const excluir = !!actions.find(
    (action) => action.id === 'a4f436ce-db53-4b02-b56a-e819d0f68a48',
  );

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: '',
      key: 'action',
      className: 'column-action',
      fixed: 'right',
      render: (row) => (
        <div className="p-1" key={row.index}>
          {editar === true ? (
            <Tooltip placement="topRight" title={text.display}>
              <i
                onClick={() => rowEdit(row, identificador)}
                className="ri-account-circle-fill linkTable"
                style={{fontSize: '26px', marginRight: '10px'}}></i>
            </Tooltip>
          ) : null}
          {excluir === true ? (
            <Tooltip placement="topRight" title={text.delete}>
              <i
                onClick={() => handleModalConfirm(row.id)}
                className="ri-delete-bin-6-fill linkTable"
                style={{fontSize: '26px', marginRight: '10px'}}></i>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  const isEmpty = !isLoading && !data.length;

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de tipo de solo</h5>
                <p className="category">Manutenção de tipo de solo</p>
                {incluir === true ? (
                  <HeaderFiltros navToNovo={() => navToNovo(identificador)} />
                ) : null}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="4"></Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="nomeTipoSolo">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="2">
                            <Button
                              className="my-2"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="filter" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                {!isEmpty && !isLoading && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={{
                          current: current,
                          showSizeChanger: true,
                          defaultCurrent: 1,
                          total: quantidade,
                          responsive: true,
                          onChange: changePage,
                          onShowSizeChange: onShowSizeChange,
                        }}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              loading={loading}
              open={openModalConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModal}
              title="Atenção"
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaTipoSolo;
