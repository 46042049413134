import React, { Fragment } from 'react';

function getHeader(
  item,
  index,
  activeTab,
  setTab,
  disabledTabs,
  typeValidation,
  handleEventValidation
) {
  function handleClickAba(index) {
    if (!disabledTabs) {
      // ABA IMAGENS DO IMÓVEL - VALIDAR OS 100% DO PROPRIETÁRIO
      if (index === 3 && typeValidation === 'I') {
        if (handleEventValidation()) setTab(index + 1);
      } else if (!disabledTabs) {
        setTab(index + 1);
      }
    }
  }

  if (index + 1 === activeTab) {
    return (
      <Fragment key={index}>
        <div
          //onClick={disabledTabs ? () => {} : () => setTab(index + 1)}
          onClick={() => handleClickAba(index)}
          className="wizard-step"
          data-wizard-type="step"
          data-wizard-state="current">
          <div className="wizard-wrapper">
            <div className="wizard-number">{index + 1}</div>
            <div className="wizard-label">
              <div className="wizard-title">{item.props.title}</div>
              {/* <div className="wizard-desc">{item.props.desc}</div> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment key={index}>
      <div
        // onClick={disabledTabs ? () => { } : () => setTab(index + 1)}
        onClick={() => handleClickAba(index)}
        className={`wizard-step ${disabledTabs ? 'disabledTabs' : ''}`}
        data-wizard-type="step"
        data-wizard-state="pending">
        <div className="wizard-wrapper">
          <div
            className={`wizard-number ${disabledTabs ? 'disabledTabNumber' : ''
              }`}>
            {index + 1}
          </div>
          <div
            className={`wizard-label ${disabledTabs ? 'disabledTabLabel' : ''
              }`}>
            <div className="wizard-title">{item.props.title}</div>
            {/* <div className="wizard-desc">{item.props.desc}</div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function getContent(children, index, activeTab) {
  if (activeTab === index + 1) {
    return <Fragment key={index}>{children.props.children}</Fragment>;
  }

  return <Fragment key={index}></Fragment>;
}

function Tabs({ children, activeTab, setTab, disabledTabs, typeValidation, handleEventValidation }) {

  return (
    <Fragment>
      <div
        className="wizard wizard-4"
        data-wizard-state="first"
        data-wizard-clickable>
        <div className="wizard-nav">
          <div className="wizard-steps">
            {children.map((item, index) =>
              item && getHeader(item, index, activeTab, setTab, disabledTabs, typeValidation, handleEventValidation),
            )}
          </div>
        </div>
      </div>
      <div>
        {children.map((item, index) => item && getContent(item, index, activeTab))}
      </div>
    </Fragment>
  );
}

export default Tabs;
