/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useEntreAgencias() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(async (fetch) => {
    try {
      if (fetch) {
        await api.get(url.IMOVEL.CONSULTAR_IMOVEL_COMP())
          .then((res) => {
            setData(res?.data || []);
            setQuantidade(res?.data?.total);
          });
      } else {
        setData([]);
      }
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    quantidade,
    onLoad,
  };
}
