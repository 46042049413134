import { useEffect, useState, useCallback, useMemo } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import debounce from "lodash.debounce";

let controller;
let canceledRequest = false;

export default function useCondominioDropdown(nome, condominioId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const cancelRequest = () => {
    if (controller) {
      canceledRequest = true;
      controller.abort();
    }
  }

  const onLoad = useCallback(
    async (params) => {
    
      if (Array.isArray(params?.condominioId) || Array.isArray(condominioId)) {
        return;
      }

      setIsLoading(true);

      controller = new AbortController();
      const signal = controller.signal;

      const payload = {
        nome: params?.nome || nome || null,
        condominioId: params?.condominioId || condominioId || null
      }

      let filter = new URLSearchParams(payload);

      [...filter.entries()].forEach(([key, value]) => {
        if (!value || value === 'null') {
          filter.delete(key);
        }
      });

      try {
        await api
          .get(`${url.DROPDOWN.CONDOMINIO_DROPDOWN()}?${filter.toString()}`, { signal })
          .then((res) => {
            canceledRequest = false;
            setData(res.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        if (!canceledRequest) setIsLoading(false);
      }
    },
    [nome, condominioId],
  );

  useEffect(() => {

    if (!nome && !condominioId)
      return;

    const params = { nome, condominioId };
    onLoad(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome, condominioId, onLoad]);

  const debouncedChangeHandler = useMemo(
    () => debounce(onLoad, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      cancelRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    data,
    isLoading,
    onLoad: debouncedChangeHandler,
    cancelRequest,
  };
}
