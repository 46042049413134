import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import { mask } from 'remask';
import Notify from '~/shared/Notify';
import Page from '~/components/Page';

import useFinalidades from '~/actions/Finalidades/useFinalidades';
import useTipoImovel from '~/actions/TipoImovel/useTipoImovel';
import useLogradouro from '~/actions/DropDown/useLogradouroDropdown';

import pattern from '~/infra/resources/pattern/mask';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';
// import history from '~/services/history';

import CadastroGeralPreview from '../components/CadastroGeralPreview';
import CarouselImagem from '../components/CarouselImagens';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CircularProgress from '~/components/HomeLoading';


import 'moment/locale/pt-br';
import moment from 'moment';
import useCondominioDropdown from '~/actions/DropDown/useCondomionio';

moment.locale('pt-BR');

function Preview(props) {
  const [loadPage, setloadPage] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [, setImovelId] = useState(null);
  const [listImages, setListImages] = useState([]);
  const [identificador, setIdentificador] = useState();

  const [currentTipoImovel, setCurrentTipoImovel] = useState(1);
  const [pageSizeTipoImovel, setPageSizeTipoImovel] = useState(9999);
  const [nomeTipoImovel] = useState('');
  const [finalidadeId, setFinalidadeId] = useState();

  const [nomeCondominio, setNomeCondominio] = useState('');
  const [logradouro, setLogradouro] = useState('');

  const { data: dataFinalidades } = useFinalidades();
  const { data: dataTipoImovel } = useTipoImovel(
    currentTipoImovel,
    pageSizeTipoImovel,
    nomeTipoImovel,
    finalidadeId,
  );

  const { data: dataCondominio } = useCondominioDropdown(nomeCondominio);
  const { data: dataLogradouros } = useLogradouro(logradouro);

  async function generateInitialValueImages(images) {
    setListImages(images);

    const object = images.reduce(function (acc, image, index) {
      return Object.assign(acc, {
        [`${index}_id`]: image.id ? image.id : null,
        [`${index}_descricao`]: image.descricao,
        [`${index}_publicavel`]: image.id ? image.publicavel : true,
        [`${index}_destaque`]: image.id ? image.destaque : false,
        [`${index}_superDestaque`]: image.superDestaque,
        [`${index}_planta`]: image.planta,
        [`${index}_ordem`]: image.ordem,
      });
    }, {});

    return object;
  }

  async function generateInitialValue(dados) {
    let defaultBeneficios = [];
    let defaultSituacoes = [];
    let defaultPossuiInfra = [];

    await setInitialValuesOnLoad(dados);

    if (dados.ocupado) defaultSituacoes.push('O');
    if (dados.exclusividade) defaultSituacoes.push('E');
    if (dados.autorizacao) defaultSituacoes.push('A');

    if (dados?.caracteristica?.viaPavimentada) {
      if (dados?.finalidade?.nome) {
        dados?.finalidade?.nome.toLowerCase() === 'rural'
          ? defaultPossuiInfra.push('V')
          : defaultBeneficios.push('VP');
      }
    }

    if (dados?.caracteristica?.agua) defaultPossuiInfra.push('A');
    if (dados?.caracteristica?.luz) defaultPossuiInfra.push('L');

    if (dados?.caracteristica?.piscina) defaultBeneficios.push('PI');
    if (dados?.caracteristica?.armarios) defaultBeneficios.push('AR');
    if (dados?.caracteristica?.despensas) defaultBeneficios.push('DE');
    if (dados?.caracteristica?.mobiliado) defaultBeneficios.push('MO');
    if (dados?.caracteristica?.salasDeJantar) defaultBeneficios.push('SJ');
    if (dados?.caracteristica?.closets) defaultBeneficios.push('CL');
    if (dados?.caracteristica?.escritorios) defaultBeneficios.push('ES');
    if (dados?.caracteristica?.lavabos) defaultBeneficios.push('LA');
    if (dados?.caracteristica?.dces) defaultBeneficios.push('DC');
    if (dados?.caracteristica?.banheirosServico) defaultBeneficios.push('BS');
    if (dados?.caracteristica?.varandas) defaultBeneficios.push('VA');
    if (dados?.caracteristica?.armarioCozinha) defaultBeneficios.push('AC');
    if (dados?.caracteristica?.churrasqueiras) defaultBeneficios.push('CH');
    if (dados?.caracteristica?.saloesFesta) defaultBeneficios.push('SF');
    if (dados?.caracteristica?.saloesDeGinastica) defaultBeneficios.push('SG');
    if (dados?.caracteristica?.quadraDeEsportes) defaultBeneficios.push('QE');
    if (dados?.caracteristica?.quintal) defaultBeneficios.push('QU');
    if (dados?.caracteristica?.copa) defaultBeneficios.push('CO');
    if (dados?.caracteristica?.redeEsgoto) defaultBeneficios.push('RE');
    if (dados?.caracteristica?.minhaCasaMinhaVida) defaultBeneficios.push('MV');

    setInitialValues({
      sincronizadoComDesktop: dados?.sincronizadoComDesktop || null,
      beneficios: defaultBeneficios,
      id: dados?.id || undefined,
      codImovel: dados?.codImovel || undefined,
      codigoExterno: dados?.codigoExterno || undefined,
      estado: dados?.logradouro?.bairro?.cidade?.estado?.nome || undefined,
      cidadeId: dados?.logradouro?.bairro?.cidadeId || undefined,
      cidade:
        String(dados?.logradouro?.bairro?.cidade?.nome) || null,
      bairro: dados?.logradouro?.bairro?.nome || null,
      logradouro: dados?.logradouro?.nome || null,
      logradouroId: dados?.logradouroId || undefined,
      tipoLogradouro: dados?.logradouro?.tipoLogradouro?.descricao || null,
      cep: dados.logradouro.cep
        ? mask(dados.logradouro.cep, [pattern.cep])
        : '',
      pais: 'Brasil',
      situacao: String(dados?.situacao) || null,
      vencimentoAutorizacao: dados.vencimentoAutorizacao
        ? moment(dados.vencimentoAutorizacao.toString())
        : undefined,
      // inscricaoImobiliaria: dados.inscricaoImobiliaria,
      chavesNaEmpresa: dados?.chavesNaEmpresa || null,
      situacaoFinanceira: dados?.situacaoFinanceira || null,
      situacaoDocumental: dados?.situacaoDocumental || null,
      aceitaPermuta: dados?.aceitaPermuta || null,
      ocupadoPor: dados?.ocupadoPor || null,
      andarTerreo: dados?.andarTerreo || null,
      padraoSocial: dados?.padraoSocial || null,
      operacaoImobiliaria: dados?.operacaoImobiliaria || null,
      tipoSoloId: dados?.tipoSoloId || undefined,
      tipoImovelId: dados.tipoImovelId || undefined,
      tipoImovel: dados.tipoImovel.descricao || null,
      finalidadeId: dados.finalidadeId || undefined,
      finalidade: dados?.finalidade?.nome || null,
      divulgavel: dados?.divulgavel || null,
      destaque: dados?.destaque || null,
      superDestaque: dados?.superDestaque || null,
      corretorCaptacaoId: dados?.corretorCaptacaoId || undefined,
      corretorCaptacao: dados.corretorCaptacao,
      corretorCaptacao2Id: dados?.corretorCaptacao2Id || undefined,
      corretorCaptacao2: dados.corretorCaptacao2,
      captacao: dados?.captacao ? moment(dados?.captacao.toString()) : null,
      dataCriacao: dados.dataCriacao ? moment(dados.dataCriacao.toString()) : undefined,
      dataAtualizacao: dados.dataAtualizacao ? moment(dados.dataAtualizacao.toString()) : undefined,
      comissaoVenda: dados?.comissaoVenda || null,
      prestacaoValor: dados?.prestacaoValor || null,
      prestacoesFaltantes: dados?.prestacoesFaltantes || null,
      procedimentoVisita: dados?.procedimentoVisita || null,
      saldoDevedor: dados?.saldoDevedor || null,
      valorVenda: dados?.valorVenda || null,
      valorAgio: dados?.valorAgio || null,
      valorAluguel: dados?.valorAluguel || null,
      tituloAmigavel: dados?.tituloAmigavel || null,
      url: dados?.url || null,
      url360: dados?.url360 || null,
      valorCondominio: dados?.valorCondominio || null,
      iptu: dados?.iptu || null,
      unidadeConsumidoraEnergia: dados?.unidadeConsumidoraEnergia || null,
      unidadeConsumidoraAgua: dados?.unidadeConsumidoraAgua || null,
      condicoesPgtoIptu: dados?.condicoesPgtoIptu
        ? String(dados.condicoesPgtoIptu)
        : null,
      valorIptu: dados?.valorIptu || null,
      valorSeguroFianca: dados?.valorSeguroFianca || null,
      valorSeguroIncendio: dados?.valorSeguroIncendio || null,
      condicoesPagamento: dados?.condicoesPagamento || null,
      loteApto: dados?.endereco?.loteApto || null,
      complemento: dados?.endereco?.complemento || null,
      bairroComercialId: dados?.endereco?.bairroComercialId || undefined,
      bairroComercialNome: dados?.endereco?.bairroComercial?.nome || undefined,
      condominioId: dados?.endereco?.condominioId || undefined,
      condominio: dados?.endereco?.condominio?.nome || undefined,
      construtora: dados?.endereco?.condominio?.construtora || undefined,
      numero: dados?.endereco?.numero || null,
      latitude: dados?.endereco?.location?.coordinate?.x || null,
      longitude: dados?.endereco?.location?.coordinate?.y || null,
      quadraBloco: dados?.endereco?.quadraBloco || null,
      aberta: dados?.area?.aberta || null,
      aFormar: dados?.area?.aFormar || null,
      benfeitorias: dados?.area?.benfeitorias || null,
      dadosCampo: dados?.area?.dadosCampo || null,
      // denominacao: dados.area.denominacao,
      distanciaAsfalto: dados?.area?.distanciaAsfalto || null,
      distanciaCapital: dados?.area?.distanciaCapital || null,
      distanciaSede: dados?.area?.distanciaSede || null,
      preservacao: dados?.area?.preservacao || null,
      alqueires: dados?.area?.alqueires || null,
      hectares: dados?.area?.hectares || null,
      caracteristicas: dados?.caracteristica?.caracteristicas || null,
      qtdAndares: dados?.caracteristica?.qtdAndares || null,
      nroAndar: dados?.caracteristica?.nroAndar || null,
      possuiElevador: dados?.caracteristica?.possuiElevador || null,
      apartamentosPorAndar: dados?.caracteristica?.apartamentosPorAndar || null,
      quantidadeTorres: dados?.caracteristica?.quantidadeTorres || null,
      anoConstrucao: dados?.caracteristica?.anoConstrucao || null,
      porDoSol: dados?.caracteristica?.porDoSol || null,
      vagasGaragem: dados?.caracteristica?.vagasGaragem || null,
      muroFrente: dados?.caracteristica?.muroFrente || null,
      muroFundo: dados?.caracteristica?.muroFundo || null,
      muroLados: dados?.caracteristica?.muroLados || null,
      areaConstruida: dados?.caracteristica?.areaConstruida || null,
      areaConstruidaUnidadeMedida: dados?.caracteristica?.areaPrivadaUniMedida?.descricao || null,
      areaTerreno: dados?.caracteristica?.areaTerreno || null,
      areaTerrenoUnidadeMedida: dados?.caracteristica?.areaTotalUniMedida?.descricao || null,
      quantidadeQuartos: dados?.caracteristica?.quantidadeQuartos || null,
      sendoSuite: dados?.caracteristica?.sendoSuite || null,
      quantidadeSuites: dados?.caracteristica?.quantidadeSuites || null,
      quantidadeBanheiros: dados?.caracteristica?.quantidadeBanheiros || null,
      lancamento: dados?.lancamento,
      // tipoConstrucao: dados.tipoConstrucao,
      vagasGaragemDescobertas:
        dados?.caracteristica?.vagasGaragemDescobertas || null,
      totalGaragens:
        Number(
          dados?.caracteristica?.vagasGaragem
            ? dados?.caracteristica?.vagasGaragem
            : 0,
        ) +
        Number(
          dados?.caracteristica?.vagasGaragemDescobertas
            ? dados?.caracteristica?.vagasGaragemDescobertas
            : 0,
        ),
      // frenteParaOMar: dados.frenteParaOMar,
      // lancamento: dados.lancamento,
      negocioIndicado: dados?.caracteristica?.negocioIndicado || null,
      proximaAtualizacao: dados?.caracteristica?.proximaAtualizacao
        ? moment(dados.caracteristica.proximaAtualizacao.toString())
        : undefined,
      frente: dados?.caracteristica?.medidasFrente || null,
      fundo: dados?.caracteristica?.medidasFundos || null,
      ladoDireito: dados?.caracteristica?.medidasLadoDireito || null,
      ladoEsquerdo: dados?.caracteristica?.medidasLadoEsquerdo || null,
      chanfro: dados?.caracteristica?.medidasChanfro || null,
      muroLadoDireito: dados?.caracteristica?.roLadoDireito || null,
      usuarioAlteracao: dados?.usuarioAlteracao,
      usuarioInclusao: dados?.usuarioInclusao
    });
  }

  function editarCadastro() {
    /*
    history.push({
      pathname: `/app/imoveis/manutencao/editar`,
      state: props.location.state,
    });
    */
  }

  async function setInitialValuesOnLoad(data) {
    setCurrentTipoImovel(1);
    setPageSizeTipoImovel(9999);
    setFinalidadeId(data?.finalidadeId);
    setLogradouro(data?.logradouro?.nome);
    setNomeCondominio(data?.endereco?.condominio?.nome);
  }

  useEffect(() => {
    async function fetch() {
      if (props.match.params) {
        await api.get(url.IMOVEL.CONSULTA(props.match.params.id))
          .then(async res => {
            const dados = res.data;
            setImovelId(dados.id);
            setIdentificador(props.match.params.identificador);

            await generateInitialValueImages(dados.imagens);
            await generateInitialValue(dados);

            setTimeout(() => {
              setloadPage(false);
            }, 1200);
          })
          .catch((err) => {
            Notify(
              'error',
              pt.comum.atencao,
              'Erro ao carregar os dados do imóvel!',
            );

            setloadPage(false);
          });
      }
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  return (
    <>
      {loadPage && <CircularProgress />}
      <div>{loadPage && <SkeletonTableBasic />}</div>
      {!loadPage && (
        <Fragment>
          <Page>
            <Card className="card-custom-basic">
              <CardBody className="p-3">
                <CarouselImagem dataImages={listImages} />

                <br></br>
                <CadastroGeralPreview
                  dataImages={listImages}
                  dataFinalidades={dataFinalidades}
                  dataTipoImovel={dataTipoImovel}
                  dataCondominio={dataCondominio}
                  dataLogradouros={dataLogradouros}
                  initialValues={initialValues}
                  setImovelId={(value) => setImovelId(value)}
                  onEditarClick={() => editarCadastro()}
                  identificador={identificador}
                />
              </CardBody>
            </Card>
          </Page>
        </Fragment>)}
    </>
  );
}

export default Preview;
