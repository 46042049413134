import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';

export default function useMaster(nome, type, cpf, cnpj, empresaId) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const localEmpresaId = localStorage.getItem('icaseEmpresaId');
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));

  const administrador = roles.find((role) => role === 'SystemAdmin');

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    const filterNome = nome ? nome : null;
    const filterCPF = type === 1 && cpf ? cpf : null;
    const filterCNPJ = type === 2 && cnpj ? cnpj : null;
    let filterEmpresa = empresaId ? empresaId : null;

    let filter = '';

    if (filterNome) {
      if (filter.length > 0) filter += `&nome=${filterNome}`;
      else filter = `?nome=${filterNome}`;
    }

    if (filterCPF) {
      if (filter.length > 0) filter += `&cpf=${filterCPF}`;
      else filter = `?cpf=${filterCPF}`;
    }

    if (filterCNPJ) {
      if (filter.length > 0) filter += `&cnpj=${filterCNPJ}`;
      else filter = `?cnpj=${filterCNPJ}`;
    }

    if (!administrador && !empresaId) {
      filterEmpresa = localEmpresaId;
    }

    if (filterEmpresa) {
      if (filter.length > 0) filter += `&empresaId=${filterEmpresa}`;
      else filter = `?empresaId=${filterEmpresa}`;
    }

    try {
      await api.get(`auth/gestores${filter}`)
        .then((res) => {
          const data = res?.data;

          if (type === 1 || type === 2) {
            const filteredData = [];
            data.forEach((item) => {
              if (item.tipo === type) {
                filteredData.push(item);
              }
            });

            setData(filteredData || []);
            setQuantidade(filteredData.length || 0);
          } else {
            setData(data);
            setQuantidade(data.length);
          }
        });
    } catch (e) {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [administrador, type, cpf, cnpj, empresaId, localEmpresaId, nome]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
