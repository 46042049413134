const URL = {
  ESQUECI_SENHA: {
    RECUPERAR: () => 'esqueci-senha',
  },
  LOGIN: {
    DESKTOP: () => 'auth/login-desktop',
    VER_EMPRESAS: () => '/auth/obter-usuarios-acesso',
    WEB: () => 'auth/login',
    WEB_POR_EMPRESA: () => '/auth/login-por-empresa',
    CHANGE_COMPANY: (empresaId) => `/auth/login-por-empresaid/${empresaId}`,
    RECUPERAR_SENHA: () => 'auth/recuperar-senha',
    CONFIRMAR_EMAIL: () => 'auth/confirmar-email',
    REFRESH_TOKEN: () => 'auth/login-refresh-token',
    ALTERAR_SENHA_TOKEN: () => 'auth/alterar-senha-por-token',
    ALTERAR_SENHA: () => 'auth/alterar-senha',
  },
  DROPDOWN: {
    PAIS: () => '/pais',
    ESTADO: (pais) => `estado/${pais}`,
    CIDADE: (estado) => `cidade/${estado}`,
    CIDADE_DROPDOWN: () => 'cidade/dropdown',
    TIPOMIDIA_DROPDOWN: () => 'tipo-midia/dropdown',
    CIDADE_DROPDOWN_GRUPO: () => 'cidade/dropdown/grupos',
    CIDADE_DROPDOWN_GRUPO_ID: () => 'cidade/dropdown/grupos/id',
    BAIRRO: (cidadeId) => `bairros/${cidadeId}`,
    BAIRRO_DROPDOWN: () => 'bairros/dropdown',
    BAIRRO_IMOVEL: () => 'bairros/imovel/dropdown',
    LOGRADOURO: (bairroId) => `logradouros/${bairroId}`,
    PROFISSAO: () => 'profissao/dropdown',
    PLANTAO: () => 'plantao/dropdown',
    CORRETOR: () => `pessoa/dropdown-corretor`,
    CORRETORES_DROPDOWN: () => 'auth/corretores',
    CAPTADORES_DROPDOWN: () => 'auth/captadores/dropdown',
    NACIONALIDADES: () => 'nacionalidades',
    GRUPO_CORRETORES: () => 'grupos-corretores/dropdown',
    LOGRADOURO_DROPDOWN: (logradouro) => `logradouros/dropdown${logradouro}`,
    CONDOMINIO_DROPDOWN: () => 'condominios/dropdown',
    PESSOA: () => 'pessoas/dropdown',
    GERENTE: () => 'auth/gerentes/dropdown',
    TIME_FAVORITO: () => 'pessoas/times/dropdown',
    UNIDADE_MEDIDA: () => 'unidades-medida/dropdown',
    PROPRIETARIOS_DROPDOWN: () => `pessoas/proprietarios/dropdown`,
    IMOVEL_CIDADES_DROPDOWN: () => 'imovel/cidade/dropdown',
    TIPO_IMOVEL: () => 'tipo-imovel/dropdown',
    EMPRESAS: () => 'empresas/dropdown',
  },
  ATENDIMENTO: {
    CADASTRO_GERAL: () => 'atendimento',
    CADASTRO_BY_OFERTA_ATIVA: () => 'atendimento/oferta-ativa',
    FILTER: () => 'atendimento/filter',
    FILTER_BY_ID: (id) => `atendimento/${id}`,
    FILTER_AGRUPADO: () => 'atendimento/filter-agrupado',
    ANALISE_FINANCEIRA: (filter) => `atendimento/analise-financeira/${filter}`,
    ATENDIMENTOS: () => 'atendimento/evento',
    CONSULTA_ATENDIMENTOS: (id) => `eventos/${id}`,
    INTERESSE: () => 'atendimento/interesse',
    CONSULTA_INTERESSE: (id) => `interesses/${id}`,
    PROPOSTA: () => 'atendimento/propostas',
    EDITAR_PROPOSTA: () => 'atendimento/propostas/editar-proposta',
    CONSULTA_PROPOSTA: (id) => `atendimento/propostas/${id}`,
    CONSULTA_HIST_IMOVEL: (id) => `atendimento/${id}/historico`,
    HISTORICO_IMOVEL: () => 'atendimento/historico',
    RELACIONAMENTOS: (id) => `atendimento/${id}/relacionamentos`,
    TRANSFERIR: () => 'atendimento/transferir-corretor',
    FECHAR_NEGOCIO: (id) => `atendimento/${id}/fechar-negocio`,
    REINICIAR_ATENDIMENTO: (id) => `atendimento/${id}/reiniciar-atendimento`,
    ENCERRAR_ATENDIMENTO: (id) => `atendimento/${id}/encerrar-atendimento`,
    ATUALIZAR_STATUS: () => 'atendimento/atualizar-status',
    CHECK_IMOVEL_EM_ATENDIMENTO: (imovelId) =>
      `imovel/${imovelId}/atendimentos-em-aberto`,
    EFETIVAR_ANALISE_FINANCEIRA: () =>
      'atendimento/efetivar-analise-financeira',
    SOLICITAR_ANALISE_FINANCEIRA: (atendimentoId) =>
      `atendimento/solicitar-analise-financeira/${atendimentoId}`,
    DEFINIR_EM_ANALISE: (atendimentoId) =>
      `atendimento/definir-em-analise/${atendimentoId}`,
    ATUALIZAR_CHECKLIST: () => 'atendimento/atualizar-checklist',
    CHECKLIST_UPLOAD_FILE: () => 'atendimento/arquivo-checklist',
    CHECKLIST_DELETE_FILE: (checklistId) =>
      `atendimento/arquivo-checklist/${checklistId}`,
    CHECKLIST_ALL_FILES: (checklistId) =>
      `atendimento/checklist-files/${checklistId}`,
    CHECKLIST_ATUALIZA_TIPO_DOCUMENTO: (arquivoId, tipo) =>
      `atendimento/atualiza-tipo-documento/${arquivoId}${
        tipo ? '/' + tipo : ''
      }`,
    CHECKLIST_ATUALIZA_SITUACAO_DOCUMENTO: (arquivoId, situacao) =>
      `atendimento/atualiza-situacao-documento/${arquivoId}${
        situacao ? '/' + situacao : ''
      }`,
    ATENDIMENTOS_POR_MIDIA: (configuracoessistemas) =>
      `dashboard/atendimentos-por-midia${configuracoessistemas}`,
    ANIVERSARIANTES: (configuracoessistemas) =>
      `dashboard/aniversariantes${configuracoessistemas}`,
    ATRIBUI_CORRETOR: () => 'atendimento/atribui-corretor',
    ATENDIMENTO_PERFIL: () => 'atendimento/atendimentoperfil',
    ALTERAR_STATUS_EM_LOTE: () => 'atendimento/atualizar-status-lote',
    REMOVER_EM_LOTE: () => 'atendimento/remover-atendimento-em-lote',
    TRANSFORMAR_EM_OFERTA_ATIVA: () => 'atendimento/transforma-oferta-ativa',
  },
  OCORRENCIA: {
    FILTER: () => 'ocorrencia/filter',
    EFETIVAR_OCORRENCIA: () => 'ocorrencia/efetivar-ocorrencia',
    ENVIAR_OCORRENCIA: () => 'ocorrencia/enviar-ocorrencia',
  },
  IMPOSTO_RENDA: {
    FILTER: () => 'imposto-renda/filter',
    EFETIVAR: () => 'imposto-renda/efetivar',
    UPLOAD_FILE: () => 'imposto-renda/arquivo',
    DELETE_FILE: (impostoRendaId) => `imposto-renda/arquivo/${impostoRendaId}`,
    ALL_FILES: (impostoRendaId) => `imposto-renda/files/${impostoRendaId}`,
    ATUALIZA_TIPO_DOCUMENTO: (arquivoId, tipo) =>
      `imposto-renda/atualiza-tipo-documento/${arquivoId}${
        tipo ? '/' + tipo : ''
      }`,
    ATUALIZA_SITUACAO_DOCUMENTO: (arquivoId, situacao) =>
      `imposto-renda/atualiza-situacao-documento/${arquivoId}${
        situacao ? '/' + situacao : ''
      }`,
    DEFINIR_EM_ANALISE: (impostoRendaId) =>
      `imposto-renda/definir-em-analise/${impostoRendaId}`,
  },
  EMPRESAS: {
    CONSULTA: () => 'empresas',
    CONSULTA_BY_ID: (emrpesaId) => `empresas/${emrpesaId}`,
    CADASTRAR: () => 'empresas',
    EDITAR: () => 'empresas',
    DELETAR: () => 'empresas',
    ALL: () => 'empresas/all',
    ALL_GRUPO: (empresaId) => `empresas/getEmpresasGrupo/${empresaId}`,
    ALL_GRUPO_DROPDOWN: (empresaId) =>
      `empresas/empresasGrupoDropdown/${empresaId}`,
  },
  MENU: {
    CONSULTA: () => 'menu',
    CONSULTA_IDENTIFICADOR: (identificador) => `menu/${identificador}`,
    CADASTRAR: () => 'menu',
    EDITAR: () => 'menu',
    DELETAR: (id) => `menu/${id}`,
    GRANTED: () => 'menu/granted',
    PERFIL: (id) => `menu/perfil/${id}`,
  },
  PERFIL: {
    CONSULTA: (empresaId) => `perfil/${empresaId}`,
    CADASTRAR: () => 'perfil',
    EDITAR: () => 'perfil',
    DELETAR: () => 'perfil',
  },
  CONFIGURACOES_SISTEMA: {
    CONFIGURACOES_SISTEMA: () => 'configuracoes-sistema',
    CONFIGURACOES_SISTEMA_EMPRESA: () => 'configuracoes-sistema/config-empresa',
    CONFIGURACOES_SISTEMA_NOME: (nome) =>
      `configuracoes-sistema/nome-config?nome=${encodeURI(nome)}`,
  },
  PERFIL_ACESSO: {
    CONSULTA: () => 'perfil-acesso',
  },
  TIPO_ATENDIMENTO: {
    CONSULTA: () => 'tipo-atendimento',
    FILTER: () => 'tipo-atendimento/filter',
    CADASTRAR: () => 'tipo-atendimento',
    EDITAR: () => 'tipo-atendimento',
    DELETAR: () => 'tipo-atendimento',
  },
  TIPO_IMOVEL: {
    CONSULTA: () => 'tipo-imovel',
    FILTER: () => 'tipo-imovel/filter',
    CADASTRAR: () => 'tipo-imovel',
    EDITAR: () => 'tipo-imovel',
    DELETAR: () => 'tipo-imovel',
  },
  TIPO_CONDOMINIO: {
    CONSULTA: () => 'tipo-condominio',
    FILTER: () => 'tipo-condominio/filter',
    CADASTRAR: () => 'tipo-condominio',
    EDITAR: () => 'tipo-condominio',
    DELETAR: () => 'tipo-condominio',
  },
  TIPO_ESTABELECIMENTO: {
    CONSULTA: () => 'tipo-estabelecimento',
    FILTER: () => 'tipo-estabelecimento/filter',
    CADASTRAR: () => 'tipo-estabelecimento',
    EDITAR: () => 'tipo-estabelecimento',
    DELETAR: () => 'tipo-estabelecimento',
  },
  TIPO_MIDIA: {
    CONSULTA: () => 'tipo-midia',
    FILTER: () => 'tipo-midia/filter',
    CADASTRAR: () => 'tipo-midia',
    EDITAR: () => 'tipo-midia',
    DELETAR: () => 'tipo-midia',
  },
  PLANTAO: {
    CONSULTA: () => 'plantoes',
    CADASTRAR: () => 'plantoes',
    EDITAR: () => 'plantoes',
    DELETAR: () => 'plantoes',
  },
  TIPO_LOGRADOURO: {
    CONSULTA: () => 'tipo-logradouro',
    FILTER: () => 'tipo-logradouro/filter',
    CADASTRAR: () => 'tipo-logradouro',
    EDITAR: () => 'tipo-logradouro',
    DELETAR: () => 'tipo-logradouro',
  },
  TIPO_HONORARIO: {
    CONSULTA: () => 'tipo-honorario',
    FILTER: () => 'tipo-honorario/filter',
    CADASTRAR: () => 'tipo-honorario',
    EDITAR: () => 'tipo-honorario',
    DELETAR: () => 'tipo-honorario',
  },
  GRUPO_CORRETORES: {
    CONSULTA: () => 'grupos-corretores',
    CORRETORES: () => 'grupos-corretores/corretores/dropdown',
    CORRETORESBYROLE: () => 'grupos-corretores/users-role-corretor/dropdown',
    GERENTES: () => 'grupos-corretores/gerentes/dropdown',
    CADASTRAR: () => 'grupos-corretores',
    EDITAR: () => 'grupos-corretores',
    DELETAR: () => 'grupos-corretores',
  },
  GRUPO_CORRETORES_CORRETOR: {
    CONSULTA: () => 'grupos-corretores/corretor',
    CADASTRAR: () => 'grupos-corretores/corretor',
    EDITAR: () => 'grupos-corretores/corretor',
    DELETAR: () => 'grupos-corretores/corretor',
  },
  BAIRRO: {
    CONSULTA: () => 'bairros',
    FILTER: () => 'bairros',
    CADASTRAR: () => 'bairros',
    EDITAR: () => 'bairros',
    DELETAR: () => 'bairros',
  },
  DISTRIBUICAO_LEAD: {
    GET_BY_ID: (id) => `distribuicao-leads/${id}`,
    GET_LOG_BY_ID: (id) => `distribuicao-leads/log-distribuicao/${id}`,
    ALL_BY_EMPRESA: () => 'distribuicao-leads/all-empresa',
    UPDATE_ORDER: () => 'distribuicao-leads/update-order',
    CADASTRAR: () => 'distribuicao-leads',
    EDITAR: () => 'distribuicao-leads',
    DELETAR: (id) => `distribuicao-leads/${id}`,
  },
  PROFISSOES: {
    CONSULTA: () => 'profissoes',
    FILTER: () => 'profissoes',
    CADASTRAR: () => 'profissoes',
    EDITAR: () => 'profissoes',
    DELETAR: () => 'profissoes',
  },
  FORMACOES: {
    CONSULTA: () => 'formacoes',
    FILTER: () => 'formacoes',
    CADASTRAR: () => 'formacoes',
    EDITAR: () => 'formacoes',
    DELETAR: () => 'formacoes',
  },
  TIPO_SOLO: {
    CONSULTA: () => 'tipo-solos',
    FILTER: () => 'tipo-solos',
    CADASTRAR: () => 'tipo-solos',
    EDITAR: () => 'tipo-solos',
    DELETAR: () => 'tipo-solos',
  },
  FINALIDADE: {
    CONSULTA: () => 'finalidades',
    FILTER: () => 'finalidades',
    CADASTRAR: () => 'finalidades',
    EDITAR: () => 'finalidades',
    DELETAR: () => 'finalidades',
  },
  LOGRADOURO: {
    CONSULTA: () => 'logradouros',
    FILTER: () => 'logradouros/filter',
    CADASTRAR: () => 'logradouros',
    EDITAR: () => 'logradouros',
    DELETAR: () => 'logradouros',
  },
  CONDOMINIO: {
    CONSULTA: () => 'condominios',
    FILTER: () => 'condominios',
    CADASTRAR: () => 'condominios',
    EDITAR: () => 'condominios',
    DELETAR: () => 'condominios',
  },
  CONDOMINIO_IMAGEM: {
    CADASTRAR: (condominioId) => `condominios/imagens/${condominioId}`,
    DELETAR: (imagemId) => `condominios/imagens/${imagemId}`,
  },
  IMOVEL: {
    CONSULTA: (imovelId) => `imovel/${imovelId}`,
    FILTER: () => 'imovel/filter',
    SIMPLIFICADO: () => 'imovel/simplificado',
    CADASTRAR: () => 'imovel',
    EDITAR: () => 'imovel',
    DELETAR: () => 'imovel',
    SYNC: () => 'imovel/sincronizar',
    IMAGES: () => 'imovel/imagens-divulgacoes',
    IMOVEIS_POR_OPERACAO: (configuracoessistemas) =>
      `dashboard/imoveis-por-operacao${configuracoessistemas}`,
    ENVIAR_EMAIL: () => 'imovel/pdf/email',
    PUBLICAR_IMOVEL: () => 'imovel/compartilhar-imovel',
    CONSULTAR_IMOVEL_COMP: () => 'imovel-compartilhado',
    CADASTRAR_IMOVEL_COMP: () => 'imovel-compartilhado',
    DELETAR_IMOVEL_COMP: (id) => `imovel-compartilhado/${id}`,
  },
  IMOVEL_DIVULGACAO: {
    CONSULTAR: (imovelId) => `imovel-divulgacao/${imovelId}`,
    CADASTRAR: () => 'imovel-divulgacao',
  },
  IMOVEL_PROPRIETARIOS: {
    CONSULTA: (imovelId) => `imovel/proprietario/${imovelId}`,
    CADASTRAR: () => 'imovel/proprietario',
    EDITAR: () => 'imovel/proprietario',
    DELETAR: () => 'imovel/proprietario',
  },
  IMOVEL_IMAGEM: {
    EDITAR: () => 'imovel/imagens',
    DELETAR: (imageId) => `imovel/imagens/${imageId}`,
  },
  USUARIO: {
    CONSULTA: () => 'auth/usuarios',
    USUARIOS_EMPRESA: () => 'auth/usuarios-empresa',
    CONSULTA_FILTRO: () => 'usuarios/filtro',
    CONSULTA_BY_ID: (userId) => `usuarios/filtro/${userId}`,
    BY_ROLE: () => 'grupos-corretores/users-role/dropdown',
    TRANSFERIR: (userId) => `usuarios/transferir/${userId}`,
  },
  USUARIO_PERFIL: {
    CONSULTA_BY_USER: (userId) => `usuario-perfil/user/${userId}`,
    CONSULTA_BY_PERFIL: (perfilId) => `usuario-perfil/perfil/${perfilId}`,
    CADASTRAR: () => 'usuario-perfil',
    DELETAR: () => 'usuario-perfil',
  },
  MENU_PERFIL: {
    CADASTRAR: () => 'perfil-menu',
    CONSULTA: () => 'perfil-menu',
    DELETAR: () => 'perfil-menu',
  },
  PESSOA: {
    CONSULTA: () => 'pessoas',
    DELETAR: () => 'pessoas',
    CONSULTA_ID: (id) => `pessoas/${id}`,
    CADASTRAR_FISICA: () => 'pessoas/pessoa-fisica',
    CADASTRAR_JURIDICA: () => 'pessoas/pessoa-juridica',
    EDITAR_FISICA: () => 'pessoas/pessoa-fisica',
    EDITAR_JURIDICA: () => 'pessoas/pessoa-juridica',
    CONSULTA_ATENDIMENTO: (id) => `pessoas/${id}/atendimentos`,
  },
  MASTER: {
    CADASTRAR: () => 'auth/cadastrar-gestor',
    CONSULTA: () => 'auth/gestores',
    EDITAR: () => 'auth/alterar-gestor',
  },
  CORRETOR: {
    CADASTRAR: () => 'auth/cadastrar-corretor',
    ATUALIZAR: () => 'usuarios',
    ATUALIZAR_SIMPLE: () => 'usuarios/simple',
    ATIVAR: (userId) => `usuarios/ativar/${userId}`,
    INATIVAR: (userId) => `usuarios/inativar/${userId}`,
  },
  TIPO_USUARIO: {
    CADASTRAR: () => 'tipo-usuarios',
    ATUALIZAR: () => 'tipo-usuarios',
    CONSULTA: () => 'tipo-usuarios',
    DELETAR: (id) => `tipo-usuarios/${id}`,
  },
  OFERTA_ATIVA: {
    PROPOSTA_ATIVA_PAGED: () => 'proposta-ativa/proposta-ativa-paged',
    PROPOSTA_ATIVA_FILA_PAGED: () => 'proposta-ativa/proposta-ativa-fila-paged',
    PROPOSTA_ATIVA_FILA_CORRETOR: () =>
      'proposta-ativa/proposta-ativa-fila-corretor',
    PROPOSTAS_ATIVAS_DISPONIVEIS: () => `proposta-ativa/disponiveis`,
    CADASTRAR: () => 'proposta-ativa',
    CONSULTAR: () => 'proposta-ativa',
    EDITAR: () => 'proposta-ativa',
    ALTERAR: (id) => `proposta-ativa/${id}`,
    DELETAR: () => 'proposta-ativa',
    DELETAR_LEAD: () => 'proposta-ativa/lead',
  },
  ESCALAS: {
    CONSULTA: () => 'escalas',
    CADASTRAR: () => 'escalas',
    EDITAR: () => 'escalas',
    DELETAR: () => 'escalas',
  },
  SERVICES: {
    CONSULTA_CEP: (cep) => `services/consulta-cep/${cep}`,
    GERA_BASE64_IMAGEM: (urlImagem) =>
      `services/url-to-base64?url=${urlImagem}`,
  },
  RELATORIOS: {
    ATENDIMENTOS: () => `relatorios/atendimento-por-corretor`,
    ATENDIMENTOS_EXCEL: () => `relatorios/atendimento-por-corretor-excel`,
    ATENDIMENTOS_DET: () => `relatorios/atendimento-por-corretor-det`,
    IMOVEIS: () => `relatorios/imoveis`,
    CORRETORES: () => `relatorios/corretores`,
  },
  IMPORTACAO_IMOVEIS: {
    CONSULTA: (term) => `importacao-imoveis?term=${term ?? ''}`,
    DELETAR: (id) => `importacao-imoveis/${id}`,
  },
  ACOES: {
    CONSULTA: () => 'acao',
    CADASTRAR: () => 'acao',
    EDITAR: () => 'acao',
    DELETAR: () => 'acao',
  },
  LISTAGEM_URL: {
    CONSULTA: () => 'integracao-node',
  },
  COMIDA_FAVORITA: {
    CONSULTA: () => 'comida-favorita',
    CADASTRAR: () => 'comida-favorita',
    EDITAR: () => 'comida-favorita',
    DELETAR: (id) => `comida-favorita/${id}`,
  },
  CONFIGURACOES_SITE: {
    CONFIGURACOES_SITE: () => 'configuracoes-site',
  },
  RETAIN_SERVICE: {
    RETAIN_OBJECT: () => 'retain-object',
    RELEASE_OBJECT: (id) => `retain-object/${id}`,
  },
};

export default URL;
