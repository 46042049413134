import React from 'react'
import formatTelefone from '~/shared/formatTelefone'
import stripPhone from '~/shared/stripPhone'

export default ({ phone }) => {
  if (!phone) return null;

  return (
    <a href={`tel:${stripPhone(phone)}`}>
      {formatTelefone(phone)}
    </a>
  )
}
