export function parseToQueryString(obj) {
  const params = new URLSearchParams();

  for (const key in obj) {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== 'null') {
      params.append(key, value);
    }
  }


  return params.toString();
}
