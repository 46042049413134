
const baseUrlNext = process.env.REACT_APP_BASE_URL_NEXT || 'https://latitude-crm-homolog.vercel.app';

const fixedMenus = [
  {
    component: 'Configuracoes',
    url: `/configuracoes`,
    title: 'Configurações',
    heading: 'Configurações',
    icon: 'SettingOutlined',
  },
  {
    component: 'Cadastros',
    url: '',
    title: 'Cadastros',
    heading: 'Cadastros',
    icon: 'AppstoreAddOutlined',
    filhos: [
      {
        component: 'Acoes',
        url: '/acoes',
        title: 'Ações',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Perfil',
        url: '/perfil',
        title: 'Perfil',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Master',
        url: '/master',
        title: 'Master',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'GrupoCorretores',
        url: '/grupocorretores',
        title: 'Equipe(s) de Corretores',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Pessoa',
        url: '/pessoa',
        title: 'Pessoa',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Plantoes',
        url: '/plantoes',
        title: 'Plantão',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'ComidaFavorita',
        url: '/comidafavorita',
        title: 'Comida(s) Favorita(s)',
        heading: 'Cadastros',
        icon: '',
      },
      // {
      //   component: 'Finalidades',
      //   url: '/finalidades',
      //   title: 'Finalidades',
      //   heading: 'Cadastros',
      //   icon: '',
      // },
      // {
      //   component: 'TipoImovel',
      //   url: '/tipoimovel',
      //   title: 'Tipo de Imóvel',
      //   heading: 'Cadastros',
      //   icon: '',
      // },
      {
        component: 'TipoMidia',
        url: '/tipomidia',
        title: 'Tipo de Midia',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'TipoAtendimento',
        url: '/tipoAtendimento',
        title: 'Tipo de Cliente',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'TipoCondominio',
        url: '/tipocondominio',
        title: 'Tipo de Condomínio',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'TipoEstabelecimento',
        url: '/tipoestabelecimento',
        title: 'Tipo de Estabelecimento',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'TipoLogradouro',
        url: '/tipologradouro',
        title: 'Tipo de Logradouro',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Bairros',
        url: '/bairros',
        title: 'Bairros',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Profissoes',
        url: '/profissoes',
        title: 'Profissões',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Formacoes',
        url: '/formacoes',
        title: 'Formações',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Logradouro',
        url: '/logradouros',
        title: 'Logradouro',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Condominio',
        url: '/condominios',
        title: 'Condomínio',
        heading: 'Cadastros',
        icon: '',
      },
      {
        component: 'Empreendimento',
        url: '/empreendimentos',
        urlExterna: `${baseUrlNext}/building`,
        title: 'Empreendimentos',
        heading: 'Cadastros',
        icon: '',
      }
      ,
      {
        component: 'parceiros',
        url: '/parceiros',
        urlExterna: `${baseUrlNext}/building-partner`,
        title: 'Parceiros Comerciais',
        heading: 'Cadastros',
        icon: '',
      }
    ],
  },
  {
    component: 'ListagemUrl',
    url: '/listagem-urls',
    title: "Listagem de URL's",
    heading: '',
    icon: 'SwitcherOutlined',
  },
  {
    component: 'Corretor',
    url: '/corretor',
    title: 'Usuário(s)',
    heading: 'Cadastros',
    icon: 'UserOutlined',
  },
  {
    component: 'Escalas',
    url: '/escalas',
    title: 'Escalas',
    heading: '',
    icon: 'CalendarOutlined',
  },
  {
    component: 'Prioridade',
    url: '/prioridade',
    title: 'Prioridade do dia',
    heading: '',
    icon: 'ScheduleOutlined',
  },
  {
    component: 'Atendimentos',
    url: '/atendimentos',
    title: 'Atendimentos',
    heading: 'Cadastros',
    icon: 'ContactsOutlined',
  },
  {
    component: 'Imoveis',
    url: '/imoveis',
    title: 'Imóveis',
    heading: '',
    icon: 'BankOutlined',
  },
  {
    component: 'EntreAgencias',
    url: '/imoveis-compartilhados',
    title: 'Imóveis compartilhados',
    heading: '',
    icon: 'ApartmentOutlined',
  },
  {
    component: 'OfertaAtiva',
    url: '/oferta-ativa',
    title: 'Oferta ativa',
    heading: 'Oferta ativa',
    icon: 'CarryOutOutlined',
  },
  {
    component: 'Site',
    url: `/site`,
    title: 'Site',
    heading: 'Site',
    icon: 'ChromeOutlined',
  },
  {
    component: 'Distribuicao',
    url: '/distribuicao',
    title: 'Distribuição Leads',
    heading: '',
    icon: 'PullRequestOutlined',
  },
  // {
  //   component: 'Facebook',
  //   url: `/facebook`,
  //   title: 'Facebook',
  //   heading: 'Facebook',
  //   icon: 'FacebookOutlined',
  // },
];

export default fixedMenus;
