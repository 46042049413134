import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { Form, Input } from 'antd';

import ModalGenerico from '~/components/ModalGenerico';

import Url from '~/infra/urls';
import api from '~/services/api';
import history from '~/services/history';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import checkAlfanumerico from '~/shared/checkAlfanumerico';
import backgroundLogin from '~/assets/img/fundo_login_latitude.png';

function RecuperarSenha() {
  const [loading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [modalSucesso, setIsModalSucesso] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const token = query.get('token');

    if (id && token) {
      setUserId(id);
      setToken(token);
    } else {
      history.push('/');
    }
  }, []);

  function closeSucesso() {
    setIsModalSucesso(false);
  }

  function tudoCerto() {
    history.push('/');
  }

  async function RecuperarSenha(values) {
    setIsLoading(true);

    const dados = {
      userId: userId,
      senha: values.senha,
      senhaConfirmacao: values.senhaConfirmacao,
      token: token,
    };

    await api
      .put(Url.LOGIN.ALTERAR_SENHA_TOKEN(), dados)
      .then((res) => {
        setIsLoading(false);
        setIsModalSucesso(true);
      })
      .catch((err) => {
        setIsLoading(false);
        const { errors } = err.response.data;

        if (errors) {
          for (const item in errors) {
            if (item.length > 0) {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            } else {
              errors[item].forEach((valor) => {
                Notify('error', item, valor);
              });
            }
          }
        }
      });
  }

  function voltar() {
    history.push('/');
  }

  return (
    <Fragment>
      <div id="latitude-login" style={{ backgroundImage: `url(${backgroundLogin})` }}>
        <Row>
          <Col>
            <Card className="latitude-login-card">
              <CardBody className="px-3 py-4">
                <Row className="mx-1 pb-2">
                  <Col xs="12">
                    <div id="latitude-login-header">
                      RECUPERAR SENHA
                    </div>
                  </Col>
                </Row>
                <Form
                  form={form}
                  initialValues={{}}
                  scrollToFirstError
                  onFinish={RecuperarSenha}>
                  <Row className="mx-1">
                    <Col xs="12">
                      <p className="latitude-login-label">Senha</p>
                      <Form.Item
                        autoComplete="off"
                        className="my-3"
                        name="senha"
                        rules={[
                          {
                            required: true,
                            message: 'Favor informar a sua senha!',
                          },
                          // eslint-disable-next-line no-empty-pattern
                          ({ }) => ({
                            validator(rule, value) {
                              if (value && value.length < 6) {
                                return Promise.reject(
                                  'Sua senha deve possuir no mínimo 6 dígitos',
                                );
                              } else {
                                const isAlfaNumerico =
                                  checkAlfanumerico(value);
                                if (!isAlfaNumerico) {
                                  return Promise.reject(
                                    'Sua senha deve possuir letras e números',
                                  );
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <Input.Password
                          size="large"
                          placeholder=" Senha"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs="12">
                      <p className="latitude-login-label">
                        Confirma senha
                      </p>
                      <Form.Item
                        className="my-3"
                        autoComplete="off"
                        name="senhaConfirmacao"
                        dependencies={['senha']}
                        rules={[
                          {
                            required: true,
                            message: 'Favor confirmar a senha!',
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue('senha') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                'Confirmação de senha diferentes!',
                              );
                            },
                          }),
                        ]}>
                        <Input.Password
                          size="large"
                          placeholder=" Confirma senha"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="mx-1">
                    <Col xs="12">
                      <Form.Item>
                        <div className="mt-4 text-center">
                          <Button
                            isloading={loading.toString()}
                            loadtext="Validando..."
                            fluid
                            className="my-2"
                            type="submit">
                            Alterar senha
                          </Button>
                        </div>
                        <div className="text-center">
                          <Button
                            fluid
                            className="my-2"
                            onClick={() => voltar()}>
                            Voltar
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <ModalGenerico
        size="small"
        close={closeSucesso}
        open={modalSucesso}>
        <Row className="mx-1">
          <Col xs="12">
            <div id="latitude-login-header">
              AGRADECEMOS
            </div>
          </Col>
        </Row>
        <Row className="mx-1">
          <Col xs="12" className="mb-3">
            <p className="latitude-login-label">
              Procedimento finalizado, faça o login com a nova senha!
            </p>
          </Col>
        </Row>
        <Row className="mx-1">
          <Col xs="12">
            <Button
              fluid
              className="mt-4"
              onClick={() => tudoCerto()}>
              OK, entendi
            </Button>
          </Col>
        </Row>
      </ModalGenerico>
    </Fragment>
  );
}

export default RecuperarSenha;
