import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: "Poppins", sans-serif !important;
    color: #444444;
  }

  a:hover {
    text-decoration: none;
  }

  html, body, #root {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif !important;
  }

  th.column-action,
  td.column-action {
    text-align: right !important;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

.ant-input, .ant-picker, .ant-input-affix-wrapper, .ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  border-radius: .5rem !important;
}

.ant-input-group > input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-input-group > .ant-select > .ant-select-selector {
  border-radius: 0.5rem 0 0 0.5rem !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.shadow-none {
  box-shadow: none!important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #858796;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dddfeb;
}

.border-bottom-locare {
  border-bottom: 6px solid #31B1A2 !important;
}

.ui.blue.progress .bar {
  background-color: #2CA8FF !important;
}

.ui.progress:first-child {
  margin: 8px 0;
}

.sidebar .nav i, .off-canvas-sidebar .nav i {
 margin-right: 5px !important;
}

.sidebar .nav li > .div-route-item, .off-canvas-sidebar .nav li > .div-route-item {
    margin: 10px 15px 0;
    border-radius: 30px;
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8em;
    padding: 10px 4px;
    line-height: 1.625rem;
}

.sidebar .nav li > .div-route-children-item, .off-canvas-sidebar .nav li > .div-route-children-item{
    margin: 8px 30px 0;
    border-radius: 30px;
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8em;
    padding: 6px 4px;
    line-height: 1.625rem;
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar .nav li:hover:not(.active) > .div-route-children-item, .sidebar .nav li:focus:not(.active) > .div-route-children-item, .off-canvas-sidebar .nav li:hover:not(.active) > .div-route-children-item, .off-canvas-sidebar .nav li:focus:not(.active) > .div-route-children-item {
    background-color: rgba(255, 255, 255, 0.5);
}


.sidebar .nav li:hover:not(.active) > .div-route-item, .sidebar .nav li:focus:not(.active) > .div-route-item, .off-canvas-sidebar .nav li:hover:not(.active) > .div-route-item, .off-canvas-sidebar .nav li:focus:not(.active) > .div-route-item {
    background-color: rgba(255, 255, 255, 0.1);
}

.off-canvas-sidebar .nav li.active > .div-route-children-item, .sidebar .nav li.active > .div-route-children-item {
    background-color: #fff;
    box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
}

.off-canvas-sidebar .nav li.active > .div-route-item, .sidebar .nav li.active > .div-route-item {
    background-color: #fff;
    box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
}

.sidebar .nav li.active > a[data-toggle=collapse] {
  color: #2ca8ff;
}

.sidebar[data-color=blue] .nav li.active > .div-route-children-item:not([data-toggle=collapse]),
.sidebar[data-color=blue] .nav li.active >.div-route-children-item:not([data-toggle=collapse])  {
  color: #2ca8ff;
}

.sidebar[data-color=blue] .nav li.active > .div-route-item:not([data-toggle=collapse]),
.sidebar[data-color=blue] .nav li.active >.div-route-item:not([data-toggle=collapse])  {
  color: #2ca8ff;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #eaecf4 #eaecf4 #dddfeb;
}

.nav-tabs .nav-link.disabled {
  color: #858796;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #6e707e;
  background-color: #fff;
  border-color: #dddfeb #dddfeb #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.35rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4e73df;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.ant-form-item .ant-input-textarea-show-count::after {
  bottom: -28px !important;
}

#wrapper #content-wrapper {
  overflow-x: visible !important;
}

.ant-table-tbody > tr {
  margin-bottom: 5px;
}

.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 6px 16px !important;
}

/* .table-row-retorno > td {
  border-bottom: solid 2px #ffb84d !important;
}

.table-row-normal > td {
  border-bottom: solid 2px #00cc66 !important;
} */


.selectDash {
  right: 30px;
  top: 20px;
}

.cardDash {
  padding-top: 15px;
}

.logo-nav {
  display: inline-flex;
  align-items: center;
}


.ant-table-tbody > tr > td {
  border-bottom: 3px solid #f0f0f0 !important;
}

.table-row-retorno > td:first-child {
   border-left: solid 8px #ffb84d !important;
}

.table-row-irregular > td:first-child {
   border-left: solid 8px #ff5c33 !important;
}

.table-row-dia > td:first-child {
   border-left: solid 8px #80d4ff !important;
}

.table-row-normal > td:first-child {
   border-left: solid 8px #00cc66 !important;
}


.table-row-blue > td:first-child {
  border-left: solid 8px #1890ff !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 5px 10px !important;
  /* margin-bottom: 20px; */
  /* padding: 0.5rem 1rem; */
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.dropdown .dropdown-menu {
  font-size: 0.85rem;
}

.notify-text-color {
  color: #686868;
}

.dropdown .dropdown-menu .dropdown-header {
  font-weight: 900;
  font-size: 1rem;
}

.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}

.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}





  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  @media (min-width: 576px) {
    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
  }

  .content-dashboard  {
    color: #686868;
    /* padding: 0.75rem 1.5rem; */
  }

  .bottom-0 {
      bottom: 0 !important;
  }
  .right-0 {
      right: 0 !important;
  }
  .mr-5, .mx-5 {
      margin-right: 1.25rem !important;
  }
  .position-relative {
    position: relative !important;
}
  .position-absolute {
      position: absolute !important;
  }
  .overflow-hidden {
      overflow: hidden !important;
  }

.p-8 {
    padding: 2rem !important;
}

.atendimentoActive {
  border: 2px solid #4DA6FF !important;
  border-radius: 0.5rem;
}

.line-divider {
  width: 100%;
  color: #FFF;
  margin: 5px 0;
  border-bottom: 2px solid #4DA6FF !important;
}

.text-blue {
  color: #4DA6FF !important;
}

.MuiCircularProgress-colorPrimary {
  color: #4DA6FF !important;
}
.flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

  @media (min-width: 1200px) {
    .max-h-xl-275px {
        max-height: 275px !important;
    }
  }

  .max-h-200px {
      max-height: 200px !important;
  }

  .mb-n20, .my-n20 {
      margin-bottom: -5rem !important;
  }

  img {
      vertical-align: middle;
      border-style: none;
  }

  .wizard.wizard-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    background-color: #fff;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - .25rem);
    flex: 0 0 calc(25% - .25rem);
    width: calc(25% - .25rem);
    background-color: #B3B3B3 ;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    margin-right: 3.5px;
  }

  .disabledTabs{
    background-color: #808080 !important;
    color: #B3B3B3 !important;
  }

  .disabledTabNumber {
    color: #B3B3B3 !important;
    border: 1px solid #B3B3B3 !important;
  }

  .disabledTabLabel {
    color: #B3B3B3 !important;
  }

  .linkTable {
    cursor: pointer;
  }

  .linkTable:hover {
    color: #4DA6FF;
  }

  .scrollTop {
    position: fixed;
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }

  .scrollTop:hover{
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  .avatar-uploader > .ant-upload {
    width: 100% !important;
    height: auto !important;
    min-height: 178px;
    max-height: 450px;
  }

  .avatar-uploader > .ant-upload > span {
    min-height: 178px;
    max-height: 450px;
  }

  .etapas-nav .etapas-steps {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

  .etapas-nav .etapas-steps .etapas-step {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-right: 1rem;
  }


  .etapas-nav .etapas-steps .etapas-step .etapas-label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2rem .5rem;
  }

  .etapas-nav[data-wizard-clickable=true] .etapas-step {
      cursor: pointer;
  }

  .etapas-nav .etapas-steps .etapas-step .etapas-label .etapas-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #b5b5c3 ;
  }

  .etapas-nav .etapas-steps .etapas-step[data-wizard-state=current] .etapas-label .etapas-title {
    color: #4DA6FF !important;
  }


  .etapas-nav .etapas-steps .etapas-step .etapas-label .etapas-bar {
    height: 10px;
    width: 100%;
    background-color: #ebedf3;
    border-radius: .25rem;
    position: relative;
  }

  .etapas-nav .etapas-steps .etapas-step[data-wizard-state=current] .etapas-label .etapas-bar:after {
    width: 100%;
    background-color: #4DA6FF;
    border-radius: .25rem;
  }

  .etapas-nav .etapas-steps .etapas-step .etapas-label .etapas-bar:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 0;
    background-color: transparent;
    -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
}

@media (min-width: 992px) {
    .pl-lg-15, .px-lg-15 {
        padding-left: 3.75rem!important;
    }

    .pr-lg-15, .px-lg-15 {
      padding-right: 3.75rem!important;
    }

    .pb-lg-3, .py-lg-3 {
      padding-bottom: .75rem!important;
    }
  }

  @media (max-width: 991.98px) {
    .etapas-nav .etapas-steps {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .etapas-nav .etapas-steps .etapas-step {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      position: relative;
      width: 100%;
    }

    .etapas-nav .etapas-steps .etapas-step .etapas-label {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      padding: 1rem 0;
    }
  }


  .icons-items i {
    display: inline-flex !important;
    width: 42px;
    height: 42px;
    font-size: 22px;
    margin: 5px 15px 5px 5px;
    border-radius: .6rem;
    background-color: #fff;
    box-shadow: 3px 3px 6px 0 rgb(28 49 85 / 8%);
    color: #f80;
  }

  .symbol {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    border-radius: .85rem;
  }

  .symbol-basic {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .symbol .symbol-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    color: #808080;
    background-color: #f3f6f9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: .85rem;
  }

  .symbol.symbol-50 .symbol-label {
    width: 70px;
    height: 70px;
  }

   .symbol.symbol-50 .symbol-progress {
    width: 80px;
    height: 80px;
  }


  .symbol.symbol-light .symbol-label {
    background-color: #f3f6f9;
    color: #7e8299;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .symbol>img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: .85rem;
  }

  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }

  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }

  .symbol-basic>img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    padding: 2px;
  }

  .symbol.symbol-50>img {
      width: 100%;
      max-width: 50px;
      height: 50px;
  }

  .img-carousel {
    border-top-left-radius: .85rem;
    border-bottom-left-radius: .85rem;
  }

  .ui.progress:last-child {
    margin-bottom: 0.5em !important;

}

  @media (min-width: 992px) {
    .symbol>img {
        width: 100%;
        max-width: 50px;
        height: 50px;
    }

    .symbol-basic.symbol-lg-120>img {
      width: 100%;
      /* max-width: 120px; */
      height: 120px;
    }

    .symbol-basic.symbol-lg-150>img {
      width: 100%;
      /* max-width: 150px; */
      height: 150px;
    }

    .symbol-basic.symbol-lg-180>img {
      width: 100%;
      /* max-width: 150px; */
      height: 300px;
    }
  }

  @media (min-width: 768px) {
    .symbol .symbol-label {
      width: 50px;
      height: 50px;
    }

    .symbol>img {
      width: 100%;
      max-width: 50px;
      height: 50px;
    }
  }

  @media (min-width: 576px) {
    .symbol .symbol-label {
      width: 50px;
      height: 50px;
    }

    .symbol>img {
      width: 100%;
      max-width: 50px;
      height: 50px;
    }
  }


  .font-size-lg {
    font-size: 1.08rem;
  }

  .text-dark-75 {
    color: #3f4254!important;
  }

  .wizard[data-wizard-clickable=true] .wizard-step {
    cursor: pointer;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      color: #808080;
      padding: 1.2rem;
  }

  .wizard-desc {
    font-size: 11px;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-number {
    font-size: 1rem;
    font-weight: 600;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.75rem;
    flex: 0 0 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    color: #808080;
    margin-right: 1rem;
    border-radius: 4px;
    border: 1px solid #808080;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-wrapper .wizard-number {
    color: #fff;
    background-color: #1D1F8C;
}

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-wrapper .wizard-label .wizard-title {
    color: #1D1F8C;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-label .wizard-title {
    font-family: 'Montserrat', 'Poppins', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .ant-tabs-tab {
    font-family: 'Montserrat', 'Poppins', sans-serif !important;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #1D1F8C;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    background-color: #fff;
    padding: 0px 15px;
    border: 0;
    border-radius: 0.25rem;
}

  .wizard[data-wizard-clickable=true] .wizard-step {
      cursor: pointer;
  }

  .loginMessage h1 {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    line-height: 64px;
  }


  .loginMessage h2{
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    margin: 10px 0 0;
  }

  .loginMessage h6{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }


  @media (max-width: 1399.98px) {
    /* .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - .25rem);
        flex: 0 0 calc(50% - .25rem);
        width: calc(50% - .25rem);
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        margin-bottom: .5rem;
    } */
  }

  @media (max-width: 767.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      -webkit-box-flex: 0!important;
      -ms-flex: 0 0 100%!important;
      flex: 0 0 100%!important;
      position: relative;
      width: 100%!important;
    }
  }


  .card-custom.card-shadowless {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .card-custom.card-transparent, .card-custom-basic.card-transparent {
    background-color: transparent;
  }

  .rounded-top-0 {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
  }

  .card-dashboard-number h1{
    font-size: 2.5rem;
    font-weight: 900;
    color: #404040;
  }

    .card-dashboard-number h2{
    font-size: 1.5rem;
    font-weight: 900;
    color: #404040;
  }

  .card-dashboard {
    color: #686868;
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
    border-radius: 0.4rem !important;
    /* border: 1px solid #EBEDF3 !important; */
    background-color: #FFF;
    cursor: pointer;

    box-shadow: 0px 0px 10px 0px rgba(82,63,105,0.05) !important;
  }

  .card-img {
    height: 300px;
  }

  .container-imovel-item  {
    padding-top: 20px;
    /* padding-left: 5px; */
    font-size: 12px;
  }

  .card-imovel-item {
    padding: 5px;
    display: inline-flex !important;
    width: 42px;
    height: 42px;
    font-size: 22px;
    margin: 5px;
    border-radius: .6rem;
    background-color: #fff;
    box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
  }

  .codigo-destaque {
    padding: 10px;
    border-radius: .2rem;
    border: 5px dashed #3BA8FF;
    font-size: 18px;
    width: 100%;
    position: relative;
    text-align: center;
  }

  .codigo-destaque > span {
    font-size: 20px;
    font-weight: 700 !important;
    color: #3BA8FF;
  }

  @media (max-width: 740px) {
    .card-imovel-item {
      margin-top: .4rem !important;
    }
  }

  .card-dashboard:hover {
     color: #4DA6FF !important;
  }

  .card-dashboard:hover h1{
     color: #4DA6FF !important;
  }

  .card-dashboard-number h1:hover{
    color: #4DA6FF !important;
  }

  .card-dashboard-chart {
    color: #686868;
    border-radius: 0.4rem !important;
    background-color: #FFF;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(82,63,105,0.05) !important;
  }

  .card-dashboard-chart-header {
    background-color: #FFF !important;
    border-border-top-left-radius: 0.4rem !important;
    border-border-top-right-radius: 0.4rem !important;
    padding-top: 2.5rem !important;
    padding: 1rem 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid #EBEDF3;
  }

  .card-dashboard-chart:hover {
    color: #4DA6FF !important;
  }

  .card-dashboard-chart:hover h1{
    color: #4DA6FF !important;
  }

  .nav-icon {
    line-height: 0;
    color: #7E8299;
    width: 2rem;
  }

  .nav-text {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #B5B5C3;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 1rem;
    font-weight: 500 !important;
  }

  .w-auto {
    width: auto !important;
  }

  .progress {
    height: 1.5rem;
  }


  #wrapper {
    height: 100%;
  }

  #wrapper #content-wrapper {
    background-color: #FCFCFC;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  #wrapper #content-wrapper #content {
    flex: 1 0 auto;
  }

  .flexbox{
    position:absolute;
    width:95%;
    height:100%;
    margin-left: 2.5%;

    display: flex;
    flex-direction:column;
  }

  .flex{
    flex:1;
    display:flex;
    justify-content: center;
    margin-top: 25%;
  }

  .flex-child{
    width:100%;
    display:block;
  }

  footer.sticky-footer {
    padding: 2rem 0;
    flex-shrink: 0;
  }

  footer.sticky-footer .copyright {
    font-family: "Poppins", sans-serif !important;
    line-height: 1.2;
    font-size: 1rem;
  }

  body.sidebar-toggled footer.sticky-footer {
    width: 100%;
  }

  .p-0 {
      padding: 0 !important;
  }

  .gutter-b {
    height: calc(100% - 25px);
  }

  .ui.table thead th  {
    color: #c6c6d2 !important;
  }

  .table td, .table th  {
    border-top: none !important;
  }

  .ui.medium.image, .ui.medium.images .image, .ui.medium.images img, .ui.medium.images svg {
      width: auto !important;
      margin-right: 2.5rem !important;
  }

  .card-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
  }

  .card-header {
    background-color: #FFF !important;
    padding: 1rem 1.25rem;
    margin-bottom: 10px;
    border-bottom: 1px solid #EBEDF3;
    border-top-left-radius: 0.85rem;
    border-top-right-radius: 0.85rem;
  }

  .card-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0;
  }

  .card-custom {
    /* padding-bottom: 2.5rem !important; */
    padding-top: 2.5rem;

    border-radius: 0.4rem !important;
    background-color: #FFF;

    box-shadow: 0px 0px 10px 0px rgba(82,63,105,0.05);
    border: 0;
    margin-bottom: 15px;

  }

  .card-custom-basic {
    border-radius: 0.4rem !important;
    box-shadow: 0px 0px 10px 0px rgba(82,63,105,0.05);
    border: 0;
    margin-bottom: 15px;
  }

  .container-custom {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .navbar {
    padding: 0
  }

  .menu-section {
    display: flex;
    margin: 10px 0 10px 0;
  }

  .menu-row {
    cursor: pointer;
    padding: 5px 0 5px 0;
    font-size: 14px;
    color: #B5B5C3;
  }

  .menu-row:hover {
    background-color: #F3F6F9;
    color: #57ADF4;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .15s ease;
  }

  .opacity-8 {
    opacity: .3!important;
    opacity: .3!important;
  }

  .mt--7, .my--7 {
    margin-top: -6rem!important;
  }

  .card-profile-image {
    position: relative;
  }

  .ant-upload.ant-upload-select {
    display: block !important;
  }

  .card-profile-image img {
    width: 180px !important;
    height: 180px !important;
    border-radius: .375rem;
    transform: translate(-50%,-30%);
    position: absolute;
    left: 50%;
    transition: all .15s ease;
    cursor: pointer;
  }

  .card-profile-image img:hover {
    opacity: .8!important;
  }

  .card-profile-image img, .shadow {
      /* box-shadow: 0 0 2rem 0 rgba(136,152,170,.15)!important; */
  }

  .rounded-circle {
    border-radius: 50%!important;
  }

  .figure {
    display: block !important;
  }

  .sectionAvatar {
    cursor: pointer;
    position: relative;
    transition: transform 1s;
    transform: translateX(0) scale(0.7);
  }

  .sectionAvatar:focus,
  .sectionAvatar:hover {
    transform: translateX(10px) scale(1);
  }

  .sectionAvatar .figure {
    /* overflow: hidden;
    position: relative; */
  }

  .sectionAvatar .figure img {
    /* max-width: 100%;
    width: 100%;
    position: relative;

    transition: transform 0.6s; */
  }

  .sectionAvatar .figure:hover .figcaption {
    /* opacity: 1;
    transform: translateY(0px);
    transition: transform 0.6s, opacity 0.1s; */
  }

  .sectionAvatar .figcaption {
    /* position: absolute;
    top: auto;
    bottom: 0;
    left: 0; */

    /* width: 100%;
    height: auto;
    text-align: center;
    color: #fff;
    background: linear-gradient(87deg, #fb6340 0%, #fbb140 100%); */

    /* opacity: 0;
    transition: transform 0.6s, opacity 0.1s 0.3s;
    transform: translateY(100%); */
  }

  .loading {
    float: left;
    width: 1.2em;
    height: 1.2em;
    -webkit-animation: button-spin .6s linear;
    animation: button-spin .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: .2em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }

  .loginFooterApp {
    display: none;
  }

  .ui.button:disabled, .ui.buttons .disabled.button, .ui.disabled.active.button,
  .ui.disabled.button, .ui.disabled.button:hover {
    opacity: 0.7!important;
  }

  .blurring.dimmable > .dimmer {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .ui.modal {
    position: inherit !important;
    height: auto !important;
    left: auto !important;
    top: auto !important;
    z-index: 1050 !important;
  }

  .ui.basic.modal > .header {
    color: #2dce89 !important;
  }

  .ui.basic.green.button {
    color: #2dce89 !important;
    border-color: #2dce89 !important;
  }

  .ui.button {
    font-weight: normal !important;
  }

  .ui.fullscreen.modal,
  .ui.fullscreen.scrolling.modal {
    left: auto !important;
  }

  .ui.message {
    font-size: 12px !important;
  }

  .ui.buttons .or:before {
    content: "ou" !important;
  }

  .upload-list-inline .ant-upload-list-item {
    float: left;
    margin-right: 8px;
  }


  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    background-color: #FFFFFF;
    border: 1px solid #d9d9d9;
  }

  .upload-card {
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border-radius: 2px;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
  }

  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 80px !important;
  }

  .titleLogin{
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    margin: 10px 0 0;
  }

  .titulo{
    font-weight: 800;
    font-size: 24px;
    line-height: 48px;
  }

  .modal {
    position: none !important;
    bottom: auto !important;
    right: auto !important;
  }

  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      padding-right: 8px;
      padding-left: 8px;
  }

  /* .div-carousel {
   margin-right: 10px;
  } */

  .ant-divider-horizontal {
      margin: 15px 0 !important;
  }

  .card-customer {
    min-height: 192px;
  }

  .divClick {
    cursor: pointer;
  }

  @media (max-width: 640px) {
    .cardDash {
      padding-top: 60px;
    }

    .selectDash {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }

    .card-dashboard-chart {
      margin-left: 1.6rem!important;
      margin-right: 1.6rem!important;
    }

    .loginMessage, .linkVoltar, .ant-tooltip {
      display: none !important;
    }

    .img-carousel {
      border-top-right-radius: .85rem;
      border-bottom-right-radius: .85rem;
    }

    .div-carousel {
      padding-bottom: 5px;
    }

    .loginFooterApp {
      display: block;
      width: 100vw;
      z-index: -1;
      position: absolute;
      bottom: 0px;
      height: auto;
    }

    .container-fluid {
      margin: 0;
      padding: 0 ;
    }

    .cardPublico {
      background-color: transparent !important;
    }

    .img-profile {
      display: none;
    }

    .container-custom {
      margin-left: 0;
      margin-right: 0;
    }

    .col-12 {
      padding-left: 6px;
      padding-right: 6px;
    }

    /* .loginProfile {
      margin-top: 6rem !important;
    } */

    /* .boxProfile {
      margin-top: 4rem !important;
    } */
  }

  @media (min-width: 640px) {
    .footerImgLogin {
      z-index: -1;
      position: absolute;
      bottom: 0px;
    }
  }

  @media (min-width: 992px) {
    .footerImgLogin {
      display: block;
      width: 100vw;
      z-index: -1;
      position: absolute;
      bottom: 0px;
      height: auto;
    }
  }


  .titleLogo {
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: -0.1rem;
  margin-top: -0.5rem;
  font-size: 100% !important;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2.5rem;
  width: 2.5rem;
}

.topbar {
  /* height: 4.375rem; */
}

.topbar #sidebarToggleTop {
  /* height: 2.5rem;
  width: 2.5rem; */
}

/* .topbar #sidebarToggleTop:hover {
  background-color: #eaecf4;
}

.topbar #sidebarToggleTop:active {
  background-color: #dddfeb;
} */

.topbar .navbar-search {
  width: 25rem;
}

.topbar .navbar-search input {
  font-size: 0.85rem;
  height: auto;
}

.topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}

.topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.topbar .nav-item .nav-link:focus {
  outline: none;
}

.topbar .nav-item:focus {
  outline: none;
}

.topbar .dropdown {
  position: static;
}

.topbar .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
}

.topbar .dropdown-list {
  padding: 0;
  border: none;
  overflow: hidden;
}

.topbar .dropdown-list .dropdown-header {
  background-color: #1A8CFF;
  border: 1px solid #1A8CFF;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
}

.topbar .dropdown-list .dropdown-item {
  white-space: normal;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: 1px solid #e3e6f0;
  border-right: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}

.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
  background-color: #eaecf4;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}

.topbar .dropdown-list .dropdown-item .text-truncate {
  max-width: 10rem;
}

.topbar .dropdown-list .dropdown-item:active {
  background-color: #eaecf4;
  color: #3a3b45;
}

@media (min-width: 576px) {
  .topbar .dropdown {
    position: relative;
  }
  .topbar .dropdown .dropdown-menu {
    width: auto;
    /* right: 0; */
  }
  .topbar .dropdown-list {
    width: 40rem !important;
  }
  .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link i {
  font-size: 1.2rem;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  font-family: "Poppins", sans-serif !important;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #b7b9cc;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: #858796;
}

.dropdown-item {
  cursor: pointer;
}

.close {
  color: rgba(0,0,0,.6) !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 5px 10px;
  /* margin-bottom: 20px; */
  /* padding: 0.5rem 1rem; */
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.align-items-center{
  align-items: center;
}

.align-items-end{
  align-items: end;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #2B2B2B;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
`;
