import React from 'react';
import styled from 'styled-components';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(11, minmax(0, 1fr));
  row-gap: 5px;
  column-gap: 2px;
`;

export function Grid({children}) {
  return <StyledGrid>{children}</StyledGrid>;
}

export function GridItem({
  children,
  span = 12,
  color = '#fff',
  textColor = '#000',
}) {
  return (
    <div
      style={{
        gridColumn: `span ${span} / span ${span}`,
        backgroundColor: color,
        padding: 6,
        fontSize: 15,
        borderRadius: 5,
        color: textColor,
        alignContent: 'center',
        justifyItems: 'center',
      }}>
      {children}
    </div>
  );
}

