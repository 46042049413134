
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import SkeletonTable from '~/components/SkeletonTableBasic';
import { Tabs } from 'antd';
import pt from '~/infra/resources/strings/pt';
import URL from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';
import 'moment/locale/pt-br';
import moment from 'moment';
import { PullRequestOutlined, RedoOutlined } from '@ant-design/icons';
moment.locale('pt-BR');


function LogDistribuicaoLeads({ isLoading, dataSource }) {

    const columns = [
        {
            title: 'Data/Hora',
            dataIndex: 'dataCriacao',
            render: (row) => (
                <span key={row.index}>{moment(row).format('DD/MM/YYYY - HH:mm')}</span>
            ),
            key: 'dataCriacao',
        },
        {
            title: 'Empresa',
            dataIndex: 'empresa',
            key: 'empresa',
        },
        {
            title: 'Corretor',
            dataIndex: 'corretor',
            key: 'corretor',
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            key: 'cliente',
        },
    ];

    return isLoading ? <SkeletonTable /> : <Table dataSource={dataSource} columns={columns} />;
}


function LogRecepcaoLeads({ id, open }) {

    const [isLoading, setIsLoading] = useState(false);
    const [dataDistribuicao, setDataDistribuicao] = useState([]);
    const [dataRedistribuicao, setDataRedistribuicao] = useState([]);

    const items = [
        {
            key: '1',
            label: (
                <span>
                    <PullRequestOutlined />
                    Distribuição de Leads
                </span>
            ),
            children: <LogDistribuicaoLeads isLoading={isLoading} dataSource={dataDistribuicao} />,
        },
        {
            key: '2',
            label: (
                <span>
                    <RedoOutlined />
                    Redistribuicão de Leads
                </span>
            ),
            children: <LogDistribuicaoLeads isLoading={isLoading} dataSource={dataRedistribuicao} />,
        },
    ];

    useEffect(() => {
        if (open) {
            if (id) {

                setIsLoading(true);

                async function fetchData() {
                    await api.get(URL.DISTRIBUICAO_LEAD.GET_LOG_BY_ID(id))
                        .then((res) => {
                            setDataDistribuicao(res?.data?.distribuicao ?? [])
                            setDataRedistribuicao(res?.data?.redistribuicao ?? [])
                        })
                        .catch((err) => {
                            setIsLoading(false);

                            if (err?.response?.data?.data?.error) {
                                for (const item in err?.response?.data?.data?.error) {
                                    Notify('error', pt.comum.atencao, err?.response?.data?.data?.error[item]);
                                }
                            }
                            else
                                Notify('error', pt.comum.atencao, 'Não foi possível carregar os dados.');

                        }).finally(() => {
                            setIsLoading(false);
                        });
                }

                fetchData();
            } else {
                setIsLoading(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return <Tabs defaultActiveKey="1" items={items} />
}

export default LogRecepcaoLeads;