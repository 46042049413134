/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Container, Col, Row} from 'reactstrap';
import {Image, Modal, Header, Dimmer, Loader} from 'semantic-ui-react';

import Url from '~/infra/urls';
import api from '~/services/api';
import history from '~/services/history';
import Button from '~/shared/Button';
import logo from '~/assets/img/logo.png';

function EsqueciSenha() {
  const [loading, setIsLoading] = useState(true);
  const [loadingButton, setIsLoadingButton] = useState(false);
  const [modalSucesso, setIsModalSucesso] = useState(false);
  const [userId, setUserId] = useState();
  const [key, setKey] = useState();

  useEffect(() => {
    setTimeout(() => {
      const query = new URLSearchParams(location.search);
      const id = query.get('id');
      const token = query.get('token');
      const key = query.get('key');

      if (id && token && key) {
        setUserId(id);
        setKey(key);
        const dados = {
          UserId: id,
          Token: token,
        };

        async function fetchData() {
          await api.put(Url.LOGIN.CONFIRMAR_EMAIL(), dados).then((res) => {
            setIsLoading(false);
            setIsModalSucesso(true);
          });
        }

        fetchData();
      } else {
        history.push('/');
      }

      setIsLoading(false);
      setIsModalSucesso(true);
    }, 1000);
  }, []);

  function closeSucesso() {
    setIsModalSucesso(false);
  }

  function tudoCerto() {
    setIsLoadingButton(true);
    history.push({
      pathname: '/',
      state: {
        redefinePassword: true,
        userId,
        key,
      },
    });
  }

  return (
    <>
      {loading ? (
        <Dimmer active>
          <Loader size="massive">Validando...</Loader>
        </Dimmer>
      ) : (
        <div id="latitude-login">
          <Container>
            <Row>
              <Col>
                <div className="p-4">
                  <Image
                    size="small"
                    verticalAlign="middle"
                    src={logo}
                    centered
                    wrapped
                  />
                </div>
              </Col>
            </Row>
            <div id="latitude-content">
              <Row className="justify-content-center">
                <Col xs="12" md="5">
                  <div className="px-4"></div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}

      <Modal
        basic
        centered
        closeOnEscape={false}
        closeOnDimmerClick={false}
        dimmer="blurring"
        size="tiny"
        open={modalSucesso}
        onClose={closeSucesso}>
        <Header
          icon="check circle"
          content="Confirmação de email realizada com sucesso!"
        />
        <Modal.Content>
          <p className="text-white p-4">
            Procedimento finalizado, o seu login está liberado!
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="my-2"
            color="green"
            icon="checkmark"
            labelPosition="right"
            onClick={tudoCerto}
            isloading={loadingButton.toString()}
            loadtext="Preparando..."
            content="Ok, entendi"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default EsqueciSenha;
