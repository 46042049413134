import { useCallback, useEffect, useState } from "react";
import api from '~/services/api';
import { parseToQueryString } from "~/shared/parseToQueryString";
import moment from 'moment';
moment.locale('pt-BR');


export default function useActiveOfferRank(mainPeriod, broker, brokerGroup, initialDate, finalDate) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const onLoad = useCallback(
    async () => {
      const filtro = parseToQueryString({
        periodoPrincipal: mainPeriod,
        corretorId: broker,
        grupoCorretoresId: brokerGroup,
        dataIni: initialDate,
        dataFim: finalDate
      });

      try {
        await api.get(`Dashboard/gerente/ranking-oferta-ativa?${filtro}`).then((res) => {
          setData(res?.data?.data);
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [mainPeriod, broker, brokerGroup, initialDate, finalDate],
  );

  const config = {
    data: data,
    isStack: true,
    xField: 'quantidadeOfertasAtivas',
    yField: 'nomeCorretor',
    seriesField: 'quantidadeOfertasAtivas',
    label: {
      position: 'middle',
    },
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    config,
    isLoading,
    onLoad
  };
}

