/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useListagemUrl() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async () => {
      try {
        await api.get(url.LISTAGEM_URL.CONSULTA()).then((res) => {
          setData(res?.data || []);
          setQuantidade(res?.data?.total);
        });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    quantidade,
    onLoad,
  };
}
