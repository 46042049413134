import {useEffect, useState, useCallback} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useBairro(cidadeId, nome) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(
    async (params) => {
      const filterCidadeId = cidadeId ? cidadeId : null;
      const filterNome = nome ? nome : null;
      let filter = '';

      if (filterCidadeId && !params.cidadeId) {
        if (filter.length > 0) filter += `&cidadeId=${filterCidadeId}`;
        else filter = `?cidadeId=${filterCidadeId}`;
      }

      if (filterNome && !params.nome) {
        if (filter.length > 0) filter += `&nome=${filterNome}`;
        else filter = `?nome=${filterNome}`;
      }

      if (params.cidadeId) {
        if (filter.length > 0) filter += `&cidadeId=${params.cidadeId}`;
        else filter = `?cidadeId=${params.cidadeId}`;
      }

      if (params.nome) {
        if (filter.length > 0) filter += `&nome=${params.nome}`;
        else filter = `?nome=${params.nome}`;
      }

      try {
        await api.get(`${url.DROPDOWN.BAIRRO_DROPDOWN()}${filter}`)
          .then(res => {
            setData(res?.data || []);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [cidadeId, nome]
  );

  useEffect(() => {
    const params = { cidadeId, nome };
    onLoad(params);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
