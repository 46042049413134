import React, {useState} from 'react';

import copyToClipboard from '~/shared/copyToClipboard';
import {CheckOutlined, CopyOutlined} from '@ant-design/icons';

const CopyPhoneButton = ({phone}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyPhone = () => {
    if (phone && phone !== undefined) {
      copyToClipboard(phone);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  };

  if (!isCopied)
    return (
      <span onClick={handleCopyPhone}>
        <CopyOutlined className='ignoreClick' size={10} />
      </span>
    );

  return (
    <span>
      <CheckOutlined className='ignoreClick' size={10} color="green" />
    </span>
  );
};

export default CopyPhoneButton;

