import { useCallback, useEffect, useState } from "react";
import api from '~/services/api';
import { parseToQueryString } from "~/shared/parseToQueryString";
import moment from 'moment';
moment.locale('pt-BR');


export default function useSalesRanking(mainPeriod, brokerGroup, initialDate, finalDate) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const onLoad = useCallback(
    async () => {
      const filtro = parseToQueryString({
        periodoPrincipal: mainPeriod,
        grupoCorretoresId: brokerGroup,
        dataIni: initialDate,
        dataFim: finalDate
      });

      try {
        await api.get(`Dashboard/gerente/ranking-geral-vendas?${filtro}`).then((res) => {
          setData(res?.data?.data);
          setIsLoading(false);
        });
      } catch (e) {
        setData([]);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [mainPeriod, brokerGroup, initialDate, finalDate],
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  }
}
