import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useConfigSite() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const onLoad = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.get(url.CONFIGURACOES_SITE.CONFIGURACOES_SITE())
        .then((res) => {
          setData(res?.data?.data);
          const banners = res?.data?.data?.banners?.map(banner => ({ url: banner.urlBanner, linkBanner: banner.linkBanner }))
          const result = res?.data?.data;
          const data = {
            ...result,
            banners
          }
          setData(data);
        });
    } catch (e) {
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, []);


  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    isLoading,
    onLoad,
  };
}
