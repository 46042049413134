import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Upload, DatePicker, Select, Divider } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './index.css';

import EmpresaFilial from './Filial';
import { Tabs } from '~/components/Tabs';
import { Tab } from '~/components/Tabs';
import Page from '~/components/Page';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';
import useCidadesDropdown from '~/actions/DropDown/useCidadesDropdown';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';

import moment from 'moment';
import { red } from '@material-ui/core/colors';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  EMPRESA: 1,
  FILIAL: 2,
};

const { Option } = Select;

function Empresa(props) {

  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [empresaId, setEmpresaId] = useState(null);
  const [listMatrizId, setListMatrizId] = useState([]);
  const [fileLogo, setFileLogo] = useState(null);
  const [fileMarcaDAgua, setFileMarcaDAgua] = useState(null);
  const [errorMarcaDAgua, setErrorMarcaDAgua] = useState([]);
  const [errorLogo, setErrorLogo] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS.EMPRESA);
  const [disabledTabs, setDisabledTabs] = useState(true);
  const [isEditFilial, setEditFilial] = useState(false);
  const { data: dataCidadesDropdown, onLoad: onLoadCidadesDropdown } =
    useCidadesDropdown();

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleChangeImgLogo = async (image) => {
    if (!image.file.url) {
      var base64 = await getBase64(image.file);
      let im = new Image();
      im.src = base64;
      im.onload = () => {
        if (im?.width > 1250 || im?.height > 1250) {
          setErrorLogo([
            `Favor informe uma imagem de logo de no máximo 1250px`,
          ]);
        } else {
          image.file.url = base64;
          setErrorLogo([]);
          setFileLogo(image.file);
        }
      };
    }
  };

  const handleChangeImgMarcaDAgua = async (image) => {
    if (!image.file.url) {
      var base64 = await getBase64(image.file);
      let im = new Image();
      im.src = base64;
      im.onload = () => {
        if (im?.width !== 250 || im?.height !== 250) {
          setErrorMarcaDAgua([
            `Sua imagem de marca d'água é tamanho ${im?.width}px por ${im?.height}px. Favor informe uma no tamanho 250px por 250px.`,
          ]);
        } else {
          image.file.url = base64;
          setErrorMarcaDAgua([]);
          setFileMarcaDAgua(image.file);
        }
      };
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchEmpresa() {
      if (props.match.params) {
        if (props.match.params.modo === MODO.EDITAR) {
          const id = props.location.state.row.id;
          await api
            .get(url.EMPRESAS.CONSULTA_BY_ID(id))
            .then((res) => {
              const editData = res.data;
              editData.cnpj = mask(editData.cnpj, [pattern.cnpj]);
              editData.dataAbertura = moment(
                editData.dataAbertura,
                'YYYY-MM-DD',
              ).clone();
              editData.cep = mask(editData.cep, [pattern.cep]);

              form.setFieldsValue({...editData})
              setFileLogo({ url: editData?.urlLogo });
              setFileMarcaDAgua({ url: editData?.urlMarcaDAgua });
              setModo(MODO.EDITAR);
              setEmpresaId(id);
              setDisabledTabs(false);

              if (editData.matrizId) {
                setEditFilial(true);
                setDisabledTabs(true);
                form.setFieldsValue({ matrizId: editData.matrizId });
              }

              if (props?.location?.state?.tab > 0) {
                const activeTAB =
                  props?.location?.state?.tab === 1
                    ? TABS.EMPRESA
                    : TABS.FILIAL;
                setActiveTab(activeTAB);
              }

              setTimeout(() => {
                setloadPage(false);
              }, 2000);
            })
            .catch((err) => {
              setloadPage(false);
              const { errors } = err.response.data;
              if (errors) {
                if (Array.isArray(errors)) {
                  for (const item in errors) {
                    Notify('error', pt.comum.atencao, errors[item]);
                  }
                } else {
                  Notify('error', pt.comum.atencao, errors);
                }
              }
            });
        } else {
          setModo(MODO.CADASTRAR);
          setloadPage(false);
        }
      }
    }

    fetchEmpresa();

    async function fetchMatriz() {
      await api.get(url.EMPRESAS.CONSULTA()).then((res) => {
        setListMatrizId(res?.data?.data || []);
      });
    }

    if (listMatrizId.length <= 0) fetchMatriz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);


  function voltar() {
    history.push('/app/empresa');
  }

  function onSave() {
    setIsLoading(true);

    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            nome: dados.nome,
            cnpj: dados.cnpj.replace(/\D/g, ''),
            email: dados.email,
            cep: dados.cep.replace(/\D/g, ''),
            endereco: dados.endereco,
            numero: dados.numero,
            complemento: dados.complemento,
            inscricaoEstadual: dados.inscricaoEstadual || null,
            inscricaoMunicipal: dados.inscricaoMunicipal || null,
            numeroRegistroJuntaComercial:
              dados.numeroRegistroJuntaComercial || null,
            pessoaContato: dados.pessoaContato || null,
            telefone: dados.telefone ? dados.telefone.replace(/\D/g, '') : null,
            dataAbertura: dados.dataAbertura,
            matrizId: dados.matrizId,
            urlApiDesktop: dados.urlApiDesktop,
            logoBase64: fileLogo?.url,
            marcaDAguaBase64: fileMarcaDAgua?.url,
            urlSite: dados.urlSite,
            cidadeId: dados.cidadeId,
          };

          await api
            .post(url.EMPRESAS.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);

              Notify('success', '', pt.geral.cadastro_com_sucesso);

              const row = res.data?.data;
              history.push({
                pathname: `/app/empresa/manutencao/${MODO.EDITAR}`,
                state: { row, tab: TABS.FILIAL },
              });
            })
            .catch((err) => {
              setIsLoading(false);
              const { errors } = err.response.data;
              if (errors) {
                if (Array.isArray(errors)) {
                  for (const item in errors) {
                    Notify('error', pt.comum.atencao, errors[item]);
                  }
                } else {
                  Notify('error', pt.comum.atencao, errors);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: empresaId,
            nome: dados.nome,
            cnpj: dados.cnpj.replace(/\D/g, ''),
            email: dados.email,
            cep: dados.cep.replace(/\D/g, ''),
            endereco: dados.endereco,
            numero: dados.numero,
            complemento: dados.complemento,
            inscricaoEstadual: dados.inscricaoEstadual || null,
            inscricaoMunicipal: dados.inscricaoMunicipal || null,
            numeroRegistroJuntaComercial:
              dados.numeroRegistroJuntaComercial || null,
            pessoaContato: dados.pessoaContato || null,
            telefone: dados.telefone ? dados.telefone.replace(/\D/g, '') : null,
            dataAbertura: dados.dataAbertura,
            matrizId: dados.matrizId,
            urlApiDesktop: dados.urlApiDesktop,
            logoBase64: fileLogo?.url,
            marcaDAguaBase64: fileMarcaDAgua?.url,
            urlSite: dados.urlSite,
            cidadeId: dados.cidadeId,
          };

          await api
            .put(url.EMPRESAS.EDITAR(), payload)
            .then((res) => {
              setIsLoading(false);

              Notify('success', '', pt.geral.atualizado_com_sucesso);

              if (isEditFilial) {
                voltar();
              } else {
                setActiveTab(TABS.FILIAL);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                if (Array.isArray(error)) {
                  for (const item in error) {
                    Notify('error', pt.comum.atencao, error[item]);
                  }
                } else {
                  Notify('error', pt.comum.atencao, error);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
      });
  }

  function handleMask(e, typeMask) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [typeMask]);

    if (typeMask === pattern.cnpj) form.setFieldsValue({ cnpj: value });
    if (typeMask === pattern.cep) form.setFieldsValue({ cep: value });
    if (typeMask === pattern.telefone) form.setFieldsValue({ telefone: value });
  }

  async function selectTab(tab) {
    setActiveTab(tab);
  }

  const uploadButton = (logo) => (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{logo ? `Upload logo empresa` : `Upload marca d'água empresa (250px por 250px)`}</div>
    </div>
  );

  async function searchCidadesDropdown(value) {
    await onLoadCidadesDropdown({ estadoId: undefined, cidade: value });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="card-custom-basic card-transparent">
              <CardBody className="p-0">
                <Tabs
                  activeTab={activeTab}
                  setTab={(tab) => selectTab(tab)}
                  disabledTabs={disabledTabs}>
                  <Tab title="Empresa" desc="Manutenção Empresa">
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0 p-4">
                      <CardBody>
                        <Row>
                          <Col xs="12">
                            <div className="my-2 p-2">
                              <Form
                                form={form}
                                scrollToFirstError>
                                <Row>
                                  <Col xs="12">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Nome</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="nome"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o nome!',
                                        },
                                      ]}>
                                      <Input placeholder=" Nome" />
                                    </Form.Item>
                                    <Form.Item
                                      hidden
                                      className="my-2"
                                      name="matrizId">
                                      <Input placeholder=" Matriz" disabled />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12" md="3">
                                    <p className="heading-small text-muted mb-2">
                                      <small>CNPJ</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="cnpj"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o CNPJ!',
                                        },
                                      ]}>
                                      <Input
                                        placeholder=" CNPJ"
                                        maxLength={18}
                                        onKeyUp={(e) =>
                                          handleMask(e, pattern.cnpj)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="6">
                                    <p className="heading-small text-muted mb-2">
                                      <small>E-mail</small>
                                    </p>
                                    <Form.Item className="my-2" name="email">
                                      <Input
                                        placeholder=" E-mail"
                                        type="email"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="3">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Data de Abertura</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="dataAbertura"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            'Favor informar a data de abertura!',
                                        },
                                      ]}>
                                      <DatePicker
                                        placeholder=" Data de Abertura"
                                        style={{ width: '100%' }}
                                        format="DD/MM/YYYY"
                                        disabledDate={(current) => {
                                          return (
                                            current &&
                                            current > moment().endOf('day')
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12" md="6">
                                    <p className="heading-small text-muted mb-2">
                                      <small>URL Site</small>
                                    </p>
                                    <Form.Item className="my-2" name="urlSite">
                                      <Input placeholder=" URL Site" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="6">
                                    <p className="heading-small text-muted mb-2">
                                      <small>URL Api Desktop</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="urlApiDesktop">
                                      <Input placeholder=" URL Api Desktop" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12" md="4">
                                    <p className="heading-small text-muted mb-2">
                                      <small>CEP</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="cep"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o CEP!',
                                        },
                                      ]}>
                                      <Input
                                        placeholder=" CEP"
                                        onKeyUp={(e) =>
                                          handleMask(e, pattern.cep)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="4">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Cidade</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="cidadeId"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar a cidade',
                                        },
                                      ]}>
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                            .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                                        }
                                        placeholder=" Cidade"
                                        onSearch={searchCidadesDropdown}>
                                        {dataCidadesDropdown.map(item => (
                                          <Option key={item.id}>
                                            {item.nome}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Divider />
                                <Row>
                                  <Col xs="12">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Endereço</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="endereco"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o endereço!',
                                        },
                                      ]}>
                                      <Input placeholder=" Endereço" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12" md="3">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Número</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="numero"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o número!',
                                        },
                                      ]}>
                                      <Input placeholder=" Número" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="9">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Complemento</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="complemento">
                                      <Input placeholder=" Complemento" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12" md="4">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Inscrição Estadual</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="inscricaoEstadual">
                                      <Input placeholder=" Inscrição Estadual" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="4">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Inscrição Municipal</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="inscricaoMunicipal">
                                      <Input placeholder=" Inscrição Municipal" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="4">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Número de Registro</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="numeroRegistroJuntaComercial">
                                      <Input placeholder=" Número de Registro" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="12" md="8">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Contato</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="pessoaContato"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o Contato!',
                                        },
                                      ]}>
                                      <Input placeholder=" Contato" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs="12" md="4">
                                    <p className="heading-small text-muted mb-2">
                                      <small>Telefone</small>
                                    </p>
                                    <Form.Item
                                      className="my-2"
                                      name="telefone"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Favor informar o Telefone!',
                                        },
                                      ]}>
                                      <Input
                                        placeholder=" (99) 9999-9999"
                                        onKeyUp={(e) =>
                                          handleMask(e, pattern.telefone)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                              <Row>
                                <Col xs="12" md="6">
                                  <p className="heading-small text-muted mb-2">
                                    <small>Logo Empresa</small>
                                  </p>
                                  <Upload
                                    accept={'image/png,image/jpeg'}
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    multiple={false}
                                    showUploadList={false}
                                    onChange={handleChangeImgLogo}
                                    beforeUpload={(file) => {
                                      return false;
                                    }}>
                                    {fileLogo?.url ? (
                                      <img
                                        src={fileLogo.url}
                                        alt="avatar"
                                        style={{ width: '100%', maxWidth: 300 }}
                                      />
                                    ) : (
                                      uploadButton(true)
                                    )}
                                  </Upload>
                                  {errorLogo.map((e, index) => (
                                    <p
                                      key={index}
                                      style={{ color: red[400] }}>{`- ${e}`}</p>
                                  ))}
                                </Col>
                                <Col xs="12" md="6">
                                  <p className="heading-small text-muted mb-2">
                                    <small>Marca d'água</small>
                                  </p>
                                  <Upload
                                    accept={'image/png,image/jpeg'}
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    multiple={false}
                                    showUploadList={false}
                                    onChange={handleChangeImgMarcaDAgua}
                                    beforeUpload={(file) => {
                                      return false;
                                    }}>
                                    {fileMarcaDAgua?.url ? (
                                      <img
                                        src={fileMarcaDAgua.url}
                                        alt="avatar"
                                        style={{ width: '100%', maxWidth: 300 }}
                                      />
                                    ) : (
                                      uploadButton
                                    )}
                                  </Upload>
                                  {errorMarcaDAgua.map((e, index) => (
                                    <p
                                      key={index}
                                      style={{ color: red[400] }}>{`- ${e}`}</p>
                                  ))}
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col xs="12" md="3">
                                  <Button
                                    onClick={onSave}
                                    icon
                                    labelPosition="left"
                                    size="medium"
                                    fluid
                                    className="mt-1"
                                    color="violet"
                                    isloading={loading.toString()}
                                    loadtext={
                                      modo === MODO.CADASTRAR
                                        ? 'Cadastrando...'
                                        : 'Atualizando...'
                                    }>
                                    <Icon name="check" />
                                    {modo === MODO.CADASTRAR
                                      ? 'Cadastrar'
                                      : 'Atualizar'}
                                  </Button>
                                </Col>
                                <Col xs="12" md="3">
                                  <Button
                                    icon
                                    labelPosition="left"
                                    size="medium"
                                    fluid
                                    className="mt-1"
                                    onClick={() => voltar()}>
                                    <Icon name="undo" />
                                    Voltar
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Tab>
                  <Tab title="Filial" desc="Manutenção Filial">
                    <EmpresaFilial
                      setActiveTab={(value) => selectTab(value)}
                      empresaId={empresaId}
                    />
                  </Tab>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Empresa;
