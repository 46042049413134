import React, {Fragment} from 'react';
import useSalesFunnel from './use-sales-funnel';

import {Card} from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';

import {Funnel} from '@ant-design/charts';

export default function SalesFunnel({filters}) {
  const {mainPeriod, broker, brokerGroup, initialDate, finalDate} = filters;

  const {config} = useSalesFunnel(mainPeriod, broker,brokerGroup, initialDate, finalDate);

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 6,
      }}>
      <CardTitle>Funil de Vendas</CardTitle>
      <Fragment>
        {config.data && config.data.length > 0 ? (
         <Funnel {...config} />

        ) : (
          <span>Nenhum dado encontrado</span>
        )}
      </Fragment>
    </Card>
  );
}

