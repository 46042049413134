import React, {Fragment, useEffect, useState} from 'react';
import {Modal, Icon} from 'semantic-ui-react';
import {Select, Input} from 'antd';
import Button from '~/shared/Button';
import useAlterarStatusEmLote from '~/actions/Atendimento/useAlterarStatusEmLote';
import pt from '~/infra/resources/strings/pt';
import Notify from '~/shared/Notify';
import ProgressBarAcaoEmLote from '../ProgressBarAcaoEmLote';

const {Option} = Select;
const STATUS_PERDIDO = 3;
function ModalAlterarStatusAtendimentoEmLote({
  open,
  close,
  atendimentos,
  textTitle,
  atualizarQuadro,
}) {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMotivoDesistencia, setSelectedMotivoDesistencia] =
    useState(null);
  const [justificativa, setJustificativa] = useState(null);
  const [showSecondConfirmationModal, setShowSecondConfirmationModal] =
    useState(false);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const {alterarStatusEmLote, isLoading} = useAlterarStatusEmLote();
  const onSubmit = () => {
    if (selectedStatus) {
      const requerimentosDoStatus =
        selectedStatus === 3 ? !!selectedMotivoDesistencia : true;
      setShowSecondConfirmationModal(requerimentosDoStatus);
    }
  };

  const handleCloseModal = () => {
    if (!isLoading) {
      close();
    }
  };

  useEffect(() => {
    setSelectedTotal(atendimentos?.total ?? 0);
  }, [atendimentos, selectedTotal]);

  const onConfirmSubmit = () => {
    const {atendimentoIds, filtros, lanes} = atendimentos;
    alterarStatusEmLote(
      atendimentoIds,
      filtros,
      lanes,
      selectedStatus,
      selectedMotivoDesistencia,
      justificativa,
      localStorage.getItem('icaseUsuarioId'),
    )
      .then(() => {
        close();
        atualizarQuadro();
      })
      .catch((error) => {
        Notify(
          'error',
          pt.comum.aviso,
          'Não foi possivel atualizar os status dos atendimentos',
        );
      });
  };
  const statusAtendimentos = [
    {label: 'Lead na Base', key: 4},
    {label: 'Em Tentativa', key: 8},
    {label: 'Em Atendimento', key: 2},
    {label: 'Perdido', key: 3},
  ];

  const motivoDesistencia = [
    {label: 'Já comprou imóvel', key: 1},
    {label: 'Tentativas de contato sem sucesso', key: 2},
    {label: 'Não aprovou financiamento', key: 3},
    {label: 'Contato inexistente', key: 4},
    {label: 'Apenas pesquisando', key: 5},
    {label: 'Não gostou da localização', key: 6},
    {label: 'Não gostou do projeto', key: 7},
    {label: 'Achou caro', key: 8},
    {label: 'Corretor do Mercado', key: 9},
    {label: 'Lead duplicado', key: 0},
    {label: 'Convertido em Oferta Ativa', key: 11},
  ];

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => handleCloseModal()}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>{textTitle}</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <Select
              style={{width: '85%'}}
              placeholder=" Status"
              disabled={isLoading}
              onChange={(value) => setSelectedStatus(value)}>
              {statusAtendimentos.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item?.label}
                </Option>
              ))}
            </Select>
          </div>
          <div
            hidden={selectedStatus !== STATUS_PERDIDO}
            className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <Select
              style={{width: '85%'}}
              disabled={isLoading}
              placeholder=" Motivo Desistencia"
              onChange={(value) => setSelectedMotivoDesistencia(value)}>
              {motivoDesistencia.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item?.label}
                </Option>
              ))}
            </Select>
          </div>
          <div
            hidden={selectedStatus !== STATUS_PERDIDO}
            className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4">
            <Input.TextArea
              style={{width: '85%'}}
              placeholder="Justificativa"
              disabled={isLoading}
              name="justificativa"
              onChange={(textarea) => {
                setJustificativa(textarea.currentTarget.value);
              }}
            />
          </div>
          <div
            className="d-flex flex-column justify-content-center align-items-center text-center p-3 mb-4"
            hidden={!isLoading}>
            <ProgressBarAcaoEmLote
              usuarioId={localStorage.getItem('icaseUsuarioId')}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              isloading={`${isLoading}`}
              onClick={() => onSubmit()}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              onClick={() => handleCloseModal()}>
              <Icon name="times" />
              Fechar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <SecondConfirmationModal
        open={showSecondConfirmationModal}
        total={selectedTotal}
        onCancel={() => {
          setShowSecondConfirmationModal(false);
        }}
        onConfirm={() => {
          onConfirmSubmit();
          setShowSecondConfirmationModal(false);
        }}
      />
    </Fragment>
  );
}

const SecondConfirmationModal = ({open, onConfirm, onCancel, total}) => {
  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="small"
        open={open}
        onClose={() => {
          onCancel();
        }}>
        <Modal.Content>
          <div className="p-3 mt-4 d-flex justify-content-center align-items-center w-100">
            <h5>Confirmar alteração de status</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span>
              <strong>Atenção:</strong>
              Você está prestes a alterar os status de{' '}
              {total > 99
                ? 'uma grande quantidade de atendimentos.'
                : `${total} atendimentos.`}
              <br />
              Tem certeza de que deseja continuar?
            </span>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-3 mb-4">
            <span style={{fontSize: 13}}>
              Observação: Dependendo do volume de dados, este processo pode
              levar alguns minutos.
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center p-3 mb-4">
            <Button
              icon
              color="violet"
              labelPosition="left"
              fluid
              onClick={() => onConfirm()}
              loadtext={'Processando...'}
              size="medium">
              <Icon name="check" />
              Confirmar
            </Button>
            <Button
              icon
              fluid
              labelPosition="left"
              size="medium"
              onClick={() => onCancel()}>
              <Icon name="times" />
              Cancelar
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default ModalAlterarStatusAtendimentoEmLote;
