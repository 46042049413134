/* eslint-disable no-unused-vars */
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Row, Col} from 'reactstrap';
import {DatePicker} from 'antd';
import {Pie, measureTextWidth} from '@ant-design/charts';
import getTiposMidia from '~/infra/resources/strings/enums/getTiposMidia';
import api from '~/services/api';
import url from '~/infra/urls';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
moment.locale('pt-BR');

const dateFormat = 'DD/MM/YYYY';
const {RangePicker} = DatePicker;

const AtendimentosDonut = ({idCorretor}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [atendimentos, setAtendimentos] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isCorretor = !!roles.find((x) => x.toUpperCase() === 'CORRETOR');
  const usuarioId = localStorage.getItem('icaseUsuarioId');

  const onLoad = useCallback(async () => {
    setIsLoading(true);

    let configuracoessistemas = `?corretorId=${
      isCorretor ? usuarioId : idCorretor ? idCorretor : ''
    }`;

    if (dateRange && dateRange[0])
      configuracoessistemas += `&dataIni=${dateRange[0].format('YYYY/MM/DD')}`;

    if (dateRange && dateRange[1])
      configuracoessistemas += `&dataFim=${dateRange[1].format('YYYY/MM/DD')}`;

    await api
      .get(url.ATENDIMENTO.ATENDIMENTOS_POR_MIDIA(configuracoessistemas))
      .then((res) => {
        if (res?.data)
          setAtendimentos(
            res.data.map((d) => {
              return {
                type: getTiposMidia(d.tipoMidia),
                value: d.quantidade,
              };
            }),
          );

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const {erros} = err.response.data;
        if (erros) {
          for (const item in erros) {
          }
        }
      });
    // eslint-disable-next-line
  }, [dateRange, idCorretor]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  function renderStatistic(containerWidth, text, style) {
    var _measureTextWidth = (0, measureTextWidth)(text, style),
      textWidth = _measureTextWidth.width,
      textHeight = _measureTextWidth.height;
    var R = containerWidth / 2;
    var scale = window.innerWidth < 1400 ? 0.85 : 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)),
          ),
        ),
        1,
      );
    }
    var textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
      .concat(textStyleStr, ';font-size:')
      .concat(scale, 'em;line-height:')
      .concat(scale < 1 ? 1 : 'inherit', ';">')
      .concat(text, '</div>');
  }

  var configDonut = {
    appendPadding: 10,
    data: atendimentos,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: function formatter(v) {
          return ''.concat(v, ' \xA5');
        },
      },
    },
    legend: {
      itemValue: {
        formatter: (text, item) => {
          const items = atendimentos.filter((d) => d.type === item.value);
          return items.length
            ? `(${(
                (items.reduce((a, b) => a + b.value, 0) /
                  items.length /
                  atendimentos.reduce((a, b) => a + b.value, 0)) *
                100
              ).toFixed(0)}%)`
            : '-';
        },
        style: {
          opacity: 0.65,
        },
      },
      position: window.innerWidth < 990 ? 'bottom' : 'right',
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {textAlign: 'center'},
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -4,
        offsetX: window.innerWidth < 1300 ? -15 : 0,
        customHtml: function customHtml(container, view, datum) {
          var _container$getBoundin = container.getBoundingClientRect(),
            width = _container$getBoundin.width,
            height = _container$getBoundin.height;
          var d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          var text = datum ? datum.type : 'Atendimentos';
          return renderStatistic(d, text, {fontSize: 28});
        },
      },
      content: {
        offsetY: 4,
        style: {fontSize: '32px'},
        customHtml: function customHtml(container, view, datum, data) {
          var _container$getBoundin2 = container.getBoundingClientRect(),
            width = _container$getBoundin2.width;
          var text = datum
            ? `${datum.value}`
            : `${data.reduce(function (r, d) {
                return r + d.value;
              }, 0)}`;
          return renderStatistic(width, text, {fontSize: 32});
        },
      },
    },
    interactions: [
      {type: 'element-selected'},
      {type: 'element-active'},
      {type: 'pie-statistic-active'},
    ],
  };

  return (
    <Fragment>
      <Row className="pl-4">
        <p className="dash-content-title">Atendimentos</p>
      </Row>
      <div style={{padding: '0 50px'}}>
        <Row>
          <Col xs="12">
            <div className="text-center py-4">
              <RangePicker
                onChange={(date) => setDateRange(date)}
                locale={locale}
                format={dateFormat}
                style={{width: '100%'}}
              />{' '}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="text-center">
              <Pie loading={isLoading} {...configDonut} />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AtendimentosDonut;
