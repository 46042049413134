import useSWR from 'swr';
import url from '~/infra/urls';
import { useSWRConfig } from 'swr';

export default function useGetOfertaAtivasDisponiveis(filterByNome = '', options = {}) {
  const config = useSWRConfig();
  const apiUrl = url.OFERTA_ATIVA.PROPOSTAS_ATIVAS_DISPONIVEIS();
  const queryArgs = filterByNome ? `?ofertaAtivaNome=${filterByNome}` : '';
  const hookRet = useSWR(`${apiUrl}${queryArgs}`, config?.fetcher, options)

  const ofertasAtivasDisponiveis = hookRet?.data?.data || [];
  const hasOfertas = ofertasAtivasDisponiveis?.length > 0;

  return {
    ...hookRet,
    ofertasAtivasDisponiveis,
    hasOfertas
  };
}
