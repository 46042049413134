import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select } from 'antd';
import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';

import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';

import useCidadesDropdown from '~/actions/DropDown/useCidadesDropdown';

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function Bairros(props) {
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [modo, setModo] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [identificador, setIdentificador] = useState();
  const [bairroId, setBairrosId] = useState(null);
  const [cidadeId, setCidadeId] = useState(null);
  const { data: dataCidades, onLoad: onLoadCidadesDropdown } =
    useCidadesDropdown(undefined, undefined, cidadeId);
  const [form] = Form.useForm();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  useEffect(() => {
    if (props.match.params) {
      if (props.match.params.modo === MODO.EDITAR) {
        const { row: editData, identificador } = props.location.state;

        setInitialValues(editData);
        setIdentificador(identificador);
        setModo(MODO.EDITAR);
        setBairrosId(props.location.state.row.id);
        setCidadeId(props.location.state.row.cidadeId);

        setTimeout(() => {
          setloadPage(false);
        }, 2000);
      } else {
        const { identificador } = props.location.state;

        setIdentificador(identificador);
        setModo(MODO.CADASTRAR);
        setloadPage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  async function onSearchCidade(value) {
    await onLoadCidadesDropdown({ cidade: value, cidadeId: null });
  }

  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/bairros/${identificador}`);
    } else {
      history.push('/app/bairros');
    }
  }

  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            nome: dados.nome,
            cidadeId: dados.cidadeId,
          };

          await api
            .post(url.BAIRRO.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);

              if (props?.onVoltar) {
                localStorage.setItem('icaseTipoImovelId', res.data?.data?.id);
                voltar();
              } else {
                setOpenModalConfirm(true);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: bairroId,
            nome: dados.nome,
            cidadeId: dados.cidadeId,
          };

          await api
            .put(url.BAIRRO.EDITAR(), payload)
            .then(() => {
              setIsLoading(false);
              setOpenModalConfirm(true);
              voltar()
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch(() => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  return (
    <Fragment>
      <Page loadPage={loadPage}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">
                  {modo === MODO.CADASTRAR
                    ? 'Cadastrar bairro'
                    : 'Atualizar bairro'}
                </h5>
                <p className="category">Manutenção de bairro</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        initialValues={initialValues}
                        scrollToFirstError>
                        <Row>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Nome</small>
                            </p>
                            <Form.Item
                              className="my-2"
                              name="nome"
                              rules={[
                                {
                                  required: true,
                                  message: 'Favor informar o nome!',
                                },
                              ]}>
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="6">
                            <p className="heading-small text-muted mb-2">
                              <small>Cidade</small>
                            </p>
                            <Form.Item className="my-2" name="cidadeId">
                              <Select
                                getPopupContainer={(node) => node.parentNode}
                                showSearch
                                filterOption={false}
                                placeholder=" Cidade"
                                onSearch={onSearchCidade}
                                allowClear>
                                {dataCidades.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.nome}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <Row>
                        <Col xs="12" md="4">
                          <Button
                            onClick={onSave}
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            color="violet"
                            isloading={loading.toString()}
                            loadtext={
                              modo === MODO.CADASTRAR
                                ? 'Cadastrando...'
                                : 'Atualizando...'
                            }>
                            <Icon name="check" />
                            {modo === MODO.CADASTRAR
                              ? 'Cadastrar'
                              : 'Atualizar'}
                          </Button>
                        </Col>
                        <Col xs="12" md="4">
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            className="mt-3"
                            onClick={() => voltar()}>
                            <Icon name="reply" />
                            Voltar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete={false}
              icon="check circle"
              open={openModalConfirm}
              close={voltar}
              message={modo === MODO.CADASTRAR ? 'Cadastro realizado' : 'Alterações salvas'}
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default Bairros;
