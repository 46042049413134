import {useState} from 'react';

import pt from '~/infra/resources/strings/pt';
import URL from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';

export default function useTransformarAtendimentoEmOfertaAtiva() {
  const [isLoading, setIsLoading] = useState(false);
  const transformarAtendimentoEmOfertaAtiva = async (
    atendimentoIds,
    filtros,
    lanes,
    nome,
    membros,
  ) => {
    setIsLoading(true);
    const {corretor, grupoCorretores} = agruparIdsPorTipo(membros);
    const usuarioId = localStorage.getItem('icaseUsuarioId');
    const empresaId = localStorage.getItem('icaseEmpresaId');
    try {
      await api
        .post(URL.ATENDIMENTO.TRANSFORMAR_EM_OFERTA_ATIVA(), {
          atendimentoIds,
          filtros,
          lanes,
          nome,
          corretorIds: corretor,
          grupoCorretoresIds: grupoCorretores,
          usuarioId,
          empresaId,
        })
        .then((res) => {
          Notify(
            'success',
            pt.comum.aviso,
            'Atendimentos transformados em Oferta Ativa',
          );
        });
    } catch (err) {
      Notify('error', pt.comum.aviso, 'Não foi possivel alterar os status');
    } finally {
      setIsLoading(false);
    }
  };
  return {
    transformarAtendimentoEmOfertaAtiva,
    isLoading,
  };
}

function agruparIdsPorTipo(dados) {
  return dados.reduce((acumulador, item) => {
    // Converte o tipo para camelCase
    const tipoCamelCase = item.tipo.replace(/-([a-z])/g, (match, letter) =>
      letter.toUpperCase(),
    );

    // Verifica se já existe o array para o tipo, se não, cria um novo array
    if (!acumulador[tipoCamelCase]) {
      acumulador[tipoCamelCase] = [];
    }
    // Adiciona o id ao array correspondente ao tipo
    acumulador[tipoCamelCase].push(item.id);

    return acumulador;
  }, {});
}
