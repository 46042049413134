import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useProfissoes(current, pageSize, nomeProfissao) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async (params) => {
      setIsLoading(true);
      const filterCurrent = current ? current : null;
      const tamanhoPagina = pageSize ? pageSize : null;
      const filterNomeProfissao = nomeProfissao ? nomeProfissao : null;
      let filter = '';

      if (filterCurrent && !params.current) filter = `?numeroPagina=${filterCurrent}`;

      if (params?.current) filter = `?numeroPagina=${params.current}`;

      if (tamanhoPagina && !params.pageSize) {
        if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
        else filter = `?tamanhoPagina=${tamanhoPagina}`;
      }

      if (params?.pageSize) {
        if (filter.length > 0) filter += `&tamanhoPagina=${params.pageSize}`;
        else filter = `?tamanhoPagina=${params.pageSize}`;
      }

      if (filterNomeProfissao) {
        if (filter.length > 0) filter += `&nome=${filterNomeProfissao}`;
        else filter = `?nome=${filterNomeProfissao}`;
      }

      if (params?.nomeProfissao) {
        if (filter.length > 0) filter += `&nome=${params.nomeProfissao}`;
        else filter = `?nome=${params.nomeProfissao}`;
      }

      try {
        await api
          .get(`${url.PROFISSOES.CONSULTA()}${filter ? filter : ''}`)
          .then((res) => {
            setData(res?.data?.data || []);
            setQuantidade(res?.data?.total);
          });
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [pageSize, nomeProfissao, current]
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
