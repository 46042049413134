import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

const today = moment();
const data = moment().format('DD/MM/YYYY');
const hora = moment(today).format('HH:mm:ss');

const styles = StyleSheet.create({
  Page: {
    backgroundColor: '#FFF',
    paddingBottom: '50px'
  },
  Container: {
    padding: 15,
    width: '100%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Section: {
    flexGrow: 1,
    margin: '5px 0',
  },
  SectionHeader: {
    paddingBottom: 5,
    marginBottom: 5
  },
  DividerSection: {
    borderBottom: '1px dashed #808080',
    width: '100%',
    marginBottom: '2px'
  },
  Bg1: {
    backgroundColor: '#2185d082'
  },
  Bg2: {
    backgroundColor: '#2185d026'
  },
  Bg3: {
    backgroundColor: 'rgb(224, 225, 226)'
  },
  Bg4: {
    backgroundColor: '#8080808a',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '1px',
    paddingBottom: '1px'
  },
  DividerSectionTop: {
    borderTop: '1px dashed #808080',
    width: '100%',
    marginTop: '2px'
  },
  DividerSectionTopSolid: {
    borderTop: '1px Solid #808080',
    width: '100%',
    marginTop: '2px',
    borderBottom: '1px Solid #808080',
    marginBottom: '2px'
  },
  DividerSectionTopDashed: {
    borderTop: '1px dashed #808080',
    width: '100%',
    marginTop: '2px',
    borderBottom: '1px dashed #808080',
    marginBottom: '2px'
  },
  Title: {
    fontSize: 16,
    fontWeight: 600,
    margin: '10px 0',
    textAlign: 'left',
  },
  SubtitleFirst: {
    fontSize: 14,
    textAlign: 'center',
    margin: '12px 0',
    paddingBottom: 8,
    textDecoration: 'underline'
  },
  Span: {
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: '1.1',
    color: '#333333',
    paddingRight: '10px'
  },
  ColorBlack: {
    color: '#000'
  },
  Label: {
    fontSize: '10px',
    margin: '2px 0',
    color: '#333333',
    fontWeight: 800,
    paddingRight: '10px'
  },
  pageDate: {
    fontSize: 12,
    position: 'absolute',
    top: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
  },
  pageNumber: {
    fontSize: 12,
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  registerCount: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: '10px',
    textAlign: 'left',
  }
});

const getStatusNegocio = (idStatus) => {
  switch (idStatus) {
    case 1:
      return 'Negócio Fechado';
    case 2:
      return 'Em Atendimento';
    case 3:
      return 'Desistência';
    case 4:
      return 'Lead na Base'
    case 5:
      return 'Em Análise Financeira'
    case 6:
      return 'Análise Financeira Aprovada'
    case 7:
      return 'Análise Financeira Reprovada'
    case 8:
      return 'Em Tentativa'
    default:
      return '';
  }
}


const getTiposMidia = (idMidia) => {
  switch (idMidia) {
    case 1:
      return 'Whatsapp';
    case 2:
      return 'Ligação';
    case 3:
      return 'Email';
    case 4:
      return 'Parceria';
    case 5:
      return 'Oferta Ativa';
    case 100:
      return 'Grupo Zap';
    case 101:
      return 'OLX';
    case 102:
      return 'Imovel Web';
    case 103:
      return 'Site Próprio';
    case 104:
      return 'Mooving';
    case 105:
      return 'Mercado Livre';
    case 106:
      return 'Casa Mineira';
    case 107:
      return 'Facebook';
    case 108:
      return 'Instagram';
    case 109:
      return 'Carteira Própria';
    case 119:
      return 'ADS Facebook - Equipe';
    case 120:
      return 'Google ADS';
    case 121:
      return 'Tiktok ADS';
    case 122:
      return 'Viva Real';
    case 123:
      return 'Rise Sales I.A.';
    case 124:
      return 'Desconhecido';
    case 125:
      return 'PDV"';
    case 126:
      return 'Ação Externa"';
    case 127:
      return 'Plantão Imobiliária';
    case 128:
      return 'Plantão Construtora';
    case 129:
      return 'Indicacão';
    case 130:
      return 'Relacionamento';
    case 131:
      return 'Evento';
    case 132:
      return 'Rodada de Negócios"';
    default:
      return '';
  }
}

const ModeloAtendimentoPadrao = ({ dados, nomeEmpresa, qtdRegistros }) => {
  return (
    <Document>
      <Page size="A4" style={styles.Page} orientation="landscape">
        <Text style={[styles.pageDate, styles.Label]}>
          {data} - {hora}
        </Text>
        <View style={styles.Container}>
          <View style={styles.SectionHeader}>
            <Text style={styles.Title}>
              {(nomeEmpresa || 'Locare Gestão Imobiliária').toUpperCase()}
            </Text>
          </View>

          <Text style={styles.SubtitleFirst}>
            Controle de Atendimentos
          </Text>

          {Object.keys(dados).map(function (key, index) {
            return (
              <View style={[{ marginBottom: '25px' }]} key={key}>
                <View style={[styles.Row, styles.Bg1, { padding: '4px' }]}>
                  <Text style={[styles.Span, styles.ColorBlack]}>Corretor: </Text>
                  <Text style={[styles.Span]}>{dados[key][0]?.corretor?.nome}</Text>
                </View>
                {dados[key].map((atd, atdIndex) =>
                  <View key={atd.id}>
                    <View wrap={false} style={[styles.Row, styles.DividerSectionTopSolid, styles.Bg4]}>
                      <Text style={[styles.Label, styles.ColorBlack]}>Atendimento: </Text>
                      <Text style={[styles.Label]}>{atdIndex + 1}</Text>
                      <Text style={[styles.Label, styles.ColorBlack]}>Cliente: </Text>
                      <Text style={[styles.Label]}>{atd?.cliente?.nome || '---'}</Text>
                      <Text style={[styles.Label, styles.ColorBlack]}>Celular: </Text>
                      <Text style={[styles.Label]}>{atd?.cliente?.celular || '---'}</Text>
                      <Text style={[styles.Label, styles.ColorBlack]}>Telefone: </Text>
                      <Text style={[styles.Label]}>{atd?.cliente?.telefone || '---'}</Text>
                      <Text style={[styles.Label, styles.ColorBlack]}>Status: </Text>
                      <Text style={[styles.Label]}>{getStatusNegocio(atd?.status) || '---'}</Text>
                    </View>
                    {atd?.eventos?.map((evt, indexEvt) => {
                      return <View style={{ padding: '10px' }} key={evt.id}>
                        <View style={[styles.Row, styles.DividerSectionTopDashed, styles.Bg3]}>
                          <Text style={[styles.Label, styles.ColorBlack]}>Tipo Atendimento: </Text>
                          <Text style={[styles.Label]}>{getTiposMidia(evt?.tipoAtendimento) || '---'}</Text>
                          <Text style={[styles.Label, styles.ColorBlack]}>Data Contato: </Text>
                          <Text style={[styles.Label]}>{moment(evt?.dataContato).format('DD/MM/YYYY') || '---'}</Text>
                          <Text style={[styles.Label, styles.ColorBlack]}>Data Prox Contato: </Text>
                          <Text style={[styles.Label]}>{moment(evt?.proximoContato).format('DD/MM/YYYY') || '---'}</Text>
                        </View>
                        <View style={[styles.Row]}>
                          <Text style={[styles.Label, styles.ColorBlack]}>Descrição: </Text>
                          <Text style={[styles.Label]}>{evt?.descricao || '---'}</Text>
                        </View>
                        <View style={[styles.Row]}>
                          <Text style={[styles.Label, styles.ColorBlack]}>Ação Tomada: </Text>
                          <Text style={[styles.Label]}>{evt?.acaoTomada || '---'}</Text>
                        </View>
                        <View style={[styles.Row]}>
                          <Text style={[styles.Label, styles.ColorBlack]}>Resultado: </Text>
                          <Text style={[styles.Label]}>{evt?.resultado || '---'}</Text>
                        </View>
                      </View>
                    })}
                  </View>
                )
                }
              </View>
            );
          })}

        </View>
        <Text style={[styles.registerCount, styles.Label]} render={({ pageNumber, totalPages }) => (
          pageNumber === totalPages ? `Qde de Registros: ${qtdRegistros}` : ''
        )} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default ModeloAtendimentoPadrao;


