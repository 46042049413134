/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Icon, Transition } from 'semantic-ui-react';
import { Form, Select, Input, Radio, Divider } from 'antd';
import { Row, Col } from 'reactstrap';

import Button from '~/shared/Button';
import CardImovel from '../CardImovel';
import api from '~/services/api';
import url from '~/infra/urls';
import pt from '~/infra/resources/strings/pt';
import Notify from '~/shared/Notify';

import Page from '~/components/Page';
import useImovel from '~/actions/Imovel/useImovel';
import useImovelCidadesDropdown from '~/actions/DropDown/useImovelCidadesDropdown';
import useBairroImovel from '~/actions/DropDown/useBairroImovel';

import enumSituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';
import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import useCondominioDropdown from '~/actions/DropDown/useCondomionio';

const { Option } = Select;

const options = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5 ou +', value: 5 },
];

function ModalBusca({
  open,
  close,
  setImovel,
  chavesNaEmpresa,
  isModalConsulta,
}) {
  const [visible, setVisible] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const { data: dataCidadesDropdown, onLoad: onLoadCidadesDropdown } =
    useImovelCidadesDropdown();
  const { data: dataBairros, onLoad: onLoadBairrosDropdown } = useBairroImovel();
  const { data: dataCondominios, onLoad: onLoadCondominio } =
    useCondominioDropdown();
  const [loadingBairro, setLoadingBairro] = useState(false);
  const [finalidades, setFinalidades] = useState([]);
  const [cidadeId, setCidadeId] = useState();
  const [bairroDisabled, setBairroDisabled] = useState(true);
  const [tipoImoveis, setTipoImoveis] = useState([]);
  const [tipoImovelDisabled, setTipoImovelDisabled] = useState(true);
  const [clearFiltroQuartos, setClearFiltroQuartos] = useState(0);
  const [clearFiltroVagas, setClearFiltroVagas] = useState(0);
  const [clearFiltroSuites, setClearFiltroSuites] = useState(0);
  const [hasSearching, setHasSearching] = useState(false);

  const { isLoading, data, onLoad } = useImovel();

  const [form] = Form.useForm();

  useEffect(() => {
    if (isLoading === false && data.length > 0) {
      setVisible(true);
      setLoadButton(false);
    } else if (isLoading === false) {
      if (hasSearching && chavesNaEmpresa === false) {
        Notify(
          'warning',
          '',
          'Não foram encontrados imóveis para os filtros informados!',
        );
      } else if (hasSearching && chavesNaEmpresa === true) {
        Notify(
          'warning',
          pt.comum.atencao,
          'No momento não existem imóveis com as chaves na empresa !',
        );
      }

      setTimeout(() => {
        setLoadButton(false);
      }, 1000);
      window.scrollTo(1, 1);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchFinalidades() {
      await api.get(url.FINALIDADE.CONSULTA()).then((res) => {
        setFinalidades(res?.data?.data || []);
      });
    }

    if (finalidades.length <= 0) fetchFinalidades();
  }, [finalidades]);

  async function onChangeCidade(cidadeId) {
    if (cidadeId && cidadeId.length > 0) {
      setLoadingBairro(true);
      setBairroDisabled(true);

      await onLoadBairrosDropdown({ cidadeId });

      form.setFieldsValue({ bairroId: [], portais: [] });

      setCidadeId(cidadeId);
      setBairroDisabled(false);
      setLoadingBairro(false);
    } else {
      form.setFieldsValue({ bairroId: [] });
      setCidadeId(undefined);
      setBairroDisabled(true);
    }
  }

  async function onChangeFinalidade(finalidadeId) {
    if (finalidadeId) {
      setTipoImovelDisabled(false);

      await api
        .get(
          `${url.TIPO_IMOVEL.CONSULTA()}?numeroPagina=1&tamanhoPagina=99999999&finalidadeId=${finalidadeId}`,
        )
        .then((res) => {
          if (res?.data?.data) setTipoImoveis(res.data.data);
          else setTipoImoveis([]);
          form.setFieldsValue({ tipoImoveis: [] });
        })
        .catch((err) => {
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    } else {
      setTipoImovelDisabled(true);
      setTipoImoveis([]);
      form.setFieldsValue({ tipoImoveis: [] });
    }
  }

  async function onSearchCidade(value) {
    await onLoadCidadesDropdown({ cidade: value });
  }

  async function onSearchBairro(value) {
    await onLoadBairrosDropdown({ cidadeId, nome: value });
  }

  async function onSearchCondominio(value) {
    await onLoadCondominio({ nome: value });
  }

  function onSelect(imovelBusca) {
    setImovel(imovelBusca);
    setVisible(false);
    close();
  }

  function onFiltros() {
    setVisible(false);
    setHasSearching(true);
    setVisible(false);
    setLoadButton(true);


    form
      .validateFields()
      .then((dados) => {
        const codImovelFilter = dados?.codImovel;
        onLoad({
          relatorio: false,
          params: {
            cidades: dados?.cidadeId,
            bairros: dados?.bairroId,
            condominios: dados?.condominioId,
            operacaoImobiliaria: dados?.operacao,
            situacao: codImovelFilter ? null : dados?.situacao ? dados?.situacao : null,
            tipoImoveis: dados?.tipoImoveis,
            logradouro: dados?.logradouro,
            valorVendaDe: dados?.valorVendaDe
              ? Number(
                dados?.valorVendaDe.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            valorVendaAte: dados?.valorVendaAte
              ? Number(
                dados?.valorVendaAte.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            valorLocacaoDe: dados?.valorLocacaoDe
              ? Number(
                dados?.valorLocacaoDe.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            valorLocacaoAte: dados?.valorLocacaoAte
              ? Number(
                dados?.valorLocacaoAte.replaceAll('.', '').replace(',', '.'),
              ).toFixed(2)
              : undefined,
            areaDe: dados?.areaDe,
            areaAte: dados?.areaAte,
            dormitorios: dados?.dormitorios,
            vagas: dados?.vagas,
            suites: dados?.suites,
            codImovel: codImovelFilter ? Number(codImovelFilter) : undefined,
            finalidadeId: dados?.finalidadeId,
          },
        });
      })
      .catch(() => {
        setLoadButton(false);
        window.scrollTo(1, 1);
      });
  }

  function onClickRadioQuartos(e) {
    if (e.target.value === clearFiltroQuartos) {
      form.setFieldsValue({ dormitorios: null });
    } else {
      setClearFiltroQuartos(e.target.value);
    }
  }

  function onClickRadioVagas(e) {
    if (e.target.value === clearFiltroVagas) {
      form.setFieldsValue({ vagas: null });
    } else {
      setClearFiltroVagas(e.target.value);
    }
  }

  function onClickRadioSuites(e) {
    if (e.target.value === clearFiltroSuites) {
      form.setFieldsValue({ suites: null });
    } else {
      setClearFiltroSuites(e.target.value);
    }
  }

  return (
    <Fragment>
      <Modal open={open} onClose={() => close()} size="large">
        <Modal.Header>
          <div className="my-3">
            <p>Consulta de imóveis</p>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Page loadPage={isLoading}>
            <div className="p-4">
              <Row>
                <Col>
                  <Form
                    initialValues={{
                      situacao: 1,
                    }}
                    form={form}
                    scrollToFirstError>
                    <Row>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Cód. do Imóvel</small>
                        </p>
                        <Form.Item className="my-2" name="codImovel">
                          <Input placeholder=" Cód. do Imóvel" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Cidade</small>
                        </p>
                        <Form.Item className="my-2" name="cidadeId">
                          <Select
                            mode='multiple'
                            showSearch
                            filterOption={false}
                            placeholder=" Cidade"
                            allowClear
                            onSearch={onSearchCidade}
                            onClear={onChangeCidade}
                            onChange={onChangeCidade}>
                            {dataCidadesDropdown.map((item) => (
                              <Option key={item.id}>{item.nome}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="4" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Bairro</small>
                        </p>
                        <Form.Item className="my-2" name="bairroId">
                          <Select
                            mode="multiple"
                            showSearch
                            showArrow
                            filterOption={false}
                            placeholder={
                              loadingBairro ? ' Carregando...' : ' Bairro'
                            }
                            allowClear
                            onSearch={onSearchBairro}
                            disabled={bairroDisabled}>
                            {dataBairros.map((item) => (
                              <Option key={item.id}>{item.nome}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="4" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Condomínio/Empreendimento</small>
                        </p>
                        <Form.Item className="my-2" name="condominioId">
                          <Select
                            mode="multiple"
                            placeholder=" Empreendimento"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear
                            onSearch={onSearchCondominio}>
                            {dataCondominios.map((item) => (
                              <Option key={item.id}>
                                {`${item.nome} - ${item?.logradouro?.bairro?.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Logradouro</small>
                        </p>
                        <Form.Item className="my-2" name="logradouro">
                          <Input placeholder=" Logradouro" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Área</small>
                        </p>
                        <Form.Item className="my-2" name="areaDe">
                          <Input prefix="M²" placeholder=" De" type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2 text-white">
                          <small>.</small>
                        </p>
                        <Form.Item className="my-2" name="areaAte">
                          <Input prefix="M²" placeholder=" Até" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Valor de Venda</small>
                        </p>
                        <Form.Item className="my-2" name="valorVendaDe">
                          <Input prefix="R$" placeholder=" De" type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2 text-white">
                          <small>.</small>
                        </p>
                        <Form.Item className="my-2" name="valorVendaAte">
                          <Input prefix="R$" placeholder=" Até" type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Valor de Locação</small>
                        </p>
                        <Form.Item className="my-2" name="valorLocacaoDe">
                          <Input prefix="R$" placeholder=" De" type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2 text-white">
                          <small>.</small>
                        </p>
                        <Form.Item className="my-2" name="valorLocacaoAte">
                          <Input prefix="R$" placeholder=" Até" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Operação</small>
                        </p>
                        <Form.Item className="my-2" name="operacao">
                          <Select placeholder=" Operação">
                            {enumOperacaoImobiliaria.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Situação</small>
                        </p>
                        <Form.Item className="my-2" name="situacao">
                          <Select placeholder=" Situaçào">
                            {enumSituacaoImovel.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Finalidade</small>
                        </p>
                        <Form.Item className="my-2" name="finalidadeId">
                          <Select
                            placeholder=" Finalidade"
                            allowClear
                            onClear={onChangeFinalidade}
                            onChange={onChangeFinalidade}>
                            {finalidades.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de Imóvel</small>
                        </p>
                        <Form.Item className="my-2" name="tipoImoveis">
                          <Select
                            mode='multiple'
                            placeholder=" Tipo de Imóvel"
                            allowClear
                            disabled={tipoImovelDisabled}>
                            {tipoImoveis.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.descricao}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Quartos</small>
                        </p>
                        <Form.Item className="my-2" name="dormitorios">
                          <Radio.Group
                            style={{ width: '100%' }}
                            optionType="button"
                            buttonStyle="solid">
                            {options.map((item) => (
                              <Radio.Button
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioQuartos}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Vagas</small>
                        </p>
                        <Form.Item className="my-2" name="vagas">
                          <Radio.Group
                            style={{ width: '100%' }}
                            optionType="button"
                            buttonStyle="solid">
                            {options.map((item) => (
                              <Radio.Button
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioVagas}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Suítes</small>
                        </p>
                        <Form.Item className="my-2" name="suites">
                          <Radio.Group
                            style={{ width: '100%' }}
                            optionType="button"
                            buttonStyle="solid">
                            {options.map((item) => (
                              <Radio.Button
                                key={item.value}
                                value={item.value}
                                onClick={onClickRadioSuites}>
                                {item.label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="3" className="my-2">
                        <Button
                          className="my-2"
                          size="tiny"
                          icon
                          labelPosition="left"
                          fluid
                          isloading={loadButton.toString()}
                          loadtext="Buscando..."
                          onClick={() => onFiltros()}>
                          <Icon name="search" />
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Divider dashed />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <Transition
                    visible={visible}
                    animation="scale"
                    duration={500}>
                    <CardImovel
                      onPreview={(imovel) => onSelect(imovel)}
                      data={data}
                      clickEnabled={true}
                      isModalConsulta={isModalConsulta}
                    />
                  </Transition>
                </Col>
              </Row>
            </div>
          </Page>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
}

export default ModalBusca;
