import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Input, Select, DatePicker, Tooltip } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';

import { checkDocumento } from '~/shared/checkDocumento';
import pattern from '~/infra/resources/pattern/mask';
import api from '~/services/api';
import url from '~/infra/urls';

import coutriesDDI from '~/infra/resources/strings/enums/countries';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import ModalLogradouro from '../ModalLogradouro';
import SkeletonTable from '../SkeletonTableBasic';
import Button from '~/shared/Button';
import useCondominioDropdown from '~/actions/DropDown/useCondomionio';
import useLogradouro from '~/actions/DropDown/useLogradouroDropdown';
moment.locale('pt-BR');

const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { TextArea } = Input;

function FormJuridica({ form, profissoes, estados, viewClient }) {
  const { data: dataCondominios } = useCondominioDropdown();
  const [loadingEndereco, setLoadingEndereco] = useState(false);
  const [modalLogradouroOpen, setModalLogradouroOpen] = useState(false);

  const [currentCep, setCurrentCep] = useState(0);

  const { data: dataLogradouros, onLoad: onLoadLogradouros } = useLogradouro();
  const { data: dataCorretores, onLoad: onLoadCorretores } = useCorretorDropdown();

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isMaster = !!roles.find((x) => x.toUpperCase() === 'MASTER');


  async function onSearchCaptador(value) {
    await onLoadCorretores({ nome: value });
  }

  // BLOCO PARA VALIDAÇÕES DO ENDEREÇO
  async function onSearchLogradouro(value) {
    await onLoadLogradouros(value);
  }


  async function onChangeLogradouro(logradouroId) {
    if (logradouroId) {
      const dataEndereco = dataLogradouros.find(
        (item) => item.id === logradouroId,
      );

      form.setFieldsValue({
        cep: dataEndereco.cep
          ? mask(dataEndereco.cep, [pattern.cep])
          : dataEndereco.cep,
        tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
        bairro: dataEndereco.bairro.nome,
        cidade: dataEndereco.bairro.cidade.nome,
        estado: dataEndereco.bairro.cidade.estadoId,
        pais: 'BRASIL',
      });
    } else {
      await onLoadLogradouros();
    }
  }

  function handleMask(e, type, field) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (field === 'celular' || field === 'telefone') {
      const fieldValue = form.getFieldValue('contato');

      if (
        (fieldValue?.celularDDI.includes("BR") && field === 'celular')
        ||
        (fieldValue?.telefoneDDI.includes("BR") && field === 'telefone')
      )
        form.setFieldsValue({ contato: { [field]: value } });
      else
        form.setFieldsValue({ contato: { [field]: unMask(e.currentTarget.value.replace(/\D/g, '')) } });
    }
    else
      form.setFieldsValue({ [field]: value });
  }

  async function handleCep(value, type) {
    let cep = value;
    cep = unMask(cep);
    cep = mask(cep, [type]);

    if (type === pattern.cep) form.setFieldsValue({ cep });

    if (cep.length === 10) {
      const unmaskedCEP = unMask(cep);
      setLoadingEndereco(true);
      await api
        .get(url.SERVICES.CONSULTA_CEP(unmaskedCEP))
        .then(async (res) => {
          const { data: dataEndereco, status } = res;
          if (status === 204) {
            Notify(
              'warning',
              pt.comum.atencao,
              'Nenhum logradouro encontrado com o CEP informado.',
            );
          } else if (dataEndereco) {
            await onLoadLogradouros(dataEndereco.nome);
            form.setFieldsValue({
              enderecoLogradouroId: dataEndereco.id,
              cep: dataEndereco.cep
                ? mask(dataEndereco.cep, [pattern.cep])
                : '',
              tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
              bairro: dataEndereco.bairro.nome,
              cidade: dataEndereco.bairro.cidade.nome.toUpperCase(),
              estado: dataEndereco.bairro.cidade.estadoId,
              pais: 'BRASIL',
            });
          }

          setLoadingEndereco(false);
        })
        .catch((err) => {
          setLoadingEndereco(false);
        });
    }
  }

  useEffect(() => {
    async function loadCorretor() {
      const captadorId = form.getFieldValue('captadorId');
      await onLoadCorretores({ id: captadorId });
    }

    async function loadLogradouro() {
      const logradouro = form.getFieldValue('enderecoLogradouro');
      await onLoadLogradouros(logradouro);
    }

    loadLogradouro();
    loadCorretor();
    // eslint-disable-next-line
  }, []);


  return (
    <Fragment>
      <Row>
        <Col xs="12" md="8">
          <p className="heading-small text-muted mb-2">
            <small>Nome</small>
          </p>
          <Form.Item
            name="nome"
            rules={[
              {
                required: true,
                message: 'Favor informar o Nome',
              },
            ]}>
            <Input placeholder=" Nome" disabled={viewClient} />
          </Form.Item>
        </Col>
        <Col xs="12" md="4">
          <p className="heading-small text-muted mb-2">
            <small>CNPJ</small>
          </p>
          <Form.Item
            name="cnpj"
            rules={[
              // eslint-disable-next-line no-empty-pattern
              ({ }) => ({
                validator(rule, value) {
                  if (value) {
                    const unMaskedValue = unMask(value);
                    const isDocumento = checkDocumento('cnpj', unMaskedValue);
                    if (isDocumento === true) {
                      return Promise.reject('Número de CNPJ inválido');
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}>
            <Input
              disabled={viewClient}
              placeholder=" Número do CNPJ"
              onKeyUp={(e) => handleMask(e, pattern.cnpj, 'CNPJ')} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Email</small>
          </p>
          <Form.Item className="my-2" name="email">
            <Input placeholder=" Email" disabled={viewClient} />
          </Form.Item>
        </Col>
        <Col xs="12" md="3" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Fundação</small>
          </p>
          <Form.Item className="my-2" name="fundacao">
            <DatePicker
              disabled={viewClient}
              locale={locale}
              format={dateFormat}
              style={{ width: '100%' }}
              placeholder=" Fundação"
            />
          </Form.Item>
        </Col>
        <Col xs="12" md="3" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Razão</small>
          </p>
          <Form.Item name="razao">
            <Input placeholder=" Razão" disabled={viewClient} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="3" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Ocupação</small>
          </p>
          <Form.Item className="my-2" name="ocupacaoId">
            <Select placeholder=" Ocupação" disabled={viewClient}>
              {profissoes.map((item) => (
                <Option key={item.id}>{item.nome}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs="12" md="3" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Celular</small>
          </p>
          <Form.Item className="my-2">
            <Input.Group compact>
              <Form.Item noStyle name={['contato', 'celularDDI']}>
                <Select
                  dropdownMatchSelectWidth={false}
                  disabled={viewClient}
                  placeholder=" DDI"
                  style={{ width: '30%' }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.props.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  options={coutriesDDI.map(item => ({
                    label: <span>{`${item.name}(${item.code})`}</span>,
                    value: `${item.iso}${item.code}`
                  }))} />
              </Form.Item>
              <Form.Item
                noStyle
                name={['contato', 'celular']}
                rules={[
                  {
                    required: true,
                    message: 'Favor informar o celular',
                  },
                  // eslint-disable-next-line no-empty-pattern
                  ({ }) => ({
                    validator(rule, value) {
                      if (value) {
                        const fieldValue = form.getFieldValue('contato');
                        const unMaskedValue = unMask(value);
                        const regex = new RegExp(/^(?:(55\d{2})|\d{2})[6-9]\d{8}$/);
                        if (!regex.test(unMaskedValue) && fieldValue?.celularDDI.includes('BR')) {
                          return Promise.reject('Número de celular inválido');
                        }
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  disabled={viewClient}
                  placeholder=" DDD + Telefone"
                  maxLength={20}
                  onKeyUp={(e) => handleMask(e, pattern.celular, 'celular')}
                  style={{ width: '70%' }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col xs="12" md="3" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Telefone</small>
          </p>
          <Form.Item className="my-2">
            <Input.Group compact>
              <Form.Item noStyle name={['contato', 'telefoneDDI']}>
                <Select
                  dropdownMatchSelectWidth={false}
                  disabled={viewClient}
                  placeholder=" DDI"
                  style={{ width: '30%' }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.props.children.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  options={coutriesDDI.map(item => ({
                    label: <span>{`${item.name}(${item.code})`}</span>,
                    value: `${item.iso}${item.code}`
                  }))} />
              </Form.Item>
              <Form.Item noStyle name={['contato', 'telefone']}>
                <Input
                  disabled={viewClient}
                  placeholder=" DDD + Telefone"
                  maxLength={20}
                  onKeyUp={(e) => handleMask(e, pattern.telefone, 'telefone')}
                  style={{ width: '70%' }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col xs="12" md="3" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Área de atuação</small>
          </p>
          <Form.Item className="my-2" name="ocupacaoId">
            <Select placeholder=" Área de atuação" disabled={viewClient}>
              {profissoes.map((item) => (
                <Option key={item.id}>{item.nome}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {loadingEndereco && <SkeletonTable cardShadowless />}
      {!loadingEndereco && (
        <Fragment>
          <Row>
            <Col xs="12" md="2" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>CEP</small>
              </p>
              <Form.Item className="my-2" name="cep">
                <Input
                  disabled={viewClient}
                  placeholder=" CEP"
                  onKeyUp={(e) => {
                    setCurrentCep(e.currentTarget.value);
                    handleCep(e.currentTarget.value, pattern.cep);
                  }}
                />
              </Form.Item>
            </Col>
            {!viewClient && (
              <Col xs="12" md="1" className="my-2">
                <p className="heading-small text-muted mb-1 text-white">
                  <small>&nbsp;</small>
                </p>
                <Tooltip placement="topRight" title={'Cadastrar Logradouro'}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => setModalLogradouroOpen(true)}
                    icon
                    color="violet">
                    <Icon name="plus" />
                  </Button>
                </Tooltip>
              </Col>
            )}
            <Col xs="12" md="9" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Logradouro</small>
              </p>
              <Form.Item className="my-2" name="enderecoLogradouroId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children[0] &&
                    option.children[0]
                      .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  placeholder=" Logradouro"
                  onSearch={onSearchLogradouro}
                  onChange={onChangeLogradouro}
                  onClear={onChangeLogradouro}
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o logradouro',
                    },
                  ]}>
                  {dataLogradouros.map((item) => (
                    <Option key={item.id}>
                      {item.nome}
                      {item?.bairro?.nome &&
                        `, Bairro: ${item?.bairro?.nome}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Tipo</small>
              </p>
              <Form.Item className="my-2" name="tipoLogradouro">
                <Input disabled placeholder=" Tipo" />
              </Form.Item>
            </Col>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Bairro</small>
              </p>
              <Form.Item className="my-2" name="bairro">
                <Input placeholder=" Bairro" disabled />
              </Form.Item>
            </Col>
            <Col xs="12" md="4" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Cidade</small>
              </p>
              <Form.Item className="my-2" name="cidade">
                <Input placeholder=" Cidade" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Estado</small>
              </p>
              <Form.Item className="my-2" name="estado">
                <Input placeholder=" Estado" disabled />
              </Form.Item>
            </Col>
            <Col xs="12" md="3" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>País</small>
              </p>
              <Form.Item className="my-2" name="pais">
                <Input disabled placeholder=" País" />
              </Form.Item>
            </Col>
            <Col xs="12" md="6" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Complemento</small>
              </p>
              <Form.Item name="enderecoComplemento">
                <Input placeholder=" Complemento" disabled={viewClient} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="2" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Número</small>
              </p>
              <Form.Item className="my-2" name="enderecoNumero">
                <Input placeholder=" Número" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="2" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Lote/Apto</small>
              </p>
              <Form.Item className="my-2" name="loteApto">
                <Input placeholder=" Lote/Apto" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="2" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Quadra/Bloco</small>
              </p>
              <Form.Item className="my-2" name="quadraBloco">
                <Input placeholder=" Quadra/Bloco" disabled={viewClient} />
              </Form.Item>
            </Col>
            <Col xs="12" md="6" className="my-2">
              <p className="heading-small text-muted mb-2">
                <small>Condomínio/Empreendimento</small>
              </p>
              <Form.Item name="condominioId">
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  showSearch
                  disabled={viewClient}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")
                      .indexOf(input?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")) >= 0
                  }
                  placeholder=" Condomínio/Empreendimento">
                  {dataCondominios.map((item) => (
                    <Option key={item.id}>
                      {`${item.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}
      {isMaster && (
        <Row>
          <Col xs="12" md="4" className="my-2">
            <p className="heading-small text-muted mb-2">
              <small>Captador</small>
            </p>
            <Form.Item name="captadorId">
              <Select
                allowClear
                showSearch
                disabled={viewClient}
                filterOption={false}
                onSearch={onSearchCaptador}
                placeholder="Captador">
                {dataCorretores.map((item) => (
                  <Option key={item.id} disabled={item.isSeparator}>
                    {item?.nome?.toString()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs="12" className="my-2">
          <p className="heading-small text-muted mb-2">
            <small>Observação</small>
          </p>
          <Form.Item className="my-2" name="observacao">
            <TextArea
              disabled={viewClient}
              placeholder=" Observações"
              rows={4}
              maxLength={500}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>

      <ModalLogradouro
        open={modalLogradouroOpen}
        close={() => {
          setModalLogradouroOpen(false);
          if (currentCep) handleCep(currentCep, pattern.cep);
        }}
      />

    </Fragment>
  );
}

export default FormJuridica;
