import React, {Fragment} from 'react';
import {Switch} from 'react-router-dom';
import Route from './Routes';

import Login from '~/views/public/Login';
import EsqueciSenha from '~/views/public/EsqueciSenha';
import ConfirmacaoEmail from '~/views/public/ConfirmarEmail';
import RecuperarSenha from '~/views/public/RecuperarSenha';
import DeskAtendimentos from '~/views/public/Desktop/Atendimentos';
import AcademiaVendas from '~/views//public/AcademiaVendas/index';
import FacebookLoginRedirect from '~/views/private/Configuracoes/components/distribuicao/components/facebookLoginRedirect';

const PublicRoutes = ({match}) => (
  <Fragment>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/esqueci-senha" exact component={EsqueciSenha} />
      <Route path="/auth/confirmar-email" exact component={ConfirmacaoEmail} />
      <Route path="/auth/recuperar-senha" exact component={RecuperarSenha} />
      <Route path="/auth/atendimentos" exact component={DeskAtendimentos} />
      <Route path="/academia-vendas" exact component={AcademiaVendas} />
      <Route path="/facebook" exact component={FacebookLoginRedirect} />
    </Switch>
  </Fragment>
);

export default PublicRoutes;
