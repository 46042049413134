import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function useTipoImovel(
  current,
  pageSize,
  nomeTipoImovel,
  finalidadeId,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async () => {
      setIsLoading(true);
      const filterCurrent = current ? current : null;
      const tamanhoPagina = pageSize ? pageSize : null;
      const filterNomeTipoImovel = nomeTipoImovel ? nomeTipoImovel : null;
      const filterFinalidadeId = finalidadeId ? finalidadeId : null;
      let filter = null;

      if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

      if (tamanhoPagina) {
        if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
        else filter = `?tamanhoPagina=${tamanhoPagina}`;
      }

      if (filterNomeTipoImovel) {
        if (filter.length > 0) filter += `&nome=${filterNomeTipoImovel}`;
        else filter = `?nome=${filterNomeTipoImovel}`;
      }

      if (filterFinalidadeId) {
        if (filter.length > 0) filter += `&finalidadeId=${filterFinalidadeId}`;
        else filter = `?finalidadeId=${filterFinalidadeId}`;
      }

      if (filter) {
        try {
          await api
            .get(`${url.TIPO_IMOVEL.CONSULTA()}${filter ? filter : ''}`)
            .then((res) => {
              setData(res?.data?.data || []);
              setQuantidade(res?.data.total);
            });
        } catch (e) {
          setData([]);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [current, pageSize, nomeTipoImovel, finalidadeId]
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
