import {useState} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

export default function useAtivarUsuario() {
  const [isLoading, setIsLoading] = useState(false);
  const ativarUsuario = async (usuarioId) => {
    setIsLoading(true);
    try {
      await api
        .patch(url.CORRETOR.ATIVAR(usuarioId))
        .then((res) =>
          Notify('success', pt.comum.aviso, 'Usuário ativo com sucesso!'),
        );
    } catch (err) {
      Notify('error', pt.comum.aviso, 'Não foi possivel ativar o usuário.');
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    ativarUsuario,
  };
}
