import { useEffect, useState, useCallback } from 'react';
import api from '~/services/api';
import url from '~/infra/urls';

export default function usePessoa(current, pageSize, nome, tipo, cpf, pesquisa) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const onLoad = useCallback(
    async () => {
      setIsLoading(true);
      const filterCurrent = current ? current : null;
      const filterNome = nome ? nome : null;
      const filterTipo = tipo ? tipo : null;
      const filterCPF = cpf ? cpf : null;
      const tamanhoPagina = pageSize ? pageSize : null;
      const filterPesquisa = pesquisa ? pesquisa : null;
      let filter = '';

      if (filterCurrent) filter = `?numeroPagina=${filterCurrent}`;

      if (tamanhoPagina) {
        if (filter.length > 0) filter += `&tamanhoPagina=${tamanhoPagina}`;
        else filter = `?tamanhoPagina=${tamanhoPagina}`;
      }

      if (filterNome) {
        if (filter.length > 0) filter += `&nome=${filterNome}`;
        else filter = `?nome=${filterNome}`;
      }

      if (filterTipo) {
        if (filter.length > 0) filter += `&Tipo=${filterTipo}`;
        else filter = `?Tipo=${filterTipo}`;
      }

      if (filterCPF) {
        if (filter.length > 0) filter += `&cpf=${filterCPF}`;
        else filter = `?cpf=${filterCPF}`;
      }

      if (filterPesquisa) {
        if (filter.length > 0) filter += `&pesquisa=${filterPesquisa}`;
        else filter = `?pesquisa=${filterPesquisa}`;
      }

      if (filter) {
        try {
          await api
            .get(`${url.PESSOA.CONSULTA()}${filter ? filter : ''}`)
            .then((res) => {
              setData(res?.data?.data || []);
              setQuantidade(res?.data?.total);
            });
        } catch (e) {
          setData([]);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [current, nome, tipo, cpf, pageSize, pesquisa],
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad,
  };
}
