import React from 'react';
import useDaysWithoutSelling from './use-days-without-selling';

import { Card, Row, Col } from 'reactstrap';
import CardTitle from '../../CardTitle/CardTitle';

import { Bullet } from '@ant-design/charts';

export default function DaysWithoutSelling({ filters }) {
  const { mainPeriod, broker, brokerGroup, initialDate, finalDate } = filters;
  const { config, data } = useDaysWithoutSelling(mainPeriod, broker, brokerGroup, initialDate, finalDate);

  return (
    <Card
      className="p-3"
      style={{
        minWidth: 300,
        display: 'flex',
        gap: 12,
      }}>
      <CardTitle>Dias sem Vender</CardTitle>
      <Row>
        <Col>
          {!!data && data.length > 0 ? <Bullet {...config} /> : <p>Nenhum dado encontrado</p>}
        </Col>
      </Row>
    </Card>
  );
}

