import React from 'react';
import emptyBox from '~/assets/img/empty-box-color.png';

function EmptyBox({message}) {
  return (
    <>
      <div className="text-center my-4">
        <img alt="..." src={emptyBox} />
        <p className="m-4">
          <span style={{fontSize: '18px'}}>
            Nenhum resultado foi encontrado
          </span>
          <br />
          <br />
          <span>{message}</span>
        </p>
      </div>
    </>
  );
}

export default EmptyBox;
