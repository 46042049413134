const getFiltrosImoveis = () => {
  return {};
};

const setFiltrosImoveis = (filtros) => {
  const idUser = localStorage.getItem('icaseUsuarioId');
  let cache = JSON.parse(localStorage.getItem(idUser) || '{}');
  cache.filterImoveis = filtros;
};

const removeFiltrosImoveis = () => {
  const idUser = localStorage.getItem('icaseUsuarioId');
  const cache = JSON.parse(localStorage.getItem(idUser) || '{}');
  cache.filterImoveis = {};
};

const getFiltrosAtendimentos = () => {
  const idUser = localStorage.getItem('icaseUsuarioId');
  const {filterAtendimentos} = JSON.parse(localStorage.getItem(idUser) || '{}');
  return (
    filterAtendimentos && {
      ...filterAtendimentos,
      status: filterAtendimentos.status,
    }
  );
};

const setFiltrosAtendimentos = (filtros) => {
  const idUser = localStorage.getItem('icaseUsuarioId');
  let cache = JSON.parse(localStorage.getItem(idUser) || '{}');
  cache.filterAtendimentos = filtros;
};

const getFiltrosImoveisCompartilhados = () => {
  const idUser = localStorage.getItem('icaseUsuarioId');
  const {filterImoveisCompartilhados} = JSON.parse(
    localStorage.getItem(idUser) || '{}',
  );
  return (
    filterImoveisCompartilhados && {
      ...filterImoveisCompartilhados,
      cidadeId: filterImoveisCompartilhados.cidades,
      bairroId: filterImoveisCompartilhados.bairros,
      condominioId: filterImoveisCompartilhados.condominios,
      operacao: filterImoveisCompartilhados.operacaoImobiliaria,
      valorVendaDe:
        filterImoveisCompartilhados?.valorVendaDe &&
        filterImoveisCompartilhados?.valorVendaDe.replaceAll('.', ','),
      valorVendaAte:
        filterImoveisCompartilhados?.valorVendaAte &&
        filterImoveisCompartilhados?.valorVendaAte.replaceAll('.', ','),
      valorLocacaoDe:
        filterImoveisCompartilhados?.valorLocacaoDe &&
        filterImoveisCompartilhados?.valorLocacaoDe.replaceAll('.', ','),
      valorLocacaoAte:
        filterImoveisCompartilhados?.valorLocacaoAte &&
        filterImoveisCompartilhados?.valorLocacaoAte.replaceAll('.', ','),
    }
  );
};

const setFiltrosImoveisCompartilhados = (filtros) => {
  const idUser = localStorage.getItem('icaseUsuarioId');
  let cache = JSON.parse(localStorage.getItem(idUser) || '{}');
  cache.filterImoveisCompartilhados = filtros;
};

export const FilterCache = {
  getFiltrosImoveis,
  setFiltrosImoveis,
  removeFiltrosImoveis,
  getFiltrosAtendimentos,
  setFiltrosAtendimentos,
  getFiltrosImoveisCompartilhados,
  setFiltrosImoveisCompartilhados,
};
