/* eslint-disable no-unused-vars */
import React, {Fragment, useEffect, useState} from 'react';
import {Modal, Button, Icon} from 'semantic-ui-react';
import {Table, Tag, Switch, Tooltip} from 'antd';
import ModalAndamentoAnalise from '~/components/ModaAnaliseFinanceira/ModalAndamentoAnalise';
import ModalCheckListAnalise from '~/components/ModaAnaliseFinanceira/ModalCheckListAnalise';
import ModalMaisInfoAnalise from '~/components/ModaAnaliseFinanceira/ModalMaisInfoAnalise';
import {
  OrderedListOutlined,
  AuditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import useAnaliseFinanceira from '~/actions/Atendimento/useAnaliseFinanceira';
import api from '~/services/api';
import url from '~/infra/urls';
import approval from '~/assets/img/approval.svg';

import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-BR');

function ModalAnaliseFinanceira({open, close, onChangeQtd}) {
  const [row, setRow] = useState({});
  const [andamentoAnaliseOpen, setAndamentoAnaliseOpen] = useState(false);
  const [checkListOpen, setCheckListOpen] = useState(false);
  const [maisInfoOpen, setMaisInfoOpen] = useState(false);
  const [loadCheck, setLoadCheck] = useState(false);
  const [current, setCurrent] = useState(1);

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isCorretor = !!roles.find((x) => x.toUpperCase() === 'CORRETOR');
  const usuarioId = localStorage.getItem('icaseUsuarioId');

  const {
    isLoading: isLoadingData,
    data,
    quantidade,
    onLoad,
  } = useAnaliseFinanceira(current, 10, isCorretor ? usuarioId : null);

  const definirEmAnalise = async (id) => {
    setLoadCheck(true);
    await api
      .put(url.ATENDIMENTO.DEFINIR_EM_ANALISE(id))
      .catch((err) => {
        const {erros} = err.response.data;
        if (erros) {
          for (const item in erros) {
          }
        }
      })
      .finally(() => {
        setLoadCheck(false);
        onLoad();
      });
  };

  function rowEdit(row) {
    setRow(row);
    setAndamentoAnaliseOpen(true);
  }

  function rowCheckList(row) {
    setRow(row);
    setCheckListOpen(true);
  }

  function rowMaisInfo(row) {
    setRow(row);
    setMaisInfoOpen(true);
  }

  useEffect(() => {
    onChangeQtd && onChangeQtd(quantidade);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantidade]);

  const columnsCorretor = [
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
      responsive: ['lg'],
      render: (cliente) => <span>{cliente.nome}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      responsive: ['lg'],
      render: (row) => (
        <div key={row.index}>
          {row.status === 5 && row.emAnalise && (
            <Tag color="blue">Em Análise</Tag>
          )}
          {row.status === 5 && !row.emAnalise && (
            <Tag color="gold">Aguardando Análise</Tag>
          )}
          {row.status === 6 && <Tag color="green">Aprovada</Tag>}
          {row.status === 7 && <Tag color="red">Reprovada</Tag>}
        </div>
      ),
    },
    {
      title: 'Data/Hora envio',
      key: 'dataStatus',
      render: (row) => (
        <span>{moment(row?.dataStatus).format('DD/MM/YYYY - HH:mm')}</span>
      ),
    },
    {
      title: 'Ações',
      key: 'acoes',
      className: 'column-action',
      render: (row) => (
        <div key={row.index}>
          <Tooltip
            title="Mais Informações"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowMaisInfo(row)}
              className="linkTable"
              style={{fontSize: '25px', marginRight: '20px'}}>
              <EyeOutlined />
            </i>
          </Tooltip>
          <Tooltip
            title="Checklist"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowCheckList(row)}
              className="linkTable"
              style={{fontSize: '25px', marginRight: '20px'}}>
              <OrderedListOutlined />
            </i>
          </Tooltip>
          <Tooltip
            title="Resultado Análise"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowEdit(row)}
              className="linkTable"
              style={{fontSize: '25px'}}>
              <AuditOutlined />
            </i>
          </Tooltip>
        </div>
      ),
    },
  ];

  const columnsMaster = [
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
      responsive: ['lg'],
      render: (cliente) => <span>{cliente.nome}</span>,
    },
    {
      title: 'Status',
      key: 'status',
      responsive: ['lg'],
      render: (row) => (
        <div key={row.index}>
          {row.status === 5 && row.emAnalise ? (
            <Tag color="blue">Em Análise</Tag>
          ) : (
            <Tag color="gold">Aguardando Análise</Tag>
          )}
          {row.status === 6 && <Tag color="green">Aprovada</Tag>}
          {row.status === 7 && <Tag color="red">Reprovada</Tag>}
        </div>
      ),
    },
    {
      title: 'Data/Hora envio',
      key: 'dataStatus',
      render: (row) => (
        <span>{moment(row?.dataStatus).format('DD/MM/YYYY - HH:mm')}</span>
      ),
    },
    {
      title: 'Em Análise?',
      key: 'emAnalise',
      render: (row) => (
        <div key={row.index}>
          {row.emAnalise && row.idAtendenteAtual !== usuarioId ? (
            <span>{row.nomeAtendenteAtual || 'Outro Usuário'}</span>
          ) : (
            <Switch
              loading={loadCheck}
              checked={row.emAnalise}
              onChange={async () => await definirEmAnalise(row.id)}
              checkedChildren="Sim"
              unCheckedChildren="Não"
              size="small"
            />
          )}
        </div>
      ),
    },
    {
      title: 'Ações',
      key: 'acoes',
      className: 'column-action',
      render: (row) => (
        <div key={row.index}>
          <Tooltip
            title="Mais Informações"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowMaisInfo(row)}
              className="linkTable"
              style={{fontSize: '25px', marginRight: '20px'}}>
              <EyeOutlined />
            </i>
          </Tooltip>
          <Tooltip
            title="Checklist"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowCheckList(row)}
              className="linkTable"
              style={{fontSize: '25px', marginRight: '20px'}}>
              <OrderedListOutlined />
            </i>
          </Tooltip>
          <Tooltip
            title="Realizar Análise"
            getPopupContainer={(node) => node.parentNode}>
            <i
              onClick={() => rowEdit(row)}
              className="linkTable"
              style={{fontSize: '25px'}}>
              <AuditOutlined />
            </i>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <Modal
        closeOnDimmerClick={false}
        size="large"
        dimmer="blurring"
        open={open}
        onClose={() => close()}>
        <Modal.Header>
          <div
            style={{display: 'flex', alignItems: 'center'}}
            className="symbol symbol-50 symbol-light">
            <img
              className="mr-3 h-50 align-self-end"
              src={approval}
              alt="..."
            />
            <h6 className="title">Análise Financeira</h6>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Table
            loading={isLoadingData}
            rowClassName={(row) =>
              row.emAnalise ? 'table-row-blue' : 'table-row-retorno'
            }
            rowKey={'id'}
            columns={isCorretor ? columnsCorretor : columnsMaster}
            dataSource={data}
            pagination={{
              current: current,
              showSizeChanger: false,
              defaultCurrent: 1,
              total: quantidade,
              responsive: true,
              onChange: (page) => setCurrent(page),
            }}
          />
          <ModalMaisInfoAnalise
            open={maisInfoOpen}
            close={() => {
              setMaisInfoOpen(false);
            }}
            row={row}
          />

          <ModalAndamentoAnalise
            open={andamentoAnaliseOpen}
            close={() => {
              onLoad();
              setAndamentoAnaliseOpen(false);
            }}
            row={row}
            isCorretor={isCorretor}
          />

          <ModalCheckListAnalise
            open={checkListOpen}
            onSuccesso={() => {
              setCheckListOpen(false);
              onLoad();
            }}
            onCancel={() => {
              setCheckListOpen(false);
            }}
            row={row}
            readOnly={isCorretor}
          />
        </Modal.Content>
        <Modal.Actions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Total Análises: {quantidade}</span>
          <Button icon labelPosition="left" onClick={() => onLoad()}>
            <Icon name="sync alternate" />
            Atualizar
          </Button>
          <Button icon labelPosition="left" onClick={() => close()}>
            <Icon name="times" />
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
}

export default ModalAnaliseFinanceira;
